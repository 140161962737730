import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#ededed',
}));

const StyledLTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
}));

const StyledRTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
}));

function createData(
  checked: boolean,
  name: string,
) {
  return { checked, name };
}

const rows = [
  createData(false, 'todo 1'),
  createData(true, 'todo 2'),
  createData(true, 'todo 3'),
  createData(true, 'todo 4'),
];

export default function Todolist() {
  return <Box style={{ marginTop: 30, minWidth: 300 }}>
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Tâches</Typography>
      <Typography variant='caption' mt={1.4}>Ajouter une tâche</Typography>
      <IconButton onClick={() => { /* */ }}>
        <AddCircleIcon />
      </IconButton>
    </Box>
    <Box height={10} />
    <TableContainer component={Box}>
      <Table size='small' style={{ borderCollapse: 'separate', borderSpacing: '0 1em' }}>
        <TableBody>
          {rows.map((row) => <StyledTableRow key={row.name}>
            <StyledLTableCell width={60} align='center'>
              <FormControlLabel control={<Checkbox defaultChecked />} label='Label' />
            </StyledLTableCell>
            <StyledRTableCell scope='row'>{row.name}</StyledRTableCell>
          </StyledTableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

// import React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import CircleIcon from '@mui/icons-material/Circle';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));

// export interface ListProps {
//   gutterBottom?: boolean;
// }

// export default function List({ gutterBottom }: ListProps) {
//   return <Box display='flex' flexDirection='column' sx={{ mb: gutterBottom ? 2 : undefined}}>
//     <Typography variant='caption'>Clients actifs</Typography>
//     <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', padding: 12, width: '100%' }}>
//       <CircleIcon />
//       <Typography variant='body1'>#123123</Typography>
//       <Typography variant='body1'>John Doe</Typography>
//       <Typography variant='body1'>29/05/22</Typography>
//       <Typography variant='body1'>67%</Typography>
//       <BorderLinearProgress variant='determinate' value={67} />
//     </Paper>
//   </Box>
// }
