import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export default function Calendar() {
  const [value, setValue] = React.useState<Date | null>(new Date());

  return <Stack direction='row' spacing={2} sx={{ mb: 4 }}>
    <Stack direction='column' spacing={2}>
      <Typography variant='body1'>Rendez-vous médical</Typography>
      <StaticDatePicker
        displayStaticWrapperAs='desktop'
        // showToolbar={true}
        openTo='day'
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Stack>
    <Stack direction='column'>
      <Typography variant='body1'>Heure</Typography>
      <Box mb={3} />
      <Chip label='13h00' />
      <Box mb={8} />
      <Typography variant='body1'>Thibvauld Émile</Typography>
      <Typography variant='caption'>Test sanguin</Typography>
      <Box mb={9} />
      <Chip label='Completé' />
    </Stack>
  </Stack>
}
