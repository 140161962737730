import { FastForm } from '.';

export const timeOffersAdd: FastForm = {
  sections: [
    {
      name: 'start',
      title: 'add time offer',
      subTitle: 'fast time selector',
      icon: 'clock',
      questions: [
        {
          id: 's1q1',
          type: 'checkbox',
          text: 'which week of the month are we talking about?',
          choices: ['scheduling.1stWeek', 'scheduling.2ndWeek', 'scheduling.3rdWeek', 'scheduling.4thWeek'],
          next: 's1q2.0',
        },
        {
          id: 's1q2.0',
          type: 'checkbox',
          text: 'which day of the week can you works?',
          choices: ['week.sunday', 'week.monday', 'week.tuesday', 'week.wednesday', 'week.thursday', 'week.friday', 'week.saturday'],
          next: 's1q2.1',
        },
        {
          id: 's1q2.1',
          type: 'bool',
          text: 'are every remaining weeks of the month the same?',
          next: { f: 's1q2.2', t: 's1q3.0' },
        },
        {
          id: 's1q2.2',
          type: 'checkbox',
          text: 'which day of the second week?',
          choices: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
          next: 's1q2.3',
        },
        {
          id: 's1q2.3',
          type: 'checkbox',
          text: 'which day of the third week?',
          choices: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
          next: 's1q2.4',
        },
        {
          id: 's1q2.4',
          type: 'checkbox',
          text: 'which day of the forth week?',
          choices: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
          next: 's1q3.0',
        },
        {
          id: 's1q3.0',
          type: 'loop',
          text: '',
          loop: {
            use: ['s1q2.0', 's1q2.2', 's1q2.3', 's1q2.4'],
            qs: [
              {
                id: 's1q3.0.0',
                type: 'butlist',
                text: 'which part of the day can you works?',
                choices: ['am/pm', 'am', 'pm'],
                next: ['s1q3.0.1', 's1q3.0.2', 's1q3.0.3'],
              },
              {
                id: 's1q3.0.1',
                type: 'timerng',
                text: 'set start and end time',
                min: 7,
                max: 20,
                next: 's1q3.0',
              },
              {
                id: 's1q3.0.2',
                type: 'timerng',
                text: 'set start and end time',
                min: 7,
                max: 12,
                next: 's1q3.0',
              },
              {
                id: 's1q3.0.3',
                type: 'timerng',
                text: 'set start and end time',
                min: 13,
                max: 20,
                next: 's1q3.0',
              },      
            ],
          },
          next: 's1q4',
        },
        {
          id: 's1q4',
          type: 'bool',
          text: 'do you have other availabilities?',
          next: { f: 'end', t: 's1q2.0' },
        },
        {
          id: 'end',
          type: 'end',
          text: 'end',
          next: 'end',
          debug: true,
        },
      ],
    },
    {
      name: 'week',
      title: 'new time offer',
      subTitle: 'define week',
      icon: 'calendar',
      questions: [
        {
          id: 's2q1.0',
          type: 'checkbox',
          text: 'which day of the week can you works?',
          choices: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
          next: 's1q2.1',
        },
      ],
    },
    {
      name: 'timeRange',
      title: 'new time offer',
      subTitle: 'define week',
      icon: 'calendar',
      questions: [
        {
          id: 's2q1',
          type: 'bool',
          text: 'are you available fulltime (every day of a month)?',
          next: { f: 's1q2.0', t: 's1q2' },
        },
      ],
    },
  ],
};
