import { useContext } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
//
import { clientEmrStore } from '../../../..';

export default function CreateEventButton() {
  const { setShowEventModal } = useContext(clientEmrStore);

  return <Button variant='outlined' startIcon={<AddIcon />} onClick={() => setShowEventModal(true)} sx={{ ml: 2 }}>
    Create
  </Button>
}
