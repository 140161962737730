/**
  =========================================================
  * eksöh ® corporate kit
  =========================================================

  * Contact: François Paquette
  * Copyright 2022 eksöh ® (https://www.eksoh.com)

  Property of eksöh ®

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Discharge, SickLeave, Diagnosis, EntryFormValues } from '../../types';

export const EmrEntryInitialValues: EntryFormValues = {
  type: '',
  description: '',
  date: (new Date).toLocaleString(),
  specialist: '',
  //
  healthCheckRating: 2,
  //
  discharge: {
    date: '',
    criteria: '',
  } as Discharge,
  //
  employerName: '',
  sickLeave: {
    startDate: '',
    endDate: '',
  } as unknown as SickLeave,
  //
  diagnosisCodes: [] as Array<Diagnosis['code']>,
};
