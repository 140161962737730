/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, createContext, useReducer, Dispatch, useEffect, useRef, useMemo } from 'react';
import { ColorSchemeName, Dimensions } from 'react-native';
import {
  NavigationContainer,
  LinkingOptions,
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
  Portal,
} from 'react-native-paper';
import BottomSheet, { BottomSheetView } from '@gorhom/bottom-sheet';
import merge from 'deepmerge';

const lightTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
const darkTheme = merge(PaperDarkTheme, NavigationDarkTheme);

export enum eAppNativeActions {
  SET_MODE, SET_BOTTOM_SHEET_NODE, SET_BOTTOM_SHEET_HEIGHT,
}

interface SetModeAction {
  type: typeof eAppNativeActions.SET_MODE;
  colorSchemeName: ColorSchemeName;
}

interface SetBottomSheetNodeAction {
  type: typeof eAppNativeActions.SET_BOTTOM_SHEET_NODE;
  bottomSheetNode?: ReactNode;
}

interface SetBsHeightAction {
  type: typeof eAppNativeActions.SET_BOTTOM_SHEET_HEIGHT;
  bsHeight: number;
}

export type AppNativeActionTypes = SetModeAction | SetBottomSheetNodeAction | SetBsHeightAction;

export interface IAppNativeState {
  colorSchemeName: ColorSchemeName;
  bottomSheetNode?: ReactNode;
  bsHeight: number;
}

const initialState: IAppNativeState = {
  colorSchemeName: 'light',
  bsHeight: 0,
}

export interface IAppNativeContext {
  nativeState: IAppNativeState;
  dispatch: Dispatch<any>;
  setMode: (colorSchemeName: ColorSchemeName) => void; // TODO fpaq Add types for returned user...
  setBottomSheetNode: (bottomSheetNode?: ReactNode) => void;
}

export const nativeStore = createContext<IAppNativeContext>({
  nativeState: initialState,
  dispatch: () => null,
  setMode: () => { throw new Error('Not implemented.'); },
  setBottomSheetNode: () => { throw new Error('Not implemented.'); },
});

function reducer(state: IAppNativeState, action: AppNativeActionTypes) {
  switch (action.type) {
    case eAppNativeActions.SET_MODE: {
      return { ...state, colorSchemeName: action.colorSchemeName };
    }
    case eAppNativeActions.SET_BOTTOM_SHEET_NODE: {
      return { ...state, bottomSheetNode: action.bottomSheetNode };
    }
    case eAppNativeActions.SET_BOTTOM_SHEET_HEIGHT: {
      return { ...state, bsHeight: action.bsHeight };
    }
    default:
      return state;
  }
}

export interface AppNativeProps {
  linkingOptions: LinkingOptions<any>;
  children: React.ReactNode | React.ReactNode[];
}

export function AppNative(props: AppNativeProps) {
  const [nativeState, dispatch] = useReducer(reducer, initialState);
  const bottomSheetRef = useRef<BottomSheet>(null);
  const snapPoints = useMemo(() => ['75%'], []);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      // console.log('>>> NEW H:', Math.max(window.height, screen.height))
      dispatch({
        type: eAppNativeActions.SET_BOTTOM_SHEET_HEIGHT,
        bsHeight: Math.max(window.height, screen.height),
      })
    });

    return () => subscription?.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bottomSheetRef.current != null && nativeState.bottomSheetNode == null) {
      bottomSheetRef.current.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomSheetRef.current, nativeState.bottomSheetNode])

  function setMode(colorSchemeName: ColorSchemeName) {
    dispatch({ type: eAppNativeActions.SET_MODE, colorSchemeName });
  }

  function setBottomSheetNode(bottomSheetNode?: ReactNode) {
    dispatch({ type: eAppNativeActions.SET_BOTTOM_SHEET_NODE, bottomSheetNode });
  }

  return <nativeStore.Provider value={{ nativeState, dispatch, setMode, setBottomSheetNode }}>
    <PaperProvider theme={nativeState.colorSchemeName === 'dark' ? darkTheme : lightTheme}>
      <Portal>
        <NavigationContainer linking={props.linkingOptions} theme={nativeState.colorSchemeName === 'dark' ? darkTheme : lightTheme}>
          {props.children}
          {nativeState.bottomSheetNode != null && <BottomSheet
            ref={bottomSheetRef}
            index={nativeState.bottomSheetNode != null ? snapPoints.length - 1 : -1}
            snapPoints={snapPoints}
            containerHeight={nativeState.bsHeight}
          >
            <BottomSheetView>
              {nativeState.bottomSheetNode}
            </BottomSheetView>
          </BottomSheet>}
        </NavigationContainer>
      </Portal>
    </PaperProvider>
  </nativeStore.Provider>
}
