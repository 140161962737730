/**
 * If you are not familiar with React Navigation, refer to the 'Fundamentals' guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React, { useContext, useEffect } from 'react';
import { useTheme } from 'react-native-paper';
import { Pressable } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useNavigation } from '@react-navigation/native';
//
import { PatientList, PatientDetail } from '@eksoh/shared/ui-expo';
import { authStore } from '@eksoh/shared/ui';
// import Colors from '@fe_shared/constants/colors';
// import useColorScheme from '../hooks/useColorScheme';
import Welcome from '../unauth/Welcome';
import LoginScreen from '../unauth/LoginScreen';

import GetLoginInfo from '../unauth/GetLoginInfo';
import ExtraLoginInfo from '../unauth/ExtraLoginInfo';

import Home from '../screens/Home';
import Profile from '../screens/Profile';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
// tmp
import TabApptmnt from '../screens/TabApptmnt';
import TabGeoloc from '../screens/TabGeoloc';
import TabChat from '../screens/TabChat';
import TabQrCode from '../screens/TabQrCode';

// Onboarding
import NeedPhone from '../onbding/NeedPhone';
import Availabilities from '../onbding/Availabilities';
import AvailabilityTimish from '../onbding/AvailabilityTimish';
import MissionType from '../onbding/MissionType';
import MissionRadius from '../onbding/MissionRadius';
import DisplType from '../onbding/DisplType';
import NurseType from '../onbding/NurseType';
import Languages from '../onbding/Languages';
import Upload from '../onbding/Upload';
import Permits from '../onbding/Permits';
import ValidateAccount from '../onbding/ValidateAccount';
import Onboarding1PostInterview from '../onbding/Onboarding1PostInterview';
import Onboarding2CardsScan from '../onbding/Onboarding2CardsScan';
import Onboarding3EmployeeInfo from '../onbding/Onboarding3EmployeeInfo';
import Onboarding4Videos from '../onbding/Onboarding4Videos';
import NotYetVetted from '../onbding/NotYetVetted';

// nurse
import NurseDashboard from '../nurse/Dashboard';
import Emr from '../nurse/Emr';
import PatientSearch from '../nurse/PatientSearch';
import MakeSchedule from '../nurse/MakeSchedule';

// client
import ClientDashboard from '../client/Dashboard';

// cms
import CmsDashboard from '../cms/Dashboard';

// residence
import ResidenceDashboard from '../residence/Dashboard';

import {
  RootStackParamList, RootTabParamList, RootTabScreenProps,
  UnauthStackParamList, RootStackScreenProps,
} from '../types';

export default function Navigation({ isSignedIn }: { isSignedIn: boolean }) {
  return  isSignedIn ? <AuthNavigator /> : <UnauthNavigator />
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const AuthStack = createNativeStackNavigator<RootStackParamList>();
const UnauthStack = createNativeStackNavigator<UnauthStackParamList>();

function UnauthNavigator() {
  return <UnauthStack.Navigator screenOptions={{ headerShown: false }} initialRouteName='Welcome'>
    <UnauthStack.Screen name='Welcome' component={Welcome} options={{ title: 'flowrence' }} />
    <UnauthStack.Screen name='Login' component={LoginScreen} options={{ title: 'login' }} />
    <UnauthStack.Screen name='GetLoginInfo' component={GetLoginInfo} options={{ headerShown: false }} />
    <UnauthStack.Screen name='ExtraLoginInfo' component={ExtraLoginInfo} options={{ headerShown: false }} />
    {/* TODO @fp - is this the bast and safest way?? Maybe do a component only for redirect... */}
    <UnauthStack.Screen name='Onboarding1PostInterview' component={Onboarding1PostInterview} options={{ title: 'almost there!' }} />
    <UnauthStack.Screen name='Onboarding3EmployeeInfo' component={Onboarding3EmployeeInfo} options={{ title: 'almost there!' }} />
  </UnauthStack.Navigator>
}

function AuthNavigator() {
  const { authState } = useContext(authStore);
  const { navigate } = useNavigation();

  useEffect(() => {
    if (authState.isSignedIn) navigate('Root');
  }, [authState.isSignedIn, navigate]);

  return <AuthStack.Navigator screenOptions={{ headerShown: false }} initialRouteName='Root'>
    <AuthStack.Screen name='Root' component={Home} options={{ headerShown: false }} />
    {/* onboarding */}
    <AuthStack.Screen name='NeedPhone' component={NeedPhone} options={{ headerShown: false }} />
    <AuthStack.Screen name='Availabilities' component={Availabilities} options={{ headerShown: false }} />
    <AuthStack.Screen name='AvailabilityTimish' component={AvailabilityTimish} options={{ headerShown: false }} />
    <AuthStack.Screen name='MissionType' component={MissionType} options={{ headerShown: false }} />
    <AuthStack.Screen name='MissionRadius' component={MissionRadius} options={{ headerShown: false }} />
    <AuthStack.Screen name='DisplType' component={DisplType} options={{ headerShown: false }} />
    <AuthStack.Screen name='NurseType' component={NurseType} options={{ headerShown: false }} />
    <AuthStack.Screen name='Languages' component={Languages} options={{ headerShown: false }} />
    <AuthStack.Screen name='Upload' component={Upload} options={{ headerShown: false }} />
    <AuthStack.Screen name='Permits' component={Permits} options={{ headerShown: false }} />
    <AuthStack.Screen name='ValidateAccount' component={ValidateAccount} options={{ title: 'bienvenue' }} />
    <AuthStack.Screen name='Onboarding1PostInterview' component={Onboarding1PostInterview} options={{ title: 'almost there!' }} />
    <AuthStack.Screen name='Onboarding2CardsScan' component={Onboarding2CardsScan} options={{ title: 'almost there!' }} />
    <AuthStack.Screen name='Onboarding3EmployeeInfo' component={Onboarding3EmployeeInfo} options={{ title: 'almost there!' }} />
    <AuthStack.Screen name='Onboarding4Videos' component={Onboarding4Videos} options={{ title: 'almost there!' }} />
    <AuthStack.Screen name='NotYetVetted' component={NotYetVetted} options={{ title: 'come back later!' }} />
    {/* nurse screens */}
    <AuthStack.Screen name='NurseDashboard' component={NurseDashboard} options={{ title: 'welcome to Flowrence' }} />
    <AuthStack.Screen name='MakeSchedule' component={MakeSchedule} options={{ title: 'build your schedule' }} />
    {/* emr */}
    <AuthStack.Screen name='PatientList' component={PatientList<RootStackScreenProps<typeof PatientList>>} options={{ title: 'emr' }} />
    <AuthStack.Screen name='PatientDetail' component={PatientDetail<RootStackScreenProps<typeof PatientDetail>>} options={{ title: 'emr' }} />
    <AuthStack.Screen name='PatientSearch' component={PatientSearch} options={{ title: 'patients' }} />
    <AuthStack.Screen name='Emr' component={Emr} options={{ title: 'notes' }} />
    {/* client screens */}
    <AuthStack.Screen name='ClientDashboard' component={ClientDashboard} options={{ title: 'welcome to Flowrence' }} />
    {/* cms screens */}
    <AuthStack.Screen name='CmsDashboard' component={CmsDashboard} options={{ title: 'welcome to Flowrence' }} />
    {/* residence screens */}
    <AuthStack.Screen name='ResidenceDashboard' component={ResidenceDashboard} options={{ title: 'welcome to Flowrence' }} />
    {/* debug */}
    <AuthStack.Screen name='Debug' component={BottomTabNavigator} options={{ headerShown: false }} />
    {/* all */}
    <AuthStack.Screen name='NotFound' component={NotFoundScreen} options={{ title: 'oops!' }} />
    <AuthStack.Group screenOptions={{ presentation: 'modal' }}>
      <AuthStack.Screen name='Modal' component={ModalScreen} />
    </AuthStack.Group>
    <AuthStack.Group screenOptions={{ presentation: 'modal' }}>
      <AuthStack.Screen name='Profile' component={Profile} />
    </AuthStack.Group>
    {/* TODO @fp - is this the bast and safest way?? Maybe do a component only for redirect... */}
    <AuthStack.Screen name='Login' component={LoginScreen} options={{ title: 'login' }} />
  </AuthStack.Navigator>
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const { authState } = useContext(authStore);
  const { colors } = useTheme();
  // const colorScheme = useColorScheme();

  return <BottomTab.Navigator
    initialRouteName='TabApptmnt'
    screenOptions={{
      tabBarActiveTintColor: colors.onSurface, // Colors[colorScheme].tint,
    }}
  >
    <BottomTab.Screen
      name='TabApptmnt'
      component={TabApptmnt}
      options={({ navigation }: RootTabScreenProps<'TabApptmnt'>) => ({
        title: 'find apptmnt',
        tabBarIcon: ({ color }) => <TabBarIcon name='calendar' color={color} />,
        headerRight: () => (
          <Pressable
            onPress={() => navigation.navigate('Profile')}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <FontAwesome
              name='user'
              size={25}
              color={colors.text /* Colors[colorScheme].text */}
              style={{ marginRight: 15 }}
            />
          </Pressable>
        ),
      })}
    />
    <BottomTab.Screen
      name='TabGeoloc'
      component={TabGeoloc}
      options={({ navigation }: RootTabScreenProps<'TabGeoloc'>) => ({
        title: 'estoy aqui',
        tabBarIcon: ({ color }) => <TabBarIcon name='map-marker' color={color} />,
        headerRight: () => (
          <Pressable
            onPress={() => navigation.navigate('Modal')}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <FontAwesome
              name='info-circle'
              size={25}
              color={colors.text /* Colors[colorScheme].text */}
              style={{ marginRight: 15 }}
            />
          </Pressable>
        ),
      })}
    />
    <BottomTab.Screen
      name='TabChat'
      component={TabChat}
      options={{
        title: authState.chatInfo?.myUsername || 'chat',
        tabBarIcon: ({ color }) => <TabBarIcon name='wechat' color={color} />,
      }}
    />
    <BottomTab.Screen
      name='TabQrCode'
      component={TabQrCode}
      options={{
        title: 'qr code',
        tabBarIcon: ({ color }) => <TabBarIcon name='qrcode' color={color} />,
      }}
    />
  </BottomTab.Navigator>
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
