export const langs = [
  'fr',
  'en',
  'fr_CA',
  'en_CA',
  'fr_US',
  'en_US',
]

export const schema = {
  type: 'object',
  properties: {
    patientInfo: {
      type: 'object',
      properties: {
        familyName: { type: 'string', minLength: 2, /* description: '...', */ },
        givenName: { type: 'string', minLength: 3, /* description: '...', */ },
        email: { type: 'string', minLength: 8, /* description: '...', */ },
        birthdate: { type: 'string', format: 'date', },
        language: { type: 'string', enum: langs, },
        mobileNumber: { type: 'string', minLength: 10, /* description: '...', */ },
        phoneNumber: { type: 'string', minLength: 10, /* description: '...', */ },
        fullAddress: { type: 'string', maxLength: 75, /* description: '...', */ },
        insurNumber: { type: 'string', minLength: 12, /* RAMQ for now... */ maxLength: 12, /* RAMQ for now... */ },
        curDoctor: { type: 'string', maxLength: 75, /* description: '...', */ },
        pharmacy: { type: 'string', maxLength: 75, /* description: '...', */ },
        contactName: { type: 'string', maxLength: 75, /* description: '...', */ },
        contactNumber: { type: 'string', minLength: 10, /* description: '...', */ },
      },
    },
    butvalid: {
      type: 'boolean',
    },
  },
}
