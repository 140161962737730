import { ReactNode, useState, useEffect } from 'react';
import { View } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import { SaveFormat } from 'expo-image-manipulator';
import { createWorker } from 'tesseract.js';
//
import { appMaxWidth } from '@eksoh/flo/ui';
import usePickDocument from './usePickDocument';

export const supportedImgTypes: { [key: string]: SaveFormat } = {
  'data:image/png': SaveFormat.PNG,
  'data:image/jpg': SaveFormat.JPEG,
  'data:image/jpeg': SaveFormat.JPEG,
  'data:image/webp': SaveFormat.WEBP,
}

export function getMimeType(base64: string) {
  const idx = base64.indexOf(';');
  return idx !== -1 ? base64.substring(0, idx) : undefined;
}

export interface useOcrProps {
  showPickDoc?: boolean;
  pickDocText?: string;
  autoOcr?: boolean;
}

export default function useOcr({ showPickDoc, pickDocText, autoOcr }: useOcrProps) {
  const [ocrBusy, setOcrBusy] = useState(false);
  const [pdt, setPickDocText] = useState(pickDocText);
  const [progress, setProgress] = useState(0);
  const { doc, docBase64, renderPickDoc, resetDoc } = usePickDocument();
  const ocrDoc = doc;
  const ocrDocBase64 = docBase64;

  useEffect(() => {
    async function processImg() {
      if (doc == null || !autoOcr) return;

      const worker = createOcrWorker();

      setOcrBusy(true);
      setProgress(0);
      await worker.load();
      await worker.loadLanguage('fra');
      await worker.initialize('fra');
      // const { data: { text } } = await worker.recognize('data:image/png;base64,' + img.base64);
      const { data: { text } } = await worker.recognize(doc);
      console.log(text);
      await worker.terminate();
      setOcrBusy(false);
    }
    processImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  async function processBase64(locale: 'eng' | 'fra', img?: string) {
    if (!img) return;

    const worker = createOcrWorker();

    setOcrBusy(true);
    setProgress(0);
    await worker.load();
    await worker.loadLanguage(locale);
    await worker.initialize(locale);
    // const { data: { text } } = await worker.recognize('data:image/png;base64,' + img.base64);
    const { data: { text } } = await worker.recognize(img);
    console.log(text);
    await worker.terminate();
    setOcrBusy(false);
    return text;
  }

  function createOcrWorker() {
    return createWorker({
      // logger: m => console.log(m), // Add logger here
      logger: m => {
        if (m.status === 'recognizing text') setProgress(m.progress)
      },
    });
  }

  async function getBase64FromFile(file: File) {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }

  function dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',');
    const mime = (arr[0].match(/:(.*?);/) || [])[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) { u8arr[n] = bstr.charCodeAt(n); }

    return new File([u8arr], filename, { type: mime });
  }

  function downloadBase64File(contentBase64: string, fileName: string) {
    // const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = contentBase64; // linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function renderOcr(children?: ReactNode | ReactNode[]) {
    return <View style={{ width: '100%' }}>
      {!ocrBusy && children}
      {ocrBusy && <View style={{ display: 'flex', width: '100%', height: 100, marginTop: 12, justifyContent: 'center' }}>
        {/* <ActivityIndicator animating={true} color='grey' /> */}
        <ProgressBar progress={progress} color='#323232' />
      </View>}
      {!ocrBusy && showPickDoc && <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 12 }}>
        {renderPickDoc(pdt)}
      </View>}
    </View>
  }

  return {
    ocrBusy, processBase64, renderOcr, ocrDoc, ocrDocBase64, resetDoc, setPickDocText,
    getBase64FromFile, dataURLtoFile, downloadBase64File,
  };
}
