import { ConsultationReason } from './ConsultationData';
import { schema as vitalSigns } from './VitalSigns';

const reasonEnum = Object.keys(ConsultationReason);

export const schema = {
  type: 'object',
  properties: {
    consultations: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          date: { type: 'string', format: 'date', default: new Date().toISOString().slice(0, 10) },
          place: { type: 'string' },
          reason: { type: 'string', enum: reasonEnum },
          otherReason: { type: 'string' },
          subjective: { type: 'string', minLength: 2, /* description: '...', */ },
          objective: { type: 'string', minLength: 2, /* description: '...', */ },
          vitalSigns,
          labResults: { type: 'string', minLength: 2, /* description: '...', */ },
          diagnostic: { type: 'string', minLength: 2, /* description: '...', */ },
          treatmentPlan: { type: 'string', minLength: 2, /* description: '...', */ },
          prescriber: { type: 'string', minLength: 2, /* description: '...', */ },
        },
        additionalProperties: {
          label: 'date',
          subLabel: 'place',
        }
      },
    },
  },
}
