// import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValid } from 'date-fns';
import { useFormik } from 'formik';
//
import { Entry, EmrEntryInitialValues, EntryFormValues } from '@eksoh/shared/common';
import { useClientEmrStore } from '@eksoh/shared/ui';
import { DiagnosisSelection } from '../cmps/FormFields';

const isValidDate = (x: unknown): boolean => (
  isValid(typeof x === 'string' ? new Date(x) : x)
);

const entryTypeOptions: { value: Entry['type']; label: string }[] = [
  { value: 'HealthCheck', label: 'Health check' },
  { value: 'OccupationalHealthcare', label: 'Occupational healthcare' },
  { value: 'Hospital', label: 'Hospital' },
];

interface Props {
  onSubmit: (values: EntryFormValues) => void;
  onCancel: () => void;
}

export function AddEntryForm({ onSubmit, onCancel }: Props) {
  const { clientEmrState } = useClientEmrStore();
  // const currentValidation = validations[0];
  const formik = useFormik<EntryFormValues>({
    initialValues: EmrEntryInitialValues as EntryFormValues,
    // validationSchema: currentValidation,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      console.log('AddEntryForm:', JSON.stringify(values, null, 2));
      onSubmit(values);
    },
    validate: values => {
      const requiredError = 'Field is required';
      const errors: { [field: string]: ({ [subfield: string]: string } | string) } = {};

      // Basic mandatory
      if (!values.description) { errors.description = requiredError; }
      if (!values.specialist) { errors.specialist = requiredError; }

      if (!values.date) { errors.date = requiredError; }
      else if (!isValidDate(values.date)) {
        errors.date = 'Wrong format. Bizarre!';
      }

      // Specific fields
      switch (values.type) {
        case 'HealthCheck':
          if (values.healthCheckRating == null) { errors.healthCheckRating = requiredError; }
          else if (
            values.healthCheckRating != null &&
            (values.healthCheckRating < 0 || values.healthCheckRating > 3)
          ) {
            errors.healthCheckRating = 'The value must be in range 0-3';
          }
          break;

        case 'Hospital':
          if (!values.discharge?.criteria) { errors.discharge = { criteria: requiredError }; }
          if (!values.discharge?.date) { errors.discharge = { date: requiredError }; }
          else if (!isValidDate(values.discharge.date)) {
            errors.discharge = { date: 'Wrong format. Bizarre!' };
          }
          break;

        case 'OccupationalHealthcare':
          if (!values.employerName) { errors.employerName = requiredError; }

          if (values.sickLeave?.startDate && !isValidDate(values.sickLeave?.startDate)) {
            errors.sickLeave = { startDate: 'Wrong format. Bizarre!' };
          }

          if (values.sickLeave?.endDate && !isValidDate(values.sickLeave?.endDate)) {
            errors.sickLeave = { endDate: 'Wrong format. Bizarre!' };
          }

          break;
      }

      return errors;
    },
  });

  // useEffect(() => {
  //   console.log('>>> FORMIK:', formik);
  //   console.log('>>> FORMIK VALUES:', formik.values);
  //   console.log('>>> FORMIK TOUCHED:', formik.touched);
  //   console.log('>>> FORMIK ERRORS:', formik.errors);
  // }, [formik])

  function getError(key1: string, key2: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const touObj = (formik.touched as any)[key1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errObj = (formik.errors as any)[key1];
    return touObj != null && touObj[key2] && errObj != null && errObj[key2] != null && Boolean(errObj[key2]);
  }

  function getErrorTxt(key1: string, key2: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errObj = (formik.errors as any)[key1];
    return errObj != null && errObj[key2] != null && errObj[key2];
  }

  return <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id='entry-type-helper-label'>Entry type</InputLabel>
          <Select
            required
            id='type'
            name='type'
            label='Entry type'
            labelId='entry-type-helper-label'
            value={formik.values.type}
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldValue('type', event.target.value);
              formik.setFieldTouched('type', true);
            }}
          >
            {entryTypeOptions.map(({ value, label }, i) => (
              <MenuItem key={`entry_${i}`} value={value}>{label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.type && formik.errors.type}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='description'
          name='description'
          label='Description'
          fullWidth
          variant='standard'
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          // openTo='year' views={['year', 'month', 'day']}
          // label={tLabel2(t, 'userManagement.dob', true)} disableFuture
          label='Date'
          value={formik.values.date || null} // onChange={formik.handleChange}
          onChange={(value: string | null, keyboardInputValue?: string) => {
            if (value == null || value === 'Invalid Date') return;
            formik.setFieldTouched('date', true);
            formik.setFieldValue('date', value);
          }}
          // renderInput={(props) => <TextField {...props} onBlur={onBlur} style={{ flexGrow: 1 }} />}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderInput={(props: any) => <TextField {...props} style={{ flexGrow: 1 }} />}
          // error={formik.touched.date && Boolean(formik.errors.date)}
          // helperText={formik.errors.date}
        />
        {formik.touched.date && Boolean(formik.errors.date) && <Typography color='error' variant='body1'>
          {formik.errors.date}
        </Typography>}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='specialist'
          name='specialist'
          label='Specialist'
          fullWidth
          variant='standard'
          value={formik.values.specialist}
          onChange={formik.handleChange}
          error={formik.touched.specialist && Boolean(formik.errors.specialist)}
          helperText={formik.touched.specialist && formik.errors.specialist}
        />
      </Grid>
      {formik.values.type === 'HealthCheck' && <Grid item xs={12} display='flex' flexDirection='column' textAlign='center'>
        <Typography variant='overline'>Health Check Rating</Typography>
        <ToggleButtonGroup
          size='small'
          style={{ justifyContent: 'center' }}
          exclusive
          value={formik.values.healthCheckRating}
          onChange={(e, v) => {
            formik.setFieldTouched('healthCheckRating', true);
            formik.setFieldValue('healthCheckRating', v);
          }}
          // error={formik.touched.healthCheckRating && Boolean(formik.errors.healthCheckRating)}
          // helperText={formik.touched.healthCheckRating && formik.errors.healthCheckRating}
        >
          <ToggleButton value={0}>
            <Avatar>0</Avatar>
          </ToggleButton>,
          <ToggleButton value={1}>
            <Avatar>1</Avatar>
          </ToggleButton>,
          <ToggleButton value={2}>
            <Avatar>2</Avatar>
          </ToggleButton>,
          <ToggleButton value={3}>
            <Avatar>3</Avatar>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>}
      {formik.values.type === 'Hospital' && <>
        <Grid item xs={12} sm={6}>
          <DatePicker
            // openTo='year' views={['year', 'month', 'day']}
            // label={tLabel2(t, 'userManagement.dob', true)} disableFuture
            label='Discharge Date'
            value={formik.values.discharge?.date || null} // onChange={formik.handleChange}
            onChange={(value: string | null, keyboardInputValue?: string) => {
              if (value == null || value === 'Invalid Date') return;
              formik.setFieldTouched('discharge.date', true);
              formik.setFieldValue('discharge.date', value);
            }}
            // renderInput={(props) => <TextField {...props} onBlur={onBlur} style={{ flexGrow: 1 }} />}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(props: any) => <TextField {...props} style={{ flexGrow: 1 }} />}
            // error={formik.touched.date && Boolean(formik.errors.date)}
            // helperText={formik.errors.date}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {formik.errors.discharge && (formik.errors.discharge as any).date && <Typography color='error' variant='body1'>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {(formik.errors.discharge as any).date}
          </Typography>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='discharge.criteria'
            name='discharge.criteria'
            label='Discharge Criteria'
            fullWidth
            variant='standard'
            value={formik.values.discharge?.criteria}
            onChange={formik.handleChange}
            error={Boolean(getError('discharge', 'criteria'))}
            helperText={getErrorTxt('discharge', 'criteria')}
            // error={formik.touched.discharge && Boolean(formik.errors.discharge)}
            // helperText={formik.errors.discharge ? (formik.errors.discharge as any).criteria : undefined}
          />
        </Grid>
      </>}
      {formik.values.type === 'OccupationalHealthcare' && <>
        <Grid item xs={12}>
          <TextField
            required
            id='employerName'
            name='employerName'
            label='Employer Name'
            fullWidth
            // autoComplete='family-name'
            variant='standard'
            value={formik.values.employerName}
            onChange={formik.handleChange}
            error={formik.touched.employerName && Boolean(formik.errors.employerName)}
            helperText={formik.touched.employerName && formik.errors.employerName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            // openTo='year' views={['year', 'month', 'day']}
            // label={tLabel2(t, 'userManagement.dob', true)} disableFuture
            label='Sick Leave Start Date'
            value={formik.values.sickLeave?.startDate || null} // onChange={formik.handleChange}
            onChange={(value: string | null, keyboardInputValue?: string) => {
              if (value == null || value === 'Invalid Date') return;
              formik.setFieldTouched('sickLeave.startDate', true);
              formik.setFieldValue('sickLeave.startDate', value);
            }}
            // renderInput={(props) => <TextField {...props} onBlur={onBlur} style={{ flexGrow: 1 }} />}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(props: any) => <TextField {...props} style={{ flexGrow: 1 }} />}
            // error={formik.touched.date && Boolean(formik.errors.date)}
            // helperText={formik.errors.date}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {formik.errors.sickLeave && (formik.errors.sickLeave as any).startDate && <Typography color='error' variant='body1'>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {(formik.errors.sickLeave as any).startDate}
          </Typography>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            // openTo='year' views={['year', 'month', 'day']}
            // label={tLabel2(t, 'userManagement.dob', true)} disableFuture
            label='Sick Leave End Date'
            value={formik.values.sickLeave?.endDate || null} // onChange={formik.handleChange}
            onChange={(value: string | null, keyboardInputValue?: string) => {
              if (value == null || value === 'Invalid Date') return;
              formik.setFieldTouched('sickLeave.endDate', true);
              formik.setFieldValue('sickLeave.endDate', value);
            }}
            // renderInput={(props) => <TextField {...props} onBlur={onBlur} style={{ flexGrow: 1 }} />}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderInput={(props: any) => <TextField {...props} style={{ flexGrow: 1 }} />}
            // error={formik.touched.sickLeave && Boolean(formik.errors.sickLeave)}
            // helperText={formik.touched.sickLeave && formik.errors.sickLeave}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {formik.errors.sickLeave && (formik.errors.sickLeave as any).endDate && <Typography color='error' variant='body1'>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {(formik.errors.sickLeave as any).endDate}
          </Typography>}
        </Grid>
      </>}
      <Grid item xs={12}>
        <DiagnosisSelection
          value={formik.values.diagnosisCodes || []}
          onChange={(event: SelectChangeEvent<string[]>) => {
            const { target: { value } } = event;
            const newVal = typeof value === 'string' ? value.split(',') : value;
            formik.setFieldTouched('diagnosisCodes', true);
            formik.setFieldValue('diagnosisCodes', newVal);
          }}
          diagnoses={clientEmrState.diagnoses}
          // error={formik.touched.diagnosisCodes && Boolean(formik.errors.diagnosisCodes)}
          // helperText={formik.touched.diagnosisCodes && formik.errors.diagnosisCodes}
        />
      </Grid>
      <Grid item xs={12} textAlign='end'>
        <Button onClick={onCancel}>cancel</Button>
        <Button type='submit'>save</Button>
      </Grid>
    </Grid>
  </form>
}
