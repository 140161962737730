import { Avatar, Banner, Dialog } from 'react-native-paper';
//
import { EntryFormValues } from '@eksoh/shared/common';
import { AddEntryForm } from './AddEntryForm';

interface Props {
  modalOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EntryFormValues) => void;
  error?: string;
}

export function AddEntryModal({ modalOpen, onClose, onSubmit, error }: Props) {
  return <Dialog visible={modalOpen} onDismiss={onClose}>
    <Dialog.Title>Add a new entry</Dialog.Title>
    <Dialog.Content>
      <AddEntryForm onSubmit={onSubmit} onCancel={onClose} />
    </Dialog.Content>
    <Banner
        visible={error != null}
        actions={[
          // {
          //   label: 'Fix it',
          //   onPress: () => setVisible(false),
          // },
          // {
          //   label: 'Close',
          //   onPress: () => setError(undefined),
          // },
        ]}
        icon={({ size }: {size: number }) => <Avatar.Icon size={size} icon='alert-circle' />}
      >
        {error || 'Unknown error.'}
      </Banner>
  </Dialog>
}
