export const schemaUI = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'GridLayout',
      config: {
        mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
      },
      elements: [
        {
          type: 'Control', // 'ListAccordion',
          scope: '#/properties/medicalSummary/properties/medications',
        },
        {
          type: 'Control', // 'ListAccordion',
          scope: '#/properties/medicalSummary/properties/allergyIntolerance',
        },
      ],
    },
    {
      type: 'Label',
      text: 'medicalSummary.activeIssues.title',
    },
    {
      type: 'GridLayout',
      config: {
        mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/medicalSummary/properties/activeIssues/properties/medical',
        },
        {
          type: 'Control',
          scope: '#/properties/medicalSummary/properties/activeIssues/properties/surgical',
        },
        {
          type: 'Control',
          scope: '#/properties/medicalSummary/properties/activeIssues/properties/psychiatric',
        },
      ],
    },
    {
      type: 'Label',
      text: 'medicalSummary.habits.title',
    },
    {
      type: 'GridLayout',
      config: {
        mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/medicalSummary/properties/habits/properties/tobacco',
          options: { format: 'radio' },
        },
        {
          type: 'Control',
          scope: '#/properties/medicalSummary/properties/habits/properties/alcohol',
          options: { format: 'radio' },
        },
        {
          type: 'Control',
          scope: '#/properties/medicalSummary/properties/habits/properties/physActivities',
          options: { format: 'radio' },
        },
      ],
    },
    {
      type: 'Control',
      scope: '#/properties/butvalid',
    },
  ],
}

// 

export const medicationsUI = {
  type: 'Control', // 'ListAccordion',
  scope: '#/properties/medicalSummary/properties/medications',
}

export const allergyIntoleranceUI = {
  type: 'Control', // 'ListAccordion',
  scope: '#/properties/medicalSummary/properties/allergyIntolerance',
}

export const activeIssuesUI = {
  type: 'GridLayout',
  config: {
    mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
  },
  elements: [
    {
      type: 'Control',
      scope: '#/properties/medical',
    },
    {
      type: 'Control',
      scope: '#/properties/surgical',
    },
    {
      type: 'Control',
      scope: '#/properties/psychiatric',
    },
  ],
}

export const habitsUI = {
  type: 'GridLayout',
  config: {
    mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
  },
  elements: [
    {
      type: 'Control',
      scope: '#/properties/tobacco',
      options: { format: 'radio' },
    },
    {
      type: 'Control',
      scope: '#/properties/alcohol',
      options: { format: 'radio' },
    },
    {
      type: 'Control',
      scope: '#/properties/physActivities',
      options: { format: 'radio' },
    },
  ],
}
