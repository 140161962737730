import React from 'react';
import { View } from 'react-native';
import { Button, Card } from 'react-native-paper';
//
import { FFLayout } from './layout';

export interface FFBooleanProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  onSelect: (selected: boolean) => void;
  onBack?: () => void;
}

export function FFBoolean(props: FFBooleanProps) {
  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      onBack={props.onBack}
      action={<Card.Actions>
        <Button mode='outlined' style={{ width: '40%', margin: '5%' }} onPress={() => props.onSelect(false)}>
          {{ en: 'no', fr: 'non' }.fr}
        </Button>
        <Button mode='outlined' style={{ width: '40%', margin: '5%' }} onPress={() => props.onSelect(true)}>
          {{ en: 'yes', fr: 'oui' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
