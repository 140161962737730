import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { Surface, Avatar, TextInput } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
//
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import { RootStackScreenProps } from '../types';
import { scale } from '../Scaling';
import Logo from '../cmps/Logo';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import { ValidationCode } from '../cmps/ValidationCode';
import TermsAndConditions from '../cmps/TermsConditions';
import { awsPhoneNumber, validateNorthAmericaPhoneNumber, ONE_DAY_IN_MILLISECONDS } from '@eksoh/shared/common';

// This component makes sure that if you signed up with a social account (google, facebook),
// if we get the phone number from your social account, it is the right one to use for
// sending sms notifications.
//
// this component is also used to accept terms and conditions.
//
// so maybe change the name of the component/file one day...

export default function NeedPhone({ navigation }: RootStackScreenProps<'NeedPhone'>) {
  const { authState, onboarding, signOut } = useContext(authStore);
  const [phone, setPhone] = useState('');
  const [showCode, setShowCode] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.needsMFA) setShowCode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.needsMFA]);

  useEffect(() => {
    if (!isFocused) return;

    if (
      authState.onboarding?.phone != null &&
      authState.user?.phoneNumberVerified === true &&
      authState.onboarding?.started?.agreed === true
    ) {
      navigation.push('NurseType');
      return;
    }

    if (authState.onboarding?.phone != null) {
      if (authState.onboarding?.phoneValid?.codePhoneSent != null) {
        const diff = (new Date()).getTime() - (new Date(authState.onboarding.phoneValid.codePhoneSent)).getTime();
        if (diff < ONE_DAY_IN_MILLISECONDS) { // < 24 hours
          setShowCode(true);
        } else if (authState.onboarding.phone != null) {
           setPhone(authState.onboarding.phone);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  function validateNext() {
    return authState.user?.email == null || !phone || !validateNorthAmericaPhoneNumber(phone);
  }

  async function next() {
    try {
      const phone_number = awsPhoneNumber(phone);
      onboarding({
        phone: phone_number,
        phoneValid: {
          codePhoneSent: new Date()
        },
      });
      await Auth.updateUserAttributes(authState.cognitoUser, { phone_number });
    }
    catch (error) {
      // TODO: handle me!!
      console.log('>>> error:', error);
    }
  }

  async function accept() {
    const credentials = await Auth.currentCredentials();
    onboarding({
      email: authState.user?.email,
      started: {
        agreed: true, 
        tosDate: (new Date()).getTime(),
        identityId: credentials.identityId,
        username: authState.cognitoUser?.getUsername() || 'NO_USERNAME'
    }});
  }

  if (authState.onboarding?.started?.agreed == null) {
    return <TermsAndConditions
      onAccepted={() => accept()}
    />
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={2} onClose={() => navigation.push('NotYetVetted')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <Logo style={{ marginTop: scale(1) }} width={60} height={60} />
      <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Text style={{ color: '#A5A5A5' }}>{t('nurse.loginPhoneNeeded')}</Text>
      </Surface>
      <View style={{ width: '100%', marginTop: 1 }}>
        <TextInput
          mode='outlined' label={t('login.form.mobile')} placeholder={t('login.form.mobile-placeholder')}
          value={phone} onChangeText={text => setPhone(text)}
          render={props => <MaskedTextInput
            value={props.value} mask='+1 (999) 999-9999' style={props.style}
            onChangeText={(t, r) => { if (props.onChangeText) props.onChangeText(r); }}
          />}
        />
      </View>
    </ScrollView>
    <OnboardingFooter
      butText='login.form.verify'
      overrideBackText='shorts.quit' disabledBut={validateNext()} hideLater
      onBack={() => signOut()} onNext={next}
    />
    <ValidationCode
      show={showCode} fromSignUp={false} onTryAgain={() => next()}
      onDone={() => setShowCode(false)} onError={() => setShowCode(false)}
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 24,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
