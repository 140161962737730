import { DateTime } from "luxon";
import { Appointment } from "./generated/graphql";
import { MockBe } from "./mock";
//


export class ScheduleCommonUtils {

  static isCancelFeeApplies(appmt?: Appointment | null) {
    return appmt && DateTime.fromISO(appmt.start).diffNow('days').as('days') < 1;
    // return true;
  }

  static async getComputeCancelFees(appmt: Appointment) {
    // TODO: put cancel fees in appoint in DB, and only check the date (remove the service call, in event for example)
    
    let fees = 0;
    if (this.isCancelFeeApplies(appmt)) {
      // less than one day, there are fees to be apply
      fees = (await MockBe.toItemFeesByCode(appmt.code)).price;
    }
    return fees;
  }

  static appmtToString(a: Appointment) {
    return ` lng: ${a.lng}, lat ${a.lat}, code ${a.code}, username ${a.username}, start: ${a.start}`;
  }

}
