import { DateTime } from "luxon"
import { TZ_AMERICA_NEW_YORK } from "../constants"

export const montrealCoords: GeolocationCoordinates = {
  accuracy: 1,
  latitude: 45.508888,
  longitude: -73.561668,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  speed: null,
}

export const montreal = {
  country: 'Canada',
  latitude: 45.508888,
  longitude: -73.561668,
  dmsLat: "45° 30' 31.9968'' N",
  dmsLong: "73° 33' 42.0048'' W",
  utmEasting: 612354.40,
  utmNorthing: 5040490.06,
  category: 'cities',
  countryCode: 'CA',
}

export const chiangmai = {
  country: 'Thailand',
  latitude: 18.789154,
  longitude: 98.985313,
  // dmsLat: "45° 30' 31.9968'' N",
  // dmsLong: "73° 33' 42.0048'' W",
  // utmEasting: 612354.40,
  // utmNorthing: 5040490.06,
  category: 'cities',
  countryCode: 'TH'
}

export const bangkok = {
  country: 'Thailand',
  latitude: 13.737233,
  longitude: 100.562375,
  category: 'cities',
  countryCode: 'TH'
}

export const tokyo = {
  country: 'Japan',
  latitude: 35.689487,
  longitude: 139.691706,
  // dmsLat: "45° 30' 31.9968'' N",
  // dmsLong: "73° 33' 42.0048'' W",
  // utmEasting: 612354.40,
  // utmNorthing: 5040490.06,
  category: 'cities',
  countryCode: 'JP',
  tz: 'Asia/Tokyo'
}

export const newyork = {
  country: 'USA',
  latitude: 40.760010,
  longitude: -73.982718,
  // dmsLat: "45° 30' 31.9968'' N",
  // dmsLong: "73° 33' 42.0048'' W",
  // utmEasting: 612354.40,
  // utmNorthing: 5040490.06,
  category: 'cities',
  countryCode: 'US',
  tz: TZ_AMERICA_NEW_YORK
}

// export class ScheduleCommonUtils {

//   public static notifDate(locale: string) {

//   }

// }

export const weekDays: { [key: string]: number } = {
  'sunday': 0, 'monday': 288, 'tuesday': 576, 'wednesday': 864, 'thursday': 1152, 'friday': 1440, 'saturday': 1728,
};

export interface HumanReadableTimeOffer {
  dow: number;
  day: string;
  hour: string;
  fullCal: string;
};

export function toHumanTimeOffer(dateIso: string, locale: string): HumanReadableTimeOffer {
  if (!dateIso) return { dow: 0, day: '', hour: '', fullCal: '' };
  const dt = DateTime.fromISO(dateIso).setLocale(locale);
  const time = dt.toLocaleString(DateTime.TIME_SIMPLE);
  return {
    dow: dt.weekday % 7,
    day: dt.weekdayLong.toLowerCase(),
    hour: time,
    fullCal: time.replace(/\s+/g, '').toLowerCase()
  }
}
