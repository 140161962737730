export function getDiff<T>(o1: T, o2: T): T | null {
  // return Object.keys(o2).reduce((diff, key) => {
  //   if (o1[key] === o2[key]) return diff
  //   return {
  //     ...diff,
  //     [key]: o2[key]
  //   }
  // }, {})

  if (o1 && o2) {
    const keys = Object.keys(o2) as (keyof T)[];
    const diff = keys.reduce((res, key) => {
      if (o2 && o1 && o2[key] !== o1[key]) {
        // res.id = user.id;
        res[key] = o1[key];
      }
      return res;
    }, {} as any);

    return diff;
  }
  return null;
}

// export function getDiff(user: IGenericUserWithID<IUnsafeUser>, orginalUser?: IGenericUserWithID<IUnsafeUser>) {
//   if (user && orginalUser) {
//       const keys = Object.keys(orginalUser) as (keyof IGenericUserWithID<IUnsafeUser>)[];
//       const diff = keys.reduce((res, key) => {
//           if (orginalUser && user && orginalUser[key] !== user[key]) {
//               res.id = user.id;
//               res[key] = user[key];
//           }
//           return res;
//       }, {} as any);

//       return diff;
//   }

//   return null;
