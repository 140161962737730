import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
//
import { UserInputs } from '../../../..';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getUsersAsAdmin } from './reducer';

export function UserManagementUpdate() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const user = useAppSelector(state => state['userManagement'].user);
  // const loading = useAppSelector(state => state['userManagement'].loading);
  // const updating = useAppSelector(state => state['userManagement'].updating);
  const updateSuccess = useAppSelector(state => state['userManagement'].updateSuccess);
  // const groups = useAppSelector(state => state['userManagement'].groups);
  // const errorMessage = useAppSelector(state => state['userManagement'].errorMessage);

  useEffect(() => {
    if (updateSuccess) leave(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  function leave(refresh?: boolean) {
    if (refresh) dispatch(getUsersAsAdmin({})); // needs await???
    navigate('/admin');
  }

  return <Box>
    <UserInputs
      isAdmin userId={params.uuid} reducer='userManagement' onCancelled={leave}
      // overrideButtons={(_isDirty: boolean, _isValid: boolean, state: UserInputsState, _saveUser: () => void) => {
      //   setIsDirty(_isDirty); setIsValid(_isValid); setInputStates(state); saveUser.current = _saveUser;
      // }}
      onWaiting={() => null}
    />
  </Box>
}
