// import styles from './generic-modal-web.module.css';
import { useContext } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { AppVersion } from '@eksoh/shared/common';
import { globalStore } from '@eksoh/shared/ui';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
};

//
// ===
//

export interface AboutModalProps {
  open: boolean;
  onClose: () => void;
  appName?: string;
  version?: AppVersion;
}

export function AboutModal({ open, appName, version, onClose }: AboutModalProps) {
  const { globalState } = useContext(globalStore);

  return <BootstrapDialog
    onClose={onClose}
    open={open}
  >
    <BootstrapDialogTitle id='about-modal' onClose={onClose}>{appName || globalState.appName}</BootstrapDialogTitle>
    <DialogContent dividers>
      <Typography gutterBottom>
        version: {version?.code || globalState.appVersion.code}
      </Typography>
      <Typography gutterBottom>
        extra: {version?.gitShortSha || globalState.appVersion.gitShortSha}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose}>close</Button>
    </DialogActions>
  </BootstrapDialog>
}
