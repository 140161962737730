import { FastForm } from '.';

export const timeOffersEdit: FastForm = {
  sections: [
    {
      name: 'start',
      title: 'edit time offer',
      subTitle: 'fast time selector',
      icon: 'clock',
      questions: [
        {
          id: 's1q1.0',
          type: 'butlist',
          text: 'which part of the day can you works?',
          choices: ['am/pm', 'am', 'pm'],
          next: ['s1q1.1', 's1q1.2', 's1q1.3'],
        },
        {
          id: 's1q1.1',
          type: 'timerng',
          text: 'set start and end time',
          min: 7,
          max: 20,
          next: 'end',
        },
        {
          id: 's1q1.2',
          type: 'timerng',
          text: 'set start and end time',
          min: 7,
          max: 12,
          next: 'end',
        },
        {
          id: 's1q1.3',
          type: 'timerng',
          text: 'set start and end time',
          min: 13,
          max: 20,
          next: 'end',
        },
        {
          id: 'end',
          type: 'end',
          text: 'end',
          next: 'end',
          debug: true,
        },
      ],
    },
  ],
};
