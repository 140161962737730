import { ReactNode, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import PaymentIcon from '@mui/icons-material/Payment';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PersonIcon from '@mui/icons-material/Person';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VideocamIcon from '@mui/icons-material/Videocam';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import WorkIcon from '@mui/icons-material/Work';
//
import { OnboardingState, StorageDocumentType } from '@eksoh/flo/model';
import { ComposedIcon } from '../../../../../../ui-web/src';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[5],
  },
}));

const IconWithTooltip = ({ name, children }: { name: string, children: ReactNode }) => <Tooltip title={name}>
  <TooltipHOC>{children}</TooltipHOC>
</Tooltip>

export const docIcons = new Map<StorageDocumentType, ReactNode>([
  [StorageDocumentType.CV, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.CV]}><DescriptionIcon /></IconWithTooltip>],
  [StorageDocumentType.DRIVER_LICENSE, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.DRIVER_LICENSE]}><PaymentIcon /></IconWithTooltip>],
  [StorageDocumentType.OTHER, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.OTHER]}><QuestionMarkIcon /></IconWithTooltip>],
  [StorageDocumentType.VOICE_CV, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.VOICE_CV]}><RecordVoiceOverIcon /></IconWithTooltip>],
  [StorageDocumentType.WORK_CONTRACT, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.WORK_CONTRACT]}><WorkIcon /></IconWithTooltip>],
  [StorageDocumentType.INSUR_CARD, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.INSUR_CARD]}><AccountBalanceIcon /></IconWithTooltip>],
  [StorageDocumentType.NURSE_CARD, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.NURSE_CARD]}><LocalHospitalIcon /></IconWithTooltip>],
  [StorageDocumentType.PHOTO_CARD, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.PHOTO_CARD]}><AccountBoxIcon /></IconWithTooltip>],
  [StorageDocumentType.RCR_CARD, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.RCR_CARD]}><HealthAndSafetyIcon /></IconWithTooltip>],
  [StorageDocumentType.OTHER, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.OTHER]}><QuestionMarkIcon /></IconWithTooltip>],
  [StorageDocumentType.CRIMINAL_CONSENT, <IconWithTooltip name={StorageDocumentType[StorageDocumentType.CRIMINAL_CONSENT]}><GavelIcon /></IconWithTooltip>],
]);

export const ViewStepIcon = () => <IconWithTooltip name='VIEW_STEP'><VisibilityIcon /></IconWithTooltip>

/*
export enum OnboardingState {
  ATCD = 'ATCD',
  ATCD_FAILED = 'ATCD_FAILED',
  ATCD_WAITING = 'ATCD_WAITING',
  COMPLETED = 'COMPLETED',
  EMPLOYEE_INFO = 'EMPLOYEE_INFO',
  INTERVIEW = 'INTERVIEW',
  INTERVIEW_FAILED = 'INTERVIEW_FAILED',
  POST_INTERVIEW = 'POST_INTERVIEW',
  REG_COMPLETED = 'REG_COMPLETED',
  REG_REJECTED = 'REG_REJECTED',
  REG_STARTED = 'REG_STARTED'
}
*/

interface StepIconProps {
  name: string
  size?: number;
  color?: string;
  onClick?: () => void;
}

interface TooltipHOC {
  children: ReactNode;
}
const TooltipHOC = forwardRef<HTMLDivElement, TooltipHOC>((props, ref) => {
  const { children, ...rest } = props;
  return <div ref={ref} style={{ display: 'flex' }} {...rest}>{children}</div>
});

const RegStartedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip key={name} title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: PersonIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: HourglassEmptyIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const RegCompletedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: PersonIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbUpIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const RegRejectedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: PersonIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbDownIcon, size: 18, where: 'SE', color: 'red', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const InterviewIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: VideocamIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: PlayArrowIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const InterviewFailedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: VideocamIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbDownIcon, size: 18, where: 'SE', color: 'red', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const InterviewSuccessIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: VideocamIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbUpIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const PostInterviewIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: VideocamIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbUpIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const EmployeeInfoIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: PersonIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: InfoIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const EmployeeInfoCompletedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: PersonIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: InfoIcon, size: 18, where: 'NE', color: color || '#0000008a', extra: { fontWeight: 900 } },
      { icon: ThumbUpIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const AtcdIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: GavelIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: PlayArrowIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const AtcdWaitingIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: GavelIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: HourglassEmptyIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const AtcdFailedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: GavelIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbDownIcon, size: 18, where: 'SE', color: 'red', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const AtcdSuccessIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: GavelIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbUpIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const CompletedIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <ComposedIcon size={size || 48} onClick={onClick} icons={[
      { icon: ThumbUpIcon, size: 24, where: 'C', color: color || '#0000008a' },
      { icon: ThumbUpIcon, size: 18, where: 'SE', color: color || '#0000008a', extra: { fontWeight: 900 } },
    ]} />
  </TooltipHOC>
</Tooltip>

const GoodIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <IconButton onClick={onClick}>
      <ThumbUpIcon style={{ fontSize: size || 32, color: color || '#009eff' }} />
    </IconButton>
  </TooltipHOC>
</Tooltip>

const BadIcon = ({ name, size, color, onClick }: StepIconProps) => <Tooltip title={name}>
  <TooltipHOC>
    <IconButton onClick={onClick}>
      <ThumbDownIcon style={{ fontSize: size || 32, color: color || 'red' }} />
    </IconButton>
  </TooltipHOC>
</Tooltip>

export const DocumentCount = ({ count, desc }: { count: number, desc: NonNullable<ReactNode> }) => <HtmlTooltip title={desc} placement='left'>
  <TooltipHOC>
    <span style={{ width: '100%', textAlign: 'center' }}>{count}</span>
  </TooltipHOC>
</HtmlTooltip>

export const stateOrder = new Map<OnboardingState, [number, number]>([
  [OnboardingState.REG_STARTED, [0, 0]],
  [OnboardingState.REG_COMPLETED, [0, 1]],
  [OnboardingState.REG_REJECTED, [0, 2]],
  [OnboardingState.INTERVIEW, [1, 0]],
  [OnboardingState.POST_INTERVIEW, [1, 1]],
  [OnboardingState.INTERVIEW_FAILED, [1, 2]],
  [OnboardingState.ATCD, [2, 0]],
  [OnboardingState.ATCD_WAITING, [2, 1]],
  [OnboardingState.ATCD_FAILED, [2, 2]],
  [OnboardingState.EMPLOYEE_INFO, [3, 0]],
  [OnboardingState.EMPLOYEE_INFO_COMPLETED, [3, 1]],
  [OnboardingState.COMPLETED, [4, 0]],
]);

export const nextStepIcons = new Map<OnboardingState, { node: ReactNode, desc: string }>([
  // [OnboardingState.REG_STARTED, { node: <RegStartedIcon name='REG_STARTED' color='#d68f00' />, desc: { en: 'REG_STARTED', fr: 'TODO' }.en }],
  // [OnboardingState.REG_COMPLETED, { node: <RegCompletedIcon name='REG_COMPLETED' color='green' />, desc: { en: 'REG_COMPLETED', fr: 'TODO' }.en }],
  [OnboardingState.REG_REJECTED, { node: <BadIcon name='REG_REJECTED' />, desc: { en: 'REG_REJECTED', fr: 'TODO' }.en }],
  [OnboardingState.INTERVIEW, { node: <GoodIcon name='INTERVIEW' />, desc: { en: 'INTERVIEW', fr: 'TODO' }.en }],
  [OnboardingState.INTERVIEW_FAILED, { node: <BadIcon name='INTERVIEW_FAILED' />, desc: { en: 'INTERVIEW_FAILED', fr: 'TODO' }.en }],
  [OnboardingState.POST_INTERVIEW, { node: <GoodIcon name='POST_INTERVIEW' />, desc: { en: 'POST_INTERVIEW', fr: 'TODO' }.en }],
  // [OnboardingState.ATCD, { node: <AtcdIcon name='ATCD' />, desc: { en: 'ATCD', fr: 'TODO' }.en }],
  [OnboardingState.ATCD_WAITING, { node: <GoodIcon name='ATCD_WAITING' />, desc: { en: 'ATCD_WAITING', fr: 'TODO' }.en }],
  [OnboardingState.ATCD_FAILED, { node: <BadIcon name='ATCD_FAILED' />, desc: { en: 'ATCD_FAILED', fr: 'TODO' }.en }],
  [OnboardingState.EMPLOYEE_INFO, { node: <GoodIcon name='EMPLOYEE_INFO' />, desc: { en: 'EMPLOYEE_INFO', fr: 'TODO' }.en }],
  [OnboardingState.EMPLOYEE_INFO_COMPLETED, { node: <GoodIcon name='EMPLOYEE_INFO_COMPLETED' />, desc: { en: 'EMPLOYEE_INFO', fr: 'TODO' }.en }],
  [OnboardingState.COMPLETED, { node: <GoodIcon name='COMPLETED' />, desc: { en: 'COMPLETED', fr: 'TODO' }.en }],
]);

export interface OnboardingStateIconProps {
  state: OnboardingState;
  againts: OnboardingState;
  onClick: (state: OnboardingState) => void;
}

export function OnboardingStateIcon({ state, againts, onClick }: OnboardingStateIconProps) {
  const ver = stateOrder.get(againts);
  // console.log('>>> STATE:', OnboardingState[state], OnboardingState[againts], ver)
  if (state === OnboardingState.REG_STARTED) {
    if (ver == null) return <RegStartedIcon name='REG_STARTED' onClick={() => onClick(OnboardingState.REG_STARTED)} />;
    if (ver[0] > 0) return <RegCompletedIcon name='REG_COMPLETED' color='green' onClick={() => onClick(OnboardingState.REG_STARTED)} />;
    if (ver[1] === 0) return <RegStartedIcon name='REG_STARTED' color='#ffbe00' onClick={() => onClick(OnboardingState.REG_STARTED)} />;
    return ver[1] === 1
      ? <RegCompletedIcon name='REG_COMPLETED' color='#009eff' onClick={() => onClick(OnboardingState.REG_STARTED)} />
      : <RegRejectedIcon name='REG_REJECTED' color='red' onClick={() => onClick(OnboardingState.REG_STARTED)} />;
  }
  if (state === OnboardingState.INTERVIEW) {
    if (ver == null || ver[0] < 1) return <InterviewIcon name='INTERVIEW' />;
    if (ver[0] > 1) return <InterviewSuccessIcon name='POST_INTERVIEW' color='green' onClick={() => onClick(OnboardingState.INTERVIEW)} />;
    if (ver[1] === 0) return <InterviewIcon name='INTERVIEW' color='#d68f00' onClick={() => onClick(OnboardingState.INTERVIEW)} />;
    return ver[1] === 1
      ? <InterviewSuccessIcon name='POST_INTERVIEW' color='#009eff' onClick={() => onClick(OnboardingState.POST_INTERVIEW)} />
      : <InterviewFailedIcon name='INTERVIEW_FAILED' color='red' onClick={() => onClick(OnboardingState.INTERVIEW_FAILED)} />;
  }
  if (state === OnboardingState.ATCD) {
    if (ver == null || ver[0] < 2) return <AtcdIcon name='ATCD' />;
    if (ver[0] > 2) return <AtcdSuccessIcon name='ATCD_SUCCESS' color='green' onClick={() => onClick(OnboardingState.POST_INTERVIEW)} />;
    if (ver[1] === 0) return <AtcdIcon name='ATCD' color='#d68f00' onClick={() => onClick(OnboardingState.ATCD)} />;
    return ver[1] === 1
      ? <AtcdWaitingIcon name='ATCD_WAITING' color='#009eff' onClick={() => onClick(OnboardingState.ATCD_WAITING)} />
      : <AtcdFailedIcon name='ATCD_FAILED' color='red' onClick={() => onClick(OnboardingState.ATCD_FAILED)} />;
  }
  if (state === OnboardingState.EMPLOYEE_INFO) {
    if (ver == null || ver[0] < 3) return <EmployeeInfoIcon name='EMPLOYEE_INFO' />;
    if (ver[0] > 3) return <EmployeeInfoIcon name='EMPLOYEE_INFO' color='green' onClick={() => onClick(OnboardingState.EMPLOYEE_INFO)} />;
    if (ver[1] === 0) return <EmployeeInfoIcon name='EMPLOYEE_INFO' color='#d68f00' onClick={() => onClick(OnboardingState.EMPLOYEE_INFO)} />;
    return <EmployeeInfoIcon name='EMPLOYEE_INFO_COMPLETED' color='#009eff' onClick={() => onClick(OnboardingState.EMPLOYEE_INFO_COMPLETED)} />;
  }
  if (ver == null || ver[0] < 4) return <CompletedIcon name='COMPLETED' />;
  return <CompletedIcon name='COMPLETED' color='green' onClick={() => onClick(OnboardingState.COMPLETED)} />;
}
