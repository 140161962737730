import { ReactNode, SyntheticEvent, useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
}

//
// ===
//

function a11yProps(index: number) {
  return {
    id: `forms-tab-${index}`,
    'aria-controls': `forms-tabpanel-${index}`,
  };
}

export interface TabContainerProps {
  labels: string[];
  children: ReactNode[];
}

export function TabContainer({ labels, children }: TabContainerProps) {
  const [value, setValue] = useState(0);
  const [t] = useTranslation();

  useEffect(() => {
    if (labels.length !== children.length) {
      throw(new Error('TabContainer labels/children length mismatch.'));
    }
  }, [labels, children])

  return <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
      <Tabs value={value} onChange={(_: SyntheticEvent, newValue: number) => setValue(newValue)}>
        {labels.map((child, index) => <Tab key={'tablbl'+index} label={t(child)} {...a11yProps(index)} />)}
      </Tabs>
    </Box>
    {children.map((child, index) => <TabPanel key={'tabpanel'+index} value={value} index={index}>
      {child}
    </TabPanel>)}
  </Box>
};
