import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import StopIcon from '@mui/icons-material/Stop';
import CloseIcon from '@mui/icons-material/Close';
import { rankWith, scopeEndsWith, RankedTester, ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import 'regenerator-runtime'; // https://github.com/JamesBrill/react-speech-recognition/issues/110#issuecomment-1022745402
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export const speechToTextControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  scopeEndsWith('voicable')
);

function SpeechToTextControl({ data, handleChange, path }: ControlProps) {
  const [listen, setListen] = useState(false);
  const textField = useRef<HTMLInputElement | null>(null);
  const selStart = useRef(0);
  const selEnd = useRef(0);
  const {
    transcript,
    // interimTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const debug = false;

  useEffect(() => {
    return () => {
      if (listen && listening) SpeechRecognition.abortListening();
      SpeechRecognition.stopListening();
    }
  }, []);

  useEffect(() => {
    if (listen && !listening) {
      if (debug) console.log('> MIC START LISTENING');
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true, language: 'en-CA' });
    }
    else if (!listen && listening) {
      if (debug) console.log('> MIC STOP LISTENING');
      SpeechRecognition.stopListening();
    }
  }, [listen]);

  useEffect(() => {
    if (listen && transcript) {
      if (debug) console.log('> DETECTED:', transcript);
      handleChange(path, replaceRange(data || '', selStart.current, selEnd.current, transcript));
      selEnd.current = selStart.current + transcript.length;
    }
  }, [transcript]);

  function replaceRange(s: string, start: number, end: number, substitute: string) {
    return s.substring(0, start) + substitute + s.substring(end);
  }

  return <Box display='flex' alignItems='center' my={1}>
    <TextField
      inputRef={textField}
      multiline rows={5}
      value={data}
      onChange={e => handleChange(path, e.target.value)}
      onFocus={() => {
        selStart.current = textField.current?.selectionStart || 0;
        selEnd.current = textField.current?.selectionEnd || 0;
        resetTranscript();
      }}
      onBlur={() => {
        selStart.current = textField.current?.selectionStart || 0;
        selEnd.current = textField.current?.selectionEnd || 0;
        resetTranscript();
      }}
      fullWidth
      // inputProps={{ readOnly: listen && listening }}
    />
    <Box display='flex' ml={1}>
      <IconButton disabled={!browserSupportsSpeechRecognition} onClick={() => {
        selStart.current = textField.current?.selectionStart || 0;
        selEnd.current = textField.current?.selectionEnd || 0;
        setListen(!listen);
      }}>
        {listen && listening ? <StopIcon color='error' /> : <KeyboardVoiceIcon />}
      </IconButton>
      <IconButton onClick={() => { handleChange(path, ''); resetTranscript(); }}>
        <CloseIcon />
      </IconButton>
    </Box>
  </Box>
}

export default withJsonFormsControlProps(SpeechToTextControl);

// For MLITE remote STT capture

export interface SpeechToTextRemoteProps {
  capture: boolean;
  onChunk: (text: string, done?: boolean) => void;
}

export function SpeechToTextRemote({ capture, onChunk }: SpeechToTextRemoteProps) {
  const {
    transcript,
    // interimTranscript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const debug = true;

  useEffect(() => {
    return () => {
      if (listening) SpeechRecognition.abortListening();
      SpeechRecognition.stopListening();
    }
  }, []);

  useEffect(() => {
    if (capture && !listening) {
      if (debug) console.log('> MIC START LISTENING');
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true, language: 'en-CA' });
    }
    else if (!capture && listening) {
      if (debug) console.log('> MIC STOP LISTENING');
      // little delai to make sure everything is sent through WebRTC...
      // not safe if cmp gets unmount. find a cleaner solution...
      setTimeout(() => SpeechRecognition.stopListening(), 800);
    }
  }, [capture]);

  useEffect(() => {
    if (transcript) {
      if (debug) console.log('> DETECTED:', transcript);
      onChunk(transcript);
    }
  }, [transcript]);

  return null;
}
