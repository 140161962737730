import React from 'react';
import { View } from 'react-native';
import { Button, List } from 'react-native-paper';
//
import { FFLayout } from './layout';

export interface FFButListProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  choices: string[];
  default?: boolean[];
  onSelect: ({ selected, index }: { selected: string, index: number }) => void;
  onBack?: () => void;
}

export function FFButList(props: FFButListProps) {
  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={props.choices.map((bl, i) => <List.Item
        key={'dtcb' + i} title={bl}
        right={() => <Button onPress={() => props.onSelect({ selected: bl, index: i })}>
          {{ en: 'choose', fr: 'choisir' }.fr}
        </Button>}
      />)}
      onBack={props.onBack}
    />
  </View>
}
