/**
 * Created by narendrasisodiya on 28/10/16.
 */

const ONE = 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loopObject(obj: any, cb: (obj: any, key: string | number) => void, sorted?: boolean) {
  const keys = Object.keys(obj);
  if (sorted === true) {
    keys.sort();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return keys.map(key => cb(obj[key], key)) as any[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSortedKeyString(obj: any) {
  return Object.keys(obj)
    .sort()
    .join(',');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getType(val: any) {
  return Object.prototype.toString.call(val).replace(/^\[object\s(.*)\]$/, '$1');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFirstEle(obj: any) {
  return obj[Object.keys(obj)[0]];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function allValuesSameInArray(arr: any[]) {
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== arr[0]) {
      return false;
    }
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkIfArrayIsAOB(obj: any) {
  if (getType(obj) === 'Array' && obj.length > ONE && getType(getFirstEle(obj)) === 'Object') {
    const test = loopObject(obj, function(row) {
      if (getType(row) === 'Object') {
        return getSortedKeyString(row);
      }
      else {
        return '';
      }
    });

    if (test.length > ONE && test[0].length > ONE) {
      return allValuesSameInArray(test);
    }
    else {
      return false;
    }
  }
  else return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkIfObjectIsOOB(obj: any) {
  if (getType(obj) === 'Object' && Object.keys(obj).length > ONE && getType(getFirstEle(obj)) === 'Object') {
    const test = loopObject(obj, (row) => {
      if (getType(row) === 'Object') return getSortedKeyString(row);
      else return '';
    });

    if (test.length > ONE && test[0].length > ONE) return allValuesSameInArray(test);
    else return false;
  }
  else return false;
}
