import { SyntheticEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import { useTranslation } from 'react-i18next';
//
import { listForms, ListedForm } from '../reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

export interface SelectFormProps {
  label: string;
  onSelect: (form: ListedForm) => void;
  onCancel: () => void;
}

export function SelectForm({ label, onSelect, onCancel }: SelectFormProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly ListedForm[]>([]);
  const [value, setValue] = useState<ListedForm | null>(null);
  const dispatch = useAppDispatch();

  const forms = useAppSelector(state => state.forms.forms);
  // const totalForms = useAppSelector(state => state.forms.totalForms);
  const loading = useAppSelector(state => state.forms.loadingForms);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { dispatch(listForms()); }, []);

  useEffect(() => {
    setOptions(forms);
  }, [forms]);

  return <Box display='flex'>
    <Autocomplete
      sx={{ flex: 1, mr: 1 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => `${option.name} (${option.category || 'uncategorized'})`}
      value={value}
      onChange={(event: SyntheticEvent<Element, Event>, selForm: ListedForm | null) => setValue(selForm)}
      options={options}
      loading={loading}
      renderInput={(params) => <TextField
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          endAdornment: <>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
            {params.InputProps.endAdornment}
          </>,
        }}
      />}
    />
    <Box display='flex' sx={{ my: 0.5 }}>
      <IconButton aria-label='select' onClick={() => { if (value != null) onSelect(value); }} disabled={value == null}>
        <CheckCircleOutlineIcon style={{ fontSize: '28px' }} />
      </IconButton>
      <IconButton aria-label='close' onClick={onCancel}>
        <CancelOutlinedIcon style={{ fontSize: '28px' }} />
      </IconButton>
    </Box>
  </Box>
}

//
// Modal
//

export interface UserFormModalProps extends SelectFormProps {
  open: boolean;
  onClose: () => void;
}

export function UserFormModal(props: UserFormModalProps) {
  const { open, onClose, ...rest } = props;
  // const [t] = useTranslation();
  return <Dialog open={open} maxWidth='lg' fullWidth={true}>
    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.8rem' }}>
      {'select resident'}
      <IconButton aria-label='close' style={{ position: 'absolute', right: 22, top: 22, padding: 0 }} onClick={onClose}>
        <CancelOutlinedIcon style={{ fontSize: '28px' }} />
      </IconButton>
    </DialogTitle>
    <Box sx={{ m: 2 }}><SelectForm {...rest} /></Box>
  </Dialog>
}
