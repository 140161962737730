/* eslint-disable @typescript-eslint/no-explicit-any */

import { Locale } from "@eksoh/flo/model";

export interface PatientDetail {
  username: string;
  familyName?: string | null;
  givenName?: string | null;
  // name: string;
  email: string;
  locale?: Locale | null;
  // birthdate: string;
  // language: string;
  // mobileNumber?: string;
  phoneNumber?: string;
  // fullAddress: string;
  // insurNumber: string;
  // curDoctor?: string;
  // pharmacy?: string;
  // contactName?: string;
  // contactNumber?: string;
}

export interface ISchemaData {
  search: string;
  options: any[];
  patients: PatientDetail[];
  curSelection?: number;
  acSelection?: any;
}

export const schemaData: ISchemaData = {
  search: '',
  patients: [],
  options: [],
}
