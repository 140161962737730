/* eslint-disable @typescript-eslint/no-explicit-any */

import { PlaceComponent } from './google-place.web';

// TODO: Move throttle and parse to a utils lib

// #region throttle

export type ThrottledFunction<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<T>;

export function throttle<T extends (...args: any) => any>(func: T, limit: number): ThrottledFunction<T> {
  let inThrottle: boolean;
  let lastResult: ReturnType<T>;

  return function(this: any): ReturnType<T> {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    if (!inThrottle) {
      inThrottle = true;

      setTimeout(() => (inThrottle = false), limit);

      lastResult = func.apply(context, args as any);
    }

    return lastResult;
  };
}

// #endregion throttle

// #region parse

export function parse(text: string, matches: number[][]) {
  const result = [];

  if (matches.length === 0) {
    result.push({
      text,
      highlight: false
    });
  } else if (matches[0][0] > 0) {
    result.push({
      text: text.slice(0, matches[0][0]),
      highlight: false
    });
  }

  matches.forEach((match, i) => {
    const startIndex = match[0];
    const endIndex = match[1];

    result.push({
      text: text.slice(startIndex, endIndex),
      highlight: true
    });

    if (i === matches.length - 1) {
      if (endIndex < text.length) {
        result.push({
          text: text.slice(endIndex, text.length),
          highlight: false
        });
      }
    } else if (endIndex < matches[i + 1][0]) {
      result.push({
        text: text.slice(endIndex, matches[i + 1][0]),
        highlight: false
      });
    }
  });

  return result;
};

// #endregion parse

export function getComponent(cmps: PlaceComponent[], tag: string): { long_name: string, short_name: string } | undefined {
  const found = cmps.find(cmp => cmp.types.find((t: string) => t === tag) != null);
  return found ? { long_name: found.long_name, short_name: found.short_name } : undefined;
}
