import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { CognitoUser } from '@aws-amplify/auth';
//
import { OnboardingState, LangLevels } from '@eksoh/flo/model';
import { Text, View } from '../cmps/Themed';
import { RootStackScreenProps } from '../types';
import { authStore, globalStore, Storage } from '@eksoh/shared/ui';

export default function Home({ navigation }: RootStackScreenProps<'Root'>) {
  const { globalState } = useContext(globalStore);
  const { authState } = useContext(authStore);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
  }, []);

  // Screen order
  // NeedPhone, NurseType, Permits, AvailabilityTimish, MissionType, MissionRadius
  // DisplType, Upload, ValidateAccount

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.onboardingState == null || authState.onboardingState === OnboardingState.REG_STARTED) {
        // TODO @sb: possible to find interface/class definition with attibutes? otherwise we need to call
        //           anoying functions with callbacks...
        const cu = authState.cognitoUser as CognitoUser & { attributes: { phone_number_verified: boolean } }
        if (
          (authState.onboarding?.started?.agreed == null) ||
          (authState.user?.phoneNumber == null && authState.onboarding?.phone == null) ||
          !(cu?.attributes.phone_number_verified || false)
        ) {
          navigation.replace('NeedPhone');
        }
        else if (authState.onboarding?.personelInfo?.nurseType == null) {
          navigation.replace('NurseType');
        }
        else if (
          authState.onboarding?.personelInfo?.french == null ||
          authState.onboarding?.personelInfo?.english == null ||
          authState.onboarding?.personelInfo?.french === LangLevels.UNSET ||
          authState.onboarding?.personelInfo?.english === LangLevels.UNSET
        ) {
          navigation.replace('Languages');
        }
        else if (authState.onboarding?.professionalInfo?.OIIQ == null) {
          navigation.replace('Permits');
        }
        // else if (authState.onboarding?.dispo == null) {
        //   navigation.replace('Availabilities');
        // }
        else if (authState.onboarding?.calendar?.range == null) {
          navigation.replace('AvailabilityTimish');
        }
        else if (authState.onboarding?.missionType?.misType == null) {
          navigation.replace('MissionType');
        }
        else if (authState.onboarding?.missionDistance?.postal == null) {
          navigation.replace('MissionRadius');
        }
        else if (authState.onboarding?.displacementMeans?.transport == null) {
          navigation.replace('DisplType');
        }
        else if (!authState.onboarding?.regDocs?.done) {
          navigation.replace('Upload');
        }
        else {
          navigation.replace('NotYetVetted');
        }
      }
      else {
        if (authState.onboardingState === OnboardingState.POST_INTERVIEW) {
          navigation.push('Onboarding1PostInterview', { code: Storage.local.get('onbCode') || '-=+=-' });
        }
        else if (authState.onboardingState === OnboardingState.EMPLOYEE_INFO) {
          navigation.push('Onboarding3EmployeeInfo');
        }
        else if (authState.onboardingState === OnboardingState.COMPLETED && !authState.onboarding?.videos?.watched) {
          navigation.push('Onboarding4Videos');
        }
        else if (authState.onboardingState !== OnboardingState.COMPLETED) {
          navigation.push('NotYetVetted');
        }
        else {
          navigation.replace('NurseDashboard');
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  return <View style={styles.container}>
    {globalState.isDev && <Text style={styles.linkText}>error, should not get here. contact tech support.</Text>}
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'rgb(229, 229, 229)',
  },
  title: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
