// import { useContext /* , useState */ } from 'react';
// import { Button } from 'react-native-paper';
// import { useTranslation } from 'react-i18next';
// //
import { authStore, globalStore } from '@eksoh/shared/ui';
// import { View } from '../cmps/Themed';
import { RootStackScreenProps } from '../types';
// import Layout from '../cmps/Layout';
// import Logo from '../cmps/Logo';
// import { scale /* , moderateScale */ } from '../Scaling';
// import LanguageBanner from '../cmps/LanguageBanner';
// import { ClientDashboard as SharedClientDashboard } from '@eksoh/shared/ui-expo';
// import { DebugPanel } from '../cmps/Debug';
import * as Linking from 'expo-linking';
import { useContext, useEffect } from 'react';

export default function ClientDashboard({ navigation }: RootStackScreenProps<'ClientDashboard'>) {
  const { globalState } = useContext(globalStore);
  const { authState, /* signOut */ } = useContext(authStore);
  // // const [code, setCode] = useState('');
  // const [t] = useTranslation();

  // const showDev = globalState.isDev;
  // return <Layout hideFab={true}>
  //   <View style={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: 'transparent', paddingTop: 24 }}>
  //     <LanguageBanner />
  //   </View>
  //   <View style={{ height: '10vh'}} />
  //   <Logo style={{ marginTop: scale(1) }} width={60} height={60} />
  //   <SharedClientDashboard />
  //   {showDev && <DebugPanel navigation={navigation} mt={40} />}
  //   <Button icon='logout' mode='outlined' onPress={signOut}>
  //     {t('shorts.logout')}
  //   </Button>
  //   <View style={{ height: '10vh'}} />
  // </Layout>

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { goToWebapp(); }, []);

  async function goToWebapp() {
    if (!authState.isSignedIn) {
      navigation.push('Login');
      return;
    }

    globalState.isDev
      ? await Linking.openURL('http://localhost:4200/')
      : await Linking.openURL('https://app.qa.flowrence.net/');
  }

  return null;
}
