/* eslint-disable @typescript-eslint/no-explicit-any */
// import styles from './select-groups.module.css';
import { ChangeEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
//
import { Group } from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER

type GroupBools = { [key in Group as string]: boolean };
const initDataGen: any = {};

// Object.keys(Group).filter(k => typeof k === 'string').forEach(k => initDataGen[k] = false);
// TODO @fe // TODO @be - this is super temporary, only to get the mvp going...
const groupToFilterOut = [Group.BROKER, Group.INSURER, Group.CLIENT, Group.NURSE, Group.NURSE_IPS];
Object.keys(Group)
  .filter(k => !groupToFilterOut.includes(k as Group))
  .filter(k => typeof k === 'string')
  .forEach(k => initDataGen[k] = false);

const initData: GroupBools = initDataGen;
const roleKeys = Object.keys(Group);

export function listGroups(groups: Group[], isAdmin?: boolean) {
  return isAdmin
    ? groups.map(p => Group[p]).join(', ')
    : groups.map(p => Group[p]).join(', ');
}

export interface SelectGroupsProps {
  groups: Group[];
  onClose: () => void;
  onSubmit: (groups: Group[]) => void;
}

export function SelectGroups({ groups, onClose, onSubmit }: SelectGroupsProps) {
  const [roles, setRoles] = useState(initData);
  // const error = false;

  useEffect(() => {
    const newRoles = { ...roles };
    groups.forEach(g => { if (roleKeys.includes(g)) newRoles[g] = true; });
    setRoles(newRoles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box display='flex' flexDirection='column' sx={{ backgroundColor: 'white' }}>
    <Box display='flex'>
      <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
        <FormLabel component='legend'>roles</FormLabel>
        <FormGroup>
          {Object.keys(roles).filter(k => roleKeys.includes(k)).map((k, i) => <FormControlLabel key={'cb_role' + i} label={k}
            control={
              <Checkbox name={k} checked={roles[k]}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setRoles({
                    ...roles,
                    [event.target.name]: event.target.checked,
                  });
                }}
              />
            }
          />)}
        </FormGroup>
        {/* <FormHelperText>you can display an error</FormHelperText> */}
      </FormControl>
    </Box>
    <Box display='flex' width='100%' justifyContent='center'>
      <Button onClick={onClose}>cancel</Button>
      <Button onClick={() => {
        onSubmit(Object.keys(roles).filter(g => roles[g]) as Group[]);
      }}>
        submit
      </Button>
    </Box>
  </Box>
}

//
// ===
//

export interface SelectGroupsModalProps extends SelectGroupsProps {
  open: boolean;
  onClose: () => void;
}

export function SelectGroupsModal({ open, onClose, ...rest }: SelectGroupsModalProps) {
  return <Dialog
    // fullWidth={true | false}
    // maxWidth={'xs' | 'sm' | 'md' | 'lg' | 'xl'}
    open={open}
    onClose={onClose}
  >
    <SelectGroups onClose={onClose} {...rest} />
  </Dialog>
}
