import React from 'react';
import SvgIcon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

export interface IconLabelProps {
  icon: React.ElementType;
  text: string;
}

export function IconLabel(props: IconLabelProps) {
  return <div style={{ display: 'flex', lineHeight: '0px' }}>
    <SvgIcon component={props.icon} style={{ width: 16, marginRight: 6, paddingTop: 8 }} />
    <Typography variant='subtitle1' color='textSecondary'>{props.text}</Typography>
  </div>
}
