import { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//
import { authStore } from '@eksoh/shared/ui';
// import { CounterTag } from '../shared/cmps/tag';
// import { Rings } from './cmps/rings';
// import { ClientsBySectors } from './cmps/sectors';
import AppmntList from './cmps/appmntlist';

export function ClientDashboard() {
  const { authState } = useContext(authStore);

  return <>
    <Box display='flex'>
      <Box style={{ flex: 1 }}>
        <Typography variant='caption' style={{ marginBottom: 40 }}>
          votre portail
        </Typography>
        <Typography variant='h6' style={{ marginBottom: 40 }}>
          {`${authState.user?.givenName} ${authState.user?.familyName}`}
        </Typography>
        {/* <Box display='flex' style={{ marginBottom: 12 }}>
          <CounterTag title='Approuvée' num={6} label='Récent' of={1100} gutterRight />
          <ClientsBySectors isLight={true} clients={[]} />
          <Rings />
        </Box> */}
      </Box>
    </Box>
    <AppmntList />
  </>
}
