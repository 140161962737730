import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Text, TouchableOpacity } from 'react-native';
import { Avatar, TextInput, Surface, Subheading, Paragraph, Modal, Banner } from 'react-native-paper';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Slider } from '@miblanchard/react-native-slider';
//
import { authStore } from '@eksoh/shared/ui';
import { useLocation, eGeolocStatus } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';

export enum Mission { TELEMED, PRESENT, WHATEVER }

export default function MissionRadius({ navigation }: RootStackScreenProps<'MissionRadius'>) {
  const { authState, onboarding } = useContext(authStore);
  const { status, position, start } = useLocation({ notOnMount: true, doNotUpd: true });
  const [geoloc, setGeoloc] = useState<GeolocationPosition>();
  const [dist, setDist] = useState(5);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  const { control, getValues, setValue, setFocus, trigger, formState: { isValid } } = useForm({
    defaultValues: { postal: '' },
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('postal', authState.onboarding?.missionDistance?.postal || '');

    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // The busy below is a bit risky if the gap is big betweem the first to last
    // position update. Might miss the last one but it is not likely.
    if (position == null) return;
    if (geoloc == null) setGeoloc(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useEffect(() => {
    if (geoloc == null) return;
    getPostal(geoloc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoloc]);

  async function getPostal(loc: GeolocationPosition) {
    const res = await fetch(`https://geocoder.ca/${loc.coords.latitude},${loc.coords.longitude}?pois=1&geoit=xml&json=1`);
    const addr = await res.json();
    setValue('postal', addr.postal || '');
    await trigger('postal');
  }

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.missionDistance?.postal != null) navigation.push('DisplType');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  return <View style={styles.container}>
    <OnboardingHeader progress={8} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Logo style={{ marginTop: scale(18) }} width={50} height={50} />
        <Paragraph style={{ marginTop: scale(6), textAlign: 'center' }}>{t('nurse.misRadDesc')}</Paragraph>
      </View>
      <View style={{ width: '100%', marginTop: 24 }}>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          inputSpacing={0}
          formConfigArray={[
            {
              name: 'postal',
              type: 'text',
              textInputProps: {
                label: t('userManagement.postalArea'),
                left: <TextInput.Icon name={'map-marker'} />,
              },
              rules: {
                required: {
                  value: true,
                  message: t('nurse.misPostalRequired'),
                },
                pattern: {
                  value: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                  message: t('nurse.misPostalInvalid'),
                },
                minLength: {
                  value: 6,
                  message: t('nurse.misPostalHelp'),
                },
                maxLength: {
                  value: 7,
                  message: t('nurse.misPostalHelp'),
                },
              },
            },
          ]}
        />
      </View>
      <TouchableOpacity
        style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 32 }}
        onPress={() => start()}
      >
        <Avatar.Icon icon='crosshairs-gps' size={24} color='#323232' style={{ backgroundColor: 'transparent' }} />
        <Text style={{ marginLeft: 4, marginTop: 3, textDecorationLine: 'underline' }}>{t('nurse.geolocMe')}</Text>
      </TouchableOpacity>
      <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Text style={{ color: '#A5A5A5' }}>{t('nurse.misRadMin')}</Text>
      </Surface>
      <View style={{ width: '100%', paddingLeft: 14, paddingRight: 14, marginTop: 8 }}>
        <Subheading style={{ width: '100%', textAlign: 'center' }}>{t('nurse.geolocRadius')}{dist} km</Subheading>
        <Slider
          minimumValue={5}
          maximumValue={200}
          minimumTrackTintColor='rgb(50, 50, 50)'
          maximumTrackTintColor='rgba(50, 50, 50, 0.38)'
          value={dist}
          onValueChange={val => setDist(Array.isArray(val) ? val[0] : val)}
          step={1}
        />
      </View>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 14, paddingRight: 14 }}>
        <Subheading>5 km</Subheading>
        <Subheading>200 km</Subheading>
      </View>
    </ScrollView>
    <OnboardingFooter onNext={() => onboarding( { missionDistance: { geoloc, postal: getValues().postal, dist }})} disabledBut={!isValid} hideLater />
    <Modal visible={status === eGeolocStatus.SEARCHING} contentContainerStyle={{ backgroundColor: 'white', padding: 20 }}>
      <Banner
        visible={status === eGeolocStatus.SEARCHING}
        actions={[]}
        icon={cprops => <Avatar.Icon {...cprops} icon='crosshairs-gps' />}
      >
        {t('nurse.misRadSearch')}
      </Banner>
    </Modal>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
