/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, ReactNode, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
//
import { authStore } from '@eksoh/shared/ui';
import { reset, listUserForms } from '../reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Waiting } from '@eksoh/shared/ui-web'; // TODO @fp: that a big NO NO !!!
import { SelectForm } from './selectform';

export interface UserFormsListProps {
  username?: string;
  getUser: ReactNode;
  assignForm: boolean;
  onFormAssign: (data?: { username: string, name: string }) => void;
  onView: (name: string, username: string) => void;
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export default function UserFormsList({ username, getUser, assignForm, onFormAssign, onView, onWaiting }: UserFormsListProps) {
  const { authState } = useContext(authStore);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (username) dispatch(listUserForms(username));
    else dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const dataList = useAppSelector(state => state.forms.userForms);
  const totalItems = useAppSelector(state => state.forms.totalUserForms);
  const loading = useAppSelector(state => state.forms.loadingUserForms);

  return <Box pt={2}>
    {getUser}
    {loading && <Waiting colorSpinner='#0b4f61' height='65vh' />}
    {assignForm && <Box sx={{ mb: 2 }}>
      <SelectForm
        label='select form'
        onSelect={f => { if (username) onFormAssign({ username, name: f.name }); }}
        onCancel={() => onFormAssign()}
      />
    </Box>}
    {!loading && <Box style={{ paddingTop: 0 }}>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('userManagement.name')}</TableCell>
              <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{t('userManagement.status')}</TableCell>
              <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{t('entity.action.title')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList && dataList.map((data, i) => (
              <TableRow key={`sas_${i}`}>
                <TableCell component='th' scope='row'>
                  {/* {data.name}{' - '}<EmailIcon sx={{ mb: -1 }} /> {slowGetDetail(data, 'email')}{' -'}<SmartphoneIcon sx={{ mb: -1 }} />{slowGetDetail(data, 'phone')} */}
                  {data.name}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  {data.status}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => { if (username) onView(data.name, username); }} size='large'>
                    <VisibilityIcon style={{ fontSize: 24 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {!totalItems && <TableRow>
              <TableCell component='th' scope='row' colSpan={5}>
                {username ? 'user has no forms' : 'no user selected'}
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>}
  </Box>
};
