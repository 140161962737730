import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Avatar, RadioButton, Paragraph, Modal, Banner } from 'react-native-paper';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { Mission } from '@eksoh/flo/model';
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';

export default function MissionType({ navigation }: RootStackScreenProps<'MissionType'>) {
  const { authState, onboarding } = useContext(authStore);
  const [misType, setMisType] = useState<Mission>(Mission.WHATEVER);
  const [showWarn, setShowWarn] = useState<string>();
  const [shownWarn1, setShownWarn1] = useState(false);
  const [shownWarn2, setShownWarn2] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.missionType?.misType != null) navigation.push('MissionRadius');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  useEffect(() => {
    if (misType === Mission.PRESENT && !shownWarn1) { setShownWarn1(true); setShowWarn('nurse.misTypeDesc1'); }
    else if (misType === Mission.TELEMED && !shownWarn2) { setShownWarn2(true); setShowWarn('nurse.misTypeDesc2'); }
  }, [misType, shownWarn1, shownWarn2]);

  return <View style={styles.container}>
    <OnboardingHeader progress={7} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Logo style={{ marginTop: scale(12) }} width={50} height={50} />
        <Paragraph style={{ marginTop: scale(6), textAlign: 'center' }}>{t('nurse.misTypeDesc')}</Paragraph>
      </View>
      <View style={{ flexGrow: 1, width: '100%', marginTop: 30 }}>
        <RadioButton.Group onValueChange={value => setMisType(Mission[value as keyof typeof Mission])} value={Mission[misType]}>
          <RadioButton.Item label={t('nurse.misTypeOpt1')} value={Mission[Mission.PRESENT]} position='leading' color='#323232' style={{ height: 60 }} labelStyle={{ textAlign: 'left' }} />
          <RadioButton.Item label={t('nurse.misTypeOpt2')} value={Mission[Mission.TELEMED]} position='leading' color='#323232' style={{ height: 60 }} labelStyle={{ textAlign: 'left' }} />
          <RadioButton.Item label={t('nurse.misTypeOpt3')} value={Mission[Mission.WHATEVER]} position='leading' color='#323232' style={{ height: 60 }} labelStyle={{ textAlign: 'left' }} />
        </RadioButton.Group>
        <View style={{ height: 95 }} />
      </View>
    </ScrollView>
    <OnboardingFooter onNext={() => onboarding({ missionType: { misType }})} hideLater />
    <Modal visible={showWarn != null} onDismiss={() => setShowWarn(undefined)} contentContainerStyle={{ backgroundColor: 'white', padding: 20 }}>
      <Banner
        visible={showWarn != null}
        actions={[{
          label: t('shorts.close'),
          onPress: () => setShowWarn(undefined),
        },]}
        icon={cprops => <Avatar.Icon {...cprops} color='white' icon='exclamation' />}
      >
        {`${t(showWarn || 'nurse.misTypeWarn')}${showWarn === 'nurse.misTypeDesc2' ? `\n\n${t('nurse.misTypeWarn')}` : ''}`}
      </Banner>
    </Modal>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
