/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer, Dispatch, useEffect, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
//
// import { BeServices } from '../../..';
import { Patient, Diagnosis } from '@eksoh/shared/common';
// import { listAppmtData } from '@eksoh/shared/ui';
// import { useAppDispatch, useAppSelector } from '../../redux/store';
// fake
import { diagnosesList, patientList } from '@eksoh/shared/common';

// TODO: Move to model
export interface EmrCalendarEvent {
  id: string | number;
  day?: number;
  label?: string;
  title: string;
  description: string;
}
export interface EmrCalendarLabel {
  label: string;
  checked: boolean;
}

export enum eClientEmrActions {
  INIT,
  // Patient
  SET_PATIENT_LIST, SET_DIAGNOSES_LIST,
  ADD_PATIENT, UPD_PATIENT,
  // Calendar
  SET_CAL_EVTS, PUSH_CAL_EVT, UPDATE_CAL_EVT, DELETE_CAL_EVT,
  SET_MONTH_IDX, SET_SMALL_MONTH_IDX,
  SET_DAY_SELECTED, SET_SELECTED_EVENT, SET_FILTERED_EVENTS,
  SET_EVENT_MODAL, SET_LABELS,
}

interface InitAction {
  type: typeof eClientEmrActions.INIT;
}

// #region Patient Actions

interface SetPatientListAction {
  type: typeof eClientEmrActions.SET_PATIENT_LIST;
  patientList: Patient[];
}

interface SetDiagnosesListAction {
  type: typeof eClientEmrActions.SET_DIAGNOSES_LIST;
  diagnosesList: Diagnosis[];
}

interface AddPatientAction {
  type: typeof eClientEmrActions.ADD_PATIENT;
  patient: Patient;
}

interface UpdPatientAction {
  type: typeof eClientEmrActions.UPD_PATIENT;
  patient: Patient;
}

// #endregion Patient Actions

// #region Calendar Actions

interface SetCalEvtsAction {
  type: typeof eClientEmrActions.SET_CAL_EVTS;
  savedEvents: EmrCalendarEvent[];
}

interface PushCalEvtAction {
  type: typeof eClientEmrActions.PUSH_CAL_EVT;
  event: EmrCalendarEvent;
}

interface UpdateCalEvtAction {
  type: typeof eClientEmrActions.UPDATE_CAL_EVT;
  event: EmrCalendarEvent;
}

interface DeleteCalEvtAction {
  type: typeof eClientEmrActions.DELETE_CAL_EVT;
  id?: string | number;
}

interface SetMonthIdxAction {
  type: typeof eClientEmrActions.SET_MONTH_IDX;
  monthIndex: number;
}

interface SetSmallMonthIdxAction {
  type: typeof eClientEmrActions.SET_SMALL_MONTH_IDX;
  smallCalendarMonth: number;
}

interface SetDaySelectedAction {
  type: typeof eClientEmrActions.SET_DAY_SELECTED;
  daySelected?: Dayjs;
}

interface SetSelectedEventAction {
  type: typeof eClientEmrActions.SET_SELECTED_EVENT;
  selectedEvent?: EmrCalendarEvent;
}

interface SetFilteredEventsAction {
  type: typeof eClientEmrActions.SET_FILTERED_EVENTS;
  filteredEvents: EmrCalendarEvent[];
}

interface SetEventModalAction {
  type: typeof eClientEmrActions.SET_EVENT_MODAL;
  showEventModal: boolean;
}

interface SetLabelsAction {
  type: typeof eClientEmrActions.SET_LABELS;
  labels: EmrCalendarLabel[];
}

// #endregion Calendar Actions

export type ClientEmrActionTypes = InitAction | SetPatientListAction | SetDiagnosesListAction
  // Patient
  | AddPatientAction | UpdPatientAction
  // Calendar
  | SetCalEvtsAction | PushCalEvtAction | UpdateCalEvtAction | DeleteCalEvtAction
  | SetMonthIdxAction | SetSmallMonthIdxAction | SetDaySelectedAction | SetSelectedEventAction
  | SetFilteredEventsAction | SetEventModalAction | SetLabelsAction;

export interface IClientEmrState {
  isReady: boolean;
  isInit: boolean;
  // Patient
  patients: { [id: string]: Patient };
  diagnoses: { [id: string]: Diagnosis };
  // Calendar
  monthIndex: number;
  smallCalendarMonth?: number;
  daySelected?: Dayjs;
  showEventModal: boolean,
  savedEvents: EmrCalendarEvent[],
  selectedEvent?: EmrCalendarEvent,
  labels: EmrCalendarLabel[],
  filteredEvents: EmrCalendarEvent[],
}

const initialState: IClientEmrState = {
  isReady: false,
  isInit: false,
  // Patient
  patients: {},
  diagnoses: {},
  // Calendar
  monthIndex: dayjs().month(),
  showEventModal: false,
  savedEvents: [],
  labels: [],
  filteredEvents: [],
}

export interface IClientEmrContext {
  clientEmrState: IClientEmrState;
  dispatch: Dispatch<any>;
  // Patient
  addPatient: (patient: Patient) => void; // TODO: Move to admin emr ctxt
  updPatient: (patient: Patient) => void; // TODO: Move to admin emr ctxt
  // Calendar
  initCalendar: () => void;
  setMonthIndex: (index: number) => void;
  setSmallCalendarMonth: (index: number) => void;
  setDaySelected: (day: Dayjs) => void;
  setShowEventModal: (showEventModal: boolean) => void;
  dispatchCalEvent: (action: PushCalEvtAction | UpdateCalEvtAction | DeleteCalEvtAction) => void;
  setSelectedEvent: (selectedEvent?: EmrCalendarEvent) => void;
  setLabels: (labels: EmrCalendarLabel[]) => void;
  updateLabel: (label: EmrCalendarLabel) => void;
}

export const clientEmrStore = createContext<IClientEmrContext>({
  clientEmrState: initialState,
  dispatch: () => null,
  // Patient
  addPatient: () => { throw new Error('Not implemented.'); },
  updPatient: () => { throw new Error('Not implemented.'); },
  // Calendar
  initCalendar: () => { throw new Error('Not implemented.'); },
  setMonthIndex: () => { throw new Error('Not implemented.'); },
  setSmallCalendarMonth: () => { throw new Error('Not implemented.'); },
  setDaySelected: () => { throw new Error('Not implemented.'); },
  setShowEventModal: () => { throw new Error('Not implemented.'); },
  dispatchCalEvent: () => { throw new Error('Not implemented.'); },
  setSelectedEvent: () => { throw new Error('Not implemented.'); },
  setLabels: () => { throw new Error('Not implemented.'); },
  updateLabel: () => { throw new Error('Not implemented.'); },
});

function reducer(state: IClientEmrState, action: ClientEmrActionTypes) {
  switch (action.type) {
    case eClientEmrActions.INIT: return { ...initialState, isReady: true };
    // Patient
    case eClientEmrActions.SET_PATIENT_LIST:
      return {
        ...state,
        patients: {
          ...action.patientList.reduce((others, patient) => ({ ...others, [patient.id]: patient }), {}),
        },
      };
    case eClientEmrActions.SET_DIAGNOSES_LIST:
      return {
        ...state,
        diagnoses: {
          ...action.diagnosesList.reduce((others, diagnosis) => ({ ...others, [diagnosis.code]: diagnosis }), {}),
        },
      };
    case eClientEmrActions.ADD_PATIENT:
    case eClientEmrActions.UPD_PATIENT:
      return {
        ...state,
        patients: {
          ...state.patients,
          [action.patient.id]: action.patient,
        }
      };
    // Calendar
    case eClientEmrActions.SET_CAL_EVTS:
      return { ...state, savedEvents: action.savedEvents, isInit: true };
    case eClientEmrActions.PUSH_CAL_EVT:
      return { ...state, savedEvents: [...state.savedEvents, action.event] };
    case eClientEmrActions.UPDATE_CAL_EVT:
      return { ...state, savedEvents: state.savedEvents.map(evt => evt.id === action.event.id ? action.event : evt) };
    case eClientEmrActions.DELETE_CAL_EVT:
      if (action.id == null) return state;
      return { ...state, savedEvents: state.savedEvents.filter(evt => evt.id !== action.id) };
    case eClientEmrActions.SET_MONTH_IDX:
      return { ...state, monthIndex: action.monthIndex, daySelected: undefined };
    case eClientEmrActions.SET_SMALL_MONTH_IDX:
      return { ...state, smallCalendarMonth: action.smallCalendarMonth };
    case eClientEmrActions.SET_DAY_SELECTED:
      return { ...state, daySelected: action.daySelected };
    case eClientEmrActions.SET_SELECTED_EVENT:
      return { ...state, selectedEvent: action.selectedEvent };
    case eClientEmrActions.SET_FILTERED_EVENTS:
      return { ...state, filteredEvents: action.filteredEvents };
    case eClientEmrActions.SET_EVENT_MODAL:
      return { ...state, showEventModal: action.showEventModal };
    case eClientEmrActions.SET_LABELS:
      return { ...state, labels: action.labels };
    default:
      return state;
  }
}

export interface ClientEmrProps {
  children: React.ReactNode | React.ReactNode[];
}

export function ClientEmr(props: ClientEmrProps) {
  const [clientEmrState, dispatch] = useReducer(reducer, initialState);
  // TODO: do not use redux here since redux provider is not implemeneted in expo app.
  //       might be nice to split the redux/reducer to not have admin functionalities in the
  //       expo app eventually...
  // const appDispatch = useAppDispatch();
  // const dataList = useAppSelector(state => state.appointments.items);
  // const totalItems = useAppSelector(state => state.appointments.totalItems);
  // const loading = useAppSelector(state => state.appointments.loading);
  // const beAppmts = BeServices.getInstance().appmts;

  useEffect(() => {
    // appDispatch(listAppmtData());
    dispatch({ type: eClientEmrActions.INIT });
  }, []);

  useEffect(() => {
    dispatch({
      type: eClientEmrActions.SET_FILTERED_EVENTS, filteredEvents: clientEmrState.savedEvents.filter(evt =>
        clientEmrState.labels
          .filter((lbl) => lbl.checked)
          .map((lbl) => lbl.label)
          .includes(evt.label || '')
      )
    });
  }, [clientEmrState.savedEvents, clientEmrState.labels]);

  useEffect(() => {
    dispatch({
      type: eClientEmrActions.SET_LABELS, labels: [...new Set(clientEmrState.savedEvents.map((evt) => evt.label || ''))].map(
        label => {
          const currentLabel = clientEmrState.labels.find((lbl) => lbl.label === label);
          return {
            label,
            checked: currentLabel ? currentLabel.checked : true,
          };
        }
      )
    });
    // if (clientEmrState.isInit) {
    //   localStorage.setItem('savedEvents', JSON.stringify(clientEmrState.savedEvents));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmrState.savedEvents]);

  // fake be call
  useEffect(() => {
    if (!clientEmrState.isReady) return;
    dispatch({ type: eClientEmrActions.SET_DIAGNOSES_LIST, diagnosesList });
    dispatch({ type: eClientEmrActions.SET_PATIENT_LIST, patientList: patientList as any });
  }, [clientEmrState.isReady]);

  // useEffect(() => {
  //   const savedEvents = dataList?.map(ev => ({
  //     id: ev.lngLatAppmtType,
  //     day: (new Date(ev.start)).getTime(),
  //     // title: `${ev.detail.fname} - ${(new Date(ev.start)).toLocaleTimeString('fr-CA')}`,
  //     title: (new Date(ev.start)).toLocaleTimeString('fr-CA').substring(0, 7).trim(),
  //     description: `${ev.detail.fname} ${ev.detail.phone}`,
  //     label: 'indigo',
  //   }));

  //   if (savedEvents && savedEvents.length > 0) {
  //     dispatch({ type: eClientEmrActions.SET_CAL_EVTS, savedEvents });
  //   }
  // }, [dataList]);
  // useEffect(() => {
  //   if (beAppmts == null) return;
  //   getAppmts();
  // }, [beAppmts]);

  // async function getAppmts(from?: Date) {
  //   const result = await BeServices.getInstance().appmts.listAppmtsRecord('Sampling', from);
  //   const savedEvents = result?.map(ev => ({
  //     id: ev.lngLatAppmtType,
  //     day: (new Date(ev.start)).getTime(),
  //     // title: `${ev.detail.fname} - ${(new Date(ev.start)).toLocaleTimeString('fr-CA')}`,
  //     title: (new Date(ev.start)).toLocaleTimeString('fr-CA').substring(0, 7).trim(),
  //     description: `${ev.detail.fname} ${ev.detail.phone}`,
  //     label: 'indigo',
  //   }));

  //   if (savedEvents && savedEvents.length > 0) {
  //     dispatch({ type: eClientEmrActions.SET_CAL_EVTS, savedEvents });
  //   }
  // }

  // #region Patient Functions

  function addPatient(patient: Patient) {
    // TODO @sb: call be
    dispatch({ type: eClientEmrActions.ADD_PATIENT, patient });
  }

  function updPatient(patient: Patient) {
    // TODO @sb: call be
    dispatch({ type: eClientEmrActions.UPD_PATIENT, patient });
  }

  // #endregion Patient Functions

  // #region Calendar Functions

  function initCalendar() {
    // try {
    //   const storageEvents = localStorage.getItem('savedEvents');
    //   const savedEvents = storageEvents ? JSON.parse(storageEvents) : [];
    //   dispatch({ type: eClientEmrActions.SET_CAL_EVTS, savedEvents });
    // }
    // catch (error) {
    //   /* */
    // }
  }

  function setMonthIndex(monthIndex: number) {
    dispatch({ type: eClientEmrActions.SET_MONTH_IDX, monthIndex });
  }

  function setSmallCalendarMonth(smallCalendarMonth: number) {
    dispatch({ type: eClientEmrActions.SET_SMALL_MONTH_IDX, smallCalendarMonth });
  }

  function setDaySelected(daySelected: Dayjs) {
    dispatch({ type: eClientEmrActions.SET_DAY_SELECTED, daySelected });
  }

  function setShowEventModal(showEventModal: boolean) {
    dispatch({ type: eClientEmrActions.SET_EVENT_MODAL, showEventModal });
  }

  function dispatchCalEvent(action: PushCalEvtAction | UpdateCalEvtAction | DeleteCalEvtAction) {
    dispatch(action);
  }

  function setSelectedEvent(selectedEvent?: EmrCalendarEvent) {
    dispatch({ type: eClientEmrActions.SET_SELECTED_EVENT, selectedEvent });
  }

  function setLabels(labels: EmrCalendarLabel[]) {
    dispatch({ type: eClientEmrActions.SET_LABELS, labels });
  }

  function updateLabel(label: EmrCalendarLabel) {
    dispatch({
      type: eClientEmrActions.SET_LABELS,
      labels: clientEmrState.labels.map(lbl => lbl.label === label.label ? label : lbl),
    });
  }

  // #endregion Calendar Functions

  return <clientEmrStore.Provider value={{
    clientEmrState, dispatch,
    // Patient
    addPatient, updPatient,
    // Calendar
    initCalendar, setMonthIndex, setSmallCalendarMonth, setDaySelected, setShowEventModal,
    dispatchCalEvent, setSelectedEvent, setLabels, updateLabel,
  }}>
    {clientEmrState.isReady && props.children}
  </clientEmrStore.Provider>
}

export const useClientEmrStore = () => useContext(clientEmrStore);
