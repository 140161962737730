import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native';
import { Avatar, Subheading, Paragraph, Surface, TextInput } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
//
import { Address } from '@eksoh/flo/model';
import { appMaxWidth } from '@eksoh/flo/ui';
import { RootStackScreenProps } from '../types';
import Logo from '../cmps/Logo';
import { scale /* , moderateScale */ } from '../Scaling';
import { authStore } from '@eksoh/shared/ui';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import { BeServices, Storage } from '@eksoh/shared/ui';

// https://github.com/fateh999/react-native-paper-form-builder/blob/d0487b3bc5ff9f36370b2f985587633b6222acee/example/AdvancedExample.tsx

export const stepStart = 1;
export const stepEnd = 2;

export default function Onboarding1PostInterview({ navigation, route }: RootStackScreenProps<'Onboarding1PostInterview'>) {
  const { authState, onboarding, refreshAuth } = useContext(authStore);
  const [mounted, setMounted] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [codeOk, setCodeOk] = useState<boolean>();
  const [t] = useTranslation();

  const { control, setValue, setFocus, formState: { isDirty, isValid }, handleSubmit } = useForm<Address>({
    defaultValues: {
      formatted: '',
      streetAddress: '',
      locality: '',
      region: '',
      postalCode: '',
      country: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (mounted) return;

    const code = route.params?.code;
    Storage.local.set('onbCode', code);

    if (authState.user?.address != null) {
      let addrObj = authState.user.address;
      if (addrObj.formatted != null) {
        try { addrObj = JSON.parse(addrObj.formatted); } catch (_) { /* */ }
      }
      setValue('formatted', authState.user.address.formatted);
      setValue('streetAddress', authState.user.address.streetAddress || addrObj.streetAddress || '');
      setValue('locality', authState.user.address.locality || addrObj.locality || '');
      setValue('region', authState.user.address.region || addrObj.region || '');
      setValue('postalCode', authState.user.address.postalCode || addrObj.postalCode || '');
      setValue('country', authState.user.address.country || addrObj.country || 'canada');
    }

    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, authState.user?.address, setValue]);

  useEffect(() => {
    if (authState.onboarding?.fullAddress != null) {
      navigation.push('Onboarding2CardsScan');
    }
  }, [authState.onboarding, navigation]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (codeOk != null) return; // tested already

      if (authState.user == null) {
        navigation.push('Login');
        return;
      }

      const code = Storage.local.get('onbCode');
      // Remove onbCode in local storage
      if (code != null) Storage.local.remove('onbCode');

      if (authState.user?.emailVerified) {
        setCodeOk(true);
        return;
      }

      if (!authState.user?.emailVerified && code === '-%3D%2B%3D-') {
        setCheckEmail(true);
        setCodeOk(false);
        return;
      }

      async function fetchData(code: string) {
        // console.info('>>> CODE IS', code);
        try {
          setCodeOk(await BeServices.getInstance().user.validateCode(code) || false);
          // console.info('>>> IS CODE OK:', isOk);
          await refreshAuth();
        }
        catch (error) {
          console.info('validation code error:', error);
        }
      };

      if (code != null && code !== 'undefined' && code !== '') fetchData(code);
      else setCodeOk(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, codeOk, authState.user, route.params?.code, navigation])
  );

  function renderCheckEmail() {
    return <>
      <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Paragraph style={{ color: '#A5A5A5' }}>{t('nurse.postIntvCheckEmail')}</Paragraph>
      </Surface>
      <TouchableOpacity onPress={async () => { await Auth.signOut(); }} style={styles.link}>
        <Subheading style={styles.linkText}>{t('shorts.logout')}</Subheading>
      </TouchableOpacity>
    </>
  }

  function renderBadCode() {
    return <>
      <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Paragraph style={{ color: '#A5A5A5' }}>{t('nurse.postIntvBadCode')}</Paragraph>
      </Surface>
      <TouchableOpacity onPress={async () => { await Auth.signOut(); }} style={styles.link}>
        <Subheading style={styles.linkText}>{t('shorts.logout')}</Subheading>
      </TouchableOpacity>
    </>
  }

  function submitNurseOnbording(addr: Address) {
    // TODO @fp ATCD: here, we don't change the state ... we have to state only when ATCD consent is uploaded
    onboarding({ fullAddress: addr });
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={1} onClose={() => navigation.push('Root')} step='nurse.onboarding' />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Logo style={{ marginTop: scale(8) }} width={50} height={50} />
        <View style={{ display: 'flex', maxWidth: '80%' }}>
          <Paragraph style={{ marginLeft: scale(6), textAlign: 'center' }}>{t('nurse.piNeedFullAddr1')}</Paragraph>
          <Paragraph style={{ marginTop: scale(6), marginLeft: scale(6), textAlign: 'center' }}>{t('nurse.piNeedFullAddr2')}</Paragraph>
        </View>
      </View>
      {!codeOk && checkEmail && renderCheckEmail()}
      {!codeOk && !checkEmail && renderBadCode()}
      {codeOk && <View style={{ width: '100%', paddingTop: 6 }}>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          inputSpacing={5}
          formConfigArray={[
            {
              name: 'streetAddress',
              type: 'text',
              textInputProps: {
                label: t('nurse.addrLine1'),
                left: <TextInput.Icon name='map-marker' />,
                // autoComplete: 'postal-address',
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.addrLine1')} ${t('forms.isReq')}`,
                },
              },
            },
            {
              name: 'locality',
              type: 'text',
              textInputProps: {
                label: t('nurse.addrCity'),
                left: <TextInput.Icon name='city' />,
                // autoComplete: 'postal-address-locality',
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.addrCity')} ${t('forms.isReq')}`,
                },
              },
            },
            {
              name: 'region',
              type: 'text',
              textInputProps: {
                label: t('nurse.addrProv'),
                left: <TextInput.Icon name='map-marker' />,
                // autoComplete: 'postal-address-region',
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.addrProv')} ${t('forms.isReq')}`,
                },
              },
            },
            {
              name: 'postalCode',
              type: 'text',
              textInputProps: {
                label: t('nurse.addrPostal'),
                left: <TextInput.Icon name={'email-outline'} />,
                // autoComplete: 'postal-code',
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.addrPostal')} ${t('forms.isReq')}`,
                },
                pattern: {
                  value: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                  message: `${t('nurse.addrPostal')} ${t('forms.isInvalid')}`,
                },
                minLength: {
                  value: 6,
                  message: t('nurse.addrPostalErr'),
                },
                maxLength: {
                  value: 7,
                  message: t('nurse.addrPostalErr'),
                },
              },
            },
            {
              name: 'country',
              type: 'autocomplete',
              textInputProps: {
                label: t('nurse.addrCountry'),
                left: <TextInput.Icon name={'flag'} />,
                // autoComplete: 'postal-address-country',
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.addrCountry')} ${t('forms.isReq')}`,
                },
              },
              options: [
                {
                  label: 'canada',
                  value: 'canada',
                },
              ],
            },
          ]}
        />
      </View>}
    </ScrollView>
    <OnboardingFooter
      disabledBut={!codeOk || !isValid || !isDirty}
      onNext={handleSubmit(submitNurseOnbording)} hideLater
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 24,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  link: {
    marginTop: 10,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
