import { useContext, useState } from 'react';
import { StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
//
import { notifStore, MapContainerTracker } from '@eksoh/shared/ui';
import { Text, View, Icon } from '../cmps/Themed';
import { RootTabScreenProps } from '../types';

export default function TabGeoloc({ navigation }: RootTabScreenProps<'TabGeoloc'>) {
  const { notifState } = useContext(notifStore);
  const [start, setStart] = useState(false);

  return <View style={styles.container}>
    <View style={{ flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      {!start && <>
        <TouchableOpacity style={[styles.btn, { width: 120 }]} onPress={() => setStart(true)}>
          <Text>start tracking</Text>
        </TouchableOpacity>
        <Text>{`(notif os: ${notifState.info.os}, registered: ${notifState.info.registered})`}</Text>
        <Text>{`(log: ${notifState.info.log})`}</Text>
        <Text>{`(error: ${JSON.stringify(notifState.error, null, 2)})`}</Text>
      </>}
      {start && <MapContainerTracker markerMe={<Icon name='home' size={20} color='#e57373' />} />}
    </View>
    <Text>Nav canGoBack: {navigation.canGoBack() ? 'yup' : 'nope'}.</Text>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
  paragraph: {
    fontSize: 18,
    marginBottom: 150,
  },
  btn: {
    backgroundColor: 'lightblue',
    padding: 10,
    margin: 10,
    borderRadius: 5,
  },
});
