import { useMemo, useEffect } from 'react';
//
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import { useNavigate } from 'react-router-dom';
// import { startOfWeek, addDays, addWeeks } from 'date-fns';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
//
import { weekDays } from '@eksoh/shared/common';
import { OffersList, WeekOffers, DowOffers, OfferRange } from '@eksoh/shared/ui';
import { MiniTimeofferInfo } from '../../modules/appointments/cmps/timeoffer-info';

export interface WeeklyViewLabelButton {
  color?: string;
  date: number;
  day: number;
  today: number;
}

export function WeeklyViewLabel({ color, date, day, today }: WeeklyViewLabelButton) {
  const [t] = useTranslation();

  return <Paper
    elevation={3}
    style={{
      display: 'flex', width: '12.5%', height: 30, justifyContent: 'center', alignItems: 'center',
      backgroundColor: 'tranparent',
    }}
  >
    <Typography variant='overline' align='center' color='text.secondary' component='p' sx={{ color: color }}>
      {`${t(Object.keys(weekDays)[day])} (${date})`}
    </Typography>
  </Paper>
}

//
// ===
//

export interface WeeklyViewButton {
  day: number;
  today: number;
  rowHeight?: number | string;
  color?: string;
  dowOffers?: DowOffers;
  onClick: (toui: OfferRange) => void;
}

export function WeeklyViewButton({ color, day, today, rowHeight, dowOffers, onClick }: WeeklyViewButton) {
  function getColor() {
    if (dowOffers == null) return 'lightgrey';
    return undefined;
  }

  // function toggleState() {
  //   if (day !== today) return;
  //   if (state === MonthlyFppCellStates.NOTHING) setState(MonthlyFppCellStates.STARTED);
  //   else if (state === MonthlyFppCellStates.STARTED) setState(MonthlyFppCellStates.DONE);
  //   else if (state === MonthlyFppCellStates.DONE) setState(MonthlyFppCellStates.NOTHING);
  //   onClick();
  // }

  // return day === today
  //   ? <Button
  //       variant='outlined'
  //       // onClick={toggleState}
  //       style={{
  //         display: 'flex', width: '12.5%', minWidth: '12.5%', maxWidth: '12.5%',
  //         height: rowHeight || 200, justifyContent: 'center', alignItems: 'center',
  //         backgroundColor: getColor(),
  //       }}>
  //       {touis != null ? touis.map((toui, idx) => <MiniTimeofferInfo key={'toui_but_' + idx} toui={toui} />) : null}
  //     </Button>
  //   : <Paper
  //       elevation={3}
  //       // onClick={onClick}
  //       style={{
  //         display: 'flex', width: '12.5%', height: rowHeight || 200, justifyContent: 'center', alignItems: 'center',
  //         backgroundColor: getColor(),
  //       }}
  //     >
  //       {touis != null ? touis.map((toui, idx) => <MiniTimeofferInfo key={'toui_ppr_' + idx} toui={toui} />) : null}
  //     </Paper>
  const now = new Date();
  return <Paper
    elevation={3}
    // onClick={onClick}
    style={{
      display: 'flex', width: '12.5%', minHeight: rowHeight || 200, alignItems: 'center', // justifyContent: 'center',
      backgroundColor: getColor(), padding: 6, flexDirection: 'column',
    }}
  >
    {
      dowOffers?.ranges?.map((dowo, idx) => <MiniTimeofferInfo
        key={'toui_ppr_' + idx} offerRange={dowo} onClick={onClick} color={color}
      />)
    }
  </Paper>
}

//
// ===
//

export interface WeeklyViewProps {
  rowHeight?: number | string;
  lblColor?: string;
  color?: string;
  startSunday?: boolean;
  weekOffers: WeekOffers;
  onClick: (toui: OfferRange) => void;
}

export function WeeklyView({ lblColor, color, rowHeight, startSunday, weekOffers, onClick }: WeeklyViewProps) {
  // const navigate = useNavigate();

  const date = useMemo(() => new Date(), []);
  // const start = useMemo(() => addWeeks(startOfWeek(date), weekOffset || 0), [date]);
  const today = useMemo(() => date.getDate(), [date]);
  // // const end = endOfWeek(date);
  // // Default start day of the week is Sunday (0), but it can be changed to Monday (1) like this:
  // // const start = startOfWeek(date, { weekStartsOn: 1 });
  // // const end = endOfWeek(date, { weekStartsOn: 1 });
  // const daysAry = useMemo(() => {
  //   const d = date.getDay();
  //   return startSunday
  //     ? Array.from(Array(7).keys()).map((_, i) => i)
  //     : Array.from(Array(7).keys()).map((_, i) => d + i);
  // }, [date, startSunday]);

  // const offerAry = useMemo(() => {
  //   return daysAry.map(day => {
  //     const date = addDays(start, day);
  //     return <WeeklyViewButton
  //       key={'tosContainer' + day} rowHeight={rowHeight}
  //       day={date.getDate()} today={today}
  //       touis={dtoui[date.getDay()]}
  //       onClick={onClick}
  //     />
  //   });
  // }, [daysAry]);

  return <>
    <Container maxWidth='lg' component='main'>
      <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' py={2}>
        {/* {daysAry.map(day => {
          const date = addDays(start, day);
          return <WeeklyViewLabel key={'tosLbl' + day} date={date.getDate()} day={date.getDay()} today={today} />
        })} */}
        {weekOffers.map((wo, idx) => <WeeklyViewLabel
          key={'tosLbl' + idx} date={DateTime.fromISO(wo.day).day}
          day={wo.weekday % 7} today={today} color={lblColor}
        />)}
      </Box>
    </Container>
    <Container maxWidth='lg' component='main'>
      <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' pb={2}>
        {weekOffers.map((wo, idx) => <WeeklyViewButton
          key={'tosContainer' + idx} rowHeight={rowHeight} color={color}
          day={wo.weekday} today={today}
          dowOffers={wo}
          onClick={onClick}
        />)}
      </Box>
    </Container>
  </>
}

//
// ===
//

export interface BiWeeklyViewProps {
  rowHeight?: number | string;
  lblColor?: string;
  color?: string;
  startSunday?: boolean;
  offerList: OffersList;
  onClick: (offer: OfferRange) => void;
}

export function BiWeeklyView({ rowHeight, lblColor, color, startSunday, offerList, onClick }: BiWeeklyViewProps) {
  // const navigate = useNavigate();

  // Not tested yet...
  const week1 = useMemo(() => offerList?.weeks.filter((_, i) => i < 7) || [], [offerList]);
  const week2 = useMemo(() => offerList?.weeks.filter((_, i) => i >= 7 && i < 14) || [], [offerList]);

  // // useEffect(() => console.log('>>> WEEK1:', week1), [week1]);
  // // useEffect(() => console.log('>>> WEEK2:', week2), [week2]);
  // useEffect(() => {
  //   console.log('>>> WEEKS:', offerList?.weeks);
  //   // console.log('>>> WEEKS DDD:', offerList?.weeks.map(w => DateTime.fromISO(w.day).toJSDate()));
  // }, [offerList]);

  return <>
    <WeeklyView weekOffers={week1} onClick={onClick} rowHeight={rowHeight} lblColor={lblColor} color={color} startSunday={startSunday} />
    <WeeklyView weekOffers={week2} onClick={onClick} rowHeight={rowHeight} lblColor={lblColor} color={color} startSunday={startSunday} />
  </>
}
