import { useContext, useState } from 'react';
import { Text, View, TouchableHighlight } from 'react-native';
import { Menu } from 'react-native-paper';
import i18n from 'i18next';
//
import { globalStore, authStore } from '@eksoh/shared/ui';
// import { scale, moderateScale } from '../Scaling';

export interface LanguageBannerProps {
  localeColor?: string;
  mr?: string | number;
}

export default function LanguageBanner({ localeColor, mr }: LanguageBannerProps) {
  const { globalState } = useContext(globalStore);
  const { authState } = useContext(authStore);
  const [menu, setMenu] = useState(false);

  function changeLang(lang: string) {
    if (i18n.language !== lang) i18n.changeLanguage(lang);
    setMenu(false);
  }

  return <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 16 }}>
    <Menu
      visible={menu}
      onDismiss={() => setMenu(false)}
      anchor={<TouchableHighlight onPress={() => setMenu(true)}>
        <Text style={{ textAlign: 'right', marginRight: mr ?? 16, color: localeColor }}>
          {i18n.language === 'fr' ? 'en' : 'fr'}
        </Text>
      </TouchableHighlight>}>
      <Menu.Item onPress={() => changeLang('en')} title='en' />
      <Menu.Item onPress={() => changeLang('fr')} title='fr' />
      {globalState.isDev && <Menu.Item onPress={() => console.log(authState)} title='dbg' />}
    </Menu>
  </View>
}
