import { randomShort } from "../utils/random-utils";
import { SES_EMAIL_SUFFIX } from '../constants';

export function getRandomEmail(suffix?: string | number) {
  return `success+jest${suffix || randomShort()}@${SES_EMAIL_SUFFIX}`;
}

// check if ApolloError is UnauthorizedError
// export function isUnauthorizedError(error: any): boolean {
//   return error.hasOwnProperty('graphQLErrors') && error.graphQLErrors.length > 0 && error.graphQLErrors[0].hasOwnProperty('errorType') && error.graphQLErrors[0].errorType === 'Unauthorized';
// }
