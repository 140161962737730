/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
//
// import { Maybe, Hour, Professional, Specialty } from '@backend/core';
// INTEG import { ScheduleApiClient } from '@backend/services/apptmnt/client';

export interface HourDialogProps {
  cli?: any; // INTEG ScheduleApiClient;
  open: boolean;
  onClose: () => void;
  professional: string;
  specialty: string;
  dateBegin: string;
  dateEnd: string;
}

export function HourDialog({ cli, open, onClose, professional, specialty, dateBegin, dateEnd }: HourDialogProps) {
  const [loading, setLoading] = useState(true);
  const [hour, setHour] = useState<number[]>([20, 37]);
  const minDistance = 10;

  useEffect(() => {
    if (open) loadHours();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  async function loadHours() {
console.log('>>> HOURS:', await cli?.getHours({ professional, specialty, dateBegin, dateEnd }) || []);
    setLoading(false);
  }

  function onDone() {
    onClose();
  }

  function handleChange(event: Event, newValue: number | number[], activeThumb: number) {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setHour([clamped, clamped + minDistance]);
      }
      else {
        const clamped = Math.max(newValue[1], minDistance);
        setHour([clamped - minDistance, clamped]);
      }
    }
    else {
      setHour(newValue as number[]);
    }
  };

  return <Dialog open={open} onClose={onDone} maxWidth='md' fullWidth>
    <DialogTitle>hours</DialogTitle>
    {loading && <DialogContent style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
    </DialogContent>}
    {!loading && <DialogContent style={{ paddingTop: 32 }}>
      <Slider
        value={hour}
        onChange={handleChange}
        valueLabelDisplay='on'
        disableSwap
        style={{ zIndex: 1500 }}
      />
    </DialogContent>}
    {!loading && <DialogActions>
      <Button onClick={onDone}>cancel</Button>
      <Button onClick={onDone}>save</Button>
    </DialogActions>}
  </Dialog>
}
