import { useContext, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { StyleSheet, Text, View, TouchableOpacity, TouchableHighlight } from 'react-native';
import { TextInput, Checkbox, Snackbar } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useTranslation } from 'react-i18next';
//
import { awsPhoneNumber } from '@eksoh/shared/common';
import { authStore } from '@eksoh/shared/ui';
import { scale, moderateScale } from '../Scaling';
import { UnauthStackScreenProps } from '../types';
import Layout from '../cmps/Layout';
import LanguageBanner from '../cmps/LanguageBanner';
import Logo from '../cmps/Logo';
import { ValidationCode } from '../cmps/ValidationCode';
import { FirstPwd } from '../cmps/FirstPwd';
import { ResetPwd } from '../cmps/ResetPwd';
import { NewPwd } from '../cmps/NewPwd';

export default function LoginScreen({ navigation }: UnauthStackScreenProps<'Login'>) {
  const { authState, signIn, cancel, clearError, clearInfo } = useContext(authStore);
  const [mobile, setMobile] = useState('');
  const [pwd, setPwd] = useState('');
  const [remember, setRemember] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);
  const [t] = useTranslation();

  function submit() {
    if (mobile && pwd) {
      signIn(awsPhoneNumber(mobile), pwd);
    }
  };

  const showSnackbar = Boolean(authState.info) || Boolean(authState.error);
  return <>
    <Layout hideFab={true} phoneSize={true} contentContainerStyle={{ paddingLeft: 18, paddingRight: 18 }}>
      <LanguageBanner mr={0} />
      <Logo style={styles.logo} width={80} height={80} />
      <Text style={styles.tagline}>{t('login.flo.title')}</Text>
      <TouchableHighlight style={[styles.but, styles.butProv]} onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })}>
        <Text style={styles.butText}>
          {t('login.form.continueWith')}{' '}<Ionicons name='logo-facebook' size={24} />{' '}{t('login.form.facebook')}
        </Text>
      </TouchableHighlight>
      <TouchableHighlight style={[styles.but, styles.butProv]} onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>
        <Text style={styles.butText}>
          {t('login.form.continueWith')}{' '}<Ionicons name='logo-google' size={24} />{' '}{t('login.form.google')}
        </Text>
      </TouchableHighlight>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 12 }}>
        <View style={[styles.line, { marginRight: 12 }]} />
        <Text style={styles.butText}>{t('shorts.or')}</Text>
        <View style={[styles.line, { marginLeft: 12 }]} />
      </View>
      <View style={{ width: '100%', marginTop: 12 }}>
        <TextInput
          mode='outlined' label={t('login.form.mobile')}
          // outlineColor='white' activeOutlineColor='white' theme={{ colors: inputStyle }}
          value={mobile} onChangeText={text => setMobile(text)}
          render={props => <MaskedTextInput
            value={props.value} mask='+1 (999) 999-9999' style={props.style}
            onChangeText={(t, r) => { if (props.onChangeText) props.onChangeText(r); }}
          />}
        />
      </View>
      <View style={{ width: '100%', marginTop: 6, marginBottom: 6 }}>
        <TextInput
          mode='outlined' label={t('login.form.password-placeholder')} secureTextEntry={true}
          outlineColor='white' activeOutlineColor='white'
          value={pwd} onChangeText={text => setPwd(text)}
        />
      </View>
      <TouchableOpacity style={[styles.but, styles.butLog]} onPress={() => submit()} disabled={!mobile || !pwd}>
        <Text style={styles.butText}>{t('login.form.button')}</Text>
      </TouchableOpacity>
      <View style={{
        display: 'flex', justifyContent: 'space-between', flexDirection: 'row',
        width: '100%', marginTop: 12,
      }}>
        <Checkbox.Item
          label={t('login.form.rememberme')} position='leading' style={{ padding: 0 }} color='#333'
          status={remember ? 'checked' : 'unchecked'} onPress={() => setRemember(!remember)}
        />
        <TouchableOpacity onPress={() => setResetPwd(true)}>
          <Text style={{ marginTop: 10 }}>{t('login.password.forgot')}</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity onPress={() => navigation.replace('GetLoginInfo')}>
        <Text style={{ marginTop: 10 }}>{t('login.form.create')}</Text>
      </TouchableOpacity>
      <ValidationCode
        show={!showSnackbar && authState.needsMFA} fromSignUp={true} ovrMobile={mobile}
        onTryAgain={() => { cancel(); submit(); }} onDone={() => {/* */ }} onError={() => cancel()}
      />
      <Snackbar
        style={{ backgroundColor: 'red' }}
        visible={Boolean(authState.error)}
        onDismiss={() => { /* cancel() */ }}
        action={{
          label: t('shorts.close'),
          onPress: () => clearError(),
        }}
      >
        {t(authState.error || 'error.unexpected')}
      </Snackbar>
      <Snackbar
        style={{ backgroundColor: 'blue' }}
        visible={Boolean(authState.info)}
        onDismiss={() => { /* cancel() */ }}
        action={{
          label: t('shorts.close'),
          onPress: () => clearInfo(),
        }}
      >
        {t(authState.info || 'error.unexpected')}
      </Snackbar>
    </Layout>
    <FirstPwd
      show={!showSnackbar && authState.needsNewPwd}
      onCancel={() => cancel()}
    />
    <ResetPwd
      show={!showSnackbar && resetPwd}
      onDone={() => setResetPwd(false)}
    />
    <NewPwd
      show={!showSnackbar && authState.needsReset}
      onCancel={() => cancel()}
    />
  </>
}

const styles = StyleSheet.create({
  logo: {
    marginTop: scale(1),
  },
  tagline: {
    marginTop: scale(20),
    marginBottom: scale(20),
    fontSize: 25, // moderateScale(25),
    textAlign: 'center',
  },
  greySpot: {
    marginTop: scale(20),
    fontSize: moderateScale(25),
    textAlign: 'center',
    backgroundColor: '#323232',
    borderRadius: 20,
    width: 340,
    height: 256,
    alignItems: 'center',
    justifyContent: 'center',
  },
  greyText: {
    marginTop: scale(20),
    fontSize: moderateScale(15),
    textAlign: 'center',
    color: '#A5A5A5',
  },
  butProv: {
    paddingTop: 14,
  },
  butLog: {
    paddingTop: 20,
  },
  but: {
    marginRight: 24,
    marginLeft: 24,
    marginTop: 10,
    paddingBottom: 20,
    backgroundColor: 'transparent',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#323232',
    width: '100%',
    height: 63,
  },
  butText: {
    color: '#323232',
    textAlign: 'center',
  },
  line: {
    display: 'flex',
    flexGrow: 1,
    border: '0.5px solid #000',
    height: 1,
    marginTop: 9,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
  },
});
