import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { Surface, Avatar, Subheading, RadioButton } from 'react-native-paper';
// import DropDown from 'react-native-paper-dropdown';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { Transports } from '@eksoh/flo/model';
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';

export default function DisplType({ navigation }: RootStackScreenProps<'DisplType'>) {
  const { authState, onboarding } = useContext(authStore);
  const [transport, setTansport] = useState<Transports>(Transports.CAR);
  // const [showDropDown, setShowDropDown] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.displacementMeans?.transport != null) navigation.push('Upload');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  return <View style={styles.container}>
    <OnboardingHeader progress={9} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <Logo style={{ marginTop: scale(6) }} width={60} height={60} />
      <Subheading style={{ marginTop: scale(12), textAlign: 'center' }}>{t('nurse.displDesc')}</Subheading>
      {/* <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Text style={{ color: '#A5A5A5' }}>{t('nurse.displNotice')}</Text>
      </Surface>
      <View style={{ width: '100%' }}>
        <DropDown
          label={t('nurse.transports.mode')}
          mode='outlined'
          value={`${transport}`}
          setValue={setTansport}
          list={Object.keys(localeTransport).map((l, idx) => ({ label: t(localeTransport[idx as Transports]), value: l }))}
          visible={showDropDown}
          showDropDown={() => setShowDropDown(true)}
          onDismiss={() => setShowDropDown(false)}
        />
      </View> */}
      <View style={{ flexGrow: 1, width: '100%', marginTop: 24 }}>
        <RadioButton.Group onValueChange={value => setTansport(Transports[value as keyof typeof Transports])} value={Transports[transport]}>
          <Surface style={styles.surface}>
            <View style={{ marginRight: 12 }}>
              <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
            </View>
            <Text style={{ color: '#A5A5A5' }}>{t('nurse.displNotice')}</Text>
          </Surface>
          <RadioButton.Item label={t('nurse.transports.foot')} value={Transports[Transports.FOOT]} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }} />
          <RadioButton.Item label={t('nurse.transports.car')} value={Transports[Transports.CAR]} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }} />
        </RadioButton.Group>
        <View style={{ height: 95 }} />
      </View>
    </ScrollView>
    <OnboardingFooter onNext={() => onboarding({ displacementMeans: { transport }})} hideLater />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
