import Box from '@mui/material/Box';

import dayjs from 'dayjs';
//
import Day from './Day';

export default function Month({ month }: { month: dayjs.Dayjs[][] }) {
  return <Box flex={1} overflow='hidden' position='relative'>
    {month.map((row, i) => <Box key={'month_row' + i} style={{ flex: 1, display: 'flex', maxHeight: '20%', minHeight: '20%', height: '20%' }}>
      {row.map((day, idx) => (
        <Day key={'month_day' + idx} rowIdx={i} day={day} />
      ))}
    </Box>)}
  </Box>
}
