import { Countries, Provinces } from 'country-and-province';
import { Address, AddressInput } from "./generated/graphql";

export class AddressUtils {
  // constructor(parameters) {

  // }

  static normalizeAddressInput(input: AddressInput): Address {

    // TODO
    // - in formatted... put space in postal code, province code to name ??
    // country to code
    // province to code

    return {
      ...input,
      formatted: this.addressToFormatted(input),
      postalCode: input.postalCode.replace(/\s+/g, ''),
      ...(input.region && { region: this.provinceToCode(input.region) }),
      ...(input.country && { country: this.countryToCode(input.country) })
    };
  }

  static addressToFormatted(address: Address | AddressInput) {
    let formatted = '';
    if (address.streetAddress) formatted += '\n' + address.streetAddress; // Street
    if (address.locality) formatted += '\n' + address.locality; // City
    if (address.region) formatted += '\n' + address.region; // Province
    if (address.postalCode) formatted += '\n' + address.postalCode; // Postal code
    if (address.country) formatted += '\n' + address.country; // Country
    if (formatted.startsWith('\n')) formatted = formatted.substring(1);
    return formatted;
  }

  static provinceToCode(province: string) {
    if ('québec' === province.toLowerCase()) {
      return 'QC';
    } else {
      return Provinces.byName(province).code || Provinces.byCode(province).code || 'QC';
    }
  }

  static countryToCode(country: string) {
    return Countries.byName(country).code || Countries.byCode(country).code || 'CA';
  }

}