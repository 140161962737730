import { Gender } from '@eksoh/shared/common';

export function GenderIcon(gender: Gender) {
  switch (gender) {
  case Gender.Male: return 'gender-male';
  case Gender.Female: return 'gender-female';
  case Gender.TransMale:
  case Gender.TransFemale: return 'gender-transgender';
  case Gender.GenderQueer: return 'gender-male-female';
  default: return 'wc';
  }
};
