import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { Surface, Avatar, Checkbox, Subheading } from 'react-native-paper';
import { Slider } from '@miblanchard/react-native-slider';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import { TimeZones } from '@eksoh/flo/model';

export default function AvailabilityTimish({ navigation }: RootStackScreenProps<'AvailabilityTimish'>) {
  const { authState, onboarding } = useContext(authStore);
  const [range, setRange] = useState<TimeZones>({
    WeekDays: false,
    WeekEnds: false,
    Morning: false,
    Afternoon: false,
    Evening: false,
  });
  const [hours, setHours] = useState(6);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.calendar?.range != null) navigation.push('MissionType');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  return <View style={styles.container}>
    <OnboardingHeader progress={6} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <Logo style={{ marginTop: scale(6) }} width={60} height={60} />
      <Subheading style={{ marginTop: scale(12), textAlign: 'center' }}>{t('nurse.rangeDesc')}</Subheading>
      <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Text style={{ color: '#A5A5A5' }}>{t('nurse.range3h')}</Text>
      </Surface>
      <View style={{ width: '100%' }}>
        {/* {Object.keys(dispo).map((k, i) => <Checkbox.Item style={{ padding: 0 }}
          key={'tz' + i} label={k} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
          status={dispo[k] ? 'checked' : 'unchecked'} onPress={() => setDispo({ ...dispo, [k]: !dispo[k] })}
        />)} */}
        <Subheading style={{ marginTop: 12, marginBottom: 6 }}>{t('nurse.range')}</Subheading>
        <View style={{ width: '100%', flexDirection: 'row' }}>
          <Checkbox.Item style={{ padding: 0 }}
            label={t('nurse.weekDays')} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
            status={range.WeekDays ? 'checked' : 'unchecked'} onPress={() => setRange({ ...range, WeekDays: !range.WeekDays })}
          />
          <Checkbox.Item style={{ padding: 0 }}
            label={t('nurse.weekEnds')} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
            status={range.WeekEnds ? 'checked' : 'unchecked'} onPress={() => setRange({ ...range, WeekEnds: !range.WeekEnds })}
          />
        </View>
        <View style={{ width: '100%', flexDirection: 'row' }}>
          <Checkbox.Item style={{ padding: 0 }}
            label={t('nurse.morning')} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
            status={range.Morning ? 'checked' : 'unchecked'} onPress={() => setRange({ ...range, Morning: !range.Morning })}
          />
          <Checkbox.Item style={{ padding: 0 }}
            label={t('nurse.afternoon')} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
            status={range.Afternoon ? 'checked' : 'unchecked'} onPress={() => setRange({ ...range, Afternoon: !range.Afternoon })}
          />
          <Checkbox.Item style={{ padding: 0 }}
            label={t('nurse.evening')} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
            status={range.Evening ? 'checked' : 'unchecked'} onPress={() => setRange({ ...range, Evening: !range.Evening })}
          />
        </View>
      </View>
      <View style={{ width: '100%', paddingLeft: 14, paddingRight: 14, marginTop: 8 }}>
        <Subheading style={{ width: '100%', textAlign: 'center', marginBottom: 8 }}>{t('nurse.approx')}{hours}h</Subheading>
        <Slider
          minimumValue={3}
          maximumValue={40}
          minimumTrackTintColor='rgb(50, 50, 50)'
          maximumTrackTintColor='rgba(50, 50, 50, 0.38)'
          value={hours}
          onValueChange={val => setHours(Array.isArray(val) ? val[0] : val)}
          step={3}
        />
      </View>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 14, paddingRight: 14 }}>
        <Subheading>3h</Subheading>
        <Subheading>40h</Subheading>
      </View>
    </ScrollView>
    <OnboardingFooter onNext={() => onboarding({ calendar: { range, hours }})} hideLater />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
