import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function MedFiles({ to }: { to: string }) {
  const navigate = useNavigate();

  return <Box style={{ marginTop: 30 }}>
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Dossier médical</Typography>
      <Typography variant='caption' mt={1.4}>Relancer la demande</Typography>
      <IconButton onClick={() => { /* */ }}>
        <ReplayIcon />
      </IconButton>
    </Box>
    <Box height={10} />
    <Paper elevation={3} style={{ display: 'flex', flexDirection: 'row', padding: 6 }}>
      <Typography variant='caption' flexGrow={1} mt={1.3}>Documents reçus</Typography>
      <Typography variant='caption' mt={1.3}>0</Typography>
      <IconButton onClick={() => navigate(`/${to}/formsinfo/135430`)}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </Paper>
  </Box>
}
