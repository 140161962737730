/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  and,
  StatePropsOfMasterItem,
  ArrayLayoutProps,
  composePaths,
  computeLabel,
  createDefaultValue,
  findUISchema,
  isObjectArray,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import {
  withJsonFormsMasterListItemProps,
  JsonFormsDispatch, withJsonFormsArrayLayoutProps,
} from '@jsonforms/react';
import { ArrayLayoutToolbar } from './arraytoolbar';
import merge from 'lodash/merge';
import map from 'lodash/map';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';

const MasterItem = ({ index, childLabel, selected, handleSelect, removeItem, path }: StatePropsOfMasterItem) => {
  const [t] = useTranslation();

  return <Box display='flex' alignItems='center' onClick={handleSelect(index)} width='100%'>
    <Avatar aria-label='Index'>{index + 1}</Avatar>
    <Typography variant={selected ? 'button' : 'caption'} sx={{ width: '100%', ml: 1 }}>{t(childLabel || 'listAccordion.childLabel')}</Typography>
    <IconButton aria-label='Delete' onClick={removeItem(path, index)} size='large'>
      <DeleteIcon />
    </IconButton>
  </Box>
};

const ListAccordionMasterItem = withJsonFormsMasterListItemProps(MasterItem);

//
// ====
//

export const listAccordionRendererTester: RankedTester = rankWith(
  4,
  and(uiTypeIs('ListAccordion'), isObjectArray)
);

function ListAccordionRenderer({
  uischemas,
  schema,
  uischema,
  path,
  errors,
  visible,
  label,
  required,
  removeItems,
  addItem,
  data,
  renderers,
  cells,
  config
}: ArrayLayoutProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const handleRemoveItem = useCallback(
    (p: string, value: any) => () => {
      if (removeItems != null) removeItems(p, [value])();
      if (selectedIndex === value) {
        setSelectedIndex(undefined);
      }
      else if (selectedIndex != null && selectedIndex > value) {
        setSelectedIndex(selectedIndex - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeItems, setSelectedIndex],
  );
  const handleListItemClick = useCallback(
    (index: number) => () => setSelectedIndex(index),
    [setSelectedIndex],
  );
  const handleCreateDefaultValue = useCallback(
    () => createDefaultValue(schema),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createDefaultValue],
  );
  const foundUISchema = useMemo(
    () => findUISchema(
      uischemas || [],
      schema,
      uischema.scope,
      path,
      undefined,
      uischema,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uischemas, schema, uischema.scope, path, uischema],
  );
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const count = useRef(0);

  useEffect(() => {
    count.current = data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data > count.current) setSelectedIndex(count.current);
    count.current = data;
  }, [data]);

  return <Box display='flex' flexDirection='column'>
    <Box pl={2}>
      <ArrayLayoutToolbar
        label={computeLabel(
          label,
          required || false,
          appliedUiSchemaOptions.hideRequiredAsterisk
        )}
        errors={errors}
        path={path}
        addItem={addItem}
        createDefault={handleCreateDefaultValue}
      />
    </Box>
    {map(range(data), index => (
      <Accordion key={index} expanded={selectedIndex === index} onChange={() => setSelectedIndex(selectedIndex === index ? undefined : index)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListAccordionMasterItem
            index={index}
            path={path}
            schema={schema}
            handleSelect={handleListItemClick}
            removeItem={handleRemoveItem}
            selected={selectedIndex === index}
            key={index}
          />
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {selectedIndex !== undefined && <JsonFormsDispatch
            renderers={renderers}
            cells={cells}
            visible={visible}
            schema={schema}
            uischema={foundUISchema}
            path={composePaths(path, `${selectedIndex}`)}
          />}
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
}

export default withJsonFormsArrayLayoutProps(ListAccordionRenderer);
