import React from 'react';
// import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function createData(
  id: string,
  name: string,
  month: string,
  year: string,
) {
  return { id, name, month, year };
}

const rows = [
  createData('#135431', 'Cogir Real Estate S.E.N.C.', '92$/Mth', '1150$/Yr'),
  createData('#135432', 'Assomption', '85$/Mth', '1150$/Yr'),
  createData('#135433', 'Humania', '100$/Mth', '1150$/Yr'),
  createData('#135434', 'Canada Vie', '90$/Mth', '1150$/Yr'),
  createData('#135435', 'Manuvie', '92$/Mth', '1150$/Yr'),
  createData('#135436', 'Union Vie', '90$/Mth', '1150$/Yr'),
];

const rows2 = [
  createData('#135441', 'Karma Assurance', '', 'Risque élevé'),
  createData('#135442', 'Viaction', '', 'Risque élevé'),
];

const rows3 = [
  createData('#135431', 'Cooperator', '--', '--'),
  createData('#135432', 'RBC Life Insurance', '--', '--'),
];

export default function AssurerList() {
  // const navigate = useNavigate();

  return <Box width='100%'>
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Approuvées (6)</Typography>
    </Box>
    <Box height={10} />
    {rows.map((row, idx) => <Paper
      key={'a'+row.name} elevation={3} sx={{ mb: 2, p: 1 }}
      style={{
        display: 'flex', flexDirection: 'row',
        color: idx === 0 ? 'white' : undefined, backgroundColor: idx === 0 ? 'black' : undefined
      }}
    >
      <Typography variant='caption' flexGrow={1} mt={0.6}>{row.name}</Typography>
      <Typography variant='caption' mt={0.6} mr={2}>{row.month}</Typography>
      <Typography variant='caption' mt={0.6} mr={2}>{row.year}</Typography>
      <CheckCircleIcon />
    </Paper>)}
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Rejetées (2)</Typography>
    </Box>
    <Box height={10} />
    {rows2.map((row, idx) => <Paper
      key={'a'+row.name} elevation={3} sx={{ mb: 2, p: 1 }}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <Typography variant='caption' flexGrow={1} mt={0.6}>{row.name}</Typography>
      <Typography variant='caption' mt={0.6} mr={2}>{row.month}</Typography>
      <Typography variant='caption' mt={0.6} mr={2}>{row.year}</Typography>
      <CheckCircleIcon color='disabled' />
    </Paper>)}
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>En cours (8)</Typography>
    </Box>
    <Box height={10} />
    {rows3.map((row, idx) => <Paper
      key={'a'+row.name} elevation={3} sx={{ mb: 2, p: 1 }}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <Typography variant='caption' flexGrow={1} mt={0.6}>{row.name}</Typography>
      <Typography variant='caption' mt={0.6} mr={5}>{row.month}</Typography>
      <Typography variant='caption' mt={0.6} mr={5}>{row.year}</Typography>
      <CheckCircleIcon color='disabled' />
    </Paper>)}
  </Box>
}
