import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//
import { CounterTag } from '../shared/cmps/tag';
import { Rings } from './cmps/rings';
import { ClientsBySectors } from './cmps/sectors';
import ClientList from './cmps/clientlist';

export function Insurer() {
  return <>
    <Box display='flex'>
      <Box style={{ flex: 1 }}>
      <Typography variant='caption' style={{ marginBottom: 40 }}>
        Aperçu
      </Typography>
      <Typography variant='h6' style={{ marginBottom: 40 }}>
        Cogir Real Estate S.E.N.C.
      </Typography>
        <Box display='flex' style={{ marginBottom: 12 }}>
          <CounterTag title='Approuvée' num={6} label='Récent' of={1100} gutterRight />
          <ClientsBySectors isLight={true} clients={[]} />
          <Rings />
        </Box>
      </Box>
    </Box>
    <ClientList />
  </>
}
