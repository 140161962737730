import { useContext, useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { List, Avatar, Paragraph, TextInput, Snackbar } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import { useIsFocused } from '@react-navigation/native';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
// import { scale, moderateScale } from '../Scaling';
import { UnauthStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter from '../cmps/OnboardingFooter';
import { pwdStrengthChecker } from '../cmps/NewPwd';
import { ValidationCode } from '../cmps/ValidationCode';
import { awsPhoneNumber, validateEmailFormat, validateNorthAmericaPhoneNumber } from '@eksoh/shared/common';

export default function ExtraLoginInfo({ navigation }: UnauthStackScreenProps<'ExtraLoginInfo'>) {
  const { authState, signUp, signIn, cancel, onboarding, clearError, clearInfo } = useContext(authStore);
  const [pwd, setPwd] = useState('');
  const [pwdc, setPwdc] = useState('');
  // const [strength, setStrength] = useState<'S' | 'M' | 'W' | ''>('');
  const [strong, setStrong] = useState(false);
  const [same, setSame] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    if (!isFocused) return;
    if (
      authState.onboarding?.email == null || !validateEmailFormat(authState.onboarding.email) ||
      authState.onboarding?.phone == null || !validateNorthAmericaPhoneNumber(authState.onboarding.phone)
    ) {
      navigation.push('GetLoginInfo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  function strengthChecker(str: string) {
    setPwd(str);
    setStrong(pwdStrengthChecker(str));
  }

  function checkSame(str: string) {
    setPwdc(str);
    if (strong && pwd === str) setSame(true);
    else setSame(false);
  }

  async function next() {
    if (authState.onboarding?.phone == null || authState.onboarding?.email == null) return;
    const email = authState.onboarding.email;
    const phone = awsPhoneNumber(authState.onboarding.phone);
    onboarding({
      email: authState.onboarding.email,
      phone
    });
    await signUp(phone, pwd, {
      email: email,
      phone_number: phone,
      locale: `${i18n.language}_CA`, // TODO: Add i18n-iso-countries one day...
    });
  }

  async function getIn() {
    if (authState.error != null) return; // We have an error, abort
    if (authState.onboarding?.phone == null || authState.onboarding?.email == null || !pwd) return;
    try {
      await signIn(awsPhoneNumber(authState.onboarding.phone), pwd);
    }
    catch (error) {
      // TODO: handle me!!
      console.log('>>> error:', error);
    }
  }

  function tryAgain() {
    cancel();
    next();
  }

  const showSnackbar = Boolean(authState.info) || Boolean(authState.error);
  return <View style={styles.container}>
    <OnboardingHeader progress={0.125} onClose={() => navigation.push('Welcome')} localeColor='white' />
    <List.Section style={{ marginTop: 10, width: '100%' }}>
      <List.Item style={{ padding: 0 }}
        left={() => <Avatar.Icon icon='account-lock' size={64} color='white' style={{ backgroundColor: 'transparent' }} />}
        title={t('login.form.securityInfo')}
        titleNumberOfLines={2} titleStyle={{ color: 'white' }}
      />
    </List.Section>
    <Paragraph style={{ color: 'white', textAlign: 'center' }}>{t('login.form.detail')}</Paragraph>
    <form style={{ width: '100%' }} noValidate>
      <View style={{ width: '100%', marginTop: 12 }}>
        <TextInput
          mode='outlined' label={t('userManagement.phoneNumber')}
          outlineColor='white' activeOutlineColor='white' theme={{ colors: inputStyle }}
          value={authState.onboarding?.phone}
          editable={false}
          render={props => <MaskedTextInput
            value={props.value} mask='+1 (999) 999-9999' style={props.style}
            onChangeText={(t, r) => { if (props.onChangeText) props.onChangeText(r); }}
          />}
        />
      </View>
      <View style={{ width: '100%', marginTop: 12 }}>
        <TextInput
          mode='outlined' label={t('login.form.password')} secureTextEntry={true}
          outlineColor='white' activeOutlineColor='white' theme={{ colors: inputStyle }}
          value={pwd} onChangeText={text => strengthChecker(text)}
          right={pwd.length > 7 && <TextInput.Icon name={strong ? 'check' : 'close'} color={strong ? 'green' : 'red'} />}
        />
      </View>
      <View style={{ width: '100%', marginTop: 12 }}>
        <TextInput
          mode='outlined' label={t('login.form.password-confim')} secureTextEntry={true}
          outlineColor='white' activeOutlineColor='white' theme={{ colors: inputStyle }}
          value={pwdc} onChangeText={text => checkSame(text)}
          right={pwdc.length > 7 && <TextInput.Icon name={same ? 'check' : 'close'} color={same ? 'green' : 'red'} />}
        />
      </View>
    </form>
    <Paragraph style={{ color: 'white', marginTop: 16, textAlign: 'center' }}>{t('login.form.password-rules')}</Paragraph>
    <OnboardingFooter
      butText='generic.menu.account.register' hideLater
      disabledBut={!same} onNext={next} onBack={() => navigation.push('GetLoginInfo')}
    />
    <ValidationCode 
      show={!showSnackbar && authState.needsConfirm} fromSignUp={true}
      onTryAgain={() => tryAgain()} onDone={() => getIn()} onError={() => cancel()}
    />
    <Snackbar
      style={{ backgroundColor: 'red' }}
      visible={Boolean(authState.error)}
      onDismiss={() => { /* cancel() */ }}
      action={{
        label: t('shorts.close'),
        onPress: () => clearError(),
      }}
    >
      {t(authState.error || 'error.unexpected')}
    </Snackbar>
    <Snackbar
      style={{ backgroundColor: 'blue' }}
      visible={Boolean(authState.info)}
      onDismiss={() => { /* cancel() */ }}
      action={{
        label: t('shorts.close'),
        onPress: () => clearInfo(),
      }}
    >
      {t(authState.info || 'error.unexpected')}
    </Snackbar>
  </View>
}

const inputStyle = {
  placeholder: 'white',
  text: 'white',
  primary: 'white',
  underlineColor: 'transparent',
  background: '#4B4B4B',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    color: 'white',
    backgroundColor: '#4B4B4B',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  surface: {
    elevation: 1,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
