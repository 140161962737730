import { DateTime } from 'luxon';
//
// //
// import { Locale } from '../graphql/generated/graphql';
// import { LocaleClass } from '../model/LocaleClass';   // fullname here fix circular ref warning native

// import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from '@src/app/config/constants';

// export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

// export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);
// export const strISOToFr = (isoDate: Date) => DateTime.fromISO(String(isoDate), { locale: Locale.FR });
// export const strISOToEn = (isoDate: Date) => DateTime.fromISO(String(isoDate), { locale: Locale.EN });
// export const strISOToFrRelative = (isoDate: Date) => strISOToFr(isoDate).toRelative();
// export const strISOToEnRelative = (isoDate: Date) => strISOToEn(isoDate).toRelative();

export const isoToDateTime = (isoStrOrDate?: string | Date) => {
  // let result = ;
  if (isoStrOrDate) {
    if (typeof isoStrOrDate === 'string') {
      return DateTime.fromISO(isoStrOrDate);
    } else {
      return DateTime.fromJSDate(isoStrOrDate);
    }
  }
  return undefined;
};

export const isoToDateJs = (isoStrOrDate?: string | Date) => {
  // let result = ;
  if (isoStrOrDate) {
    if (typeof isoStrOrDate === 'string') {
      return new Date(isoStrOrDate);
    } else {
      return isoStrOrDate;
    }
  }
  return undefined;
};

export function getRoundUpDate() {
  const start = DateTime.now().startOf('minute');
  const remainder = 15 - (start.minute % 15);
  const dateTime = start.plus({ minutes: remainder === 15 ? 15 : remainder + 15 });
  return dateTime.toJSDate();
}

export function roundUpTime(date: Date, offset: number) {
  const start = DateTime.fromJSDate(date).startOf('minute');
  const remainder = start.minute % 15;
  // if offset is before, round to previous 15 mins otherwise, next one...
  const roundUpTo = offset < 0
    ? -remainder
    : remainder > 0 ? 15 - remainder : 0;
  const dateTime = start.plus({ minutes: roundUpTo + offset });
  return dateTime.toJSDate();
}

export function dateToInt(date: Date) {
  // const newDate = DateTime.fromJSDate(date);
  // return newDate.hour + Duration.fromObject({ minute: newDate.minute }).as('hour');
  return date.getHours() + parseFloat((date.getMinutes() / 60).toFixed(2));
}

// Assuming minutes are a fraction of 100. i.e. 25 = 15 mins, 50 = 30 mins...
export function intToDate(time: number, date: Date) {
  const newDate = new Date(date);
  newDate.setHours(Math.floor(time));
  const fraction = time % 1;
  newDate.setMinutes(Math.round(60 * fraction));
  return newDate;
}

export function stripTimeAndTz(dateParam?: Date | string) {
  return typeof dateParam === 'string' ? stripStrTimeAndTz(dateParam) : stripDateTimeAndTz(dateParam);
}

export function stripDateTimeAndTz(dateParam?: Date) {
  return dateParam && stripStrTimeAndTz(dateParam.toISOString());
}

export function stripStrTimeAndTz(dateStrParam?: string) {
  if(dateStrParam) {
    return new Date(dateStrParam.split('T')[0]);
  }
  return undefined;
}

export function toStringStripTimeAndTz(dateParam?: Date | string) {
  const d = stripTimeAndTz(dateParam);
  return d && d.toISOString().split('T')[0];
}
