import { ReactElement, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//
import {
  Appointment, User, TestAppmntTypes, TestTimeOffer, AppmntTypeDetails, PaymentInfoInput,
} from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { nectoStore, useNecto, OfferRange, localeTestTimeOffer, BiWeeklyView, eNectoStatus } from '@eksoh/shared/ui';
import { CurrencyInput, CurrencyInputOnChangeValues } from '@eksoh/shared/ui-web'; // TODO @fp: that a big NO NO !!!
import { PickSlotModal } from './cmps/pick-slot';
import { LocationInfo } from '../admin/user-mgnt/profs';

export interface BookingInfoProps {
  user: User;
  location: LocationInfo;
  appmt?: Appointment;
  finalData?: { date: Date, paymentInfo: PaymentInfoInput };
  onWaiting: (waiting: boolean) => ReactElement | null;
  onDateSelected: (date: Date) => void;
  onAppmtCreated: () => void;
}

export function BookingInfo({
  user, location, appmt, finalData, onWaiting, onDateSelected, onAppmtCreated,
}: BookingInfoProps) {
  const { nectoState } = useContext(nectoStore);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [appmntType, setAppmntType] = useState<TestAppmntTypes>();
  const [needPrice, setNeedPrice] = useState(false);
  const [price, setPrice] = useState('');
  const [timeOfferTest, setTimeOfferTest] = useState<TestTimeOffer>();
  const [pickSlot, setPickSlot] = useState<OfferRange>();
  //
  const {
    dailyTimeOffersUi, setUsers, fetchTimeOffer,
    clear, setAppmtType, paymentInfo, setPaymentInfo, createAppmt, // adminCreatePreAppmt,
  } = useNecto({ overrideGeoloc: location.coords, ignorePreAppnts: true });

  useEffect(() => {
    if (user != null) setUsers([user]); else setUsers([]);
  }, [user]);

  useEffect(() => {
    // console.log('>>> UE 2:', nectoState.appmntDetails, appmntType)
    if (nectoState.appmntDetails != null && appmntType != null) {
      if (nectoState.appmntDetails[appmntType].price === 0) { setNeedPrice(true); setPrice(''); }
      else setPrice(`${nectoState.appmntDetails[appmntType].price / 100}`);
    }
    else {
      setNeedPrice(false);
    }
  }, [nectoState.appmntDetails, appmntType]);

  useEffect(() => {
    // keep necto one if already set
    if (nectoState.newAppmtType != null) return;

    // console.log('>>> UE 3:', nectoState.appmntDetails, appmntType ? TestAppmntTypes[appmntType] : undefined, timeOfferTest ? TestTimeOffer[timeOfferTest] : undefined)
    if (nectoState.appmntDetails != null) { // TODO @fp: add ui feedback if no appmntDetails or useNecto to fetch
      // console.log('>>> UE 4:', nectoState.users, appmntType, timeOfferTest, needPrice, parseFloat(price) > 0)
      if (
        (nectoState.users?.length || 0) > 0 && appmntType != null && timeOfferTest != null &&
        (!needPrice || (needPrice && parseFloat(price) > 0))
      ) {
        setAppmtType(appmntType);
        return;
      }
    }
    setAppmtType(undefined);
  }, [nectoState.newAppmtType, nectoState.appmntDetails, nectoState.users, appmntType, timeOfferTest, needPrice, price]);

  useEffect(() => {
    if (finalData != null) {
      // setAppmtType(finalData.apType);
      setPaymentInfo(finalData.paymentInfo);
    }
  }, [finalData, nectoState.newAppmtType]);

  useEffect(() => {
    if (paymentInfo != null && finalData != null) {
      createAppmt(finalData.date); // TODO @fe: add ui feedback if needed
      setPickSlot(undefined);
      clear();
      onAppmtCreated();
    }
  }, [paymentInfo, finalData]);

  function handlePrice(value?: string, name?: string, values?: CurrencyInputOnChangeValues) {
    // setValues(values);
    // setRawValue(value === undefined ? 'undefined' : value || ' ');

    if (!value) {
      // setClassName('');
      setPrice('');
      return;
    }

    if (Number.isNaN(Number(value))) {
      // setErrorMessage('Please enter a valid number');
      // setClassName('is-invalid');
      return;
    }

    if (Number(value) > 10000) {
      // setErrorMessage(`Max: ${prefix}${limit}`);
      // setClassName('is-invalid');
      setPrice('10000.00'); // setPrice(value);
      return;
    }

    // setClassName('is-valid');
    setPrice(value);
  };

  function searchTOs() {
    if (appmntType == null || timeOfferTest == null) return;
    fetchTimeOffer(appmntType, timeOfferTest);
  }

  const numUsers = nectoState.users?.length || 0;
  const fetchingTOs = nectoState.status === eNectoStatus.FETCHING_TIMEOFFERS;
  return <Box display='flex' justifyContent='center' mt={2}>
    <Card style={{ width: '100%', textAlign: 'center' }}>
      <Typography variant='h6' component='h6' sx={{ mb: 2 }}>
        {'what and when?'}
      </Typography>
      <CardContent>
        <Box display='flex' flexDirection='row' mb={1}>
          <FormControl style={{ minWidth: 320, maxWidth: 320, marginRight: 12 }}>
            <InputLabel>{/* t('tlmd.sessions.duration') */ 'type'}</InputLabel>
            <Select
              label={/* t('tlmd.sessions.date') */ 'type'}
              value={appmntType || ''} onChange={e => setAppmntType(e.target.value as TestAppmntTypes)}
            >
              {
                Object.keys(TestAppmntTypes)
                  .filter(k => Object.keys(AppmntTypeDetails).includes(k))
                  .map((a, idx) => <MenuItem key={'atkey_' + idx} value={a}>
                    {t(AppmntTypeDetails[a as TestAppmntTypes].name)}
                  </MenuItem>)
              }
            </Select>
          </FormControl>
          <FormControl style={{ flexGrow: 1 }}>
            <InputLabel>{/* t('tlmd.sessions.duration') */ 'search'}</InputLabel>
            <Select
              label={/* t('tlmd.sessions.date') */ 'search'}
              value={timeOfferTest || ''} onChange={e => setTimeOfferTest(e.target.value as TestTimeOffer)}
            >
              {
                Object.keys(TestTimeOffer)
                  .filter(k => Object.keys(localeTestTimeOffer).includes(k))
                  .map((t, idx) => <MenuItem key={'tokey_' + idx} value={t}>
                    {localeTestTimeOffer[t as TestTimeOffer].fr}
                  </MenuItem>)
              }
            </Select>
          </FormControl>
          <CurrencyInput
            customInput={TextField} customInputProps={{ label: 'price' }} style={{ marginLeft: 12 }}
            prefix='$' step={1} min={0} max={100} disabled={!needPrice}
            value={`${price}`} onValueChange={handlePrice}
          />
        </Box>
        <Button
          variant='outlined' onClick={searchTOs} disabled={nectoState.newAppmtType == null}
          sx={{ mt: 2, color: '#1A73E8', borderColor: '#1A73E8' }}
        >
          search
        </Button>
        {onWaiting(fetchingTOs)}
        {!fetchingTOs && dailyTimeOffersUi != null && numUsers > 0 && <BiWeeklyView
          offerList={dailyTimeOffersUi} onClick={setPickSlot} lblColor='#7b809a' color='#1A73E8'
        />}
        {/* <TakeNotes notes={notes} onUpdate={setNotes} /> */}
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button size='small' onClick={() => navigate(-1)}>
          {t('shorts.back')}
        </Button>
      </CardActions>
    </Card>
    <PickSlotModal
      open={pickSlot != null} onClose={() => setPickSlot(undefined)}
      offerRange={pickSlot} onCancel={() => setPickSlot(undefined)}
      onCreate={onDateSelected}
    />
  </Box>
}
