import { 
  AUTH_TYPE, 
  AuthOptions
} from "aws-appsync-auth-link";
import { Credentials, CredentialProvider } from '@aws-sdk/types';
//
import {
  UpdatePatientRecordDocument,
  GetPatientRecordDocument,
  GetConsultRecordDocument,
  UpdateConsultRecordDocument,
  ListConsultRecordDocument,
  DeletePatienttRecordDocument,
  DeleteConsultRecordDocument,
  AdmUpdatePatientRecordDocument,
  AdmGetPatientRecordDocument,
  AdmGetConsultRecordDocument,
  AdmUpdateConsultRecordDocument,
  AdmListConsultRecordDocument,
  AdmDeletePatienttRecordDocument,
  AdmDeleteConsultRecordDocument,
  PatientRecord,
  ConsultRecord,
  // ListUsersDocument,
  // Group,
  AdmListPatientsDocument
} from '@eksoh/flo/model';
import { ApolloBase } from './apollobase';
import { AppsyncConfigParams } from '@eksoh/shared/common';


export class EmrClient extends ApolloBase {
  constructor(
    params: AppsyncConfigParams,
    jwtToken?: string,
    credentials?: (() => Credentials | CredentialProvider | Promise<Credentials | CredentialProvider | null>) | Credentials | CredentialProvider | null,
    tz?: string
    ) {
      let auth: AuthOptions;
      if (credentials) {
        auth = {
          type: AUTH_TYPE.AWS_IAM,
          credentials
        };
      } else {
        auth = {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => jwtToken || '',
        };
      }
      
      super(params, auth, tz);
  }

  async getPatientRecord() {
    const result = await this.client.query({
      query: GetPatientRecordDocument
    });
    return this.parseContent<PatientRecord>(result.data.getPatientRecord);
  }

  async updatePatientRecord(content: unknown) {
    const result = await this.client.mutate({
      mutation: UpdatePatientRecordDocument,
      variables: { input: { content: JSON.stringify(content) } }
    });
    return this.parseContent<PatientRecord>(result.data?.updatePatientRecord);
  }

  async deletePatientRecord() {
    const result = await this.client.mutate({
      mutation: DeletePatienttRecordDocument
    });
    return result.data?.deletePatientRecord;
  }

  async getConsultRecord(date: Date) {
    const result = await this.client.query({
      query: GetConsultRecordDocument,
      variables: { input: { date }}
    });
    return this.parseContent<ConsultRecord>(result.data.getConsultRecord);
  }

  async listConsultRecord() {
    const result = await this.client.query({
      query: ListConsultRecordDocument
    });
    return this.parseContents<ConsultRecord>(result.data.listConsultRecord);
  }

  async updateConsultRecord(date: Date, content: unknown) {
    const result = await this.client.mutate({
      mutation: UpdateConsultRecordDocument,
      variables: { input: { date, content: JSON.stringify(content) } }
    });
    return this.parseContent<ConsultRecord>(result.data?.updateConsultRecord);
  }

  async deleteConsultRecord(date: Date) {
    const result = await this.client.mutate({
      mutation: DeleteConsultRecordDocument,
      variables: { input: { date } }
    });
    return result.data?.deleteConsultRecord;
  }

  async admGetPatientRecord(username: string) {
    const result = await this.client.query({
      query: AdmGetPatientRecordDocument,
      variables: { username }
    });
    return this.parseContent<PatientRecord>(result.data.admGetPatientRecord);
  }

  async admUpdatePatientRecord(username: string, content: unknown) {
    const result = await this.client.mutate({
      mutation: AdmUpdatePatientRecordDocument,
      variables: { input: { username, content: JSON.stringify(content) } }
    });
    return this.parseContent<PatientRecord>(result.data?.admUpdatePatientRecord);
  }

  async admDeletePatientRecord(username: string) {
    const result = await this.client.mutate({
      mutation: AdmDeletePatienttRecordDocument,
      variables: { username }
    });
    return result.data?.admDeletePatientRecord;
  }

  async admGetConsultRecord(username: string, date: Date) {
    const result = await this.client.query({
      query: AdmGetConsultRecordDocument,
      variables: { input: { username, date }}
    });
    return this.parseContent<ConsultRecord>(result.data.admGetConsultRecord);
  }

  async admListConsultRecord(username: string) {
    const result = await this.client.query({
      query: AdmListConsultRecordDocument,
      variables: { username }
    });
    return this.parseContents<ConsultRecord>(result.data.admListConsultRecord);
  }

  async admUpdateConsultRecord(username: string, date: Date, content: unknown) {
    const result = await this.client.mutate({
      mutation: AdmUpdateConsultRecordDocument,
      variables: { input: { username, date, content: JSON.stringify(content) } }
    });
    return this.parseContent<ConsultRecord>(result.data?.admUpdateConsultRecord);
  }

  async admDeleteConsultRecord(username: string, date: Date) {
    const result = await this.client.mutate({
      mutation: AdmDeleteConsultRecordDocument,
      variables: { input: { username, date } }
    });
    return result.data?.admDeleteConsultRecord;
  }

  async listPatients() {
    const result = await this.client.query({
      query: AdmListPatientsDocument
    });
    return result.data.admListPatients;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseContent<T>(contentHolder: { content: any } | undefined | null): T {
    return (contentHolder && contentHolder.content && {
      ...contentHolder,
      content: JSON.parse(contentHolder.content),
    }) || contentHolder;
  }

  private parseContents<T>(contentHolders: { content: unknown }[] | undefined | null) {
    return contentHolders?.map(c => this.parseContent<T>(c));
  }
}
