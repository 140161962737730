import { SyntheticEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import { useTranslation } from 'react-i18next';
//
import { Group, User } from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { UserServiceClient } from '@eksoh/flo/ui'; // TODO @fp: that a big NO NO !!!

export interface UserSearchUIProps {
  beService: UserServiceClient;
  label: string;
  groups: Group[];
  onClick: (user: User | null) => void;
}

export function UserSearchUI({ beService, label, groups, onClick }: UserSearchUIProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly User[]>([]);
  const [value, setValue] = useState<User | null>(null);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!loading) {
      return;
    }

    listUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  async function listUsers() {
    // setLoading(true);
    try {
      const result = await beService.listUsers(groups);
      setOptions(result as User[]);
    }
    catch (error) {
      console.log('>>> listClient error:', error);
      setOptions([]);
    }
    // setLoading(false);
  }

  return <Autocomplete
    sx={{ width: '100%' }}
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    isOptionEqualToValue={(option, value) => option.username === value.username}
    getOptionLabel={(option) => `${option.name} (${option.email}, ${option.phoneNumber})`}
    value={value}
    onChange={(event: SyntheticEvent<Element, Event>, selUser: User | null) => {
      setValue(selUser);
      onClick(selUser);
    }}
    options={options}
    loading={loading}
    renderInput={(params) => <TextField
      {...params}
      label={label}
      InputProps={{
        ...params.InputProps,
        endAdornment: <>
          {loading ? <CircularProgress color='inherit' size={20} /> : null}
          {params.InputProps.endAdornment}
        </>,
      }}
    />}
  />
}

//
// Modal
//

export interface UserSearchModalProps extends UserSearchUIProps {
  open: boolean;
  onClose: () => void;
}

export function UserSearchModal(props: UserSearchModalProps) {
  const { open, onClose, ...rest } = props;
  // const [t] = useTranslation();
  return <Dialog open={open} maxWidth='lg' fullWidth={true}>
    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.8rem' }}>
      {'select resident'}
      <IconButton aria-label='close' style={{ position: 'absolute', right: 22, top: 22, padding: 0 }} onClick={onClose}>
        <CancelOutlinedIcon style={{ fontSize: '28px' }} />
      </IconButton>
    </DialogTitle>
    <Box sx={{ m: 2 }}><UserSearchUI {...rest} /></Box>
  </Dialog>
}
