import { useState, useEffect } from 'react';
import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';

// CS: AIzaSyB8BCdD_7Mtr5XBUUrH4N5gPg2kPVvRoA4 (CarpeDiem)
// EK: AIzaSyDAO3vEgUnCUFOKOA6Jhy4XZVaM2Zl4iOU (LandingPage)
// EK: AIzaSyBPca-CfyKeOC7BpbCzf6nixSBZYhTV4iw (Flowrence)

if (typeof window === 'undefined') {
  throw new Error('google map cannot be loaded outside browser env.');
}

let loader: Loader | null = null;

if (loader == null) {
  loader = new Loader({
    apiKey: 'AIzaSyDAO3vEgUnCUFOKOA6Jhy4XZVaM2Zl4iOU',
    id: '__googleMapsScriptId',
    // version: 'weekly',
    libraries: ['places'],
  });
}

export function useGoogleApi() {
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const [googleApiError, setGoogleApiError] = useState<unknown>();

  useEffect(() => {
    if (!googleApiLoaded && loader?.status === LoaderStatus.SUCCESS) {
      setGoogleApiLoaded(true);
      return;
    }

    loader?.loadCallback(e => {
      if (e != null) setGoogleApiError(e);
      else setGoogleApiLoaded(true);
    });
  }, [googleApiLoaded]);

  useEffect(() => {
    if (!googleApiLoaded && loader?.status === LoaderStatus.SUCCESS) setGoogleApiLoaded(true);
    else if (googleApiLoaded && loader?.status !== LoaderStatus.SUCCESS) setGoogleApiLoaded(false);
  }, [googleApiLoaded]);

  useEffect(() => {
    if (!googleApiLoaded && document.getElementById('__googleMapsScriptId') != null && window.google != null) setGoogleApiLoaded(true);
  }, [googleApiLoaded]);

  return { googleApiLoaded, googleApiError };
}
