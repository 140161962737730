// import styles from './are-you-sure.module.css';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import WarningIcon from '@mui/icons-material/Warning';

export enum eYesNo { 'YES' = 'YES', 'NO' = 'NO' }

export interface AreYouSureProps {
  title: string;
  warning?: string;
  msg: string;
  onChanged: (answer: eYesNo) => void;
  yesText?: string;
  noText?: string;
}

export function AreYouSure(props: AreYouSureProps) {
  const { title, warning, msg, onChanged, yesText, noText } = props;

  return <Card sx={{ minWidth: 275 }}>
    <CardHeader
      avatar={<WarningIcon style={{ color: 'red' }} />}
      title={title}
      subheader={warning}
    />
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
        {msg}
      </Typography>
    </CardContent>
    <CardActions style={{ justifyContent: 'flex-end' }}>
      <Button size='small'onClick={() => onChanged(eYesNo.NO)}>
        {noText || 'No'}
      </Button>
      <Button size='small' color='primary' onClick={() => onChanged(eYesNo.YES)}>
        {yesText || 'Yes'}
      </Button>
    </CardActions>
  </Card>
}

//
// ========
//

export interface AreYouSureModalProps extends AreYouSureProps {
  show: boolean;
  onClose: () => void;
}

export function AreYouSureModal(props: AreYouSureModalProps) {
  return <Dialog open={props.show} onClose={props.onClose}>
    <DialogContent style={{ padding: 0 }}>
      <AreYouSure {...props} />
    </DialogContent>
  </Dialog>
}
