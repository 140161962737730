import { useState } from 'react';
import { View } from 'react-native';
import { Button, Card, TextInput } from 'react-native-paper';
//
import { FFLayout } from './layout';

export interface FFInputTextProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  label?: string;
  onSelect: (text: string) => void;
  onBack?: () => void;
}

export function FFInputText(props: FFInputTextProps) {
  const [value, setValue] = useState('');

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<TextInput label={props.label} value={value} onChangeText={text => setValue(text)} />}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => props.onSelect(value)}>
        {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
