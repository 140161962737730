/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, ChangeEvent, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
// import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs, { Dayjs } from 'dayjs';
import { DateTime, Duration } from 'luxon';
//
import { montrealCoords } from '@eksoh/shared/common';
import { Appointment, PreAppointmentOperation } from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { authStore } from '@eksoh/shared/ui';
import { Waiting } from '@eksoh/shared/ui-web'; // TODO @fp: that a big NO NO !!!
import { listAppmtData, adminUpdatePreAppmt, AppmtResultType, PreAppmtResultType } from './reducer';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { BeServices } from '../../..';
import { ShowAppmtDetail } from './cmps/showstep';
import { PreAppmtInfoModal } from './cmps/pre-appmt-info';
import { slowGetDetail } from './cmps/appmt-info';
import { LocationInfo } from '../admin/user-mgnt/profs';

// AWS connect explained
// https://www.youtube.com/watch?v=syF1CEiWAto
// eFax: https://aws.amazon.com/marketplace/pp/prodview-ibwjfca4giq44
// twilio: https://www.twilio.com/blog/reliable-fax-pipeline-twilio-aws-expand-access-ballot-box

const styles = {
  table: {
    minWidth: 650,
  },
};

export interface AppointmentListProps {
  locations: LocationInfo[];
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function AppointmentList({ locations, onWaiting }: AppointmentListProps) {
  const { authState } = useContext(authStore);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [showStep, setShowStep] = useState<{ index: number, appmt: Appointment }>();
  const [from, setFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [ready, setReady] = useState(false);
  const [usePreAppmt, setUsePreAppmt] = useState(true);
  const [showPreAppmt, setShowPreAppmt] = useState(-1);
  const [locIdx, setLocIdx] = useState(0);
  const [t] = useTranslation();

  // const { match } = props;
  // const account = useAppSelector(state => state.authentication.account);
  const dataList = usePreAppmt
    ? useAppSelector(state => state.appointments.items) as PreAppmtResultType
    : useAppSelector(state => state.appointments.items) as AppmtResultType;
  const totalItems = useAppSelector(state => state.appointments.totalItems);
  const loading = useAppSelector(state => state.appointments.loading);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setReady(true), []);
  useEffect(() => { if (ready) listDataFromProps() }, [ready, from, usePreAppmt, locIdx]);
  // useEffect(() => { if (dataList) console.log('>>> APPMT LIST:', dataList) }, [dataList]);

  // const [pagination, setPagination] = useState(
  //   overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  // );

  function listDataFromProps() {
    dispatch(listAppmtData({
      usePreAppmt,
      from: from != null ? new Date(from.toDate().setHours(0, 0, 0, 0)) : undefined,
      lat: getLocation().coords.latitude,
      lng: getLocation().coords.longitude,
    }));
    // const endURL = `?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  // function autoNotes(notes: string, nextState: OnboardingState) {
  //   if (nextState === OnboardingState.ATCD_WAITING) {
  //     return notes + `\nBackground check sent on ${(new Date).toString()} by ${authState.user?.givenName} ${authState.user?.familyName}\n`;
  //   }
  //   if (nextState === OnboardingState.EMPLOYEE_INFO) {
  //     return notes + `\nBackground check approved on ${(new Date).toString()} by ${authState.user?.givenName} ${authState.user?.familyName}\n`;
  //   }
  //   if (nextState === OnboardingState.COMPLETED) {
  //     return notes + `\nEmployee accepted on ${(new Date).toString()} by ${authState.user?.givenName} ${authState.user?.familyName}\n`;
  //   }
  //   return notes;
  // }

  async function handleNextOnboardingStep(username: string, notes: string) {
    // await BeServices.getInstance().user.adminSetOnboardingState({
    //   username,
    //   state: nextState,
    //   notes: autoNotes(notes, nextState),
    // });
    listDataFromProps();
    setShowStep(undefined);
  };

  async function handleNotes(username: string, notes?: string) {
    await BeServices.getInstance().user.updateNotes({
      username,
      notes,
    });
  };

  async function confirmPreAppmt(rowIdx: number, cents: string, priceNotes: string, acceptedNotes: string) {
    if (rowIdx < 0) return;
    const data = (dataList as PreAppmtResultType)[rowIdx];
    if (data == null || data.code == null /* bad cast */) return;
    dispatch(adminUpdatePreAppmt({
      input: {
        code: data.code,
        lat: data.lat,
        lng: data.lng,
        username: data.username,
        operation: PreAppointmentOperation.ADM_ESTIMATE_FINISHED,
        price: parseInt(cents.replace('.', '').replace(',', '')),
        priceNotes,
        acceptedNotes,
      },
      from: from != null ? new Date(from.toDate().setHours(0, 0, 0, 0)) : undefined
    }));
    setShowPreAppmt(-1);
  }

  const locationFallback = { name: 'montreal', coords: montrealCoords };
  function getLocation() {
    if (locIdx < locations.length) return locations[locIdx];
    return locationFallback;
  }

  return <Box>
    <Box>
      <Box display='flex' justifyContent='space-between' style={{ padding: '30px 0' }}>
        <Typography variant='h4'>{`${usePreAppmt ? 'pre-' : ''}appointments`}</Typography>
        <Box display='flex'>
          {!usePreAppmt && <FormControl sx={{ mr: 3, minWidth: 160 }}>
            <InputLabel>location</InputLabel>
            <Select label='location' fullWidth value={locIdx} onChange={e => setLocIdx(e.target.value as number)}>
              {locations.map((l, idx) => <MenuItem key={`location-${idx}`} value={idx}>
                {l.name}
              </MenuItem>)}
            </Select>
          </FormControl>}
          <Box display='flex' flexDirection='column' mr={3}>
            <Typography variant='caption'>pre-appmnt</Typography>
            <Switch
              checked={usePreAppmt} onChange={(event: ChangeEvent<HTMLInputElement>) => setUsePreAppmt(event.target.checked)}
            />
          </Box>
          <DesktopDatePicker
            label='from'
            inputFormat='MM/dd/yyyy'
            value={from}
            onChange={val => setFrom(dayjs(val))}
            renderInput={(params) => <TextField {...params} />}
          />
          <IconButton
            onClick={() => navigate(`${location.pathname}/new`)}
            disabled={loading}
            size='large'
            style={{ marginLeft: 12, marginTop: -6 }}
          >
            <AddIcon style={{ fontSize: 24 }} />
          </IconButton>
          <IconButton onClick={listDataFromProps} disabled={loading} size='large' style={{ marginTop: -6 }}>
            <SyncIcon style={{ fontSize: 24 }} />
          </IconButton>
          {/* <IconButton onClick={() => navigate('/admin')} disabled={loading}>
            <ArrowBackIcon style={{ fontSize: 24 }} />
          </IconButton> */}
        </Box>
      </Box>
    </Box>
    {loading ? <Waiting colorSpinner='#0b4f61' height='65vh' /> : <Box style={{ paddingTop: 0 }}>
      <TableContainer component={Paper}>
        <Table style={styles.table} size='small'>
          <TableHead sx={{ display: 'table-header-group' }}>
            {usePreAppmt && <TableRow>
              <TableCell>code</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>createdAt</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>name</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>price</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>state</TableCell>
              <TableCell style={{ width: 1, whiteSpace: 'nowrap' }}>actions</TableCell>
            </TableRow>}
            {!usePreAppmt && <TableRow>
              <TableCell>{t('userManagement.name')}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>date</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>time</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>length</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>info</TableCell>
              <TableCell style={{ width: 1, whiteSpace: 'nowrap' }}>actions</TableCell>
            </TableRow>}
          </TableHead>
          <TableBody>
            {usePreAppmt && dataList != null && (dataList as PreAppmtResultType).map((data, i) => <TableRow key={`sas_${i}`}>
              <TableCell component='th' scope='row'>
                {data.code}
              </TableCell>
              <TableCell>
                {DateTime.fromISO(data.createdAt).setLocale('fr-CA').toLocaleString(DateTime.DATETIME_MED)}
              </TableCell>
              <TableCell>
                {/* {slowGetDetail(data, 'givenName')} */}
                {`${data.detail.givenName} ${data.detail.familyName}`}
              </TableCell>
              <TableCell>
                {data.price === 0 ? '---' : `$${(data.price / 100).toFixed(2)}`}
              </TableCell>
              <TableCell>
                {data.state}
              </TableCell>
              <TableCell style={{ width: 1, whiteSpace: 'nowrap' }}>
                {/* <IconButton onClick={() => setShowStep({ index: i, appmt: data as Appointment })} disabled={loading} size='large'>
                  <PhoneIcon style={{ fontSize: 24 }} />
                </IconButton>
                <IconButton onClick={() => navigate(`/booking/edit/${data.appmt.lng}/${data.appmt.lat}/${data.appmt.code}/${data.start}`)} disabled={loading} size='large'>
                  <EditIcon style={{ fontSize: 24 }} />
                </IconButton> */}
                <IconButton onClick={() => setShowPreAppmt(i)} disabled={loading} size='large'>
                  <VisibilityIcon style={{ fontSize: 24 }} />
                </IconButton>
              </TableCell>
            </TableRow>)}
            {!usePreAppmt && dataList != null && (dataList as AppmtResultType).map((data, i) => <TableRow key={`sas_${i}`}>
              <TableCell component='th' scope='row'>
                {slowGetDetail(data, 'givenName')}
              </TableCell>
              <TableCell>
                {(new Date(data.start)).toLocaleDateString('fr-CA')}
              </TableCell>
              <TableCell>
                {(new Date(data.start)).toLocaleTimeString('fr-CA').substring(0, 7).trim()}
              </TableCell>
              <TableCell>
                {`${Duration.fromISO(data.durationIso).as('minutes')} mins`}
              </TableCell>
              <TableCell>
                {/* {data.detail.fname}{' - '}<EmailIcon sx={{ mb: -1 }} /> {data.detail.email}{' -'}<SmartphoneIcon sx={{ mb: -1 }} />{data.detail.phone} */}
                <SmartphoneIcon sx={{ mb: -1, mr: 1 }} />{data.detail.phoneNumber}
              </TableCell>
              <TableCell style={{ width: 1, whiteSpace: 'nowrap' }}>
                <IconButton onClick={() => setShowStep({ index: i, appmt: data as Appointment })} disabled={loading} size='large'>
                  <PhoneIcon style={{ fontSize: 24 }} />
                </IconButton>
                <IconButton onClick={() => navigate(`/booking/edit/${data.lng}/${data.lat}/${data.code}/${data.start}`)} disabled={loading} size='large'>
                  <EditIcon style={{ fontSize: 24 }} />
                </IconButton>
                <IconButton onClick={() => {/* */ }} disabled={loading} size='large'>
                  <CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />
                </IconButton>
              </TableCell>
            </TableRow>)}
            {!totalItems && <TableRow>
              <TableCell component='th' scope='row' colSpan={5}>{'no appointments found.'}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <ShowAppmtDetail
        show={showStep != null}
        onAction={handleNextOnboardingStep}
        onDismiss={() => setShowStep(undefined)}
        data={dataList == null || showStep == null || (showStep.index < 0 && showStep.index > dataList.length - 1)
          ? undefined
          : dataList[showStep.index] as Appointment
        }
        onNoteUpd={handleNotes}
        onWaiting={onWaiting}
      />
      <PreAppmtInfoModal
        open={showPreAppmt !== -1}
        onClose={() => setShowPreAppmt(-1)}
        preAppmt={(dataList as PreAppmtResultType)[showPreAppmt]}
        // onAction={handleNextOnboardingStep}
        // data={dataList == null || showStep == null || (showStep.index < 0 && showStep.index > dataList.length - 1)
        //   ? undefined
        //   : dataList[showStep.index] as Appointment
        // }
        // onNoteUpd={handleNotes}
        onWaiting={onWaiting}
        onUpdate={(cents, priceNotes, acceptedNotes) => confirmPreAppmt(showPreAppmt, cents, priceNotes, acceptedNotes)}
        onCancel={() => setShowPreAppmt(-1)}
      />
    </Box>}
  </Box>
};
