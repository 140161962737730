import { useState } from 'react';
// import axios from 'axios';
import { Avatar, Card, /* IconButton, */ List, Banner } from 'react-native-paper';
//
import { useClientEmrStore } from '@eksoh/shared/ui';
// import { PatientFormValues } from '../AddPatientModal/AddPatientForm';
import { GenderIcon } from '../cmps/GenderIcon';
// import { AddPatientModal } from '../AddPatientModal';
import { HealthRatingBar } from '../cmps/HealthRatingBar';

// TODO @fp: is this useful? maybe the screens should stay in the apps and only
//           the cmps should be shared to avoid props as any. to evaluate...
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export function PatientList<ScreenProps>({ navigation }: any) { // ScreenProps) {
  const { clientEmrState /* , addPatient */ } = useClientEmrStore();

  // const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  // function closeModal() {
  //   setModalOpen(false);
  //   setError(undefined);
  // };

  // async function submitNewPatient(values: PatientFormValues) {
  //   // console.log('submitNewPatient:', values);
  //   try {
  //     // const { data: newPatient } = await axios.post<Patient>(
  //     //   `${apiBaseUrl}/patients`,
  //     //   values
  //     // );
  //     // dispatch(addPatient(newPatient));
  //     addPatient({ ...values, id: `${(new Date()).getTime()}`, entries: [] });
  //     // closeModal();
  //   }
  //   catch (e) {
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     console.error((e as any).response.data);
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     setError((e as any).response.data.error);
  //   }
  // };

  return (
    <>
      <Card.Title
        title='My Patients'
        subtitle='for my next missions'
      // right={(props) => <IconButton {...props} icon='plus-circle-outline' onPress={() => setModalOpen(true)} />}
      />
      <List.Section>
        {Object.values(clientEmrState.patients).map(patient => <div key={patient.id}>
          <List.Item
            title={patient.name}
            description={patient.occupation}
            left={props => <List.Icon {...props} icon={GenderIcon(patient.gender)} />}
            right={props => <HealthRatingBar showText={false} rating={1} />}
            onPress={() => navigation.navigate('PatientDetail', { id: patient.id })}
          />
        </div>)}
      </List.Section>
      {/* <AddPatientModal
        modalOpen={modalOpen}
        onSubmit={submitNewPatient}
        error={error}
        onClose={closeModal}
      /> */}
      <Banner
        visible={error != null}
        actions={[
          // {
          //   label: 'Fix it',
          //   onPress: () => setVisible(false),
          // },
          {
            label: 'Close',
            onPress: () => setError(undefined),
          },
        ]}
        icon={({ size }: {size: number }) => <Avatar.Icon size={size} icon='alert-circle' />}
      >
        {error || 'Unknown error.'}
      </Banner>
    </>
  );
};
