/**
  =========================================================
  * eksöh ® corporate kit
  =========================================================

  * Contact: François Paquette
  * Copyright 2022 eksöh ® (https://www.eksoh.com)

  Property of eksöh ®

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export const EmrEntryForm = {
  formId: 'new-entry-form',
  formField: {
    type: {
      name: 'type',
      label: 'Entry Type',
      type: 'text',
      errorMsg: 'Form Type is required.',
    },
    description: {
      name: 'description',
      label: 'Description',
      type: 'text',
      errorMsg: 'Description is required.',
    },
    date: {
      name: 'date',
      label: 'Date',
      type: 'text',
      errorMsg: 'Date is required.',
    },
    specialist: {
      name: 'specialist',
      label: 'Specialist\'s name',
      type: 'text',
      errorMsg: 'Specialist is required.',
      // invalidMsg: 'Your password should be more than 6 characters.',
    },
    healthCheckRating: {
      name: 'healthCheckRating',
      label: 'Health Check Rating',
      type: 'number',
      errorMsg: 'Health Check Rating is required.',
    },
    // 'discharge.date': {
    //   name: 'discharge.date',
    //   label: 'Discharge Date',
    //   type: 'text',
    //   errorMsg: 'Discharge Date is required.',
    // },
    // 'discharge.criteria': {
    //   name: 'discharge.criteria',
    //   label: 'Discharge Criteria',
    //   type: 'text',
    //   errorMsg: 'Discharge Criteria is required.',
    // },
    discharge: {
      date: {
        name: 'discharge.date',
        label: 'Discharge Date',
        type: 'text',
        errorMsg: 'Discharge Date is required.',
      },
      criteria: {
        name: 'discharge.criteria',
        label: 'Discharge Criteria',
        type: 'text',
        errorMsg: 'Discharge Criteria is required.',
      },
    },
    employerName: {
      name: 'employerName',
      label: 'Employer Name',
      type: 'text',
      errorMsg: 'Employer Name is required.',
    },
    sickLeave: {
      startDate: {
        name: 'sickLeave.startDate',
        label: 'Sick Leave Start Date',
        type: 'text',
        errorMsg: 'Sick Leave Start Date is required.',
      },
      endDate: {
        name: 'sickLeave.endDate',
        label: 'Sick Leave End Date',
        type: 'text',
        errorMsg: 'Sick Leave End Date is required.',
      },
    },
    diagnosisCodes: {
      code: {
        name: 'diagnoses.code',
        label: 'Diagnoses Code',
        type: 'text',
        errorMsg: 'Diagnoses Code is required.',
      },
      name: {
        name: 'diagnoses.name',
        label: 'Diagnoses Name',
        type: 'text',
        errorMsg: 'Diagnoses Name is required.',
      },
      latin: {
        name: 'diagnoses.latin',
        label: 'Diagnoses Latin',
        type: 'text',
      },
    },
  },
};
