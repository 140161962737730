/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {
  rankWith, uiTypeIs, RankedTester, ControlProps, Resolve, getData, update,
} from '@jsonforms/core';
import { withJsonFormsControlProps, JsonFormsStateContext, useJsonForms } from '@jsonforms/react';

export const autocompleteControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  uiTypeIs('AutocompleteControl'),
);

function AutocompleteControl({ id, enabled, label, data, schema, uischema, handleChange, path }: ControlProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const loading = open && options.length === 0;
  const ctx = useJsonForms();

  useEffect(() => {
    // let active = true;

    // if (!loading) {
    //   return undefined;
    // }

    // (async () => {
    //   await sleep(1e3); // For demo purposes.

    //   if (active) {
    //     setOptions([...top100Films]);
    //   }
    // })();
    setOptions(getOptions(ctx));

    // return () => {
    //   active = false;
    // };
  }, [ctx, loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  function getOptions(ctx: JsonFormsStateContext) {
    const childData = Resolve.data(getData({ jsonforms: { ...ctx } }), 'options');
    return childData;
  }

  const handleListItemClick = (val: any) => {
    if (ctx?.dispatch == null) return;
    ctx.dispatch(update('acSelection', () => { return val; }));
  };

  function buildOptionLabel(option: any) {
    const hasProps = Object.keys(option).length > 0;
    if (uischema.options?.labels == null || !hasProps) return hasProps ? Object.values(option)[0] : '';
    if (Array.isArray(uischema.options.labels)) {
      return uischema.options.labels.map(l => option[l] ?? '').join(', ');
    }
    else if (typeof uischema.options.labels === 'string') {
      return option[uischema.options.labels] ?? '';
    }
    else return '';
  }

  return <Autocomplete
    id='asynchronous-autocomplete-control'
    sx={{ width: '100%' }}
    open={open}
    onOpen={() => {
      setOpen(true);
    }}
    onClose={() => {
      setOpen(false);
    }}
    // noOptionsText={(event: React.SyntheticEvent, value: T | Array<T>, reason: string, details?: string) => void}
    onChange={(event: any, newValue: string | null) => {
      // console.log('>>> SEL:', event, newValue);
      handleListItemClick(newValue);
    }}
    isOptionEqualToValue={(option, value) => option.email === value.email}
    getOptionLabel={buildOptionLabel}
    options={options}
    loading={loading}
    renderInput={(params) => (
      <TextField
        {...params}
        label='search'
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color='inherit' size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />
}

export default withJsonFormsControlProps(AutocompleteControl);
