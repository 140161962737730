import {
  AUTH_TYPE,
  AuthOptions
} from "aws-appsync-auth-link";
//
import {
  FormStatusType,
  ListUserFormsDocument, // ListUserFormsInput, 
  GetUserFormDocument, // GetUserFormInput, 
  AdminListFormsDocument, // AdminListFormsInput, 
  AdminGetFormDocument,  // AdminGetFormInput, 
  //
  UpdUserFormDocument, // UpdUserFormInput, 
  CreateUserFormDocument, // CreateUserFormInput, 
  AdminCreateFormDocument,  // AdminCreateFormInput, 
  AdminUpdFormDocument, // AdminUpdFormInput, 
  AdminDelFormDocument, // AdminDelFormInput, 
} from '@eksoh/flo/model';
import { ApolloBase } from './apollobase';
import { AppsyncConfigParams } from '@eksoh/shared/common';


export class FormsClient extends ApolloBase {
  constructor(params: AppsyncConfigParams, jwtToken: string, tz?: string) {
    const auth: AuthOptions = {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => jwtToken
    };
    super(params, auth, tz);
  }

  // QUERIES

  async listUserForms(username?: string, status?: FormStatusType) {
    const result = await this.client.query({
      query: ListUserFormsDocument,
      variables: { input: { username, status } },
    });
    return result.data?.listUserForms;
  }

  async getUserForm(name: string, username?: string) {
    const result = await this.client.query({
      query: GetUserFormDocument,
      variables: { input: { name, username } },
    });
    return result.data?.getUserForm;
  }

  // QUERIES - ADMIN

  async adminListForms(category?: string) {
    const result = await this.client.query({
      query: AdminListFormsDocument,
      variables: { input: { category } },
    });
    return result.data?.adminListForms;
  }

  async adminGetForm(name: string) {
    const result = await this.client.query({
      query: AdminGetFormDocument,
      variables: { input: { name } },
    });
    return result.data?.adminGetForm;
  }

  // MUTATIONS

  async updUserForm(name: string, data: unknown, done?: boolean) {
    let strData = '';
    try {
      strData = typeof data === 'string' ? data : JSON.stringify(data);
    }
    catch (error) {
      console.log('>>> (adminUpdForm) Error stringifying json:', error);
      return undefined;
    }
    const result = await this.client.query({
      query: UpdUserFormDocument,
      variables: { input: { name, data: strData, done } },
    });
    return result.data?.updUserForm;
  }

  // MUTATIONS - ADMIN

  // and nurses...
  async createUserForm(username: string, name: string) {
    const result = await this.client.query({
      query: CreateUserFormDocument,
      variables: { input: { username, name } },
    });
    return result.data?.createUserForm;
  }

  async adminCreateForm(name: string, category: string, model: unknown, data?: unknown) {
    let strModel = '';
    try {
      strModel = typeof model === 'string' ? model : JSON.stringify(model);
    }
    catch (error) {
      console.log('>>> (adminUpdForm) Error stringifying json:', error);
      return undefined;
    }
    const result = await this.client.query({
      query: AdminCreateFormDocument,
      variables: { input: { name, category, model: strModel, data } },
    });
    return result.data?.adminCreateForm;
  }

  async adminUpdForm(name: string, model?: unknown, data?: unknown) {
    let strModel = '';
    try {
      strModel = typeof model === 'string' ? model : JSON.stringify(model);
    }
    catch (error) {
      console.log('>>> (adminUpdForm) Error stringifying json:', error);
      return undefined;
    }
    const result = await this.client.query({
      query: AdminUpdFormDocument,
      variables: { input: { name, model: strModel, data } },
    });
    return result.data?.adminUpdForm;
  }

  async adminDelForm(name: string) {
    const result = await this.client.query({
      query: AdminDelFormDocument,
      variables: { input: { name } },
    });
    return result.data?.adminDelForm;
  }
}
