import { useState } from 'react';
import { Storage } from '@aws-amplify/storage';
import { View, Text } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import { useTranslation } from 'react-i18next';

export interface useUploadFileProps {
  alwaysRender?: boolean;
}

export default function useUploadFile({ alwaysRender }: useUploadFileProps) {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>();
  const [t] = useTranslation();

  async function upload(file: File, name: string, onCompleted?: () => void) {
    try {
      setUploading(true);
      await Storage.put(name, file, {
        level: 'private',
        contentType: file.type,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        progressCallback(progress: any) {
          const prog = progress.loaded / progress.total;
          setProgress(prog);
          if (onCompleted != null && prog > 0.999) {
            setUploading(false);
            onCompleted();
          }
        },
      });
    }
    catch (error) {
      // TODO: handle me!
      console.log('>>> UPD ERROR:', error);
    }
  }

  function renderUplFile() {
    return <View style={{ width: '100%', borderWidth: 1, borderRadius: 5, padding: 16 }}>
      {(progress != null || alwaysRender) && <ProgressBar progress={progress} color='#323232' />}
      <Text style={{ width: '100%', textAlign: 'center', marginTop: 8 }}>{t('nurse.uplPogress')}</Text>
    </View>
  }

  return { uploading, progress, upload, renderUplFile };
}
