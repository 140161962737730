import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';
// import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
//
import { Diagnosis, Entry, EntryFormValues } from '@eksoh/shared/common';
import { useClientEmrStore } from '../../../../../../ui/src/lib/contexts/client-emr/client-emr';
import { AddEntryModal } from '../cmps/AddEntryModal';
import EntryDetails, { NoItems } from '../cmps/EntryDetails';
import DiagnosesList from '../cmps/DiagnosesList';
import { GenderIcon } from '../cmps/GenderIcon';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function PatientDetail() {
  const [expanded, setExpanded] = useState(false);
  const { id: patientIdMatch } = useParams<{ id: string }>();
  const { clientEmrState, updPatient } = useClientEmrStore();
  const patient = patientIdMatch != null ? clientEmrState.patients[patientIdMatch] : undefined;

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const closeModal = (): void => {
    setModalOpen(false);
    setError(undefined);
  };

  const submitNewEntry = async (data: EntryFormValues) => {
    // console.log('submitNewEntry:', data);
    try {
      if (patient != null) {
        // fake.
        const newEntry: Entry = { ...data, id: `${(new Date()).getTime()}` } as Entry;
        updPatient({ ...patient, entries: [...patient.entries, newEntry] });
      }

      closeModal();
    }
    catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.error((e as any).response?.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any).response?.data?.error);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (!patient) {
    return (
      <Container>
        <h1>No patient found with such ID</h1>
        <pre>Submitted ID: {patientIdMatch}</pre>
      </Container>
    );
  }

  return (
    <Container>
      <Card sx={{ mt: 2, maxWidth: 600 }}>
        <CardHeader
          avatar={<GenderIcon gender={patient.gender} />}
          action={
            <IconButton aria-label='add' onClick={() => setModalOpen(true)}>
              <AddIcon />
            </IconButton>
          }
          title={patient.name}
          subheader={`${patient.occupation} (${patient.ssn})`}
        />
        <CardContent>
          {
            patient.entries.length === 0
              ? <NoItems />
              : patient.entries.map(entry => <EntryDetails key={entry.id} entry={entry}>
                {
                  entry.diagnosisCodes
                    ? <DiagnosesList diagnoses={entry.diagnosisCodes.map((code): Diagnosis =>
                      clientEmrState.diagnoses[code] ?? { code, name: 'Unknown' }
                    )} />
                    : <Typography variant='subtitle2'>No diagnosis code available for this entry</Typography>
                }
              </EntryDetails>)
          }
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label='add to favorites'>
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label='share'>
            <ShareIcon />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Typography paragraph>more info:</Typography>
            <Typography paragraph>
              Some more info...
            </Typography>
            <Typography paragraph>
              Some more more info...
            </Typography>
            <Typography paragraph>
              Some more more more info...
            </Typography>
            <Typography>
              Some more more more more info...
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      <AddEntryModal
        modalOpen={modalOpen}
        onSubmit={submitNewEntry}
        error={error}
        onClose={closeModal}
      />
    </Container>
  );
}
