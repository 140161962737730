/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RGL, { WidthProvider } from 'react-grid-layout';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
//
import { FormEngine } from '../../..';
import { EmrClient } from '@eksoh/flo/ui'; // TODO @fp: that a big NO NO !!!
import {
  patientInfo, patientInfoUI, patientInfoData,
  // medicalSummary, medicalSummaryUI, medicalSummaryData,
  /* consultation, consultationUI, */ consultationData, Consultation,
  //
  medications, medicationsUI, medicationsData,
  allergyIntolerance, allergyIntoleranceUI, allergyIntoleranceData,
  activeIssues, activeIssuesUI, activeIssuesData,
  habits, habitsUI, habitsData,
} from './forms';
//
import { PopperContainer } from './cmps/popper';
// import { EmrDashboard } from './cmps/dashboard';
import { EarningCard } from './cmps/emr-card';
import { EarningCard2 } from './cmps/emr-card2';
import { EarningCard3 } from './cmps/emr-card3';
// import { EmrSwipper } from './cmps/swipper';

const ReactGridLayout = WidthProvider(RGL);

export interface EmrForm {
  schema: any,
  ui: any,
  data: any,
}

export interface EmrUIProps {
  username: string;
  beService: EmrClient;
  extra?: EmrForm[];
  onExtraUpd?: (index: number, data: any) => void;
  onWaiting: (waiting: boolean) => ReactElement | null;
  onLayoutChange?: (lo: RGL.Layout[]) => void;
}

export function EmrUI({ username, beService, extra, onExtraUpd, onWaiting, onLayoutChange }: EmrUIProps) {
  const [loading, setLoading] = useState(false);
  const [piData, setPiData] = useState(patientInfoData);
  // const [msData, setMsData] = useState(medicalSummaryData);
  const [ctData, setCtData] = useState(consultationData);
  //
  const [medsData, setMedsData] = useState(medicationsData);
  const [algIntData, setAlgIntData] = useState(allergyIntoleranceData);
  const [actIssData, setActIssData] = useState(activeIssuesData);
  const [habsData, setHabsData] = useState(habitsData);
  const [layout, setLayout] = useState<RGL.Layout[]>([]);
  const data3 = useMemo(() => Array.from({ length: Math.floor(Math.random() * (9) + 8) }).map((_, i) => ({
    type: 'menu' as const,
    title: 'section ' + i,
    // cmd: 'data_3_cmd' + i,
    // border: '1px solid #ccc',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    height: 200,
  })), []);

  useEffect(() => {
    getPatientEmr();
    setLayout([
      { i: 'badge1', x: 1, y: 0, w: 3, h: 1 },
      { i: 'badge2', x: 4, y: 0, w: 4, h: 1 },
      { i: 'badge3', x: 8, y: 0, w: 3, h: 1 }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (piData.butvalid) {
      savePatientRecord();
      setPiData({ patientInfo: { ...piData.patientInfo }, butvalid: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [piData.butvalid]);

  // useEffect(() => {
  //   if (msData.butvalid) {
  //     savePatientRecord();
  //     setMsData({ medicalSummary: { ...msData.medicalSummary }, butvalid: undefined });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [msData.butvalid]);

  useEffect(() => {
    if (
      ctData.idxSelection == null ||
      ctData.idxSelection < 0 ||
      ctData.idxSelection >= ctData.consultations.length
    ) return;

    const data = ctData.consultations[ctData.idxSelection];
    if (!(data.locked || false) && data.butvalid === true) {
      saveConsultation(ctData.idxSelection, { ...data, locked: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctData.idxSelection, ctData.consultations]);

  async function getPatientEmr() {
    if (username == null) return;
    setLoading(true);
    try {
      const result = await beService.admGetPatientRecord(username);
      if (result?.content?.patientInfo != null) setPiData({ patientInfo: result?.content?.patientInfo as any });
      // if (result?.content?.medicalSummary != null) setMsData({ medicalSummary: result?.content?.medicalSummary as any });
      //
      if (result?.content?.medicalSummary != null) {
        setMedsData(result?.content?.medicalSummary?.medications as any);
        setAlgIntData(result?.content?.medicalSummary?.allergyIntolerance as any);
        setActIssData(result?.content?.medicalSummary?.activeIssues as any);
        setHabsData(result?.content?.medicalSummary?.habits as any);
      }
    }
    catch (error) {
      console.log('>>> getPatientEmr error:', error);
    }
    try {
      const result = await beService.admListConsultRecord(username);
      // console.log('>>> PAT CONSULTS:', result)
      if (result != null) setCtData({ ...ctData, consultations: result.map(c => c.content) as Consultation[] });
    }
    catch (error) {
      console.log('>>> getPatientEmr (consultations) error:', error);
    }
    setLoading(false);
  }

  async function savePatientRecord() {
    if (username == null) return; // TODO: add ui feedback
    try {
      /* const result = */ await beService.admUpdatePatientRecord(username, {
      ...piData,
      medicalSummary: {
        medications: medsData, allergyIntolerance: algIntData, activeIssues: actIssData, habits: habsData,
      },
    });
      // console.log('>>> UPD PAT INFO:', result)
    }
    catch (error) {
      console.log('>>> savePatientInfo error:', error);
    }
  }

  async function saveConsultation(idx: number, data: Consultation) {
    if (username == null || idx == null || data?.date == null) return; // TODO: add ui feedback
    try {
      const date = new Date(data.date);
      /* const result = */ await beService.admUpdateConsultRecord(username, date, data);
      // console.log('>>> UPD PAT INFO:', result)
      setCtData({ ...ctData, consultations: ctData.consultations.map((c, i) => i === idx ? data : c) });
    }
    catch (error) {
      console.log('>>> savePatientInfo error:', error);
      setCtData({ ...ctData, consultations: ctData.consultations.map((c, i) => i === idx ? { ...c, butvalid: undefined } : c) });
    }
  }

  return <Box display='flex' width='100%' flexDirection='column'>
    <Typography variant='h6'>SECURE NOTES</Typography>
    {onWaiting(loading)}
    {!loading && <>
      <PopperContainer mt={2}
        data={[
          {
            type: 'content',
            title: 'forms.patientInfo',
            content: () => <FormEngine key='fPI' form={{ schema: patientInfo, ui: patientInfoUI, data: piData }} onChange={setPiData} />,
          },
          {
            type: 'menu',
            title: 'forms.medicalSummary',
            data: [
              {
                type: 'content',
                title: 'medications',
                content: () => <FormEngine key='fMEDS' form={{ schema: medications, ui: medicationsUI, data: medsData }} onChange={setMedsData} />
              },
              {
                type: 'content',
                title: 'allergies',
                content: () => <FormEngine key='fALGTOL' form={{ schema: allergyIntolerance, ui: allergyIntoleranceUI, data: algIntData }} onChange={setAlgIntData} />
              },
              {
                type: 'content',
                title: 'activeIssues.title',
                content: () => <FormEngine key='fACTISS' form={{ schema: activeIssues, ui: activeIssuesUI, data: actIssData }} onChange={setActIssData} />
              },
              {
                type: 'content',
                title: 'habits.title',
                content: () => <FormEngine key='fACTISS' form={{ schema: habits, ui: habitsUI, data: habsData }} onChange={setHabsData} />
              }
            ],
          },
          {
            type: 'menu',
            title: 'forms.consultations',
            data: data3,
          },
        ]}
      />
      <ReactGridLayout
        layout={layout}
        onLayoutChange={onLayoutChange}
        className='layout' rowHeight={200} cols={12}
      >
        <div key='badge1'><EarningCard isLoading={false} /></div>,
        <div key='badge2'><EarningCard2 isLoading={false} /></div>,
        <div key='badge3'><EarningCard3 isLoading={false} /></div>,
      </ReactGridLayout>
      {/* <EmrSwipper /> */}
    </>}
  </Box>
}
