import React from 'react';
// import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

function createData(
  id: string,
  avatar: string,
  name: string,
  date: string,
  note: string,
) {
  return { id, avatar, name, date, note };
}

const rows = [
  createData('123', 'https://material-ui.com/static/images/avatar/1.jpg', 'Vous', 'Vendredi', 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'),
  createData('456', 'https://material-ui.com/static/images/avatar/2.jpg', 'Assureur', 'Vendredi', 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'),
  createData('789', 'https://material-ui.com/static/images/avatar/3.jpg', 'Collègue', 'Vendredi', 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'),
];

export default function Notes() {
  // const navigate = useNavigate();

  return <Box style={{ marginTop: 30 }}>
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Notes</Typography>
    </Box>
    <Box height={10} />
    <Stack direction='column'>
      {rows.map((row) => <Stack key={row.id} direction='column' sx={{ mb: 2 }}>
        <Stack direction='row' sx={{ mb: 1 }}>
          <Avatar alt={row.name} src={row.avatar} sx={{ mr: 2 }} />
          <Stack direction='column'>
            <Typography variant='body1' flexGrow={1}>{row.name}</Typography>
            <Typography variant='caption' flexGrow={1}>{row.date}</Typography>
          </Stack>
        </Stack>
        <Typography variant='body1' flexGrow={1}>{row.note}</Typography>
      </Stack>)}
      <TextField
        fullWidth placeholder='Ajouter une note...'
        InputProps={{
          startAdornment: <InputAdornment position='start'>
            <NoteAddIcon />
          </InputAdornment>,
        }}
      />
    </Stack>
  </Box>
}

// import React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import CircleIcon from '@mui/icons-material/Circle';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));

// export interface ListProps {
//   gutterBottom?: boolean;
// }

// export default function List({ gutterBottom }: ListProps) {
//   return <Box display='flex' flexDirection='column' sx={{ mb: gutterBottom ? 2 : undefined}}>
//     <Typography variant='caption'>Clients actifs</Typography>
//     <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', padding: 12, width: '100%' }}>
//       <CircleIcon />
//       <Typography variant='body1'>#123123</Typography>
//       <Typography variant='body1'>John Doe</Typography>
//       <Typography variant='body1'>29/05/22</Typography>
//       <Typography variant='body1'>67%</Typography>
//       <BorderLinearProgress variant='determinate' value={67} />
//     </Paper>
//   </Box>
// }
