import { DateTime } from "luxon";

export class DynamoUtils {

  static strToTTL(date: string) {
    return this.toTTL(DateTime.fromISO(date));
  }

  static toTTL(date: DateTime) {
    return Math.round(date.toUTC().toMillis() / 1000);
  }
}