// https://github.com/uber/react-digraph
// https://bkrem.github.io/react-d3-tree/
// https://reactflow.dev/

// TODO: consider https://ui-schema.bemit.codes/

export type FFQuestionType = 'bool' | 'bin' | 'radio' | 'checkbox' | 'butlist' | 'inptxt' |
  'slider' | 'sldrng' |
  'sldtime' | 'timerng' | 'daterng' |
  'loop' |
  'end';

const oneNextOpt = ['checkbox', 'inptxt', 'slider', 'sldrng', 'sldtime', 'timerng', 'daterng'];

export interface FFQuestion {
  id: string;
  type: FFQuestionType;
  text: string;
  choices?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  butText?: any;
  min?: number;
  max?: number;
  inptxt?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  next: any;
  // logic
  loop?: {
    use: string[];
    qs: FFQuestion[];
  };
  debug?: boolean;
}
export type FFQuestionDict = { [id: string]: FFQuestion };
export interface FFSection {
  name: string;
  title: string;
  subTitle: string;
  icon: string;
  questions: FFQuestion[];
}
export type FFSectionDict = { [name: string]: FFSection };
export interface FastForm {
  sections: FFSection[];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FFAnswerDict = { [id: string]: any };

export default class FastFormEngine {
  protected sections: FFSectionDict = {};
  protected questions: FFQuestionDict = {};
  protected curSNAME: string;
  protected curQID: string;
  protected onNewQ: () => void;
  protected answers: FFAnswerDict = {};
  protected history: string[];
  protected loopSize = 0;
  protected looping = -1;
  protected loopId = '';
  protected loopUse = '';

  constructor(tree: FastForm, onNewQ: () => void) {
    this.onNewQ = onNewQ;

    if (tree.sections.length < 1 || tree.sections[0].questions.length < 1) {
      throw Error('FastFormEngine - invalid tree: ' + JSON.stringify(tree, null, 2));
    }

    this.resetLoop();
    this.curSNAME = tree.sections[0].name;
    this.curQID = tree.sections[0].questions[0].id;
    this.history = [this.curQID];
    tree.sections.forEach(s => {
      if (this.sections[s.name] != null) throw Error('FastFormEngine - 2 sections with same name: ' + s.name);
      this.sections[s.name] = s;
      this.scanQ(s.questions);
    });
  }

  private scanQ(qs: FFQuestion[]) {
    qs.forEach(q => {
      if (this.questions[q.id] != null) throw Error('FastFormEngine - 2 questions with same id: ' + q.id);
      this.questions[q.id] = q;
      if (q.type === 'loop' && q.loop?.qs != null) this.scanQ(q.loop.qs);
    });
  }

  private resetLoop() {
    this.loopSize = 0;
    this.looping = -1;
    this.loopId = '';
    this.loopUse = '';
  }

  public tryGoBack() {
    return this.history.length > 1 ? this.goBack.bind(this) : undefined;
  }

  public goBack() {
    if (this.history.length > 1) {
      const prevQID = this.history.pop();
      if (prevQID != null) {
        // remove last answer
        delete this.answers[this.curQID];
        this.curQID = this.history.slice(-1)[0];
        this.onNewQ();
      }
    }
  }

  private curSection() { return this.sections[this.curSNAME] as FFSection; }
  private curQuestion() { return this.questions[this.curQID] as FFQuestion; }
  private question(key: string) { return this.questions[key] != null ? this.questions[key] as FFQuestion : undefined; }

  public getCurSection() { return this.curSection(); }
  // public getCurQuestion() { return this.curQuestion(); }
  public getCurQuestion() {
    const q = this.curQuestion();
    // TEMP_LOG console.log('>>> NEXT Q:', this.curSNAME, this.curQID, q?.type, q?.loop?.qs, q)
    // if starts the loop
    if (q?.type === 'loop' && q.loop?.qs != null && q.loop.qs.length > 0 && this.looping === -1) {
      // starts the loop
      this.loopId = this.curQID;
      this.looping = 0;
      this.loopSize = q.loop.use
        .map(u => this.answers[u] != null ? this.answers[u].length : 0)
        .reduce((partialSum, a) => partialSum + a, 0);
      this.loopUse = q.loop.use[0];
      this.curQID = q.loop.qs[0].id;
      this.answers[this.loopId] = [[]];
      return this.curQuestion();
    }
    // if already looping
    else if (q != null && q.loop != null && this.looping !== -1) {
      // if loop completed, increase
      this.looping = this.looping + 1;
      // TEMP_LOG console.log('>>> LOOPING Q:', this.curQID, this.loopId, this.loopUse, this.loopSize, this.looping)
      if (this.looping < this.loopSize) {
        this.curQID = q.loop.qs[0].id;
        this.answers[this.loopId].push([]);
        return this.curQuestion();
      }
      else {
        this.setNext(this.questions[this.loopId].next);
        this.resetLoop();
        return this.curQuestion();
      }
    }
    else {
      return this.curQuestion();
    }
  }
  public getQuestion(key: string) { return this.question(key); }
  public getAnswers() { return this.answers; }

  private setNext(sq: string) {
    const s_q = sq.split('|');
    if (s_q.length > 1) {
      if (this.sections[s_q[0]] == null) throw Error('FastFormEngine - invalid section: ' + s_q[0]);
      this.curSNAME = s_q[0];
      this.curQID = s_q[1];
    }
    else {
      this.curQID = s_q[0];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setAnswer(resp: any) {
    const zeQuestion = this.curQuestion();
    const ary = this.loopId !== '' ? this.answers[this.loopId][this.looping] : this.answers;
    const aryIdx = this.loopId !== '' ? this.answers[this.loopId][this.looping].length : this.curQID;
    // TEMP_LOG if (this.loopId !== '') {
    // TEMP_LOG   console.log('>>> LOOP AAA:', this.answers[this.loopId][this.looping])
    // TEMP_LOG }
    if (zeQuestion.type === 'bool' && typeof resp === 'boolean') {
      ary[aryIdx] = resp;
      this.setNext(this.questions[this.curQID].next[resp ? 't' : 'f']);
    }
    else if (zeQuestion.type === 'bin') {
      ary[aryIdx] = resp;
      this.setNext(this.questions[this.curQID].next[resp ? 'l' : 'r']);
    }
    else if (zeQuestion.type === 'radio') {
      ary[aryIdx] = resp.selected;
      if (typeof resp.index !== 'number' || resp.index < 0) throw Error('FastFormEngine - radio bad index: ' + resp.index);
      this.setNext(this.questions[this.curQID].next[resp.index]);
    }
    else if (zeQuestion.type === 'butlist') {
      ary[aryIdx] = resp.selected;
      if (typeof resp.index !== 'number' || resp.index < 0) throw Error('FastFormEngine - butlist bad index: ' + resp.index);
      this.setNext(this.questions[this.curQID].next[resp.index]);
    }
    else if (oneNextOpt.includes(zeQuestion.type)) {
      ary[aryIdx] = resp;
      this.setNext(this.questions[this.curQID].next);
    }
    else {
      throw Error('FastFormEngine - question type: ' + zeQuestion.type);
    }
    // TEMP_LOG console.log('>>> FF ANSWERS:', this.answers);

    this.history.push(this.curQID);
    this.onNewQ();
  }
}
