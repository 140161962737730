// import styles from './generic-modal-web.module.css';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export interface GenericModalProps {
  open: boolean;
  colorText?: string;
  text?: string;
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

export function GenericModal(props: GenericModalProps) {
  const { height, width, colorText } = props;
  return <Dialog open={props.open} onClose={() => { /* nothing */ }}>
    <DialogContent style={{ width: width, height: height || '20rem' }}>
      {props.text && <h3 style={{ color: colorText || 'grey' }}>{props.text}</h3>}
      {props.children}
    </DialogContent>
  </Dialog>
}
