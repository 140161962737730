import { FastForm } from '.';

export const ffTest: FastForm = {
  sections: [
    {
      name: 'start',
      title: 'section 1',
      subTitle: 'subTitle - section 1',
      icon: 'clock',
      questions: [
        {
          id: 's1q1',
          type: 'bool',
          text: 'are you available fulltime (every workday of a month)?',
          next: { f: 's1q2', t: 's1q2' },
        },
        {
          id: 's1q2',
          type: 'bool',
          text: 'can you work half a day?',
          next: { f: 's1q3', t: 's1q3' },
        },
        {
          id: 's1q3',
          type: 'bin',
          text: 'which part of the day can you works?',
          butText: { l: 'am', r: 'pm' },
          next: { l: 's1q4', r: 's1q4' },
        },
        {
          id: 's1q4',
          type: 'radio',
          text: 'which part of the day can you works?',
          choices: ['day', 'breakfast', 'morning', 'lunch', 'afternoon', 'dinner', 'evening', 'night'],
          next: ['s1q5', 's1q5', 's1q5', 's1q5', 's1q5', 's1q5', 's1q5', 's1q5'],
        },
        {
          id: 's1q5',
          type: 'checkbox',
          text: 'which part of the day can you works?',
          choices: ['day', 'breakfast', 'morning', 'lunch', 'afternoon', 'dinner', 'evening', 'night'],
          next: 's1q6',
        },
        {
          id: 's1q6',
          type: 'butlist',
          text: 'which part of the day can you works?',
          choices: ['button 1', 'button 2', 'button 3', 'button 4', 'button 5', 'button 6'],
          next: ['s1q7', 's1q7', 's1q7', 's1q7', 's1q7', 's1q7', 's1q7', 's1q7'],
        },
        {
          id: 's1q7',
          type: 'sldtime',
          text: 'start time',
          min: 8,
          max: 12,
          next: 's1q8',
        },
        {
          id: 's1q8',
          type: 'sldtime',
          text: 'end time',
          min: 13,
          max: 17,
          next: 's1q9',
        },
        {
          id: 's1q9',
          type: 'slider',
          text: 'simple slider',
          min: -10,
          max: 10,
          next: 's1q10',
        },
        {
          id: 's1q10',
          type: 'sldrng',
          text: 'range slider',
          min: -100,
          max: 100,
          next: 's1q11',
        },
        {
          id: 's1q11',
          type: 'inptxt',
          text: 'enter zip code',
          next: 's1q12',
        },
        {
          id: 's1q12',
          type: 'daterng',
          text: 'select a date range',
          next: 's1q13',
        },
        {
          id: 's1q13',
          type: 'timerng',
          text: 'select a time range',
          min: 8,
          max: 20,
          next: 'end',
        },
        {
          id: 'end',
          type: 'end',
          text: 'end',
          next: 'end',
          debug: true,
        },
      ],
    },
  ],
};
