/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Pagination from '@mui/material/Pagination';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
//
import { ASC, DESC, ITEMS_PER_PAGE } from '../../../utils/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../utils/entity-utils';
import { getSortState } from '../../../utils/pagination-utils';
import { getUsersAsAdmin, updateUser } from './reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

const styles = {
  table: {
    minWidth: 650,
  },
};

export interface UserManagementProps {
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function UserManagement({ onWaiting }: UserManagementProps) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const getUsersFromProps = () => {
    dispatch(
      getUsersAsAdmin({
        page: pagination.activePage - 1,
        size: pagination.itemsPerPage,
        sort: `${pagination.sort},${pagination.order}`,
      })
    );
    const endURL = `?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getUsersFromProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.activePage, pagination.order, pagination.sort]);

  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    // const page = params.get('page');
    // const sortParam = params.get(SORT);
    // if (page && sortParam) {
    //   const sortSplit = sortParam.split(',');
    //   setPagination({
    //     ...pagination,
    //     activePage: +page,
    //     sort: sortSplit[0],
    //     order: sortSplit[1],
    //   });
    // }
    if (params.page && params.sortParam) {
      const sortSplit = params.sortParam.split(',');
      setPagination({
        ...pagination,
        activePage: +params.page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const sort = (p: any) => () =>
    setPagination({
      ...pagination,
      order: pagination.order === ASC ? DESC : ASC,
      sort: p,
    });

  const handlePagination = (currentPage: any) =>
    setPagination({
      ...pagination,
      activePage: currentPage,
    });

  const handleSyncList = () => {
      getUsersFromProps();
    };

  const toggleActive = (user: any) => () =>
    dispatch(
      updateUser({
        ...user,
        activated: !user.activated,
      })
    );

  // const { match } = props;
  // const account = useAppSelector(state => state.authentication.account);
  const users = useAppSelector(state => state.userManagement.users);
  const totalItems = useAppSelector(state => state.userManagement.totalItems);
  const loading = useAppSelector(state => state.userManagement.loading);

  return <Box>
    <Box>
      <Box display='flex' justifyContent='space-between' style={{ padding: 0 }}>
        <Typography variant='h4' gutterBottom>{t('userManagement.home.title')}</Typography>
        <Box>
          <IconButton
            onClick={() => navigate(`${location.pathname}/new`)}
            disabled={loading}
            size='large'>
            <AddIcon style={{ fontSize: 24 }} />
          </IconButton>
          <IconButton onClick={handleSyncList} disabled={loading} size='large'>
            <SyncIcon style={{ fontSize: 24 }} />
          </IconButton>
          {/* <IconButton onClick={() => navigate('/admin')} disabled={loading}>
            <ArrowBackIcon style={{ fontSize: 24 }} />
          </IconButton> */}
        </Box>
      </Box>
    </Box>
    {onWaiting(loading)}
    {!loading && <Box style={{ paddingTop: 0 }}>
      <TableContainer component={Paper}>
        <Table style={styles.table} size='small'>
          <TableHead>
            <TableRow>
              {/* <TableCell onClick={() => sort('id')}>{t('generic.field.id')}</TableCell> */}
              <TableCell onClick={() => sort('email')}>{t('userManagement.email')}</TableCell>
              <TableCell onClick={() => sort('locale')}>{t('userManagement.locale')}</TableCell>
              <TableCell>{t('userManagement.profiles')}</TableCell>
              <TableCell>{t('userManagement.products')}</TableCell>
              {/* <TableCell onClick={() => sort('createdDate')}>{t('userManagement.createdDate')}</TableCell>
              <TableCell onClick={() => sort('lastModifiedBy')}>{t('userManagement.lastModifiedBy')}</TableCell>
              <TableCell onClick={() => sort('lastModifiedDate')}>{t('userManagement.lastModifiedDate')}</TableCell> */}
              <TableCell>{t('entity.action.title')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, i) => (
              <TableRow key={`sas_${user.username}`}>
                {/* <TableCell>{`${user.username.substr(0, 5)}...${user.username.substr(-5, 5)}`}</TableCell> */}
                <TableCell component='th' scope='row'>{user.email}</TableCell>
                <TableCell>{user.locale}</TableCell>
                <TableCell>{user.groups
                  ? user.groups.map((group, j) => (
                      <div key={`user-group-${i}-${j}`}>
                        <Badge color='primary'>{group}</Badge>
                      </div>
                    ))
                  : null}
                </TableCell>
                <TableCell>{(user as typeof user & { products: string[] }).products
                  ? (user as typeof user & { products: string[] }).products.map((prod, j) => (
                      <div key={`user-prod-${i}-${j}`}>
                        <Badge color='primary'>{prod}</Badge>
                      </div>
                    ))
                  : null}
                </TableCell>
                {/* <TableCell>{user.createdDate}</TableCell>
                <TableCell>{user.lastModifiedBy}</TableCell>
                <TableCell>{user.lastModifiedDate}</TableCell> */}
                <TableCell align='right' style={{ padding: 0 }}>
                  <IconButton onClick={() => navigate(`${location.pathname}/view/${user.username}`)} size='large'>
                    <VisibilityIcon style={{ fontSize: 24 }} />
                  </IconButton>
                  <IconButton onClick={() => navigate(`${location.pathname}/edit/${user.username}`)} size='large'>
                    <EditIcon style={{ fontSize: 24 }} />
                  </IconButton>
                  <IconButton onClick={toggleActive} size='large'>{
                    user.enabled
                      ? <CheckBoxIcon style={{ fontSize: 24 }} />
                      : <CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />
                  }</IconButton>
                  <IconButton
                    onClick={() => navigate(`${location.pathname}/delete/${user.username}`)}
                    size='large'>
                    <DeleteIcon style={{ fontSize: 24 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {!totalItems && <TableRow>
              <TableCell component='th' scope='row' colSpan={5}>{t('userManagement.nothingFound')}</TableCell>
            </TableRow>}
            {totalItems > 0 && <TableRow>
              <TableCell colSpan={5} style={{ justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination variant='outlined' shape='rounded' count={Math.round(totalItems/pagination.itemsPerPage)} page={pagination.activePage}
                    onChange={(e, page) => handlePagination(page)}
                  />
                </div>
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>}
  </Box>
};
