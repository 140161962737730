import React, { useContext, useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Platform, StyleSheet } from 'react-native';
import { Auth } from '@aws-amplify/auth';
//
import { geolocStore } from '@eksoh/shared/ui';
import { Text, View } from '../cmps/Themed';
import Button from '../cmps/Button';
import { MonoInput } from '../cmps/StyledInput';
import { scale } from '../Scaling';

export default function ModalScreen() {
  const { geolocState, setMyTag } = useContext(geolocStore);
  const [tag, setTag] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setTag(geolocState.myTag), [])

  const lastPos = geolocState.geolocHisto.length > 0 ? geolocState.geolocHisto[0] : undefined;
  return <View style={styles.container}>
    <Text style={styles.title}>accuracy: {lastPos?.coords.accuracy}</Text>
    <Text style={styles.title}>altitude: {lastPos?.coords.altitude}</Text>
    <Text style={styles.title}>altitudeAccuracy: {lastPos?.coords.altitudeAccuracy}</Text>
    <Text style={styles.title}>heading: {lastPos?.coords.heading}</Text>
    <Text style={styles.title}>latitude: {lastPos?.coords.latitude}</Text>
    <Text style={styles.title}>longitude: {lastPos?.coords.longitude}</Text>
    <Text style={styles.title}>speed: {lastPos?.coords.speed}</Text>
    <Text style={styles.title}>timestamp: {lastPos?.timestamp ? new Date(lastPos.timestamp).toLocaleString('fr-CA') : 'no ts'}</Text>
    <View style={styles.separator} />
    <Text style={styles.title}>your tag</Text>
    <MonoInput
      style={styles.inputStyle}
      // placeholder='message'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(e: any) => {
        e.persist();
        const val = e.target.value as string;
        if (val.length >= 6) return;
        setTag(val);
      }}
      onKeyPress={(event) => {
        if (event.nativeEvent.key === 'Enter') setMyTag(tag);
      }}
      value={tag}
    />
    <View style={styles.separator} />
    <Button style={styles.btn} onPress={async () => {
      await Auth.signOut();
    }}>
      logout
    </Button>

    {/* Use a light status bar on iOS to account for the black space above the modal */}
    <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: scale(8),
  },
  inputStyle: {
    padding: 9,
    backgroundColor: '#333',
    fontSize: 20,
    textAlign: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  btn: {
    maxWidth: 300,
    marginTop: scale(10),
    marginBottom: scale(10),
    backgroundColor: '#146bdc',
  },
});
