/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useRef } from 'react';
import { StyleSheet, ActivityIndicator, ScrollView } from 'react-native';
//
// import theme from './theme';
import { authStore, textChatStore } from '@eksoh/shared/ui';
import { Text, View } from '../cmps/Themed';
import { MonoInput } from '../cmps/StyledInput';
import IconButton from '../cmps/IconButton';

// const { primaryColor } = theme;
const primaryColor = '#007bff';

const scrollToRefWithAnimation = (ref: React.MutableRefObject<any>) => {
  if (!ref.current) return;
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth'
  });
}

export function Chat() {
  const { authState } = useContext(authStore);
  const { textChatState, sendMsg } = useContext(textChatStore);
  const [inputValue, setInputValue] = useState('');
  const scrollRef = useRef<any>(null);
  // const executeScroll = () => scrollToRef(scrollRef);
  const executeScrollWithAnimation = () => scrollToRefWithAnimation(scrollRef);

  function onChange(e: any) {
    e.persist();
    setInputValue(e.target.value);
  }

  function showWhen(message: { createdAt: string | number | Date; }) {
    return message.createdAt
      ? new Date(message.createdAt).toLocaleTimeString()
      : new Date().toLocaleTimeString();
  }

  function onSendMsg() {
    sendMsg(inputValue);
    setInputValue('');
    setTimeout(() => executeScrollWithAnimation());
}

  const hasNoMsgs = textChatState.messages.length === 0;
  return <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
    <View style={{ flex: 1 }}>
      {textChatState.loading && <View style={{ flex: 1, justifyContent: 'center' }}>
        <ActivityIndicator size='large' />
        <Text style={{ textAlign: 'center' }}>getting ready...</Text>
      </View>}
      {!textChatState.loading && hasNoMsgs && <View style={styles.noMessageContainer}>
        <Text style={{ textAlign: 'center' }}>no messages yet!</Text>
      </View>}
      {!textChatState.loading && !hasNoMsgs && <View>{textChatState.messages.map((message, index) => <View
        key={message?.id || `CD_MSG_${index}`}
        style={messageContainerStyle(message?.sender === authState.chatInfo?.myChatUserId)}
      >
        <p style={messageStyle(message?.sender === authState.chatInfo?.myChatUserId)}>{message?.content}</p>
        <p style={ownerStyle(message?.sender === authState.chatInfo?.myChatUserId)}>{showWhen(message)}</p>
      </View>)}
      </View>}
    </View>
    <View style={styles.inputContainerStyle}>
      <View style={styles.inputWrapperStyle}>
        <MonoInput
          style={styles.inputStyle}
          placeholder='message'
          onChange={onChange}
          onKeyPress={ (event) => {
            if(event.nativeEvent.key === 'Enter') onSendMsg();
          }}
          value={inputValue}
        />
      </View>
      <View style={{ marginLeft: 6, marginTop: 3 }}>
        <IconButton name='send' size={32} onPress={onSendMsg} />
      </View>
    </View>
  </ScrollView>
}

const messageContainerStyle = (isOwner: boolean) => ({
  backgroundColor: isOwner ? primaryColor : '#ddd',
  padding: 6,
  borderRadius: 5,
  marginBottom: 10,
  marginLeft: isOwner ? 20 : 0,
  marginRight: isOwner ? 0 : 20,
  boxShadow: `0 1px 1px rgba(0,0,0,0.11), 
  0 2px 2px rgba(0,0,0,0.11)`,
});

const messageStyle = (isOwner: boolean) => ({
  color: isOwner ? 'white' : 'black',
  fontSize: 22,
  margin: 0,
});

const ownerStyle = (isOwner: boolean) => ({
  color: isOwner ? '#ddd' : '#666',
  fontWeight: 400,
  fontSize: 12,
  marginTop: 8,
  marginBottom: 0,
});

const styles = StyleSheet.create({
  inputWrapperStyle: {
    display: 'flex',
    flexGrow: 1,
  },
  inputContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: 0,
    paddingBottom: 10,
  },
  inputStyle: {
    padding: 9,
    backgroundColor: '#333',
    fontSize: 20,
  },
  noMessageContainer: {
    marginTop: 200,
    display: 'flex',
    justifyContent: 'center',
    color: primaryColor,
  },
});
