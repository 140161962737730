/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

const ColoredPaper = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  // textAlign: 'center',
  color: theme.palette.text.secondary,
  // height: 60,
  // lineHeight: '60px',
  minWidth: 250,
  width: 'fit-content',
  padding: 24,


  backgroundColor: '#EDEDED',
}));

const darkTheme = createTheme({ palette: { mode: 'dark' } } as any);
const lightTheme = createTheme({ palette: { mode: 'light' } } as any);

//
// ---
//

export interface TagProps {
  isLight?: boolean;
  children: ReactNode | ReactNode[];
  gutterBottom?: boolean;
  gutterRight?: boolean
}

export default function Tag({ isLight, children, gutterBottom, gutterRight }: TagProps) {
  return <ThemeProvider theme={isLight ? lightTheme : darkTheme}>
    <ColoredPaper
      elevation={3}
      sx={{ mb: gutterBottom ? 2 : undefined, mr: gutterRight ? 2 : undefined}}
    >
      {children}
    </ColoredPaper>
  </ThemeProvider>
}

//
// ---
//

export interface CounterTagProps {
  isLight?: boolean;
  clients: any[]; // TYPE ME!
  gutterBottom?: boolean
  gutterRight?: boolean
}

export function ClientsBySectors({ isLight, gutterBottom, gutterRight}: CounterTagProps) {
  return <ThemeProvider theme={isLight ? lightTheme : darkTheme}>
    <ColoredPaper
      elevation={3}
      sx={{ mb: gutterBottom ? 2 : undefined, mr: gutterRight ? 2 : undefined}}
    >
      <Typography variant='h6' style={{ marginBottom: 6 }}>Clients par secteur</Typography>
      <Box>
        <Box display='flex' style={{ backgroundColor: 'white', width: 400, borderRadius: 10, marginBottom: 6, padding: 12, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='caption' style={{ flexGrow: 1 }}>Construction</Typography>
          <CircularProgress variant='determinate' value={65} size={16} />
        </Box>
        <Box display='flex' style={{ backgroundColor: 'white', width: 400, borderRadius: 10, marginBottom: 6, padding: 12, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='caption' style={{ flexGrow: 1 }}>Particuliers</Typography>
          <CircularProgress variant='determinate' value={92} size={16} />
        </Box>
        <Box display='flex' style={{ backgroundColor: 'white', width: 400, borderRadius: 10, marginBottom: 6, padding: 12, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='caption' style={{ flexGrow: 1 }}>Commerce de détail</Typography>
          <CircularProgress variant='determinate' value={65} size={16} />
        </Box>
      </Box>
    </ColoredPaper>
  </ThemeProvider>
}
