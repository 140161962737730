import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
//
import { EmrPatientFormValidations, EmrPatientInitialValues, /* Gender, */ Patient } from '@eksoh/shared/common';
// import { /* TextField, */ GenderSelectField, GenderOption } from '../components/FormFields';

/*
 * use type Patient, but omit id and entries,
 * because those are irrelevant for new patient object.
 */
export type PatientFormValues = Omit<Patient, 'id' | 'entries'>;

interface Props {
  onSubmit: (values: PatientFormValues) => void;
  onCancel: () => void;
}

// const upperFirst = (str: string): string => (str.charAt(0).toUpperCase() + str.slice(1));

// const genderOptions: GenderOption[] = Object.values(Gender).map(genderValue => {
//   if (genderValue === 'other') {
//     return { value: genderValue, label: 'Something Else' };
//   }
//   if (genderValue === 'unknown') {
//     return { value: genderValue, label: 'Decline to Answer' };
//   }
//   return { value: genderValue, label: upperFirst(genderValue) };
// });

export function AddPatientForm({ onSubmit, onCancel }: Props) {
  const currentValidation = EmrPatientFormValidations[0];
  const formik = useFormik({
    initialValues: EmrPatientInitialValues,
    validationSchema: currentValidation,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      // console.log('addPatientForm:', JSON.stringify(values, null, 2));
      onSubmit({ ...values, name: `${values.firstName} ${values.lastName}` } as PatientFormValues);
    },
  });

  return <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='firstName'
          name='firstName'
          label='First name'
          fullWidth
          autoComplete='given-name'
          variant='standard'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='lastName'
          name='lastName'
          label='Last name'
          fullWidth
          autoComplete='family-name'
          variant='standard'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='email'
          name='email'
          label='Email'
          fullWidth
          variant='standard'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='ssn'
          name='ssn'
          label='SSN'
          fullWidth
          variant='standard'
          value={formik.values.ssn}
          onChange={formik.handleChange}
          error={formik.touched.ssn && Boolean(formik.errors.ssn)}
          helperText={formik.touched.ssn && formik.errors.ssn}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          openTo='year' views={['year', 'month', 'day']} disableFuture
          // label={tLabel2(t, 'userManagement.dob', true)} disableFuture
          label='Date of birth'
          value={formik.values.dob || null} // onChange={formik.handleChange}
          onChange={(value: string | null, keyboardInputValue?: string) => {
            if (value == null || value === 'Invalid Date') return;
            formik.setFieldTouched('dob', true);
            formik.setFieldValue('dob', value);
          }}
          // renderInput={(props) => <TextField {...props} onBlur={onBlur} style={{ flexGrow: 1 }} />}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderInput={(props: any) => <TextField {...props} style={{ flexGrow: 1 }} />}
          // error={formik.touched.dob && Boolean(formik.errors.dob)}
          // helperText={formik.errors.dob}
        />
        {formik.touched.dob && Boolean(formik.errors.dob) && <Typography color='error' variant='body1'>
          {formik.errors.dob}
        </Typography>}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='occupation'
          name='occupation'
          label='Occupation'
          fullWidth
          variant='standard'
          value={formik.values.occupation}
          onChange={formik.handleChange}
          error={formik.touched.occupation && Boolean(formik.errors.occupation)}
          helperText={formik.touched.occupation && formik.errors.occupation}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='gender'
          name='gender'
          label='Gender'
          fullWidth
          variant='standard'
          value={formik.values.gender}
          onChange={formik.handleChange}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='address1'
          name='address1'
          label='Address line 1'
          fullWidth
          autoComplete='shipping address-line1'
          variant='standard'
          value={formik.values.address1}
          onChange={formik.handleChange}
          error={formik.touched.address1 && Boolean(formik.errors.address1)}
          helperText={formik.touched.address1 && formik.errors.address1}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='address2'
          name='address2'
          label='Address line 2'
          fullWidth
          autoComplete='shipping address-line2'
          variant='standard'
          value={formik.values.address2}
          onChange={formik.handleChange}
          error={formik.touched.address2 && Boolean(formik.errors.address2)}
          helperText={formik.touched.address2 && formik.errors.address2}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='city'
          name='city'
          label='City'
          fullWidth
          autoComplete='shipping address-level2'
          variant='standard'
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='state'
          name='state'
          label='State/Province/Region'
          fullWidth
          variant='standard'
          value={formik.values.state}
          onChange={formik.handleChange}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='zip'
          name='zip'
          label='Zip / Postal code'
          fullWidth
          autoComplete='shipping postal-code'
          variant='standard'
          value={formik.values.zip}
          onChange={formik.handleChange}
          error={formik.touched.zip && Boolean(formik.errors.zip)}
          helperText={formik.touched.zip && formik.errors.zip}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id='country'
          name='country'
          label='Country'
          fullWidth
          autoComplete='shipping country'
          variant='standard'
          value={formik.values.country}
          onChange={formik.handleChange}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color='secondary' name='saveAddress' value='yes' />}
          label='Use this address for payment details'
        />
      </Grid> */}
      <Grid item xs={12} textAlign='end'>
        <Button onClick={onCancel}>cancel</Button>
        <Button type='submit'>save</Button>
      </Grid>
    </Grid>
  </form>
}

export default AddPatientForm;
