/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  APP_REGION, AWS_USER_POOL_ID, AWS_IDENTITY_POOL_ID, AWS_USER_POOL_CLIENT_ID,
  AWS_GRAPHQL_ENDPOINT, NODE_ENV, AMPLIFY_LOG_LEVEL, DEBUG, AUTH_MANDATORY_SIGN_IN, BASE_URL, 
  getCognitoRedirectUrls, getUserOpener, BASE_URL_MOBILE, AWS_OAUTH_DOMAIN, STORAGE_S3_NAME,
  APP_VERSION, APP_VERSION_GIT, BASE_URL_WEBAPP
} from './init';
import { Config, ENodeEnvType } from '@eksoh/shared/common';
// import {
// 	ICookieStorageData,
// 	ICognitoStorage,
// } from 'amazon-cognito-identity-js';


// TODO @fp - default to dev env when no env vars found. make it default in prod env when we launch
//            pilot project or official demoing
if (AWS_USER_POOL_ID === undefined) {
  console.log('**********************************************************************');
  console.log('**********************************************************************');
  console.log('**********************************************************************');
  console.log('COGNITO_USER_POOL_ID:', AWS_USER_POOL_ID);
  console.log('Looks like you have no environment variables set for AWS. This in most');
  console.log('likely that you have not set the NODE_ENV properly. Check it out.');
  console.log('  -- defaulting to development enviroment...');
  console.log('**********************************************************************');
  console.log('**********************************************************************');
  console.log('**********************************************************************');
}

// Node & App
const nodeEnv = NODE_ENV || ENodeEnvType.DEVELOPMENT;
const isDebugMode = String(DEBUG) === 'true';
// AWS
const awsRegion = APP_REGION;
const amplifyLogLevel = AMPLIFY_LOG_LEVEL || 'INFO';
const mandatorySignIn = String(AUTH_MANDATORY_SIGN_IN) !== 'false'; // default true
const userPoolId = AWS_USER_POOL_ID;
const userPoolWebClientId = AWS_USER_POOL_CLIENT_ID;
const identityPoolId = AWS_IDENTITY_POOL_ID;
const aws_appsync_graphqlEndpoint = AWS_GRAPHQL_ENDPOINT;
const domain = AWS_OAUTH_DOMAIN;

// 'aws_appsync_authenticationType': 'API_KEY', 'AMAZON_COGNITO_USER_POOLS'
// 'aws_appsync_apiKey': 'da2-xxxxxxxxxxxxxxxxxxxxxxxxxx',
const baseUrl = BASE_URL;
const baseUrlMobile = BASE_URL_MOBILE;
const baseUrlWebapp = BASE_URL_WEBAPP;
const storageName = STORAGE_S3_NAME;

const isDevMode = nodeEnv === ENodeEnvType.DEVELOPMENT || nodeEnv === 'dev' || nodeEnv === 'local';

const config: Config = {
  global: {
    appVersion: isDevMode
      ? { code: 'local build', gitShortSha: 'local build' }
      : { code: APP_VERSION, gitShortSha: APP_VERSION_GIT },
    isDevMode,
    nodeEnv,
    isDebugMode,
    baseUrl,
    baseUrlMobile,
    baseUrlWebapp
  },
  aws: {
    region: awsRegion || 'CDK_ERROR',
    amplifyLogLevel,
    Auth: {
      mandatorySignIn,
      region: awsRegion,
      userPoolId,
      identityPoolId,
      userPoolWebClientId,
      oauth: {
        domain,
        scope: [
          'email',
          'openid',
          'phone',
          'profile',
          'aws.cognito.signin.user.admin'
        ],
        ...getCognitoRedirectUrls(),
        responseType: 'code',
        urlOpener: getUserOpener(),
      },
    },
    aws_appsync_graphqlEndpoint: aws_appsync_graphqlEndpoint,
    aws_appsync_region: awsRegion || 'CDK_ERROR',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    Storage: !storageName ? undefined : {
      AWSS3: {
        bucket: storageName,
        region: awsRegion,
        level: 'private',
      }
    }
  }
};
// export const config: Config = {
//   global: {
//     isDevMode: true,
//     nodeEnv: 'development',
//     isDebugMode,
//     baseUrl,
//   },
//   aws: {
//     region: awsRegion || 'CDK_ERROR',
//     amplifyLogLevel,
//     Auth: {
//       mandatorySignIn,
//       region: awsRegion,
//       userPoolId,
//       identityPoolId,
//       userPoolWebClientId,
  
//       oauth: {
//         domain: `${sstStage}-flowrence.auth.ca-central-1.amazoncognito.com`,
//         scope: [
//           'email',
//           'openid',
//           'phone',
//           'profile',
//           // 'aws.cognito.signin.user.admin'
//         ],
//         ...getCognitoRedirectUrls(),
//         responseType: 'code',
//         urlOpener: getUserOpener(),
//       },
//       federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
//       aws_cognito_username_attributes: [
//         'EMAIL'
//       ],
//       aws_cognito_social_providers: [
//         'FACEBOOK',
//         'GOOGLE'
//       ],

//     },
  
//     aws_appsync_graphqlEndpoint: aws_appsync_graphqlEndpoint,
//     aws_appsync_region: awsRegion || 'CDK_ERROR',
//     aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//     aws_appsync_dangerously_connect_to_http_endpoint_for_testing,

//     // delete me eventually
//     aws_apigwEndpoint,
  
//   }
// };

console.debug('CONFIG:', JSON.stringify(config, null, 2)); // eslint-disable-line no-console
export function initConfig() { return config; }
