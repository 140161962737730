import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
//
import { authStore } from '@eksoh/shared/ui';

export interface MyTagProps {
  gutterBottom?: boolean;
}

export default function MyTag({ gutterBottom }: MyTagProps) {
  const { authState } = useContext(authStore);

  const name = authState.user?.name != null
    ? authState.user?.name
    : authState.user?.givenName && authState.user?.familyName
      ? `${authState.user?.givenName} ${authState.user?.familyName}`
      : 'à vous!'

  return <Paper elevation={3} style={{ padding: 12, width: 'fit-content' }} sx={{ mb: gutterBottom ? 2 : undefined}}>
    <Typography variant='caption'>bon après-midi,</Typography>
    <Typography variant='h4'>{name}</Typography>
  </Paper>
}
