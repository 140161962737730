import Box from '@mui/material/Box';
//
import CreateEventButton from './CreateEventButton';
import SmallCalendar from './SmallCalendar';
import Labels from './Labels';

export default function Sidebar() {
  return <Box style={{ border: '1px solid #ccc' }} sx={{ px: 0, py: 2, w: 5 }}>
    <CreateEventButton />
    <SmallCalendar />
    <Labels />
  </Box>
}
