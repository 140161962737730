import React, { useContext, useState, useRef } from 'react';
import { StyleSheet, View, Text, ScrollView, Dimensions, TouchableOpacity } from 'react-native';
import { Appbar } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
  const paddingToBottom = 20;
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
};

export interface TermsAndConditionsProps {
  onAccepted: () => void;
}

export default function TermsAndConditions({ onAccepted }: TermsAndConditionsProps) {
  const { signOut } = useContext(authStore);
  const [readAll, setReadAll] = useState(false);
  const actualH = useRef(0);
  const [t] = useTranslation();

  return <View style={styles.container}>
    <Appbar.Header style={{ backgroundColor: 'grey' }}>
      <Appbar.BackAction onPress={signOut} />
      <Appbar.Content title={t('app.terms.title')} subtitle={t('app.terms.lastDate')} />
      {/* <Appbar.Action icon="magnify" onPress={_handleSearch} />
      <Appbar.Action icon="dots-vertical" onPress={_handleMore} /> */}
    </Appbar.Header>
    <ScrollView
      style={styles.tcContainer}
      scrollEventThrottle={20}
      onScroll={({ nativeEvent }) => {
        if (isCloseToBottom(nativeEvent)) setReadAll(true);
      }}
      onContentSizeChange={(_, h) => { actualH.current = h; }}
      onLayout={({ nativeEvent }) => { if (nativeEvent.layout.height > actualH.current) setReadAll(true); }}
    >
      <View style={{ paddingBottom: 6, paddingLeft: 6, paddingRight: 6 }}>
        <View>
          <Text>{t('app.terms.line0')}</Text>
          <Text style={{ marginTop: 12 }}>{t('app.terms.line1')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line2')}</strong></Text>
          <Text>{t('app.terms.line3')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line4')}</strong></Text>
          <Text>{t('app.terms.line5')}</Text>
          <ul style={{ paddingInlineStart: 20 }}><li>{t('app.terms.line6')}</li></ul>
          <Text>{t('app.terms.line7')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line8')}</strong></Text>
          <Text>{t('app.terms.line9')}</Text>
          <Text>{t('app.terms.line10')}</Text>
          <ul style={{ paddingInlineStart: 20 }}><li>{t('app.terms.line11')}</li></ul>
          <Text>{t('app.terms.line12')}</Text>
          <Text style={{ marginTop: 12 }}>{t('app.terms.line13')}</Text>
          <Text style={{ marginTop: 12 }}>{t('app.terms.line14')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line15')}</strong></Text>
          <Text>{t('app.terms.line16')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line17')}</strong></Text>
          <Text>{t('app.terms.line18')}</Text>
          <ul style={{ paddingInlineStart: 20 }}>
            <li>{t('app.terms.line19.0')}</li>
            <li>{t('app.terms.line19.1')}</li>
            <li>{t('app.terms.line19.2')}</li>
            <li>{t('app.terms.line19.3')}</li>
          </ul>
          <Text>{t('app.terms.line20')}</Text>
          <Text>{t('app.terms.line21')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line22')}</strong></Text>
          <Text>{t('app.terms.line23')}</Text>
          <ul style={{ paddingInlineStart: 20 }}><li>{t('app.terms.line24')}</li></ul>
          <Text><strong>{t('app.terms.line25')}</strong></Text>
          <Text>{t('app.terms.line26')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line27')}</strong></Text>
          <Text>{t('app.terms.line28')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line29')}</strong></Text>
          <Text>{t('app.terms.line30')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line31')}</strong></Text>
          <Text>{t('app.terms.line32')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line33')}</strong></Text>
          <Text>{t('app.terms.line34')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line35')}</strong></Text>
          <Text>{t('app.terms.line36')}</Text>
          <View style={{ height: 24 }} />
          <Text><strong>{t('app.terms.line37')}</strong></Text>
          <Text>{t('app.terms.line38')}</Text>
          <Text>info@flowrence.ca</Text>
          <Text>______________________________________</Text>
          <Text>{t('app.terms.line39')}</Text>
        </View>
      </View>

    </ScrollView>

    <TouchableOpacity
      disabled={!readAll} onPress={onAccepted}
      style={readAll ? styles.button : styles.buttonDisabled}
    >
      <Text style={styles.buttonLabel}>{t('app.terms.termAccept')}</Text>
    </TouchableOpacity>
  </View>
}

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  tcP: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
  },
  tcL: {
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
  },
  tcContainer: {
    marginTop: 15,
    marginBottom: 15,
    height: height * .7,
  },

  button: {
    backgroundColor: '#136AC7',
    borderRadius: 5,
    padding: 10,
  },

  buttonDisabled: {
    backgroundColor: '#999',
    borderRadius: 5,
    padding: 10,
  },

  buttonLabel: {
    fontSize: 14,
    color: '#FFF',
    alignSelf: 'center'
  },
});
