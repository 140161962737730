import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useTranslation } from 'react-i18next';
//
// import { scale, moderateScale } from '../Scaling';
import LanguageBanner from './LanguageBanner';
import { stepEnd } from '../onbding/Onboarding4Videos';


const stepRatio: { [key: string]: number } = {
  'nurse.register': 1 / 10,
  'nurse.onboarding': 1 / stepEnd,
};

export interface OnboardingHeaderProps {
  progress: number;
  onClose: () => void;
  localeColor?: string;
  step?: string;
}

export default function OnboardingHeader({ progress, onClose, localeColor, step }: OnboardingHeaderProps) {
  const { t } = useTranslation();

  return <View style={styles.container}>
    <View style={styles.containerGrey}>
      <View style={{ width: '100%', flexDirection: 'row', marginTop: 12 }}>
        <TouchableHighlight style={{ marginLeft: 12 }} onPress={() => onClose()}>
          <Ionicons name='close' size={32} color='#323232' />
        </TouchableHighlight>
        <Text style={{ flexGrow: 1, marginTop: 8, textAlign: 'center' }}>{t(step || 'nurse.register')}</Text>
        <View style={{ marginRight: 12, width: 32 }} />
      </View>
      <View style={{ width: '60%' }}>
        <ProgressBar progress={stepRatio[step || 'nurse.register'] * progress} color='#323232' />
      </View>
    </View>
    <LanguageBanner localeColor={localeColor} />
  </View>
}

const styles = StyleSheet.create({
  container: {
    width: '-webkit-fill-available',
    height: 114,
    marginLeft: -16,
    marginRight: -16,
  },
  containerGrey: {
    width: '100%',
    height: 80,
    backgroundColor: '#EBEBEB',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
