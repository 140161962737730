import { useContext, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
// import { Auth } from '@aws-amplify/auth';
// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { List, Avatar, Surface, TextInput, Modal, Button, Banner } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
// import { scale, moderateScale } from '../Scaling';
import { awsPhoneNumber, VerifiedAttributeType, Constants } from '@eksoh/shared/common';
import { UserType } from '@eksoh/flo/model';

export interface ValidationCodeProps {
  show: boolean;
  fromSignUp: boolean;
  ovrMobile?: string;
  onTryAgain: () => void;
  onDone: () => void;
  onError: (error: unknown) => void;
}

export function ValidationCode({ show, fromSignUp, ovrMobile, onTryAgain, onDone, onError }: ValidationCodeProps) {
  const { authState, sendVerifyAttribCode, cancel, confirmSignUp, refreshAuth } = useContext(authStore);
  const [code, setCode] = useState('');
  const [error, setError] = useState<unknown>();
  const [t] = useTranslation();

  async function tryCode() {
    try {
      let success = false;
      const email = authState.user?.email || authState.onboarding?.email;
      const mobile = ovrMobile || authState.user?.phoneNumber || authState.onboarding?.phone;
      if (mobile == null) return; // TODO: navigation back or go to root...
      if (fromSignUp) {
        success = await confirmSignUp(
          awsPhoneNumber(mobile),
          code,
          { [Constants.params.USER_TYPE_POST_CONFIRM_PARAM]: UserType.NURSE_TYPE },
        );
      }
      else {
        if (email == null) return; // TODO: navigation back or go to root...
        success = await sendVerifyAttribCode(VerifiedAttributeType.PHONE_NUMBER, code);
      }
      await refreshAuth();
      if (success) onDone();
    }
    catch (error) {
      setError(error);
    }
  }

  return <Modal
    visible={show} onDismiss={() => cancel()}
    contentContainerStyle={{ backgroundColor: 'white', padding: 20 }}
  >
    <Banner
      visible={error != null}
      actions={[
        {
          label: t('generic.error.tryAgain'),
          onPress: () => onError(error),
        },
        {
          label: t('generic.menu.contactUs'),
          onPress: () => setError(undefined),
        },
      ]}
      icon={cprops => <Avatar.Icon {...cprops} icon='alert-circle' />}
    >
      {t('generic.error.unexpected')}
    </Banner>
    <List.Section style={{ marginTop: 10, width: '100%' }}>
      <List.Item style={{ padding: 0 }}
        left={() => <Avatar.Icon icon='account-lock' size={64} color='#323232' style={{ backgroundColor: 'transparent' }} />}
        title={t('login.form.securityInfo')}
        titleNumberOfLines={2} titleStyle={{ color: '#323232' }}
      />
    </List.Section>
    <Surface style={styles.surface}>
      <View style={{ marginRight: 12 }}>
        <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
      </View>
      <Text style={{ color: '#A5A5A5' }}>{t('login.form.cellCode')}</Text>
    </Surface>
    <View style={{ width: '100%', marginTop: 1, marginBottom: 16 }}>
      <TextInput mode='outlined' label={t('login.reset.enterCode')} value={code} onChangeText={text => setCode(text)} />
    </View>
    <Button icon='email' mode='contained' onPress={() => tryCode()} disabled={!code || code.length < 6} style={{ marginBottom: 16 }}>
      {t('login.validation.tryCode')}
    </Button>
    {/* // TODO: limit the amount of retries to limit abuse (cost)... */}
    <Button icon='restart' mode='contained' onPress={() => onTryAgain()}>
      {t('login.validation.resendCode')}
    </Button>
  </Modal>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  surface: {
    elevation: 1,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
