/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer, Dispatch } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export type ThemeMode = 'light' | 'dark';

const darkTheme = createTheme({ palette: { mode: 'dark' } } as any);
const lightTheme = createTheme({ palette: { mode: 'light' } } as any);

export enum eAppWebActions {
  SET_THEME_MODE,
}

interface SetModeAction {
  type: typeof eAppWebActions.SET_THEME_MODE;
  themeMode: ThemeMode;
}

export type AppWebActionTypes = SetModeAction;

export interface IAppWebState {
  themeMode: ThemeMode;
}

const initialState: IAppWebState = {
  themeMode: 'light',
}

export interface IAppWebContext {
  webState: IAppWebState;
  dispatch: Dispatch<any>;
  setThemeMode: (themeMode: ThemeMode) => void; // TODO fpaq Add types for returned user...
}

export const webStore = createContext<IAppWebContext>({
  webState: initialState,
  dispatch: () => null,
  setThemeMode: () => { throw new Error('Not implemented.'); },
});

function reducer(state: IAppWebState, action: AppWebActionTypes) {
  switch(action.type) {
    case eAppWebActions.SET_THEME_MODE: {
      return { ...state, themeMode: action.themeMode };
    }
    default:
      return state;
  }
}

export interface AppWebProps {
  children: React.ReactNode | React.ReactNode[];
}

export function AppWeb(props: AppWebProps) {
  const [webState, dispatch] = useReducer(reducer, initialState);

  function setThemeMode(themeMode: ThemeMode) {
    dispatch({ type: eAppWebActions.SET_THEME_MODE, themeMode });
  }

  return <webStore.Provider value={{ webState, dispatch, setThemeMode }}>
    <ThemeProvider theme={webState.themeMode === 'dark' ? darkTheme : lightTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  </webStore.Provider>
}
