import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { List, Surface, Avatar, /* RadioButton, */ Paragraph } from 'react-native-paper';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
// import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
// import { scale, moderateScale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
// import { Dispo } from '@eksoh/flo/model';

export default function Availabilities({ navigation }: RootStackScreenProps<'Availabilities'>) {
  const { authState /* , onboarding */ } = useContext(authStore);
  // const [dispo, setDispo] = useState<Dispo>(Dispo.FIXED);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  // const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    // if (authState.onboarding?.step35?.dispo != null) navigation.push('AvailabilityTimish');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  return <View style={styles.container}>
    <OnboardingHeader progress={0} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <List.Section style={{ marginTop: 10, width: '100%' }}>
        <List.Item style={{ padding: 0 }}
          left={() => <Avatar.Icon icon='calendar-month' size={64} color='#323232' style={{ backgroundColor: 'transparent' }} />}
          title='question disponibilité lorem ipsum dolor sit amet?'
          titleNumberOfLines={2} titleStyle={{ color: '#323232'}}
        />
      </List.Section>
      <Paragraph>explication, lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
      <Surface style={styles.surface}>
        <View style={{ marginRight: 12 }}>
          <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
        </View>
        <Text style={{ color: '#A5A5A5' }}>bloc de 3 heures minimum par mission. modifications possibles plus tard dans votre profil.</Text>
      </Surface>
      <View style={{ width: '100%' }}>
        {/* <RadioButton.Group onValueChange={value => setDispo(Dispo[value as keyof typeof Dispo])} value={Dispo[dispo]}>
          <RadioButton.Item label={t('nurse.availabilityFixed')} value={Dispo[Dispo.FIXED]} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }} />
          <RadioButton.Item label={t('nurse.availabilityFlex')} value={Dispo[Dispo.FLEXIBLE]} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }} />
        </RadioButton.Group> */}
      </View>
    </ScrollView>
    <OnboardingFooter onNext={() => { /* onboarding({ step35: { dispo }}) */ }} />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
  },
  surface: {
    elevation: 1,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
