export const schema = {
  type: 'object',
  properties: {
    systolic: { type: 'number', minimum: 0, maximum: 1000 },
    diastolic: { type: 'number', minimum: 0, maximum: 1000 },
    pulse: { type: 'number', minimum: 0, maximum: 1000 },
    spo2: { type: 'number', minimum: 0, maximum: 1000 },
    respirations: { type: 'number', minimum: 0, maximum: 1000 },
    temperature: { type: 'number', minimum: 0, maximum: 1000 },
  },
  butvalid: { type: 'boolean' },
}
