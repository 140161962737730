import { useContext, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Card, IconButton, Avatar, Surface, Subheading, TextInput, Modal, Button, Banner } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
// import { scale, moderateScale } from '../Scaling';

// Utils
export const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
// export const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
export function pwdStrengthChecker(str: string) {
  if (!str) return false;
  return strongPassword.test(str) ? true : false;
}


export interface FirstPwdProps {
  show: boolean;
  onCancel: () => void;
}

export function FirstPwd({ show, onCancel }: FirstPwdProps) {
  const { authState, updPwd } = useContext(authStore);
  const [tmpPwd, setTmpPwd] = useState('');
  const [pwd, setPwd] = useState('');
  const [conf, setConf] = useState('');
  const [cmpError, setCmpError] = useState('');
  const [error, setError] = useState<unknown>();
  const [t] = useTranslation();

  async function sendReset() {
    try {
      if (!tmpPwd || !pwd || !conf) {
        setCmpError('login.messages.error.incomplet');
        return;
      }
      if (!authState.user?.username) {
        setCmpError('login.messages.error.malformedPhoneNumber');
        return;
      }
      if (pwd !== conf) {
        setCmpError('login.messages.error.pwdNoMatch');
        return;
      }
      if (!pwdStrengthChecker(pwd)) {
        setCmpError('login.form.password-rules');
        return;
      }
      updPwd(authState.user.username, pwd);
    }
    catch (error) {
      // console.log('>>> FIRST PWD ERROR:', error);
      setError(error);
    }
  }

  function notReadyToGo() {
    return !tmpPwd || tmpPwd.length < 6
      || !pwd || !pwdStrengthChecker(pwd)
      || pwd !== conf;
  }

  return <Modal
    visible={show} // onDismiss={onDone}
    style={{ shadowOpacity: 0 }}
    contentContainerStyle={{ padding: 12 }}
  >
    <Card>
      <Card.Title
        title={t('login.password.needNew1')} titleNumberOfLines={2}
        left={props => <Avatar.Icon {...props} icon='account-lock' />}
        right={props => <IconButton {...props} icon='close-circle-outline' onPress={onCancel} />}
      />
      <Card.Content>
        <Banner
          visible={error != null || !!cmpError}
          style={{ marginBottom: 24 }}
          actions={[
            {
              label: t('shorts.close'),
              onPress: () => { setError(undefined); setCmpError(''); },
            },
            // {
            //   label: t('generic.menu.contactUs'),
            //   onPress: () => { setError(undefined); setCmpError(''); },
            // },
          ]}
          icon={cprops => <Avatar.Icon {...cprops} icon='alert-circle' />}
        >
          {t(cmpError || 'error.unexpected')}
        </Banner>
        <Subheading style={{ marginBottom: 24 }}>{t('login.password.recupSms3')}</Subheading>
        <Surface style={styles.surface}>
          <View style={{ marginRight: 12 }}>
            <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
          </View>
          <Text style={{ color: '#A5A5A5' }}>{t('login.password.needNew2')}</Text>
        </Surface>
        <View style={{ width: '100%', marginTop: 1, marginBottom: 16 }}>
          <TextInput mode='outlined' label={t('login.reset.needNew3')} value={tmpPwd} onChangeText={text => setTmpPwd(text)} />
        </View>
        <View style={{ width: '100%', marginTop: 1, marginBottom: 16 }}>
          <TextInput mode='outlined' label={t('login.form.password')} value={pwd} onChangeText={text => setPwd(text)} />
        </View>
        <View style={{ width: '100%', marginTop: 1, marginBottom: 16 }}>
          <TextInput mode='outlined' label={t('login.form.password-confim')} value={conf} onChangeText={text => setConf(text)} />
        </View>
        <Button icon='email' mode='contained' onPress={() => sendReset()} disabled={notReadyToGo()} style={{ marginBottom: 16 }}>
          {t('login.form.title_reset')}
        </Button>
      </Card.Content>
    </Card>
  </Modal>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  surface: {
    elevation: 1,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
