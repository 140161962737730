import { 
  // AppSyncResolverEvent, 
  AppSyncIdentity, 
  AppSyncIdentityCognito, 
  AppSyncIdentityIAM, 
  // AppSyncResolverEventHeaders, 
  // Context
} from 'aws-lambda';

export function identUsername(identity: AppSyncIdentity | undefined | null) {
  if(identity && (identity as AppSyncIdentityCognito).username ) {
    return (identity as AppSyncIdentityCognito).username;
  } else {
    return undefined;
  }
}

export function identSub(identity: AppSyncIdentity | undefined | null) {
  if(identity && (identity as AppSyncIdentityCognito).sub ) {
    return (identity as AppSyncIdentityCognito).sub;
  } else if(identity && (identity as AppSyncIdentityIAM).username) {
    return (identity as AppSyncIdentityIAM).username
  } else {
    return undefined;
  }
}