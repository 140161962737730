import { TFunction } from 'i18next';
//
import { Locale, LocaleClass } from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER

export function getUserCountry(locale?: Locale) {
  const country = locale ? new LocaleClass(locale).getCountry() : undefined;
  if (!country) {
    // TODO @fp - Get it from the browser
  }
  return 'CA';
}

// REF: https://www.nngroup.com/articles/required-fields/

export function tLabel(t: TFunction, key: string, required?: boolean) {
  return `${t(key)}${required ? ' *' : ''}`;
}

export function tLabel2(t: TFunction, key: string, required?: boolean) {
  return `${t(key)}${required ? ` (${t('shorts.required')})` : ''}`;
}
