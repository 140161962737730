// import { useState /* , useMemo */ } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
//
import { OnboardingInfo, OnboardingData } from '@eksoh/flo/model';

export interface InterviewProps {
  data: OnboardingInfo;
  onbData: OnboardingData;
}

export function Interview({ data, onbData }: InterviewProps) {
  const [t] = useTranslation();

  return <Grid container mt={2}>
    <Grid item xs={12}>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q1`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q2`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q3`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q4`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q5`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q6`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q7`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q8`)}</Typography>
      <Typography variant='subtitle2'>{t(`nurse.audioCv.q9`)}</Typography>
    </Grid>
  </Grid>
}