import { ReactElement, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
import IconButton /* , { IconButtonProps } */ from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MuiPhoneNumber from 'mui-phone-number';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import BugReportIcon from '@mui/icons-material/BugReport';
import { useForm, Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
//
import {
  Locale, Gender, Group, CreateUserInput, UserInput, User,
} from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER
import {
  globalStore, defaulCreatetUser, tLabel2, useAppDispatch, useAppSelector, getUserCountry,
  getUser, getGroups, updateUser, createUser, reset as resetUser,
  // getPatient, updatePatient, createPatient, reset as resetPatient,
  SelectGroupsModal, listGroups, AreYouSureModal, eYesNo,
} from '../../..';

// interface ExpandMoreProps extends IconButtonProps {
//   expand: boolean;
// }

// const ExpandMore = styled((props: ExpandMoreProps) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

// from react-jhipster src/util/number-utils.ts =================

export function isEmpty(value: unknown) {
  return (
    typeof value === 'undefined' ||
    value === null ||
    (typeof value === 'string' && value.trim() === '') ||
    value === false ||
    (Array.isArray(value) && value.length === 0)
  );
}

// =================

// from react-jhipster src/form/validated-form.tsx =================

const EMAIL_REGEXP =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export function isEmail(value: string) {
  if (isEmpty(value)) return true;

  return EMAIL_REGEXP.test(value);
}

// =================

export enum UserInputsState { IDLE, CREATING, CREATED, UPDATING, UPDATED, ERROR };

export type UserInputsUpdates = (
  isDirty: boolean,
  isValid: boolean,
  state: UserInputsState,
  saveUser: () => void,
) => void

export interface UserInputsProps {
  isAdmin?: boolean;
  isClinician?: boolean;
  userId?: string;
  reducer: 'userManagement'; // TODO TMP: | 'patientMngt';
  overrideButtons?: UserInputsUpdates;
  onCancelled: () => void;
  onWaiting: (waiting: boolean) => ReactElement | null;
  disabledAutocomplete?: boolean;
}

export function UserInputs(props: UserInputsProps) {
  // const [expanded, setExpanded] = useState(false);
  // const handleExpandClick = () => setExpanded(!expanded);

  const { globalState } = useContext(globalStore);
  const dispatch = useAppDispatch();
  const [showGroups, setShowGroups] = useState(false);
  const [showARS, setShowARS] = useState(false);
  const [t] = useTranslation();
  const {
    setValue, getValues, watch, formState: { isDirty, isValid, errors }, control,
  } = useForm<CreateUserInput>({
    mode: 'onChange',
    defaultValues: defaulCreatetUser,
  });

  const isNew = !props.userId;
  const user = useAppSelector(state => state[props.reducer].user);
  const loading = useAppSelector(state => state[props.reducer].loading);
  const updating = useAppSelector(state => state[props.reducer].updating);
  const updateSuccess = useAppSelector(state => state[props.reducer].updateSuccess);
  // const groups = useAppSelector(state => state[props.reducer].groups);
  const errorMessage = useAppSelector(state => state[props.reducer].errorMessage);

  useEffect(() => {
    watch(['user.groups']);

    if (props.userId) {
      dispatch(props.isAdmin ? getUser(props.userId) : getUser(props.userId)); // TODO TMP: getPatient(props.userId));
    }
    else {
      dispatch(props.isAdmin ? resetUser() : resetUser()); // TODO TMP: resetPatient());
    }

    if (props.isAdmin) dispatch(getGroups());
    return () => {
      dispatch(props.isAdmin ? resetUser() : resetUser()); // TODO TMP: resetPatient());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(watch('birthdate'));

  useEffect(() => {
    if (props.userId != null) {
      Object.keys(user).forEach(k => setValue(
        `user.${k}` as keyof CreateUserInput,
        user[k as keyof UserInput],
        { shouldValidate: false, shouldDirty: false },
      ));
    }
    else if (props.isClinician) {
      setValue('user.groups', [Group.CLIENT], { shouldTouch: false, shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (props.overrideButtons != null) {
      const inpState = isNew
        ? updating ? UserInputsState.CREATING : updateSuccess ? UserInputsState.CREATED : UserInputsState.IDLE
        : updating ? UserInputsState.UPDATING : updateSuccess ? UserInputsState.UPDATED : UserInputsState.IDLE
      props.overrideButtons(isDirty, isValid, inpState, saveUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isDirty, updating]);

  function saveUser() {
    const usrInput = getValues();

    if (usrInput.user == null) return;

    // to iso date for aws...
    usrInput.user.birthdate = DateTime.fromJSDate(usrInput.user.birthdate, { zone: 'utc' }).toISODate();

    if (isNew) {
      dispatch(props.isAdmin ? createUser(usrInput) : createUser(usrInput)); // TODO TMP: createPatient(user));
    }
    else {
      const user = {
        ...usrInput.user, enabled: true, username: '', name: '', createdAt: new Date(), updatedAt: new Date(),
        emailVerified: false, mfaSmsActive: false, phoneNumberVerified: false, userStatus: 'UNKNOWN',
      } as User; // TODO TMP: use UserInput properly
      dispatch(props.isAdmin ? updateUser(user) : updateUser(user)); // TODO TMP: updatePatient(getValues()));
    }
  };

  function readyToLeave() {
    if (isDirty) setShowARS(true);
    else props.onCancelled();
  }

  function displayErr(field: string, type?: unknown) {
    if (type === 'required') {
      return `${t(field)}: ${t('entity.validation.required')}`;
    }
    else if (type === 'validate') {
      return `${t(field)}: ${t(field === 'email' ? 'entity.validation.invalidEmail' : 'entity.validation.invalid')}`;
    }
    else {
      return `${t(field)}: ${t('entity.validation.invalid')}`;
    }
  }

  function displayErrMsg(field: string) {
    const err = errors[field as keyof CreateUserInput];
    if (err == null) return `${t(field)}: ${t('entity.validation.invalid')}`;
    return err.message || displayErr(field, err.type);
  }

  const country = getUserCountry(user.locale || Locale.FR_CA).toLowerCase();
  const genderLabel = tLabel2(t, 'userManagement.gender', true);
  const localeLabel = tLabel2(t, 'userManagement.locale', true);
  return <Card>
    <CardHeader
      avatar={<PersonAddIcon fontSize='large' />}
      // action={<IconButton aria-label="settings">
      //   <MoreVertIcon />
      // </IconButton>}
      action={<Controller
        control={control}
        name='user.enabled'
        render={({ field: { onChange, onBlur, value } }) => <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='caption'>{t('userManagement.active')}</Typography>
          <Switch checked={value || false} onChange={onChange} onBlur={onBlur} />
        </Box>}
      />}
      // action={<Box display='flex' flexDirection='column' alignItems='center'>
      //   <Typography variant='caption'>active</Typography>
      //   <Switch
      //     checked={user.enabled}
      //     onChange={(_, b) => setValue('user.enabled', !b, { shouldTouch: true, shouldDirty: true })}
      //   />
      // </Box>}
      // action={<Controller
      //   name='user.enabled'
      //   control={control}
      //   rules={{ required: true }}
      //   render={({ field: { onChange, onBlur, value } }) => <Box display='flex' flexDirection='column' alignItems='center'>
      //     <Typography variant='caption'>active</Typography>
      //     <Switch checked={value || false} onChange={onChange} onBlur={onBlur} />
      //   </Box>}
      // />}
      title={t(isNew ? 'userManagement.home.createLabel' : 'userManagement.home.editLabel')}
      subheader={<Box display='flex'>
        <Typography>
          {'group: ' + listGroups(getValues().user.groups || [], props.isAdmin)}
        </Typography>
        <IconButton style={{ height: 20, marginLeft: 4 }} onClick={() => setShowGroups(true)}>
          <EditIcon sx={{ fontSize: 18 }} />
        </IconButton>
      </Box>}
    />
    <CardContent>
      {props.onWaiting(loading || updating)}
      {errors && Object.keys(errors).map(field => errors ? <Alert key={`form-err-${field}`} severity='error' style={{ marginBottom: 20 }}>{displayErrMsg(field)}</Alert> : null)}
      {errorMessage && <Alert severity='error' style={{ marginBottom: 20 }}>{errorMessage}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            name='user.givenName'
            control={control}
            rules={{ required: true, maxLength: 30 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.givenName', true)} fullWidth variant='standard'
              autoComplete='given-name' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='user.familyName'
            control={control}
            rules={{ required: true, maxLength: 30 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.familyName', true)} fullWidth variant='standard'
              autoComplete='family-name' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='user.address.streetAddress'
            control={control}
            rules={{ required: true, maxLength: 120 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.physicalAddress.streetAddress', true)} fullWidth variant='standard'
              autoComplete='street-address' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='user.address.locality'
            control={control}
            rules={{ required: true, maxLength: 60 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.physicalAddress.locality', true)} fullWidth variant='standard'
              autoComplete='address-level2' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='user.address.region'
            control={control}
            rules={{ required: true, maxLength: 60 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.physicalAddress.region', true)} fullWidth variant='standard'
              autoComplete='address-level1' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='user.address.postalCode'
            control={control}
            rules={{ required: true, maxLength: 10 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.physicalAddress.postalCode', true)} fullWidth variant='standard'
              autoComplete='postal-code' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='user.address.country'
            control={control}
            rules={{ required: true, maxLength: 60 }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              label={tLabel2(t, 'userManagement.physicalAddress.country', true)} fullWidth variant='standard'
              autoComplete='country' value={value} onChange={onChange} onBlur={onBlur}
            />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* INFO - MuiPhoneNumber generates -> Warning: Each child in a list should have a unique 'key' prop. in browser. */}
          <Controller
            control={control} rules={{ required: true }}
            name='user.phoneNumber'
            render={({ field: { onChange, onBlur, value } }) => (
              <MuiPhoneNumber defaultCountry={country} onChange={onChange} onBlur={onBlur} value={value}
                label={tLabel2(t, 'userManagement.phoneNumber', true)} placeholder='+1 (514) 123-1234'
                style={{ width: '100%' }} variant='standard'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {isNew && <Controller
            name='user.email'
            control={control}
            rules={{ required: true, maxLength: 100, validate: v => isEmail(v) }}
            render={({ field: { onChange, onBlur, value } }) => <TextField
              onChange={onChange} onBlur={onBlur} value={value} variant='standard'
              label={tLabel2(t, 'userManagement.email', true)} fullWidth
            />}
          />}
          {!isNew && <TextField value={user.email} label={t('userManagement.email')} style={{ border: 0 }}
            InputProps={{ readOnly: true }} fullWidth variant='standard'
          />}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name='user.birthdate'
            control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePicker
                openTo='year' views={['year', 'month', 'day']}
                label={tLabel2(t, 'userManagement.dob', true)} disableFuture
                value={value || null} onChange={onChange}
                renderInput={(props) => <TextField
                  {...props} onBlur={onBlur} variant='standard' style={{ width: '100%' }}
                />}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name='user.gender'
            control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => <FormControl variant='standard' style={{ width: '100%' }}>
              <InputLabel>{genderLabel}</InputLabel>
              <Select fullWidth value={value} onChange={onChange} onBlur={onBlur} label={genderLabel}>
                {Object.values(Gender).map(s => <MenuItem key={`sel-gender-${s}`} value={s}>
                  {s}
                </MenuItem>)}
              </Select>
            </FormControl>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name='user.locale'
            control={control} rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => <FormControl variant='standard' style={{ width: '100%' }}>
              <InputLabel>{localeLabel}</InputLabel>
              <Select fullWidth value={value} onChange={onChange} onBlur={onBlur} label={localeLabel}>
                {Object.values(Locale).map(l => <MenuItem key={`sel-locale-${l}`} value={l}>
                  {l}
                </MenuItem>)}
              </Select>
            </FormControl>}
          />
        </Grid>

      </Grid>
    </CardContent>
    <CardActions disableSpacing>
      {globalState.isDev && <IconButton onClick={() => console.log('>>> FORM VALUES:', getValues())}>
        <BugReportIcon fontSize='large' />
      </IconButton>}
      {props.overrideButtons == null && <Box sx={{ ml: 'auto' }}>
        {/* <Button color='primary' variant='outlined' onClick={() => props.onCancelled()} style={{ marginRight: 20 }}>
          <Typography variant='button' align='center' style={{ width: '15em' }}>{t('shorts.back')}</Typography>
        </Button>
        <Button color='primary' variant='contained' onClick={saveUser} disabled={!isValid || updating || !isDirty}>
          <Typography variant='button' align='center' style={{ width: '15em' }}>{t('entity.action.save')}</Typography>
        </Button> */}
        <IconButton onClick={readyToLeave}>
          <CancelIcon fontSize='large' />
        </IconButton>
        <IconButton onClick={saveUser} disabled={!isValid || updating || !isDirty}>
          <SaveIcon fontSize='large' />
        </IconButton>
      </Box>}
    </CardActions>
    {/* <CardActions disableSpacing>
      <IconButton aria-label="add to favorites">
        <FavoriteIcon />
      </IconButton>
      <IconButton aria-label="share">
        <ShareIcon />
      </IconButton>
      <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore>
    </CardActions>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Typography paragraph>bla bla bla:</Typography>
        <Typography paragraph>
          bla bla bla...
        </Typography>
        <Typography paragraph>
          bla bla bla...
        </Typography>
        <Typography paragraph>
          bla bla bla...
        </Typography>
        <Typography>
          bla bla bla...
        </Typography>
      </CardContent>
    </Collapse> */}
    <SelectGroupsModal
      open={showGroups} onClose={() => setShowGroups(false)}
      groups={getValues().user.groups || []} onSubmit={gps => {
        setValue('user.groups', gps, { shouldTouch: true, shouldDirty: true });
        setShowGroups(false);
      }}
    />
    <AreYouSureModal
      show={showARS}
      onClose={() => setShowARS(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      msg={t('userManagement.cancel.question')}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) {
          props.onCancelled();
        }
        setShowARS(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.cancel')}
    />
  </Card>
}
