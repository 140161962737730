/* eslint-disable @typescript-eslint/no-explicit-any */

import { CSSProperties, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from '@react-spring/web';
import { useTranslation } from 'react-i18next';
//
import { PopperContentData, PopperContent } from './popper';

const styles = {

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
    background: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    boxShadow: '0px 10px 10px -5px rgba(0, 0, 0, 0.05)',
    // breaks: position: 'fixed' -> willChange: 'width, height',
  } as CSSProperties,

  gridCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // breaks: position: 'fixed' -> willChange: 'transform, opacity',
  } as CSSProperties,

  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%',
    margin: 6,
    padding: 6,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
  } as CSSProperties,
}

export interface PopperMenuData {
  type: 'menu';
  title: string;
  data?: (PopperMenuData | PopperContentData)[];
}

export interface PopperMenuProps {
  disabled?: boolean;
  level: number;
  title: string;
  data: (PopperMenuData | PopperContentData)[];
  // beService: EmrClient;
  // extra?: EmrForm[];
  // onExtraUpd?: (index: number, data: any) => void;
  onOpen: () => void;
  onDone: () => void;
}

enum eAnimState { BUTTON, EXPAND, FILL, OPENED, EMPTY, RETRACK }

export function PopperMenu({ level, title, data, onOpen, onDone, disabled /* , beService, extra, onExtraUpd */ }: PopperMenuProps) {
  const [open, setOpen] = useState(false);
  const [animState, setAnimState] = useState(eAnimState.BUTTON);
  const [t] = useTranslation();
  const AnimatedGrid = animated(Grid);

  const springApi = useSpringRef();
  const { width, height, ...rest } = useSpring({
    ref: springApi,
    config: config.default,
    from: { width: '20%', height: '25px' },
    to: {
      width: open ? '100%' : '20%',
      height: open ? '100%' : '25px',
    },
    onStart: () => setAnimState(open ? eAnimState.EXPAND : eAnimState.RETRACK),
    onRest: () => setAnimState(open ? eAnimState.FILL : eAnimState.BUTTON),
    // onChange: (vals, ctls) => {
    //   if (animState !== eAnimState.EMPTY) return;
    //   console.log('>>> VALS:', vals.value.height);
    //   // console.log('>>> CTLS:', ctls);
    // },
  });

  const transApi = useSpringRef();
  const transition = useTransition(open ? data : [], {
    ref: transApi,
    trail: 400 / data.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    onRest: () => setAnimState(open ? eAnimState.OPENED : eAnimState.EMPTY),
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(
    open
      ? [springApi, transApi]
      : [transApi, springApi],
    [0, open ? 0.1 : 0.6],
  );

  useEffect(() => {
    if (open) onOpen();
    else onDone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // useEffect(() => {
  //   console.log('>>> ANIM STATE:', eAnimState[animState])
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [animState]);

  const showLbl = animState === eAnimState.BUTTON || animState === eAnimState.RETRACK || animState === eAnimState.EMPTY;
  return <animated.div
    style={{
      ...styles.container, ...rest, width, height,
      cursor: disabled || open ? undefined : 'pointer',
      background: disabled ? 'grey' : 'white',
      minWidth: 'fit-content',
    }}
    onClick={disabled || open ? undefined : () => setOpen(open => !open)}
  >
    {showLbl && <Box minWidth={193} textAlign='center' pt={0}>
      <Typography variant='button'>{t(title)}</Typography>
    </Box>}
    {!showLbl && <Grid container width='100%'>
      <Grid item xs={2}></Grid>
      <Grid item xs={8} textAlign='center'><Typography variant='button'>{t(title)}</Typography></Grid>
      <Grid item xs={2} textAlign='right'>
        <IconButton onClick={() => setOpen(open => !open)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {transition((style, item) => <AnimatedGrid
        item xs={12} sm={6} md={4} lg={3}
        style={{
          ...styles.gridCell, ...style,
          cursor: item?.type === 'menu' ? 'pointer' : undefined,
        }}
        onClick={item?.type === 'menu' ? () => setOpen(open => !open) : undefined}
      >
        <Box style={styles.item}>
          {item?.type === 'menu' && <Typography variant='button'>{t(item?.title || 'no name')}</Typography>}
          {item?.type === 'content' && <PopperContent
            level={(level || 0) + 1}
            title={item?.title}
            content={item?.content}
            onOpen={() => {/* */}}
            onDone={() => {/* */}}
          />}
        </Box>
      </AnimatedGrid>)}
    </Grid>}
  </animated.div>
}
