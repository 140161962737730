/* eslint-disable @typescript-eslint/no-explicit-any */
//
import { setLocale } from './reducers/locale';
import { Storage } from '../..';

export const languages: any = {
  en: { name: 'English' },
  // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
  fr: { name: 'Français' },
};

export const locales = Object.keys(languages).sort();

export const registerLocale = (store: any) => {
  store.dispatch(setLocale(Storage.session.get('locale', 'en')));
};
