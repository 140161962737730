import { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
//
import { clientEmrStore, EmrCalendarEvent } from '../../../..';

export default function Day({ day, rowIdx }: { day: dayjs.Dayjs, rowIdx: number }) {
  const [dayEvents, setDayEvents] = useState<EmrCalendarEvent[]>([]);
  const { clientEmrState, setDaySelected, setShowEventModal, setSelectedEvent } = useContext(clientEmrStore);

  useEffect(() => {
    const events = clientEmrState.filteredEvents.filter(
      (evt) => dayjs(evt.day).format('DD-MM-YY') === day.format('DD-MM-YY')
    );
    setDayEvents(events);
  }, [clientEmrState.filteredEvents, day]);

  function getCurrentDayStyle() {
    const date = day.format('DD');
    return day.format('DD-MM-YY') === dayjs().format('DD-MM-YY')
      ? <Avatar sx={{ bgcolor: 'blue', width: 24, height: 24 }} alt={date}>
        <Typography variant='caption'>{date}</Typography>
      </Avatar>
      : <Typography variant='caption'>{date}</Typography>;
  }

  return <Box display='flex' flexDirection='column' width='100%' height='100%' border='1px solid #ccc'>
    <Box display='flex' flexDirection='column' textAlign='center' alignItems='center' pt={0.5}>
      {rowIdx === 0 && <Typography variant='caption'>{day.format('ddd').toUpperCase()}</Typography>}
      {getCurrentDayStyle()}
    </Box>
    <Box style={{ flex: '1 1 auto', cursor: 'pointer', textAlign: 'center' }}
      onClick={() => { setDaySelected(day); setShowEventModal(true); }}
    >
      {dayEvents.map((evt, idx) => <Chip
        key={'chip_' + idx} label={evt.title} sx={{ color: evt.label, width: '90%', mb: 0.5 }}
        onClick={() => setSelectedEvent(evt)}
      />)}
    </Box>
  </Box>
}
