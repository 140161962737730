import { List } from 'react-native-paper';
//
import { Diagnosis } from '@eksoh/shared/common';

export function DiagnosesList({ diagnoses }: { diagnoses: Array<Diagnosis> }) {
  return <List.Section>
    {diagnoses.map((diagnosis) => <List.Item
      key={diagnosis.code}
      title={`• ${diagnosis.code}: ${diagnosis.name}`}
      // left={() => <List.Icon color="#000" icon="folder" />}
    />)}
  </List.Section>
  // <List>
  //   {diagnoses.map((diagnosis) => <ListItem key={diagnosis.code}>
  //     <ListItemText
  //       primary={`• ${diagnosis.code}: ${diagnosis.name}`}
  //       // secondary={`from: ${entry.sickLeave.startDate} to ${entry.sickLeave.endDate}`}
  //     />
  //   </ListItem>)}
  // </List>
}
