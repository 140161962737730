import { 
  AUTH_TYPE, 
  AuthOptions
} from "aws-appsync-auth-link";
import { Credentials, CredentialProvider } from '@aws-sdk/types';
//
import {
  ListAppmtsDocument,
  GetAppmtDocument,
  CreateAppmtDocument, CreateAppmtInput,
  // v3
  // TestTimeOffer, TimeOffer,
  SearchTimeOffersInput, SearchTimeOffersDocument,
  SearchTimeOffersTodInput, SearchTimeOffersTodDocument,
  GetUserTimeOffersDocument,
  AppmntGroups, TestAppmntTypes,
  AdmCreateAppmtInput,
  AdmCreateAppmtDocument,
  AdmDeleteAppmtInput,
  AdmDeleteAppmtDocument,
  AdmListAppmtsDocument,
  GetAppmtInput,
  AdmGetAppmtInput,
  AdmGetAppmtDocument,
  CreatePreAppmtInput,
  CreatePreAppmtDocument,
  AdmListAllPreAppmtDocument,
  AdmGetPreAppmtInput,
  AdmGetPreAppmtDocument,
  MockBe,
  PreAppmtOperationInput,
  PreAppmtOperationDocument,
  AdmPreAppmtOperationInput,
  AdmPreAppmtOperationDocument,
  ListPreAppmtsDocument,
  ListAppmtsProfessionalInput,
  ListAppmtsProfessionalDocument,
  DeletePreAppmtInput,
  DeletePreAppmtDocument,
  AdmDeletePreAppmtInput,
  AdmDeletePreAppmtDocument,
  GetPreCancelAppmtInput,
  GetPreCancelAppmtDocument,
  CancelAppmtInput,
  CancelAppmtDocument,
  CollectPaymentInput,
  CollectPaymentDocument,
  AddAppmtItemsInput,
  AddAppmtItemsDocument,
  ListAppmtFilter,
  RemoveAppmtItemsInput,
  RemoveAppmtItemsDocument,
  ListAppmtsHistoryDocument,
  Appointment,
  CreateAppmtMutation,
  AdmCancelAppmtInput,
  AdmCancelAppmtDocument
} from '@eksoh/flo/model';
import { ApolloBase } from './apollobase';
import { AppsyncConfigParams } from '@eksoh/shared/common';

export type CreateAppmtType = CreateAppmtMutation['createAppmt'];

export class AppmtsClient extends ApolloBase {
  constructor(
    params: AppsyncConfigParams,
    jwtToken?: string,
    credentials?: (() => Credentials | CredentialProvider | Promise<Credentials | CredentialProvider | null>) | Credentials | CredentialProvider | null,
    tz?: string
  ) {
    let auth: AuthOptions;
    if (credentials) {
      auth = { type: AUTH_TYPE.AWS_IAM, credentials };
    }
    else {
      auth = { type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS, jwtToken: async () => jwtToken || '' };
    }
    
    super(params, auth, tz);
  }

  async listAppmts(type?: TestAppmntTypes, from?: Date, filter?: ListAppmtFilter) {
    const result = await this.client.query({
      query: ListAppmtsDocument,
      variables: { input: {
        from: from?.toISOString(),
        appmtType: type, 
        filter: filter || ListAppmtFilter.ACTIVE
      } },
    });
    return result.data?.listAppmts;
  }

  async listAppmtsHistory(type?: TestAppmntTypes, from?: Date, filter?: ListAppmtFilter) {
    const result = await this.client.query({
      query: ListAppmtsHistoryDocument,
      variables: { input: {
        from: from?.toISOString(),
        appmtType: type, 
        filter: filter || ListAppmtFilter.ACTIVE
      } },
    });
    return result.data?.listAppmts;
  }

  async listAppmtsProfessional(input: ListAppmtsProfessionalInput) {
    const result = await this.client.query({
      query: ListAppmtsProfessionalDocument,
      variables: { input: { 
        filter: ListAppmtFilter.ACTIVE,
        from: input.from
      }},
    });
    return result.data?.listAppmtsProfessional;
  }

  async admListAppmts(lng: number, lat: number, type?: TestAppmntTypes[], from?: Date) {
    const result = await this.client.query({
      query: AdmListAppmtsDocument,
      variables: { input: { 
        lng,
        lat,
        appmtTypes: type, 
        from: from?.toISOString(),
        filter: ListAppmtFilter.ACTIVE
      } },
    });
    return result.data?.admListAppmts;
  }

  async getAppmt(input: GetAppmtInput) {
    const result = await this.client.query({
      query: GetAppmtDocument,
      variables: { input }
    });
    return result.data.getAppmt;
  }

  async createAppmt(input: CreateAppmtInput) {
    const result = await this.client.mutate({
      mutation: CreateAppmtDocument,
      variables: { input }
    });
    return result.data?.createAppmt;
  }

  async admCreateAppmt(input: AdmCreateAppmtInput) {
    const result = await this.client.mutate({
      mutation: AdmCreateAppmtDocument,
      variables: { input }
    });
    return result.data?.admCreateAppmt;
  }

  async admGetAppmt(input: AdmGetAppmtInput) {
    const result = await this.client.query({
      query: AdmGetAppmtDocument,
      variables: { input }
    });
    return result.data.admGetAppmt;
  }

  // async deleteAppmt(input: DeleteAppmtInput) {
  //   const result = await this.client.mutate({
  //     mutation: DeleteAppmtDocument,
  //     variables: { input }
  //   });
  //   return result.data?.deleteAppmt;
  // }

  async cancelAppmt(input: CancelAppmtInput) {
    const result = await this.client.mutate({
      mutation: CancelAppmtDocument,
      variables: { input }
    });
    return result.data?.cancelAppmt;
  }

  async admCancelAppmt(input: AdmCancelAppmtInput) {
    const result = await this.client.mutate({
      mutation: AdmCancelAppmtDocument,
      variables: { input }
    });
    return result.data?.admCancelAppmt;
  }

  async admDeleteAppmt(input: AdmDeleteAppmtInput) {
    const result = await this.client.mutate({
      mutation: AdmDeleteAppmtDocument,
      variables: { input }
    });
    return result.data?.admDeleteAppmt;
  }

  async createPreAppmt(input: CreatePreAppmtInput) {
    const result = await this.client.mutate({
      mutation: CreatePreAppmtDocument,
      variables: { input }
    });
    return result.data?.createPreAppmt;
  }

  async deletePreAppmt(input: DeletePreAppmtInput) {
    const result = await this.client.mutate({
      mutation: DeletePreAppmtDocument,
      variables: { input }
    });
    return result.data?.deletePreAppmt;
  }

  async admDeletePreAppmt(input: AdmDeletePreAppmtInput) {
    const result = await this.client.mutate({
      mutation: AdmDeletePreAppmtDocument,
      variables: { input }
    });
    return result.data?.admDeletePreAppmt;
  }

  async preAppmtOperation(input: PreAppmtOperationInput) {
    const result = await this.client.mutate({
      mutation: PreAppmtOperationDocument,
      variables: { input }
    });
    return result.data?.preAppmtOperation;
  }

  async admPreAppmtOperation(input: AdmPreAppmtOperationInput) {
    const result = await this.client.mutate({
      mutation: AdmPreAppmtOperationDocument,
      variables: { input }
    });
    return result.data?.admPreAppmtOperation;
  }

  async admListAllPreAppmt() {
    const result = await this.client.query({
      query: AdmListAllPreAppmtDocument
    });
    return result.data.admListAllPreAppmt;
  }

  async admGetPreAppmt(input: AdmGetPreAppmtInput) {
    const result = await this.client.query({
      query: AdmGetPreAppmtDocument,
      variables: { input }
    });
    return result.data.admGetPreAppmt;
  }

  async listPreAppmts() {
    const result = await this.client.query({
      query: ListPreAppmtsDocument
    });
    return result.data.listPreAppmts;
  }

  // v3

  async searchTimeOffers(input: SearchTimeOffersInput) {
    const result = await this.client.query({
      query: SearchTimeOffersDocument,
      variables: { input },
    });
    return result.data.searchTimeOffers;
  }

  async searchTimeOffersTod(input: SearchTimeOffersTodInput) {
    const result = await this.client.query({
      query: SearchTimeOffersTodDocument,
      variables: { input },
    });
    return result.data.searchTimeOffersTod;
  }

  async getUserTimeOffers(username: string) {
    const result = await this.client.query({
      query: GetUserTimeOffersDocument,
      variables: { username },
    });
    return result.data.getUserTimeOffers;
  }

  async getPreCancelAppmt(input: GetPreCancelAppmtInput) {
    const result = await this.client.query({
      query: GetPreCancelAppmtDocument,
      variables: { input },
    });
    return result.data.getPreCancelAppmt;
  }

  async collectPayment(input: CollectPaymentInput) {
    const result = await this.client.mutate({
      mutation: CollectPaymentDocument,
      variables: { input },
    });
    return result.data?.collectPayment;
  }

  async addAppmtItems(input: AddAppmtItemsInput) {
    const result = await this.client.mutate({
      mutation: AddAppmtItemsDocument,
      variables: { input },
    });
    return result.data?.addAppmtItems;
  }

  async removeAppmtItems(input: RemoveAppmtItemsInput) {
    const result = await this.client.mutate({
      mutation: RemoveAppmtItemsDocument,
      variables: { input },
    });
    return result.data?.removeAppmtItems;
  }

  // tmp / debug - appmt types

  async getAppmntTypesDetails() {
    return MockBe.getAppmntTypesDetails();
  }

  async getAppmntTypesDetailsOfGroup(group: AppmntGroups) {
    return MockBe.getAppmntTypesDetailsOfGroup(group);
  }

  async getAppmntTypeDetailsByType(type: TestAppmntTypes) {
    return MockBe.getAppmntTypeDetailsByType(type);
  }

  async getAppmntTypeDetailsByCode(code: string) {
    return MockBe.getAppmntTypeDetailsByCode(code); 
  }

  async getAppmntTypeDetailsByGroupAndCode(group: AppmntGroups, code: string) {
    return MockBe.getAppmntTypeDetailsByGroupAndCode(group, code); 
  }

  async getAllResidenceDetails() {
    return MockBe.getAllResidenceDetails(); 
  }

  async getResidenceDetailsByTmppwd(tmppwd: string) {
    return MockBe.getResidenceDetailsByTmppwd(tmppwd); 
  }

  isAppmt(res: Partial<CreateAppmtType> ): res is Appointment {
    return res?.__typename !== 'Appointment';
  }
  // common

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // private parseContent<T>(contentHolder: { detail: any } | undefined | null): T {
  //   return (contentHolder && contentHolder.detail && {
  //     ...contentHolder,
  //     detail: JSON.parse(contentHolder.detail)
  //   }) || contentHolder;
  // }

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // private parseContents<T>(contentHolders: { detail: any }[] | undefined | null) {
  //   return contentHolders?.map(c => this.parseContent<T>(c));
  // }
}
