// import styles from './debug-label.module.css';

import { MouseEvent, ReactNode, /* ElementType, */ useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import SvgIcon from '@mui/material/Icon';
//
import { globalStore, conf } from '../../../../../ui/src/lib/contexts';
import { mainConsoleOut, ConsoleOutMenus } from './console-out';

export interface MenuTileProps {
  menuItems: ReactNode[];
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export function MenuTile(props: MenuTileProps) {
  const opened = Boolean(props.anchorEl);

  return <Menu
    anchorEl={props.anchorEl}
    id='account-menu'
    open={opened}
    onClose={props.onClose}
    onClick={props.onClose}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    {props.menuItems}
  </Menu>
}

//
// =================================================
//

// export interface DebugIconProps {
//   menuItems: ReactNode[];
//   icon?: ElementType;
//   color?: string;
// }

// export function DebugIcon(props: DebugIconProps) {
//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//   const icon = props.icon || MoreVertIcon;

//   return <>
//     <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)} size='large'>
//       <SvgIcon component={icon} style={{ color: props.color || '#2dcbd3' }} />
//     </IconButton>
//     <MenuTile
//       menuItems={props.menuItems}
//       anchorEl={anchorEl}
//       onClose={() => setAnchorEl(null)}
//     />
//   </>
// }

//
// =================================================
//

export interface DebugLabelProps {
  pathname: string
  label: string;
  color?: string;
  callback?: (pathname: string) => ConsoleOutMenus;
}

export function DebugLabel(props: DebugLabelProps) {
  const { globalState } = useContext(globalStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function getMenu() {
    if (props.pathname === 'todo_contextual_menu_one_day') return mainConsoleOut(conf, props.pathname, props.callback);
    // default
    return mainConsoleOut(conf, props.pathname, props.callback);
  }

  if (!globalState.isDev) {
    return <Typography variant='h6' noWrap component='div' style={{ marginRight: 20 }}>
      {props.label}
    </Typography>
  }

  return <>
    <div style={{
      position: 'relative',
      cursor: 'default'
    }}>
      <Typography variant='h6' noWrap component='div' style={{ marginRight: 20 }} onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}>
        {props.label}
      </Typography>
      <Typography variant='caption' style={{ position: 'absolute', top: 20, right: 0 }}>
        dev
      </Typography>
    </div>
    <MenuTile
      menuItems={getMenu()}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
    />
  </>
}
