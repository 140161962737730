import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface NewFormProps {
  open: boolean;
  onCreate: (name: string, category: string) => void;
  onCancel: () => void;
}

export default function NewForm({ open, onCreate, onCancel }: NewFormProps) {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => reset(), []);

  function reset() { setName(''); setCategory('General'); }

  return <Dialog open={open} onClose={onCancel}>
    <DialogTitle>Subscribe</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Select a unique name and a category
      </DialogContentText>
      <TextField
        autoFocus margin='dense' id='name' label='Name' fullWidth variant='standard'
        value={name} onChange={e => setName(e.target.value)}
      />
      <TextField
        margin='dense' id='category' label='Category' fullWidth variant='standard'
        value={category} onChange={e => setCategory(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={() => { onCreate(name, category); reset(); }} disabled={!name || !category}>
        Create
      </Button>
    </DialogActions>
  </Dialog>
}