import React from 'react';
import Box from '@mui/material/Box';
//
import MyTag from '../shared/cmps/mytag';
import Tag, { CounterTag } from '../shared/cmps/tag';
import TodoList from '../shared/cmps/todolist';
import ClientList from '../shared/cmps/clientlist';

export function Broker() {
  return <>
    <Box display='flex'>
      <Box style={{ flex: 1 }}>
        <MyTag gutterBottom={true} />
        <Box display='flex' style={{ marginBottom: 12 }}>
          <CounterTag title='Approuvée' num={6} label='New' of={125} gutterRight />
          <CounterTag isLight title='Rejetée' num={2} label='New' of={14} gutterRight />
          <Tag isLight>Agenda</Tag>
        </Box>
        <Box display='flex' style={{ marginBottom: 12 }}>
          <CounterTag title='Info pourcentage et argent gagné' num={6} label='New' of={125} gutterRight />
          <CounterTag isLight title='Info pourcentage et argent gagné' num={6} label='New' of={125} gutterRight />
        </Box>
      </Box>
      <TodoList />
    </Box>
    <ClientList />
  </>
}
