import { useContext /* , useState */ } from 'react';
import { StyleSheet } from 'react-native';
import { /* Surface, Avatar, TextInput, IconButton, */ Text, Button } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { OnboardingState } from '@eksoh/flo/model';
import { authStore, globalStore } from '@eksoh/shared/ui';
import { appMaxWidth, appMaxWidthOnboarded } from '@eksoh/flo/ui';
import { View } from '../cmps/Themed';
import { RootStackScreenProps } from '../types';
import Layout from '../cmps/Layout';
import Logo from '../cmps/Logo';
import { scale /* , moderateScale */ } from '../Scaling';
import LanguageBanner from '../cmps/LanguageBanner';
import { DebugPanel } from '../cmps/Debug';

const stepInfo: { [key: string]: string } = {
  [OnboardingState.REG_COMPLETED]: 'nurse.notYetVetted',
  [OnboardingState.REG_REJECTED]: 'nurse.regRejected',
  [OnboardingState.INTERVIEW]: 'nurse.interview',
  [OnboardingState.ATCD]: 'nurse.atcd',
  [OnboardingState.ATCD_WAITING]: 'nurse.atcdWaiting',
  [OnboardingState.ATCD_FAILED]: 'nurse.atcdFailed',
  [OnboardingState.EMPLOYEE_INFO_COMPLETED]: 'nurse.preWatchVid',
  [OnboardingState.COMPLETED]: 'nurse.youAreIn',
}

export default function NotYetVetted({ navigation }: RootStackScreenProps<'NotYetVetted'>) {
  const { globalState } = useContext(globalStore);
  const { authState, signOut } = useContext(authStore);
  // const [code, setCode] = useState('');
  const [t] = useTranslation();

  const showDev = globalState.isDev;
  const onbState = authState.onboardingState || OnboardingState.REG_COMPLETED;
  const appWidth = onbState === OnboardingState.COMPLETED ? appMaxWidthOnboarded : appMaxWidth;
  return <Layout hideFab={true}>
    <View style={[styles.container, { width: '100%', maxWidth: appWidth }]}>
      <View style={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: 'transparent', paddingTop: 24 }}>
        <LanguageBanner />
      </View>
      <View style={{ height: '10vh'}} />
      <Logo style={{ marginTop: scale(1) }} width={60} height={60} />
      <Text style={[styles.title, { marginTop: scale(20), marginBottom: scale(20) }]}>{t(stepInfo[onbState] || 'nurse.notYetVetted')}</Text>
      {onbState === OnboardingState.REG_COMPLETED && <View
        style={{ width: '100%', marginTop: scale(24), marginBottom: scale(48), backgroundColor: 'transparent' }}
      >
        {/* <Text style={{ marginBottom: 6, textAlign: 'center' }}>TRANS : if you have been given a code from florence, use it here.</Text>
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}>
          <TextInput
            mode='outlined' label='TODO: code' placeholder={t('login.form.mobile-placeholder')}
            value={code} onChangeText={text => setCode(text)} style={{ flexGrow: 1 }}
          />
          <IconButton
            icon='arrow-right-circle-outline' size={32} style={{ paddingTop: 8, marginRight: -8 }}
            onPress={() => { console.log('>>> CLICK 2!') }}
          />
        </View> */}
      </View>}
      {onbState !== OnboardingState.REG_COMPLETED && <View
        style={{ width: '100%', marginTop: scale(64), marginBottom: scale(48), backgroundColor: 'transparent' }}
      />}
      <Button icon='logout' mode='outlined' onPress={signOut}>
        {t('shorts.logout')}
      </Button>
      {/* {onbState === OnboardingState.COMPLETED && <Button
        icon='note-edit-outline' mode='outlined' style={{ marginTop: 40 }} onPress={() => navigation.push('PatientSearch')}
      >
        search patient
      </Button>} */}
      {showDev && <DebugPanel navigation={navigation} mt={40} />}
      <View style={{ height: '10vh'}} />
    </View>
  </Layout>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'rgb(229, 229, 229)',
  },
  title: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
