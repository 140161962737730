import { ReactNode } from 'react';
import { View } from 'react-native';
import { Avatar, IconButton, Card, Title, Paragraph } from 'react-native-paper';

export interface FFLayoutProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  content?: ReactNode | ReactNode[];
  action?: ReactNode;
  onBack?: () => void;
}

export function FFLayout(props: FFLayoutProps) {
  return <View style={{ width: '100%', padding: 6 }}>
    <Card style={{ width: '100%' }}>
      <Card.Title
        title={props.title} subtitle={props.subTitle}
        left={cprops => <Avatar.Icon {...cprops} icon={props.icon} />}
        right={props.onBack ? cprops => <IconButton {...cprops} icon='arrow-left' onPress={props.onBack} /> : undefined}
      />
      <Card.Content>
        <Title>{props.question}</Title>
        {props.extra && <Paragraph>{props.extra}</Paragraph>}
        {props.content}
      </Card.Content>
      {/* <Card.Cover source={{ uri: 'https://picsum.photos/700' }} /> */}
      {props.action}
    </Card>
  </View>
}
