import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

function Dots({ count }: { count: number }) {
  return <Box display='flex' justifyContent='flex-end'>
    {/* {Array.from(Array(count).keys()).map((_, i) => <CircleIcon key={'dot'+i} sx={{ fontSize: 32, mt: 0.6, ml: -2.5, color: '#919191', border: '1px solid white' }} />)} */}
    {Array.from(Array(count).keys()).map((_, i) => <Box key={'dot'+i} sx={{ width: 32, height: 32, ml: -2, backgroundColor: '#919191', border: '2px solid #ededed', borderRadius: '50%' }} />)}
  </Box>
}

//
// ===
//

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#ededed',
}));

const StyledRTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
}));

function createData(
  id: string,
  name: string,
  by: string,
  size: number,
  date: string,
  percent: number,
) {
  return { id, name, by, size, date, percent };
}

const rows = [
  createData('#135430', 'Juliette Lévesque', 'Loup Marchand', 2, '10/08/21', 100),
  createData('#135430', 'Rolande Traver', 'Anaïs Salmon', 3, '02/08/21', 25),
  createData('#135430', 'Ferrand Firmin', 'Isabelle Leclerc', 1, '24/07/21', 92),
  createData('#135430', 'Lucien Perrot', 'Tristand Herbert', 2, '13/07/21', 50),
  createData('#135430', 'Florence Salomon', 'Noële Gage', 3, '06/07/21', 10),
  createData('#135430', 'Philippine Fortier', 'Fernande Dennel', 1, '18/06/21', 20),
];

export default function ClientList() {
  const navigate = useNavigate();

  return <Box style={{ marginTop: 30 }}>
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Clients actifs</Typography>
      {/* <Typography variant='caption' mt={1.4}>Nouveau client</Typography>
      <IconButton onClick={() => { /* * / }}>
        <AddCircleIcon />
      </IconButton> */}
    </Box>
    <Box height={10} />
    <TableContainer component={Box}>
      <Table size='small' style={{ borderCollapse: 'separate', borderSpacing: '0 1em' }}>
        <TableBody>
          {rows.map((row) => <StyledTableRow key={row.name} onClick={() => navigate('/insurer/clientinfo/135430')}>
            <StyledTableCell width={80} align='center'>{row.id}</StyledTableCell>
            <StyledTableCell scope='row'>{row.name}</StyledTableCell>
            <StyledTableCell width={180} align='right'>by {row.by}</StyledTableCell>
            <StyledTableCell width={120} align='right'><Dots count={row.size} /></StyledTableCell>
            <StyledTableCell width={100} align='center'>{row.date}</StyledTableCell>
            <StyledRTableCell><BorderLinearProgress variant='determinate' value={row.percent} /></StyledRTableCell>
          </StyledTableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}
