import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import Typography from '@mui/material/Typography';
//
import { Locale } from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER

export interface LocaleLabelProps {
  locale?: Locale;
}

export function LocaleLabel(props: LocaleLabelProps) {
  return <div style={{ display: 'flex', lineHeight: '0px' }}>
    <LanguageIcon style={{ width: 16, marginRight: 6, paddingTop: 8 }} />
    <Typography variant='subtitle1' color='textSecondary'>{props.locale?.substring(0, 2) || 'n/a'}</Typography>
  </div>
}
