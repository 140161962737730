import { MouseEventHandler } from 'react';
import SvgIcon from '@mui/material/Icon';

export interface IconType {
  icon: React.ElementType;
  size: number | string;
  where: 'C' | 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW';
  color: string;
  extra?: React.CSSProperties;
  hOffset?: number | string;
  wOffset?: number | string;
}

export interface ComposedIconProps {
  size: number;
  icons: IconType[];
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export function ComposedIcon(props: ComposedIconProps) {
  const centeredOffsets = { 'N': '50%', 'E': undefined, 'S': undefined, 'W': '50%' }
  function test(mask: string, corner: 'N' | 'E' | 'S' | 'W', offset?: number | string) {
    if (mask === 'C') return centeredOffsets[corner];
    if (mask.includes(corner)) return offset || '0%';
    return undefined;
  }

  const first = props.icons.length > 0 ? props.icons[0] : undefined;
  if (!first) return null;
  return <div
    style={{
      position: 'relative',
      cursor: props.onClick != null ? 'pointer' : 'default',
    }}
    onClick={props.onClick}
  >
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '0px' }}>
      <SvgIcon
        component={first.icon}
        style={{
          fontSize: first.size,
          // width: '1.25em',
          color: first.color,
          ...first.extra,
        }} />
    </div>
    {props.icons.length > 0 && props.icons.slice(1).map((i, key) => <div
      key={'icon_' + key}
      style={{
        lineHeight: '0px',
        position: 'absolute',
        textShadow: '0.75px 0px 0.5px #FFF, 0px 0.75px 0.5px #FFF, -0.75px 0px 0.5px #FFF, 0px -0.75px 0.5px #FFF',
        top: test(i.where, 'N', i.hOffset),
        left: test(i.where, 'W', i.wOffset),
        bottom: test(i.where, 'S', i.hOffset),
        right: test(i.where, 'E', i.wOffset),
        transform: i.where === 'C' ? `translate(-50%, -50%) ${i.wOffset ? ` translate(${i.wOffset}, 0)` : ''} ${i.hOffset ? ` translate(0, ${i.hOffset})` : ''}` : undefined,
        fontSize: i.size,
        color: i.color,
      }}>
      <SvgIcon
        component={i.icon}
        style={{
          padding: '2px',
          ...i.extra,
          fontSize: i.size,
        }}
      />
    </div>)}
  </div>
}
