import { ReactElement, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
// import { useTranslation } from 'react-i18next';
//
import {
  FormEngine,
  // forms
  searchPatient, searchPatientUI, searchPatientData, PatientDetail,
} from '../../..';
import { EmrClient } from '@eksoh/flo/ui'; // TODO @fp: that a big NO NO !!!

export interface PatientSearchUIProps {
  beService: EmrClient;
  onClick: (username: string, data: PatientDetail) => void;
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function PatientSearchUI({ beService, onClick, onWaiting }: PatientSearchUIProps) {
  const [loading, setLoading] = useState(false);
  const [spData, setSpData] = useState(searchPatientData);

  useEffect(() => {
    listPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log('>>> PATIENT SELECTED ROL:', spData.curSelection, spData.options.length, spData.patients.length)
    if (spData.curSelection != null && spData.curSelection < spData.options.length) {
      // navigate(`/nurse/emr/${spData.options[spData.curSelection].username}`);
      onClick(spData.options[spData.curSelection].username, spData.options[spData.curSelection]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spData.curSelection]);

  useEffect(() => {
    // console.log('>>> PATIENT SELECTED AC:', spData.acSelection)
    if (spData.acSelection != null && spData.acSelection.username) {
      // navigate(`/nurse/emr/${spData.acSelection.username}`);
      onClick(spData.acSelection.username, spData.acSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spData.acSelection]);

  useEffect(() => {
    if (spData.search != null) {
      // console.log('>>> PATIENT SEARCH:', spData.search)
    }
  }, [spData.search]);

  async function listPatients() {
    setLoading(true);
    try {
      const result = await beService.listPatients();
      // console.log('>>> PATIENTS:', result)
      setSpData({ ...spData, options: result, patients: result }); // setSpData(result as any);
    }
    catch (error) {
      console.log('>>> listPatients error:', error);
      setSpData({ ...spData, options: [], patients: [] });
    }
    setLoading(false);
  }

  return <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2  }}>
      <Typography variant='h6'>FIND PATIENTS</Typography>
    </Box>
    {onWaiting(loading)}
    {!loading && <FormEngine
      form={{ schema: searchPatient, ui: searchPatientUI, data: spData }} onChange={setSpData}
    />}
  </Box>
}

//
// Modal
//

export interface PatientSearchModalProps extends PatientSearchUIProps {
  open: boolean;
}

export function PatientSearchModal(props: PatientSearchModalProps) {
  const { open, ...rest } = props;
  // const [t] = useTranslation();
  return <Dialog aria-labelledby='simple-dialog-title' open={open} maxWidth='lg' fullWidth={true}>
    <DialogTitle id='simple-dialog-title'>{/* t('tlmd.fe.select-patients') */ 'select client'}</DialogTitle>
    <Box sx={{ m: 2 }}><PatientSearchUI {...rest} /></Box>
  </Dialog>
}
