import Box from '@mui/material/Box';
//
// import { MainLayout } from '../shared/layout';
import AssurerList from '../shared/cmps/assurerlist';

export interface CFormsInfoProps {
  name: string;
  logoPath: string;
}

// export function CFormsInfo({ name, logoPath }: CFormsInfoProps) {
//   return <MainLayout name={name} logoPath={logoPath}>
//     <Box display='flex'>
//       <AssurerList />
//     </Box>
//   </MainLayout>
// }

export function CFormsInfo({ name, logoPath }: CFormsInfoProps) {
  return <Box display='flex'>
    <AssurerList />
  </Box>
}
