import { CSSProperties } from 'react';
import Paper from '@mui/material/Paper';
import Table, { TableProps } from '@mui/material/Table';
import TableBody, { TableBodyProps } from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead, { TableHeadProps } from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
//
import { checkIfArrayIsAOB, checkIfObjectIsOOB, getFirstEle, getType, loopObject } from './util';

import ValueViewer from './valueviewer';

export interface JSONViewerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any; // PropTypes.any.isRequired,
  tableProps?: TableProps; // PropTypes.object,
  trProps?: TableRowProps; // PropTypes.object,
  tdProps?: TableCellProps; // PropTypes.object,
  thProps?: TableHeadProps; // PropTypes.object,
  tableBodyProps?: TableBodyProps; // PropTypes.object,
  theadProps?: TableHeadProps; // PropTypes.object,
}

export default function JSONViewer({
  json, tableProps, trProps, tdProps, thProps, tableBodyProps, theadProps,
}: JSONViewerProps) {
  const styles = {
    td: {
      border: '1px solid #cccccc',
      textAlign: 'left',
      margin: 0,
      padding: '6px 13px',
    } as CSSProperties,
    th: {
      border: '1px solid #cccccc',
      textAlign: 'left',
      margin: 0,
      padding: '6px 13px',
      fontWeight: 'bold',
    } as CSSProperties,
  };

  function renderHeaderByKeys(keys: string[], addExtra?: string) {
    return <TableHead {...theadProps}>
      <TableRow {...trProps}>
        {addExtra === 'addExtra' && <TableHead {...thProps} style={styles.td}>
          <span style={{ color: 'rgb(111, 11, 11)' }} />
        </TableHead>}
        {keys.map((key, i) => {
          return (
            <TableCell {...tdProps} key={i} style={styles.td}>
              <span style={{ color: 'rgb(111, 11, 11)' }}>{key}</span>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function objToTable(obj: any) {
    if (JSON.stringify(obj) === '{}') return 'empty object';
    return <TableContainer component={Paper}>
      <Table {...tableProps}>
        {renderHeaderByKeys(Object.keys(obj))}
        <TableBody>
          <TableRow {...trProps}>
            {loopObject(obj, (v, key) => renderTd(v, key))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function arrayToTable(obj: any) {
    if (getType(obj) === 'Array' && obj.length === 0) return 'empty array';
    return <TableContainer component={Paper}>
      <Table {...tableProps}>
        <TableBody>
          {loopObject(obj, (v, key) => {
            return (
              <TableRow key={key} {...trProps}>
                <TableCell {...tdProps} style={styles.td}>{`${key}`}</TableCell>
                {renderTd(v, key)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  }

  function oobToTable(aob: unknown) {
    return <TableContainer component={Paper}>
      <Table {...tableProps}>
        {renderHeaderByKeys(Object.keys(getFirstEle(aob)), 'addExtra')}
        <TableBody>
          {loopObject(aob, (row, j) => <TableRow {...trProps} key={j}>
            <TableCell {...tdProps} style={styles.td}>
              <ValueViewer value={j} />
            </TableCell>
            {loopObject(getFirstEle(aob), (val, key) => renderTd(row[key], key))}
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  }

  function renderTd(guess: unknown, index: string | number) {
    return <TableCell {...tdProps} key={index} style={styles.td}>
      {decideAndRender(guess)}
    </TableCell>
  }

  function decideAndRender(guess: unknown) {
    if (getType(guess) === 'Array') {
      if (checkIfArrayIsAOB(guess)) return aobToTable(guess);
      return arrayToTable(guess);
    }
    if (getType(guess) === 'Object') {
      if (checkIfObjectIsOOB(guess)) return oobToTable(guess);
      return objToTable(guess);
    }
    return <ValueViewer value={guess} />;
  }

  function aobToTable(aob: unknown) {
    return <TableContainer component={Paper}>
      <Table {...tableProps}>
        {renderHeaderByKeys(Object.keys(getFirstEle(aob)))}
        <TableBody {...tableBodyProps}>
          {loopObject(aob, (row, j) => <TableRow {...trProps} key={j}>
            {loopObject(getFirstEle(aob), (val, key) => renderTd(row[key], key))}
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  }

  return <div>{decideAndRender(json)}</div>;
}