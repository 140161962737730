/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  and,
  ArrayLayoutProps,
  composePaths,
  computeLabel,
  findUISchema,
  isObjectArray,
  RankedTester,
  rankWith,
  uiTypeIs,
  Paths,
  Resolve,
  JsonSchema,
  getData,
  update,
  ControlElement,
} from '@jsonforms/core';
import {
  withJsonFormsArrayLayoutProps,
  JsonFormsStateContext,
  useJsonForms,
  JsonFormsDispatch,
} from '@jsonforms/react';
import { ReadonlyArrayLayoutToolbar } from './arraytoolbar';
import merge from 'lodash/merge';
import map from 'lodash/map';
import range from 'lodash/range';
// import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none'
});

const NoBorderTableCell = ({ children, ...otherProps }: any) => (
  <StyledTableCell {...otherProps}>
    {children}
  </StyledTableCell>
);

export interface EmptyTableProps {
  numColumns: number;
}

const EmptyTable = ({ numColumns }: EmptyTableProps) => (
  <TableRow>
    <NoBorderTableCell colSpan={numColumns}>
      <Typography align='center'>No data</Typography>
    </NoBorderTableCell>
  </TableRow>
);

export const readonlyArrayRendererTester: RankedTester = rankWith(
  4,
  and(uiTypeIs('ReadonlyArray'), isObjectArray)
);

function getLabelSubLbl(ctx: JsonFormsStateContext, path: string, uischema: ControlElement) {
  const childPath = path;
  const childData = Resolve.data(getData({ jsonforms: { ...ctx } }), childPath);
  if (childData == null) return '';
  const hasProps = Object.keys(childData).length > 0;
  if (uischema.options?.labels == null || !hasProps) return hasProps ? Object.values(childData)[0] : '';
  if (Array.isArray(uischema.options.labels)) {
    return uischema.options.labels.map(l => childData[l] ?? '').join(', ');
  }
  else if (typeof uischema.options.labels === 'string') {
    return childData[uischema.options.labels] ?? '';
  }
  else return '';
}

function ReadonlyArrayRenderer({
  uischemas,
  schema,
  uischema,
  path,
  errors,
  visible,
  label,
  required,
  data,
  renderers,
  cells,
  config,
}: ArrayLayoutProps) {
  const ctx = useJsonForms();

  const [selectedIndex, setSelectedIndex] = useState<number>();
  const xs = useMediaQuery('(max-width:600px)');
  // const sm = useMediaQuery('(min-width:600px)');

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    if (ctx?.dispatch == null) return;
    ctx.dispatch(update('curSelection', () => { return index; }));
  };

  const foundUISchema = useMemo(
    () => findUISchema(
      uischemas || [],
      schema,
      uischema.scope,
      path,
      undefined,
      uischema,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uischemas, schema, uischema.scope, path, uischema],
  );
  const schemaHeaders = useMemo(
    () => {
      if (schema?.properties != null) {
        return Object.keys(schema.properties);
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uischemas, schema, uischema.scope, path, uischema],
  );
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  function getCellData(ctx: JsonFormsStateContext, path: string, index: number, propName: string, schema: JsonSchema) {
    const childPath = composePaths(path, `${propName}`);
    const childData = Resolve.data(getData({ jsonforms: { ...ctx } }), childPath);
    return childData;
  }

  if (xs) {
    return <Box display='flex' flexDirection='column'>
      <Box pl={2}>
        <ReadonlyArrayLayoutToolbar
          label={computeLabel(
            label,
            required || false,
            appliedUiSchemaOptions.hideRequiredAsterisk,
          )}
          errors={errors}
          path=''
          addItem={(path: string, data: any) => () => { /* */ }}
          createDefault={() => { /* */ }}
        />
      </Box>
      {data ? map(range(data), index => {
        const selected = selectedIndex === index;
        const childPath = Paths.compose(path, `${index}`);

        return <Accordion key={index} expanded={selected} onChange={() => setSelectedIndex(selectedIndex === index ? undefined : index)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display='flex' alignItems='center' onClick={() => handleListItemClick(index)} width='100%'>
              <Avatar aria-label='Index'>{index + 1}</Avatar>
              <Typography variant={selected ? 'button' : 'caption'} sx={{ width: '100%', ml: 1 }}>{getLabelSubLbl(ctx, childPath, uischema)}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {selectedIndex !== undefined && <JsonFormsDispatch
              renderers={renderers}
              cells={cells}
              visible={visible}
              schema={schema}
              uischema={foundUISchema}
              path={composePaths(path, `${selectedIndex}`)}
            />}
          </AccordionDetails>
        </Accordion>
      }) : <Box sx={{ border: 1, borderColor: '#999', borderRadius: 1, p: 1 }}>
        <Typography variant='h6' sx={{ width: '100%', textAlign: 'center' }}>no data</Typography>
      </Box>}
    </Box>
  }

  return <Box display='flex' flexDirection='column'>
    <Box pl={2}>
      <ReadonlyArrayLayoutToolbar
        label={computeLabel(
          label,
          required || false,
          appliedUiSchemaOptions.hideRequiredAsterisk,
        )}
        errors={errors}
        path=''
        addItem={(path: string, data: any) => () => { /* */ }}
        createDefault={() => { /* */ }}
      />
    </Box>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {schemaHeaders.map((lbl, idx) => <TableCell key={lbl+idx}>
              {lbl}
            </TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
        {data ? map(range(data), index => {
          const childPath = Paths.compose(path, `${index}`);

          return <TableRow
            key={childPath} hover selected={index === selectedIndex}
            onClick={() => handleListItemClick(index)}
          >
            {schemaHeaders.map((name, idx) => {
            return <TableCell key={name+idx}> {/* onClick={() => handleListItemClick(index)}> */}
              {getCellData(ctx, childPath, index, name, schema)}
            </TableCell>})}
          </TableRow>
        }) : <EmptyTable numColumns={schemaHeaders.length} />}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

export default withJsonFormsArrayLayoutProps(ReadonlyArrayRenderer);
