import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Rating from '@mui/material/Rating';
import i18n from 'i18next';
//
import { OfferRange, getTimeFromTo } from '@eksoh/shared/ui';

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export interface TimeofferInfoProps {
  offerRange: OfferRange;
}

export function TimeofferInfo({ offerRange }: TimeofferInfoProps) {
  const [expanded, setExpanded] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [desc, setDesc] = useState('');
  const [fakeProfile] = useState(Math.floor((Math.random()*3)+1));
  const [fakeRating2] = useState(Math.floor(Math.random()*8));
  const [fakeRating, setFakeRating] = useState<number>();

  useEffect(() => { if (offerRange.startDate != null) setStartDate(new Date(offerRange.startDate)) }, [offerRange]);
  useEffect(() => { if (offerRange.endDate != null) setEndDate(new Date(offerRange.endDate)) }, [offerRange]);
  useEffect(() => setDesc(getTimeFromTo(startDate, endDate)), [startDate, endDate]);

  useEffect(() => { if (fakeRating2 < 6) setFakeRating(fakeRating2) }, [fakeRating2]);
  // useEffect(() => { console.log('>>> LANG:', i18n.language) }, [i18n.language]);
  // useEffect(() => { console.log('>>> TOUI:', JSON.stringify(toui, null, 2)) }, [toui]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return <Card sx={{ width: '100%', m: 1 }}>
    <CardHeader
      avatar={<ScheduleIcon />}
      action={<ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label='show more'
      >
        <ExpandMoreIcon />
      </ExpandMore>}
      title={startDate.toLocaleDateString(i18n.language.replace('_', '-'), dateOptions)}
      subheader={desc}
    />
    <Collapse in={expanded} timeout='auto' unmountOnExit>
      <CardMedia
        component='img'
        height='194'
        image={`https://material-ui.com/static/images/avatar/${fakeProfile}.jpg`}
        alt={desc}
      />
      <CardContent>
        <Rating name='satisfaction' value={fakeRating} readOnly />
        {fakeRating == null && <Typography paragraph>(not rated yet)</Typography>}
        <Typography>
          * has a car
        </Typography>
        <Typography>
          * has all proper equipement
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', mb: 1 }}>
        <Button variant='outlined' size='small'>SELECT</Button>
      </CardActions>
    </Collapse>
  </Card>
}

//
// ===
//

export interface MiniTimeofferInfoProps extends TimeofferInfoProps {
  color?: string;
  onClick: (offerRange: OfferRange) => void;
}

export function MiniTimeofferInfo({ color, offerRange, onClick }: MiniTimeofferInfoProps) {
  const [expanded, setExpanded] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [desc, setDesc] = useState('');
  const [fakeProfile] = useState(Math.floor((Math.random()*3)+1));
  const [fakeRating2] = useState(Math.floor(Math.random()*8));
  const [fakeRating, setFakeRating] = useState<number>();

  useEffect(() => { if (offerRange.startDate != null) setStartDate(new Date(offerRange.startDate)) }, [offerRange]);
  useEffect(() => { if (offerRange.endDate != null) setEndDate(new Date(offerRange.endDate)) }, [offerRange]);
  useEffect(() => setDesc(getTimeFromTo(startDate, endDate)), [startDate, endDate]);

  useEffect(() => { if (fakeRating2 < 6) setFakeRating(fakeRating2) }, [fakeRating2]);
  // useEffect(() => { console.log('>>> LANG:', i18n.language) }, [i18n.language]);
  // useEffect(() => { console.log('>>> TOUI:', JSON.stringify(toui, null, 2)) }, [toui]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return <Button variant='outlined' onClick={() => onClick(offerRange)} size='small' sx={{ mb: 0.5, color }}>
    {desc}
  </Button>

  // return <Card sx={{ width: '100%', m: 1 }}>
  //   <CardHeader
  //     avatar={<ScheduleIcon />}
  //     action={<ExpandMore
  //       expand={expanded}
  //       onClick={handleExpandClick}
  //       aria-expanded={expanded}
  //       aria-label='show more'
  //     >
  //       <ExpandMoreIcon />
  //     </ExpandMore>}
  //     title={toui.start.toLocaleDateString(i18n.language.replace('_', '-'), dateOptions)}
  //     subheader={toui.desc}
  //   />
  //   <Collapse in={expanded} timeout='auto' unmountOnExit>
  //     <CardMedia
  //       component='img'
  //       height='194'
  //       image={`https://material-ui.com/static/images/avatar/${fakeProfile}.jpg`}
  //       alt={toui.desc}
  //     />
  //     <CardContent>
  //       <Rating name='satisfaction' value={fakeRating} readOnly />
  //       {fakeRating == null && <Typography paragraph>(not rated yet)</Typography>}
  //       <Typography>
  //         * has a car
  //       </Typography>
  //       <Typography>
  //         * has all proper equipement
  //       </Typography>
  //       <Typography>
  //         bla bla bla...
  //       </Typography>
  //     </CardContent>
  //     <CardActions sx={{ justifyContent: 'center', mb: 1 }}>
  //       <Button variant='outlined' size='small'>SELECT</Button>
  //     </CardActions>
  //   </Collapse>
  // </Card>
}
