import React from 'react';
import { StyleSheet, View, Modal } from 'react-native';
import { ActivityIndicator, Title } from 'react-native-paper';

// TODO FE: import the prop interface from shared ui lib
export interface WaitingProps {
  text?: string;
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

export function Waiting({ text, height, width, children }: WaitingProps) {
  return <View style={{ justifyContent: 'center', padding: 12 }}>
    {text && <Title style={{ textAlign: 'center', color: 'grey' }}>{text}</Title>}
    <ActivityIndicator />
    {children}
  </View>
}

// TODO FE: import the prop interface from shared ui lib
export interface WaitingModalProps extends WaitingProps {
  open: boolean;
  onClose?: () => void;
}

export function WaitingModal(props: WaitingModalProps) {
  const { open, onClose, ...rest } = props;
  return <Modal
    transparent={true}
    visible={open}
    onDismiss={onClose}
  >
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <Waiting {...rest} />
      </View>
    </View>
  </Modal>
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  modalContent: {
    minWidth: 300,
    minHeight: 300,
    borderRadius: 10,
    border: '2px #ccc solid',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
})
