import { TouchableOpacity } from 'react-native';
// import FontAwesome from '@expo/vector-icons/FontAwesome';
import Ionicons from '@expo/vector-icons/Ionicons';
//
import { Icon } from '../cmps/Themed';

export interface ButtonProps {
  name: keyof typeof Ionicons.glyphMap;
  onPress: () => void;
  size?: number;
  color?: string;
}

export default function IconButton({ name, onPress, size, color }: ButtonProps) {
  return <TouchableOpacity onPress={onPress}>
    <Icon name={name} size={size || 32} color={color} />
  </TouchableOpacity>
}

// <FontAwesome.Button name="facebook" backgroundColor="#3b5998" onPress={createMsg}>
//   send
// </FontAwesome.Button>
