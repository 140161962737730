import { useState, useCallback } from 'react';
import { View } from 'react-native';
import { Button, Card } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
//
import { FFLayout } from './layout';

export interface FFDateRangeProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  onSelect: (range: { startDate: Date | undefined, endDate: Date | undefined }) => void;
  onBack?: () => void;
}

export function FFDateRange(props: FFDateRangeProps) {
  const [range, setRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({ startDate: undefined, endDate: undefined });
  const [open, setOpen] = useState(true);

  const onDismiss = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirm = useCallback(
    ({ startDate, endDate }) => {
      setOpen(false);
      setRange({ startDate, endDate });
    },
    [setOpen, setRange]
  );

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<DatePickerModal
        locale='en'
        mode='range'
        visible={open}
        onDismiss={onDismiss}
        startDate={range.startDate}
        endDate={range.endDate}
        onConfirm={onConfirm}
        // validRange={{
        //   startDate: new Date(2021, 1, 2),  // optional
        //   endDate: new Date(), // optional
        //   disabledDates: [new Date()] // optional
        // }}
        // onChange={} // same props as onConfirm but triggered without confirmed by user
        // saveLabel='Save' // optional
        // uppercase={false} // optional, default is true
        // label='Select period' // optional
        // startLabel='From' // optional
        // endLabel='To' // optional
        // animationType='slide' // optional, default is slide on ios/android and none on web
      />}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => props.onSelect(range)}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
