import { ConsultationReason, SubjectiveSuggestion, ObjectiveSuggestion } from './ConsultationData';

const subSuggestions = Object.keys(SubjectiveSuggestion);
const objSuggestions = Object.keys(ObjectiveSuggestion);

export const schemaUI = {
  type: 'RespListWithDetail',
  scope: '#/properties/consultations',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'GridLayout',
          config: {
            mb: 2, xs: 12, sm: 6, md: 6, // md: 4,
          },
          elements: [
            {
              type: 'Control',
              scope: '#/properties/date',
            },
            {
              type: 'Control',
              scope: '#/properties/place',
            },
            {
              type: 'Control',
              scope: '#/properties/reason',
            },
            {
              type: 'Control',
              scope: '#/properties/otherReason',
              options: { multi: true },
              rule: {
                effect: 'SHOW',
                condition: {
                  scope: '#/properties/reason',
                  schema: { enum: [ConsultationReason.other] },
                },
              },
            },
            {
              type: 'Control',
              scope: '#/properties/subjective',
              suggestion: subSuggestions,
              options: { multi: true },
            },
            {
              type: 'Control',
              scope: '#/properties/objective',
              suggestion: objSuggestions,
              options: { multi: true },
            },
            {
              type: 'Control',
              scope: '#/properties/vitalSigns',
            },
            {
              type: 'Control',
              scope: '#/properties/labResults',
            },
            {
              type: 'Control',
              scope: '#/properties/diagnostic',
              options: { multi: true },
            },
            {
              type: 'Control',
              scope: '#/properties/treatmentPlan',
              options: { multi: true },
            },
            {
              type: 'Control',
              scope: '#/properties/prescriber',
              options: { multi: true },
            },
            {
              type: 'Control',
              scope: '#/properties/butvalid',
              rule: {
                effect: 'HIDE',
                condition: {
                  scope: '#/properties/locked',
                  schema: { const: true },
                },
              },
            },
          ],
        },
      ],
    },
  },
}
