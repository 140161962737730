import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { rankWith, uiTypeIs, RankedTester, ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
//
import { useStateRef } from '../../..';

export const rangeSliderControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  uiTypeIs('RangeSlider'),
);

function RangeSliderControl({ id, enabled, label, data, schema, handleChange, path }: ControlProps) {
  const [min, setMin, minRef] = useStateRef<number>(data?.min || schema.minimum);
  const [max, setMax, maxRef] = useStateRef<number>(data?.max || schema.maximum);

  const onLocalChange = (_ev: any, value: any) => {
    setMin(value[0]);
    setMax(value[1]);
  }

  const onChange = useCallback(() => {
    handleChange(path, { min: minRef.current, max: maxRef.current });
  }, [path, handleChange]);

  return <Box display='flex' flexDirection='column' m={2}>
    <Typography variant='h6'>{label}</Typography>
    <Slider
      style={{ marginTop: 34 }}
      min={schema.minimum}
      max={schema.maximum}
      value={[min, max]}
      onChange={onLocalChange}
      onChangeCommitted={onChange}
      id={id + '-slider'}
      disabled={!enabled}
      step={schema.multipleOf || 1}
      valueLabelDisplay='on'
    />
  </Box>
}

export default withJsonFormsControlProps(RangeSliderControl);
