import { MouseEvent, ReactElement, useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import i18n from 'i18next';
import { DateTime } from 'luxon';
//
import { toHumanTimeOffer } from '@eksoh/shared/common';
import { ListUsersQuery, UserTimeOffer, Group } from '@eksoh/flo/model'; // TODO @sb: that a big NO NO !!!
import { useLocation } from '@eksoh/shared/ui';

export type Week5MinRange = [number, number, string, string, Group[] | undefined, string, string];
type ActionTypes = Group.NURSE | Group.NURSE_IPS | undefined;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export interface TimeOffersViewProps {
  users: ListUsersQuery['listUsers'];
  usersTos: { [key: string]: UserTimeOffer[] };
  onWaiting: (waiting: boolean) => ReactElement | null;
  onClose: () => void;
}

export function TimeOffersView({ users, usersTos, onWaiting, onClose }: TimeOffersViewProps) {
  const { position } = useLocation({});
  //
  const [zones, setZones] = useState<Week5MinRange[]>([]);
  const [usersObj, setUsersObj] = useState<{ [key: string]: string }>({});
  // Argghhh!!! https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
  const mount = useRef(false);
  //
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profGroup, setProfGroup] = useState<ActionTypes>(Group.NURSE);
  const open = Boolean(anchorEl);

  const numRows = 24 * 60 / 5;

  useEffect(() => {
    const lastSun = DateTime.local().startOf('week').minus({ days: 1 }).toJSDate();
    const allTos: Week5MinRange[] = [];
    const usersWithTos: { [key: string]: string } = {};
    Object.keys(usersTos).forEach((uto, idx) => {
      const user = users.find(u => u.username === uto);
      if (user != null) usersWithTos[uto] = user.name || 'MissingName';
      usersTos[uto].forEach(to => allTos.push([
        to.start5mins, to.end5mins, uto, getUniqueColor(idx), user?.groups,
        (new Date(lastSun.getTime() + to.start5mins * 300000)).toISOString(),
        (new Date(lastSun.getTime() + to.end5mins * 300000)).toISOString(),
      ]));
    });
    // console.log('>>> ALL TOS:', allTos)
    setUsersObj(usersWithTos);
    setZones(allTos);

    mount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getUniqueColor(n: number) {
    const rgb = [0, 0, 0];
    for (let i = 0; i < 24; i++) {
      rgb[i % 3] <<= 1;
      rgb[i % 3] |= n & 0x01;
      n >>= 1;
    }
    return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '');
  }

  function detailUserTo(to: Week5MinRange) {
    const sDetail = toHumanTimeOffer(to[5], i18n.language);
    const eDetail = toHumanTimeOffer(to[6], i18n.language);
    return <>
      <Typography variant='h5' color='inherit'>{usersObj[to[2]] || to[2]}</Typography>
      <Typography color='inherit'>s5m: {to[0]}</Typography>
      <Typography color='inherit'>start: {sDetail.day}, hour: {sDetail.hour}</Typography>
      <Typography color='inherit'>e5m: {to[1]}</Typography>
      <Typography color='inherit'>end: {eDetail.day}, hour: {eDetail.hour}</Typography>
    </>
  }

  function addUserTo(idx: number) {
    const tos = zones.filter(z => idx >= z[0] && idx < z[1] && (profGroup == null || z[4]?.includes(profGroup)));
    if (tos.length === 0) return <Box width='100%' height={12} />;
    // console.log('>>> USER TOS:', tos.map(to => ({ user: to[2], color: to[3] })))
    return <Box width='100%' height={12}>
      {tos.map((to, ii) => <HtmlTooltip key={to[2] + ii} title={detailUserTo(to)} placement='right' sx={{ cursor: 'pointer' }}>
        <Box display='flex' flexGrow={1} sx={{ backgroundColor: to[3] }} width={25} height={12} />
      </HtmlTooltip>)}
    </Box>
  }

  function menuAction(cmd?: ActionTypes) {
    setProfGroup(cmd);
    setAnchorEl(null);
  }

  return <Card>
    <CardHeader
      avatar={<AccessTimeIcon sx={{ fontSize: 40 }} />}
      action={<>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <IconButton onClick={(event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </>}
      title='all time offers'
      subheader={position?.coords == null ? 'waiting for geolocation...' : `at lng: ${position?.coords?.longitude} lat: ${position?.coords?.latitude}`}
    />
    <CardContent style={{ position: 'relative', width: '100%', height: 13 * numRows + 36.5, zIndex: 1 }}>
      <Box height={36.5} width='100%' />
      {Array.from(Array(24).keys()).map((_, i) => <Box key={'bg_hour_' + i} display='flex' justifyContent='center' alignItems='center' height={156} style={{ fontSize: 120, color: '#ccc' }}>
        {('0' + i).slice(-2) + ':00'}
      </Box>)}
      <TableContainer component={Box} style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>SUN</TableCell>
              <TableCell align='center'>MON</TableCell>
              <TableCell align='center'>TUE</TableCell>
              <TableCell align='center'>WED</TableCell>
              <TableCell align='center'>THU</TableCell>
              <TableCell align='center'>FRI</TableCell>
              <TableCell align='center'>SAT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(Array(numRows).keys()).map((_, i) => <TableRow key={'five_mins_' + i}>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}>{addUserTo(              i)}</TableCell>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}>{addUserTo(    numRows + i)}</TableCell>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}>{addUserTo(2 * numRows + i)}</TableCell>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}>{addUserTo(3 * numRows + i)}</TableCell>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}>{addUserTo(4 * numRows + i)}</TableCell>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}>{addUserTo(5 * numRows + i)}</TableCell>
              <TableCell sx={{ p: 0, borderBottom: (i + 1) % 12 ? undefined : '1px solid black' }}                                  >{addUserTo(6 * numRows + i)}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
    <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant='outlined' sx={{ ml: 1 }} onClick={onClose}>
        {'close'}
      </Button>
    </CardContent>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => menuAction()}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => menuAction(Group.NURSE)}>only aux</MenuItem>
      <MenuItem onClick={() => menuAction(Group.NURSE_IPS)}>only ips</MenuItem>
      <MenuItem onClick={() => menuAction(undefined)}>all</MenuItem>
    </Menu>
  </Card>
}