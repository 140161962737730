import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#ededed',
}));

const StyledLTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
}));

const StyledRTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
}));

function createData(
  hasDot: boolean,
  id: string,
  name: string,
  date: string,
  percent: number,
) {
  return { hasDot, id, name, date, percent };
}

const rows = [
  createData(true, '#135430', 'Juliette Lévesque', '10/08/21', 100),
  createData(true, '#135430', 'Rolande Traver', '02/08/21', 100),
  createData(false, '#135430', 'Ferrand Firmin', '24/07/21', 95),
  createData(false, '#135430', 'Lucien Perrot', '13/07/21', 92),
  createData(false, '#135430', 'Florence Salomon', '06/07/21', 50),
  createData(false, '#135430', 'Philippine Fortier', '18/06/21', 50),
];

export default function ClientList() {
  const navigate = useNavigate();

  return <Box style={{ marginTop: 30 }}>
    <Box display='flex'>
      <Typography variant='body1' flexGrow={1} sx={{ mt: 1 }}>Clients actifs</Typography>
      <Typography variant='caption' mt={1.4}>Nouveau client</Typography>
      <IconButton onClick={() => { /* */ }}>
        <AddCircleIcon />
      </IconButton>
    </Box>
    <Box height={10} />
    <TableContainer component={Box}>
      <Table size='small' style={{ borderCollapse: 'separate', borderSpacing: '0 1em' }}>
        <TableBody>
          {rows.map((row) => <StyledTableRow key={row.name} onClick={() => navigate('/cms/clientinfo/135430')}>
            <StyledLTableCell width={40} align='center'>{row.hasDot && <CircleIcon sx={{ fontSize: 14, mt: 0.6 }} />}</StyledLTableCell>
            <StyledTableCell width={80} align='center'>{row.id}</StyledTableCell>
            <StyledTableCell scope='row'>{row.name}</StyledTableCell>
            <StyledTableCell width={100} align='center'>{row.date}</StyledTableCell>
            <StyledTableCell width={100} align='center'>{row.percent}%</StyledTableCell>
            <StyledRTableCell><BorderLinearProgress variant='determinate' value={row.percent} /></StyledRTableCell>
          </StyledTableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

// import React from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import CircleIcon from '@mui/icons-material/Circle';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));

// export interface ListProps {
//   gutterBottom?: boolean;
// }

// export default function List({ gutterBottom }: ListProps) {
//   return <Box display='flex' flexDirection='column' sx={{ mb: gutterBottom ? 2 : undefined}}>
//     <Typography variant='caption'>Clients actifs</Typography>
//     <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', padding: 12, width: '100%' }}>
//       <CircleIcon />
//       <Typography variant='body1'>#123123</Typography>
//       <Typography variant='body1'>John Doe</Typography>
//       <Typography variant='body1'>29/05/22</Typography>
//       <Typography variant='body1'>67%</Typography>
//       <BorderLinearProgress variant='determinate' value={67} />
//     </Paper>
//   </Box>
// }
