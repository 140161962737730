import { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//
import { clientEmrStore, eClientEmrActions } from '../../../..';

const labelsClasses = [
  'indigo',
  'gray',
  'green',
  'blue',
  'red',
  'purple',
];

const idDelEvt = 'delEvt';
const idCancel = 'idCancel';

export interface EventModalProps {
  open: boolean;
}

export default function EventModal({ open }: EventModalProps) {
  const { clientEmrState, dispatchCalEvent, setShowEventModal, setSelectedEvent } = useContext(clientEmrStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLabel, setSelectedLabel] = useState(labelsClasses[0]);

  useEffect(() => {
    if (clientEmrState.selectedEvent != null) {
      setTitle(clientEmrState.selectedEvent.title);
      setDescription(clientEmrState.selectedEvent.description);
      const lbl = labelsClasses.find(lbl => lbl === clientEmrState.selectedEvent?.label);
      if (lbl) setSelectedLabel(lbl);
    }
  }, [clientEmrState.selectedEvent])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCloseMenu = (evt: any) => {
    if (evt.target.id === idCancel) {
      done();
    }
    else if (evt.target.id === idDelEvt) {
      dispatchCalEvent({ type: eClientEmrActions.DELETE_CAL_EVT, id: clientEmrState.selectedEvent?.id });
      done();
    }
    setAnchorEl(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleSubmit(e: any) {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      label: selectedLabel,
      day: clientEmrState.daySelected?.valueOf(),
      id: clientEmrState.selectedEvent ? clientEmrState.selectedEvent.id : Date.now(),
    };

    if (clientEmrState.selectedEvent) {
      dispatchCalEvent({ type: eClientEmrActions.UPDATE_CAL_EVT, event: calendarEvent });
    }
    else {
      dispatchCalEvent({ type: eClientEmrActions.PUSH_CAL_EVT, event: calendarEvent });
    }

    done();
  }

  function done() {
    setTitle('');
    setDescription('');
    setSelectedEvent();
    setShowEventModal(false);
  }

  return <Dialog onClose={() => {/* */ }} open={open}>
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={<CalendarMonthIcon />}
        action={clientEmrState.selectedEvent?.id == null
          ? <IconButton aria-label='settings' onClick={ev => setShowEventModal(false)}>
              <CloseIcon />
            </IconButton>
          : <IconButton aria-label='settings' onClick={ev => setAnchorEl(ev.currentTarget)}>
              <MoreVertIcon />
            </IconButton>}
        title='add appointement'
        subheader={clientEmrState.daySelected?.format('dddd, MMMM DD')}
      />
      <CardContent>
        <TextField
          required fullWidth sx={{ mb: 2 }}
          label='title' value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
        />
        <TextField
          required fullWidth sx={{ mb: 2 }}
          label='description' value={description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
        />
      </CardContent>
      <CardActions disableSpacing>
        <BookmarkIcon />
        {labelsClasses.map((lblClass, i) => <IconButton key={'lbl_but' + i} onClick={() => setSelectedLabel(lblClass)}>
          <Box position='relative' style={{ width: 18, height: 26 }}>
            <CircleIcon style={{ position: 'absolute', top: 0, left: 0 }} sx={{ color: lblClass }} />
            {selectedLabel === lblClass && <CheckIcon style={{ position: 'absolute', top: 0, left: 0, zIndex: 20 }} sx={{ color: 'white' }} />}
          </Box>
        </IconButton>)}
        <Button variant='outlined' sx={{ ml: 1, width: 86 }} onClick={handleSubmit}>
          {clientEmrState.selectedEvent?.id == null ? 'add' : 'update'}
        </Button>
      </CardActions>
    </Card>
    {<Menu
      anchorEl={anchorEl}
      open={anchorEl != null}
      onClose={handleCloseMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem id={idDelEvt} onClick={handleCloseMenu}>delete</MenuItem>
      <MenuItem id={idCancel} onClick={handleCloseMenu}>cancel</MenuItem>
    </Menu>}
  </Dialog>
}
