export enum Professions {
  AthleticTrainer,
  Audiologist,
  CardiacSonographer,
  CardiovascularInvasiveSpecialist,
  CardiovascularPerfusionist,
  CentralServiceTechnician,
  ChildLifeSpecialist,
  CytogeneticTechnologist,
  Cytotechnologist,
  DiagnosticMedicalSonographer,
  Dietitian,
  EmergencyMedicineParamedic,
  GeneticCounselor,
  HealthInformationManager,
  HemodialysisTechnician,
  HistologyTechnician,
  HospitalChaplain,
  MedicalLaboratoryScientist,
  MedicalMassageTherapist,
  MedicalPhysicist,
  MedicalSocialWorker,
  MedicalSpeechLanguagePathologist,
  MolecularGeneticsTechnologist,
  NeurodiagnosticTechnologist,
  NuclearMedicineTechnologist,
  Nurse,
  NurseAnesthetist,
  NurseMidwife,
  NursePractitioner,
  OccupationalTherapist,
  Orthoptist,
  PathologistsAssistant,
  PerioperativeNurse,
  Pharmacist,
  PharmacyTechnician,
  PhlebotomyTechnician,
  PhysicalTherapist,
  PhysicianAssistant,
  PositronEmissionTomographyTechnologist,
  RadiationTherapist,
  RadiologicTechnologist,
  RecreationalTherapist,
  RespiratoryTherapist,
  SurgicalFirstAssistant,
  SurgicalTechnologist,
}