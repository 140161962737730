/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef } from 'react';
import { GraphView, INode, IEdge, IPoint, SelectionT } from 'react-digraph';
import {
  default as nodeConfig,
  EMPTY_EDGE_TYPE,
  CUSTOM_EMPTY_TYPE,
  NODE_KEY,
  POLY_TYPE,
  SPECIAL_CHILD_SUBTYPE,
  SPECIAL_EDGE_TYPE,
  SPECIAL_TYPE,
  SKINNY_TYPE
} from './config';

const sample = {
  edges: [
    {
      handleText: '5',
      source: 'start1',
      target: 'a1',
      type: SPECIAL_EDGE_TYPE
    },
    {
      handleText: '5',
      source: 'a1',
      target: 'a2',
      type: SPECIAL_EDGE_TYPE
    },
    {
      handleText: '54',
      source: 'a2',
      target: 'a4',
      type: EMPTY_EDGE_TYPE
    },
    {
      handleText: '54',
      source: 'a1',
      target: 'a3',
      type: EMPTY_EDGE_TYPE
    },
    {
      handleText: '54',
      source: 'a3',
      target: 'a4',
      type: EMPTY_EDGE_TYPE
    },
    {
      handleText: '54',
      source: 'a1',
      target: 'a5',
      type: EMPTY_EDGE_TYPE
    },
    {
      handleText: '54',
      source: 'a4',
      target: 'a1',
      type: EMPTY_EDGE_TYPE
    },
    {
      handleText: '54',
      source: 'a1',
      target: 'a6',
      type: EMPTY_EDGE_TYPE
    },
    {
      handleText: '24',
      source: 'a1',
      target: 'a7',
      type: EMPTY_EDGE_TYPE
    }
  ],
  nodes: [
    {
      id: 'start1',
      title: 'Start (0)',
      type: SPECIAL_TYPE
    },
    {
      id: 'a1',
      title: 'Node A (1)',
      type: SPECIAL_TYPE,
      x: 258.3976135253906,
      y: 331.9783248901367
    },
    {
      id: 'a2',
      subtype: SPECIAL_CHILD_SUBTYPE,
      title: 'Node B (2)',
      type: CUSTOM_EMPTY_TYPE,
      x: 593.9393920898438,
      y: 260.6060791015625
    },
    {
      id: 'a3',
      title: 'Node C (3)',
      type: CUSTOM_EMPTY_TYPE,
      x: 237.5757598876953,
      y: 61.81818389892578
    },
    {
      id: 'a4',
      title: 'Node D (4)',
      type: CUSTOM_EMPTY_TYPE,
      x: 600.5757598876953,
      y: 600.81818389892578
    },
    {
      id: 'a5',
      title: 'Node E (5)',
      type: null,
      x: 50.5757598876953,
      y: 500.81818389892578
    },
    {
      id: 'a6',
      title: 'Node E (6)',
      type: SKINNY_TYPE,
      x: 300,
      y: 600
    },
    {
      id: 'a7',
      title: 'Node F (7)',
      type: POLY_TYPE,
      x: 0,
      y: 300
    }
  ]
};

export function TimeOfferDigraph() {
  const [nodes, setNodes] = useState<INode[]>(sample.nodes as any);
  const [edges, setEdges] = useState<IEdge[]>(sample.edges);
  const [selected, setSelected] = useState<SelectionT>();
  const graphRef = useRef<any>(null);

  function getNodeIndex(searchNode: INode) {
    return nodes.findIndex(node => node[NODE_KEY] === searchNode[NODE_KEY]);
  }

  // Helper to find the index of a given edge
  function getEdgeIndex(searchEdge: IEdge) {
    return edges.findIndex(edge => edge.source === searchEdge.source && edge.target === searchEdge.target);
  }

  function addStartNode(e: any) {
    // using a new array like this creates a new memory reference
    // this will force a re-render
    setNodes([
      {
        id: Date.now(),
        title: 'Node A',
        type: SPECIAL_TYPE,
        x: e ? e.screenX : 0, // Figure out the correct coordinates to drop
        y: e ? e.screenY : 0,
      },
      ...nodes,
    ]);
  }

  // function onUndo() {
  //   // Not implemented
  //   console.warn('Undo is not currently implemented in the example.');
  //   // Normally any add, remove, or update would record the action in an array.
  //   // In order to undo it one would simply call the inverse of the action performed. For instance, if someone
  //   // called onDeleteEdge with (viewEdge, i, edges) then an undelete would be a splicing the original viewEdge
  //   // into the edges array at position i.
  // };

  // function onCopySelected() {
  //   if (state.selected.source) {
  //     console.warn('Cannot copy selected edges, try selecting a node instead.');

  //     return;
  //   }

  //   const x = state.selected.x + 10;
  //   const y = state.selected.y + 10;

  //   setState({
  //     copiedNode: { ...state.selected, x, y }
  //   });
  // };

  // function onPasteSelected() {
  //   if (!state.copiedNode) {
  //     console.warn(
  //       'No node is currently in the copy queue. Try selecting a node and copying it with Ctrl/Command-C'
  //     );
  //   }

  //   const graph = state.graph;
  //   const newNode = { ...state.copiedNode, id: Date.now() };

  //   graph.nodes = [...graph.nodes, newNode];
  //   forceUpdate();
  // };

  // function handleChangeLayoutEngineType(event: any) {
  //   setState({
  //     layoutEngineType: event.target.value
  //   });
  // };

  // function onSelectPanNode(event: any) {
  //   if (GraphView) {
  //     GraphView.panToNode(event.target.value, true);
  //   }
  // };

  /* Define custom graph editing methods here */

  return <div id='graph' style={{ height: '30rem' }}>
    <button onClick={addStartNode}>Create Node</button>
    <div draggable onDragEnd={e => addStartNode(e)}>
      create on drag
    </div>
    <GraphView
      ref={graphRef}
      readOnly={false}
      allowMultiselect={true}
      showGraphControls={true}
      gridSize={100} // '100rem'
      gridDotSize={1}
      nodeKey={NODE_KEY}
      nodes={nodes}
      edges={edges}
      selected={selected}
      nodeTypes={nodeConfig.NodeTypes}
      nodeSubtypes={nodeConfig.NodeSubtypes}
      edgeTypes={nodeConfig.NodeTypes}
      onSelect={(selected: SelectionT, event: any) => {
        if (event) {
          const { id = '' } = event.target;
          if (id.includes('text')) {
            document.getElementById(event.target.id)?.click();
          }
          setSelected(selected);
        }
      }}
      backgroundFillId='000000'
      onBackgroundClick={(x: number, y: number, event: any) => {
        console.log('>>> (not working) TimeOfferDigraph::onBackgroundClick:', x, y, event);
        setSelected(undefined);
      }}
      onCreateNode={(x: number, y: number) => {
        // This is just an example - any sort of logic
        // could be used here to determine node type
        // There is also support for subtypes. (see 'sample' above)
        // The subtype geometry will underlay the 'type' geometry for a node
        const type = Math.random() < 0.25 ? SPECIAL_TYPE : CUSTOM_EMPTY_TYPE;
    
        const viewNode = {
          id: Date.now(),
          title: 'new question',
          type,
          x,
          y,
        };
        setNodes([...nodes, viewNode]);
      }}
      onCreateEdge={(sourceViewNode: INode, targetViewNode: INode) => {
        // This is just an example - any sort of logic
        // could be used here to determine edge type
        const type = sourceViewNode.type === SPECIAL_TYPE
          ? SPECIAL_EDGE_TYPE
          : EMPTY_EDGE_TYPE;
    
        const viewEdge = {
          source: sourceViewNode[NODE_KEY],
          target: targetViewNode[NODE_KEY],
          type,
        };
    
        // Only add the edge when the source node is not the same as the target
        if (viewEdge.source !== viewEdge.target) {
          setEdges([...edges, viewEdge]);
          // setSelected(viewEdge);
        }
      }}
      onUpdateNode={(viewNode: any) => {
        const i = getNodeIndex(viewNode);
        nodes[i] = viewNode;
        setNodes(nodes);
      }}
      onSwapEdge={(sourceViewNode: INode, targetViewNode: INode, viewEdge: IEdge) => {
        const i = getEdgeIndex(viewEdge);
        const edge = edges[i];
    
        edge.source = sourceViewNode[NODE_KEY];
        edge.target = targetViewNode[NODE_KEY];
        edges[i] = edge;
        setEdges([...edges]);
        // setSelected(edge);
      }}
      onDeleteSelected={(selected: SelectionT) => {
        const eids = Array.from(selected.edges?.keys() || []);
        setEdges(edges.filter(edge => !eids.includes(edge['id'])));
    
        const nids = Array.from(selected.nodes?.keys() || []);
        setNodes(nodes.filter(node => !nids.includes(node['id'])));
      }}

      canCreateEdge={(startNode?: INode, endNode?: INode) => {
        console.log('>>> TimeOfferDigraph::canCreateEdge: // TODO:!!!');
        return true;
      }}
      canDeleteSelected={(selected: SelectionT) => {
        console.log('>>> TimeOfferDigraph::canDeleteSelected: // TODO:!!!');
        return true;
      }}
      onCopySelected={() => {
        console.log('>>> TimeOfferDigraph::onCopySelected: // TODO:!!!');
      }}
      onPasteSelected={(selected?: SelectionT | null | undefined, xyCoords?: IPoint | undefined) => {
        console.log('>>> TimeOfferDigraph::onPasteSelected: // TODO:!!!', selected, xyCoords);
      }}
      onUndo={() => {
        console.log('>>> TimeOfferDigraph::onCopySelected: // TODO:!!!');
      }}
    />
  </div>
}
