import GoogleMapReact from 'google-map-react';

export type Coords = { lat: number, lng: number };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MapObject = { map: any, maps: any };
export type Bounds = { nw: Coords, se: Coords };
export type Circle = { coords: Coords, radius: number }

export function getDistanceInRadFromCoords(coords0: Coords, coords1: Coords): number | undefined {
  // http://www.movable-type.co.uk/scripts/latlong.html
  const φ1 = coords0.lat * (Math.PI / 180);
  const φ2 = coords1.lat * (Math.PI / 180);
  const Δφ = (coords1.lat - coords0.lat) * (Math.PI / 180);
  const Δλ = (coords1.lng - coords0.lng) * (Math.PI / 180);

  const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2)) + ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));

  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRadiusInRadFromMap(map: any): number | undefined {
  const bounds = map.bounds;
  const center = map.center;
  const radius = undefined;
  if (bounds && center) {
    const lat1 = center.lat;
    const lng1 = center.lng;
    if (lat1 && lng1) {
      const lat2 = bounds.ne.lat;
      const lng2 = bounds.ne.lng;
      if (lat2 && lng2) {
        return getDistanceInRadFromCoords(center, bounds.ne);
      }
    }
  }

  return radius;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRadiusInMeterFromMap(map: any): number | undefined {
  const rad = getRadiusInRadFromMap(map);
  return rad ? 6378137 * rad : undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getCircleInRadFromMap(map: any): Circle | undefined {
  const rad = getRadiusInRadFromMap(map);
  return rad ? { coords: map.center, radius: rad } as Circle : undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function distanceInPx(coords0: Coords, coords1: Coords, map: any): number | undefined {
  if (map) {
    const p1 = map.getProjection().fromLatLngToPoint(coords0);
    const p2 = map.getProjection().fromLatLngToPoint(coords1);

    const pixelSize = Math.pow(2, -map.getZoom());

    return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
  }

  return undefined;
}

export function fitBounds(bounds: Bounds, maps: MapObject) {
  if (maps.map && maps.maps) {
    const LatLngList = [
      new maps.maps.LatLng(bounds.nw.lat, bounds.nw.lng),
      new maps.maps.LatLng(bounds.se.lat, bounds.se.lng)
    ], latlngbounds = new maps.maps.LatLngBounds();

    for (const latLng of LatLngList)
      latlngbounds.extend(latLng);

    // const center = latlngbounds.getCenter();
    // const lat = center.lat();
    // const lng = center.lng();
    // maps.map.setCenter(latlngbounds.getCenter());
    maps.map.fitBounds(latlngbounds);
  }
}

//
//
//

function rad(x: number) {
  return x * Math.PI / 180;
}

export function getDistance(p1: GeolocationPosition, p2: GeolocationPosition) {
  const p1Lat = p1.coords.latitude;
  const p1Lng = p1.coords.longitude;
  const p2Lat = p2.coords.latitude;
  const p2Lng = p2.coords.longitude;
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2Lat - p1Lat);
  const dLong = rad(p2Lng - p1Lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1Lat)) * Math.cos(rad(p2Lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
}

export function getDistanceClick(p1: GeolocationPosition, p2: GoogleMapReact.ClickEventValue) {
  const p1Lat = p1.coords.latitude;
  const p1Lng = p1.coords.longitude;
  const p2Lat = p2.lat;
  const p2Lng = p2.lng;
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2Lat - p1Lat);
  const dLong = rad(p2Lng - p1Lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1Lat)) * Math.cos(rad(p2Lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
}
