export const schemaUI = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'GridLayout',
      config: {
        mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/systolic',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/diastolic',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/pulse',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/spo2',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/respirations',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/temperature',
        },
      ],
    },

    {
      type: 'Control',
      scope: '#/properties/butvalid',
    },
  ],
}
