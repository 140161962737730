import { useContext, useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Card, IconButton, Avatar, Surface, Subheading, TextInput, Modal, Button, Banner } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import { useTranslation } from 'react-i18next';
//
import { awsPhoneNumber } from '@eksoh/shared/common';
import { authStore } from '@eksoh/shared/ui';
// import { scale, moderateScale } from '../Scaling';

export interface ResetPwdProps {
  show: boolean;
  onDone: () => void;
}

export function ResetPwd({ show, onDone }: ResetPwdProps) {
  const { authState, forgotPwd } = useContext(authStore);
  const [mobile, setMobile] = useState('');
  const [cmpError, setCmpError] = useState('');
  const [error, setError] = useState<unknown>();
  const [t] = useTranslation();

  useEffect(() => {
    setMobile(authState.user?.phoneNumber || authState.onboarding?.phone || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.needsReset) onDone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  async function sendForgot() {
    try {
      if (mobile == null) {
        setCmpError('login.messages.error.malformedPhoneNumber');
        return;
      }
      forgotPwd(awsPhoneNumber(mobile));
    }
    catch (error) {
      // console.log('>>> RESET PWD ERROR:', error);
      setError(error);
    }
  }

  return <Modal
    visible={show} onDismiss={onDone}
    style={{ shadowOpacity: 0 }}
    contentContainerStyle={{ padding: 12 }}
  >
    <Card>
      <Card.Title
        title={t('login.password.forgot')} titleNumberOfLines={2}
        left={props => <Avatar.Icon {...props} icon='account-lock' />}
        right={props => <IconButton {...props} icon='close-circle-outline' onPress={onDone} />}
      />
      <Card.Content>
        <Banner
          visible={error != null || !!cmpError}
          style={{ marginBottom: 24 }}
          actions={[
            {
              label: t('shorts.close'),
              onPress: () => { setError(undefined); setCmpError(''); },
            },
            // {
            //   label: t('generic.menu.contactUs'),
            //   onPress: () => { setError(undefined); setCmpError(''); },
            // },
          ]}
          icon={cprops => <Avatar.Icon {...cprops} icon='alert-circle' />}
        >
          {t(cmpError || 'error.unexpected')}
        </Banner>
        <Subheading style={{ marginBottom: 24 }}>{t('login.password.recupSms1')}</Subheading>
        <Surface style={styles.surface}>
          <View style={{ marginRight: 12 }}>
            <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
          </View>
          <Text style={{ color: '#A5A5A5' }}>{t('login.password.recupSms2')}</Text>
        </Surface>
        <View style={{ width: '100%', marginTop: 1, marginBottom: 16 }}>
          <TextInput
            mode='outlined' label={t('login.form.mobile')}
            // outlineColor='white' activeOutlineColor='white' theme={{ colors: inputStyle }}
            value={mobile} onChangeText={text => setMobile(text)}
            render={props => <MaskedTextInput
              value={props.value} mask='+1 (999) 999-9999' style={props.style}
              onChangeText={(t, r) => { if (props.onChangeText) props.onChangeText(r); }}
            />}
          />
        </View>
        <Button icon='email' mode='contained' onPress={() => sendForgot()} disabled={!mobile} style={{ marginBottom: 16 }}>
          {t('login.form.title_reset')}
        </Button>
      </Card.Content>
    </Card>
  </Modal>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  surface: {
    elevation: 1,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
