import { useState, useEffect } from 'react';
// https://www.winxdvd.com/answers/webm-vs-mp4.htm
import { Audio } from 'expo-av';
import { StyleSheet, View } from 'react-native';
import {
  Card, Paragraph, Title, Text, IconButton, Modal, Button, RadioButton, Avatar, Surface,
} from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';
//
import { PlayAudio } from '@eksoh/shared/ui-web';

export interface useVoiceRecordingProps {
  label?: string;
  maxRecTime?: number;
}

export default function useVoiceRecording({ label, maxRecTime }: useVoiceRecordingProps) {
  const [recFile, setRecFile] = useState<File>();
  const [recUri, setRecUri] = useState<string>();
  const [recording, setRecording] = useState<Audio.Recording>();
  const [active, setActive] = useState(false);
  const [helper, setHelper] = useState(1);
  const [count, setCount] = useState(maxRecTime || 300)
  const [t] = useTranslation();

  useEffect(() => {
    if (recording) setCount(maxRecTime || 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  useEffect(() => {
    if (count < 1) stopRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useInterval(
    () => {
      // Your custom logic here
      setCount(count - 1)
    },
    // Delay in milliseconds or null to stop it
    recording ? 1000 : null,
  )

  async function startRecording() {
    try {
      // console.log('Requesting permissions..');
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      }); 
      // console.log('Starting recording..');
      const { recording } = await Audio.Recording.createAsync(
         Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY
      );
      setRecording(recording);
      // console.log('Recording started');
    }
    catch (err) {
      // TODO: handle me!
      console.error('Failed to start recording', err);
    }
  }

  async function stopRecording() {
    if (recording != null) {
      // console.log('Stopping recording..');
      setRecording(undefined);
      await recording.stopAndUnloadAsync();
      const uri = recording.getURI();
      // console.log('Recording stopped and stored at', uri);
      if (uri != null) {
        setRecUri(uri);
        try {
          const fetchResponse = await fetch(uri);
          // console.log('fetchResponse', fetchResponse);
          const blob = await fetchResponse.blob();
          // console.log('blob', blob);
          const file = new File([blob], 'voice_recording.webm');
          // console.log('file', file);
          setRecFile(file);
        }
        catch (error) {
          // TODO: handle me!
          console.log('fetch recording failed:', error);
        }
      }
    }
    setHelper(1);
    setActive(false);
}

  function renderVoiceRec(iconColor?: string) {
    return <View style={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton
        icon={recording ? 'stop' : 'microphone'} size={40} color={iconColor}
        onPress={() => { if (recording != null) stopRecording(); else startRecording(); }}
      />
      <Title style={{ marginLeft: 40, marginTop: 20 }}>{t(label || 'nurse.uploadVoiceRec')}</Title>
    </View>
  }

  function renderVoiceRecAudioCv(iconColor?: string) {
    return <View style={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton
        icon='microphone' size={40} color={iconColor}
        onPress={() => setActive(true)}
      />
      <Title style={{ marginLeft: 20, marginTop: 20 }}>{t(label || 'nurse.uploadVoiceRec')}</Title>
      {recUri != null && <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
        <PlayAudio audioUrl={recUri} />
      </View>}
    </View>
  }

  function renderAudioCvModal() {
    return <Modal visible={active} contentContainerStyle={{ backgroundColor: 'white', padding: 20 }}>
      <Card style={{ width: '100%' }}>
        <Card.Title
          title={t('nurse.audioCv.helper')} titleNumberOfLines={2}
          subtitle={t('nurse.audioCv.limit')}
          // left={props => <Avatar.Icon {...props} size={12} icon='help' />}
          // right={props => <Avatar.Icon {...props} icon='close' />}
          right={props => <IconButton {...props} icon='close' onPress={() => stopRecording()} />}
        />
        <Card.Content>
          <Surface style={styles.surface}>
            <View style={{ marginRight: 6 }}>
              <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
            </View>
            <Text style={{ color: '#A5A5A5' }}>{t('nurse.audioCv.note')}</Text>
          </Surface>
          <View style={{ flexDirection: 'row', width: '100%', marginTop: 8 }}>
            <Paragraph style={{ minHeight: 75, marginTop: 24 }}>{t(`nurse.audioCv.q${helper}`)}</Paragraph>
          </View>
        </Card.Content>
        <Card.Actions style={{ justifyContent: 'space-between' }}>
          <View style={{ width: 75, justifyContent: 'center' }}>
            <Text style={{ textAlign: 'center' }}>{count} {t('shorts.secDot')}</Text>
            <Text style={{ textAlign: 'center' }}>{t('shorts.left')}</Text>
          </View>
          <Button
            icon={helper < 2 && recording == null ? 'record' : helper > 8 ? 'stop' : 'chevron-right'} mode='outlined'
            onPress={() => helper < 2 && recording == null ? startRecording() : helper > 8 ? stopRecording() : setHelper(helper + 1)}
          >
            {helper < 2 && recording == null ? t('nurse.startRec') : helper > 8 ? t('nurse.stopRec') : t('nurse.nextQ')}
          </Button>
          <View style={{ width: 75, alignItems: 'center' }}>
            <RadioButton
              value=''
              status={ recording != null ? 'checked' : 'unchecked' }
              onPress={() => { /* */ }}
            />
            <Text style={{ textAlign: 'center', marginTop: -8 }}>rec</Text>
          </View>
        </Card.Actions>
      </Card>
    </Modal>
  }

  return { recFile, recUri, startRecording, stopRecording, renderVoiceRec, renderVoiceRecAudioCv, renderAudioCvModal };
}

const styles = StyleSheet.create({
  surface: {
    elevation: 3,
    marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
