/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { Text, TouchableOpacity, TextStyle } from 'react-native';
import { scale, moderateScale } from '../Scaling';

export interface ButtonProps {
  onPress: () => void;
  style?: any;
  children?: ReactNode | ReactNode[];
}

export default function Button({ onPress, children, style }: ButtonProps) {
  const { buttonStyle, textStyle } = styles;

  return (
    <TouchableOpacity onPress={onPress} style={{ ...buttonStyle, ...style }}>
      <Text style={textStyle}>{children}</Text>
    </TouchableOpacity>
  );
}

const styles = {
  textStyle: {
    padding: scale(12),
    color: '#ffffff',
    fontSize: moderateScale(24),
    // fontWeight: 'normal',
    textAlign: 'center',
    textShadowColor: '#000000',
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 0,
  } as TextStyle,
  buttonStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: moderateScale(60),
    alignSelf: 'stretch',
    minHeight: moderateScale(32),
    margin: scale(10),
    backgroundColor: 'rgba(64, 64, 255, 0.8)',
    borderRadius: 8,
  },
};
