// import styles from './calendar.module.css';

import { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
//
import { clientEmrStore } from '../../..';
import { getMonth } from './util';
import CalendarHeader from './components/CalendarHeader';
import Sidebar from './components/Sidebar';
import Month from './components/Month';
import EventModal from './components/EventModal';

export interface CalendarProps {
  fake?: string
}

export function Calendar(props: CalendarProps) {
  const { clientEmrState, initCalendar } = useContext(clientEmrStore);
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const lg = useMediaQuery('(max-width:900px)');
  // const sm = useMediaQuery('(min-width:600px)');

  // https://www.youtube.com/watch?v=RYCGLSooIAo
  // https://github.com/hughmp/google-calendar-lambda
  // https://github.com/JFenstermacher/aws-calendar-api

  useEffect(() => {
    setCurrentMonth(getMonth(clientEmrState.monthIndex));
    initCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmrState.monthIndex]);

  useEffect(() => {
    if (clientEmrState.daySelected != null) {
      setCurrentMonth(getMonth(clientEmrState.daySelected.month()));
      initCalendar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmrState.daySelected]);

  return <>
    <Box display='flex' flexDirection='column' width='100%' height='80vh'>
      <CalendarHeader />
      <Box display='flex' flex={1}>
        {!lg && <Sidebar />}
        <Month month={currenMonth} />
      </Box>
    </Box>
    <EventModal open={clientEmrState.showEventModal} />
  </>
}
