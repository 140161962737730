import { Address } from '../..';

export interface OnboardingData {
  // registration
  email?: string;
  phone?: string;
  started?: OnboardingStarted;
  phoneValid?: OnboardingPhoneValid;
  personelInfo?: OnboardingPersonelInfo;
  professionalInfo?: OnboardingProfessionalInfo;
  calendar?: OnboardingCalendar;
  missionType?: OnboardingMissionType;
  missionDistance?: OnboardingMissionDistance;
  displacementMeans?: OnboardingDisplacementMeans;
  regDocs?: OnboardingRegDocs;
  // onboarding
  fullAddress?: Address;
  postInterview?: PostInterviewData;
  // active nurse
  employeeInfo?: EmployeeInfoData;
  videos?: VideosData;
}

export interface OnboardingStarted {
  agreed: boolean;
  tosDate: number;
  ips?: string[];
  identityId: string;
  username: string;
  // email: string;
}

export interface OnboardingPhoneValid {
  // phone: string;
  codePhoneSent?: Date;
}

export enum NurseTitles {
  AUX = 'AUX',
  REG = 'REG',
  CLI = 'CLI',
  PRACT_IPS = 'PRACT_IPS'
}

export type NurseTitlesLocaleMap = {
  [key in NurseTitles]: string;
}

export const kLocaleNurseTypes: NurseTitlesLocaleMap = {
  [NurseTitles.AUX]: 'nurse.type1',           // dep
  [NurseTitles.REG]: 'nurse.type2',           // cegep
  [NurseTitles.CLI]: 'nurse.type3',           // bac.
  [NurseTitles.PRACT_IPS]: 'nurse.type4',         // IPS, maitrise
};

export enum LangLevels { UNSET = -1, NONE, BEGIN, INTER, ADV, MOTHER }
export type LangLevelsUI = Exclude<LangLevels, LangLevels.UNSET>;

export type LangLevelsLocaleMap = {
  [key in LangLevelsUI]: string;
}

export const kLocaleLangLevels: LangLevelsLocaleMap = {
  [LangLevels.NONE]: 'nurse.langLevel.none',
  [LangLevels.BEGIN]: 'nurse.langLevel.begin',
  [LangLevels.INTER]: 'nurse.langLevel.int',
  [LangLevels.ADV]: 'nurse.langLevel.adv',
  [LangLevels.MOTHER]: 'nurse.langLevel.mother',
};

export interface OnboardingPersonelInfo {
  nurseType: NurseTitles;
  givenName: string;
  middleName: string;
  familyName: string;
  french: LangLevels;
  english: LangLevels;
  extraLangs?: string[];
}

export interface OnboardingProfessionalInfo {
  OIIQ?: string;
}

export interface TimeZones {
  WeekDays: boolean;
  WeekEnds: boolean;
  Morning: boolean;
  Afternoon: boolean;
  Evening: boolean;
}

export interface OnboardingCalendar {
  range: TimeZones;
  hours: number;
}

export enum Mission { TELEMED, PRESENT, WHATEVER }

export interface OnboardingMissionType {
  misType: Mission;
}

export interface OnboardingMissionDistance {
  postal: string;
  dist: number;
  geoloc?: GeolocationPosition;
}

// export enum Transports { FOOT, BICYCLE, CAR, PUB_TRANS, TROTINETTE, TRAIN, PLANE, HORSE, CAMEL, CART_WHEELS, JUMPING_JACKS }
export enum Transports { FOOT, CAR }

// export type TranspLocaleMap = {
//   [key in Transports]: string;
// }

// export const localeTransport: TranspLocaleMap = {
//   [Transports.FOOT]: 'nurse.transports.foot',
//   [Transports.BICYCLE]: 'nurse.transports.bicycle',
//   [Transports.CAR]: 'nurse.transports.car',
//   [Transports.PUB_TRANS]: 'nurse.transports.pub_trans',
//   [Transports.TROTINETTE]: 'nurse.transports.trotinette',
//   [Transports.TRAIN]: 'nurse.transports.train',
//   [Transports.PLANE]: 'nurse.transports.plane',
//   [Transports.HORSE]: 'nurse.transports.horse',
//   [Transports.CAMEL]: 'nurse.transports.camel',
//   [Transports.CART_WHEELS]: 'nurse.transports.cartWheels',
//   [Transports.JUMPING_JACKS]: 'nurse.transports.jumpingJacks',
// };

export interface OnboardingDisplacementMeans {
  transport: Transports;
}

export interface OnboardingRegDocs {
  hasVoice?: boolean;
  hasResume?: boolean;
  done: boolean;
}

export interface PostInterviewData {
  noOiiaqCard?: boolean;
  noDlCard?: boolean;
  done: boolean;
}

export interface EmployeeInfoData {
  insurDocFilename: string;
  SSN: string;
  bizNum: string;
  transitNum: string;
  bankNum: string;
  accountNum: string;
  insurance: boolean;
  photo: boolean;
  signed: boolean;
}

export interface VideosData {
  watched: boolean;
}

// export enum Dispo { FIXED, FLEXIBLE }

// export interface SignUpAvailStep35 {
//   dispo?: Dispo;
// }
