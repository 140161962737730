import { FLO_CA, Constants, NO_REPLY_EMAIL } from '@eksoh/shared/common';

export const ConstFlo = {
  commons: {
    ...Constants
  },
  email: {
    from: {
      cv: `Flowrence CV <cv@${FLO_CA}>`,
      noReply: NO_REPLY_EMAIL,
      info: `info@${FLO_CA}`
    },
    to: {
      rdv: `Rendez-Vous <rdv@${FLO_CA}>`
    }
  },
}

export const CUSTOM_PRODUCTS = 'custom:products';
export const CUSTOM_LICENSE = 'custom:licenseNo';
export const CUSTOM_SSN = 'custom:ssn';
export const CUSTOM_ENTERPRISE = 'custom:enterpriseNo';
export const CUSTOM_INSURANCE = 'custom:insuranceNo';
export const CUSTOM_FAMILY_NAME = 'custom:familyName';
export const CUSTOM_GIVEN_NAME = 'custom:givenName';
export const CUSTOM_ID_FLOW = 'custom:idFlow';
