import Box from '@mui/material/Box';
//
import Header from './Views/Header';
import Main from './Views/Main';
import Footer from './Views/Footer';
import LegalNoticePopup from './Views/LegalNoticePopups/LegalNoticePopup';

export function StripePayment() {
  return <Box style={{ flexGrow: 1 }}>
    <Box style={{ flexGrow: 1 }}>
      <Header title='PAYMENT FORM' logoLink='assets/flowrence_logo.png' />
      <Main />
      <Footer />
    </Box>
    <LegalNoticePopup />
  </Box>
}
