import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';
import { Button } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
// import { scale, moderateScale } from '../Scaling';

export const footMinHeight = 82;
export const footMaxHeight = 133;

export interface OnboardingFooterProps {
  onNext: () => void;
  onBack?: () => void;
  overrideBackText?: string;
  butText?: string;
  disabledBut?: boolean;
  hideLater?: boolean;
}

export default function OnboardingFooter(props: OnboardingFooterProps) {
  const { t } = useTranslation();

  const { onNext, onBack, overrideBackText, butText, disabledBut, hideLater } = props;

  return <View style={[styles.container, { height: hideLater ? footMinHeight : footMaxHeight }]}>
    {!hideLater && <View style={styles.back}>
      <Text style={{ textDecorationLine: 'underline' }}>{t('nurse.defLater')}</Text>
    </View>}
    <View style={styles.containerGrey}>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        {onBack == null && <View style={{ marginLeft: 12, width: 32 }} />}
        {onBack != null && <TouchableHighlight style={{ marginLeft: 12 }} onPress={onBack}>
          <Text style={{ textDecorationLine: 'underline' }}>{t(overrideBackText || 'shorts.back')}</Text>
        </TouchableHighlight>}
        {/* <TouchableHighlight style={styles.but} onPress={onNext} disabled={disabledBut}>
          <Text style={{ color: 'white', textAlign: 'center' }}>{t(butText || 'shorts.next')}</Text>
        </TouchableHighlight> */}
        <Button mode='contained' style={styles.but} labelStyle={styles.butLabel} onPress={onNext} disabled={disabledBut}>
          {t(butText || 'shorts.next')}
        </Button>
      </View>
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  containerGrey: {
    width: '100%',
    height: footMinHeight,
    backgroundColor: '#EBEBEB',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  back: {
    flexGrow: 1,
    textAlign: 'right',
    justifyContent: 'center',
    height: 42,
    marginRight: 20,
  },
  butLabel: {
    color: 'white',
    textTransform: 'lowercase',
  },
  but: {
    marginRight: 18,
    // backgroundColor: '#323232',
    minWidth: 90,
  },
  // but: {
  //   marginRight: 18,
  //   backgroundColor: '#323232',
  //   borderRadius: 10,
  //   borderWidth: 1,
  //   borderColor: '#323232',
  //   padding: 12,
  //   minWidth: 90,
  // },
});
