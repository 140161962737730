/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ColoredPaper = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  // textAlign: 'center',
  color: theme.palette.text.secondary,
  // height: 60,
  // lineHeight: '60px',
  minWidth: 250,
  width: 'fit-content',
  padding: 24,
}));

const darkTheme = createTheme({ palette: { mode: 'dark' } } as any);
const lightTheme = createTheme({ palette: { mode: 'light' } } as any);

//
// ---
//

export interface TagProps {
  isLight?: boolean;
  children: ReactNode | ReactNode[];
  gutterBottom?: boolean;
  gutterRight?: boolean
}

export default function Tag({ isLight, children, gutterBottom, gutterRight }: TagProps) {
  return <ThemeProvider theme={isLight ? lightTheme : darkTheme}>
    <ColoredPaper
      elevation={3}
      sx={{ mb: gutterBottom ? 2 : undefined, mr: gutterRight ? 2 : undefined}}
    >
      {children}
    </ColoredPaper>
  </ThemeProvider>
}

//
// ---
//

export interface CounterTagProps {
  isLight?: boolean;
  title: string;
  label: string;
  num: number;
  of: number;
  gutterBottom?: boolean
  gutterRight?: boolean
}

export function CounterTag({ isLight, title, label, num, of, gutterBottom, gutterRight}: CounterTagProps) {
  return <ThemeProvider theme={isLight ? lightTheme : darkTheme}>
    <ColoredPaper
      elevation={3}
      sx={{ mb: gutterBottom ? 2 : undefined, mr: gutterRight ? 2 : undefined}}
    >
      <Typography variant='h6'>
        {title}
      </Typography>
      <Box height='calc(100% - 80px)' />
      <Box display='flex' flex='0 1 auto'>
        <Typography variant='h3' style={{ marginRight: 14 }}>{num}</Typography>
        <Typography variant='body1' style={{ marginTop: 14 }}>{`${label} of ${of}`}</Typography>
      </Box>
    </ColoredPaper>
  </ThemeProvider>
}
