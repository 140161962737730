import { ReactElement, useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';

// import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventIcon from '@mui/icons-material/Event';
import { useForm, Controller } from 'react-hook-form';
//
import { Appointment } from '@eksoh/flo/model';
import { authStore } from '../../../..';
import { AppmtInfo } from './appmt-info';

export interface ShowAppmtDetailProps {
  show: boolean;
  onAction: (username: string, notes: string) => void;
  onDismiss: () => void;
  data?: Appointment;
  onNoteUpd: (username: string, notes: string) => void;
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function ShowAppmtDetail({ show, onAction, onDismiss, data, onNoteUpd, onWaiting }: ShowAppmtDetailProps) {
  const { authState } = useContext(authStore);
  // const [freshData, setFreshData] = useState<OnboardingData>({} as OnboardingData);
  // const [onbInfo, setOnbInfo] = useState<OnboardingInfo | undefined>({} as OnboardingInfo);
  // const safeCurState = data?.state || OnboardingState.REG_STARTED;

  const [loading, setLoading] = useState(true);
  const {
    getValues, setValue, control, formState: { isDirty, isValid }, reset,
  } = useForm({ defaultValues: { notes: '' } });

  useEffect(() => {
  //   async function getFreshData(username: string) {
  //     let rawData: unknown;
  //     try {
  //       const newInfo = await BeServices.getInstance().user.getOnboardingInfo(username);
  //       setOnbInfo(newInfo || data);
  //       rawData = newInfo?.rawData || data?.rawData || freshData;
  //     }
  //     catch (error) {
  //       console.log('>>> error getting be fresh onboarding data:', error);
  //     }
  //     finally {
  //       let newData: OnboardingData;
  //       try {
  //         newData = JSON.parse(rawData || data?.rawData);
  //       }
  //       catch (error) {
  //         console.log('>>> error parsing raw data from boarding info:', error);
  //         newData = freshData;
  //       }
  //       setFreshData(newData);
  //       setLoading(false);
  //     }
  //   }
  //   if (data?.username != null) getFreshData(data.username);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data == null) return;
    if (show) {
      // setValue('notes', data.detail?.notes || '');
      setValue('notes', '');
    }
  }, [data, setValue, show]);

  function renderContent() {
    if (data == null) return null;
    return <AppmtInfo appmt={data} /> // onbData={freshData} />
    // if (state === OnboardingState.REG_STARTED) return <Registration data={data} onbData={freshData} />
    // if (state === OnboardingState.INTERVIEW) return <Interview data={data} onbData={freshData} />
    // if (state === OnboardingState.ATCD) return <History data={data} onbData={freshData} />
    // if (state === OnboardingState.EMPLOYEE_INFO) return <EmployeeInfo data={data} onbData={freshData} />
    // return null;
  }

  // const noActions = stepsMapping[state] < stepsMapping[safeCurState] || data?.state === OnboardingState.COMPLETED;
  // const waitingForUser = stepsMapping[state] === stepsMapping[safeCurState] && (onbInfo?.nextStates == null || onbInfo.nextStates.length === 0);
  const noteOnly = false; // state === OnboardingState.ATCD || state === OnboardingState.EMPLOYEE_INFO || state === OnboardingState.COMPLETED;
  return <Dialog open={show} maxWidth='lg' fullWidth={true}>
    <DialogContent style={{ backgroundColor: 'transparent', padding: 0 }}>
      <Card>
        <CardHeader
          avatar={<EventIcon onClick={() => {/* */}} />}
          action={<IconButton onClick={onDismiss}><CloseIcon /></IconButton>}
          title={`${data?.detail?.givenName} ${data?.detail?.familyName}`}
          subheader={`${(new Date(data?.start)).toLocaleDateString('fr-CA')} ${(new Date(data?.start)).toLocaleTimeString('fr-CA').substring(0, 7).trim()}`}
        />
        <CardContent>
          {onWaiting(loading)}
          {!loading && <>
            {renderContent()}
            <Box display='flex' alignItems='flex-end' mt={2}>
              <Controller
                name='notes'
                control={control} rules={{ required: false }}
                render={({ field: { onChange, onBlur, value } }) => <TextField
                  label='notes' multiline fullWidth
                  onChange={onChange} onBlur={onBlur} value={value}
                  rows={noteOnly ? 15 : 5}
                />}
              />
              <IconButton
                style={{ padding: 0 }} disabled={!isDirty || !isValid}
                onClick={() => { if (data != null) { onNoteUpd(authState.user?.username || 'unknown', getValues().notes); reset(getValues()); } }}
              >
                <SaveIcon />
              </IconButton>
            </Box>
          </>}
        </CardContent>
        {/* <CardActions style={{ display: 'flex', justifyContent: 'center', margin: 12 }}> {/* disableSpacing> * /}
          {!noActions && waitingForUser && <Typography variant='subtitle2'>
            waiting for action from the user...
          </Typography>}
          {!noActions && onbInfo?.nextStates?.map((nextState, i) => (
            <IconButton
              key={'actionBut' + i} style={{ padding: 0 }}
              onClick={() => onAction(onbInfo.username, getValues().notes, nextState)}
            >
              {nextStepIcons.get(nextState)?.node}
            </IconButton>
          ))}
        </CardActions> */}
      </Card>
    </DialogContent>
  </Dialog>
}
