import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { TextInput, Paragraph, Subheading, List, IconButton } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { authStore, useStateRef } from '@eksoh/shared/ui';
import { nativeStore } from '@eksoh/shared/ui-expo';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import { LangLevels, LangLevelsUI, kLocaleLangLevels } from '@eksoh/flo/model';

const languages = ['français', 'english'];

export default function Languages({ navigation }: RootStackScreenProps<'Languages'>) {
  const { authState, onboarding } = useContext(authStore);
  const { setBottomSheetNode } = useContext(nativeStore);
  const [showLangsDD, setShowLangsDD] = useState([false, false]);
  const [langs, setLangs, langsRef] = useStateRef<(LangLevels | null)[]>([null, null]);
  const [extraLang, setExtraLang] = useState<string>('');
  const [extraLangs, setExtraLangs] = useState<string[]>();
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setBottomSheetNode(undefined);
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (
      authState.onboarding?.personelInfo?.french == null ||
      authState.onboarding?.personelInfo?.english == null
    ) {
      return;
    }
    if (
      authState.onboarding?.personelInfo?.french !== LangLevels.UNSET &&
      authState.onboarding?.personelInfo?.english !== LangLevels.UNSET
    ) {
      navigation.push('Permits');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  function validateNext() {
    return !langs[0] || !langs[1] || extraLang !== '';
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={4} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Logo style={{ marginRight: scale(12) }} width={50} height={50} />
        <Paragraph style={{ marginTop: scale(6), textAlign: 'center' }}>{t('nurse.languageInfo')}</Paragraph>
      </View>
      <View style={{ width: '100%', marginTop: 12, marginBottom: 6 }}>
        <View style={{ width: '100%', marginTop: 12 }}>
          <Subheading>{t('nurse.languages')}</Subheading>
          {languages.map((lang, idx) => <View key={'dd_known_lang' + idx} style={{ width: '100%', marginTop: 3 }}>
            <DropDown
              label={lang}
              mode={'outlined'}
              visible={showLangsDD[idx]}
              showDropDown={() => setShowLangsDD(showLangsDD.map((_, i) => i === idx ? true : false))}
              onDismiss={() => setShowLangsDD(showLangsDD.map(() => false))}
              value={`${langs[idx] || ''}`}
              setValue={val => setLangs(langs.map((_, i) => i === idx ? val : langsRef.current[i]))}
              list={Object.keys(kLocaleLangLevels).map((l, idx) => ({ label: t(kLocaleLangLevels[idx as LangLevelsUI]), value: l }))}
              dropDownContainerHeight={246}
            />
          </View>)}
          <TextInput
            mode={'outlined'} label={t('nurse.otherLang')}
            value={extraLang}
            onChangeText={text => setExtraLang(text)}
            onKeyPress={({ nativeEvent: { key: keyValue } }) => {
              if (keyValue === 'Enter' && extraLang) {
                setExtraLangs([...(extraLangs || []), extraLang]);
                setExtraLang('');
              }
            }}
            style={{ marginBottom: 3 }}
          />
          {extraLangs?.map((el, idx) => <List.Item key={'el_' + idx}
            title={el}
            left={props => <IconButton {...props}
              icon='delete'
              onPress={() => {
                if (extraLangs == null) return;
                const newEL = extraLangs.filter((_, i) => i !== idx);
                setExtraLangs(newEL.length > 0 ? newEL : undefined);
              }}
            />}
          />)}
        </View>
      </View>
    </ScrollView>
    <OnboardingFooter
      onNext={() => {
        if (authState.onboarding?.personelInfo == null) return; // TODO: Add feedback
        onboarding({
          personelInfo: {
            ...authState.onboarding?.personelInfo,
            french: langs[0] || LangLevels.NONE, english: langs[1] || LangLevels.NONE,
            extraLangs,
          }
        });
      }}
      disabledBut={validateNext()} hideLater
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
});
