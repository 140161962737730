export const schemaUI = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'GridLayout',
      config: {
        mb: 2,xs: 12, sm: 6, md: 6, // md: 4,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/familyName',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/givenName',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/email',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/birthdate',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/language',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/insurNumber',
        },
      ],
    },
    {
      type: 'GridLayout',
      config: {
        mb: 2, xs: 12, sm: 12, md: 12, // md: 4,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/fullAddress',
        },
      ],
    },
    {
      type: 'GridLayout',
      config: {
        mb: 2, xs: 12, sm: 6, md: 6, // md: 4,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/mobileNumber',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/phoneNumber',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/curDoctor',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/pharmacy',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/contactName',
        },
        {
          type: 'Control',
          scope: '#/properties/patientInfo/properties/contactNumber',
        },
      ],
    },

    {
      type: 'Control',
      scope: '#/properties/butvalid',
    },
  ],
}
