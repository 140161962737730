import { useState } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { List, Button } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';
import { useTranslation } from 'react-i18next';
//
import { TestAppmntTypes, TestTimeOffer, AppmntTypeDetails } from '@eksoh/flo/model';
import { useNecto, getTimeFromTo, localeTestTimeOffer } from '@eksoh/shared/ui';
import { Text, View } from '../cmps/Themed';

export default function TabApptmnt() {
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [showDropDown2, setShowDropDown2] = useState(false);
  const { geoloc, timeOfferTest, setTimeOfferTest, dailyTimeOffersUi, appmntType, setAppmntType } = useNecto({});
  const [t] = useTranslation();

  return <View style={styles.container}>
    <Text style={{ color: 'grey', padding: 12 }}>
      {geoloc == null ? 'getting geoloc...' : `lat: ${geoloc.coords.latitude} lng: ${geoloc.coords.longitude}`}
    </Text>
    <View style={{ width: '100%', padding: 12, backgroundColor: 'white' }}>
      <DropDown
        label={{ en: 'type', fr: 'type' }.fr}
        mode='outlined'
        value={appmntType}
        setValue={v => setAppmntType(v)}
        list={Object.keys(TestAppmntTypes)
          .filter(k => Object.keys(AppmntTypeDetails).includes(k))
          .map((tat) => ({ // , idx) => ({
            label: t(AppmntTypeDetails[tat as TestAppmntTypes].name),
            value: tat,
            // custom: <List.Item
            //   key={'toItem' + idx}
            //   title={localeTestTimeOffer[t].fr}
            //   titleNumberOfLines={2}
            //   // description={localeTestTimeOffer[t].fr}
            //   left={props => <List.Icon {...props} icon='clock' />}
            // />
        }))}
        visible={showDropDown1}
        showDropDown={() => setShowDropDown1(true)}
        onDismiss={() => setShowDropDown1(false)}
      />
    </View>
    <View style={{ width: '100%', margin: -12, padding: 12, backgroundColor: 'white' }}>
      <DropDown
        label={{ en: 'find nurses...', fr: 'trouver infirm/ière/ier...' }.fr}
        mode='outlined'
        value={timeOfferTest}
        setValue={v => setTimeOfferTest(v)}
        list={Object.keys(TestTimeOffer)
          .filter(k => Object.keys(localeTestTimeOffer).includes(k))
          .map((t) => ({ // , idx) => ({
            label: localeTestTimeOffer[t as TestTimeOffer].fr,
            value: t,
            // custom: <List.Item
            //   key={'toItem' + idx}
            //   title={localeTestTimeOffer[t].fr}
            //   titleNumberOfLines={2}
            //   // description={localeTestTimeOffer[t].fr}
            //   left={props => <List.Icon {...props} icon='clock' />}
            // />
        }))}
        visible={showDropDown2}
        showDropDown={() => setShowDropDown2(true)}
        onDismiss={() => setShowDropDown2(false)}
      />
    </View>
    <ScrollView style={{ flexGrow: 1, width: '100%', backgroundColor: 'white' }}>
    {dailyTimeOffersUi?.weeks.map((w, wIdx) => <List.Section key={'toWeek'+wIdx}>
        {w.ranges?.map((r, rIdx) => <View
          key={'range'+rIdx}
          style={{ width: '100%', flexDirection: 'row', backgroundColor: 'white' }}
        >
          <List.Item
            style={{ flexGrow: 1, width: 'min-content' }}
            title={r.startDate?.toLocaleDateString('fr-CA')} // 5 * 60 * 1000
            description={getTimeFromTo(r.startDate || new Date(), r.endDate || new Date())}
            left={props => <List.Icon {...props} icon='calendar' />}
          />
          <Button style={styles.btn} mode='outlined' onPress={() => { /* */ }}>
            <Text style={{ color: 'black' }}>{{ en: 'select', fr: 'choisir' }.fr}</Text>
          </Button>
        </View>)}
      </List.Section>)}
    </ScrollView>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  btn: {
    margin: 20,
    height: 34,
  },
});
