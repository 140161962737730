/**
  =========================================================
  * eksöh ® corporate kit
  =========================================================

  * Contact: François Paquette
  * Copyright 2022 eksöh ® (https://www.eksoh.com)

  Property of eksöh ®

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { EmrPatientForm } from './form';

const {
  formField: {
    firstName,
    lastName,
    company,
    email,
    // password,
    // repeatPassword,
    address1,
    address2,
    city,
    state,
    zip,
    // twitter,
    // facebook,
    // instagram,
    // publicEmail,
    // bio,
    ssn,
    dob,
    occupation,
    gender,
  },
} = EmrPatientForm;

export const EmrPatientInitialValues = {
  [firstName.name]: '',
  [lastName.name]: '',
  [company.name]: '',
  [email.name]: '',
  // [password.name]: '',
  // [repeatPassword.name]: '',
  [address1.name]: '',
  [address2.name]: '',
  [city.name]: '',
  [state.name]: '',
  [zip.name]: '',
  // [twitter.name]: '',
  // [facebook.name]: '',
  // [instagram.name]: '',
  // [publicEmail.name]: '',
  // [bio.name]: '',
  [ssn.name]: '',
  [dob.name]: '',
  [occupation.name]: '',
  [gender.name]: '',
};
