/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Locale } from '../generated/graphql';

export class LocaleClass {
  constructor(private locale: Locale) {}

  public toLocaleStringDash() {
    return this.locale.replace('_', '-');
  }

  // public toLang() {
  //   return new LocaleClass(this.getLangEnum());
  // }

  public getLangEnum() {
    return this.locale === Locale.FR_CA ? Locale.FR : Locale.EN
  }

  public getLocaleEnum() {
    return this.locale;
  }

  public getCountry(fallback?: string) {
    return this.locale.includes('_') ? this.locale.split('_')[1] || fallback : fallback;
  }

  public static get fr_CA() {
    return new LocaleClass(Locale.FR_CA);
  }

  public static get en_CA() {
    return new LocaleClass(Locale.EN_CA);
  }

  public static get en_US() {
    return new LocaleClass(Locale.EN_US);
  }

  public static fromString(localeStr: string): LocaleClass {
    if (localeStr) {
      const fmtLocale = localeStr.replace('-', '_').toUpperCase();
      if (Object.keys(Locale).indexOf(fmtLocale) != -1) {
        const l = Locale[fmtLocale as keyof typeof Locale];
        return new LocaleClass(l);
      }
    }
    return LocaleClass.default();
  }

  public static fromLangToLocale(localeLangStr: string): LocaleClass {
    if (localeLangStr.includes('-') || localeLangStr.includes('_')) {
      return LocaleClass.fromString(localeLangStr);
    } else {
      return 'fr' === localeLangStr ? LocaleClass.fr_CA : LocaleClass.en_CA;
    }
  }

  public static default() {
    return LocaleClass.en_CA;
  }

  public static supportedLang() {
    return [Locale.FR, Locale.EN];
  }
}


// export function getLocale(from: Locale, use = '_') {
//   if (!from) return Locale.FR_CA.replace('_', use);
//   return from.replace('_', use);
// }


// for (var enumMember in Locale) {
//   console.log('enum member: ', enumMember);
// }

// const e = Object.keys(Locale)
//   .map(key => Locale[key])
//   .filter(value => typeof value === 'string') as string[];

// console.log(e);
// const keys = Object.keys(Locale).indexOf('fr_CA');
// console.log(Object.keys(Locale)[keys], Locale['fr_CA']);
// console.log(typeof Object.keys(Locale)[keys], typeof Locale['fr_CA']);
// let reverseMode = new Map<string, Locale>();
// Object.keys(Locale).forEach((mode: string) => {
//     const modeValue: string = Locale[<any>mode];
//     reverseMode.set(modeValue, mode);
// });
// let mode: Locale = reverseMode.get('fr-CA');
// console.log(mode, typeof mode);
//Object.keys(myEnum).map(key => myEnum[key]).filter(value => typeof value === 'string') as string[];