import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
//
import ActivityRings, { ActivityRingsConfig, ActivityRingsData } from '../../shared/rings/ActivityRings';

export function Rings() {
  const activityData: ActivityRingsData = [
    { value: 0.75, color: '#000000' },
    { value: 0.5, color: '#5E5E5E' },
    { value: 0.6, color: '#919191' },
    { value: 0.3, color: '#C6C6C6' },
  ]

  const activityConfig: ActivityRingsConfig = {
    width: 200,
    height: 200,
  }

  return <Box style={{ marginLeft: 24 }}>
    <ActivityRings data={activityData} config={activityConfig} />
    <Box display='flex'>
      <CircleIcon sx={{ color: 'black', fontSize: 14, mt: 0.2, mr: 0.2 }} />
      <Typography variant='caption' style={{ color: 'black' }}>Aliquam</Typography>
      <CircleIcon sx={{ color: '#5E5E5E', fontSize: 14, mt: 0.2, mr: 0.2, ml: 2 }} />
      <Typography variant='caption' style={{ color: '#5E5E5E' }}>Morbi</Typography>
      <CircleIcon sx={{ color: '#919191', fontSize: 14, mt: 0.2, mr: 0.2, ml: 2 }} />
      <Typography variant='caption' style={{ color: '#919191' }}>Molestie</Typography>
    </Box>
  </Box>
}