/**
  =========================================================
  * eksöh ® corporate kit
  =========================================================

  * Contact: François Paquette
  * Copyright 2022 eksöh ® (https://www.eksoh.com)

  Property of eksöh ®

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from 'yup';
import { EmrEntryForm } from './form';

const {
  formField: {
    type, description, date, specialist, healthCheckRating, discharge, employerName, sickLeave, diagnosisCodes,
  },
} = EmrEntryForm;

export const EmrEntryFormValidations = [
  Yup.object().shape({
    [type.name]: Yup.string().required(type.errorMsg),
    [description.name]: Yup.string().required(description.errorMsg),
    [date.name]: Yup.string().required(date.errorMsg),
    [specialist.name]: Yup.string().required(specialist.errorMsg),
    [diagnosisCodes.code.name]: Yup.string().required(diagnosisCodes.code),
  // }),
  // Yup.object().shape({
    [healthCheckRating.name]: Yup.string().required(healthCheckRating.errorMsg),
  // }),
  // Yup.object().shape({
    [discharge.date.name]: Yup.string().required(discharge.date.errorMsg),
    [discharge.criteria.name]: Yup.string().required(discharge.criteria.errorMsg),
  // }),
  // Yup.object().shape({
    [employerName.name]: Yup.string().required(employerName.errorMsg),
    [sickLeave.startDate.name]: Yup.string().required(sickLeave.startDate.errorMsg),
    [sickLeave.endDate.name]: Yup.string().required(sickLeave.endDate.errorMsg),
  }),
];
