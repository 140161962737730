export interface Medication {
  date: string;
  medication: string;
}

export interface AllergyIntolerance {
  started: string;
  condition: string;
}

export interface ActiveIssue {
  started: string;
  ended?: string;
  issue: string;
}

export type MedSumHabits = 'never' | 'past' | 'still';

export interface ISchemaData {
  medicalSummary: {
    medications: Medication[];
    allergyIntolerance: AllergyIntolerance[];
    activeIssues: {
      medical: ActiveIssue[];
      surgical: ActiveIssue[];
      psychiatric: ActiveIssue[];
    }
    habits: {
      tobacco: MedSumHabits;
      alcohol: MedSumHabits;
      physActivities: MedSumHabits;
    }
  }
  butvalid?: boolean;
}

export const schemaData: ISchemaData = {
  medicalSummary: {
    medications: [],
    allergyIntolerance: [],
    activeIssues: {
      medical: [],
      surgical: [],
      psychiatric: [],
    },
    habits: {
      tobacco: 'never',
      alcohol: 'never',
      physActivities: 'never',
    },
  },
}

// TEST V2 ...

export const medicationsData: Medication[] = [];
export const allergyIntoleranceData: AllergyIntolerance[] = [];
export const activeIssuesData: typeof schemaData.medicalSummary.activeIssues = {
  medical: [],
  surgical: [],
  psychiatric: [],
};
export const habitsData: Medication[] = [];
