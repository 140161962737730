/* eslint-disable @typescript-eslint/no-explicit-any */

import { CSSProperties, useState, useRef, useCallback, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  and, StatePropsOfMasterItem, ArrayLayoutProps, composePaths, computeLabel,
  createDefaultValue, findUISchema, isObjectArray, RankedTester, rankWith,
  uiTypeIs, Resolve, getData, JsonSchema, update,
} from '@jsonforms/core';
import {
  useJsonForms, withJsonFormsMasterListItemProps, JsonFormsDispatch, withJsonFormsArrayLayoutProps,
  JsonFormsStateContext,
} from '@jsonforms/react';
import merge from 'lodash/merge';
import map from 'lodash/map';
import range from 'lodash/range';
// import { useTranslation } from 'react-i18next';
//
import { ArrayLayoutToolbar } from './arraytoolbar';

const styles = {
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    minHeight: '30vh'
  },
  headBG: {
    backgroundColor: '#e0e0e0'
  },
  borderRight500: {
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    borderRightStyle: 'solid',
  } as CSSProperties,
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  } as CSSProperties,
};

function getLabelSubLbl(ctx: JsonFormsStateContext, path: string, index: number, schema: JsonSchema) {
  const childPath = composePaths(path, `${index}`);
  const childData = Resolve.data(getData({ jsonforms: { ...ctx } }), childPath);
  let masterLabel = '';
  let subLabel = '';
  const key = (schema.additionalProperties as any).label;
  if (key) {
    // const masterLabel = firstPrimitiveProp ? childData[firstPrimitiveProp] : '';
    masterLabel = childData && childData[key] ? childData[key] : '';
    const subKey = (schema.additionalProperties as any).subLabel;
    if (subKey) {
      subLabel = childData[subKey] || '';
    }
  }
  return { masterLabel, subLabel, noDelete: childData['butvalid'] };
}

function MasterItem({ index, childLabel, selected, handleSelect, removeItem, path, schema }: StatePropsOfMasterItem) {
  // const [t] = useTranslation();
  const ctx = useJsonForms();
  const { masterLabel, /* subLabel, */ noDelete } = getLabelSubLbl(ctx, path, index, schema);

  return <Box display='flex' alignItems='center' onClick={handleSelect(index)} width='100%'>
    <Avatar aria-label='index'>{index + 1}</Avatar>
    <Typography variant={selected ? 'button' : 'caption'} sx={{ width: '100%', ml: 1 }}>
      {masterLabel || childLabel || ''}
    </Typography>
    {!noDelete && <IconButton aria-label='delete' onClick={removeItem(path, index)} size='large'>
      <DeleteIcon />
    </IconButton>}
  </Box>
}

const ListAccordionMasterItem = withJsonFormsMasterListItemProps(MasterItem);

//

function SmMasterItem({ index, childLabel, selected, handleSelect, removeItem, path, schema }: StatePropsOfMasterItem) {
  // const [t] = useTranslation();
  const ctx = useJsonForms();
  const { masterLabel, subLabel, noDelete } = getLabelSubLbl(ctx, path, index, schema);

  return <ListItem button key={index} onClick={handleSelect(index)} secondaryAction={
    noDelete ? undefined : <IconButton edge='end' onClick={removeItem(path, index)}>
        <DeleteIcon />
      </IconButton>
    } style={{ backgroundColor: selected ? '#eee6' : undefined }}
  >
    <ListItemIcon>
      {/* <Avatar alt='Alice' src='https://material-ui.com/static/images/avatar/3.jpg' /> */}
      <LocalHospitalIcon />
    </ListItemIcon>
    <ListItemText primary={masterLabel || childLabel || ''} secondary={subLabel || ''} />
  </ListItem>
}

const SmListAccordionMasterItem = withJsonFormsMasterListItemProps(SmMasterItem);

//
// ====
//

export const respListWithDetailRendererTester: RankedTester = rankWith(
  4,
  and(uiTypeIs('RespListWithDetail'), isObjectArray)
);

function RespListWithDetailRenderer({
  uischemas,
  schema,
  uischema,
  path,
  errors,
  visible,
  label,
  required,
  removeItems,
  addItem,
  data,
  renderers,
  cells,
  config
}: ArrayLayoutProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const xs = useMediaQuery('(max-width:600px)');
  const sm = useMediaQuery('(min-width:600px)');

  const ctx = useJsonForms();
  const dispatch = useMemo(() => ctx?.dispatch, [ctx?.dispatch]);

  useEffect(() => {
    if (dispatch == null) return;
    dispatch(update('idxSelection', () => { return selectedIndex; }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex])

  const handleRemoveItem = useCallback(
    (p: string, value: any) => () => {
      if (removeItems != null) removeItems(p, [value])();
      if (selectedIndex === value) {
        setSelectedIndex(undefined);
      }
      else if (selectedIndex != null && selectedIndex > value) {
        setSelectedIndex(selectedIndex - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeItems, setSelectedIndex],
  );

  const handleListItemClick = useCallback(
    (index: number) => () => setSelectedIndex(index),
    [setSelectedIndex],
  );

  const handleCreateDefaultValue = useCallback(
    () => createDefaultValue(schema),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createDefaultValue],
  );

  const foundUISchema = useMemo(
    () => findUISchema(
      uischemas || [],
      schema,
      uischema.scope,
      path,
      undefined,
      uischema,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uischemas, schema, uischema.scope, path, uischema],
  );
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const count = useRef(0);

  useEffect(() => {
    count.current = data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data > count.current) setSelectedIndex(count.current);
    count.current = data;
  }, [data]);

  return <Box display='flex' flexDirection='column'>
    {xs && <div>
      <Box pl={2}>
        <ArrayLayoutToolbar
          label={computeLabel(
            label,
            required || false,
            appliedUiSchemaOptions.hideRequiredAsterisk
          )}
          errors={errors}
          path={path}
          addItem={addItem}
          createDefault={handleCreateDefaultValue}
        />
      </Box>
      {map(range(data), index => <Accordion
        key={index} expanded={selectedIndex === data - 1 - index}
        onChange={() => setSelectedIndex(selectedIndex === data - 1 - index ? undefined : data - 1 - index)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListAccordionMasterItem
            index={data - 1 - index}
            path={path}
            schema={schema}
            handleSelect={handleListItemClick}
            removeItem={handleRemoveItem}
            selected={selectedIndex === data - 1 - index}
            key={index}
          />
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {selectedIndex !== undefined && <JsonFormsDispatch
            renderers={renderers}
            cells={cells}
            visible={visible}
            schema={schema}
            uischema={foundUISchema}
            path={composePaths(path, `${selectedIndex}`)}
          />}
        </AccordionDetails>
      </Accordion>)}
    </div>}
    {sm && <div>
      <Grid container style={styles.chatSection}>
        <Grid item xs={3} style={styles.borderRight500}>
          <List>
            <ListItem style={{ width: '100%' }}>
              <ArrayLayoutToolbar
                label={computeLabel(
                  label,
                  required || false,
                  appliedUiSchemaOptions.hideRequiredAsterisk
                )}
                errors={errors}
                path={path}
                addItem={addItem}
                createDefault={handleCreateDefaultValue}
              />
            </ListItem>
            {map(range(data), index => <SmListAccordionMasterItem
              index={data - 1 - index}
              path={path}
              schema={schema}
              handleSelect={handleListItemClick}
              removeItem={handleRemoveItem}
              selected={selectedIndex === data - 1 - index}
              key={index}
            />)}
          </List>
        </Grid>
        <Grid item xs={9} px={2}>
          {selectedIndex !== undefined && <JsonFormsDispatch
            renderers={renderers}
            cells={cells}
            visible={visible}
            schema={schema}
            uischema={foundUISchema}
            path={composePaths(path, `${selectedIndex}`)}
          />}
        </Grid>
      </Grid>
    </div>}
  </Box>
}

export default withJsonFormsArrayLayoutProps(RespListWithDetailRenderer);
