/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, ReactNode, useContext, useState, useMemo } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BugReportIcon from '@mui/icons-material/BugReport';

import PeopleIcon from '@mui/icons-material/People';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaletteIcon from '@mui/icons-material/Palette';
//
import { Group } from '@eksoh/flo/model';
import { globalStore, authStore, useAppSelector, AreYouSureModal, eYesNo } from '@eksoh/shared/ui';
import { webStore, DebugLabel } from '@eksoh/shared/ui-web'; // TODO @fp: that a big NO NO !!!
import GenericSearchBar from './cmps/searchbar';


export type AdminMenuKey = Group | string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AdminMenuItem = { label: string, icon: ReactNode, cmd: any, devOnly?: boolean };

export type AdminMenus = Map<AdminMenuKey, AdminMenuItem[]>;

const kDrawerWidth = 240;

const openedMixin = (theme: Theme, dw?: number): CSSObject => ({
  width: dw || kDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  dw?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, dw }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: dw || kDrawerWidth,
    width: `calc(100% - ${dw || kDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface DrawerProps extends MuiDrawerProps {
  dw?: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})<DrawerProps>(({ theme, open, dw }) => ({
  width: dw || kDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, dw),
    '& .MuiDrawer-paper': openedMixin(theme, dw),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export interface MainLayoutProps {
  name: string;
  logoPath: string;
  menus: AdminMenus;
  onAction: (label: string) => void;
  onAbout: () => void;
  drawerWidth?: number;
  appBarCmp?: ReactNode;
  children: ReactNode | ReactNode[];
}

export function MainLayout({ children, name, logoPath, menus, onAction, onAbout, drawerWidth, appBarCmp }: MainLayoutProps) {
  const { globalState } = useContext(globalStore);
  // const { webState, setThemeMode } = useContext(webStore);
  const { signOut } = useContext(authStore);
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const filteredMenu = useMemo(() => Array.from(menus)
    .map(m => [m[0], m[1].filter(m => !m.devOnly || (m.devOnly && globalState.isDev))] as [string, AdminMenuItem[]])
    .filter(m => m[1].length > 0)
  , [menus]);

  const reduxState = useAppSelector(state => state);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // function action(label: string) {
  //   if (label === 'back') navigate(-1);
  //   else if (label === 'onboarding') { navigate('/onboarding'); }
  //   else if (label === 'search') { navigate('/nurse/search'); }
  //   // else if (label === 'emr') { navigate('/emr'); }
  //   else if (label === 'stripe') { navigate('/stripe'); }
  //   else if (label === 'debug') { navigate('/debug'); }
  //   else if (label === 'theme') setThemeMode(webState.themeMode === 'dark' ? 'light' : 'dark');
  //   else if (label === 'users') { navigate('/'); }
  // }

  function addConsoleLogs(pathname: string) {
    return [
      { label: 'redux', cmd: async () => { console.log('>>> REDUX:', reduxState); } },
    ];
  }

  return <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar position='fixed' open={open} dw={drawerWidth} color='inherit'>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          sx={{ marginRight: 2, ...(open && { display: 'none' }) }}
        >
          <img alt={name} src={logoPath} width={20} />
        </IconButton>
        {appBarCmp || <GenericSearchBar onLogout={() => setLogout(true)} onAbout={onAbout} />}
      </Toolbar>
    </AppBar>
    <Drawer variant='permanent' open={open}>
      <DrawerHeader>
        <img alt={name} src={logoPath} width={20} style={{ marginRight: 10 }} />
        <DebugLabel pathname='' callback={addConsoleLogs} label={name} />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {filteredMenu.map((menu, ii) => <Fragment key={menu[0] + ii}>
        <List>
          {menu[1].map((m, idx) => <ListItem
            key={m.cmd + idx} disablePadding sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => onAction(m.cmd)}
            >
              <Tooltip title={m.label} placement='right'>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {m.icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={m.label} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>)}
        </List>
        <Divider />
      </Fragment>)}
    </Drawer>
    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
      <DrawerHeader />
      {children}
    </Box>
    <AreYouSureModal
      show={logout}
      onClose={() => setLogout(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      msg={t('logout.question')}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) {
          signOut();
        }
        setLogout(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.no')}
    />
  </Box>
}

//

export interface RouteLayoutProps {
  name: string;
  logoPath: string;
  menus: Map<Group | string, { label: string, icon: ReactNode, cmd: any }[]>;
  onAction: (label: string) => void;
  onAbout: () => void;
  drawerWidth?: number;
}

export function RouteLayout({ name, logoPath, menus, onAction, onAbout, drawerWidth }: RouteLayoutProps) {
  return <MainLayout
    name={name} logoPath={logoPath} menus={menus} onAction={onAction} onAbout={onAbout} drawerWidth={drawerWidth}
  >
    <Outlet />
  </MainLayout>
}



// const suMenus = [
//   { label: 'manage users', icon: <PeopleIcon />, cmd: 'users' },
// ];
// const nurseMenus = [
//   { label: 'onboarding', icon: <PersonAddIcon />, cmd: 'onboarding' },
//   { label: 'secure notes', icon: <PersonSearchIcon />, cmd: 'search' },
// ];
// const debugMenus = [
//   { label: 'test stripe', icon: <CreditCardIcon />, cmd: 'stripe' },
//   { label: 'debug', icon: <BugReportIcon />, cmd: 'debug' },
// ];

// const menus = new Map<Group | string, { label: string, icon: ReactNode, cmd: any }[]>([
//   [Group.SUPER_ADMIN, suMenus],
//   ['tmp1', nurseMenus], // temp for now...
//   ['all', [
//     { label: 'back', icon: <ArrowBackIcon />, cmd: 'back' },
//     { label: 'theme', icon: <PaletteIcon />, cmd: 'theme' },
//   ]],
//   ['debug', debugMenus],
// ]);
