import { useState } from 'react';
import { Avatar, Card, Title, Paragraph, List, Button, IconButton } from 'react-native-paper';
//
import { Diagnosis, Entry, EntryFormValues } from '@eksoh/shared/common';
import { useClientEmrStore } from '@eksoh/shared/ui';
import { GenderIcon } from '../cmps/GenderIcon';
import { AddEntryModal } from '../cmps/AddEntryModal';
import { EntryDetails } from '../cmps/EntryDetails';
import { DiagnosesList } from '../cmps/DiagnosesList';

// TODO @fp: is this useful? maybe the screens should stay in the apps and only
//           the cmps should be shared to avoid props as any. to evaluate...
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export function PatientDetail<ScreenProps>({ navigation, route: { params } }: any) { // ScreenProps) {
  const { clientEmrState, updPatient } = useClientEmrStore();
  const patient = params?.id != null ? clientEmrState.patients[params.id] : undefined;

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const closeModal = (): void => {
    setModalOpen(false);
    setError(undefined);
  };

  const submitNewEntry = async (data: EntryFormValues) => {
    // console.log('submitNewEntry:', data);
    try {
      if (patient != null) {
        // fake.
        const newEntry: Entry = { ...data, id: `${(new Date()).getTime()}` } as Entry;
        updPatient({ ...patient, entries: [...patient.entries, newEntry] });
      }

      closeModal();
    }
    catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.error((e as any).response?.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any).response?.data?.error);
    }
  };

  if (!patient) {
    return <Card>
      <Card.Content>
        <Title>No patient found with such ID</Title>
        <Paragraph>Submitted ID: {params?.id}</Paragraph>
      </Card.Content>
      <Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => navigation.replace('PatientListPage')}>Back</Button>
      </Card.Actions>
    </Card>
  }

  return <>
    <Card>
      <Card.Title
        title={patient.name}
        subtitle={patient.occupation}
        left={(props) => <Avatar.Icon {...props} icon={GenderIcon(patient.gender)} />}
        right={(props) => <IconButton {...props} icon='arrow-left-bold-circle' onPress={() => navigation.goBack()} />}
      />
      <Card.Content>
        <Paragraph>SSN: {patient.ssn}</Paragraph>
        <Paragraph>DOB: {patient.dateOfBirth}</Paragraph>
      </Card.Content>
      <Card.Content>
        <List.Section>
          {
            patient.entries.length === 0
              ? <List.Item
                title='No entries'
                description='Add new ones below'
              />
              : patient.entries.map(entry => <EntryDetails key={entry.id} entry={entry}>
                {
                  entry.diagnosisCodes
                    ? <DiagnosesList diagnoses={entry.diagnosisCodes.map((code): Diagnosis =>
                      clientEmrState.diagnoses[code] ?? { code, name: 'Unknown' }
                    )} />
                    : <Paragraph>No diagnosis code available for this entry</Paragraph>
                }
              </EntryDetails>)
          }
        </List.Section>
      </Card.Content>
      <Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => setModalOpen(true)}>Add Entry</Button>
      </Card.Actions>
    </Card>
    <AddEntryModal
      modalOpen={modalOpen}
      onSubmit={submitNewEntry}
      error={error}
      onClose={closeModal}
    />
  </>
}
