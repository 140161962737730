export const schemaUI = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'AutocompleteControl',
      scope: '#/properties/search',
      options: { labels: ['name', 'email', 'phoneNumber'] }
    },
    {
      type: 'ReadonlyArray',
      scope: '#/properties/patients',
      options: { labels: ['name', 'email', 'phoneNumber'] }
    },
  ],
}