import { Ref, forwardRef, ReactElement, useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Alert from '@mui/material/Alert';

// import { AreYouSureModal, eYesNo } from '../../../..';

import JSONViewer from '../jsonviewer';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${0}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export interface FormViewProps {
  open: boolean;
  onClose: () => void;
  name: string;
  data?: unknown;
}

export default function FormView({ open, onClose, name, data }: FormViewProps) {
  const [jsonObj, setJsonObj] = useState<unknown>();
  // const [showARS, setShowARS] = useState(false);
  const [error, setError] = useState('');
  // const [t] = useTranslation();

  useEffect(() => {
    try {
      setJsonObj(typeof data === 'string' ? JSON.parse(data) : data);
    }
    catch (error) {
      setJsonObj({});
      setError(`parsing data error: ${error}`);
    }
  }, [data]);

  return <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          onClick={onClose}
          aria-label='close'
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
          {name}
        </Typography>
        {/* <Button autoFocus color='inherit' onClick={() => handleClose(true)}>
          save
        </Button>
        <Button autoFocus color='inherit' onClick={() => setShowARS(true)}>
          close
        </Button> */}
      </Toolbar>
    </AppBar>
    <Main open={true}>
      {error && <Alert severity='error'>{JSON.stringify(error)}</Alert>}
      <JSONViewer
        json={jsonObj || {}}
      />
    </Main>
    {/* <AreYouSureModal
      show={showARS}
      onClose={() => setShowARS(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      msg={t('userManagement.cancel.question')}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) onClose();
        setShowARS(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.cancel')}
    /> */}
  </Dialog>
}
