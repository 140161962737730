import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { QrReader } from 'react-qr-reader';
//
import { Text, View } from '../cmps/Themed';

export default function TabQrCode() {
  const [value, setValue] = useState('no result');

  return <View style={styles.container}>
    <QrReader
      onResult={(result, error) => {
        if (result != null) {
          setValue(result?.getText());
        }

        if (error != null && error.message != null) {
          console.info('qr code error:', error.name, error.message);
        }
      }}
      containerStyle={{ width: '100%' }}
      constraints={{ facingMode: 'user' }}
    />
    <Text>{value}</Text>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
