/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions, getStateFromPath } from '@react-navigation/native';
import { createURL } from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TabApptmnt: {
            screens: {
              TabApptmnt: 'apptmnt',
            },
          },
          TabGeoloc: {
            screens: {
              TabGeoloc: 'geoloc',
            },
          },
          TabChat: {
            screens: {
              TabChat: 'chat',
            },
          },
          TabQrCode: {
            screens: {
              TabQrCode: 'qrcode',
            },
          },
        },
      },
      Modal: 'modal',
      Onboarding1PostInterview: 'on1postint/:code',
      Onboarding3EmployeeInfo: 'on3einfo',
      Onboarding4Videos: 'on4train',
      PatientList: 'patientlist',
      PatientDetail: 'patientdetail',
      NotFound: '*',
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStateFromPath: (path: string, options?: any) => {
    const p = path.startsWith('/on1postint') ? path : path.toLowerCase();
    return getStateFromPath(p, options);
  }
};

export default linking;
