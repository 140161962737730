import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Platform, StyleSheet, Text, View, ScrollView, Image } from 'react-native';
import { Subheading, Paragraph, Button, TextInput } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import { FormBuilder } from 'react-native-paper-form-builder';
import { Camera, CameraType } from 'expo-camera';
import * as FileSystem from 'expo-file-system';
// import * as Linking from 'expo-linking';
// import { ImageType } from 'expo-camera/src/Camera.types';
import { manipulateAsync, /* FlipType, */ SaveFormat, ImageResult } from 'expo-image-manipulator';
import { useForm } from 'react-hook-form';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
//
import { EmployeeInfoData, Group } from '@eksoh/flo/model';
import { authStore } from '@eksoh/shared/ui';
import { WaitingModal } from '@eksoh/shared/ui-expo';
import { appMaxWidth } from '@eksoh/flo/ui';
import { RootStackScreenProps } from '../types';
import { scale } from '../Scaling';
import Logo from '../cmps/Logo';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import useUploadFile from '../hooks/useUploadFile';
import usePickDocument from '../hooks/usePickDocument';
import useOcr, { supportedImgTypes } from '../hooks/useOcr';
import { BeServices } from '@eksoh/shared/ui';
import { CheckSpecimen } from '../cmps/CheckSpecimen';
import { stepEnd as prevStepEnd } from './Onboarding2CardsScan';

// NOTE: In American English, the noun is spelled the same as the verb—license. But in British English,
//       the noun is spelled licence. All the while, the meaning stays the same—permission, a permit, a
//       document that states you are qualified or allowed to do something.

enum StepTypes { ALL, INSUR, PHOTO, BANK, CONSENT }

export const stepStart = prevStepEnd;
export const stepEnd = prevStepEnd + Object.keys(StepTypes).length;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stepInfos: { [key: string]: any } = {
  [StepTypes.ALL]: {
    list: 'nurse.emplInfoTitle',
    desc: 'nurse.emplInfoDesc',
    btn: 'shorts.start',
  },
  [StepTypes.INSUR]: {
    list: 'nurse.insurUl',
    desc: 'nurse.insurDesc',
  },
  [StepTypes.PHOTO]: {
    list: 'nurse.photoOfYou',
    desc: 'nurse.genPhoto',
  },
  [StepTypes.BANK]: {
    list: 'nurse.driversLic',
    desc: 'nurse.bankInfo',
  },
  [StepTypes.CONSENT]: {
    dlText: 'nurse.contractDl',
    list: 'nurse.contractUl',
    desc: 'nurse.contractDesc',
  },
}

export default function Onboarding3EmployeeInfo({ navigation }: RootStackScreenProps<'Onboarding3EmployeeInfo'>) {
  const { authState, refreshAuth } = useContext(authStore);
  const [mounted, setMounted] = useState(false);
  const [camType, setCamType] = useState(CameraType.back);
  const [camTypes, setCamTypes] = useState<CameraType[]>([]);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [camReady, setCamReady] = useState(false);
  const camRef = useRef<Camera | null>(null);
  const [stepType, setStepType] = useState(StepTypes.ALL);
  const [capture, setCapture] = useState<ImageResult>();
  const [insurDoc, setInsurDoc] = useState<File>();
  const [photo, setPhoto] = useState<ImageResult>();
  const [showCheck, setShowCheck] = useState(false);
  const [retake, setRetake] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [refreshDone, setRefreshDone] = useState(false);
  const { ocrBusy, /* processBase64, */ renderOcr, ocrDocBase64, setPickDocText, dataURLtoFile } = useOcr({
    showPickDoc: true, pickDocText: 'nurse.nurseCard', autoOcr: false,
  });
  const { upload } = useUploadFile({ alwaysRender: true });
  const { doc, fullname, resetDoc, renderPickDoc } = usePickDocument();
  //
  const [t] = useTranslation();

  const { control, getValues, setValue, setFocus, formState: { isDirty, isValid } } = useForm<EmployeeInfoData>({
    defaultValues: {
      insurDocFilename: '',
      SSN: '',
      bizNum: '',
      transitNum: '',
      bankNum: '',
      accountNum: '',
      insurance: false,
      photo: false,
      signed: false,
    },
    mode: 'onChange',
  });

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user == null) navigation.push('Login');
    }, [mounted, authState.user, navigation])
  );

  useEffect(() => {
    if (mounted) return;

    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshDone) navigation.push('Root'); // Go wait for COMPLETED...
  }, [refreshDone, navigation]);

  useEffect(() => {
    if (mounted) return;

    if (authState.user != null) {
      if (authState.onboarding?.employeeInfo == null) return;
      const ei = authState.onboarding?.employeeInfo;
      if (ei.signed) {
        navigation.push('Root'); // Go wait for COMPLETED...
      }
      else {
        setValue('insurDocFilename', ei.insurDocFilename || '');
        setValue('SSN', ei.SSN || '');
        setValue('bizNum', ei.bizNum || '');
        setValue('transitNum', ei.transitNum || '');
        setValue('bankNum', ei.bankNum || '');
        setValue('accountNum', ei.accountNum || '');
        setValue('insurance', ei.insurance || false);
        setValue('photo', ei.photo || false);

        if (ei.SSN && ei.bizNum && ei.transitNum && ei.bankNum && ei.accountNum) setStepType(StepTypes.CONSENT);
        else if (ei.photo) setStepType(StepTypes.BANK);
        else if (ei.insurance) setStepType(StepTypes.PHOTO);
      }
    }
    else {
      navigation.push('Login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding, navigation]);

  useEffect(() => {
    if (!camReady) return;
    async function getCamTypes() {
      const types = await Camera.getAvailableCameraTypesAsync();
      setCamTypes(types);
      if (!types.includes(CameraType.back)) {
        setCamType(CameraType.front);
      }
    }
    getCamTypes();
  }, [camReady])

  useEffect(() => {
    if (!camReady) return;
    async function getCamTypes() {
      const types = await Camera.getAvailableCameraTypesAsync();
      setCamTypes(types);
      if (!types.includes(CameraType.back)) {
        setCamType(CameraType.front);
      }
    }
    getCamTypes();
  }, [camReady])

  useEffect(() => {
    if (retake && capture != null) {
      setCapture(undefined);
      setRetake(false);
    }
  }, [capture, retake]);

  useEffect(() => {
    if (capture != null) {
      if (stepType === StepTypes.PHOTO) { setPhoto(capture); setValue('photo', true); }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capture, stepType]);

  useEffect(() => {
    if (doc != null) {
      if (stepType === StepTypes.INSUR) { setInsurDoc(doc); setValue('insurDocFilename', fullname || ''); setValue('insurance', true); }
      if (stepType === StepTypes.CONSENT) { setValue('signed', true); }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, stepType]);

  useEffect(() => {
    async function watchDoc() {
      if (ocrDocBase64 != null && ocrDocBase64.length > 14) {
        const imgType = supportedImgTypes[ocrDocBase64.substring(0,15)]
        if (imgType == null) return; // TODO: add ui feedback
        const manipResult = await manipulateAsync(
          ocrDocBase64,
          camType === CameraType.front ? [] : [], // [{ flip: FlipType.Horizontal }] : [],
          { compress: 1, format: SaveFormat.PNG }
        );
        if (stepType === StepTypes.PHOTO) { setPhoto(manipResult); setValue('photo', true); }
      }
    }
    if (stepType === StepTypes.CONSENT) return;
    watchDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrDocBase64]);

  if (!permission) {
    // Camera permissions are still loading
    return <View />;
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: 'center' }}>{t('nurse.camNeedPerm')}</Text>
        <Button mode='outlined' onPress={requestPermission}>{t('nurse.camGrantPerm')}</Button>
      </View>
    );
  }

  function toggleCameraType() {
    setCamType((current) => current === CameraType.back ? CameraType.front : CameraType.back);
  }

  async function takePicture() {
    if (camRef.current == null) return;
    if (capture != null) {
      setRetake(true);
      return;
    }

    // const imgOpts: CameraPictureOptions = {
    //   base64?: boolean, // Whether to also include the image data in Base64 format.
    //   exif?: boolean, // Whether to also include the EXIF data for the image.
    //   imageType?: ImageType, //
    //   isImageMirror?: boolean, //
    //   quality?: number, // Specify the quality of compression, from 0 to 1. 0 means compress for small size, 1 means compress for maximum quality.
    //   scale?: number,
    //   skipProcessing?: boolean, // If set to true, camera skips orientation adjustment and returns an image straight from the device's camera. If enabled, quality option is discarded (processing pipeline is skipped as a whole). Although enabling this option reduces image delivery time significantly, it may cause the image to appear in a wrong orientation in the Image component (at the time of writing, it does not respect EXIF orientation of the images).
    //                             // Note: Enabling skipProcessing would cause orientation uncertainty. Image component does not respect EXIF stored orientation information, that means obtained image would be displayed wrongly (rotated by 90°, 180° or 270°). Different devices provide different orientations. For example some Sony Xperia or Samsung devices don't provide correctly oriented images by default. To always obtain correctly oriented image disable skipProcessing option.
    //   onPictureSaved?: (picture: CameraCapturedPicture) => void, // A callback invoked when picture is saved. If set, the promise of this method will resolve immediately with no data after picture is captured. The data that it should contain will be passed to this callback. If displaying or processing a captured photo right after taking it is not your case, this callback lets you skip waiting for it to be saved.
    // };
    // const { uri, width, height, exif, base64 } = await camRef.current.takePictureAsync();
    // console.log('>>> IMG:', uri, width, height, exif, base64);

    // const { base64 } = await camRef.current.takePictureAsync({ isImageMirror: CameraType.front ? true : false });
    const { base64 } = await camRef.current.takePictureAsync();
    if (base64 == null || !base64) return;
    // downloadBase64File(base64, 'b64Snap.png');
    const manipResult = await manipulateAsync(
      base64,
      camType === CameraType.front ? [] : [], // [{ flip: FlipType.Horizontal }] : [],
      { compress: 1, format: SaveFormat.PNG }
    );

    setCapture(manipResult);
    // scanImg('fra', manipResult);
    // processBase64('fra', base64); // TODO FE - re-activate one day...
  }

  const docs = {
    [Group.NURSE]: {
      'fr': require('../../../assets/docs/reg-nurse-fr.pdf'),
      'en': require('../../../assets/docs/reg-nurse-en.pdf'),
    },
    [Group.NURSE_IPS]: {
      'fr': require('../../../assets/docs/reg-nurseips-fr.pdf'),
      'en': require('../../../assets/docs/reg-nurseips-en.pdf'),
    },
  };

  // TODO: improve me and move me in an util flo lib...
  function nurseGroup() {
    return authState.curGroups?.includes(Group.NURSE_IPS)
      ? Group.NURSE_IPS
      : Group.NURSE; // Juste assume nurse otherwise...
  }

  async function download() {
    const grpDoc = docs[nurseGroup()];
    const doc = grpDoc[i18n.language.substring(0, 2) as keyof typeof grpDoc];
    if (doc == null) return; // TODO: return UI feedback
    if (Platform.OS === 'web') {
      window.open(doc, '_blank');

      // // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // Linking.openURL(form).catch((err: any) => {
      //   console.log(err)
      // });

      // const downloadLink = document.createElement('a');
      // document.body.appendChild(downloadLink);
      // downloadLink.href = form;
      // downloadLink.target = '_self';
      // downloadLink.download = 'flowrence_consent_form.pdf';
      // downloadLink.click();
      // // document.body.removeChild(downloadLink);
    }
    else {
      FileSystem.downloadAsync(doc, FileSystem.documentDirectory + 'flowrence_contract.pdf')
        .then(({ uri }) => {
          console.log('Finished downloading to ', uri);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  async function uploadCard(card: string, name: string) {
    const fileName = `${name}_card.png`;
    await upload(dataURLtoFile('data:image/png;base64,' + card, fileName), fileName);
  }

  async function uploadFile(file: File, name: string) {
    await upload(file, name);
  }

  async function nextStep() {
    if (stepType === StepTypes.ALL) {
      setCapture(undefined);
      setRetake(false);
      setStepType(StepTypes.INSUR);
      setPickDocText(stepInfos[StepTypes.INSUR].list)
    }
    else if (stepType === StepTypes.INSUR && insurDoc != null) {
      resetDoc();
      setStepType(StepTypes.PHOTO);
      setPickDocText(stepInfos[StepTypes.PHOTO].list)
    }
    else if (stepType === StepTypes.PHOTO && photo != null) {
      setCapture(undefined);
      setRetake(false);
      setStepType(StepTypes.BANK);
      setPickDocText(stepInfos[StepTypes.BANK].list)
    }
    else if (stepType === StepTypes.BANK && isDirty && isValid) {
      setCapture(undefined);
      setRetake(false);
      setStepType(StepTypes.CONSENT);
      setPickDocText(stepInfos[StepTypes.CONSENT].list)
    }
    else if (stepType === StepTypes.CONSENT && doc != null) {
      // TODO @sb ATCD: here we have to do the async be call that will change the onb status to ATCD_WAITING
      //                otherwise it will restart this component...
      if (insurDoc != null && photo?.base64 != null && doc != null) {
        setUploading(true);
        uploadFile(insurDoc, 'insur');
        uploadCard(photo.base64, 'photo');
        uploadFile(doc, 'work_contract.pdf');
        setUploading(false);
        // onboarding({ employeeInfo: getValues() });
        await BeServices.getInstance().user.onboardingEmployeeInfo({
          ...authState.onboarding,
          employeeInfo: getValues()
        });
        await refreshAuth();
        setRefreshDone(true);
      }
    }
  }

  function notReadyForNext() {
    return ocrBusy
      || (stepType === StepTypes.INSUR && insurDoc == null)
      || (stepType === StepTypes.PHOTO && photo == null)
      || (stepType === StepTypes.BANK && (!isDirty || !isValid))
      || (stepType === StepTypes.CONSENT && doc == null)
  }

  const bigLogo = stepType === StepTypes.ALL || stepType === StepTypes.INSUR || stepType === StepTypes.CONSENT;
  const needsCam = stepType > StepTypes.INSUR && stepType < StepTypes.BANK;
  const uplFile = stepType === StepTypes.INSUR || stepType === StepTypes.CONSENT;
  return <View style={styles.container}>
    <OnboardingHeader progress={stepStart + stepType} onClose={() => navigation.push('Root')} step='nurse.onboarding' />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      {bigLogo && <>
        <Logo style={{ marginTop: scale(6) }} width={60} height={60} />
        <Subheading style={{ marginTop: scale(50), textAlign: 'center' }}>{t(stepInfos[stepType].desc)}</Subheading>
      </>}
      {!bigLogo && <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Logo style={{ marginTop: scale(18) }} width={50} height={50} />
        <View style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
          <Paragraph style={{ marginTop: scale(6), textAlign: 'center', marginLeft: 12 }}>{t(stepInfos[stepType].desc)}</Paragraph>
          {stepType === StepTypes.BANK && <Button mode='outlined' onPress={() => setShowCheck(true)} style={{ marginTop: 6 }}>
            {t('nurse.findOnCheck')}
          </Button>}
        </View>
      </View>}
      {needsCam && <>
        <View style={{ width: '100%', height: 220, marginTop: 24 }}>
          <Camera
            ref={camRef} style={[styles.camera, { transform: [{ scaleX: CameraType.front ? -1 : 1 }] }]}
            type={camType} onCameraReady={() => setCamReady(true)}
          >
            <View style={styles.buttonContainer}>
              {/* <TouchableOpacity
                style={styles.button}
                onPress={toggleCameraType}>
                <Text style={styles.text}>Flip Camera</Text>
              </TouchableOpacity> */}
            </View>
          </Camera>
          {capture != null && capture.base64 && <Image
            style={{ width: '100%', height: 220, resizeMode: 'contain' }}
            source={{ uri: 'data:image/png;base64,' + capture.base64 }}
          />}
        </View>
        {renderOcr(
          <View style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 12, justifyContent: camTypes.length > 1 ? 'space-between' : 'center' }}>
            {camTypes.length > 1 && <Button icon='camera-flip' mode='outlined' onPress={toggleCameraType} disabled={!camReady || camRef.current == null} style={{ width: 160 }}>
              flip camera
            </Button>}
            <Button icon='camera' mode='outlined' onPress={takePicture} disabled={!camReady || camRef.current == null} style={{ width: 160 }}>
              {capture != null ? 're-take' : 'snap'}
            </Button>
          </View>
        )}
      </>}
      {/*
        ref: https://regexlib.com/UserPatterns.aspx?authorId=ea23ae18-1a81-41f9-a637-1ea655cc1fe0
        maybe one day: https://github.com/wealthsimple/canadian-bank-account/blob/master/src/validations.coffee
      */}
      {stepType === StepTypes.BANK && <View style={{ width: '100%', marginTop: stepType === StepTypes.BANK ? 6 : 24 }}>
        <FormBuilder
          control={control}
          setFocus={setFocus}
          inputSpacing={5}
          formConfigArray={[
            {
              name: 'SSN',
              type: 'text',
              textInputProps: {
                label: t('nurse.SSN'),
                left: <TextInput.Icon name='card-account-details' />,
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.SSN')} ${t('forms.isReq')}`,
                },
                pattern: {
                  value: /^\d{3}-?\d{3}-?\d{3}$/,
                  message: `${t('nurse.SSN')} ${t('forms.isInvalid')}`,
                },
              },
            },
            {
              name: 'bizNum',
              type: 'text',
              textInputProps: {
                label: t('nurse.bizNum'),
                left: <TextInput.Icon name='domain' />,
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.bizNum')} ${t('forms.isReq')}`,
                },
              },
            },
            {
              name: 'transitNum',
              type: 'text',
              textInputProps: {
                label: t('nurse.bankBranch'),
                left: <TextInput.Icon name='bank' />,
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.bankBranch')} ${t('forms.isReq')}`,
                },
                pattern: {
                  value: /^\d{5}$/,
                  message: `${t('nurse.bankBranch')} ${t('forms.isInvalid')}`,
                },
                minLength: {
                  value: 5,
                  message: `${t('nurse.bankBranch')} ${t('forms.isInvalid')}`,
                },
                maxLength: {
                  value: 5,
                  message: `${t('nurse.bankBranch')} ${t('forms.isInvalid')}`,
                },
              },
            },
            {
              name: 'bankNum',
              type: 'text',
              textInputProps: {
                label: t('nurse.bankInst'),
                left: <TextInput.Icon name='bank' />,
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.bankInst')} ${t('forms.isReq')}`,
                },
                pattern: {
                  value: /^\d{3}$/,
                  message: `${t('nurse.bankInst')} ${t('forms.isInvalid')}`,
                },
                minLength: {
                  value: 3,
                  message: `${t('nurse.bankInst')} ${t('forms.isInvalid')}`,
                },
                maxLength: {
                  value: 3,
                  message: `${t('nurse.bankInst')} ${t('forms.isInvalid')}`,
                },
              },
            },
            {
              name: 'accountNum',
              type: 'text',
              textInputProps: {
                label: t('nurse.bankAccount'),
                left: <TextInput.Icon name='bank' />,
              },
              rules: {
                required: {
                  value: true,
                  message: `${t('nurse.bankAccount')} ${t('forms.isReq')}`,
                },
                pattern: {
                  value: /^(\d{7}|\d{8})|(\d{9})|(\d{10})|(\d{11})|(\d{12})$/,
                  message: `${t('nurse.bankAccount')} ${t('forms.isInvalid')}`,
                },
                minLength: {
                  value: 7,
                  message: `${t('nurse.bankAccount')} ${t('forms.isInvalid')}`,
                },
                maxLength: {
                  value: 12,
                  message: `${t('nurse.bankAccount')} ${t('forms.isInvalid')}`,
                },
              },
            },
          ]}
        />
      </View>}
      {uplFile && <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 40 }}>
        {stepInfos[stepType].dlText && <Button
          icon='download' mode='outlined' onPress={download} style={{ width: '100%', marginBottom: 40 }}
        >
          {t(stepInfos[stepType].dlText)}
        </Button>}
        {!stepInfos[stepType].dlText && <View style={{ height: 60 }} />}
        {renderPickDoc(stepInfos[stepType].list)}
      </View>}
    </ScrollView>
    <OnboardingFooter butText={t(stepInfos[stepType].btn)} onNext={nextStep} hideLater disabledBut={notReadyForNext()} />
    <WaitingModal open={uploading} text={t('shorts.uploading')} width='70vw' />
    <CheckSpecimen show={showCheck} onClose={() => setShowCheck(false)} />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 64,
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
});
