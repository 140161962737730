import { useContext, useMemo /* , useEffect */ } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dayjs from 'dayjs';
//
// import logo from '../assets/logo.png';
import { clientEmrStore } from '../../../..';

export default function CalendarHeader() {
  const { clientEmrState, setMonthIndex, setDaySelected } = useContext(clientEmrStore);

  const monthYear = useMemo(
    () => clientEmrState.daySelected != null
      ? clientEmrState.daySelected.format('MMMM YYYY')
      : dayjs(new Date(dayjs().year(), clientEmrState.monthIndex)).format('MMMM YYYY'),
    [clientEmrState.monthIndex, clientEmrState.daySelected],
  );
  // useEffect(() => console.log('>>> MONTH INDEX:', clientEmrState.monthIndex), [clientEmrState.monthIndex])

  function handlePrevMonth() {
    setMonthIndex(clientEmrState.monthIndex - 1);
  }

  function handleNextMonth() {
    setMonthIndex(clientEmrState.monthIndex + 1);
  }

  function handleReset() {
    setMonthIndex(
      clientEmrState.monthIndex === dayjs().month()
        ? clientEmrState.monthIndex + Math.random()
        : dayjs().month()
    );
    setDaySelected(dayjs());
  }

  return <Box display='flex' alignItems='center' sx={{ /* px: 4, */ py: 2 }}>
    {/* <Box display='flex' alignItems='center' sx={{ w: 48, h: 48, mr: 2 }}>
      <img src={logo} alt='calendar' width={48} height={48} />
    </Box> */}
    <Typography variant='h5' sx={{ mr: 2 }}>Calendar</Typography>
    <Button onClick={handleReset} variant='outlined' sx={{ py: 1, px: 2, mr: 2 }}>
      Today
    </Button>
    <IconButton onClick={handlePrevMonth}>
      <ChevronLeftIcon />
    </IconButton>
    <IconButton onClick={handleNextMonth}>
      <ChevronRightIcon />
    </IconButton>
    <Typography variant='h6' sx={{ ml: 4 }}>
      {monthYear}
    </Typography>
  </Box>
}
