import { Ref, forwardRef, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Alert from '@mui/material/Alert';

import JSONInput from 'react-json-editor-ajrm';
// import locale from 'react-json-editor-ajrm/locale/en';

import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.css';

import { miniForm } from './forms';
import { AreYouSureModal, eYesNo } from '../../../..';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const locale = require('react-json-editor-ajrm/locale/en');

StylesManager.applyTheme('defaultV2');

const drawerWidth = '75vw';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export interface FormEditProps {
  open: boolean;
  onClose: () => void;
  onSave: (model: unknown) => void;
  name: string;
  model: unknown;
  data?: unknown;
}

export default function FormEdit({ open, onClose, onSave, name, model, data }: FormEditProps) {
  const [edit, setEdit] = useState(false);
  const [objModel, setObjModel] = useState<unknown>();
  const [survey, setSurvey] = useState<Model | null>(null);
  const [showARS, setShowARS] = useState(false);
  const [error, setError] = useState('');
  const [t] = useTranslation();

  useEffect(() => {
    try {
      const m = typeof model === 'string' ? model ? JSON.parse(model) : miniForm : model;
      setObjModel(m);
      setSurvey(new Model(m));
    }
    catch (error) {
      setObjModel(null);
      setError(`parsing model error: ${JSON.stringify(error)}`);
    }
  }, [model]);

  function handleDrawerClose(apply?: boolean) {
    if (apply) setSurvey(new Model(objModel))
    setEdit(false);
  };

  function handleClose(save?: boolean) {
    if (save) onSave(objModel);
    onClose();
  };

  return <Dialog
    fullScreen
    open={open}
    onClose={() => handleClose()}
    TransitionComponent={Transition}
  >
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => setEdit(true)}
          aria-label='close'
        >
          <ModeEditIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
          {name}
        </Typography>
        <Button autoFocus color='inherit' onClick={() => handleClose(true)}>
          save
        </Button>
        <Button autoFocus color='inherit' onClick={() => setShowARS(true)}>
          close
        </Button>
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant='persistent'
      anchor='left'
      open={edit}
    >
      <DrawerHeader>
        <Button onClick={() => handleDrawerClose(true)}>
          {/* {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
          {'apply'}
        </Button>
        <IconButton onClick={() => handleDrawerClose()}>
          {/* {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      {error && <Alert severity='error'>{JSON.stringify(error)}</Alert>}
      <JSONInput
        id='form_model'
        placeholder={objModel}
        // colors={darktheme}
        locale={locale}
        height='90vh'
        width='100%'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(d: any) => {
          if (!d.error) {
            // console.log('>>> JS INPUT SESS:', d)
            // setBusy(true);
            setObjModel(d.jsObject);
          }
        }}
      />
    </Drawer>
    <Main open={edit}>
      <DrawerHeader />
      {survey == null && <Typography variant='h5' sx={{ width: '100%', textAlign: 'center' }}>
        no forms loaded...
      </Typography>}
      {survey != null && <Survey model={survey} />}
    </Main>
    <AreYouSureModal
      show={showARS}
      onClose={() => setShowARS(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      msg={t('userManagement.cancel.question')}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) handleClose(false);
        setShowARS(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.cancel')}
    />
  </Dialog>
}
