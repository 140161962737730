import { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button, Card, Subheading } from 'react-native-paper';
import { Slider } from '@miblanchard/react-native-slider';
//
import { FFLayout } from './layout';
import { FFSliderProps, FFSliderRangeProps } from './slider';

export interface TimeSliderProps {
  min: number;
  max: number;
  step: number;
  stepUnit: 'M' | 'H';
  label?: string;
  value: number;
  onChange: (value: number) => void;
}

export function TimeSlider(props: TimeSliderProps) {
  const [step, setStep] = useState(-1);

  useEffect(() => {
    if (props.stepUnit === 'M' && (props.step < 1 || props.step > 59)) {
      throw Error('TimeSlider - step minute should be between 1 and 59:' + props.step);
    }

    if (props.stepUnit === 'M') {
      setStep(0.01666666666 * props.step);
    }
    else {
      setStep(props.step);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  function renderTime() {
    const mins = Math.round(((props.value - Math.trunc(props.value)) * 60));
    const hour = ('0' + Math.floor(mins === 60 ? props.value + 1 : props.value)).slice(-2);
    const min = props.stepUnit === 'M' && mins !== 60 ? ('0' + mins).slice(-2) : '00';
    return `${props.label ? props.label + ': ' : ''}${hour}:${min}`;
  }

  return <>
    <Subheading style={{ width: '100%', textAlign: 'center' }}>{renderTime()}</Subheading>
    <Slider
      minimumValue={props.min}
      maximumValue={props.max}
      minimumTrackTintColor='rgb(50, 50, 50)'
      maximumTrackTintColor='rgba(50, 50, 50, 0.38)'
      value={props.value}
      onValueChange={val => props.onChange(Array.isArray(val) ? val[0] : val)}
      step={step}
    />
  </>
}

//
// ===
//

export interface FFTimeSliderProps extends FFSliderProps {
  step: number;
  stepUnit: 'M' | 'H';
}

export function FFTimeSlider(props: FFTimeSliderProps) {
  const [value, setValue] = useState(0);

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<TimeSlider
        min={props.min}
        max={props.max}
        step={props.step}
        stepUnit={props.stepUnit}
        label={props.label}
        value={value}
        onChange={val => setValue(val)}
      />}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => props.onSelect(Math.round((value + Number.EPSILON) * 100) / 100)}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}

//
// ===
//

export interface FFTimeRangeProps extends FFSliderRangeProps {
  step: number;
  stepUnit: 'M' | 'H';
}

export function FFTimeRange(props: FFTimeRangeProps) {
  const [value, setValue] = useState([props.min, props.max]);
  const [step, setStep] = useState(-1);

  useEffect(() => {
    if (props.stepUnit === 'M' && (props.step < 1 || props.step > 59)) {
      throw Error('TimeSlider - step minute should be between 1 and 59:' + props.step);
    }

    if (props.stepUnit === 'M') {
      setStep(0.01666666666 * props.step);
    }
    else {
      setStep(props.step);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  function renderTime() {
    const mins = Math.round(((value[0] - Math.trunc(value[0])) * 60));
    const hour = ('0' + Math.floor(mins === 60 ? value[0] + 1 : value[0])).slice(-2);
    const min = props.stepUnit === 'M' && mins !== 60 ? ('0' + mins).slice(-2) : '00';
    const mins2 = Math.round(((value[1] - Math.trunc(value[1])) * 60));
    const hour2 = ('0' + Math.floor(mins2 === 60 ? value[1] + 1 : value[1])).slice(-2);
    const min2 = props.stepUnit === 'M' && mins2 !== 60 ? ('0' + mins2).slice(-2) : '00';
    return `${props.label ? props.label + ': ' : ''}${hour}:${min} - ${hour2}:${min2}`;
  }

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<>
        <Subheading style={{ width: '100%', textAlign: 'center' }}>{renderTime()}</Subheading>
        <Slider
          animateTransitions
          minimumValue={props.min}
          maximumValue={props.max}
          minimumTrackTintColor='rgb(50, 50, 50)'
          maximumTrackTintColor='rgba(50, 50, 50, 0.38)'
          value={value}
          onValueChange={val => setValue(Array.isArray(val) ? val : [val])}
          step={step}
        />
      </>}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => props.onSelect({
          low: Math.round((value[0] + Number.EPSILON) * 100) / 100,
          high: Math.round((value[1] + Number.EPSILON) * 100) / 100,
        })}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
