import { rankWith, scopeEndsWith, RankedTester } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Rating } from './rating';

export const ratingControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  scopeEndsWith('rating')
);

interface RatingControlProps {
  data: any;
  handleChange(path: string, value: any): void;
  path: string;
}

const RatingControl = ({ data, handleChange, path }: RatingControlProps) => (
  <Rating
    value={data}
    updateValue={(newValue: number) => handleChange(path, newValue)}
  />
);

export default withJsonFormsControlProps(RatingControl);
