// import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import CircleIcon from '@mui/icons-material/Circle';
//
// import { MainLayout } from '../shared/layout';
import AssurerList from '../shared/cmps/assurerlist';

// function Title() {
//   const navigate = useNavigate();

//   return <Box display='flex' width='100%'>
//     <IconButton onClick={() => navigate(-1)}>
//       <KeyboardArrowLeftIcon />
//     </IconButton>
//     <Typography variant='h6' mt={0.6} flexGrow={1}>Questionnaire médical</Typography>
//     <IconButton onClick={() => { /* */ }} sx={{ ml: 2 }}>
//       <CircleIcon />
//     </IconButton>
//     <IconButton onClick={() => { /* */ }}>
//       <CircleIcon />
//     </IconButton>
//   </Box>
// }

// export function IFormsInfo() {
//   return <MainLayout name='Flowrence' logoPath='/assets/flowrence_logo.png' appBarCmp={<Title />}>
//     <Box display='flex'>
//       <AssurerList />
//     </Box>
//   </MainLayout>
// }

export function IFormsInfo() {
  return <Box display='flex'>
    <AssurerList />
  </Box>
}
