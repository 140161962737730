/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
//

export interface playAudioPorps {
  audioUrl: string;
}

export function PlayAudio({ audioUrl }: playAudioPorps) {
  const audio = useRef<HTMLAudioElement | null>(null);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    if (!play && audio.current != null) {
      audio.current.pause();
      audio.current = null;
      return;
    }
    else if (play && audio.current == null) {
      try {
        audio.current = new Audio(audioUrl);
        audio.current.addEventListener('ended', () => setPlay(false));
        audio.current.play();
      }
      catch (error) {
        console.log('>>> error playing audio:', error);
        audio.current?.removeEventListener('ended', () => setPlay(false));
        audio.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, audioUrl, play]);


  return <IconButton
    // size='large'
    color={play ? 'info' : undefined}
    onClick={() => setPlay(play => !play)}
  >
    {!play ? <PlayCircleIcon style={{ fontSize: 40 }} /> : <StopCircleIcon style={{ fontSize: 40 }} />}
  </IconButton>
}