import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//
import { Appointment } from '@eksoh/flo/model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function slowGetDetail(data: any, key: string) {
  try {
    if (typeof data?.detail === 'string') {
      return JSON.parse(data.detail)[key] || 'missing';
    }
    else if (typeof data?.detail === 'object') {
      return data.detail[key] || 'missing';
    }
    else {
      return 'bad detail';
    }
  }
  catch (error) { console.log('>>> error in slowGetDetail:', error) }
  return 'missing';
}

export interface AppmtInfoProps {
  appmt: Appointment;
}

export function AppmtInfo({ appmt }: AppmtInfoProps) {
  return <>
    <Typography variant='h6' gutterBottom>
      appointment info
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <TextField label='given name' fullWidth value={slowGetDetail(appmt, 'givenName')} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label='date' fullWidth value={(new Date(appmt.start)).toLocaleDateString('fr-CA')} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label='time slot' fullWidth value={(new Date(appmt.start)).toLocaleTimeString('fr-CA').substring(0, 7).trim()} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField label='phone' fullWidth value={slowGetDetail(appmt, 'phoneNumber')} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
    </Grid>
  </>
}