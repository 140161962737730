import { AppmntGroups, AppmtOrder, AppmtOrderItem, TestAppmntTypes } from './generated/graphql';

// #region Appoiment Details

export type AppmntTypeNurseTypes = 'NURSE' | 'NURSE_IPS';

export interface AppmntTypeDetail {
  code: string;
  appmtType: TestAppmntTypes;
  name: string;
  len: number;
  teamLen?: { [key in AppmntTypeNurseTypes]?: number };
  price: number;
  img: string;
  cardImg: string;
  profession: AppmntTypeNurseTypes;
  cancelFeesAmount?: number;
  cancelFeesPercent?: number;
  needsPrescription?: boolean;
  dependsOn?: TestAppmntTypes;
  // TODO find a way to translate in BE
  title: {
    fr: string;
    en: string;
  };
}

type TypeofAppmntGroups = typeof AppmntGroups;
type AppmntGroupsKey = keyof TypeofAppmntGroups;

export const AppmntTypeDetailsGroupped: { [key in AppmntGroupsKey]: { [key: string]: AppmntTypeDetail } } = {
  [AppmntGroups.PRIVATE_LABORATORY]: {
    [TestAppmntTypes.SAMPLING]: {
      code: '001',
      appmtType: TestAppmntTypes.SAMPLING,
      name: 'appmt.types.SAMPLING',
      len: 3,
      price: 0,
      cancelFeesAmount: 5000,
      img: 'assets/v1/', // icon: 'bloodtype',
      profession: 'NURSE',
      cardImg: 'https://cdn.pixabay.com/photo/2020/09/25/13/48/laboratory-5601435_1280.jpg',
      title: {
        fr: 'Prélèvements et/ou test d\'urine',
        en: 'Blood and/or urine test',
      },
    },
  },
  [AppmntGroups.SUPERNURSE_CONSULTATION]: {
    [TestAppmntTypes.MINOR_EMRGY]: {
      code: '100',
      appmtType: TestAppmntTypes.MINOR_EMRGY,
      name: 'appmt.types.MINOR_EMRGY',
      len: 4,
      teamLen: { NURSE: 3, NURSE_IPS: 4 },
      price: 18500,
      cancelFeesPercent: 0.5,
      img: 'fmd_bad', // icon: 'fmd_bad',
      profession: 'NURSE_IPS',
      title: {
        fr: 'urgence mineure',
        en: 'minor emergency',
      },
      cardImg: 'https://images.pexels.com/photos/339620/pexels-photo-339620.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    },
    [TestAppmntTypes.MAJOR_EMRGY]: {
      code: '101',
      appmtType: TestAppmntTypes.MAJOR_EMRGY,
      name: 'appmt.types.MAJOR_EMRGY',
      len: 8,
      teamLen: { NURSE: 3, NURSE_IPS: 8 },
      price: 30000,
      cancelFeesPercent: 0.5,
      img: 'fmd_bad', // icon: 'fmd_bad',
      profession: 'NURSE_IPS',
      title: {
        fr: 'urgence majeure',
        en: 'major emergency',
      },
      cardImg: 'https://images.pexels.com/photos/42230/teddy-teddy-bear-association-ill-42230.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    },
    [TestAppmntTypes.RENEW1]: {
      code: '102',
      appmtType: TestAppmntTypes.RENEW1,
      name: 'appmt.types.RENEW1',
      len: 4,
      teamLen: { NURSE: 3, NURSE_IPS: 4 },
      price: 18500,
      cancelFeesPercent: 0.5,
      img: 'medication', // icon: 'medication',
      profession: 'NURSE_IPS',
      title: {
        fr: 'renouvellement 2 médicaments',
        en: 'renewal of 1 to 2 medications',
      },
      cardImg: 'https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80',
    },
    [TestAppmntTypes.RENEW2]: {
      code: '103',
      appmtType: TestAppmntTypes.RENEW2,
      name: 'appmt.types.RENEW2',
      len: 8,
      teamLen: { NURSE: 3, NURSE_IPS: 8 },
      price: 30000,
      cancelFeesPercent: 0.5,
      img: 'medication', // icon: 'medication',
      profession: 'NURSE_IPS',
      title: {
        fr: 'renouvellement 3+ médicaments',
        en: 'renewal 3 & more medications',
      },
      cardImg: 'https://images.pexels.com/photos/11211050/pexels-photo-11211050.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    },
    [TestAppmntTypes.ANNUAL_CHECK]: {
      code: '104',
      appmtType: TestAppmntTypes.ANNUAL_CHECK,
      name: 'appmt.types.ANNUAL_CHECK',
      len: 8,
      teamLen: { NURSE: 3, NURSE_IPS: 8 },
      price: 42500,
      cancelFeesPercent: 0.5,
      img: 'medical_services', // icon: 'medical_services',
      profession: 'NURSE_IPS',
      title: {
        fr: 'bilan annuel',
        en: 'annual check up',
      },
      cardImg: 'https://previews.123rf.com/images/rawpixel/rawpixel1706/rawpixel170656305/81011433-stethoscope-doctor-calendar-pen-pencil.jpg',
    },
    [TestAppmntTypes.COMPLEX_ISSUE]: {
      code: '105',
      appmtType: TestAppmntTypes.COMPLEX_ISSUE,
      name: 'appmt.types.COMPLEX_ISSUE',
      len: 8,
      teamLen: { NURSE: 3, NURSE_IPS: 8 },
      price: 30000,
      cancelFeesPercent: 0.5,
      img: 'medical_services', // icon: 'medical_services',
      profession: 'NURSE_IPS',
      title: {
        fr: 'problème complex',
        en: 'complex issue',
      },
      cardImg: 'https://images.pexels.com/photos/1170979/pexels-photo-1170979.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    },
    [TestAppmntTypes.IPS_FOLLOWUP1]: {
      code: '106',
      appmtType: TestAppmntTypes.IPS_FOLLOWUP1,
      name: 'appmt.types.IPS_FOLLOWUP1',
      len: 4,
      price: 0,
      cancelFeesAmount: 0,
      img: 'medical_services', // icon: 'medical_services',
      profession: 'NURSE_IPS',
      title: {
        fr: 'Suivi avec IPS (inclus avec bilan)',
        en: 'Follow-up with IPS (included with yearly health evaluation)',
      },
      cardImg: 'https://images.omerlocdn.com/resize?url=https%3A%2F%2Fgcm.omerlocdn.com%2Fproduction%2Fglobal%2Ffiles%2Fimage%2F1e2f773d-3849-4ecb-9518-69a64b972f6e.jpg&width=1024&type=jpeg&stripmeta=true',
    },
    [TestAppmntTypes.IPS_FOLLOWUP2]: {
      code: '107',
      appmtType: TestAppmntTypes.IPS_FOLLOWUP2,
      name: 'appmt.types.IPS_FOLLOWUP2',
      len: 4,
      price: 12500,
      cancelFeesPercent: 0.5,
      img: 'medical_services', // icon: 'medical_services',
      profession: 'NURSE_IPS',
      title: {
        fr: 'Suivi avec IPS',
        en: 'Follow-up with IPS',
      },
      cardImg: 'https://images.omerlocdn.com/resize?url=https%3A%2F%2Fgcm.omerlocdn.com%2Fproduction%2Fglobal%2Ffiles%2Fimage%2F1e2f773d-3849-4ecb-9518-69a64b972f6e.jpg&width=1024&type=jpeg&stripmeta=true',
    },
  },
  [AppmntGroups.ALACARTE_TESTS]: {
    [TestAppmntTypes.PREGNANCY]: {
      code: '200',
      appmtType: TestAppmntTypes.PREGNANCY,
      name: 'appmt.types.PREGNANCY',
      len: 3,
      price: 2500,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Test de grossesse',
        en: 'Pregnancy test',
      },
    },
    [TestAppmntTypes.COVID]: {
      code: '201',
      appmtType: TestAppmntTypes.COVID,
      name: 'appmt.types.COVID',
      len: 3,
      price: 4000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Test Covid rapide',
        en: 'Rapid covid test',
      },
    },
    [TestAppmntTypes.STREP]: {
      code: '202',
      appmtType: TestAppmntTypes.STREP,
      name: 'appmt.types.STREP',
      len: 10,
      price: 4000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Strep test rapid',
        en: 'Rapid Strep test',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.URINE]: {
      code: '203',
      appmtType: TestAppmntTypes.URINE,
      name: 'appmt.types.URINE',
      len: 1,
      price: 1500,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Test urinaire',
        en: 'Rapid urine test',
      },
      needsPrescription: true,
    },[TestAppmntTypes.VITAL_SIGNS]: {
      code: '204',
      appmtType: TestAppmntTypes.VITAL_SIGNS,
      name: 'appmt.types.VITAL_SIGNS',
      len: 10,
      price: 3000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Prise de signes vitaux',
        en: 'Vital signs',
      },
    },
    [TestAppmntTypes.CAP_BLOOD_GLU]: {
      code: '205',
      appmtType: TestAppmntTypes.CAP_BLOOD_GLU,
      name: 'appmt.types.CAP_BLOOD_GLU',
      len: 1,
      price: 2000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Glycémie capillaire',
        en: 'Capillary blood glucose',
      },
    },
  },
  [AppmntGroups.ALACARTE_HEALTHCARE]: {
    [TestAppmntTypes.EAR_CLEAN]: {
      code: '300',
      appmtType: TestAppmntTypes.EAR_CLEAN,
      name: 'appmt.types.EAR_CLEAN',
      len: 3,
      price: 5000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Nettoyage oreille',
        en: 'Ear cleaning',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.CHANGE_DRESSING]: {
      code: '301',
      appmtType: TestAppmntTypes.CHANGE_DRESSING,
      name: 'appmt.types.CHANGE_DRESSING',
      len: 3,
      price: 6000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Changement de pansement',
        en: 'Dressing change',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.NITROGEN]: {
      code: '303',
      appmtType: TestAppmntTypes.NITROGEN,
      name: 'appmt.types.NITROGEN',
      len: 3,
      price: 5000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Traitement azote (1-5 lésions)',
        en: 'Nitrogen treatment (1-5 lesions)',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.SUTURE_REMOVAL]: {
      code: '304',
      appmtType: TestAppmntTypes.SUTURE_REMOVAL,
      name: 'appmt.types.SUTURE_REMOVAL',
      len: 3,
      price: 3000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Retrait de suture',
        en: 'Suture removal',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.NITROGEN_5]: {
      code: '305',
      appmtType: TestAppmntTypes.NITROGEN_5,
      name: 'appmt.types.NITROGEN_5',
      len: 3,
      price: 6000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Traitement azote (6-10 lésions)',
        en: 'Nitrogen treatment (6-10 lesions)',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.NITROGEN_10]: {
      code: '306',
      appmtType: TestAppmntTypes.NITROGEN_10,
      name: 'appmt.types.NITROGEN_10',
      len: 3,
      price: 7000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Traitement azote (plus que 10 lésions)',
        en: 'Nitrogen treatment (more than 10 lesions)',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.CUSTOM_PRODUCT]: {
      code: '399',
      appmtType: TestAppmntTypes.CUSTOM_PRODUCT,
      name: 'appmt.types.CUSTOM_PRODUCT',
      len: 1,
      price: 0,
      cancelFeesAmount: 0,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Divers',
        en: 'Custom product',
      },
    },
  },
  [AppmntGroups.SUPERNURSE_PROCEDURE]: {
    [TestAppmntTypes.SUTURE]: {
      code: '400',
      appmtType: TestAppmntTypes.SUTURE,
      name: 'appmt.types.SUTURE',
      len: 3,
      price: 7500,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Suture (IPS)',
        en: 'Suture (NP)',
      },
      needsPrescription: true,
    },
    [TestAppmntTypes.PELVIC]: {
      code: '401',
      appmtType: TestAppmntTypes.PELVIC,
      name: 'appmt.types.PELVIC',
      len: 1,
      price: 4000,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Examen pelvien',
        en: 'Pelvic exam',
      },
      needsPrescription: true,
    },
  },
  [AppmntGroups.FORM_FILL]: {
    [TestAppmntTypes.FORM_FILL_1]: {
      code: '500',
      appmtType: TestAppmntTypes.FORM_FILL_1,
      name: 'appmt.types.FORM_FILL_1',
      len: 4,
      price: 12500,
      cancelFeesPercent: 0.5,
      img: 'question_mark', // icon: 'question_mark',
      profession: 'NURSE_IPS',
      cardImg: 'https://images.unsplash.com/photo-1627850604058-52e40de1b847?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dG9kb3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60',
      title: {
        fr: 'Formulaire à remplir',
        en: 'Form to fill',
      },
      dependsOn: TestAppmntTypes.MAJOR_EMRGY,
    },
  },
  [AppmntGroups.ERROR]: {
    [TestAppmntTypes.ERROR]: {
      code: '999',
      appmtType: TestAppmntTypes.ERROR, // TODO: maybe add an unknown or error type...
      name: 'appmt.types.ERROR',
      len: 3,
      price: 0,
      cancelFeesAmount: 0,
      img: 'error', // icon: 'error',
      title: {
        fr: 'erreur interne',
        en: 'internal error'
      },
      cardImg: 'https://cdn.pixabay.com/photo/2014/11/23/10/49/accidental-slip-542551_1280.jpg',
      profession: 'NURSE_IPS',
    },
  },
};

export const AppmntTypeDetails: { [key: string]: AppmntTypeDetail } = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
Object.entries(AppmntTypeDetailsGroupped).forEach(([_, grp]) =>
  Object.entries(grp).forEach(([k2, details]) => AppmntTypeDetails[k2] = details)
);

export function filterServices(grp: { [key: string]: AppmntTypeDetail }) {
  const types: { [key: string]: AppmntTypeDetail; } = {};
  Object.entries(grp).forEach(([k2, details]) => {
    if (k2 !== TestAppmntTypes.CUSTOM_PRODUCT) types[k2] = details;
  });
  return types;
}

function filter(group: typeof AppmntTypeDetailsGroupped) {
  const types: { [key: string]: AppmntTypeDetail; } = {};
  Object.entries(group).forEach(([type, grp]) => {
    if (type !== AppmntGroups.ERROR) {
      Object.entries(grp).forEach(([k2, details]) => {
        if (k2 !== TestAppmntTypes.CUSTOM_PRODUCT) types[k2] = details;
      });
    }
  });
  return types;
}

// console.log('>>> appointment details:', AppmntTypeDetails);

// #endregion Appoiment Details

// #region Appoiment Groups

export interface AppmntGroupDetail {
  img: string;
  // TODO find a way to translate in BE
  title: {
    fr: string;
    en: string;
  };
}

export const AppmntGroupDetails: { [key: string]: AppmntGroupDetail } = {
  [AppmntGroups.PRIVATE_LABORATORY]: {
    img: 'assets/v1/flow_icons_black-16.svg',
    title: {
      fr: 'laboratoires privé',
      en: 'private laboratory',
    },
  },
  [AppmntGroups.SUPERNURSE_CONSULTATION]: {
    img: 'assets/v1/flow_icons_black-02.svg',
    title: {
      fr: 'consultation avec infirmière praticienne spécialisé',
      en: 'supernurse consultation',
    },
  },
  [AppmntGroups.ALACARTE_TESTS]: {
    img: 'assets/v1/flow_icons_black-13.svg',
    title: {
      fr: 'tests à la carte',
      en: 'a la carte tests',
    },
  },
  [AppmntGroups.ALACARTE_HEALTHCARE]: {
    img: 'assets/v1/flow_icons_black-34.svg',
    title: {
      fr: 'soins infirmiers à la carte',
      en: 'a la carte healthcare',
    },
  },
  [AppmntGroups.SUPERNURSE_PROCEDURE]: {
    img: 'assets/v1/flow_icons_black-19.svg',
    title: {
      fr: 'actes médicaux fait par IPS',
      en: 'medical procedures performed by IPS',
    },
  },
  [AppmntGroups.FORM_FILL]: {
    img: 'assets/v1/flow_icons_black-06.svg',
    title: {
      fr: 'Formulaire à remplir',
      en: 'Form to fill',
    },
  },
}

// #endregion Groups

// #region Residence Details

export interface TmpResidenceDetail {
  tmppwd: string;
  name: string;
  address: {
    formatted: string;
    street_name: string;
    city_town: string;
    governing_district: string;
    postal_area: string;
    country: string;
    tel: string;
  };
  geoloc: GeolocationCoordinates;
}

export const TmpResidenceDetails: { [key: string]: TmpResidenceDetail } = {
  '@Newman2023': {
    tmppwd: '@Newman2023',
    name: 'Jazz Newman',
    address: {
      formatted: '2225, rue Léger, LaSalle, H8N 2V7 QC, Canada',
      street_name: '2225, rue Léger',
      city_town: 'LaSalle',
      governing_district: 'Québec',
      postal_area: 'H8N 2V7',
      country: 'Canada',
      tel: '+1 (514) 368-3449',
    },
    geoloc: {
      accuracy: 1,
      latitude: 45.4424775,
      longitude: -73.620359,
      altitude: null,
      altitudeAccuracy: null,
      heading: null, speed: null,
    }
  },
  '@Brossard2023': {
    tmppwd: '@Brossard2023',
    name: 'Jazz Brossard',
    address: {
      formatted: '500, rue des Éclaircies, Brossard, J4Z 0R1 QC, Canada',
      street_name: '500, rue des Éclaircies',
      city_town: 'Brossard',
      governing_district: 'Québec',
      postal_area: 'J4Z 0R1',
      country: 'Canada',
      tel: '+1 (450) 766-0579',
    },
    geoloc: {
      accuracy: 1,
      latitude: 45.4497332,
      longitude: -73.4283315,
      altitude: null,
      altitudeAccuracy: null,
      heading: null, speed: null,
    }
  },
  '@St-Bruno2023': {
    tmppwd: '@St-Bruno2023',
    name: 'Jazz St-Bruno',
    address: {
      formatted: '1470 rue Roberval, St-Bruno de Montarville, J3V 0N1 QC, Canada',
      street_name: '1470 rue Roberval',
      city_town: 'St-Bruno de Montarville',
      governing_district: 'Québec',
      postal_area: 'J3V 0N1',
      country: 'Canada',
      tel: '+1 (450) 283-0242',
    },
    geoloc: {
      accuracy: 1,
      latitude: 45.5269364,
      longitude: -73.3445736,
      altitude: null,
      altitudeAccuracy: null,
      heading: null, speed: null,
    }
  },
};

// #endregion Residence Details

export class MockBe {
  static async getAppmntTypesDetails() {
    await new Promise(resolve => setTimeout(resolve, 700));
    return AppmntTypeDetails;
  }

  static async getAppmntTypesDetailsOfGroup(group: AppmntGroups) {
    await new Promise(resolve => setTimeout(resolve, 700));
    return AppmntTypeDetailsGroupped[group];
  }

  static async getAppmntTypeDetailsByType(type: TestAppmntTypes, showAll = false) {
    await new Promise(resolve => setTimeout(resolve, 700));

    const d = showAll ? AppmntTypeDetails[type] : filter(AppmntTypeDetailsGroupped)[type];
    return d || AppmntTypeDetailsGroupped[AppmntGroups.ERROR][TestAppmntTypes.ERROR];
  }

  private static toItemFees(product: AppmntTypeDetail) {
    const p = this.getCancelationFeesAmount(product);
    return {
      code: product.code,
      i18nKey: 'appmt.types.NO_SHOW',
      total: p,
      price: p,
      quantity: 1,
    };
  }

  static async toItemFeesByCode(code: string) {
    const pr = await this.getAppmntTypeDetailsByCode(code);
    return this.toItemFees(pr);
  }

  static async toOrderFees(items: AppmtOrderItem[]) {
    const newReduceItems = [];
      for (const item of items) {
        newReduceItems.push(await this.toItemFeesByCode(item.code));
      }
      return this.makeNewOrder(newReduceItems, []);
  }

  static makeNewOrder(items: AppmtOrderItem[], appmtItems: AppmtOrderItem[]) {
    const initOrder: AppmtOrder = {
      total: 0,
      subtotal: 0,
      fedTax: 0,
      provTax: 0,
      discount: 0,
      items: [],
    };

    // add existing items of order
    for (const oldItem of appmtItems) {
      initOrder.total += oldItem.total;
      initOrder.subtotal += oldItem.total;
      initOrder.items.push(oldItem);
    }

    for (const i of items) {
      initOrder.total += i.total;
      initOrder.subtotal += i.total;
      initOrder.items.push(i);
    }

    // validate final order
    if (initOrder.total < 0) {
      throw new Error('The order amount can\'t have a negative amount');
    }

    return initOrder;
  }



  static async getDetailToOrderItem(appmtType: TestAppmntTypes, price?: number | null, description?: string | null, isCancelFeeApplies = false) {
    const product = await MockBe.getAppmntTypeDetailsByType(appmtType, true);
    // console.debug('>>>>> product', JSON.stringify(product, null, 2));
    let i: AppmtOrderItem;
    if (appmtType === TestAppmntTypes.CUSTOM_PRODUCT) {
      if (!description || !price)
        throw new Error("The desciption and price can't be empty for a custom product.");

      i = {
        code: product.code,
        i18nKey: description,
        total: price,
        price: price,
        quantity: 1,
      };
    } else if (isCancelFeeApplies) {
      i = this.toItemFees(product);
    } else {
      i = {
        code: product.code,
        i18nKey: product.name,
        total: product.price,
        price: product.price,
        quantity: 1,
      };
    }
    return i;
  }

  static async getAppmntTypeDetailsByGroup(group: AppmntGroups) {
    await new Promise(resolve => setTimeout(resolve, 700));
    return AppmntTypeDetailsGroupped[group] || AppmntTypeDetailsGroupped[AppmntGroups.ERROR][TestAppmntTypes.ERROR];
  }

  static async getAppmntTypeDetailsByGroupAndCode(group: AppmntGroups, code: string) {
    await new Promise(resolve => setTimeout(resolve, 700));
    const detailGroup = AppmntTypeDetailsGroupped[group];
    if (detailGroup == null) return AppmntTypeDetailsGroupped[AppmntGroups.ERROR][TestAppmntTypes.ERROR];
    const details = Object.keys(detailGroup).find(k => detailGroup[k].code === code);
    if (details == null) return AppmntTypeDetailsGroupped[AppmntGroups.ERROR][TestAppmntTypes.ERROR];
    return detailGroup[details];
  }

  static async getAppmntTypeDetailsByCode(code: string) {
    const grpKeys = Object.keys(AppmntTypeDetailsGroupped) as AppmntGroups[];
    for (const grpKeyIdx in grpKeys) {
      const grpKey = grpKeys[grpKeyIdx];
      const apmtDetails = Object.keys(AppmntTypeDetailsGroupped[grpKey]);
      for (const apmtKeyIdx in apmtDetails) {
        const apmtKey = apmtDetails[apmtKeyIdx];
        for (let ii = 0; ii < grpKeys.length; ++ii) {
          if (AppmntTypeDetailsGroupped[grpKey][apmtKey].code === code) return AppmntTypeDetailsGroupped[grpKey][apmtKey];
        }
      }
    }
    return AppmntTypeDetailsGroupped[AppmntGroups.ERROR][TestAppmntTypes.ERROR];
  }

  static async getResidenceDetailsByTmppwd(tmppwd: string) {
    await new Promise(resolve => setTimeout(resolve, 700));
    return TmpResidenceDetails[tmppwd] || null;
  }

  static async getAllResidenceDetails() {
    await new Promise(resolve => setTimeout(resolve, 700));
    return TmpResidenceDetails;
  }

  private static async getCancelationFeesAmountForCode(code: string) {
    const product = await this.getAppmntTypeDetailsByCode(code);
    return this.getCancelationFeesAmount(product);
  }

  private static getCancelationFeesAmount(product: AppmntTypeDetail) {
    if (product.cancelFeesPercent) {
      return Math.ceil(product.price * product.cancelFeesPercent);
    }

    return product.cancelFeesAmount || 0;
  }

  static typeToCode(appmtType: TestAppmntTypes) {
    const details = AppmntTypeDetails[appmtType];
    return details?.code || '999';
  }

  static async typeToProf(type: TestAppmntTypes) {
    const details = AppmntTypeDetails[type] || AppmntTypeDetails[TestAppmntTypes.ERROR];
    return { profession: details.profession, len: details.len };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getResidenceDetailsByCoordinate(obj: any) {
    const lat = obj.lat || obj.latitude;
    const lng = obj.lng || obj.longitude;
    if (lat != null && lng != null) {
      const key = Object.keys(TmpResidenceDetails).find(
        rd => (TmpResidenceDetails[rd].geoloc.latitude === lat || String(TmpResidenceDetails[rd].geoloc.latitude).startsWith(`${lat}`))
          && (TmpResidenceDetails[rd].geoloc.longitude === lng || String(TmpResidenceDetails[rd].geoloc.longitude).startsWith(`${lng}`))
      );
      if (key != null) return TmpResidenceDetails[key];
    }
    return null;
  }
}
