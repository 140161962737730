import { getType } from './util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ValueViewer({ value }: { value: any }) {
  function r() {
    switch (getType(value)) {
      case 'String':
        return <span style={{ color: 'rgb(255, 65, 60)' }}>{`"${value}"`}</span>;
      case 'Boolean':
        return <span style={{ color: 'rgb(31, 48, 255)' }}>{`${value}`}</span>;
      case 'Number':
        return <span style={{ color: 'rgb(31, 49, 255)' }}>{`${value}`}</span>;
      case 'Undefined':
        return <i style={{ color: '#777777' }}>{'undefined'}</i>;
      case 'Null':
        return <i style={{ color: '#777777' }}>{'null'}</i>;
      case 'Date':
        return <i style={{ color: '#007bc7' }}>{`${JSON.stringify(value)}`}</i>;
      default:
        return <span style={{ color: 'rgb(31, 49, 255)' }}>{`${value}`}</span>;
    }
  }

  return <span>{r()}</span>;
}
