import { useState, useEffect, useRef } from 'react';

export enum eGeolocStatus { DISABLED, NOT_SUPPORTED, DENIED, SEARCHING, DONE, ERROR }

export interface useLocationProps {
  notOnMount?: boolean;
  doNotUpd?: boolean;
}

export function useLocation({ notOnMount, doNotUpd }: useLocationProps) {
  const [enabled, setEnabled] = useState(false);
  const [status, setStatus] = useState(eGeolocStatus.DISABLED);
  const [error, setError] = useState<unknown>();
  const [position, setPosition] = useState<GeolocationPosition>();
  const preDebug = useRef<GeolocationPosition>();
  const debug = useRef<GeolocationPosition>();
  const watchId = useRef<number>();

  useEffect(() => {
    if (!notOnMount) start();

    return () => {
      if (watchId.current != null) navigator.geolocation.clearWatch(watchId.current);
      watchId.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enabled && !notOnMount) getGeoLoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, notOnMount]);

  useEffect(() => {
    if (position && !doNotUpd) {
      getGeoLoc();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  function foundLoc(loc?: GeolocationPosition) {
    setPosition(debug.current || (loc != null ? posToObj(loc) : undefined));
    setStatus(eGeolocStatus.DONE);
  }

  // TODO @fe: disable browser geoloc for now...
  function start() {
    // if(!navigator.geolocation) {
      setStatus(eGeolocStatus.NOT_SUPPORTED);
    // }
    // else {
    //   setEnabled(true);
    //   setStatus(eGeolocStatus.SEARCHING);
    //   watchId.current = navigator.geolocation.watchPosition(
    //     position => foundLoc(position),
    //     error => console.log('useLocation - watchPosition error:', error),
    //     {
    //       enableHighAccuracy: true,
    //       maximumAge: 30000,
    //       timeout: 27000
    //     },
    //   );
    // }
  }

  // https://stackoverflow.com/questions/11042212/ff-13-ie-9-json-stringify-geolocation-object
  function posToObj(position: GeolocationPosition) {
    return {
      coords: {
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        speed: position.coords.speed,
      },
      timestamp: position.timestamp,
    }
  }

  function getGeoLoc() {
    if (!enabled) return;
    setStatus(eGeolocStatus.SEARCHING);
    navigator.geolocation.getCurrentPosition(
      position => {
        foundLoc(position);
      },
      error => {
        setStatus(eGeolocStatus.NOT_SUPPORTED);
        setError(`unable to retrieve your location: ${error}`);
      },
    );
  }

  function setDebug(pos?: GeolocationPosition) {
    if (pos != null && debug.current == null) preDebug.current = position;
    debug.current = pos;
    foundLoc(pos || preDebug.current);
    if (pos == null) preDebug.current = undefined;
  }

  return { enabled, status, error, position, start, setDebug, getGeoLoc };
}
