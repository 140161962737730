/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AdminGetUserResponse, AttributeType, GroupType, UserType, VerifiedAttributeType
} from '@aws-sdk/client-cognito-identity-provider';
import {
  PHONE_NUMBER_VERIFIED_ATTRIB,
} from '@eksoh/shared/common';
import { 
  CUSTOM_ENTERPRISE,
  CUSTOM_FAMILY_NAME,
  CUSTOM_GIVEN_NAME,
  CUSTOM_INSURANCE,
  CUSTOM_LICENSE,
  CUSTOM_PRODUCTS,
  CUSTOM_SSN,
  CUSTOM_ID_FLOW, } from "../constants";
import {
  Group,
  User,
  UserStatus,
  UserTypes
} from '../generated/graphql';

export class UserClass {

  // public getDiffOfParamObj(otherUser: Partial<User>) {
  //   // return getDiff<Partial<User>>(otherUser, this); 
  //   return diff(this, otherUser) as Partial<User>;
  // }

  // public getGroupDelta(otherUser: Partial<User>) {
  //   const newGroups = otherUser.groups && otherUser.groups.filter(x => x && !this.groups.includes(x));
  //   const removedGroups = this.groups.filter(x => (x && otherUser.groups) && !otherUser.groups.includes(x)); 
  //   return { add: newGroups, remove: removedGroups };
  // }

  static fromCognito(cognitoUser: UserType | AdminGetUserResponse, groupTypes: GroupType[] | Group[], hasMfaSms?: boolean | null): UserTypes {

    const att = (cognitoUser as UserType).Attributes ? 
                  (cognitoUser as UserType).Attributes : 
                  (cognitoUser as AdminGetUserResponse).UserAttributes;
    const u = this.parseAttribs(att);
    
    u.username = cognitoUser.Username;
    u.enabled = cognitoUser.Enabled;
    u.createdAt = cognitoUser.UserCreateDate ? new Date(cognitoUser.UserCreateDate) : new Date();
    u.updatedAt = cognitoUser.UserLastModifiedDate ? new Date(cognitoUser.UserLastModifiedDate) : new Date();
    u.products = u.products || [];
    u.emailVerified = u.emailVerified || false;
    u.phoneNumberVerified = u.phoneNumberVerified || false;
    // u.updatedAt = '1657090933';
    u.givenName = u[CUSTOM_GIVEN_NAME] || u.givenName;
    u.familyName = u[CUSTOM_FAMILY_NAME] || u.familyName;
    !u.name && (u.name = `${u.givenName || ''} ${u.familyName || ''}`);
    u.mfaSmsActive = hasMfaSms || (cognitoUser as AdminGetUserResponse).UserMFASettingList?.includes('SMS_MFA') || false;
    u.userStatus = cognitoUser.UserStatus as UserStatus || UserStatus.UNKNOWN;

    u.groups = this.parseGroup(groupTypes);
    // console.log('>>>>> g', u.groups);
    // if(!u.id || !u.email || !u.givenName || !u.familyName || !u.locale || !u.gender)
    //   throw new Error(`At least one required field is missing in order to created a valid User: ${JSON.stringify(u)}`);

    u.__typename = UserClass.resolveType(u.groups);
    
    // console.log('>>>>> fromCognito user:', u);
    // return this.fromUser(u as UserTypes);
    return u as UserTypes;
  }

  private static isGroup(input: GroupType[] | Group[]): input is Group[] {
     return input.length > 0 && typeof input[0] === 'string' && input[0] in Group;
  }


  public static resolveType(g: Group[]): 'UserBroker' | 'UserClient' | 'UserSystem' | 'UserNurse' {
    if (g.includes(Group.BROKER)) {
      return 'UserBroker';
    } else if (g.includes(Group.CLIENT)) {
      return 'UserClient';
    } else if (g.includes(Group.NURSE) || g.includes(Group.NURSE_IPS)) {
      return 'UserNurse';
    } 
    // else if (g.includes(Group.INSURER)) {
    //   return 'UserSystem';      // TODO: change to UserInsurer
    // }
    return 'UserSystem';
  };

  // @visibleForTesting
  public static parseGroup(groups: GroupType[] | Group[]): Group[] {
    if (this.isGroup(groups)) {
      return groups;
    } else {
      return groups
                .filter(g => g && g.GroupName && g.GroupName in Group)
                .map(g => Group[g.GroupName as keyof typeof Group]);  
    }
  }

  private static parseAttribs(attribs: AttributeType[] | undefined) {

    const mapping = {
      [VerifiedAttributeType.EMAIL]: VerifiedAttributeType.EMAIL,
      name: 'name',
      given_name: 'givenName',
      family_name: 'familyName',
      locale: 'locale',
      gender: 'gender',
      address: 'address',
      [VerifiedAttributeType.PHONE_NUMBER]: 'phoneNumber',
      birthdate: 'birthdate',
      [CUSTOM_ENTERPRISE]: 'enterpriseNo',
      [CUSTOM_PRODUCTS]: 'products',
      middle_name: 'middleName',
      zoneinfo: 'zoneinfo',
      [CUSTOM_LICENSE]: 'licenseNo',
      [CUSTOM_SSN]: 'ssn',
      [CUSTOM_INSURANCE]: 'insuranceNo',
      [PHONE_NUMBER_VERIFIED_ATTRIB]: 'phoneNumberVerified',
      email_verified: 'emailVerified',
      [CUSTOM_FAMILY_NAME]: CUSTOM_FAMILY_NAME,
      [CUSTOM_GIVEN_NAME]: CUSTOM_GIVEN_NAME,
      [CUSTOM_ID_FLOW]: 'idFlow'
    }
    
    const u : Partial<UserTypes> & {
      [CUSTOM_GIVEN_NAME]?: string,
      [CUSTOM_FAMILY_NAME]?: string,
    } = {};
    
    // assign attributes to props
    attribs && attribs.forEach(att => {
      if (att.Name) {
        const prop = att.Name && mapping[att.Name as keyof typeof mapping];
        if (prop) {
          u[prop as keyof User] = this.parseValue(att.Name, att.Value);
        }
      }
    });

    return u;
  }

  private static parseValue(keyName: string, attribVal: string | undefined) {
    if (keyName === 'address' && attribVal) {
      return JSON.parse(attribVal);
    // } else if (keyName === 'birthdate' && attribVal) {
    //   console.debug('>>>>>>> BIRTHDAY', attribVal);
    //   return new Date(attribVal);
    } else if (keyName === CUSTOM_PRODUCTS) {
      return attribVal ? JSON.parse(attribVal) : [];
    } else if (keyName === 'email_verified' || keyName === 'phone_number_verified' || attribVal === 'true' || attribVal === 'false') {
      return attribVal === 'true';
    } else {
      return attribVal;
    }
  }
}