import { ChangeEvent, MouseEvent, ReactElement, useState, useEffect, useRef } from 'react';
// import { addMinutes, addHours, addMonths } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
//
import { montrealCoords } from '@eksoh/shared/common';
import { ListUsersQuery } from '@eksoh/flo/model';
import { BeServices, useLocation } from '@eksoh/shared/ui';
import { LocationInfo } from './profs';

export type Week5MinRange = [number, number];

const locationFallback = { name: 'montreal', coords: montrealCoords };

export interface DateRangePickerContainerProps {
  locations: LocationInfo[];
  user: ListUsersQuery['listUsers'][number];
  onWaiting: (waiting: boolean) => ReactElement | null;
  onClose: () => void;
}

export function TimeOfferGrid({ locations, user, onWaiting, onClose }: DateRangePickerContainerProps) {
  const [loading, setLoading] = useState(false);
  const { position } = useLocation({});
  const [granularity, setGranularity] = useState(60);
  const [location, setLocation] = useState(0);
  //
  const [zones, setZones] = useState<Week5MinRange[]>([]);
  const [cell1, setCell1] = useState(-1);
  const [cell2, setCell2] = useState(-1);
  // Argghhh!!! https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
  const mount = useRef(false);
  //
  const [debug, setDebug] = useState(false);

  const numRows = 24 * 60 / granularity;

  useEffect(() => {
    if (!mount.current) loadOffers();
    mount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadOffers() {
    if (loading) return;
    setLoading(true);
    try {
      const tos = await BeServices.getInstance().schedule.getUserTimeOffers(user.username) || [];
      setZones(tos.map(to => [to.start5mins, to.end5mins]));
    }
    catch (error) {
      console.log('getUserTimeOffers error:', error);
    }
    finally {
      setLoading(false);
    }
  }

  function getLocation() {
    if (location < locations.length) return locations[location];
    return locationFallback;
  }

  async function onSave() {
    setLoading(true);
    const loc = getLocation();
    try {
      const createTimeOffersInput = {
        lat: position?.coords?.latitude || loc.coords.latitude,
        lng: position?.coords?.longitude || loc.coords.longitude,
        username: user.username,
        profession: user.groups != null && user.groups.length > 1 ? user.groups[0] : 'NURSE',
        ranges: zones.map(z => ({ start5mins: z[0], end5mins: z[1] })),
        reset: true,
      };

      await BeServices.getInstance().schedule.createTimeOffers(createTimeOffersInput);
    }
    catch (error) {
      console.log('getUserTimeOffers error:', error);
    }
    finally {
      setLoading(false);
    }
  }

  function onMouseDown(event: MouseEvent<HTMLTableCellElement>, idx: number) {
    event.preventDefault();
    if (debug) console.log('>>> DOWN:', idx);
    setCell1(idx);
    setCell2(idx);
  }

  function onMouseUp(event: MouseEvent<HTMLTableCellElement>, idx: number) {
    event.preventDefault();
    if (debug) console.log('>>> UP:', idx);
    setZones(mergeOverlapses([...zones, granularity === 5 ? [cell1, cell2] : [cell1 * 12, cell2 * 12]]));
    setCell1(-1); setCell2(-1);
  }

  function onHover(event: MouseEvent<HTMLTableCellElement>, idx: number) {
    event.preventDefault();
    if (debug) console.log('>>> HOVER:', idx);
    if (cell1 === -1) return;
    setCell2(idx);
  }

  function bgColor(idx: number) {
    const isSelected = granularity === 5
      ? zones.some(z => idx >= z[0] && idx < z[1])
      : zones.some(z => idx >= z[0]/12 && idx < z[1]/12);
    const isHighligthed = granularity === 5
      ? idx >= cell1 && idx < cell2
      : idx >= cell1 && idx < cell2;
    return isSelected && isHighligthed
      ? 'red'
      : isHighligthed
        ? 'blue'
        : isSelected
          ? '#0000008a'
          : undefined;
  }

  function mergeOverlapses(numbers: Week5MinRange[]) {
    return numbers
      .sort((a, b) => a[0] - b[0] || a[1] - b[1])
      .reduce<Week5MinRange[]>((combined, next) => {
        // the +1 is to merge adjacent zones...
        if (!combined.length || (combined[combined.length - 1][1] + 1) < next[0]) {
          combined.push(next);
        }
        else {
          const prev = combined.pop();
          if (prev != null) combined.push([Math.min(prev[0], next[0]), Math.max(prev[1], next[1])]);
        }
        return combined;
      }, []);
  }

  return <Card>
    <CardHeader
      avatar={<AccessTimeIcon sx={{ fontSize: 40 }} onClick={() => setDebug(!debug)} />}
      action={<Box display='flex'>
        <Box display='flex' mr={3}>
          <FormControl sx={{ width: '100%', mr: 3 }}>
            <InputLabel>location</InputLabel>
            <Select label='location' fullWidth value={location} onChange={e => setLocation(e.target.value as number)} style={{ height: 44 }}>
              {locations.map((l, idx) => <MenuItem key={`location-${idx}`} value={idx}>
                {l.name}
              </MenuItem>)}
            </Select>
          </FormControl>
          <Switch
            checked={granularity === 5} sx={{ mt: 0.5 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setGranularity(event.target.checked ? 5 : 60)}
          />
          <Typography variant='caption' sx={{ width: 120, mt: 1.5 }}>5 mins</Typography>
        </Box>
        <IconButton onClick={onSave}>
          <SaveIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <IconButton onClick={() => setZones([])}>
          <DeleteOutlineIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <IconButton onClick={loadOffers}>
          <ReplayIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Box>}
      title={`time offers of ${user.name}`}
      subheader={position?.coords == null ? 'waiting for geolocation...' : `at lng: ${position?.coords?.longitude} lat: ${position?.coords?.latitude}`}
    />
    {onWaiting(loading)}
    {!loading && <CardContent style={{ position: 'relative', width: '100%', height: 13 * numRows + 36.5, zIndex: 1 }}>
      <Box height={21} width='100%' />
      {granularity === 60 && Array.from(Array(24).keys()).map((_, i) => <Box key={'bg_hour_' + i} display='flex' justifyContent='center' alignItems='center' height={13} style={{ fontSize: 8, color: '#ccc' }}>
        {('0' + i).slice(-2) + ':00'}
      </Box>)}
      {granularity === 5 && Array.from(Array(24).keys()).map((_, i) => <Box key={'bg_hour_' + i} display='flex' justifyContent='center' alignItems='center' height={156} style={{ fontSize: 120, color: '#ccc' }}>
        {('0' + i).slice(-2) + ':00'}
      </Box>)}
      <TableContainer component={Box} style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>SUN</TableCell>
              <TableCell align='center'>MON</TableCell>
              <TableCell align='center'>TUE</TableCell>
              <TableCell align='center'>WED</TableCell>
              <TableCell align='center'>THU</TableCell>
              <TableCell align='center'>FRI</TableCell>
              <TableCell align='center'>SAT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(Array(numRows).keys()).map((_, i) => <TableRow key={'five_mins_' + i}>
              <TableCell onMouseDown={e => onMouseDown(e,               i)} onMouseUp={e => onMouseUp(e,               i)} onMouseOver={e => onHover(e,               i)} sx={{ width: '15%', backgroundColor: bgColor(              i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}></TableCell>
              <TableCell onMouseDown={e => onMouseDown(e, numRows     + i)} onMouseUp={e => onMouseUp(e,     numRows + i)} onMouseOver={e => onHover(e,     numRows + i)} sx={{ width: '14%', backgroundColor: bgColor(    numRows + i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}></TableCell>
              <TableCell onMouseDown={e => onMouseDown(e, 2 * numRows + i)} onMouseUp={e => onMouseUp(e, 2 * numRows + i)} onMouseOver={e => onHover(e, 2 * numRows + i)} sx={{ width: '14%', backgroundColor: bgColor(2 * numRows + i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}></TableCell>
              <TableCell onMouseDown={e => onMouseDown(e, 3 * numRows + i)} onMouseUp={e => onMouseUp(e, 3 * numRows + i)} onMouseOver={e => onHover(e, 3 * numRows + i)} sx={{ width: '14%', backgroundColor: bgColor(3 * numRows + i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}></TableCell>
              <TableCell onMouseDown={e => onMouseDown(e, 4 * numRows + i)} onMouseUp={e => onMouseUp(e, 4 * numRows + i)} onMouseOver={e => onHover(e, 4 * numRows + i)} sx={{ width: '14%', backgroundColor: bgColor(4 * numRows + i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}></TableCell>
              <TableCell onMouseDown={e => onMouseDown(e, 5 * numRows + i)} onMouseUp={e => onMouseUp(e, 5 * numRows + i)} onMouseOver={e => onHover(e, 5 * numRows + i)} sx={{ width: '14%', backgroundColor: bgColor(5 * numRows + i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black', borderRight: '1px solid #e0e0e0' }}></TableCell>
              <TableCell onMouseDown={e => onMouseDown(e, 6 * numRows + i)} onMouseUp={e => onMouseUp(e, 6 * numRows + i)} onMouseOver={e => onHover(e, 6 * numRows + i)} sx={{ width: '15%', backgroundColor: bgColor(6 * numRows + i), borderBottom: (i + 1) % 12 ? undefined : '1px solid black'                                   }}></TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>}
    {!loading && <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant='outlined' sx={{ ml: 1 }} onClick={onSave}> {/* browser geoloc is disabled for now... disabled={position?.coords == null}> */}
        {'save'}
      </Button>
      <Button variant='outlined' sx={{ ml: 1 }} onClick={() => setZones([])}>
        {'clear'}
      </Button>
      <Button variant='outlined' sx={{ ml: 1 }} onClick={loadOffers}>
        {'reset'}
      </Button>
      <Button variant='outlined' sx={{ ml: 1 }} onClick={onClose}>
        {'cancel'}
      </Button>
    </CardContent>}
  </Card>
}