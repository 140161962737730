export interface IVitalSigns {
  systolic: number;
  diastolic: number;
  pulse: number;
  spo2: number;
  respirations: number;
  temperature: number;
}

export const schemaData: IVitalSigns = {
  systolic: 0,
  diastolic: 0,
  pulse: 0,
  spo2: 0,
  respirations: 0,
  temperature: 0,
}
