import { useContext /* , useState */ } from 'react';
import { Title, Subheading, Button } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { authStore, globalStore } from '@eksoh/shared/ui';
import { View } from '../cmps/Themed';
import { RootStackScreenProps } from '../types';
import Layout from '../cmps/Layout';
import Logo from '../cmps/Logo';
import { scale /* , moderateScale */ } from '../Scaling';
import LanguageBanner from '../cmps/LanguageBanner';
import { DebugPanel } from '../cmps/Debug';

export default function ResidenceDashboard({ navigation }: RootStackScreenProps<'ResidenceDashboard'>) {
  const { globalState } = useContext(globalStore);
  const { signOut } = useContext(authStore);
  // const [code, setCode] = useState('');
  const [t] = useTranslation();

  const showDev = globalState.isDev;
  return <Layout hideFab={true}>
    <View style={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: 'transparent', paddingTop: 24 }}>
      <LanguageBanner />
    </View>
    <View style={{ height: '10vh'}} />
    <Logo style={{ marginTop: scale(1) }} width={60} height={60} />
    <Title style={{ marginTop: 40 }}>residence</Title>
    <Subheading style={{ marginTop: 12 }}>mobile version not yet implemented</Subheading>
    {showDev && <DebugPanel navigation={navigation} mt={40} />}
    <Button icon='logout' mode='outlined' onPress={signOut}>
      {t('shorts.logout')}
    </Button>
    <View style={{ height: '10vh'}} />
  </Layout>
}
