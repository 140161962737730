import { ReactNode, useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { Avatar } from 'react-native-paper';

type BarProps = {
  rating: number;
  showText: boolean;
};

const HEALTHBAR_TEXTS = [
  'The patient is in great shape',
  'The patient has a low risk of getting sick',
  'The patient has a high risk of getting sick',
  'The patient has a diagnosed condition',
];

export function HealthRatingBar({ rating, showText }: BarProps) {
  const [curRating, setCurRating] = useState(3);
  const [maxRating] = useState(4);
  const [stars, setStarts] = useState<ReactNode[]>([]);

  useEffect(() => {
    const allStarts: ReactNode[] = [];
    for (let i = 1; i <= maxRating; i++) {
      allStarts.push(
        <TouchableOpacity
          activeOpacity={0.7}
          key={'start' + i}
          onPress={() => setCurRating(i)}>
          <Avatar.Icon
            size={40} color='red' style={{ backgroundColor: 'transparent' }}
            icon={i <= curRating ? 'heart' : 'heart-outline'}
          />
        </TouchableOpacity>
      );
    }
    setStarts(allStarts);
  }, [curRating, maxRating]);

  return (
    <View style={styles.MainContainer}>
      <View style={styles.childView}>{stars}</View>
      {showText && <Text style={styles.textStyleSmall}>{HEALTHBAR_TEXTS[rating]}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  MainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // paddingTop: Platform.OS === 'ios' ? 20 : 0,
  },
  childView: {
    justifyContent: 'center',
    flexDirection: 'row',
    // marginTop: 30,
  },
  textStyleSmall: {
    textAlign: 'center',
    fontSize: 16,
    color: '#000',
    marginTop: 12,
  },
});