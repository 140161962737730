import React from 'react';
import { StyleSheet } from 'react-native';
import { Appbar } from 'react-native-paper';
//
import { RootStackScreenProps } from '../types';
import { View } from '../cmps/Themed';

export default function Profile({ navigation }: RootStackScreenProps<'Profile'>) {
  return <View style={styles.container}>
    <Appbar style={styles.bottom}>
      <Appbar.Action
        icon='chevron-left'
        onPress={navigation.goBack}
      />
      {/* <Appbar.Action icon='mail' onPress={() => console.log('Pressed mail')} />
      <Appbar.Action icon='label' onPress={() => console.log('Pressed label')} />
      <Appbar.Action
        icon='delete'
        onPress={() => console.log('Pressed delete')}
      /> */}
    </Appbar>
  </View>
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 20,
  },
});
