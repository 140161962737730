/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
// import generator from 'generate-password-ts';
// 
import {
  Group, User, Gender, CreateUserInput, Product, Locale, PwdAction, UserType, UserInput, ListUsersQuery, UsernameAliasType,
} from '@eksoh/flo/model';
import { IQueryParams, serializeAxiosError } from '../../../redux/reducers/reducer.utils';
// TODO @fp: import { sanitizeObjTmp } from '@shared/util/misc';
import {
  DEFAULT_PASSWORD
} from '@eksoh/shared/common';
import { BeServices } from '../../../..';

// TODO: to move to a util lib =================

// function generatePassword(
//   length = 12,
//   wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
// ) {
//   return Array.from(crypto.getRandomValues(new Uint32Array(length)))
//     .map((x) => wishlist[x % wishlist.length])
//     .join('');
// }

// =================

export const defaultInputUser: UserInput = {
  enabled: true,
  givenName: '',
  familyName: '',
  email: '',
  groups: [Group.SUPER_ADMIN],
  locale: Locale.FR_CA,
  gender: Gender.O,
  birthdate: null, // new Date((new Date()).setFullYear(new Date().getFullYear() - 20)), // 20 years ago
  phoneNumber: '', // '+15141231234',
  middleName: '',
  zoneinfo: 'America/Toronto',
  products: [Product.INSURANCE],
  address: {
    postalCode: '',
  },
}

export const defaulCreatetUser: CreateUserInput = {
  usernameAliasType: UsernameAliasType.EMAIL,
  user: defaultInputUser,
  pwdOptions: {
    action: PwdAction.GENERATE_RANDOM,
    // action: PwdAction.SET,
    // password: generatePassword(),
  },
  userType: UserType.SYSTEM_TYPE,
  // userType: UserType.BROKER_TYPE,
  userBroker: {
    licenseNo: 'idLicense',
  },
}

const initialState = {
  loading: false,
  errorMessage: null as any,
  users: [] as ListUsersQuery['listUsers'],
  groups: [] as Group[],
  user: { ...defaultInputUser } as User,
  password: DEFAULT_PASSWORD,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

// Async Actions

export const getUsers = createAsyncThunk('userManagement/fetch_users',
  async ({ page, size, sort }: IQueryParams) => {
    // const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    const result = await BeServices.getInstance().user.listUsers();
    return result;
  }
);

export const getUsersAsAdmin = createAsyncThunk('userManagement/fetch_users_as_admin',
  async ({ query, page, size, sort }: IQueryParams) => {
    const groups = query != null ? query.split('|') as Group[]: undefined;
    const result = await BeServices.getInstance().user.listUsers(groups);
    return result;
  }
);

export const getUser = createAsyncThunk(
  'userManagement/fetch_user',
  async (username: string) => {
    const result = await BeServices.getInstance().user.getUser(username);
    if (result == null) throw new Error('user not found'); // TODO: localize me (return i18n key) !!
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createUser = createAsyncThunk(
  'userManagement/create_user',
  async (input: CreateUserInput, { dispatch }) => {
    // TODO @sb - move this into createUserOrPatient ??
    //          - +1 (514) 290-4800 not accepted by AWS but +15142904800 is
    if (input.user.phoneNumber) input.user.phoneNumber = input.user.phoneNumber.replace(/[- )(]/g, '');
    const result = await BeServices.getInstance().user.createUser(input);
    return result;
  },
  { serializeError: serializeAxiosError } // TODO @fp - FE WORK - handler les errors appsync eventuellement
);

export const updateUser = createAsyncThunk(
  'userManagement/update_user',
  async (user: User, { dispatch }) => {
    // TODO @sb - move this into createUserOrPatient ??
    //          - +1 (514) 290-4800 not accepted by AWS but +15142904800 is
    // if (input.user.phoneNumber) input.user.phoneNumber = input.user.phoneNumber.replace(/[- )(]/g, '');
    // TODO @sb: const cleanUser = sanitizeObjTmp(user, 'UpdateUserInput');
    const result = await Promise.resolve(defaultInputUser); // TODO @sb: BeServices.getInstance().user.updateUser(cleanUser);
    dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteUser = createAsyncThunk(
  'userManagement/delete_user',
  async (username: string, { dispatch }) => {
    const result = await BeServices.getInstance().user.deleteUser(username);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export type UserManagementState = Readonly<typeof initialState>;

export const UserManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState as UserManagementState,
  reducers: {
    reset() {
      return initialState;
    },
    getGroups(state) {
      state.groups = Object.values(Group);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(deleteUser.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.user = { ...defaultInputUser } as User;
      })
      .addMatcher(isFulfilled(getUser), (state, action) => {
        state.loading = false;
        state.user = action.payload as User;
      })
      .addMatcher(isFulfilled(getUsers, getUsersAsAdmin), (state, action) => {
        state.loading = false;
        state.users = action.payload;
        // state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.totalItems = action.payload.length;
      })
      .addMatcher(isFulfilled(createUser, updateUser), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.user = action.payload as User;
      })
      .addMatcher(isPending(getUsers, getUsersAsAdmin, getUser), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createUser, updateUser, deleteUser), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(getUsers, getUsersAsAdmin, getUser, createUser, updateUser, deleteUser), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset, getGroups } = UserManagementSlice.actions;

// Reducer
export default UserManagementSlice.reducer;
