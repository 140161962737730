import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import i18n from 'i18next';
import { DateTime } from 'luxon';
//
import { User, TimeOfferRange } from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { authStore, nectoStore, UserDetailTwoLiners } from '@eksoh/shared/ui';

export interface PickSlotProps {
  offerRange?: TimeOfferRange;
  onCancel: () => void;
  onCreate: (selectedDate: Date) => void;
}

export function PickSlot({ offerRange, onCancel, onCreate }: PickSlotProps) {
  const { authState } = useContext(authStore);
  const { nectoState } = useContext(nectoStore);
  const [selIdx, setSelIdx] = useState(-1);
  const [t] = useTranslation();

  function returnDate() {
    if (offerRange == null || selIdx < 0) return;
    const time = DateTime.fromISO(offerRange.times[selIdx]);
    onCreate(
      DateTime.fromISO(offerRange.startDate)
        .set({ hour: time.hour, minute: time.minute, second: 0, millisecond: 0 })
        .toJSDate()
    );
  }

  const notMe = nectoState.users != null && nectoState.users.length > 0 && nectoState.users[0].username !== authState.user?.username ? nectoState.users[0] : undefined;
  return <Box display='flex' justifyContent='center' mt={2}>
    <Card style={{ maxWidth: 800, textAlign: 'center' }}>
      <Typography sx={{ typography: { sm: 'h3', xs: 'h5' } }}>
        {'select a time slot'}
      </Typography>
      {offerRange != null && <CardContent>
        {notMe != null && <UserDetailTwoLiners
          label='' user={notMe as User}
        />}
        <Typography sx={{ typography: { sm: 'h4', xs: 'h6' } }}>
          date: {DateTime.fromISO(offerRange.startDate).toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}
        </Typography>
        <Grid container spacing={1} mt={2}>
          {offerRange.times.map((time, idx) => <Grid key={'b' + idx} item xs={4} sm={3}>
            <Button
              variant={selIdx === idx ? 'contained' : 'outlined'} color='info'
              onClick={() => setSelIdx(idx)} sx={{ px: 0.2, py: 0.1 }}
            >
              {DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE, { locale: i18n.language })}
            </Button>
          </Grid>)}
        </Grid>
      </CardContent>}
      {offerRange == null && <CardContent sx={{ py: 0 }}>
        <Typography variant='h4'>
          sorry, no available times
        </Typography>
      </CardContent>}
      <CardActions style={{ justifyContent: 'flex-end', padding: 24 }}>
        <Button size='small' onClick={onCancel}>
          {t('shorts.cancel')}
        </Button>
        {selIdx !== -1 && <Button variant='outlined' size='small' sx={{ ml: 2 }} onClick={returnDate}>
          {t('entity.action.confirm')}
        </Button>}
      </CardActions>
    </Card>
  </Box>
}

//
// ===
//

export interface PickSlotModalProps extends PickSlotProps {
  open: boolean;
  onClose: () => void;
}

export function PickSlotModal(props: PickSlotModalProps) {
  const { open, onClose, ...rest } = props;
  return <Dialog open={open} onClose={onClose}>
    <DialogContent sx={{ p: 0 }}>
      <PickSlot {...rest} />
    </DialogContent>
  </Dialog>
}
