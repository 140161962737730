import { useState /* , useEffect */ } from 'react';
import { View } from 'react-native';
import { TextInput, Subheading, ToggleButton, Divider, Button } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';
import { DatePickerInput } from 'react-native-paper-dates';
import { isValid } from 'date-fns';
import { useFormik } from 'formik';
//
import { Entry, EmrEntryInitialValues, EntryFormValues } from '@eksoh/shared/common';
import { useClientEmrStore } from '@eksoh/shared/ui';
import { DiagnosisSelection } from '../cmps/FormFields';

const isValidDate = (x: unknown): boolean => (
  isValid(typeof x === 'string' ? new Date(x) : x)
);

const entryTypeOptions: { value: Entry['type']; label: string }[] = [
  { value: 'HealthCheck', label: 'Health check' },
  { value: 'OccupationalHealthcare', label: 'Occupational healthcare' },
  { value: 'Hospital', label: 'Hospital' },
];

interface Props {
  onSubmit: (values: EntryFormValues) => void;
  onCancel: () => void;
}

export function AddEntryForm({ onSubmit, onCancel }: Props) {
  const { clientEmrState } = useClientEmrStore();
  const [showDropDown, setShowDropDown] = useState(false);
  // const currentValidation = validations[0];
  const formik = useFormik<EntryFormValues>({
    initialValues: EmrEntryInitialValues as EntryFormValues,
    // validationSchema: currentValidation,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      console.log('AddEntryForm:', JSON.stringify(values, null, 2));
      onSubmit(values);
    },
    validate: values => {
      const requiredError = 'Field is required';
      const errors: { [field: string]: ({ [subfield: string]: string } | string) } = {};

      // Basic mandatory
      if (!values.description) { errors.description = requiredError; }
      if (!values.specialist) { errors.specialist = requiredError; }

      if (!values.date) { errors.date = requiredError; }
      else if (!isValidDate(values.date)) {
        errors.date = 'Wrong format. Bizarre!';
      }

      // Specific fields
      switch (values.type) {
        case 'HealthCheck':
          if (values.healthCheckRating == null) { errors.healthCheckRating = requiredError; }
          else if (
            values.healthCheckRating != null &&
            (values.healthCheckRating < 0 || values.healthCheckRating > 3)
          ) {
            errors.healthCheckRating = 'The value must be in range 0-3';
          }
          break;

        case 'Hospital':
          if (!values.discharge?.criteria) { errors.discharge = { criteria: requiredError }; }
          if (!values.discharge?.date) { errors.discharge = { date: requiredError }; }
          else if (!isValidDate(values.discharge.date)) {
            errors.discharge = { date: 'Wrong format. Bizarre!' };
          }
          break;

        case 'OccupationalHealthcare':
          if (!values.employerName) { errors.employerName = requiredError; }

          if (values.sickLeave?.startDate && !isValidDate(values.sickLeave?.startDate)) {
            errors.sickLeave = { startDate: 'Wrong format. Bizarre!' };
          }

          if (values.sickLeave?.endDate && !isValidDate(values.sickLeave?.endDate)) {
            errors.sickLeave = { endDate: 'Wrong format. Bizarre!' };
          }

          break;
      }

      return errors;
    },
  });

  // useEffect(() => {
  //   console.log('>>> FORMIK:', formik);
  //   console.log('>>> FORMIK VALUES:', formik.values);
  //   console.log('>>> FORMIK TOUCHED:', formik.touched);
  //   console.log('>>> FORMIK ERRORS:', formik.errors);
  // }, [formik])

  function getError(key1: string, key2: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const touObj = (formik.touched as any)[key1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errObj = (formik.errors as any)[key1];
    return touObj != null && touObj[key2] && errObj != null && errObj[key2] != null && Boolean(errObj[key2]);
  }

  // function getErrorTxt(key1: string, key2: string) {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   const errObj = (formik.errors as any)[key1];
  //   return errObj != null && errObj[key2] != null && errObj[key2];
  // }

  return <form onSubmit={formik.handleSubmit}>
    <View style={{ width: '100%' }}>
      <View style={{ width: '100%' }}>
        <DropDown
          label='Entry type'
          mode='outlined'
          value={formik.values.type}
          setValue={val => {
            formik.setFieldValue('type', val);
            formik.setFieldTouched('type', true);
          }}
          list={entryTypeOptions}
          visible={showDropDown}
          showDropDown={() => setShowDropDown(true)}
          onDismiss={() => setShowDropDown(false)}
        />
      </View>
      <View style={{ width: '100%', marginTop: 6 }}>
        <TextInput
          mode='outlined'
          label='Description'
          multiline numberOfLines={4}
          value={formik.values.description}
          onBlur={formik.handleBlur('description')}
          onChangeText={formik.handleChange('description')}
          error={formik.touched.description && Boolean(formik.errors.description)}
          // helperText={formik.touched.description && formik.errors.description}
        />
      </View>
      <View style={{ width: '100%', marginTop: 6 }}>
        <DatePickerInput
          locale='en'
          label='Date'
          value={new Date(formik.values.date)}
          onChange={d => {
            if (d == null) return;
            formik.setFieldTouched('date', true);
            formik.setFieldValue('date', d.toISOString());
          }}
          inputMode='start'
          mode='outlined'
          error={formik.touched.date && Boolean(formik.errors.date)}
          // helperText={formik.touched.date && formik.errors.date}
        />
      </View>
      <View style={{ width: '100%' }}>
        <TextInput
          mode='outlined'
          label='Specialist'
          value={formik.values.specialist}
          onBlur={formik.handleBlur('specialist')}
          onChangeText={formik.handleChange('specialist')}
          error={formik.touched.specialist && Boolean(formik.errors.specialist)}
          // helperText={formik.touched.specialist && formik.errors.specialist}
        />
      </View>
      {formik.values.type !== '' && <Divider style={{ marginTop: 12, marginBottom: 6 }} />}
      {formik.values.type === 'HealthCheck' && <View style={{ width: '100%' }}>
        <View style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
          <Subheading>Health Check Rating</Subheading>
          <View style={{ flexDirection: 'row' }}>
            <ToggleButton.Group
              onValueChange={formik.handleChange('healthCheckRating')}
              value={`${formik.values.healthCheckRating}`}
            >
              <ToggleButton icon='numeric-0-circle-outline' value='0' />
              <ToggleButton icon='numeric-1-circle-outline' value='1' />
              <ToggleButton icon='numeric-2-circle-outline' value='2' />
              <ToggleButton icon='numeric-3-circle-outline' value='3' />
            </ToggleButton.Group>
          </View>
        </View>
      </View>}
      {formik.values.type === 'Hospital' && <View style={{ width: '100%' }}>
        <View style={{ width: '100%', flexDirection: 'column' }}>
          <DatePickerInput
            locale='en'
            label='Discharge Date'
            value={
              formik.values.discharge == null || formik.values.discharge?.date == null ||
                typeof formik.values.discharge.date !== 'string' || !isValid(new Date(formik.values.discharge.date))
                ? new Date()
                : new Date(formik.values.discharge.date)
            }
            onChange={d => {
              if (d == null) return;
              formik.setFieldTouched('discharge.date', true);
              formik.setFieldValue('discharge.date', d.toISOString());
            }}
            inputMode='start'
            mode='outlined'
            error={formik.touched.discharge?.date && Boolean(formik.errors.discharge?.date)}
            // helperText={formik.touched.date && formik.errors.date}
          />
          <TextInput
            mode='outlined'
            label='Discharge Criteria'
            value={formik.values.discharge?.criteria}
            onBlur={formik.handleBlur('discharge.criteria')}
            onChangeText={formik.handleChange('discharge.criteria')}
            error={Boolean(getError('discharge', 'criteria'))}
            // helperText={getErrorTxt('discharge', 'criteria')}
          />
        </View>
      </View>}
      {formik.values.type === 'OccupationalHealthcare' && <View style={{ width: '100%' }}>
        <TextInput
          mode='outlined'
          label='Employer Name'
          value={formik.values.employerName}
          onBlur={formik.handleBlur('employerName')}
          onChangeText={formik.handleChange('employerName')}
          error={formik.touched.employerName && Boolean(formik.errors.employerName)}
          // helperText={formik.touched.specialist && formik.errors.specialist}
        />
        <Subheading style={{ marginTop: 12 }}>Sick Leave</Subheading>
        <DatePickerInput
          locale='en'
          label='Start Date'
          value={
            formik.values.sickLeave == null || formik.values.sickLeave?.startDate == null ||
              typeof formik.values.sickLeave.startDate !== 'string' || !isValid(new Date(formik.values.sickLeave.startDate))
              ? new Date()
              : new Date(formik.values.sickLeave.startDate)
          }
          onChange={d => {
            if (d == null) return;
            formik.setFieldTouched('sickLeave.startDate', true);
            formik.setFieldValue('sickLeave.startDate', d.toISOString());
          }}
          inputMode='start'
          mode='outlined'
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error={formik.errors.sickLeave && (formik.errors.sickLeave as any).startDate && Boolean((formik.errors.sickLeave as any).startDate)}
          // helperText={formik.touched.startDate && formik.errors.startDate}
        />
        <DatePickerInput
          locale='en'
          label='End Date'
          value={
            formik.values.sickLeave == null || formik.values.sickLeave?.endDate == null ||
              typeof formik.values.sickLeave.endDate !== 'string' || !isValid(new Date(formik.values.sickLeave.endDate))
              ? new Date()
              : new Date(formik.values.sickLeave.endDate)
          }
          onChange={d => {
            if (d == null) return;
            formik.setFieldTouched('sickLeave.endDate', true);
            formik.setFieldValue('sickLeave.endDate', d.toISOString());
          }}
          inputMode='start'
          mode='outlined'
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error={formik.errors.sickLeave && (formik.errors.sickLeave as any).endDate && Boolean((formik.errors.sickLeave as any).endDate)}
          // helperText={formik.touched.date && formik.errors.date}
        />
      </View>}
      <Divider style={{ marginTop: 12, marginBottom: 6 }} />
      <View style={{ width: '100%' }}>
        <DiagnosisSelection
          value={formik.values.diagnosisCodes || []}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) => {
            const { target: { value } } = event;
            const newVal = typeof value === 'string' ? value.split(',') : value;
            formik.setFieldTouched('diagnosisCodes', true);
            formik.setFieldValue('diagnosisCodes', newVal);
          }}
          diagnoses={clientEmrState.diagnoses}
          // error={formik.touched.diagnosisCodes && Boolean(formik.errors.diagnosisCodes)}
          // helperText={formik.touched.diagnosisCodes && formik.errors.diagnosisCodes}
        />
      </View>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-around', marginTop: 24 }}>
        <Button onPress={onCancel}>cancel</Button>
        <Button onPress={() => formik.submitForm()}>save</Button>
        {/* <Button type='submit'>save</Button> */}
      </View>
    </View>
  </form>
}
