import { Group } from "./generated/graphql"; 

export function hasProperGroups(from: Group[], list: Group[]) {
  if (from && from.length !== 0) {
    if (!list || list.length === 0) {
      return true;
    }
    return list.some((g) => from.includes(g));
  }
  return false;
}
