// import styles from './generic-modal-expo.module.css';
import React from 'react';
import { StyleSheet, View, Modal } from 'react-native';
import { Title } from 'react-native-paper';

// TODO FE: import the prop interface from shared ui lib
export interface GenericModalProps {
  open: boolean;
  onClose?: () => void;
  text?: string;
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

export function GenericModal({ open, onClose, text, width, height, children }: GenericModalProps) {
  return <Modal
    transparent={true}
    visible={open}
    onDismiss={onClose}
  >
    <View style={styles.modal}>
      <View style={[styles.modalContent, { minWidth: width || 300, minHeight: height || 300 }]}>
        <View style={{ justifyContent: 'center', padding: 12 }}>
          {text && <Title style={{ textAlign: 'center', color: 'grey' }}>{text}</Title>}
          {children}
        </View>
      </View>
    </View>
  </Modal>
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  modalContent: {
    borderRadius: 10,
    border: '2px #ccc solid',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
});
