/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from 'react';
//
// import {
//   Geoloc, Geolocs,
//   // Queries
//   GetMyGeolocQueryVariables, GetMyGeolocDocument,
//   ScanAreaQueryVariables, ScanAreaDocument,
//   // Mutations
//   MyPosAndInfoMutationVariables, MyPosAndInfoDocument, GeolocationPositionInput,
//   UpdateMyInfoMutationVariables, UpdateMyInfoDocument,
//   UpdateMyPosMutationVariables, UpdateMyPosDocument,
//   // Subscriptions
//   SubscribeToNewMessageSubscriptionVariables, SubscribeToNewNearBysDocument,
// } from '@backend/core';
import { geolocStore, useLocation } from '../../..';
// import { useInterval } from './timerhooks';

export interface GeoUser extends GeolocationPosition {
  cid: string;
  myTag: string;
}

export function Scanner() {
  const { getGeolocApiClient, geolocState, addNewGeoloc } = useContext(geolocStore);
  const [geolocs, setGeoloc] = useState<GeoUser[]>([]);
  const { position } = useLocation({});

  // useInterval(() => scanMyArea(), 10000);
  useEffect(() => {
    // The busy below is a bit risky if the gap is big betweem the first to last
    // position update. Might miss the last one but it is not likely.
    if (position == null || geolocState.busy) return;

    if (geolocState.geolocHisto.length < 1) {
      addNewGeoloc(position as GeolocationPosition);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  async function scanMyArea() {
    const geolocs = await getGeolocApiClient()?.scanArea({
      geoloc: {
        accuracy: 1,
        // altitude: Float
        // altitudeAccuracy: Float
        // heading: Float
        latitude: 0,
        longitude: 0,
        // speed: Float      
      },
      radius: 200,
    });
    const conv = geolocs?.geolocs.map((g: any) => ({
      cid: g.cognitoId,
      myTag: g.myTag,
      coords: g.coords,
      timestamp: g.ts,
    })) as GeoUser[];
    setGeoloc(conv || []);
  }

  return { geolocs, scanMyArea };
}
