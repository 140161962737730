/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useReducer, Dispatch, useRef, useEffect } from 'react';
// INTEG import { GraphQLAPI } from '@aws-amplify/api-graphql';
//
// INTEG import { Geoloc } from '../../backend/core';
// INTEG import { TZ_AMERICA_MONTREAL } from '@eksoh/shared/model'
import { authStore } from '../../..';
// INTEG import { GeolocApiClient } from '../../backend/services/geoloc/client';

const HISTO_SIZE = 5;

// #region Actions

export enum eGeolocActions {
  SET_BUSY,
  SET_MY_TAG, SET_EXTRA_INFO,
  ADD_NEW_GEOLOC, CLEAR_MY_GEOLOC,
}

interface SetBusyAction {
  type: typeof eGeolocActions.SET_BUSY;
  state: boolean;
}

interface SetMyTagAction {
  type: typeof eGeolocActions.SET_MY_TAG;
  myTag: any;
}

interface SetExtraInfoAction {
  type: typeof eGeolocActions.SET_EXTRA_INFO;
  extraInfo?: any;
}

interface AddNewGeolocAction {
  type: typeof eGeolocActions.ADD_NEW_GEOLOC;
  geoloc: GeolocationPosition;
}

interface ClearMyGeolocAction {
  type: typeof eGeolocActions.CLEAR_MY_GEOLOC;
}

export type GeolocActionTypes = SetBusyAction | SetExtraInfoAction | SetMyTagAction |
  AddNewGeolocAction | ClearMyGeolocAction;

// #endregion Actions

// #region Reducer

export interface IGeolocState {
  busy: boolean;
  myTag: string;
  extraInfo?: any; // TODO: type me
  geolocHisto: GeolocationPosition[];
}

const initialState: IGeolocState = {
  busy: false,
  myTag: Math.random().toString(36).slice(2, 7),
  geolocHisto: [],
}

export interface IGeolocContext {
  geolocState: IGeolocState;
  dispatch: Dispatch<any>;
  getGeolocApiClient: () => any; // INTEG GeolocApiClient | undefined;
  setMyTag: (myTag: string) => void;
  setExtraInfo: (extraInfo?: any) => void;
  addNewGeoloc: (pos: GeolocationPosition) => void;
}

export const geolocStore = createContext<IGeolocContext>({
  geolocState: initialState,
  dispatch: () => null,
  getGeolocApiClient: () => { throw new Error('Not implemented.'); },
  setMyTag: () => { throw new Error('Not implemented.'); },
  setExtraInfo: () => { throw new Error('Not implemented.'); },
  addNewGeoloc: () => { throw new Error('Not implemented.'); },
});

function reducer(state: IGeolocState, action: GeolocActionTypes): IGeolocState {
  switch (action.type) {
    case eGeolocActions.SET_BUSY:
      return { ...state, busy: action.state };
    case eGeolocActions.SET_MY_TAG:
      return { ...state, myTag: action.myTag };
    case eGeolocActions.SET_EXTRA_INFO:
      return { ...state, extraInfo: action.extraInfo };
    case eGeolocActions.ADD_NEW_GEOLOC:
      return { ...state, geolocHisto: [action.geoloc, ...state.geolocHisto].splice(0, HISTO_SIZE) };
    case eGeolocActions.CLEAR_MY_GEOLOC:
      return { ...state, geolocHisto: [] };
    default:
      return state;
  }
}

// #endregion Reducer

export interface GeolocationPros {
  children: React.ReactNode | React.ReactNode[];
}

export function Geolocation(props: GeolocationPros) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { authState } = useContext(authStore);
  const [geolocState, dispatch] = useReducer(reducer, initialState);
  const geolocApiClient = useRef<any>(); // INTEG useRef<GeolocApiClient>();

  useEffect(() => {
    // INTEG geolocApiClient.current = new GeolocApiClient(GraphQLAPI, authState.jwtToken, TZ_AMERICA_MONTREAL);
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region Internal Functions

  async function init() {
    if (geolocApiClient.current == null) return;
    let myInfo: any; // INTEG Geoloc;
    try {
      myInfo = await geolocApiClient.current.getMyGeoloc({});
      if (myInfo?.myTag) dispatch({ type: eGeolocActions.SET_MY_TAG, myTag: myInfo.myTag });
      else setMyTag(geolocState.myTag);  // Make a tmp one
      if (myInfo?.extra) dispatch({ type: eGeolocActions.SET_EXTRA_INFO, extraInfo: myInfo.extra });
    }
    catch (error) {
      console.log('error getting my geoloc:', error);
    }
  }

  // #endregion Internal Functions

  // #region Functions

  function getGeolocApiClient() { return geolocApiClient.current; }

  async function setMyTag(myTag: string) {
    try {
      if (geolocApiClient.current != null) {
        await geolocApiClient.current.updateMyInfo(myTag, geolocState.extraInfo);
      }
    }
    catch (error) {
      console.log('error: ', error);
    }
    dispatch({ type: eGeolocActions.SET_MY_TAG, myTag });
  }

  async function setExtraInfo(extraInfo: any) {
    try {
      if (geolocApiClient.current != null) {
        await geolocApiClient.current.updateMyInfo(geolocState.myTag, extraInfo);
      }
    }
    catch (error) {
      console.log('error: ', error);
    }
    dispatch({ type: eGeolocActions.SET_EXTRA_INFO, extraInfo });
  }

  async function addNewGeoloc(geoloc: GeolocationPosition) {
    dispatch({ type: eGeolocActions.SET_BUSY, state: true });
    try {
      if (geolocApiClient.current != null) {
        await geolocApiClient.current.updateMyPos(geoloc);
      }
    }
    catch (error) {
      console.log('error: ', error);
    }
    dispatch({ type: eGeolocActions.ADD_NEW_GEOLOC, geoloc });
    dispatch({ type: eGeolocActions.SET_BUSY, state: false });
  }

  // #endregion Functions

  return <geolocStore.Provider value={{
    geolocState, dispatch, getGeolocApiClient, setMyTag, setExtraInfo, addNewGeoloc,
  }}>
    {props.children}
  </geolocStore.Provider>
}
