import { IVitalSigns as vitalSigns, schemaData as vitalSignsData } from './VitalSignsData';

// Will be driven by ML
export enum ConsultationReason {
  'reason1'='reason1', 'reason2'='reason2', 'reason3'='reason3', 'reason4'='reason4',
  'reason5'='reason5', 'reason6'='reason6', 'reason7'='reason7', 'reason8'='reason8',
  'reason9'='reason9', 'reason10'='reason10', 'reason11'='reason11', 'reason12'='reason12',
  'other'='other',
};

export enum SubjectiveSuggestion {
  'subjective1'='subjective1', 'subjective2'='subjective2', 'subjective3'='subjective3', 'subjective4'='subjective4',
  'subjective5'='subjective5', 'subjective6'='subjective6', 'subjective7'='subjective7', 'subjective8'='subjective8',
  'subjective9'='subjective9', 'subjective10'='subjective10', 'subjective11'='subjective11', 'subjective12'='subjective12',
};

export enum ObjectiveSuggestion {
  'objective1'='objective1', 'objective2'='objective2', 'objective3'='objective3', 'objective4'='objective4',
  'objective5'='objective5', 'objective6'='objective6', 'objective7'='objective7', 'objective8'='objective8',
  'objective9'='objective9', 'objective10'='objective10', 'objective11'='objective11', 'objective12'='objective12',
};

export interface Consultation {
  date: string;
  place?: string;
  reason: ConsultationReason;
  otherReason?: string;
  subjective: string;
  objective: string;
  vitalSigns: vitalSigns;
  labResults: string;
  diagnostic: string;
  treatmentPlan: string;
  prescriber: string;
  locked?: boolean;
  butvalid?: boolean;
}

export const consultation: Consultation = {
  date: (new Date()).toISOString(),
  reason: ConsultationReason.other,
  otherReason: '',
  subjective: '',
  objective: '',
  vitalSigns: vitalSignsData,
  labResults: '',
  diagnostic: '',
  treatmentPlan: '',
  prescriber: '',
}

export interface ISchemaData {
  idxSelection?: number;
  consultations: Consultation[];
}

export const schemaData: ISchemaData = {
  consultations: [],
}
