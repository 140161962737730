import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// third-party
import Chart from 'react-apexcharts';

// project imports
import MainCard from './main-card';
import { EarningCard2 as SkeletonTotalOrderCard } from './skeleton/earning-card2';

import ChartDataMonth from './chart-data/total-order-month-line-chart';
import ChartDataYear from './chart-data/total-order-year-line-chart';

// assets
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.primary.main, // [800],
    borderRadius: '50%',
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 210,
    height: 210,
    background: theme.palette.primary.main, // [800],
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

// ==============================|| DASHBOARD - TOTAL ORDER LINE CHART CARD ||============================== //

export function EarningCard2({ isLoading }: { isLoading: boolean }) {
  const theme = useTheme();

  const [timeValue, setTimeValue] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTime = (event: any, newValue: any) => {
    setTimeValue(newValue);
  };

  return isLoading
    ? <SkeletonTotalOrderCard />
    : <CardWrapper border={false} content={false}>
      <Box sx={{ p: 2.25 }}>
        <Grid container direction='column'>
          <Grid item>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Avatar
                  variant='rounded'
                  sx={{
                    // ...theme.typography.commonAvatar,
                    // ...theme.typography.largeAvatar,
                    backgroundColor: theme.palette.primary.main, // [800],
                    color: '#fff',
                    mt: 1
                  }}
                >
                  <FavoriteIcon fontSize='inherit' />
                </Avatar>
              </Grid>
              <Grid item>
                <Button
                  disableElevation
                  variant={timeValue ? 'contained' : 'text'}
                  size='small'
                  sx={{ color: 'inherit' }}
                  onClick={(e) => handleChangeTime(e, true)}
                >
                  Mois
                </Button>
                <Button
                  disableElevation
                  variant={!timeValue ? 'contained' : 'text'}
                  size='small'
                  sx={{ color: 'inherit' }}
                  onClick={(e) => handleChangeTime(e, false)}
                >
                  Année
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mb: 0.75 }}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <Grid container alignItems='center'>
                  <Grid item>
                    {timeValue ? (
                      <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                        180
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                        172
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        // ...theme.typography.smallAvatar,
                        cursor: 'pointer',
                        backgroundColor: theme.palette.primary.main, // [200],
                        color: theme.palette.primary.dark
                      }}
                    >
                      <ArrowDownwardIcon fontSize='inherit' sx={{ transform: 'rotate3d(1, 1, 1, 45deg)' }} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: theme.palette.primary.light, // [200]
                      }}
                    >
                      moyenne
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {timeValue ? <Chart {...ChartDataMonth} /> : <Chart {...ChartDataYear} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
}
