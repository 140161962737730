/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { rankWith, scopeEndsWith, RankedTester, ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

export const butvalidControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  scopeEndsWith('butvalid')
);

function ButValidControl({ visible, data, handleChange, path }: ControlProps) {
  if (!visible) return null;

  return <Box display='flex' justifyContent='flex-end' my={1}>
    <Button
      variant='outlined' startIcon={data ? <CheckCircleIcon /> : undefined}
      onClick={() => handleChange(path, !data)}
    >
      {data ? 'validated' : 'validate' }
    </Button>
  </Box>
}

export default withJsonFormsControlProps(ButValidControl);
