import { useContext, useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { Surface, Title, Switch, IconButton, Menu, /* ToggleButton, Divider */ } from 'react-native-paper';
import { changeLanguage } from 'i18next';
import i18n from 'i18next';
//
import { authStore, globalStore } from '@eksoh/shared/ui';

export interface DebugPanelProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any;
  mt?: number | string;
  mb?: number | string;
}

export function DebugPanel({ navigation, mt, mb }: DebugPanelProps) {
  const { globalState } = useContext(globalStore);
  const { onboarding } = useContext(authStore);
  const [en, setEn] = useState(false);
  const [dbgMenu, setDbgMenu] = useState(false);

  useEffect(() => {
    if (i18n.language.substring(0, 2) === 'en') setEn(true);
    else setEn(false);
  }, []);

  useEffect(() => {
    if (!en && i18n.language.substring(0, 2) === 'en') changeLanguage('fr');
    else if (en && i18n.language.substring(0, 2) !== 'en') changeLanguage('en');
  }, [en]);

  function debugCall(d: 'cnd' | 's' | 'p') {
    if (d === 'cnd') {
      onboarding(undefined, undefined, true);
    }
    else if (d === 's') {
      changeLanguage(en ? 'en' : 'fr', () =>
        navigation.navigate('TelemedNav', { screen: 'SurveyRoom' })
      );
    }
    else if (d === 'p') {
      changeLanguage(en ? 'en' : 'fr', () =>
        navigation.navigate('PreClinicNav', { screen: 'UseSMS' })
      );
    }
    setDbgMenu(false)
  }

  if (!globalState.isDev) return null;

  return <Surface style={{ padding: 12, borderRadius: 10, marginTop: mt || 0, marginBottom: mb || 18, width: '100%', maxWidth: 300 }}>
    <View style={{ width: '100%', alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Text style={{ width: '10%' }}> </Text>
        <Title style={{ textAlign: 'center', width: '80%' }}>DEBUG SECTION</Title>
        <Menu
          visible={dbgMenu} // style={{ width: '10%' }}
          onDismiss={() => setDbgMenu(false)}
          anchor={<IconButton icon='dots-vertical' style={{ margin: 0 }} onPress={() => setDbgMenu(true)} />}
        >
          <Menu.Item onPress={() => debugCall('cnd')} title='clear nurse data' />
          {/* <Menu.Item onPress={() => debugCall('s')} title='survey' />
          <Divider />
          <Menu.Item onPress={() => debugCall('p')} title='pre-clinic' /> */}
        </Menu>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={{ marginRight: 12 }}>fr</Text>
        <Switch style={{ marginBottom: 12 }} value={en} onValueChange={() => setEn(!en)} />
        <Text style={{ marginLeft: 12 }}>en</Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}>
        <IconButton icon='account-circle' style={{ margin: 0 }} onPress={() => navigation.push('ClientDashboard')} />
        <IconButton icon='room-service-outline' style={{ margin: 0 }} onPress={() => navigation.push('CmsDashboard')} />
        <IconButton icon='domain' style={{ margin: 0 }} onPress={() => navigation.push('ResidenceDashboard')} />
        <IconButton icon='bug' style={{ margin: 0 }} onPress={() => navigation.push('Debug')} />
      </View>
      {/* <Text>MANUAL ROOM. PATIENT #</Text>
      <ToggleButton.Row onValueChange={value => navigation.navigate('ManualRoom', { params: { patientidx: value, locale: en ? 'en' : 'fr' } })} value=''>
        <ToggleButton icon='numeric-1' value='1' />
        <ToggleButton icon='numeric-2' value='2' />
        <ToggleButton icon='numeric-3' value='3' />
        <ToggleButton icon='numeric-4' value='4' />
      </ToggleButton.Row> */}
    </View>
  </Surface>
}