import { useContext } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
//
import { clientEmrStore } from '../../../..';

export default function Labels() {
  const { clientEmrState, updateLabel } = useContext(clientEmrStore);

  return <List
    subheader={<ListSubheader component='div' id='nested-list-subheader'>labels</ListSubheader>}
    sx={{ width: '100%' }}
  >
    {clientEmrState.labels.map(({ label: lbl, checked }, idx) => <ListItem
      key={'label_' + idx}
      disablePadding
    >
      <ListItemButton role={undefined} onClick={() => updateLabel({ label: lbl, checked: !checked })} dense>
        <ListItemIcon>
          <Checkbox
            edge='start'
            checked={checked}
            tabIndex={-1}
            disableRipple
            // inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <Chip label={lbl} sx={{ color: lbl }} />
        {/* <ListItemText id={lbl} primary={lbl} primaryTypographyProps={{ color: lbl, border: `1px solid ${lbl}` }} /> */}
      </ListItemButton>
    </ListItem>)}
  </List>
}
