/* eslint-disable @typescript-eslint/no-explicit-any */

import { useContext } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { Button } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { BeServices, authStore, globalStore, EmrUI } from '@eksoh/shared/ui';
import { Waiting } from '@eksoh/shared/ui-expo';
import { appMaxWidthOnboarded } from '@eksoh/flo/ui';
import { View } from '../cmps/Themed';
import { RootStackScreenProps } from '../types';
import Logo from '../cmps/Logo';
import { scale /* , moderateScale */ } from '../Scaling';
import { DebugPanel } from '../cmps/Debug';

export default function Emr({ navigation, route }: RootStackScreenProps<'Emr'>) {
  const { globalState } = useContext(globalStore);
  const { signOut } = useContext(authStore);
  const [t] = useTranslation();
  const uuid = route.params?.uuid;

  if (!uuid) return <Waiting />

  const showDev = globalState.isDev;
  return <View style={styles.container}>
    <ScrollView style={{ width: '100%', maxWidth: appMaxWidthOnboarded }}>
      <View style={{ minWidth: '100%', maxWidth: appMaxWidthOnboarded, backgroundColor: 'transparent', alignItems: 'center' }}>
        <Logo style={{ marginTop: scale(1) }} width={12} height={12} />
        <EmrUI username={uuid} beService={BeServices.getInstance().emr} onWaiting={waiting => waiting ? <Waiting /> : null} />
        <Button icon='home' mode='outlined' onPress={() => navigation.push('Root')} style={{ marginTop: 20 }}>
          {/* {t('shorts.logout')} */}
          home
        </Button>
        <Button icon='logout' mode='outlined' onPress={signOut} style={{ marginTop: 20 }}>
          {t('shorts.logout')}
        </Button>
        {showDev && <DebugPanel navigation={navigation} mt={40} />}
      </View>
    </ScrollView>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'rgb(229, 229, 229)',
  },
  title: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
