import React from 'react';
import { View } from 'react-native';
import { Button, Card } from 'react-native-paper';
//
import { FFLayout } from './layout';

export interface FFBinaryProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  leftButText: string;
  rightButText: string;
  onSelect: (selected: string) => void;
  onBack?: () => void;
}

export function FFBinary(props: FFBinaryProps) {
  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      onBack={props.onBack}
      action={<Card.Actions>
        <Button mode='outlined' style={{ width: '40%', margin: '5%' }} onPress={() => props.onSelect(props.leftButText)}>
          {props.leftButText}
        </Button>
        <Button mode='outlined' style={{ width: '40%', margin: '5%' }} onPress={() => props.onSelect(props.rightButText)}>
          {props.rightButText}
        </Button>
      </Card.Actions>}
    />
  </View>
}
