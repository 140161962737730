import { useContext, useEffect } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Subheading } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import Logo from '../cmps/Logo';
import usePickDocument from '../hooks/usePickDocument';
import useUploadFile from '../hooks/useUploadFile';
import useVoiceRecording from '../hooks/useVoiceRecording';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';

export default function Upload({ navigation }: RootStackScreenProps<'Upload'>) {
  const { authState, onboarding } = useContext(authStore);
  const { doc, renderPickDoc } = usePickDocument();
  const { uploading, upload, renderUplFile } = useUploadFile({ alwaysRender: true });
  const { recFile, renderVoiceRecAudioCv, renderAudioCvModal } = useVoiceRecording({});
  const [t] = useTranslation();

  useEffect(() => {
    if (!authState.isSignedIn) navigation.push('Root');
    if (authState.onboarding?.regDocs?.done) navigation.push('ValidateAccount');
  }, [authState.isSignedIn, authState.onboarding?.regDocs, navigation]);

  useEffect(() => {
    if (doc != null) {
      // console.log('>>> DOC:', doc);
      upload(
        doc,
        `resume_${doc.name}`,
        () => onboarding({ regDocs: {  ...authState.onboarding?.regDocs, hasResume: true, done: false } }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  useEffect(() => {
    if (recFile != null) {
      // console.log('>>> REC FILE:', recFile);
      upload(recFile, recFile.name, () => onboarding({ regDocs: {  ...authState.onboarding?.regDocs, hasVoice: true, done: false } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recFile]);

  function next() {
    onboarding({ regDocs: { ...authState.onboarding?.regDocs, done: true } })
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={10} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <Logo style={{ marginTop: scale(6) }} width={60} height={60} />
      <Subheading style={{ marginTop: scale(12), textAlign: 'center' }}>{t('nurse.upload')}</Subheading>
      <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 24 }}>
        {renderPickDoc('nurse.uploadResume', authState.onboarding?.regDocs?.hasResume ? 'blue' : undefined)}
      </View>
      <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 8 }}>
        {renderVoiceRecAudioCv(authState.onboarding?.regDocs?.hasVoice ? 'blue' : undefined)}
      </View>
      <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 30, marginBottom: 12 }}>
        {renderUplFile()}
      </View>
    </ScrollView>
    <OnboardingFooter
      disabledBut={(!authState.onboarding?.regDocs?.hasResume && !authState.onboarding?.regDocs?.hasVoice) || uploading}
      onNext={() => next()} hideLater
    />
    {renderAudioCvModal()}
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
});
