/* eslint-disable @typescript-eslint/no-explicit-any */
// import styles from './select-products.module.css';
import { ChangeEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
//
import { Product } from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER

type ProductsBools = { [key in Product as string]: boolean };
const initDataGen: any = {};
Object.keys(Product).filter(k => typeof k === 'string').forEach(k => initDataGen[k] = false);
const initData: ProductsBools = initDataGen;
const productsKeys = Object.keys(Product);

export function listProducts(products: Product[], isAdmin?: boolean) {
  return isAdmin
    ? products.map(p => Product[p]).join(', ')
    : products.map(p => Product[p]).join(', ');
}

export interface SelectProductsProps {
  products: Product[];
  onClose: () => void;
  onSubmit: (products: Product[]) => void;
}

export function SelectProducts({ products, onClose, onSubmit }: SelectProductsProps) {
  const [prods, setProds] = useState(initData);
  // const error = false;

  useEffect(() => {
    const newProds = { ...prods };
    products.forEach(p => { if (productsKeys.includes(p)) newProds[p] = true; });
    setProds(newProds);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box display='flex' flexDirection='column' sx={{ backgroundColor: 'white' }}>
    <Box display='flex'>
      <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
        <FormLabel component='legend'>products</FormLabel>
        <FormGroup>
          {Object.keys(prods).filter(k => productsKeys.includes(k)).map((k, i) => <FormControlLabel key={'cb_prod' + i} label={k}
            control={
              <Checkbox name={k} checked={prods[k]}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setProds({
                    ...prods,
                    [event.target.name]: event.target.checked,
                  });
                }}
              />
            }
          />)}
        </FormGroup>
        {/* <FormHelperText>you can display an error</FormHelperText> */}
      </FormControl>
    </Box>
    <Box display='flex' width='100%' justifyContent='center'>
      <Button onClick={onClose}>cancel</Button>
      <Button onClick={() => {
        onSubmit(Object.keys(prods).filter(g => prods[g]) as Product[]);
      }}>
        submit
      </Button>
    </Box>
  </Box>
}

//
// ===
//

export interface SelectProductsModalProps extends SelectProductsProps {
  open: boolean;
  onClose: () => void;
}

export function SelectProductsModal({ open, onClose, ...rest }: SelectProductsModalProps) {
  return <Dialog
    // fullWidth={true | false}
    // maxWidth={'xs' | 'sm' | 'md' | 'lg' | 'xl'}
    open={open}
    onClose={onClose}
  >
    <SelectProducts onClose={onClose} {...rest} />
  </Dialog>
}
