import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//
import { OnboardingInfo, TimeZones, NurseTitles, OnboardingData, LangLevels } from '@eksoh/flo/model';

// TODO: convert from number to enum in libs/flo/model/src/lib/users/onboarding-data.ts
//       (also do it in apps/flo/expo-app/src/app/screens/DisplType.tsx)
enum Transports { FOOT, CAR }
// TODO: convert from number to enum in libs/flo/model/src/lib/users/onboarding-data.ts
//       (also do it in apps/flo/expo-app/src/app/screens/DisplType.tsx)
enum Mission { TELEMED, PRESENT, WHATEVER }

export interface RegistrationProps {
  data: OnboardingInfo;
  onbData: OnboardingData;
}

export function Registration({ data, onbData }: RegistrationProps) {
  const address = useMemo(() => {
    // if (data.user.address?.formatted != null) return data.user.address?.formatted;
    if (onbData == null || onbData.missionDistance?.postal == null) return 'unknown';
    return `postal code: ${onbData.missionDistance.postal}`;
  }, [onbData]); // , data.user.address?.formatted]);

  const nurseType = useMemo(() => {
    if (onbData == null || onbData?.personelInfo?.nurseType == null) return 'unknown';
    return NurseTitles[onbData.personelInfo.nurseType] || 'unknown';
  }, [onbData]);

  const ooiq = useMemo(() => {
    if (onbData == null || onbData?.professionalInfo?.OIIQ == null) return 'unknown';
    return onbData.professionalInfo.OIIQ;
  }, [onbData]);

  const misType = useMemo(() => {
    if (onbData == null || onbData?.missionType?.misType == null) return 'unknown';
    return Mission[onbData.missionType.misType] || 'unknown';
  }, [onbData]);

  const transport = useMemo(() => {
    if (onbData == null || onbData?.displacementMeans?.transport == null) return 'unknown';
    return Transports[onbData.displacementMeans.transport] || 'unknown';
  }, [onbData]);

  const french = useMemo(() => {
    if (onbData == null || onbData?.personelInfo?.french == null) return 'unknown';
    return LangLevels[onbData.personelInfo.french] || 'unknown';
  }, [onbData]);

  const english = useMemo(() => {
    if (onbData == null || onbData?.personelInfo?.english == null) return 'unknown';
    return LangLevels[onbData.personelInfo.english] || 'unknown';
  }, [onbData]);

  const otherLang = useMemo(() => {
    return onbData?.personelInfo?.extraLangs || 'none';
  }, [onbData]);

  const availability = useMemo(() => {
    const range = (onbData.calendar?.range || {}) as TimeZones;
    const rangeStr = Object.keys(range).filter(r => range[r as keyof TimeZones]).map(r => r.toLowerCase()).join(', ');
    return `${onbData.calendar?.hours || 'unknown'} hours-ish - days: ${rangeStr} `;
  }, [onbData]);

  return <>
    <Typography variant='h6' gutterBottom>
      personal info
    </Typography>
    <Grid container spacing={3}>
      {/* <Grid item xs={12} sm={4}>
        <TextField label='first name' fullWidth value={data.user.givenName} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label='last name' fullWidth value={data.user.familyName} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label='email' fullWidth value={onbData?.email || ''} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label='phone' fullWidth value={onbData?.phone || ''} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={8}> */}
      <Grid item xs={12}>
        <TextField label='address' fullWidth value={address} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
    </Grid>
    <Typography variant='h6' gutterBottom mt={2}>
      nurse info
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={6} sm={2}>
        <TextField label='nurse title' fullWidth value={nurseType} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label='ooiq / oiiaq' fullWidth value={ooiq} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label='french' fullWidth value={french} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label='english' fullWidth value={english} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label='other langs' fullWidth value={otherLang} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label='mission type' fullWidth value={misType} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label='transport' fullWidth value={transport} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField label='distance (km)' fullWidth value={onbData.missionDistance?.dist || 'unknown'} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label='availability' fullWidth value={availability} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
    </Grid>
  </>
}