/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

// ***********************************************
// ***********************************************
// this is if we want a RTE editor for styled text
// ***********************************************
// ***********************************************

import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import MUIRichTextEditor from 'mui-rte';
import { rankWith, scopeEndsWith, RankedTester } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
// import { EditorState, convertToRaw } from 'draft-js';

export const paragraphControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  scopeEndsWith('paragraph')
);

// const emptyContentState = JSON.stringify(
//   convertToRaw(EditorState.createEmpty().getCurrentContent())
// );

const myTheme = createTheme({
  // Set up your custom MUI theme here
  components: {
    // @ts-ignore
    MUIRichTextEditor: {
      root: {
        width: '100%',
      },
      editor: {
        height: '100vh',
        maxHeight: '100vh',
        overflow: 'auto',
      },
    },
  },
});

interface ParagraphControlProps {
  data: string;
  handleChange(path: string, value: any): void;
  path: string;
}

function ParagraphControl({ data }: ParagraphControlProps) {
  return <ThemeProvider theme={myTheme}>
    <Box mb={2}>
      {/* <MUIRichTextEditor
        toolbar={false}
        readOnly={true}
        defaultValue={data || emptyContentState}
      /> */}
    </Box>
  </ThemeProvider>
}

export default withJsonFormsControlProps(ParagraphControl);

//
// DEBUG -------
//

export interface TextEditorProps {
  data?: string;
  onSave: (data: string) => void;
}

export function TextEditor({ data }: TextEditorProps) {
  // const [text, setText] = useState<EditorState>();
  const [expanded, setExpanded] = useState(false);

  function toClipboard() {
    // if (navigator != null && text != null) {
    //   // navigator.clipboard.writeText(text);
    //   navigator.clipboard.writeText(JSON.stringify(convertToRaw(text.getCurrentContent())));
    // }
  }

  return <ThemeProvider theme={myTheme}>
    <Box width='100%'>
      <Box mb={2} px={2} height='60vh' style={{ overflowY: 'auto' }}>
        {/* <MUIRichTextEditor
          defaultValue={data || emptyContentState}
          onChange={state => setText(state)}
          onSave={saveData => console.log(saveData)}
        /> */}
      </Box>
      <Box display='flex' flexGrow='1 1 auto' px={2} justifyContent='flex-end'>
        <IconButton onClick={toClipboard}>
          <ContentCopyIcon />
        </IconButton>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Typography variant='body2'>
          {/* {text != null ? JSON.stringify(convertToRaw(text.getCurrentContent())) : ''} */}
        </Typography>
      </Collapse>
    </Box>
  </ThemeProvider>
}