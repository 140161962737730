import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Button } from 'react-native';
import { Subheading } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import { Video, ResizeMode, AVPlaybackStatusSuccess } from 'expo-av';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import { scale /* , moderateScale */ } from '../Scaling';
import { RootStackScreenProps } from '../types';
import Logo from '../cmps/Logo';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import { stepEnd as prevStepEnd } from './Onboarding3EmployeeInfo';

enum StepTypes { ALL, VID1 }

export const stepStart = prevStepEnd;
export const stepEnd = prevStepEnd + Object.keys(StepTypes).length - 1;

export default function Onboarding4Videos({ navigation }: RootStackScreenProps<'Onboarding4Videos'>) {
  const { authState, onboarding } = useContext(authStore);
  const [mounted, setMounted] = useState(false);
  const video = useRef<Video | null>(null);
  const [stepType, setStepType] = useState(StepTypes.ALL);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [donePlaying, setDonePlaying] = useState(false);
  const [t] = useTranslation();

  // TODO @fp: do a hook of a HOC for all screens that have a link configured for them

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user == null) navigation.push('Login');
    }, [mounted, authState.user, navigation])
  );

  useEffect(() => {
    if (mounted) return;

    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.onboarding?.videos?.watched) {
      if (authState.user == null) navigation.push('Login'); else navigation.push('Root');
    }
  }, [authState.onboarding, authState.user, navigation]);

  useEffect(() => {
    if (isPlaying) setStepType(StepTypes.VID1);
  }, [isPlaying]);

  async function weAreDone() {
    onboarding({ videos: { watched: true } });
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={stepStart + stepType + 1} onClose={() => navigation.push('Root')} step='nurse.onboarding' />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <Logo style={{ marginTop: scale(6) }} width={60} height={60} />
      <Subheading style={{ marginTop: scale(24), textAlign: 'center' }}>{t('nurse.watchVid1')}</Subheading>
      <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: scale(40), marginBottom: 24 }}>
        <Video
          ref={video}
          style={styles.video}
          source={{ uri: 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4' }}
          // useNativeControls
          resizeMode={ResizeMode.CONTAIN}
          // isLooping
          onPlaybackStatusUpdate={status => {
            if (status == null) return;
            if ((status as AVPlaybackStatusSuccess).isLoaded != null) setIsLoaded((status as AVPlaybackStatusSuccess).isLoaded);
            if ((status as AVPlaybackStatusSuccess).isPlaying != null) setIsPlaying((status as AVPlaybackStatusSuccess).isPlaying);
            if ((status as AVPlaybackStatusSuccess).didJustFinish != null) setDonePlaying((status as AVPlaybackStatusSuccess).didJustFinish);
          }}
        />
        <View style={styles.buttons}>
          <Button
            title={isPlaying ? 'Pause' : 'Play'}
            onPress={() => isPlaying ? video.current?.pauseAsync() : video.current?.playAsync()}
            disabled={!isLoaded}
          />
        </View>
      </View>
    </ScrollView>
    <OnboardingFooter disabledBut={!donePlaying} onNext={weAreDone} hideLater />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  video: {
    alignSelf: 'center',
    width: 320,
    height: 200,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
