import { useState, useEffect } from 'react';
//
// INTEG import { Notif } from '../../backend/core';
import { UseNotifInfo } from '../../..';





// Web-Push
// Public base64 to Uint
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}




export interface useNotificationProps {
  vapid?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNewNotif: (notif: any) => void; // INTEG (notif: Notif) => void;
  onRegistered?: (sub: PushSubscription) => void;
}

export function useNotification({ vapid, /* onNewNotif, */ onRegistered }: useNotificationProps) {
  const [enabled, setEnabled] = useState(false);
  const [pushToken, setPushToken] = useState('');
  const [info, setInfo] = useState<UseNotifInfo>({ os: 'react web', registered: false });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (vapid == null || !enabled) return;

    setInfo({ ...info, log: 'registering service worker...' });
    // Register a Service Worker.
    navigator.serviceWorker.register('./sw.js');

    setInfo({ ...info, log: 'service worker registered' });
    navigator.serviceWorker.ready
      .then(registration => {
        setInfo({ ...info, log: `service worker ready: ${registration.scope}` });
        // Use the PushManager to get the user's subscription to the push service.
        return registration.pushManager.getSubscription()
          .then(async (subscription) => {
            // If a subscription was found, return it.
            if (subscription) {
              setInfo({ ...info, log: `got registration: ${registration.scope}` });
              return subscription;
            }

            setInfo({ ...info, log: 'subscribing to push manager...' });
            // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
            // send notifications that don't have a visible effect for the user).
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(vapid)
            });
          })
          .catch(error => {
            throw error;
          });
      })
      .then(subscription => {
        if (onRegistered) {
          onRegistered(subscription);
          setInfo({ ...info, registered: true, log: undefined });
        }
      })
      .catch(error => {
        setError(error);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, vapid]);

  function init() {
    try {
      setInfo({ ...info, log: `init: ${enabled} ${vapid}` });
      Notification.requestPermission()
        .then((result) => {
          setInfo({ ...info, log: `permission: ${result}` });
          if (result === 'granted') {
            setEnabled(true);
            setPushToken('');
          }
        })
        .catch((error) => setError(error));
    }
    catch (error) {
      setInfo({ ...info, log: `init failed with error: ${error} (${enabled} ${vapid})` });
    }
  }

  return { init, info, error, enabled, pushToken };
}
