import { useContext, useState, useRef, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Appbar, Card, List, Avatar, Subheading, Button, IconButton } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
//
import { Text } from '../../cmps/Themed';
import { FFBoolean } from '../cmps/boolean';
import { FFBinary } from '../cmps/binary';
import { FFRadio } from '../cmps/radio';
import { FFCheckbox } from '../cmps/checkbox';
import { FFButList } from '../cmps/butlist';
import { FFInputText } from '../cmps/inputtext';
import { FFSlider, FFSliderRange } from '../cmps/slider';
import { FFTimeSlider, FFTimeRange } from '../cmps/time';
import { FFDateRange } from '../cmps/date';
//
import { UserTimeOffer, RangeInput } from '@eksoh/flo/model';
import { montreal } from '@eksoh/shared/common';
import { weekDays, toHumanTimeOffer, HumanReadableTimeOffer } from '@eksoh/shared/common';
import { BeServices, globalStore, authStore, AreYouSureModal, eYesNo, useLocation /* , eGeolocStatus */ } from '@eksoh/shared/ui';
import { WaitingModal } from '@eksoh/shared/ui-expo';
import FastFormEngine, { FFSection, FFQuestion, FFQuestionType, FFAnswerDict } from '../logic';
import { timeOffersStart } from '../logic/timeoffers-start';
import { timeOffersAdd } from '../logic/timeoffers-add';
import { timeOffersEdit } from '../logic/timeoffers-edit';
import { ffTest } from '../logic/test';

export interface FastFormUIProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  route: any;
}

export function FastFormUI({ navigation, route }: FastFormUIProps) {
  const { authState } = useContext(authStore);
  const { /* status, position, */ start } = useLocation({ notOnMount: true, doNotUpd: true });
  const [section, setSection] = useState<FFSection>();
  const [question, setQuestion] = useState<FFQuestion>();
  const [ready, setReady] = useState(false);
  const [leave, setLeave] = useState(false);
  const [del, setDel] = useState(false);
  const [busy, setBusy] = useState(false);
  // const dteTO = useRef(new FastFormEngine(myTimeOffers, onNextQuestion));
  // const dteTest = useRef(new FastFormEngine(ffTest, onNextQuestion));
  const dte = useRef<FastFormEngine>();
  const [t] = useTranslation();
  const mode = route.params?.mode;
  const k1 = route.params?.k1;
  const k2 = route.params?.k2;
  const dow = route.params?.dow;
  // const week = route.params?.week;

  useEffect(() => {
    if (mode === 'test') { dte.current = new FastFormEngine(ffTest, onNextQuestion); }
    else if (mode === 'start') { dte.current = new FastFormEngine(timeOffersStart, onNextQuestion); }
    else if (mode === 'add') { dte.current = new FastFormEngine(timeOffersAdd, onNextQuestion); }
    else if (mode === 'edit') { dte.current = new FastFormEngine(timeOffersEdit, onNextQuestion); }
    else { throw new Error('FastFormUI, unsupported mode:', mode); }
    setSection(dte.current.getCurSection());
    setQuestion(dte.current.getCurQuestion());
  }, [mode]);

  useEffect(() => {
    if (!busy || dte.current?.getAnswers() == null || authState.curGroups == null || authState.curGroups.length < 1) return;
    forFfToTimeOffer(authState.curGroups[0], dte.current.getAnswers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busy]);

  // NOTES:
  // * week devided in 5 mons = 2016 x 5 mins in a week. 0 to 2015 (base 0)
  // * if we want to expand to a month: 8064
  // * 6 months: 48384 and so on. we have room...
  async function forFfToTimeOffer(profession: string, answer: FFAnswerDict) {
    // console.log('>>> COMPUTING:', authState.user?.username, lng, lat, answer)
    if (authState.user?.username == null) return;

    if (mode === 'start') {
      const ranges: RangeInput[] = [];
      let rangeIdx = 0;
      if (answer['s1q1']) {
        const vals = Object.values(weekDays);
        for (let ii = 0; ii < vals.length; ++ii) {
          // console.log('>>> 5 MINS:', vals[ii], hour24To5Mins(answer['s1q3.0'][0][1].low), hour24To5Mins(answer['s1q3.0'][0][1].high));
          const start5mins = hour24To5Mins(answer['s1q3.0'][0][1].low);
          const end5mins = hour24To5Mins(answer['s1q3.0'][0][1].high);
          ranges.push({ start5mins: vals[ii] + start5mins, end5mins: vals[ii] + end5mins });
        }
      }
      // do we have any ranges?
      else if (answer['s1q3.0'] != null && Array.isArray(answer['s1q3.0'])) {
        // week1 to week 4
        const weekAnsIdx = [0, 2, 3, 4]; // 1 is the answer for 'are every remaining weeks of the month the same?'
        for (let weekIdx = 0; weekIdx < weekAnsIdx.length; ++weekIdx) {
          const key = `s1q2.${weekAnsIdx[weekIdx]}`;
          if (answer[key] != null && Array.isArray(answer[key])) {
            // loop days of that week
            for (let dotw = 0; dotw < answer[key].length; ++dotw) {
              const start5mins = hour24To5Mins(answer['s1q3.0'][rangeIdx][1].low);
              const end5mins = hour24To5Mins(answer['s1q3.0'][rangeIdx][1].high);
              const dowStr = (answer[key][dotw])?.toLowerCase();
              const day5mins = dowStr != null ? weekDays[dowStr] : 0;
              const month5mins = day5mins + (2016 * weekIdx);
              ranges.push({ start5mins: month5mins + start5mins, end5mins: month5mins + end5mins });
              // console.log('>>> 5 MINS:', answer[key][dotw], day5mins, month5mins, start5mins, end5mins, month5mins + start5mins, month5mins + end5mins);
              rangeIdx = rangeIdx + 1;
            }
          }
        }
      }

      if (ranges.length > 0) {
        /* const res = */ await BeServices.getInstance().schedule.createTimeOffers({
          lat: montreal.latitude, // position.coords.latitude,
          lng: montreal.longitude, // position.coords.longitude,
          username: authState.user.username,
          profession,
          ranges
        });
        // console.log('>>> CREATE TOS:', res);
      }
      else {
        console.log('>>> NO RANGES SO NO TOS CREATED...');
      }
    }

    if (mode === 'edit' && k1 && k2 && dow != null) {
        /* const res = */ await BeServices.getInstance().schedule.updateTimeOffers({
          lngLatProfession: k1,
          userIdStart5min: k2,
          start5mins: (dow * 288) + hour24To5Mins(answer['s1q1.1'].low),
          end5mins: (dow * 288) + hour24To5Mins(answer['s1q1.1'].high),
        });
        // console.log('>>> UPDATE TO:', res);
    }

    setBusy(false);
    navigation.navigate('NurseDashboard');
  }
  // Assuming minutes are a fraction of 100. i.e. 25 = 15 mins, 50 = 30 mins...
  function hour24To5Mins(time: number) {
    try {
      const h = Math.floor(time);
      const m = time - Math.floor(time);
      return (h * 12) + Math.floor(12 * m);
    }
    catch (error) {
      console.log('>>> hour24To5Mins error:', error);
      return 0;
    }
  }

  function onNextQuestion() {
    setSection(dte.current?.getCurSection());
    setQuestion(dte.current?.getCurQuestion());
    if (dte.current?.getCurQuestion().type === 'end') setBusy(true);
  }

  function renderBoolean() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFBoolean
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // extra?: string;
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderBinary() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFBinary
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // extra?: string;
      leftButText={question.butText?.l}
      rightButText={question.butText?.r}
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderRadio() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFRadio
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // extra?: string;
      choices={question.choices?.map(c => t(c)) || []}
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderCheckbox() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFCheckbox
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      choices={question.choices?.map(c => t(c)) || []}
      // extra?: string;
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderButList() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFButList
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // extra?: string;
      choices={question.choices?.map(c => t(c)) || []}
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderInputText() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFInputText
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      label={{ en: 'enter text', fr: 'entrez du texte' }.fr}
      // extra?: string;
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderSlider() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFSlider
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // label={{ en: 'slide me', fr: 'glissez moi' }.fr}
      min={question.min != null ? question.min : 0}
      max={question.max != null ? question.max : 100}
      // extra?: string;
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderSliderRange() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFSliderRange
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // label={{ en: 'slide me', fr: 'glissez moi' }.fr}
      min={question.min != null ? question.min : 0}
      max={question.max != null ? question.max : 100}
      // extra?: string;
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderTimeSlider() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFTimeSlider
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // label={{ en: 'slide me', fr: 'glissez moi' }.fr}
      min={question.min != null ? question.min : 0}
      max={question.max != null ? question.max : 100}
      // extra?: string;
      step={15}
      stepUnit='M'
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderTimeRange() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFTimeRange
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // label={{ en: 'slide me', fr: 'glissez moi' }.fr}
      min={question.min != null ? question.min : 0}
      max={question.max != null ? question.max : 100}
      // extra?: string;
      step={15}
      stepUnit='M'
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderDateRange() {
    if (section == null || question == null) return null; // TODO: add feedback
    return <FFDateRange
      title={t(section.title)}
      subTitle={t(section.subTitle)}
      icon={section.icon}
      question={t(question.text)}
      // label={{ en: 'slide me', fr: 'glissez moi' }.fr}
      // extra?: string;
      onSelect={s => dte.current?.setAnswer(s)}
      onBack={dte.current?.tryGoBack()}
    />
  }

  function renderEnd() {
    return <Text style={styles.title}>{t('shorts.please-wait')}</Text>
  }

  function renderType(type: FFQuestionType) {
    switch (type) {
      case 'bool': return renderBoolean();
      case 'bin': return renderBinary();
      case 'radio': return renderRadio();
      case 'checkbox': return renderCheckbox();
      case 'butlist': return renderButList();
      case 'inptxt': return renderInputText();
      case 'slider': return renderSlider();
      case 'sldrng': return renderSliderRange();
      case 'sldtime': return renderTimeSlider();
      case 'timerng': return renderTimeRange();
      case 'daterng': return renderDateRange();
      case 'end': return renderEnd();
      default: return null; // TODO: add feedback
    }
  }

  function render() {
    if (question == null) return null;
    if (question.debug) console.log('>>> FF DEBUG:', dte.current?.getAnswers());
    return renderType(question.type);
  }

  const isDone = dte.current?.getCurQuestion().type === 'end';
  const iconName = isDone ? 'check' : 'close';
  return <View style={{ flex: 1 }}>
    <Appbar style={{ position: 'absolute', left: 0, right: 0, top: 0, zIndex: 20, backgroundColor: '#333' }}>
      <View style={{ flexGrow: 1, paddingLeft: 24 }}>
        <Subheading style={{ color: 'white' }}>{t('scheduling.setTimeOffer')}</Subheading>
      </View>
      {/* <Appbar.Action icon='mail' onPress={() => console.log('Pressed mail')} />
      <Appbar.Action icon='label' onPress={() => console.log('Pressed label')} /> */}
      <Appbar.Action icon='delete' onPress={() => setDel(true)} />
      <Appbar.Action icon={iconName} onPress={() => isDone ? navigation.goBack() : setLeave(true)} />
    </Appbar>
    <View style={{ width: '100%', height: 60 }} />
    {!ready && <View style={{ flexGrow: 1, marginTop: 24 }}>
      <Subheading style={{ textAlign: 'center' }}>{t('scheduling.clickStartGeoloc')}</Subheading>
      <Button onPress={() => { start(); setReady(true); }}>{t('shorts.start')}</Button>
    </View>}
    {ready && render()}
    <AreYouSureModal
      show={leave}
      onClose={() => setLeave(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      // msg={t('logout.question')}
      msg={t('userManagement.cancel.question')}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) {
          navigation.goBack();
        }
        setLeave(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.no')}
    />
    <AreYouSureModal
      show={del}
      onClose={() => setDel(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      // msg={t('logout.question')}
      msg={'are you sure you want to delete this time offer?'}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) {
          // TODO: implement delete...
          navigation.goBack();
        }
        setDel(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.no')}
    />
    <WaitingModal open={busy} width='50vw' text={t('shorts.please-wait')} />
  </View>
}

//
// ===
//

// TODO: Move to shared lib at one point...
export interface HrTimeOffers {
  to: UserTimeOffer;
  s5: HumanReadableTimeOffer;
  e5: HumanReadableTimeOffer;
}

export interface TimeOffersPerMonth {
  weeks: HrTimeOffers[][];
}

//
// ===
//

export interface FastFormUIResumeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any;
}

export function FastFormUIResume({ navigation }: FastFormUIResumeProps) {
  const { globalState } = useContext(globalStore);
  const { authState } = useContext(authStore);
  const [offers, setOffers] = useState<UserTimeOffer[]>([]);
  const [busy, setBusy] = useState(false);
  const [perMonth, setPerMonth] = useState<TimeOffersPerMonth>({ weeks: [[]] });
  const [t] = useTranslation();

  useEffect(() => {
    getOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const week1Limit = 2016; // week1: start5mins < 2016
  const week2Limit = 4032; // week2: start5mins < 4032
  const week3Limit = 6048; // week3: start5mins < 6048
  const week4Limit = 8064; // week4: start5mins < 8064
  useEffect(() => {
    const l = i18n.language;
    setBusy(true);
    try {
      const perMonth = offers.reduce<TimeOffersPerMonth>((previous, current, curIndex, tos) => {
        const to = tos[curIndex];
        if (to.start5mins < week1Limit) {
          previous.weeks[0].push({ to, s5: toHumanTimeOffer(to.startDate, l), e5: toHumanTimeOffer(to.endDate, l) });
        }
        else if (to.start5mins < week2Limit) {
          if (previous.weeks[1].length < 2) previous.weeks.push([]);
          previous.weeks[1].push({ to, s5: toHumanTimeOffer(to.startDate, l), e5: toHumanTimeOffer(to.endDate, l) });
        }
        else if (to.start5mins < week3Limit) {
          if (previous.weeks[1].length < 3) previous.weeks.push([]);
          previous.weeks[2].push({ to, s5: toHumanTimeOffer(to.startDate, l), e5: toHumanTimeOffer(to.endDate, l) });
        }
        else if (to.start5mins < week4Limit) {
          if (previous.weeks[1].length < 4) previous.weeks.push([]);
          previous.weeks[3].push({ to, s5: toHumanTimeOffer(to.startDate, l), e5: toHumanTimeOffer(to.endDate, l) });
        }
        return previous;
      }, { weeks: [[]] });
      // console.log('>>> TOS PER MONTH:', perMonth);
      setPerMonth(perMonth);
    }
    catch (error) {
      console.log('>>> offers error:', error);
    }
    finally {
      setBusy(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers, i18n.language]);

  async function getOffers() {
    setBusy(true);
    try {
      if (authState.user?.username == null) return;
      setOffers(
        await BeServices.getInstance().schedule.getUserTimeOffers(authState.user.username) as UserTimeOffer[] || []
      );
    }
    catch (error) {
      console.log('>>> offers error:', error);
    }
    finally {
      setBusy(false);
    }
  }

  async function onDelete(month: number, toIdx: number) {
    // console.log('>>> TODO: timeoffer onDelete:', month, to);

    if (perMonth.weeks.length < month || perMonth.weeks[month][toIdx] == null) return; // TODO: visual feedback
    setBusy(true);
    const to = perMonth.weeks[month][toIdx].to;
    await BeServices.getInstance().schedule.deleteTimeOffers({
      lngLatProfession: to.lngLatProfession,
      userIdStart5min: to.userIdStart5min,
    });
    setOffers(
      offers.filter(o => o.lngLatProfession !== to.lngLatProfession || o.userIdStart5min !== to.userIdStart5min)
    );
    setBusy(false);
  }

  const monthLbls = ['scheduling.1stWeek', 'scheduling.2ndWeek', 'scheduling.3rdWeek', 'scheduling.4thWeek'];
  function renderTimeOffers(month: number, hrtos?: HrTimeOffers[]) {
    if (hrtos == null || hrtos.length === 0) return null;
    return <List.Accordion
      title={t(monthLbls[month])}
      left={props => <List.Icon {...props} icon='calendar' />}
    >
      {hrtos.map((hrto, idx) => <List.Item key={'to_' + month + '_' + idx}
        title={t(Object.keys(weekDays)[hrto.s5.dow])} description={`${hrto.s5.hour} - ${hrto.e5.hour}`}
        left={props => <List.Icon {...props} icon='clock' />}
        right={props => <>
          <IconButton {...props} icon='pencil' onPress={() => navigation.navigate('MakeSchedule', { mode: 'edit', k1: hrto.to.lngLatProfession, k2: hrto.to.userIdStart5min, dow: hrto.e5.dow, week: month })} />
          <IconButton {...props} icon='delete' onPress={() => onDelete(month, idx)} />
        </>}
      />)}
    </List.Accordion>
  }

  return <Card style={{ width: '100%' }}>
    <Card.Title
      title={t('scheduling.formTile')}
      subtitle={t('scheduling.formSubtile')}
      left={props => <Avatar.Icon {...props} icon='calendar' />}
      right={props => <IconButton {...props} icon='reload' onPress={getOffers} />}
    />
    <Card.Content>
      <List.Section title={busy ? t('shorts.please-wait') : t('scheduling.yourOffers', { count: offers.length })}>
        {perMonth.weeks.length > 0 && renderTimeOffers(0, perMonth.weeks[0])}
        {perMonth.weeks.length > 1 && renderTimeOffers(1, perMonth.weeks[1])}
        {perMonth.weeks.length > 2 && renderTimeOffers(2, perMonth.weeks[2])}
        {perMonth.weeks.length > 3 && renderTimeOffers(3, perMonth.weeks[3])}
      </List.Section>
    </Card.Content>
    <Card.Actions style={{ justifyContent: 'flex-end' }}>
      {globalState.isDev && <Button onPress={() => navigation.navigate('MakeSchedule', { mode: 'test' })}>test</Button>}
      <Button onPress={() => navigation.navigate('MakeSchedule', { mode: offers.length ? 'add' : 'start' })}>
        {offers.length ? t('shorts.add') : t('shorts.start')}
      </Button>
    </Card.Actions>
    <WaitingModal open={busy} width='50vw' text={t('shorts.please-wait')} />
  </Card>
}

const styles = StyleSheet.create({
  title: {
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
    margin: 40,
    textAlign: 'center',
  },
});
