import { useContext, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableHighlight } from 'react-native';
import { List } from 'react-native-paper';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useTranslation } from 'react-i18next';
//
import { UnauthStackScreenProps } from '../types';
import Layout from '../cmps/Layout';
import LanguageBanner from '../cmps/LanguageBanner';
import Logo from '../cmps/Logo';
import { scale, moderateScale } from '../Scaling';
import { nativeStore } from '@eksoh/shared/ui-expo';

export default function Welcome({ navigation }: UnauthStackScreenProps<'Welcome'>) {
  const { nativeState, setBottomSheetNode } = useContext(nativeStore);
  const [t] = useTranslation();

  const bsNode = <View style={{ flex: 1, justifyContent: 'space-between', backgroundColor: 'rgb(229, 229, 229)' }}>
    <View style={{ width: '100%', alignItems: 'flex-end' }}>
      <TouchableHighlight onPress={() => setBottomSheetNode(undefined)} style={{ marginRight: 12 }}>
        <Ionicons name='close' size={32} color='#323232' />
      </TouchableHighlight>
    </View>
    <View style={{ display: 'flex', alignItems: 'center' }}>
      <Text style={{ textAlign: 'center', fontWeight: '800', padding: 12 }}>{t('nurse.welcomeDesc')}</Text>
      <View style={{ display: 'flex', maxWidth: 500, alignItems: 'center' }}>
        <List.Section>
          <List.Item
            left={() => <List.Icon icon='currency-usd' />}
            title={t('nurse.welcomeList1')}
            titleNumberOfLines={2}
          />
          <List.Item
            left={() => <List.Icon icon='calendar-month' />}
            title={t('nurse.welcomeList2')}
            titleNumberOfLines={2}
          />
          <List.Item
            left={() => <List.Icon icon='account-group' />}
            title={t('nurse.welcomeList3')}
            titleNumberOfLines={2}
          />
        </List.Section>
      </View>
      <View style={{ height: 30 }} />
      <TouchableHighlight style={styles.but} onPress={() => {
          setBottomSheetNode(undefined);
          navigation.replace('GetLoginInfo');
        }}>
        <Text style={styles.butText}>{t('login.form.create')}</Text>
      </TouchableHighlight>
      <View style={{ height: 1000 }} />
    </View>
  </View>

  useEffect(() => {
    if (nativeState.bottomSheetNode != null) setBottomSheetNode(bsNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return <Layout hideFab={true} phoneSize={true}>
    <LanguageBanner />
    <Logo width={80} height={80} />
    <Text style={styles.tagline}>{t('nurse.welcomeMotto')}</Text>
    <View style={styles.greySpot}>
      <Ionicons name='image-outline' size={32} color='#A5A5A5' style={{ marginTop: 70, marginBottom: 55 }} />
      <Text style={styles.greyText}>{t('shorts.discover')} {t('flo.title')}</Text>
    </View>
    <View>
      <TouchableHighlight style={[styles.but, { marginBottom: scale(16) }]} onPress={() => setBottomSheetNode(bsNode) /* hostedUI() */}>
        <Text style={styles.butText}>{t('shorts.join')} {t('flo.title')}</Text>
      </TouchableHighlight>
      <TouchableHighlight style={styles.but} onPress={() => navigation.replace('Login')}>
        <Text style={styles.butText}>{t('login.form.button')}</Text>
      </TouchableHighlight>
    </View>
  </Layout>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#e5e5e5',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagline: {
    fontSize: 20, // moderateScale(20),
    textAlign: 'center',
    padding: scale(6),
  },
  greySpot: {
    marginTop: scale(12),
    marginBottom: scale(20),
    fontSize: moderateScale(25),
    textAlign: 'center',
    backgroundColor: '#323232',
    borderRadius: 20,
    width: 340,
    height: 256,
    alignItems: 'center',
    justifyContent: 'center',
  },
  greyText: {
    fontSize: moderateScale(15),
    textAlign: 'center',
    color: '#A5A5A5',
  },
  but: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: 'transparent',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#323232',
    width: 270,
    height: 63,
  },
  butText: {
    color: '#323232',
    textAlign: 'center',
  },
});
