export interface ISchemaData {
  patientInfo: {
    familyName: string;
    givenName: string;
    email: string;
    birthdate: string;
    language: string;
    mobileNumber?: string;
    phoneNumber?: string;
    fullAddress: string;
    insurNumber: string;
    curDoctor?: string;
    pharmacy?: string;
    contactName?: string;
    contactNumber?: string;
  };
  butvalid?: boolean;
}

export const schemaData: ISchemaData = {
  patientInfo: {
    familyName: '',
    givenName: '',
    email: '',
    birthdate: '',
    language: '',
    fullAddress: '',
    insurNumber: '',
  },
}
