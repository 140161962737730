// import { useContext /* , useState */ } from 'react';
// //
import { authStore, globalStore } from '@eksoh/shared/ui';
// import { ClientProfile } from '@eksoh/shared/ui-expo';
import { RootStackScreenProps } from '../types';
// import Layout from '../cmps/Layout';
// import { FastFormUIResume } from '../fastForms/ui';
// import { MenuBar } from './cmps/MenuBar';
// import { DebugPanel } from '../cmps/Debug';
import * as Linking from 'expo-linking';
import { useContext, useEffect } from 'react';

export default function NurseDashboard({ navigation }: RootStackScreenProps<'NurseDashboard'>) {
  const { globalState } = useContext(globalStore);
  const { authState, signOut } = useContext(authStore);

  // const showDev = globalState.isDev;
  // return <Layout hideFab={true}>
  //   <MenuBar navigation={navigation} onCmd={() => { /* */ }} mt={0} mb={12} />
  //   <ClientProfile name={authState.user?.name || ''} bottomGutter />
  //   <FastFormUIResume navigation={navigation} />
  //   {showDev && <DebugPanel navigation={navigation} mt={12} mb={12} />}
  // </Layout>

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { goToWebapp(); }, []);

  async function goToWebapp() {
    if (!authState.isSignedIn) {
      navigation.push('Login');
      return;
    }

    signOut();
    // TODO: remove this for v1, just a quick hack to make sure we log out of expo app before
    //       going to webapp so when we test and want to try expo again, we are not stuck in
    //       redirect hell. Since signOut is not async/await, lets just wait a bit to make sure
    //       signOut was fully completed. Surper uncool but we need a fix quick...
    await new Promise(resolve => setTimeout(resolve, 700));

    await Linking.openURL(globalState.baseUrlWebapp || 'NO_BASE_URL_WEBAPP_FOUND');
  }

  return null;
}
