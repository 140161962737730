import { Auth } from '@aws-amplify/auth';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
//
import { Config } from '@eksoh/shared/common';

export interface ConsoleOutMenuItem {
  label: string;
  cmd: () => void;
}

export type ConsoleOutMenus = ConsoleOutMenuItem[];

export function mainConsoleOut(conf: Config, pathname: string, callback?: (pathname: string) => ConsoleOutMenus) {
  const menus = [
    { label: 'app config', cmd: () => console.log('>>> APP CONF:', conf) },
    { label: 'aws auth user', cmd: async () => { console.log('>>> AUTH USR:', await Auth.currentAuthenticatedUser()); } },
    // { label: 'redux', cmd: async () => { console.log('>>> REDUX:', await Auth.currentAuthenticatedUser()); } },
    ...(callback != null ? callback(pathname) : []),
  ];
  return menus.map((m, i) =>
      <MenuItem key={'dm_mainConsoleOut' + i} onClick={m.cmd}>
        <Typography>{m.label}</Typography>
      </MenuItem>
  );
}
