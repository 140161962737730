/* eslint-disable @typescript-eslint/no-explicit-any */
// import React, { useContext, createContext, useReducer, Dispatch, useRef, useEffect } from 'react';
// import { GraphQLAPI } from '@aws-amplify/api-graphql';
import { ReactNode, useContext, createContext, useReducer, Dispatch, useRef, useEffect } from 'react';
// INTEG import { GraphQLAPI } from '@aws-amplify/api-graphql';
//
// INTEG import { TZ_AMERICA_MONTREAL, Notif } from '../../backend/core';
import { authStore, useNotification } from '../../..';
// TODO: Notifs should be only sent by BE, not by users!! But for testing, it will...
// INTEG import { NotifApiClient } from '../../backend/services/notif/client';

const HISTO_SIZE = 20;

export interface UseNotifInfo {
  os: string;
  registered: boolean;
  log?: string;
}

// #region Actions

export enum eNotifActions {
  SET_IS_READY, SET_BUSY, SET_INFO, SET_ERROR, SET_VAPID,
  ADD_NEW_NOTIF, MARK_AS_READ, MARK_ALL_AS_READ, CLEAR_NOTIFS,
}

interface SetReadyAction {
  type: typeof eNotifActions.SET_IS_READY;
}

interface SetBusyAction {
  type: typeof eNotifActions.SET_BUSY;
  state: boolean;
}

interface SetInfoAction {
  type: typeof eNotifActions.SET_INFO;
  info: UseNotifInfo;
}

interface SetErrorAction {
  type: typeof eNotifActions.SET_ERROR;
  error: any;
}

interface SetVapidAction {
  type: typeof eNotifActions.SET_VAPID;
  vapid: string;
}

interface AddNewNotifAction {
  type: typeof eNotifActions.ADD_NEW_NOTIF;
  notif: any; // INTEG Notif;
}

interface MarkAsReadAction {
  type: typeof eNotifActions.MARK_AS_READ;
  id: string;
}

interface MarkAllAsReadAction {
  type: typeof eNotifActions.MARK_ALL_AS_READ;
}

interface ClearMyGeolocAction {
  type: typeof eNotifActions.CLEAR_NOTIFS;
}

export type NotifActionTypes = SetReadyAction | SetBusyAction |
  SetInfoAction | SetErrorAction | SetVapidAction |
  AddNewNotifAction | MarkAsReadAction | MarkAllAsReadAction | ClearMyGeolocAction;

// #endregion Actions

// #region Reducer

export interface INotifState {
  isReady: boolean;
  busy: boolean;
  info: UseNotifInfo;
  error?: any;
  vapid?: string;
  notifHisto: any[]; // INTEG Notif[];
}

const initialState: INotifState = {
  isReady: false,
  busy: false,
  info: { os: 'unknown', registered: false },
  notifHisto: [],
}

export interface INotifContext {
  notifState: INotifState;
  dispatch: Dispatch<any>;
  getNotifApiClient: () => any; // INTEG NotifApiClient | undefined;
  requestPermissions: () => void;
  markAsRead: (id: string) => void;
  markAllAsRead: () => void;
  clearNotifs: () => void;
}

export const notifStore = createContext<INotifContext>({
  notifState: initialState,
  dispatch: () => null,
  getNotifApiClient: () => { throw new Error('Not implemented.'); },
  requestPermissions: () => { throw new Error('Not implemented.'); },
  markAsRead: () => { throw new Error('Not implemented.'); },
  markAllAsRead: () => { throw new Error('Not implemented.'); },
  clearNotifs: () => { throw new Error('Not implemented.'); },
});

function reducer(state: INotifState, action: NotifActionTypes): INotifState {
  switch (action.type) {
    case eNotifActions.SET_IS_READY:
      return { ...state, isReady: true };
    case eNotifActions.SET_BUSY:
      return { ...state, busy: action.state };
    case eNotifActions.SET_INFO:
      return { ...state, info: action.info };
    case eNotifActions.SET_ERROR:
      return { ...state, error: action.error };
    case eNotifActions.SET_VAPID:
      return { ...state, vapid: action.vapid };
    case eNotifActions.ADD_NEW_NOTIF:
      return { ...state, notifHisto: [action.notif, ...state.notifHisto].splice(0, HISTO_SIZE) };
    case eNotifActions.MARK_AS_READ:
      return { ...state, notifHisto: state.notifHisto.map(n => n.id === action.id ? { ...n, read: true } : n) };
    case eNotifActions.MARK_ALL_AS_READ:
      return { ...state, notifHisto: state.notifHisto.map(n => ({ ...n, read: true })) };
    case eNotifActions.CLEAR_NOTIFS:
      return { ...state, notifHisto: [] };
    default:
      return state;
  }
}

// #endregion Reducer

export interface AppNotifProps {
  useVapid: boolean;
  children: ReactNode | ReactNode[];
}

export function AppNotif(props: AppNotifProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { authState } = useContext(authStore);
  const [notifState, dispatch] = useReducer(reducer, initialState);
  const notifApiClient = useRef<any>(); // INTEG useRef<NotifApiClient>();
  const { /* init, */ info, error } = useNotification({ vapid: notifState.vapid, onNewNotif, onRegistered });

  useEffect(() => {
    // INTEG notifApiClient.current = new NotifApiClient(GraphQLAPI, authState.jwtToken, TZ_AMERICA_MONTREAL);
    if (props.useVapid) vapidPublicKey();
    dispatch({ type: eNotifActions.SET_IS_READY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => dispatch({ type: eNotifActions.SET_INFO, info }), [info]);
  useEffect(() => dispatch({ type: eNotifActions.SET_ERROR, error }), [error]);

  // #region Internal Functions

  async function vapidPublicKey() {
    try {
      const vapid = await notifApiClient.current?.vapidPublicKey();
      if (vapid) dispatch({ type: eNotifActions.SET_VAPID, vapid });
    }
    catch (error) {
      console.log('error getting vapid:', error);
    }
  }

  function onNewNotif(notif: any) { // INTEG Notif) {
    dispatch({ type: eNotifActions.ADD_NEW_NOTIF, notif });
  }

  async function onRegistered(subscription: PushSubscription) {
    try {
      /* const state = */ await notifApiClient.current?.registerToNotifs(subscription);
      // console.log('registered for push notification:', state, subscription)
    }
    catch (error) {
      console.log('registered push notif failed:', error)
    }
  }

  // #endregion Internal Functions

  // #region Functions

  function getNotifApiClient() { return notifApiClient.current; }

  async function requestPermissions() {
    // TODO: @fe - reactivate when needed: init();
  }

  async function markAsRead(id: string) {
    dispatch({ type: eNotifActions.MARK_AS_READ, id });
  }

  async function markAllAsRead() {
    dispatch({ type: eNotifActions.MARK_ALL_AS_READ });
  }

  async function clearNotifs() {
    dispatch({ type: eNotifActions.CLEAR_NOTIFS });
  }

  // #endregion Functions

  return <notifStore.Provider value={{
    notifState, requestPermissions, dispatch, getNotifApiClient, markAsRead, markAllAsRead, clearNotifs,
  }}>
    {notifState.isReady && props.children}
  </notifStore.Provider>
}
