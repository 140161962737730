import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Diagnosis } from '@eksoh/shared/common';

const DiagnosesList: React.FC<{ diagnoses: Array<Diagnosis> }> = ({ diagnoses }) => (
  <List>
    {diagnoses.map((diagnosis) => <ListItem key={diagnosis.code}>
      <ListItemText
        primary={`• ${diagnosis.code}: ${diagnosis.name}`}
        // secondary={`from: ${entry.sickLeave.startDate} to ${entry.sickLeave.endDate}`}
      />
    </ListItem>)}
  </List>
);

export default DiagnosesList;