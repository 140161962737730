
export const schema = {
  type: 'object',
  properties: {
    medicalSummary: {
      type: 'object',
      properties: {
        medications: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              // i18n not working down here...
              date: { type: 'string', format: 'date' },
              medication: { type: 'string', maxLength: 50 },
            },
          },
        },
        allergyIntolerance: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              // i18n not working down here...
              started: { type: 'string', format: 'date' },
              condition: { type: 'string', maxLength: 50 },
            },
          },
        },
        activeIssues: {
          type: 'object',
          properties: {
            medical: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  // i18n not working down here...
                  date: { type: 'string', format: 'date' },
                  issue: { type: 'string', maxLength: 50 },
                },
              },
            },
            surgical: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  // i18n not working down here...
                  date: { type: 'string', format: 'date' },
                  issue: { type: 'string', maxLength: 50 },
                },
              },
            },
            psychiatric: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  // i18n not working down here...
                  date: { type: 'string', format: 'date' },
                  issue: { type: 'string', maxLength: 50 },
                },
              },
            },
          },
        },
        habits: {
          type: 'object',
          properties: {
            tobacco: { type: 'string', enum: ['never', 'past', 'now'] },
            alcohol: { type: 'string', enum: ['never', 'past', 'now'] },
            physActivities: { type: 'string', enum: ['never', 'past', 'now'] },
          },
        },
      },
    },
    butvalid: {
      type: 'boolean',
    },
  },
}

// TEST V2 ...

export const medications = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      // i18n not working down here...
      date: { type: 'string', format: 'date' },
      medication: { type: 'string', maxLength: 50 },
    },
  },
}

export const allergyIntolerance = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      // i18n not working down here...
      started: { type: 'string', format: 'date' },
      condition: { type: 'string', maxLength: 50 },
    },
  },
}

export const activeIssues = {
  type: 'object',
  properties: {
    medical: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          // i18n not working down here...
          date: { type: 'string', format: 'date' },
          issue: { type: 'string', maxLength: 50 },
        },
      },
    },
    surgical: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          // i18n not working down here...
          date: { type: 'string', format: 'date' },
          issue: { type: 'string', maxLength: 50 },
        },
      },
    },
    psychiatric: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          // i18n not working down here...
          date: { type: 'string', format: 'date' },
          issue: { type: 'string', maxLength: 50 },
        },
      },
    },
  },
}

export const habits = {
  type: 'object',
  properties: {
    tobacco: { type: 'string', enum: ['never', 'past', 'now'] },
    alcohol: { type: 'string', enum: ['never', 'past', 'now'] },
    physActivities: { type: 'string', enum: ['never', 'past', 'now'] },
  },
}
