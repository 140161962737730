/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
//
// import theme from './theme'
import { User } from '@eksoh/flo/model';
import { authStore, eAppAuthActions, textChatStore } from '@eksoh/shared/ui';

// const { primaryColor } = theme;
const primaryColor = '#007bff';

export interface UsersProps {
  onUserSelected: (user: User) => void;
}

export function Users({ onUserSelected }: UsersProps) {
  const { authState, dispatch } = useContext(authStore);
  const { getChatApiClient, listConvsForUser } = useContext(textChatStore);
  const [loading, setLoading] = useState(true);
  const [myData, setMyData] = useState<User>();
  const [users, setUsers] = useState<User[]>();
  const [user, setUser] = useState<User>();
  let subscription: any;

  useEffect(() => {
    fetchMe();
    // subscribe();
    return () => subscription.unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myData != null) {
      // TODO: Not cool, add extra action just for chat info...
      dispatch({
        type: eAppAuthActions.SET_USR_INFO,
        username: authState.user?.username,
        chatInfo: { myChatUserId: myData.username, myUsername: `${myData.givenName} ${myData.familyName}` },
        jwtToken: authState.jwtToken,
        cognitoUser: authState.cognitoUser
      });
      fetchUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myData]);

  useEffect(() => {
    if (user != null) {
      listConvsForUser(user);
      onUserSelected(user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function fetchMe() {
    try {
      const me = await getChatApiClient()?.getMe({});
      if (me != null) setMyData(me);
      else createMe();
    }
    catch (err) {
      console.log('error: ', err)
    }
  }

  async function createMe() {
    try {
      const saveMe = await getChatApiClient()?.createUser(`${authState.user?.givenName} ${authState.user?.familyName}`);
      setMyData(saveMe);
    }
    catch (err) {
      console.log('error: ', err)
    }
  }

  async function fetchUsers() {
    try {
      const users = await getChatApiClient()?.getAllUsers({});
      setUsers(users);

      setLoading(false);
    }
    catch (err) {
      console.log('error: ', err)
    }
  }

  if (loading) {
    return <View style={{ flex: 1 }}>
      <Text style={styles.titleStyle}>loading...</Text>
    </View>
  }

  if (user == null && users != null) {
    return <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <Text style={styles.titleStyle}>available users</Text>
        {users.length === 0 && <Text style={styles.noUsersStyle}>no users</Text>}
        {users.map((u, idx) =>
          <TouchableOpacity key={`CDCU_${idx}`} style={styles.btn} onPress={() => setUser(u)}>
            <Text>{u.givenName} {u.familyName}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  }

  return null;
}

const styles = StyleSheet.create({
  titleStyle: {
    color: primaryColor,
    marginTop: 6,
    marginBottom: 6,
    textAlign: 'center',
  },
  noUsersStyle: {
    color: 'red',
    marginTop: 12,
    textAlign: 'center',
  },
  btn: {
    backgroundColor: 'lightblue',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    marginBottom: 10,
  },
});
