import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import NumbersIcon from '@mui/icons-material/Numbers';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import InventoryIcon from '@mui/icons-material/Inventory';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomIcon from '@mui/icons-material/Room';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
//
// import { authStore } from '@fe_shared/contexts/auth';
import Calendar from './calendar';
import MedForms from './medforms';
import MedFiles from './medfiles';
import Notes from './notes';

export interface ProfileProps {
  to: string;
  gutterBottom?: boolean
  gutterRight?: boolean
}

export default function Profile({ to, gutterBottom, gutterRight }: ProfileProps) {
  const params = useParams<{ cid: string }>();
  const [tags, setTags] = useState<{ name: string }[]>([{ name: 'VIP' }, { name: 'Premium' }])
  // const { authState } = useContext(authStore);

  // const name = authState.user?.name != null
  //   ? authState.user?.name
  //   : authState.user?.givenName && authState.user?.familyName
  //     ? `${authState.user?.givenName} ${authState.user?.familyName}`
  //     : 'à vous!'

  return <Stack direction='row'>
    <Stack direction='column'>
      <Paper
        elevation={3}
        style={{ display: 'flex', flexDirection: 'column', padding: 12, width: 460, alignItems: 'center' }}
        sx={{ mb: gutterBottom ? 2 : undefined, mr: gutterRight ? 2 : undefined}}
      >
        <Avatar alt='Isabelle Fortin' src='/static/images/avatar/1.jpg' sx={{ width: 56, height: 56, mt: 4, mb: 3 }} />
        <Stack direction='row' spacing={2} sx={{ mb: 4 }}>
          <NumbersIcon />
          <Typography variant='body1'>{params.cid}</Typography>
        </Stack>
        <Stack direction='row' spacing={2}>
          <IconButton onClick={() => { /* */ }}>
            <ChatIcon />
          </IconButton>
          <IconButton onClick={() => { /* */ }}>
            <EmailIcon />
          </IconButton>
          <IconButton onClick={() => { /* */ }}>
            <InventoryIcon />
          </IconButton>
          <IconButton onClick={() => { /* */ }}>
            <MoreHorizIcon />
          </IconButton>
          <IconButton onClick={() => { /* */ }}>
            <OpenInNewIcon />
          </IconButton>
        </Stack>
        <Divider />
        <Stack direction='column' spacing={2} sx={{ mt: 2, mb: 2, width: '100%' }}>
          <Stack direction='row' spacing={2}>
            <PersonIcon />
            <Typography variant='body1'>Isabelle Fortin</Typography>
          </Stack>
          <Stack direction='row' spacing={2}>
            <AlternateEmailIcon />
            <Typography variant='body1' flexGrow={1}>i.fortin@gmail.com</Typography>
          </Stack>
          <Stack direction='row' spacing={2}>
            <LocalPhoneIcon />
            <Typography variant='body1' style={{ color: '#999' }}>Ajouter un numéro de téléphone...</Typography>
          </Stack>
          <Stack direction='row' spacing={2}>
            <RoomIcon />
            <Typography variant='body1'>7321 rue Sherbrooke, QC H1B 1B4 Montréal</Typography>
          </Stack>
          <Stack direction='row' spacing={2}>
            <LocalOfferIcon />
            {tags.map((t, i) => <Chip key={'tag'+i} label={t.name} onDelete={() => { setTags(tags.filter((t, idx) => idx !== i)) }} />)}
          </Stack>
        </Stack>
        <Calendar />
      </Paper>
    </Stack>
    <Box display='flex' flexDirection='column' width='100%' px={3}>
      <MedForms to={to} />
      <MedFiles to={to} />
      <Notes />
    </Box>
  </Stack>
}
