export const miniForm = {
  elements: [{
    name: 'FirstName',
    title: 'Enter your first name:',
    type: 'text'
  },
  {
    name: 'LastName',
    title: 'Enter your last name:',
    type: 'text'
  }],
};

