import { ReactElement, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//
import { BeServices } from '@eksoh/shared/ui';
import { CurrencyInput, CurrencyInputOnChangeValues, formatValue, GenericModal } from '@eksoh/shared/ui-web'; // TODO @fp: that a big NO NO !!!
import { AppmntTypeDetail, AdmGetPreAppmtQuery, PreAppointmentState } from '@eksoh/flo/model';
import { PreAppmtResultType } from '../reducer';

const priceWarning = 'please confirm your amount. your confirmation will send a notice to the client and will block the price to this amount on the clients credit card.';
const statesCanStillUpd = [PreAppointmentState.TO_REVIEW];

export interface PreAppmtInfoProps {
  preAppmt: PreAppmtResultType[number];
  onWaiting: (waiting: boolean) => ReactElement | null;
  onUpdate: (cents: string, priceNotes: string, acceptedNotes: string) => void;
  onCancel: () => void;
}

export function PreAppmtInfo({ preAppmt, onWaiting, onUpdate, onCancel }: PreAppmtInfoProps) {
  const [t] = useTranslation();
  const [apDetail, setApDetail] = useState<AppmntTypeDetail>();
  const [allDetails, setAllDetails] = useState<AdmGetPreAppmtQuery['admGetPreAppmt']>();
  const [price, setPrice] = useState(preAppmt?.price ? `${(preAppmt.price / 100).toFixed(2)}` : '');
  const [fPrice, setFPrice] = useState(0);
  const [cents, setCents] = useState('');
  const [confirmPrice, setConfirmPrice] = useState(false);
  const [priceNotes, setPriceNotes] = useState(preAppmt?.priceNotes || '');
  const [acceptedNotes, setAcceptedNotes] = useState(preAppmt?.acceptedNotes || '');

  const canUpd = preAppmt?.state ? statesCanStillUpd.includes(preAppmt.state) : false;
  const canUpdPrice = canUpd && (preAppmt?.price || 0) === 0;

  useEffect(() => {
    async function getAllDetails() {
      if (preAppmt == null) return;
      setAllDetails(await BeServices.getInstance().appmts.admGetPreAppmt({
        code: preAppmt.code,
        lat: preAppmt.lat,
        lng: preAppmt.lng,
        username: preAppmt.username,
      }));
    }
    getAllDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getDetail() {
      if (preAppmt == null) return;
      setApDetail(await BeServices.getInstance().appmts.getAppmntTypeDetailsByCode(preAppmt.code));
    }
    getDetail();
  }, [preAppmt]);

  function handlePrice(value?: string, name?: string, values?: CurrencyInputOnChangeValues) {
    // setValues(values);
    // setRawValue(value === undefined ? 'undefined' : value || ' ');
    setFPrice(values?.float || 0);

    if (!value) {
      // setClassName('');
      setPrice('');
      return;
    }

    if (Number.isNaN(Number(value))) {
      // setErrorMessage('Please enter a valid number');
      // setClassName('is-invalid');
      return;
    }

    if (Number(value) > 10000) {
      // setErrorMessage(`Max: ${prefix}${limit}`);
      // setClassName('is-invalid');
      setPrice('10000.00'); // setPrice(value);
      return;
    }

    // console.log('>>> FORMATED:', formatValue({ value, disableGroupSeparators: true, decimalScale: 2 }));
    // setClassName('is-valid');
    setPrice(value);
    setCents(formatValue({ value: price, disableGroupSeparators: true, decimalScale: 2 }));
  };

  function confirm() {
    onUpdate(cents, priceNotes, acceptedNotes);
    setFPrice(0);
    setPrice('');
    setCents('');
    setConfirmPrice(false);
  }

  return <Grid container spacing={3}>
    <Grid item xs={12}>
      <TextField label='type' fullWidth value={t(apDetail?.name || '')} InputProps={{ readOnly: true }} variant='standard' />
    </Grid>
    {onWaiting(allDetails == null)}
    {allDetails != null && <>
      <Grid item xs={12} sm={6}>
        <TextField label='name' fullWidth value={`${allDetails.detail.givenName} ${allDetails.detail.familyName}`} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField label='posted' fullWidth value={(new Date(allDetails.createdAt)).toLocaleDateString(`${i18n.language}-CA`)} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <TextField label='storage key' fullWidth value={`${allDetails.detail.identityId}/${allDetails.storageKey}`} InputProps={{ readOnly: true }} variant='standard' />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <TextField label='form pages' fullWidth value={allDetails.documents?.length || 0} InputProps={{ readOnly: true }} variant='standard' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CurrencyInput
          customInput={TextField} customInputProps={{ label: 'price', readOnly: !canUpdPrice }} style={{ marginLeft: 12 }}
          prefix='$' step={1} min={0} max={100} // disabled={!needPrice}
          // onFocus={() => { if (canUpdPrice && fPrice > 0) { setFPrice(0); setPrice(`${allDetails.price}`); } }}
          // onBlur={() => { if (canUpdPrice && fPrice > 0) setConfirmPrice(true); }}
          // onKeyPress={e => { if (canUpdPrice && fPrice > 0 && e.key === 'Enter') setConfirmPrice(true); }}
          value={price} onValueChange={canUpdPrice ? handlePrice : undefined}
        />
      </Grid>
      <Grid item xs={12} md={6} height={260}>
        <ReactQuill
          theme='snow' style={{ height: 200 }} value={priceNotes} onChange={e => setPriceNotes(e)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReactQuill
          theme='snow' style={{ height: 200 }} value={acceptedNotes} onChange={e => setAcceptedNotes(e)}
        />
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='flex-end'>
        <Button variant='outlined' color='primary' onClick={onCancel} sx={{ ml: 2 }}>
          <Typography variant='button' color='primary'>{canUpd ? 'cancel' : 'close'}</Typography>
        </Button>
        {canUpd && <Button
          variant='contained' color='info' sx={{ ml: 2 }}
          onClick={() => setConfirmPrice(true)} disabled={fPrice <= 0}
        >
          confirm
        </Button>}
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Typography variant='h4' style={{ borderTop: '1px #333 solid', borderBottom: '1px #333 solid' }}>
          list of attached files
        </Typography>
      </Grid>
      {allDetails.documents?.map((doc, idx) => {
        const isImg = doc.key.toLowerCase().endsWith('.jpg') ||
          doc.key.toLowerCase().endsWith('.jpeg') ||
          doc.key.toLowerCase().endsWith('.png') ||
          doc.key.toLowerCase().endsWith('.gif');
        if (isImg) {
          return <Grid key={'form_Page_' + idx} item xs={12} display='flex' justifyContent='center' style={{ borderBottom: '1px #333 solid' }}>
            <img alt={doc.key} style={{ maxWidth: '100%' }} src={doc.signedUrl || ''} />
          </Grid>
        }
        const isPdf = doc.key.toLowerCase().endsWith('.pdf')
        if (isPdf) {
          const fileName = doc.key.split('/');
          return <Grid key={'form_Page_' + idx} item xs={12} display='flex' flexDirection='column' alignItems='center' pb={3} style={{ borderBottom: '1px #333 solid' }}>
            <Typography variant='overline' color='info'>{fileName.slice(-1)}</Typography>
            <Button variant='outlined' color='primary' onClick={() => window?.open(doc.signedUrl || '', '_blank')?.focus()}>
              <Typography variant='button' color='secondary'>open pdf</Typography>
            </Button>
          </Grid>
        }

        const fileName = doc.key.split('/');
        return <Grid key={'form_Page_' + idx} item xs={12} display='flex' justifyContent='center' style={{ borderBottom: '1px #333 solid' }}>
          <Typography variant='overline' color='error'>unknown file: {fileName.slice(-1)} (unsafe to open)</Typography>
          {/* <Button variant='outlined' color='secondary' onClick={() => { window.open(doc.signedUrl || '', '_blank') }}>
            <Typography variant='button' color='error'>unknown file (unsafe to open)</Typography>
          </Button> */}
        </Grid>
      })}
    </>}
    <GenericModal open={confirmPrice} text={`${priceWarning} amout: $ ${cents}`} height='100%' width={350}>
      <Box display='flex' width='100%' mt={3} justifyContent='flex-end'>
        <Button size='small' onClick={() => setConfirmPrice(false)}>cancel</Button>
        <Button size='small' onClick={confirm}>I confirm</Button>
      </Box>
    </GenericModal>
  </Grid>
}

//
// Modal
//

export interface PreAppmtInfoModalProps extends PreAppmtInfoProps {
  open: boolean;
  onClose: () => void;
}

export function PreAppmtInfoModal({ open, onClose, ...rest }: PreAppmtInfoModalProps) {
  // const [t] = useTranslation();
  return <Dialog open={open} maxWidth='lg' fullWidth={true}>
    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.8rem' }}>
      {'pre-appointment details'}
      <IconButton aria-label='close' style={{ position: 'absolute', right: 22, top: 22, padding: 0 }} onClick={onClose}>
        <CancelOutlinedIcon style={{ fontSize: '28px' }} />
      </IconButton>
    </DialogTitle>
    <Box sx={{ m: 2 }}><PreAppmtInfo {...rest} /></Box>
  </Dialog>
}
