import { DateTime } from 'luxon';
//
import { isoToDateTime } from '@eksoh/shared/common';
import { Locale } from './generated/graphql';
import { LocaleClass } from './users/LocaleClass';

export function toStringFormatted(isoStrOrDate: string | Date, locale: Locale, timezone: string, displayLong?: boolean) {
  // const zoneFromOffset = FixedOffsetZone.instance(offset);
  const lang = new LocaleClass(locale).getLangEnum();
  let d = isoToDateTime(isoStrOrDate);
  if (!d) d = DateTime.now();
  return d.setZone(timezone)
          .setLocale(lang)
          .toLocaleString(displayLong ? DateTime.DATETIME_FULL : DateTime.DATETIME_MED);
}

export function toStringFormatPattern(isoStrOrDate: string | Date, locale: Locale, timezone: string, fmt: string) {
  // const zoneFromOffset = FixedOffsetZone.instance(offset);
  const lang = new LocaleClass(locale).getLangEnum();
  let d = isoToDateTime(isoStrOrDate);
  if (!d) d = DateTime.now();
  return d.setZone(timezone)
          .setLocale(lang)
          .toFormat(fmt);
}

export function toStringFormatDt(isoStrOrDate: string | Date, locale: Locale, timezone: string,  pattern: Intl.DateTimeFormatOptions) {
  const lang = new LocaleClass(locale).getLangEnum();
  let d = isoToDateTime(isoStrOrDate);
  if (!d) d = DateTime.now();
  return d.setZone(timezone)
          .setLocale(lang)
          .toLocaleString(pattern);
}
