/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO @fe DISABLED STRIPE: import Axios from 'axios';
import { StripeFormState } from '../../../modules/payment/stripe/reducer';
import { ONE_DAY_IN_MILLISECONDS } from '@eksoh/shared/common';

const localHost = window.location.hostname === 'localhost';

const baseUrl = localHost
  ? 'http://localhost/'
  : window.location.hostname // 'https://example.com'

const devPublishableKeyGet = () => {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({ data: { publishableKey: 'pk_test_nqH70Fb8FmabuVsU5kp4gpYf00XGNeVxyf' } });
    }, 50);
  })
}

// TODO @fe DISABLED STRIPE:
function fakeStripeCall(url: string, data: unknown) {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({ data: { publishableKey: 'pk_test_nqH70Fb8FmabuVsU5kp4gpYf00XGNeVxyf' } });
    }, 50);
  })
}

export const publishableKeyGet = () => {
  // TODO @fe DISABLED STRIPE: const url = baseUrl + '/publishableKeyGet.php';
  // return new Promise<any>(async resolve => {
  //   const { data: { publishableKey } } = localHost
  //     ? await devPublishableKeyGet()
  //     : await Axios.get(url);
  //   resolve(publishableKey)
  // });
  async function getData() {
    try {
      const { data: { publishableKey } } = localHost
        ? await devPublishableKeyGet()
        : await devPublishableKeyGet(); // TODO @fe DISABLED STRIPE: await Axios.get(url);
      return publishableKey;
    }
    catch(error) {
      return 'PUBLIC KEY ERROR: ' + error;
    }
  }
  return getData();
}

export const clientSecretPull = (data: StripeFormState) => {
  const url = baseUrl + 'capture.php'
  // return new Promise<any>(async (resolve) => {
  //   const { data: { clientSecret } } = await Axios.post(url, data);
  //   resolve(clientSecret)
  // });
  async function getData() {
    try {
      const { data: { clientSecret } } = await fakeStripeCall(url, data); // TODO @fe DISABLED STRIPE: await Axios.post(url, data);
      return clientSecret;
    }
    catch(error) {
      return 'SECRET PULL ERROR: ' + error;
    }
  }
  return getData();
}

export const clientSecretDataObjectConverter = ({
  staff,
  arrivaldate,
  service,
  fsm,
  email: receipt_email,
  currency,
  amount
}: any) => (
  {
    amount: amount * 100,
    currency: currency.code,
    cardType: 'card',
    receipt_email,
    metadata: {
      staff,
      arrivaldate,
      service,
      fsm,
    },
  } as any
)

export const stripeDataObjectConverter = ({ firstname, lastname, email, line1, line2, postal_code, city, country }: StripeFormState, cardElement: any) => ({
  payment_method: {
    card: cardElement,
    billing_details: {
      address: {
        city,
        country: country.code,
        line1,
        line2,
        postal_code,
        // state: null
      },
      email,
      name: `${firstname} ${lastname}`,
      // phone: null
    },
  },
})

export const setCookie = (name: string, value: number, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * ONE_DAY_IN_MILLISECONDS));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export const getCookie = (name: string) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; Max-Age=-99999999;';
}