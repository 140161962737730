import { ReactNode, SyntheticEvent, useState } from 'react';
import Hidden from '@mui/material/Hidden';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { withJsonFormsLayoutProps, JsonFormsDispatch } from '@jsonforms/react';
import { rankWith, uiTypeIs, LayoutProps, UISchemaElement } from '@jsonforms/core';

export const tabLayoutTester = rankWith(1000, uiTypeIs('TabLayout'));

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
}

//
// ===
//

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface TabLayoutSchemaConfig {
  label?: string;
}

export interface TabLayoutSchema extends UISchemaElement {
  elements: (UISchemaElement & { config: TabLayoutSchemaConfig })[];
}

export interface TabLayoutConfig {
  mt?: number | string;
  mb?: number | string;
}

export interface TabLayoutProps extends LayoutProps {
  uischema: TabLayoutSchema & { config?: TabLayoutConfig };
}

function TabLayout({ uischema, schema, path, visible, enabled, renderers, cells }: TabLayoutProps) {
  const [value, setValue] = useState(0);

  return <Hidden xsUp={!visible}>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
        <Tabs value={value} aria-label='basic tabs example'
          onChange={(_: SyntheticEvent, newValue: number) => setValue(newValue)}
        >
          {uischema.elements.map((child, index) => <Tab
            key={'tablbl'+index} label={child.config?.label || 'no label'} {...a11yProps(index)}
          />)}
        </Tabs>
      </Box>
      {uischema.elements.map((child, index) => <TabPanel key={'tabpanel'+index} value={value} index={index}>
        <JsonFormsDispatch
          schema={schema}
          uischema={child}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      </TabPanel>)}
    </Box>
  </Hidden>
};

export default withJsonFormsLayoutProps(TabLayout);
