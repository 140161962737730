import React from 'react';
import Box from '@mui/material/Box';
//
import Profile from '../shared/cmps/profile';

export function IClientInfo() {
  return <Box display='flex'>
    <Profile to='insurer' />
  </Box>
}
