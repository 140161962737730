import { useState } from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
//
import { useClientEmrStore } from '../../../../../../ui/src/lib/contexts/client-emr/client-emr'; // TODO: fix circular ref... '@eksoh/shared/ui';
import { PatientFormValues } from '../cmps/AddPatientForm';
import { AddPatientModal } from '../cmps/AddPatientModal';
import { HealthRatingBar } from '../cmps/HealthRatingBar';

export function PatientList() {
  const { clientEmrState, addPatient } = useClientEmrStore();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  function closeModal() {
    setModalOpen(false);
    setError(undefined);
  };

  async function submitNewPatient(values: PatientFormValues) {
    // console.log('submitNewPatient:', values);
    try {
      // const { data: newPatient } = await axios.post<Patient>(
      //   `${apiBaseUrl}/patients`,
      //   values
      // );
      // dispatch(addPatient(newPatient));
      addPatient({ ...values, id: `${(new Date()).getTime()}`, entries: [] });
      closeModal();
    }
    catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.error((e as any).response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any).response.data.error);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Occupation</TableCell>
              <TableCell>Health Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(clientEmrState.patients).map(patient => (
              <TableRow key={patient.id}>
                <TableCell><Link to={`/emr/patient/${patient.id}`}>{patient.name}</Link></TableCell>
                <TableCell>{patient.gender}</TableCell>
                <TableCell>{patient.occupation}</TableCell>
                <TableCell>
                  <HealthRatingBar showText={false} rating={1} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} sx={{ textAlign: 'end' }}>
                <Button variant='outlined' color='primary' startIcon={<AddIcon />} onClick={() => setModalOpen(true)}>add new patient</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <AddPatientModal
        modalOpen={modalOpen}
        onSubmit={submitNewPatient}
        error={error}
        onClose={closeModal}
      />
    </>
  );
};
