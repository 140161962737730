import { useTheme, Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const styles = {
  topAppBar: {
    height: '20vh',
    minHeight: 200,
  },
  toolbar: (theme: Theme) => ({
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }),
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginLeft: 40,
  },
};

export interface AppBarsProps {
  title: string;
  logoLink: string;
}

export default function AppBars({ title, logoLink }: AppBarsProps) {
  const theme = useTheme();

  return <AppBar position='static' elevation={0} color='default' style={styles.topAppBar}>
    <Toolbar style={styles.toolbar(theme)}>
      {title !== undefined &&
        <Grid container item direction='row' alignItems='center' xs={12} sm={6}>
          <Grid item>
            {logoLink !== undefined && <img src={`./${logoLink}`} alt='logo' height={30} />}
          </Grid>
          <Grid item>
            <Typography style={styles.title} variant='h4' noWrap>
              {title.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
      }
    </Toolbar>
  </AppBar>
}
