/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
//
// import theme from './theme'
// INTEG import { Conversation } from '@eksoh/shared/ui';
import { textChatStore } from '@eksoh/shared/ui';

// const { primaryColor } = theme;
const primaryColor = '#007bff';

export interface ConversationsProps {
	selected?: any; // INTEG Conversation;
	onChatSelected: (c?: any) => void; // INTEG Conversation) => void;
}

export function Conversations({ selected, onChatSelected }: ConversationsProps) {
  const { textChatState, joinTextChatConv, newTextChatConv } = useContext(textChatStore);

  useEffect(() => {
    if (textChatState.conversation != null) onChatSelected(textChatState.conversation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChatState.conversation]);

  if (selected == null) {
    const ucWithConv = textChatState.userConvs || [];
    const withUser = textChatState.withUser;
    return <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <Text style={styles.titleStyle}>available conversations</Text>
        {ucWithConv.length === 0 && <>
          <Text style={styles.noConversationsStyle}>no conversations</Text>
          <Text style={styles.crickets}>{'"cricket sounds..."'}</Text>
          {withUser != null && <TouchableOpacity style={[styles.btn, { marginTop: 20 }]} onPress={() => newTextChatConv(withUser)}>
            <Text>{'start one'}</Text>
          </TouchableOpacity>}
        </>}
        {ucWithConv.map((c, idx) =>
          <TouchableOpacity key={`CDCV_${idx}`} style={styles.btn} onPress={() => {
            if (c.conversation != null) {
              joinTextChatConv(c.conversation.id);
              onChatSelected(c.conversation);
            }
          }}>
            <Text>{c.conversation?.name}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  }

  return null;
}

const styles = StyleSheet.create({
  titleStyle: {
    color: primaryColor,
    marginTop: 6,
    marginBottom: 6,
    textAlign: 'center',
  },
  noConversationsStyle: {
    color: 'red',
    marginTop: 12,
    fontSize: 20,
    textAlign: 'center',
  },
  crickets: {
    color: 'red',
    marginTop: 4,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  btn: {
    backgroundColor: 'lightblue',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    marginBottom: 10,
  },
});
