/* eslint-disable @typescript-eslint/no-explicit-any */
// import styles from './user-detail.module.css';
import React, { useContext, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HomeIcon from '@mui/icons-material/Home';
import CakeIcon from '@mui/icons-material/Cake';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { DateTime } from 'luxon';
//
import { User } from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER
import { authStore, /* globalStore, */ GenderLabel, IconLabel, LocaleLabel } from '../../..';

const theme = createTheme();
const styles: { [key: string]: React.CSSProperties } = {
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  avatar: {
    height: 150,
    width: 150,
    margin: 10,
  },
  extra: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  miniIcon: {
    height: 38,
    width: 38,
    marginRight: 12,
  },
};

export interface UserDetailProps {
  isAdmin?: boolean
  user: User;
}

export function UserDetail({ user }: UserDetailProps) {
  const { authState } = useContext(authStore);
  // const [t] = useTranslation();

  const addr = useMemo(() => user.address != null
    ? `${user.address.streetAddress} ${user.address.locality} ${user.address.region} ${user.address.country}`
    : 'n/a'
  , [user]);

  const locale = (typeof authState.user?.locale === 'string'
   ? authState.user?.locale || 'en-CA'
   : authState.user?.localeClass.toLocaleStringDash() || 'en-CA').replace('_', '-');
  return <Card style={styles.root}>
    <Avatar alt={`${user.givenName} ${user.familyName}`} style={styles.avatar} />
    <div style={styles.details}>
      <CardContent style={styles.content}>
        <div style={{ display: 'flex' }}>
          {user.enabled && <CheckCircleIcon style={{ color: 'green', marginTop: 5, marginRight: 6 }} />}
          {!user.enabled && <CancelIcon style={{ color: 'red', marginTop: 5, marginRight: 6 }} />}
          <Typography component='h5' variant='h5'>
            {`${user.familyName}${user.middleName != null ? ` (${user.middleName})` : ''}, ${user.givenName}`}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <IconLabel icon={AlternateEmailIcon} text={user.email} />
          <Typography variant='subtitle1' color='textSecondary' style={{ padding: '0 10px' }}>|</Typography>
          <IconLabel icon={PhoneIphoneIcon} text={user.phoneNumber || 'n/a'} />
          <Typography variant='subtitle1' color='textSecondary' style={{ padding: '0 10px' }}>|</Typography>
          <GenderLabel gender={user.gender || undefined} />
          <Typography variant='subtitle1' color='textSecondary' style={{ padding: '0 10px' }}>|</Typography>
          <LocaleLabel locale={user.locale || undefined} />
        </div>
        <div style={{ height: 12 }} />
        <IconLabel icon={HomeIcon} text={addr} />
        <IconLabel icon={CakeIcon} text={user.birthdate ? DateTime.fromISO(user.birthdate).setLocale(locale).toLocaleString(DateTime.DATE_FULL) : 'n/a'} />
      </CardContent>
    </div>
  </Card>
}

//
// ===
//

export interface UserDetailOneLinerProps {
  // isAdmin?: boolean
  label: string;
  user: User;
}

export function UserDetailOneLiner({ /* isAdmin, */ label, user }: UserDetailOneLinerProps) {
  // const [t] = useTranslation();

  return <Box width='100%' mb={4}>
    {label}{user.name}{' - '}<EmailIcon sx={{ mb: -1 }} /> {user.email || 'no email'}{' -'}<SmartphoneIcon sx={{ mb: -1 }} />{user.phoneNumber || 'no phone number'}
  </Box>
}

export function UserDetailTwoLiners({ /* isAdmin, */ label, user }: UserDetailOneLinerProps) {
  // const [t] = useTranslation();

  return <Box width='100%' mb={4}>
    <Box width='100%' mb={0.5}>{label}{user.name}</Box>
    <Box width='100%' mb={4}><EmailIcon sx={{ mb: -1 }} /> {user.email || 'no email'}{' -'}<SmartphoneIcon sx={{ mb: -1 }} />{user.phoneNumber || 'no phone number'}</Box>
  </Box>
}
