/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice /* , isFulfilled, isPending, isRejected */ } from '@reduxjs/toolkit';
// 
import { serializeAxiosError } from '../../../redux/reducers/reducer.utils';
// TODO @fp: import { sanitizeObjTmp } from '@shared/util/misc';
// import { BeServices } from '../../../..';

export interface StripeFormState {
  date: string;
  service: string;
  facebook: string;
  twitter: string;
  firstname: string;
  lastname: string;
  email: string;
  line1: string;
  line2: string;
  postal_code: string;
  city: string;
  country: any;
  currency: any;
  amount: string;
}

const emptyForm: StripeFormState = {
  date: '',
  service: '',
  facebook: '',
  twitter: '',
  firstname: '',
  lastname: '',
  email: '',
  line1: '',
  line2: '',
  postal_code: '',
  city: '',
  country: null as any,
  currency: null as any,
  amount: '',
};

const initialState = {
  loading: false,
  errorMessage: null as any,
  formValues: {
    date: '',
    service: '',
    facebook: '',
    twitter: '',
    firstname: '',
    lastname: '',
    email: '',
    line1: '',
    line2: '',
    postal_code: '',
    city: '',
    country: null as any,
    currency: null as any,
    amount: '',
  },
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

// Actions

export const resetPayment = createAsyncThunk(
  'paymentStripe/resetPayment',
  async (nothing: undefined, { dispatch }) => {
    return null;
  },
  { serializeError: serializeAxiosError },
);

export const updatePayment = createAsyncThunk(
  'paymentStripe/updatePayment',
  async ({ key, value }: { key: string, value: any }, { dispatch }) => {
    return { key, value };
  },
  { serializeError: serializeAxiosError }
);

// Async Actions

// ...

export type StripePaymentState = Readonly<typeof initialState>;

export const stripePaymentSlice = createSlice({
  name: 'stripePayment',
  initialState: initialState as StripePaymentState,
  reducers: {
    resetStripe() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetPayment.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.formValues = emptyForm;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.formValues = { ...state.formValues, [action.payload.key]: action.payload.value };
      })
      // .addMatcher(isPending(getUsers, getUsersAsAdmin, getUser), state => {
      //   state.errorMessage = null;
      //   state.updateSuccess = false;
      //   state.loading = true;
      // })
      // .addMatcher(isPending(createUser, updateUser, deleteUser), state => {
      //   state.errorMessage = null;
      //   state.updateSuccess = false;
      //   state.updating = true;
      // })
      // .addMatcher(isRejected(getUsers, getUsersAsAdmin, getUser, getGroups, createUser, updateUser, deleteUser), (state, action) => {
      //   state.loading = false;
      //   state.updating = false;
      //   state.updateSuccess = false;
      //   state.errorMessage = action.error.message;
      // });
  },
});

export const { resetStripe } = stripePaymentSlice.actions;

// Reducer
export default stripePaymentSlice.reducer;
