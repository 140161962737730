import { Dispatch, SetStateAction, MutableRefObject, useState, useRef, useEffect } from 'react';

export function useStateRef<T>(initialState: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>];
export function useStateRef<T = undefined>(): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T>];

export function useStateRef<T>(initialState?: T): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T | undefined>] {
  const [value, setValue] = useState<T | undefined>(initialState);
  const valueRef = useRef<T | undefined>(initialState);

  useEffect(() => { valueRef.current = value }, [value]);

  return [value, setValue, valueRef];
}
