/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useContext, useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
//
import { authStore } from '@eksoh/shared/ui';
import { listData } from './reducer';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { BeServices } from '../../..';
import { Waiting } from '@eksoh/shared/ui-web'; // TODO @fp: that a big NO NO !!!
import { OnboardingInfo, OnboardingState } from '@eksoh/flo/model';
import { ShowOnboardingStep } from './cmps/showstep';
import { OnboardingStateIcon, DocumentCount } from './cmps/onboardingicons';

const styles = {
  table: {
    minWidth: 650,
  },
};

export interface OnboardingListProps {
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function OnboardingList({ onWaiting }: OnboardingListProps) {
  const { authState } = useContext(authStore);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [showStep, setShowStep] = useState<{ index: number, state: OnboardingState }>();
  const [t] = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => listDataFromProps(), []);

  // const [pagination, setPagination] = useState(
  //   overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  // );

  function listDataFromProps() {
    dispatch(listData());
    // const endURL = `?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  function autoNotes(notes: string, nextState: OnboardingState) {
    if (nextState === OnboardingState.ATCD_WAITING) {
      return notes + `\nBackground check sent on ${(new Date).toString()} by ${authState.user?.givenName} ${authState.user?.familyName}\n`;
    }
    if (nextState === OnboardingState.EMPLOYEE_INFO) {
      return notes + `\nBackground check approved on ${(new Date).toString()} by ${authState.user?.givenName} ${authState.user?.familyName}\n`;
    }
    if (nextState === OnboardingState.COMPLETED) {
      return notes + `\nEmployee accepted on ${(new Date).toString()} by ${authState.user?.givenName} ${authState.user?.familyName}\n`;
    }
    return notes;
  }

  async function handleNextOnboardingStep(username: string, notes: string, nextState: OnboardingState) {
    await BeServices.getInstance().user.adminSetOnboardingState({
      username,
      state: nextState,
      notes: autoNotes(notes, nextState),
    });
    listDataFromProps();
    setShowStep(undefined);
  };

  async function handleNotes(username: string, notes?: string) {
    await BeServices.getInstance().user.updateNotes({
      username,
      notes,
    });
  };

  // const { match } = props;
  // const account = useAppSelector(state => state.authentication.account);
  const dataList = useAppSelector(state => state.onboarding.items);
  const totalItems = useAppSelector(state => state.onboarding.totalItems);
  const loading = useAppSelector(state => state.onboarding.loading);

  // type OnboardingRecord = ListNurseOnboardingQuery['listNurseOnboarding'];
  function slowGetDetail(data: any, key: 'email' | 'phone') {
    const objProp = key === 'phone' ? 'phoneNumber' : 'email';
    try {
      if (data != null && data.rawData != null) {
        return data.user[objProp] || JSON.parse(data.rawData)[key];
      }
    }
    catch (error) { console.log('>>> error in slowGetDetail:', error) }
    return 'missing';
  }

  return <Box>
    <Box>
      <Box display='flex' justifyContent='space-between' style={{ padding: '30px 0' }}>
        <Typography variant='h4'>{t('userManagement.onboarding')}</Typography>
        <Box>
          <IconButton
            onClick={() => navigate(`${location.pathname}/new`)}
            disabled={loading}
            size='large'>
            <AddIcon style={{ fontSize: 24 }} />
          </IconButton>
          <IconButton onClick={listDataFromProps} disabled={loading} size='large'>
            <SyncIcon style={{ fontSize: 24 }} />
          </IconButton>
          {/* <IconButton onClick={() => navigate('/admin')} disabled={loading}>
            <ArrowBackIcon style={{ fontSize: 24 }} />
          </IconButton> */}
        </Box>
      </Box>
    </Box>
    {loading ? <Waiting colorSpinner='#0b4f61' height='65vh' /> : <Box style={{ paddingTop: 0 }}>
      <TableContainer component={Paper}>
        <Table style={styles.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('userManagement.name')}</TableCell>
              <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{t('userManagement.status')}</TableCell>
              <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{t('userManagement.doc')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList && dataList.map((data, i) => (
              <TableRow key={`sas_${i}`}>
                <TableCell component='th' scope='row'>
                  {data.user.name}{' - '}<EmailIcon sx={{ mb: -1 }} /> {slowGetDetail(data, 'email')}{' -'}<SmartphoneIcon sx={{ mb: -1 }} />{slowGetDetail(data, 'phone')}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                  <OnboardingStateIcon
                    state={OnboardingState.REG_STARTED} againts={data.state}
                    onClick={() => setShowStep({ index: i, state: OnboardingState.REG_STARTED })} // console.log('>>> CLICKED STATE:', state)}
                  />
                  <OnboardingStateIcon
                    state={OnboardingState.INTERVIEW} againts={data.state}
                    onClick={() => setShowStep({ index: i, state: OnboardingState.INTERVIEW })} // console.log('>>> CLICKED STATE:', state)}
                  />
                  <OnboardingStateIcon
                    state={OnboardingState.ATCD} againts={data.state}
                    onClick={() => setShowStep({ index: i, state: OnboardingState.ATCD })} // console.log('>>> CLICKED STATE:', state)}
                  />
                  <OnboardingStateIcon
                    state={OnboardingState.EMPLOYEE_INFO} againts={data.state}
                    onClick={() => setShowStep({ index: i, state: OnboardingState.EMPLOYEE_INFO })} // console.log('>>> CLICKED STATE:', state)}
                  />
                  <OnboardingStateIcon
                    state={OnboardingState.COMPLETED} againts={data.state}
                    onClick={() => setShowStep({ index: i, state: OnboardingState.COMPLETED })} // console.log('>>> CLICKED STATE:', state)}
                  />
                </TableCell>
                <TableCell>
                  <DocumentCount
                    count={data?.documents?.length || 0}
                    desc={<Fragment>
                      {data?.documents?.map((d, di) => <Typography key={'docName'+i+di}>{d.type}</Typography>)}
                    </Fragment>}
                  />
                </TableCell>
              </TableRow>
            ))}
            {!totalItems && <TableRow>
              <TableCell component='th' scope='row' colSpan={5}>{t('userManagement.nothingFound')}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <ShowOnboardingStep
        show={showStep != null}
        onAction={handleNextOnboardingStep}
        onDismiss={() => setShowStep(undefined)}
        data={dataList == null || showStep == null || (showStep.index < 0 && showStep.index > dataList.length - 1)
          ? undefined
          : dataList[showStep.index] as OnboardingInfo
        }
        state={showStep?.state || OnboardingState.REG_STARTED}
        onNoteUpd={handleNotes}
        onWaiting={onWaiting}
      />
    </Box>}
  </Box>
};
