import { CSSProperties, /* // TODO @fe DISABLED STRIPE: useState, */ useEffect } from 'react';
import Container  from '@mui/material/Container';
// TODO @fe DISABLED STRIPE: import Paper  from '@mui/material/Paper';
import Box  from '@mui/material/Box';
// TODO @fe DISABLED STRIPE: import CustomizedSteppers from './Stepper';
// TODO @fe DISABLED STRIPE: import { Elements, } from '@stripe/react-stripe-js';
// TODO @fe DISABLED STRIPE: import { loadStripe, Stripe } from '@stripe/stripe-js';
// TODO @fe DISABLED STRIPE: import { publishableKeyGet } from '../constants/functions'
// import SetupForm from './SetupForm';
// import { BeServices } from '../../../..';





const styles = {
  boxWrapper: {
    marginBottom: 55,
    minHeight: 'calc(26vh + 260px)',
  },
  container: {
    position: 'relative',
    zIndex: '1100',
    marginTop: -95,
    marginBottom: 45,
  } as CSSProperties,
};

const Main = () => {
  // TODO @fe DISABLED STRIPE: const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

  useEffect(() => {
    const retrievePublishableKey = async () => {
      // TODO @fe DISABLED STRIPE: const publishableKey = await publishableKeyGet()
      // TODO @fe DISABLED STRIPE: const stripe = loadStripe(publishableKey);
      // TODO @fe DISABLED STRIPE: setStripePromise(stripe)
      // // const publishableKey = await publishableKeyGet()
      // const stripe = loadStripe('pk_test_51LAb17I4F5yokKDp4M2TSNrmDIDMTYptLYgGUcxMV37dxK5HabYbid7SE5xkfGu4SuCJogOJnrlmlCMYOha7Y2DA00i2Eesqms');
      // setStripePromise(stripe);
      // console.debug('>>>>>> SECRET', si?.client_secret);
      // setClientSecret(si?.client_secret || '');

    }
    retrievePublishableKey()
  }, [])

  // const options = {
  //   // passing the client secret obtained in step 3
  //   clientSecret,
    
  // };

  // return (
  //   <Elements stripe={stripePromise} options={options}>
  //     <SetupForm />
  //   </Elements>
  // );
  return <Box component='main' style={styles.boxWrapper}>
    <Container maxWidth='md' style={styles.container}>
      {/* // TODO @fe DISABLED STRIPE: <Paper elevation={5}>
        {stripePromise
          ? <Elements stripe={stripePromise}>
              <CustomizedSteppers />
            </Elements>
          : null
        }
      </Paper>*/}
    </Container>
  </Box>
}

export default Main;