import { useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

// project imports
import MainCard from './main-card';
import { EarningCard as SkeletonEarningCard } from './skeleton/earning-card';

// assets
// import EarningIcon from '../../../assets/imgs/icons/earning.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
// import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
// import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
// import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';
import ThermostatIcon from '@mui/icons-material/Thermostat';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.secondary, // [800],
    borderRadius: '50%',
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: theme.palette.secondary, // [800],
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

export function EarningCard({ isLoading }: { isLoading: boolean }) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return isLoading
    ? <SkeletonEarningCard />
    : <CardWrapper border={false} content={false}>
      <Box sx={{ p: 2.25 }}>
        <Grid container direction='column'>
          <Grid item>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Avatar
                  variant='rounded'
                  sx={{
                    // ...theme.typography.commonAvatar,
                    // ...theme.typography.largeAvatar,
                    backgroundColor: theme.palette.secondary.main, // [800],
                    mt: 1
                  }}
                >
                  {/* <img src={EarningIcon} alt='Notification' /> */}
                  <ThermostatIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Avatar
                  variant='rounded'
                  sx={{
                    // ...theme.typography.commonAvatar,
                    // ...theme.typography.mediumAvatar,
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.main, // [200],
                    zIndex: 1
                  }}
                  aria-controls='menu-earning-card'
                  aria-haspopup='true'
                  onClick={handleClick}
                >
                  <MoreHorizIcon fontSize='inherit' />
                </Avatar>
                <Menu
                  id='menu-earning-card'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  variant='selectedMenu'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> entrer nouvelle valeur
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>
                    <GetAppTwoToneIcon sx={{ mr: 1.75 }} /> Import Card
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <FileCopyTwoToneIcon sx={{ mr: 1.75 }} /> Copy Data
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <PictureAsPdfTwoToneIcon sx={{ mr: 1.75 }} /> Export
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <ArchiveTwoToneIcon sx={{ mr: 1.75 }} /> Archive File
                  </MenuItem> */}
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems='center'>
              <Grid item>
                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                  37.9 &deg;C
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    cursor: 'pointer',
                    // ...theme.typography.smallAvatar,
                    backgroundColor: theme.palette.secondary.main, // [200],
                    color: theme.palette.secondary.dark
                  }}
                >
                  <ArrowUpwardIcon fontSize='inherit' sx={{ transform: 'rotate3d(1, 1, 1, 45deg)' }} />
                </Avatar>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ mb: 1.25 }}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                color: theme.palette.secondary.light, // [200]
              }}
            >
              moyenne
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
}
