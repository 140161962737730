/**
  =========================================================
  * eksöh ® corporate kit
  =========================================================

  * Contact: François Paquette
  * Copyright 2022 eksöh ® (https://www.eksoh.com)

  Property of eksöh ®

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from 'yup';
import { EmrPatientForm } from './form';

const {
  formField: {
    firstName, lastName, address1, city, state, zip, email, // password, repeatPassword, twitter,
    ssn, dob, occupation, gender,
  },
} = EmrPatientForm;

export const EmrPatientFormValidations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    // [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    // [repeatPassword.name]: Yup.string()
    //   .required(repeatPassword.errorMsg)
    //   .oneOf([Yup.ref('password'), null], repeatPassword.invalidMsg),
  // }),
  // Yup.object().shape({
    [address1.name]: Yup.string().required(address1.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.string().required(city.errorMsg),
    [zip.name]: Yup.string().required(zip.errorMsg).min(6, zip.invalidMsg),
  // }),
  // Yup.object().shape({
  //   [twitter.name]: Yup.string().required(twitter.errorMsg),
  // }),
  // Yup.object().shape({
    [ssn.name]: Yup.string().required(ssn.errorMsg),
    [dob.name]: Yup.string().required(dob.errorMsg),
    [occupation.name]: Yup.string().required(occupation.errorMsg),
    [gender.name]: Yup.string().required(gender.errorMsg),
  }),
];
