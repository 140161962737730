/* eslint-disable @typescript-eslint/no-explicit-any */

import {useState, useEffect } from 'react';
import {
  ScrollView,
  ScrollViewProps,
  View,
  ViewStyle,
} from 'react-native';
import { useTheme, FAB, Avatar } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import i18n from 'i18next';
//
import { appMaxWidth, appMaxWidthOnboarded } from '@eksoh/flo/ui';

export interface LayoutProps extends ScrollViewProps {
  children: React.ReactNode;
  withScrollView?: boolean;
  style?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  phoneSize?: boolean;
  hideFab?: boolean;
};

export default function Layout({
  children,
  withScrollView = true,
  style,
  contentContainerStyle,
  phoneSize,
  hideFab,
  ...rest
}: LayoutProps) {
  const [expand, setExpand] = useState(false);
  const [lang, setLang] = useState(i18n.language.substring(0, 2));

  useEffect(() => {
    i18n.changeLanguage(lang);
    window.document.documentElement.lang = lang;
  }, [lang]);

  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const containerStyle = {
    backgroundColor: theme.colors.background,
    paddingTop: 6 + insets.top,
    paddingBottom: 6 + insets.bottom,
    paddingLeft: 6 + insets.left,
    paddingRight: 6 + insets.left,
    alignItems: 'center' as const,
    width: '100%',
    ...contentContainerStyle,
  };
  const svStyle = {
    flex: 1,
    width: '100%',
    maxWidth: phoneSize ? appMaxWidth : appMaxWidthOnboarded,
    ...style,
  };

  const view = withScrollView
    ? <ScrollView
      {...rest}
      contentContainerStyle={containerStyle}
      alwaysBounceVertical={false}
      showsVerticalScrollIndicator={false}
      style={svStyle}
    >
      {children}
    </ScrollView>
    : <View style={[svStyle, containerStyle]}>
      {children}
    </View>

  return <View style={{ width: '100%', alignItems: 'center' }}>
    {view}
    <FAB.Group
      fabStyle={{ backgroundColor: '#ccc' }}
      visible={!hideFab}
      open={expand}
      icon={() => <View style={{ marginLeft: -4, marginTop: -4 }}><Avatar.Text size={32} label={lang.substring(0, 2)} color='black' style={{ padding: 0, backgroundColor: 'rgb(204, 204, 204)' }} /></View>}
      actions={[
        {
          icon: () => <Avatar.Text size={24} label='en' color='black' style={{ backgroundColor: 'white' }} />,
          label: 'english',
          onPress: () => setLang('en_CA' as any),
        },
        {
          icon: () => <Avatar.Text size={24} label='fr' color='black' style={{ backgroundColor: 'white' }} />,
          label: 'français',
          onPress: () => setLang('fr_CA' as any),
        },
        // { icon: 'plus', onPress: () => console.log('pressed add') },
        // {
        //   icon: 'star',
        //   label: 'star',
        //   onPress: () => console.log('pressed star'),
        // },
        // {
        //   icon: 'email',
        //   label: 'email',
        //   onPress: () => console.log('pressed email'),
        // },
        // {
        //   icon: 'bell',
        //   label: 'remind',
        //   onPress: () => console.log('pressed notifications'),
        //   small: false,
        // },
      ]}
      onStateChange={e => setExpand(e.open)}
      onPress={() => {
        if (expand) {
          // do something if the speed dial is open
        }
      }}
    />
  </View>
}
