/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useReducer, Dispatch, useRef, useEffect } from 'react';
// INTEG import { GraphQLAPI } from '@aws-amplify/api-graphql';
//
// INTEG import { User, UserConversations, Conversation, Message } from '../../backend/core';
// INTEG import { TZ_AMERICA_MONTREAL } from '@eksoh/shared/model';
import { authStore } from '../../..';
// INTEG import { ChatApiClient } from '../../backend/services/chat/client';

// #region Actions

export enum eChatActions {
  SET_LOADING,
  SET_WITH_USER, SET_USER_CONVERSATIONS, SET_CONVERSATION,
  SET_MESSAGES, CREATE_MESSAGE, UPD_MESSAGE,
  LEAVE,
}

interface SetIsLoadingAction {
  type: typeof eChatActions.SET_LOADING;
  state: boolean;
}

interface SetConversationsAction {
  type: typeof eChatActions.SET_USER_CONVERSATIONS;
  userConvs?: any[]; // INTEG UserConversations[];
}

interface SetWithUserAction {
  type: typeof eChatActions.SET_WITH_USER;
  withUser?: any; // INTEG User;
}

interface SetConversationAction {
  type: typeof eChatActions.SET_CONVERSATION;
  conversation?: any; // INTEG Conversation;
}

interface SetMessagesAction {
  type: typeof eChatActions.SET_MESSAGES;
  msgs: any[]; // INTEG Message[];
}

interface CreateMessageAction {
  type: typeof eChatActions.CREATE_MESSAGE;
  msg: any; // INTEG Message;
}

interface UpdMessageAction {
  type: typeof eChatActions.UPD_MESSAGE;
  msg: any; // INTEG Message;
}

interface LeaveAction {
  type: typeof eChatActions.LEAVE;
}

export type ChatActionTypes = SetIsLoadingAction |
  SetWithUserAction | SetConversationsAction | SetConversationAction |
  SetMessagesAction | CreateMessageAction | UpdMessageAction |
  LeaveAction;

// #endregion Actions

// #region Reducer

export interface ITextChatState {
  userConvs?: any[]; // INTEG UserConversations[];
  withUser?: any; // INTEG User;
  conversation?: any; // INTEG Conversation;
  messages: any[]; // INTEG Message[];
  loading: boolean;
}

const initialState: ITextChatState = {
  messages: [],
  loading: false,
}

export interface ITextChatContext {
  textChatState: ITextChatState;
  dispatch: Dispatch<any>;
  getChatApiClient: () => any; // INTEG ChatApiClient | undefined;
  listConvsForUser: (user?: any) => any; // INTEG User) => any;
  joinTextChatConv: (convId?: string) => any;
  newTextChatConv: (user: any) => any; // INTEG User) => any;
  sendMsg: (content: string) => void;
  // markAsUnread: (idx: string) => void;
  leaveTextChat: () => void;
}

export const textChatStore = createContext<ITextChatContext>({
  textChatState: initialState,
  dispatch: () => null,
  getChatApiClient: () => { throw new Error('Not implemented.'); },
  listConvsForUser: () => { throw new Error('Not implemented.'); },
  joinTextChatConv: () => { throw new Error('Not implemented.'); },
  newTextChatConv: () => { throw new Error('Not implemented.'); },
  sendMsg: () => { throw new Error('Not implemented.'); },
  // markAsUnread: () => { throw new Error('Not implemented.'); },
  leaveTextChat: () => { throw new Error('Not implemented.'); },
});

function reducer(state: ITextChatState, action: ChatActionTypes) {
  switch (action.type) {
    case eChatActions.SET_LOADING:
      return { ...state, loading: action.state };
    case eChatActions.SET_WITH_USER:
      return { ...state, withUser: action.withUser };
    case eChatActions.SET_USER_CONVERSATIONS:
      return { ...state, userConvs: action.userConvs };
    case eChatActions.SET_CONVERSATION:
      return { ...state, conversation: action.conversation };
    case eChatActions.SET_MESSAGES:
      return { ...state, messages: action.msgs };
    case eChatActions.CREATE_MESSAGE:
      return { ...state, messages: [...state.messages, action.msg] };
    case eChatActions.UPD_MESSAGE:
      return {
        ...state, messages: state.messages.map(m => m.id === action.msg.id ? action.msg : m),
      };
    case eChatActions.LEAVE:
      return initialState;
    default:
      return state;
  }
}

// #endregion Reducer

export interface TextChatPros {
  children: React.ReactNode | React.ReactNode[];
}

export function TextChat(props: TextChatPros) {
  const { authState } = useContext(authStore);
  const [textChatState, dispatch] = useReducer(reducer, initialState);
  const chatApiClient = useRef<any>(); // INTEG useRef<ChatApiClient>();
  const onMessage = useRef<any>();

  useEffect(() => {
    // INTEG chatApiClient.current = new ChatApiClient(GraphQLAPI, authState.jwtToken, TZ_AMERICA_MONTREAL);
  }, []);

  useEffect(() => {
    if (onMessage.current != null) onMessage.current.unsubscribe();
    if (textChatState.conversation != null) onMessage.current = subscribeToMsgs(textChatState.conversation.id);

    return () => {
      if (onMessage.current != null) onMessage.current.unsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textChatState.conversation]);

  // #region Internal Functions

  async function subscribeToMsgs(conversationId: string) {
    if (chatApiClient.current == null) return;
    onMessage.current = chatApiClient.current.subscribeToNewMessage(conversationId)
      .subscribe({
        next: async (subscriptionData: any) => { // INTEG next: async subscriptionData => {
          if (authState.chatInfo == null || subscriptionData.value.data == null) return;
          // const { value: { data: { subscribeToNewMessage } } } = subscriptionData;
          const msg = subscriptionData.value.data.subscribeToNewMessage;
          if (msg != null && msg.sender === authState.chatInfo.myChatUserId) {
            // if one day support offline msg, its my msg, mark it sent
          }
          else {
            if (msg) dispatch({ type: eChatActions.CREATE_MESSAGE, msg });
          }
        }
      });
  }

  // #endregion Internal Functions

  // #region Functions

  function getChatApiClient() { return chatApiClient.current; }

  async function listConvsForUser(withUser?: any) { // INTEG User) {
    if (chatApiClient.current == null) return;
    dispatch({ type: eChatActions.SET_WITH_USER, withUser });
    if (withUser == null) return;
    dispatch({ type: eChatActions.SET_LOADING, state: true });
    try {
      const userConv = await chatApiClient.current.getUserConversationConnectionThroughUser({ after: '', first: 1000 });
      const me = userConv.me;
      if (me != null && me.conversations != null && me.conversations.userConversations != null) {
        const allConvs = [] as any[]; // INTEG me.conversations.userConversations.filter(c => c != null) as UserConversations[];
        const userConvs = allConvs.filter(
          () => false // INTEG c => c.associated != null && c.associated.filter(au => au != null && au.userId === withUser?.id).length > 0
        );
        dispatch({ type: eChatActions.SET_USER_CONVERSATIONS, userConvs });
      }
    }
    catch (err) {
      console.log('error: ', err)
    }
    finally {
      dispatch({ type: eChatActions.SET_LOADING, state: false });
    }
  }

  async function joinTextChatConv(convId?: string) {
    if (chatApiClient.current == null || textChatState.withUser == null) return;
    if (convId == null) {
      dispatch({ type: eChatActions.SET_CONVERSATION, conversation: undefined });
      return;
    }
    dispatch({ type: eChatActions.SET_LOADING, state: true });
    try {
      if (textChatState.userConvs != null) {
        const getConv = textChatState.userConvs.find(c => c.conversationId === convId);
        if (getConv != null && getConv.conversation != null) {
          dispatch({ type: eChatActions.SET_CONVERSATION, conversation: getConv.conversation });
          const allMsgs = await chatApiClient.current.getConversationMessages({ conversationId: getConv.conversation.id });
          dispatch({ type: eChatActions.SET_MESSAGES, msgs: allMsgs || [] });
        }
      }
    }
    catch (err) {
      console.log('error: ', err)
    }
    finally {
      dispatch({ type: eChatActions.SET_LOADING, state: false });
    }
  }

  async function newTextChatConv(withUser: any) { // INTEG User) {
    if (chatApiClient.current == null || authState.chatInfo == null || authState.username == null) return;
    try {
      // TODO: Bundle in one call
      dispatch({ type: eChatActions.SET_WITH_USER, withUser });
      // Add conversation
      const conv = await chatApiClient.current.createConversation(`${withUser.username} is in the house!`);
      // Add me to the conversation
      await chatApiClient.current.createUserConversations(conv.id, withUser.id);
      // Add user to the conversation
      await chatApiClient.current.createUserConversations(conv.id, authState.chatInfo.myChatUserId);
      dispatch({ type: eChatActions.SET_USER_CONVERSATIONS, userConvs:[{
        // associated?: Maybe<Array<Maybe<UserConversations>>>;
        conversation: conv,
        conversationId: conv.id,
        user: authState.user,
        userId: authState.cognitoUser?.getUsername(),
      }] });
      dispatch({ type: eChatActions.SET_CONVERSATION, conversation: conv });
    }
    catch (err) {
      console.log('error starting conversation:', err);
    }
  }

  async function sendMsg(content: string) {
    if (chatApiClient.current == null || textChatState.conversation == null || !content || authState.chatInfo == null) return;

    const msg = await chatApiClient.current.createMessage(
      textChatState.conversation.id, authState.chatInfo.myChatUserId, content
    );
    dispatch({ type: eChatActions.CREATE_MESSAGE, msg });
  }

  function leaveTextChat() {
    dispatch({ type: eChatActions.LEAVE });
    if (onMessage.current) onMessage.current.unsubscribe();
  }

  // #endregion Functions

  return <textChatStore.Provider value={{
    textChatState, dispatch, getChatApiClient, listConvsForUser, joinTextChatConv, newTextChatConv, sendMsg, leaveTextChat,
  }}>
    {props.children}
  </textChatStore.Provider>
}
