/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, ReactNode, SyntheticEvent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
//
import {
  listForms, adminGetForm, resetForm, adminCreateForm, adminUpdForm,
  listUserForms, getUserForm, createUserForm, resetUserForm,
} from './reducer';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { miniForm } from './cmps/forms';
import FormsList from './cmps/formsList';
import UserFormsList from './cmps/userFormsList';
import FormEdit from './cmps/formedit';
import FormView from './cmps/formview';
import NewForm from './cmps/newform';
import FormEditor from './editor';

//
// ===
//

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
}

//
// ===
//

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    // 'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface FormsMngmtProps {
  me: string;
  username?: string;
  getUser: ReactNode;
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function FormsMngmt({ me, username, getUser, onWaiting }: FormsMngmtProps) {
  const [value, setValue] = useState(0);
  const [assignForm, setAssignForm] = useState(false);
  const [startNew, setStartNew] = useState(false);
  const [useEditor, setUseEditor] = useState(false);
  const dispatch = useAppDispatch();

  const loadingF = useAppSelector(state => state.forms.loadingForms);
  const loadingUF = useAppSelector(state => state.forms.loadingUserForms);
  const form = useAppSelector(state => state.forms.form);
  const userForm = useAppSelector(state => state.forms.userForm);
  const updateSuccess = useAppSelector(state => state.forms.updateSuccess);

  useEffect(() => {
    if (updateSuccess && username) dispatch(listUserForms(username));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, username])

  function saveForm(model: unknown) {
    if (form != null) dispatch(adminUpdForm({ name: form.name, model, data: form.data }));
    dispatch(resetForm());
  }

  function assignUserForm(data?: { username: string, name: string }) {
    if (data != null) {
      dispatch(createUserForm(data));
    }
    setAssignForm(false);
  }

  function startEditForm(name: string, withEditor: boolean) {
    setUseEditor(withEditor);
    dispatch(adminGetForm(name));
  }

  return <Box sx={{ width: '100%' }}>
    <Box display='flex' justifyContent='space-between' sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={(event: SyntheticEvent, newValue: number) => setValue(newValue)}>
        <Tab label='Users' {...a11yProps(0)} />
        <Tab label='Forms' {...a11yProps(1)} />
      </Tabs>
      {value === 0 && <Box>
        <IconButton
          onClick={() => setAssignForm(true)}
          disabled={loadingUF || !username}
          size='large'
        >
          <AddIcon style={{ fontSize: 24 }} />
        </IconButton>
        <IconButton
          onClick={() => { if (username) dispatch(listUserForms(username)); }}
          disabled={loadingUF || !username}
          size='large'
        >
          <SyncIcon style={{ fontSize: 24 }} />
        </IconButton>
        {/* <IconButton onClick={() => navigate('/admin')} disabled={loading}>
          <ArrowBackIcon style={{ fontSize: 24 }} />
        </IconButton> */}
      </Box>}
      {value === 1 && <Box>
        <IconButton
          onClick={() => setStartNew(true)}
          disabled={loadingF}
          size='large'
        >
          <AddIcon style={{ fontSize: 24 }} />
        </IconButton>
        <IconButton
          onClick={() => dispatch(listForms())}
          disabled={loadingF}
          size='large'
        >
          <SyncIcon style={{ fontSize: 24 }} />
        </IconButton>
        {/* <IconButton onClick={() => navigate('/admin')} disabled={loading}>
          <ArrowBackIcon style={{ fontSize: 24 }} />
        </IconButton> */}
      </Box>}
    </Box>
    <TabPanel value={value} index={0}>
      <UserFormsList
        username={username} getUser={getUser} assignForm={assignForm} onFormAssign={assignUserForm}
        onView={(name, username) => dispatch(getUserForm({ name, username }))} onWaiting={onWaiting}
      />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <FormsList
        onEdit={name => startEditForm(name, false)} onEditor={name => startEditForm(name, true)}
        onWaiting={onWaiting}
      />
    </TabPanel>
    {!useEditor && form && <FormEdit
      open={true} onClose={() => dispatch(resetForm())} onSave={saveForm}
      name={form.name} model={form.model} data={form.data}
    />}
    {useEditor && form && <FormEditor
      open={true} onClose={() => dispatch(resetForm())} onSave={saveForm}
      name={form.name} model={form.model} data={form.data}
    />}
    <FormView
      open={Boolean(userForm)} onClose={() => dispatch(resetUserForm())}
      name={userForm?.name || 'error'} data={userForm?.data}
    />
    <NewForm
      open={startNew} onCancel={() => setStartNew(false)}
      onCreate={(name, category) => {
        dispatch(adminCreateForm({ name, category, model: miniForm }));
        setStartNew(false);
      }}
    />
  </Box>
}
