/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
//
import { Group, ListUsersQuery } from '@eksoh/flo/model'; // TODO: @sb THATS A NO NO BUT WILL DO UNTIL WE HAVE A SHARED USER
import { authStore } from '../../../../';

import { deleteUser, getUsersAsAdmin } from './reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

type ListUser = ListUsersQuery['listUsers'][0];

export function UserManagementDeleteDialog() {
  const { authState } = useContext(authStore);
  const [user, setUser] = useState<ListUser>();
  const [canDelete, setCanDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const users = useAppSelector(state => state.userManagement.users);

  useEffect(() => {
    let found = false;
    users.forEach(u => { if (u.username === params.uuid) {
      setCanDelete(
        authState.curGroups != null &&
        authState.curGroups.includes(Group.SUPER_ADMIN) &&
        // TODO @fe // TODO @be - this is super temporary, only to get the mvp going...
        u.email.toLowerCase() !== 'su@flowrence.ca'
      );
      setUser(u);
      found = true;
    }})
    if (!found) navigate('/admin');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleClose = (event: any) => {
    event.stopPropagation();
    navigate('/admin');
  };

  const confirmDelete = async (event: any) => {
    try {
      if (user) {
        setDeleting(true);
        await dispatch(deleteUser(user.username)).unwrap();
        dispatch(getUsersAsAdmin({}));
        handleClose(event);
      }
    }
    catch (err) {
      console.error('delete user error:', err);
    }
    finally {
      setDeleting(false);
    }
  }

  if (!user) return null;

  return <Dialog open={true} onClose={handleClose}>
    <DialogTitle>
      {t('entity.delete.title')}
    </DialogTitle>
    <DialogContent>
      {t('userManagement.delete.question', { email: user.email })}
      {deleting && <Alert severity='info' sx={{ mt: 2 }}>{t('userManagement.deleting')}</Alert>}
      {!canDelete && <Alert severity='info' sx={{ mt: 2 }}>{'sorry, this user cannot be deleted.'}</Alert>}
    </DialogContent>
    <DialogActions>
      <Button color='secondary' onClick={handleClose}>
        <BlockIcon />
        &nbsp;
        {t('entity.action.cancel')}
      </Button>
      <Button color='secondary' onClick={confirmDelete} disabled={!canDelete}>
        <DeleteIcon />
        &nbsp;
        {t('entity.action.delete')}
      </Button>
    </DialogActions>
  </Dialog>
}
