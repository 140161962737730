// import { useState /* , useMemo */ } from 'react';
//
import { OnboardingInfo, OnboardingData } from '@eksoh/flo/model';

export interface EmployeeInfoProps {
  data: OnboardingInfo;
  onbData: OnboardingData;
}

export function EmployeeInfo({ data, onbData }: EmployeeInfoProps) {
  return null;
  // return <Grid container mt={2}>
  //   <Grid item xs={12}>
  //     <Typography variant='h1' mt={5} mb={5} textAlign='center'>COMING SOON</Typography>
  //   </Grid>
  // </Grid>
}