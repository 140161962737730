import { 
  AUTH_TYPE, 
  AuthOptions
} from "aws-appsync-auth-link";
import { Credentials, CredentialProvider } from '@aws-sdk/types';
//
import {
  CreateUserInput,
  ListUsersDocument,
  CreateUserDocument,
  GetUserDocument,
  DeleteUserDocument,
  ListDataDocument,
  GetDataDocument,
  PutDataInput,
  DeleteDataDocument,
  PutDataDocument,
  OnboardingRawData,
  SubmitNurseRegistrationInput,
  SubmitNurseRegistrationDocument,
  AdminUpdateUserDocument,
  AdminUpdateUserInput,
  ListNurseOnboardingDocument,
  ListNurseOnboardingInput,
  OnboardingData,
  AdminSetOnboardingStateInput,
  AdminSetOnboardingStateDocument,
  ValidateVerifyCodeDocument,
  UpdateNotesInput,
  UpdateNotesDocument,
  OnboardingEmployeeInfoDocument,
  OnboardingPostInterviewDocument,
  GetOnboardingInfoDocument,
  Group,
  AdmCreateEmrPatientInput,
  AdmCreateEmrPatientDocument,
  UpdateUserAttribInput,
  UpdateUserAttribDocument
} from '@eksoh/flo/model';
import { ApolloBase } from './apollobase';
import { AppsyncConfigParams } from '@eksoh/shared/common';


export class UserServiceClient extends ApolloBase {
  constructor(
    params: AppsyncConfigParams,
    jwtToken?: string,
    credentials?: (() => Credentials | CredentialProvider | Promise<Credentials | CredentialProvider | null>) | Credentials | CredentialProvider | null,
    tz?: string
    ) {
      let auth: AuthOptions;
      if (credentials) {
        auth = {
          type: AUTH_TYPE.AWS_IAM,
          credentials
        };
      } else {
        auth = {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => jwtToken || '',
        };
      }
      
      super(params, auth, tz);
  }

  // async updateUser(input: UpdateUserInput) {
  //   const result = await this.client.mutate({
  //     mutation: UpdateUserDocument,
  //     variables: { input }
  //   });
  //   return result.data?.updateUser;
  // }

  async createUser(input: CreateUserInput) {
    const result = await this.client.mutate({
      mutation: CreateUserDocument,
      variables: { input }
    });
    return result.data?.createUser;
  }

  async adminUpdateUser(input: AdminUpdateUserInput) {
    const result = await this.client.mutate({
      mutation: AdminUpdateUserDocument,
      variables: { input }
    });
    return result.data?.adminUpdateUser;
  }

  async updateUserAttrib(input: UpdateUserAttribInput) {
    const result = await this.client.mutate({
      mutation: UpdateUserAttribDocument,
      variables: { input }
    });
    return result.data?.updateUserAttrib;
  }

  // SUPER_ADMIN
  async listUsers(groups?: Group[]) {
    const result = await this.client.query({
      query: ListUsersDocument,
      variables: { input: { groups } }
    });
    return result.data.listUsers;
  }

  async getUser(username: string) {
    const result = await this.client.query({
      query: GetUserDocument,
      variables: { username }
    });
    return result.data.getUser;
  }

  async deleteUser(username: string) {
    const result = await this.client.mutate({
      mutation: DeleteUserDocument,
      variables: { username }
    });
    return result.data?.deleteUser;
  }

  async listData() {
    const result = await this.client.query({
      query: ListDataDocument
    });
    return result.data.listData;
  }

  async getData() {
    const result = await this.client.query({
      query: GetDataDocument
    });
    return result.data.getData;
  }

  async putData(input: PutDataInput) {
    const result = await this.client.mutate({
      mutation: PutDataDocument,
      variables: { input }
    });
    return result.data?.putData;
  }

  async deleteData() {
    const result = await this.client.mutate({
      mutation: DeleteDataDocument
    });
    return result.data?.deleteData;
  }

  private marshall(gen?: OnboardingRawData | null) {
    return gen && {
      ...gen,
      data: JSON.parse(gen.data)
    };
  }
  async putOnboardingData(data: OnboardingData, version = '1.0.0') {
    const r = await this.putData({
      data: JSON.stringify(data),
      version
    });
    return this.marshall(r);
  }

  async getOnboardingData() {
    const r = await this.getData();
    return this.marshall(r);
  }

  async deleteOnboardingData() {
    return this.deleteData();
  }

  async updateNotes(input: UpdateNotesInput) {
    const result = await this.client.mutate({
      mutation: UpdateNotesDocument,
      variables: { input }
    });
    return result.data?.updateNotes;
  }

  async submitNurseRegistration(input: SubmitNurseRegistrationInput) {
    const result = await this.client.mutate({
      mutation: SubmitNurseRegistrationDocument,
      variables: { input }
    });
    return result.data?.submitNurseRegistration;
  }
  
  async listNurseOnboarding(input: ListNurseOnboardingInput) {
    const result = await this.client.query({
      query: ListNurseOnboardingDocument,
      variables: { input }
    });
    return result.data.listNurseOnboarding;
  }

  async getOnboardingInfo(username: string) {
    const result = await this.client.query({
      query: GetOnboardingInfoDocument,
      variables: { 
        input: { username }
      }
    });
    return result.data.getOnboardingInfo;
  }

  async adminSetOnboardingState(input: AdminSetOnboardingStateInput) {
    const result = await this.client.query({
      query: AdminSetOnboardingStateDocument,
      variables: { input }
    });
    return result.data.adminSetOnboardingState;
  }

  async validateCode(code: string) {
    const result = await this.client.mutate({
      mutation: ValidateVerifyCodeDocument,
      variables: { code }
    })
    return result.data?.validateVerifyCode;
  }

  async onboardingPostInterview(data: OnboardingData, version = '1.0.0') {
    const result = await this.client.mutate({
      mutation: OnboardingPostInterviewDocument,
      variables: { 
        input: {
          data: JSON.stringify(data),
          version
        }
      }
    });
    return result.data?.onboardingPostInterview;
  }

  async onboardingEmployeeInfo(data: OnboardingData, version = '1.0.0') {
    const result = await this.client.mutate({
      mutation: OnboardingEmployeeInfoDocument,
      variables: { 
        input: {
          data: JSON.stringify(data),
          version
        }
      }
    });
    return result.data?.onboardingEmployeeInfo;
  }

  async admCreateEmrPatient(input: AdmCreateEmrPatientInput) {
    const result = await this.client.mutate({
      mutation: AdmCreateEmrPatientDocument,
      variables: { input }
    });
    return result.data?.admCreateEmrPatient;
  }
}
