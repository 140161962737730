// nice tool: https://regex101.com/r/cO8lqs/2

export function awsPhoneNumber(phone: string) {
  if (!phone || phone.length < 10) return phone || '';
  const cleanPhone = phone.replace(/[- )(]/g, '');
  if (!cleanPhone || cleanPhone.length < 10) return cleanPhone || '';
  return '+1' + cleanPhone.slice(-10);
}

export function validateNorthAmericaPhoneNumber(phoneStr: string) {
  const cleanPN = phoneStr.startsWith('+1')
    ? phoneStr.substring(2)
    : phoneStr.startsWith('1')
      ? phoneStr.substring(1)
      : phoneStr;
  const phoneRE = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return phoneRE.test(cleanPN);
}

export function validatePhoneV2(phone: string) {
  const northAmericaPHoneRE = /^[1]?[- ]?(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
  return northAmericaPHoneRE.test(phone);
}
