import { ReactElement, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//
import { getUser } from './reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { UserDetail } from '../../../..';

export interface UserManagementDetailProps {
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function UserManagementDetail({ onWaiting }: UserManagementDetailProps) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    if (params.uuid != null) dispatch(getUser(params.uuid));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = useAppSelector(state => state.userManagement.loading);
  const user = useAppSelector(state => state.userManagement.user);
  return <Box>
    <Box>
      <Box display='flex' justifyContent='space-between' style={{ padding: '30px 0' }}>
        <Typography variant='h4'>{t('userManagement.detail.title')}</Typography>
        <Box>
          {/* {user.username ? <Typography variant='h5'>{user.username}</Typography> : null} */}
          <IconButton onClick={() => navigate('/admin')} disabled={loading}>
            <ArrowBackIcon style={{ fontSize: 24 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
    <Box minHeight='65vh' style={{ paddingTop: 0 }}>
      {onWaiting(loading)}
      {!loading && <UserDetail isAdmin user={user} />}
    </Box>
  </Box>
}
