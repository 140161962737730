import { useState } from 'react';
import { View } from 'react-native';
import { Button, Card, Subheading } from 'react-native-paper';
import { Slider } from '@miblanchard/react-native-slider';
//
import { FFLayout } from './layout';

export interface FFSliderProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  label?: string;
  min: number;
  max: number;
  onSelect: (selected: number) => void;
  onBack?: () => void;
}

export function FFSlider(props: FFSliderProps) {
  const [value, setValue] = useState(props.min);

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<>
        <Subheading style={{ width: '100%', textAlign: 'center' }}>{`${props.label ? props.label + ': ' : ''}${value}`}</Subheading>
        <Slider
          minimumValue={props.min}
          maximumValue={props.max}
          minimumTrackTintColor='rgb(50, 50, 50)'
          maximumTrackTintColor='rgba(50, 50, 50, 0.38)'
          value={value}
          onValueChange={val => setValue(Array.isArray(val) ? val[0] : val)}
          step={1}
        />
      </>}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => props.onSelect(value)}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}

//
// ===
//

export interface FFSliderRangeProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  label?: string;
  min: number;
  max: number;
  onSelect: (range: { low: number, high: number }) => void;
  onBack?: () => void;
}

export function FFSliderRange(props: FFSliderRangeProps) {
  const [value, setValue] = useState([props.min, props.max]);

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<>
        <Subheading style={{ width: '100%', textAlign: 'center' }}>{`${props.label ? props.label + ': ' : ''}${value[0]} - ${value[1]}`}</Subheading>
        <Slider
          animateTransitions
          minimumValue={props.min}
          maximumValue={props.max}
          minimumTrackTintColor='rgb(50, 50, 50)'
          maximumTrackTintColor='rgba(50, 50, 50, 0.38)'
          value={value}
          onValueChange={val => setValue(Array.isArray(val) ? val : [val])}
          step={1}
        />
      </>}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button onPress={() => props.onSelect({ low: value[0], high: value[1] })}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
