import { Image } from 'react-native';
import { Modal, Card, IconButton, Subheading, Avatar } from 'react-native-paper';
import { useTranslation } from 'react-i18next';

export interface CheckSpecimenProps {
  show: boolean;
  onClose: () => void;
}

export function CheckSpecimen({ show, onClose }: CheckSpecimenProps) {
  const [t] = useTranslation();

  return <Modal
    visible={show} onDismiss={onClose}
    contentContainerStyle={{ backgroundColor: 'white', padding: 20 }}
  >
    <Card>
      <Card.Title
        title={t('nurse.findOnCheck')} subtitle={t('nurse.specCheck')}
        left={props => <Avatar.Icon {...props} icon='bank' />}
        right={props => <IconButton {...props} icon='close-circle-outline' onPress={onClose} />}
      />
      <Card.Content>
        <Image
          source={require('../../../assets/images/checkspecimen.png')}
          style={{ height: 200, resizeMode: 'contain' }}
        />
      </Card.Content>
      <Card.Content>
        <Subheading>{t('nurse.checkExplA')}</Subheading>
        <Subheading>{t('nurse.checkExplB')}</Subheading>
        <Subheading>{t('nurse.checkExplC')}</Subheading>
        <Subheading>{t('nurse.checkExplD')}</Subheading>
      </Card.Content>
    </Card>
  </Modal>
}
