/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import StopIcon from '@mui/icons-material/Stop';
import { rankWith, scopeEndsWith, RankedTester } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

export const butRemoteStringControlTester: RankedTester = rankWith(
  3, // increase rank as needed
  scopeEndsWith('butlisten')
);

interface ButValidControlProps {
  data: boolean;
  handleChange(path: string, value: any): void;
  path: string;
}

function ButRemoteStringControl({ data, handleChange, path }: ButValidControlProps) {
  return <Box display='flex' my={1}>
    <IconButton onClick={() => handleChange(path, !data)}>
      {data ? <StopIcon /> : <KeyboardVoiceIcon />}
    </IconButton>
    <Typography variant='caption' mt={1.5}>click to record patient</Typography>
  </Box>
}

export default withJsonFormsControlProps(ButRemoteStringControl);
