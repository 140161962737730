import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Text } from 'react-native';
import { Surface, Avatar, Checkbox, TextInput, Paragraph, Modal, Banner } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';

export default function Permits({ navigation }: RootStackScreenProps<'Permits'>) {
  const { authState, onboarding } = useContext(authStore);
  const [mounted, setMounted] = useState(false);
  const [OIIQ, setOIIQ] = useState('');
  const [noPermit, setNoPermit] = useState(false);
  const [showWarn, setShowWarn] = useState(false);
  const [shownWarn, setShownWarn] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (noPermit && !shownWarn) { setShownWarn(true); setShowWarn(true); }
  }, [noPermit, shownWarn]);

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.professionalInfo?.OIIQ != null) navigation.push('AvailabilityTimish');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  return <View style={styles.container}>
    <OnboardingHeader progress={5} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Logo style={{ marginTop: scale(12) }} width={50} height={50} />
        <Paragraph style={{ marginTop: scale(20), textAlign: 'center' }}>{t('nurse.permitsDesc')}</Paragraph>
      </View>
      <View style={{ width: '100%', marginTop: 12 }}>
        <Surface style={styles.surface}>
          <View style={{ marginRight: 6 }}>
            <Avatar.Icon size={24} icon='information-outline' color='#A5A5A5' style={{ backgroundColor: 'transparent' }} />
          </View>
          <Text style={{ color: '#A5A5A5' }}>{t('nurse.permitsNoNumPOP')}</Text>
        </Surface>
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 12 }}>
          <TextInput
            style={{ flexGrow: 1 }} mode='outlined' label={t('nurse.permitsPH')}
            value={OIIQ} onChangeText={text => { if (text.length < 8) setOIIQ(text); }}
            render={props => <MaskedTextInput
              value={props.value} mask='9999999' style={props.style}
              onChangeText={(t, r) => { if (props.onChangeText) props.onChangeText(r); }}
            />}
          />
        </View>
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 12 }}>
          <Checkbox.Item style={{ marginTop: 12 }}
            label={t('nurse.permitsNoNumCB')} position='leading' color='#323232' labelStyle={{ textAlign: 'left' }}
            status={noPermit ? 'checked' : 'unchecked'} onPress={() => setNoPermit(!noPermit)}
          />
        </View>
      </View>
    </ScrollView>
    <OnboardingFooter
      onNext={() => onboarding({ professionalInfo: { OIIQ }})}
      hideLater disabledBut={(OIIQ.length < 5 || OIIQ.length > 7) && !noPermit}
    />
    <Modal visible={showWarn} onDismiss={() => setShowWarn(false)} contentContainerStyle={{ backgroundColor: 'white', padding: 20 }}>
      <Banner
        visible={showWarn}
        actions={[{
          label: t('shorts.close'),
          onPress: () => setShowWarn(false),
        },]}
        icon={cprops => <Avatar.Icon {...cprops} color='white' icon='exclamation' />}
      >
        {t('nurse.permitsNoNumPOP')}
      </Banner>
    </Modal>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  surface: {
    elevation: 1,
    marginTop: 36,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
