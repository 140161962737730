/* eslint-disable @typescript-eslint/no-explicit-any */

import { memo } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import ValidationIcon from './validationIcon';

export interface ArrayLayoutToolbarProps {
  label: string;
  errors: string;
  path: string;
  addItem(path: string, data: any): () => void;
  createDefault(): any;
}

export const ArrayLayoutToolbar = memo(
  ({
    label,
    errors,
    addItem,
    path,
    createDefault
  }: ArrayLayoutToolbarProps) => {
    return <Toolbar disableGutters={true} style={{ width: '100%' }}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <Typography variant='h6'>{label}</Typography>
        </Grid>
        <Hidden smUp={errors.length === 0}>
          <Grid item>
            <ValidationIcon id='tooltip-validation' errorMessages={errors} />
          </Grid>
        </Hidden>
        <Grid item>
          <Grid container>
            <Grid item>
              <Tooltip
                id='tooltip-add'
                title={`Add to ${label}`}
                placement='bottom'
              >
                <IconButton
                  aria-label={`Add to ${label}`}
                  onClick={addItem(path, createDefault())}
                  size='large'>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  }
);

export const ReadonlyArrayLayoutToolbar = memo(({ label, errors }: ArrayLayoutToolbarProps) => {
  return <Toolbar disableGutters={true} style={{ width: '100%' }}>
    <Grid container alignItems='center' justifyContent='space-between'>
      <Grid item>
        <Typography variant='h6'>{label}</Typography>
      </Grid>
      <Hidden smUp={errors.length === 0}>
        <Grid item>
          <ValidationIcon id='tooltip-validation' errorMessages={errors} />
        </Grid>
      </Hidden>
    </Grid>
  </Toolbar>
});
