// const data = 10000000.12;
// console.log(
//   data.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })
// );
// // 10 000 000,12 $
// console.log(
//   data.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })
// );
// // $10,000,000.12
// console.log(data.toLocaleString('en', { style: 'currency', currency: 'CAD' }));
// CA$10,000,000.12

// console.log(data.toLocaleString('fr', { style: 'currency', currency: 'CAD' }));
// 10 000 000,12 $CA

import { LocaleClass } from "./users";

export function formatCentsAmount(cents: number, locale: string, currency = 'CAD') {
  const localeCl = LocaleClass.fromLangToLocale(locale);
  return (cents / 100).toLocaleString(localeCl.toLocaleStringDash(), { style: 'currency', currency });
}