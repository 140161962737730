import React from 'react';
import Typography from '@mui/material/Typography';
import WcIcon from '@mui/icons-material/Wc';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import { Gender } from '@eksoh/flo/model';

enum GenderUndefined { U='U' };

const icons: { [key in Gender | GenderUndefined]: React.ReactNode } = {
  'M': <MaleIcon style={{ width: 16, marginRight: 6, paddingTop: 8 }} />,
  'F': <FemaleIcon style={{ width: 16, marginRight: 6, paddingTop: 8 }} />,
  'O': <VisibilityOffIcon style={{ width: 16, marginRight: 6, paddingTop: 8 }} />,
  'U': <Typography variant='subtitle1' color='textSecondary'>n/a</Typography>,
}

export interface GenderLabelProps {
  gender?: Gender;
}

export function GenderLabel(props: GenderLabelProps) {
  return <div style={{ display: 'flex', lineHeight: '0px' }}>
    <WcIcon style={{ width: 16, marginRight: 6, paddingTop: 8 }} />
    {icons[props.gender || 'U']}
  </div>
}
