/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
// import generator from 'generate-password-ts';
//
import {
  AdminListFormsQuery,
  ListUserFormsQuery, GetUserFormQuery,
} from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!

import { BeServices /* , IRootState */ } from '../../..';

export type ListedForms = NonNullable<AdminListFormsQuery['adminListForms']>;
export type ListedForm = NonNullable<AdminListFormsQuery['adminListForms']>[number];
export type ListedUserForms = NonNullable<ListUserFormsQuery['listUserForms']>;
export type ListedUserForm = NonNullable<ListUserFormsQuery['listUserForms']>[number];
export type UserForm = NonNullable<GetUserFormQuery['getUserForm']>;

const initialState = {
  errorMessage: null as any,
  loadingForms: false,
  forms: [] as ListedForms,
  form: undefined as ListedForm | undefined,
  loadingUserForms: false,
  userForms: [] as ListedUserForms,
  userForm: undefined as UserForm | undefined,
  updating: false,
  updateSuccess: false,
  totalForms: 0,
  totalUserForms: 0,
};

// Async Actions

export const listForms = createAsyncThunk('forms/fetch_forms',
  async () => {
    const result = await BeServices.getInstance().forms.adminListForms(); // {completedOnly: false});
    return result;
  }
);

export const adminGetForm = createAsyncThunk('forms/fetch_form',
  async (name: string) => {
    const result = await BeServices.getInstance().forms.adminGetForm(name); // {completedOnly: false});
    return result;
  }
);

export interface adminCreateFormProps {
  name: string;
  category: string;
  model: unknown;
  data?: unknown;
}
export const adminCreateForm = createAsyncThunk('forms/create_form',
  async ({ name, category, model, data }: adminCreateFormProps) => {
    const result = await BeServices.getInstance().forms.adminCreateForm(name, category, model, data); // {completedOnly: false});
    return result;
  }
);

export interface adminUpdFormProps {
  name: string;
  model: unknown;
  data?: unknown;
}
export const adminUpdForm = createAsyncThunk('forms/upd_form',
  async ({ name, model, data }: adminUpdFormProps) => {
    const result = await BeServices.getInstance().forms.adminUpdForm(name, model, data); // {completedOnly: false});
    return result;
  }
);

//

export const listUserForms = createAsyncThunk('forms/fetch_user_forms',
  async (username: string) => {
    const result = await BeServices.getInstance().forms.listUserForms(username); // {completedOnly: false});
    return result;
  }
);

export const getUserForm = createAsyncThunk('forms/fetch_user_form',
  async ({ name, username }: { name: string, username?: string }) => {
    const result = await BeServices.getInstance().forms.getUserForm(name, username); // {completedOnly: false});
    return result;
  }
);

export const createUserForm = createAsyncThunk('forms/create_user_form',
  async ({ name, username }: { name: string, username: string }) => {
    const result = await BeServices.getInstance().forms.createUserForm(username, name); // {completedOnly: false});
    return result;
  }
);

// export const getDataItem = createAsyncThunk(
//   'forms/fetch_data_item',
//   async (username: string, { getState }) => {
//     // find first item with id and return it
//     const state = getState() as IRootState;
//     const result = state.userForms.items?.find(i => i.username === username);
//     // const result = await BeServices.getInstance().user.listNurseOnboarding({completedOnly: false});
//     if (result == null) throw new Error('item not found');
//     return result;
//   }
// );

export type FormsState = Readonly<typeof initialState>;

export const FormsSlice = createSlice({
  name: 'forms',
  initialState: initialState as FormsState,
  reducers: {
    reset() {
      return initialState;
    },
    resetForm(state) {
      return { ...state, form: undefined };
    },
    resetUserForm(state) {
      return { ...state, userForm: undefined };
    },
    // newForm(state, action) {
    //   console.log('>>> NEW ACTION:', action.type, action.payload)
    //   return {
    //     ...state,
    //     form: {
    //       name: action.payload.payload.name,
    //       category: action.payload.payload.category,
    //       model: '',
    //       createdBy: action.payload.payload.me,
    //     },
    //   };
    // },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(listForms), (state, action) => {
        state.loadingForms = false;
        state.forms = action.payload || [];
        // state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.totalForms = action.payload?.length || 0;
      })
      .addMatcher(isFulfilled(adminGetForm), (state, action) => {
        state.loadingForms = false;
        state.form = action.payload || undefined;
      })
      .addMatcher(isFulfilled(createUserForm, adminCreateForm, adminUpdForm), (state, action) => {
        state.updating = false;
        if (action.type === 'forms/create_form/fulfilled') state.form = action.payload as ListedForm || undefined;
        // if (action.type === 'forms/create_user_form') state.userForm = action.payload as ListedUserForm || undefined;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(getUserForm), (state, action) => {
        state.loadingUserForms = false;
        state.userForm = action.payload || undefined;
      })
      .addMatcher(isFulfilled(listUserForms), (state, action) => {
        state.loadingUserForms = false;
        state.userForms = action.payload || [];
        // state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.totalUserForms = action.payload?.length || 0;
      })
      .addMatcher(isPending(listForms), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loadingForms = true;
      })
      .addMatcher(isPending(listUserForms, createUserForm), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(listForms, listUserForms, adminGetForm, getUserForm, createUserForm), (state, action) => {
        state.loadingForms = false;
        state.loadingUserForms = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset, resetForm, resetUserForm } = FormsSlice.actions;

// Reducer
export default FormsSlice.reducer;
