/* eslint-disable @typescript-eslint/no-explicit-any */

const tryBoth = (key: string) => process.env[key] || ((process.env['APP_MANIFEST'] as any)?.extra || {})[key];

export const APP_REGION = tryBoth('NX_REGION');
export const AWS_USER_POOL_ID = tryBoth('NX_USER_POOL_ID');
export const AWS_IDENTITY_POOL_ID = tryBoth('NX_IDENTITY_POOL_ID');
export const AWS_USER_POOL_CLIENT_ID = tryBoth('NX_USER_POOL_CLIENT_ID');
export const AWS_OAUTH_DOMAIN = tryBoth('NX_AWS_OAUTH_DOMAIN');
export const AWS_GRAPHQL_ENDPOINT = tryBoth('NX_GRAPHQL_ENDPOINT');
export const NODE_ENV = tryBoth('NODE_ENV') || 'developement';
export const DEBUG = tryBoth('DEBUG');
export const AMPLIFY_LOG_LEVEL = tryBoth('AMPLIFY_LOG_LEVEL');
export const AUTH_MANDATORY_SIGN_IN = tryBoth('AUTH_MANDATORY_SIGN_IN');
export const BASE_URL = tryBoth('NX_BASE_URL');
export const SIGNIN_REDIRECT_URL = tryBoth('NX_SIGNIN_REDIRECT_URL');
export const SIGNOUT_REDIRECT_URL = tryBoth('NX_SIGNOUT_REDIRECT_URL');
export const BASE_URL_MOBILE = tryBoth('NX_BASE_URL_MOBILE');
export const BASE_URL_WEBAPP = tryBoth('NX_BASE_URL_WEBAPP');
export const STORAGE_S3_NAME = tryBoth('NX_STORAGE_S3_NAME');
// export const CALENDAR_STORAGE = tryBoth('NX_CALENDAR_STORAGE');
// export const USER_STORAGE = tryBoth('NX_USER_STORAGE');

export const APP_VERSION = tryBoth('NX_APP_VERSION');
export const APP_VERSION_GIT = tryBoth('NX_APP_VERSION_GIT');

export function getCognitoRedirectUrls() {
  // return { redirectSignIn: 'http://localhost:19006', redirectSignOut: 'http://localhost:19006' };
  return { redirectSignIn: SIGNIN_REDIRECT_URL, redirectSignOut: SIGNOUT_REDIRECT_URL };
}

export function getUserOpener() { return undefined; }
