import { useState } from 'react';
import { View } from 'react-native';
import { Button, Card, RadioButton } from 'react-native-paper';
//
import { FFLayout } from './layout';

export interface FFRadioProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  choices: string[];
  default?: string;
  onSelect: ({ selected, index }: { selected: string, index: number }) => void;
  onBack?: () => void;
}

export function FFRadio(props: FFRadioProps) {
  const [value, setValue] = useState('');

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={<RadioButton.Group onValueChange={value => setValue(value)} value={value}>
        {props.choices.map((c, i) => <RadioButton.Item key={'dtrb' + i} label={c} value={c} />)}
      </RadioButton.Group>}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button disabled={!value} onPress={() => props.onSelect({ selected: value, index: props.choices.indexOf(value) })}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
