import { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
//
import { StorageDocument, StorageDocumentType } from '@eksoh/flo/model';
import { docIcons } from './onboardingicons';

export interface EmployeeInfoProps {
  doc: StorageDocument;
}

export function DocumentInfo({ doc }: EmployeeInfoProps) {
  const audio = useRef<HTMLAudioElement>();
  const [audioUrl, setAudioUrl] = useState<string>();

  useEffect(() => {
    if (audioUrl == null && audio.current != null) {
      audio.current.pause();
      audio.current = undefined;
      return;
    }
    else if (audioUrl != null && audio.current == null) {
      try {
        audio.current = new Audio(audioUrl);
        audio.current.addEventListener('ended', () => setAudioUrl(undefined));
        audio.current.play();
      }
      catch (error) {
        console.log('>>> error playing audio:', error);
        audio.current?.removeEventListener('ended', () => setAudioUrl(undefined));
        audio.current = undefined;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, audioUrl]);

  function openDoc(doc?: StorageDocument) {
    if (doc == null) return;
    if (doc.type === StorageDocumentType.VOICE_CV) {
      if (doc.signedUrl == null) { setAudioUrl(undefined); return; }
      if (audioUrl != null) setAudioUrl(undefined); else setAudioUrl(doc.signedUrl);
    }
    else {
      window?.open(doc.signedUrl || '', '_blank')?.focus();
    }
  }

  return <IconButton
    size='large'
    color={doc?.type === StorageDocumentType.VOICE_CV && audioUrl === doc?.signedUrl ? 'info' : undefined}
    onClick={() => openDoc(doc)}
  >
    {doc.type != null ? (docIcons.get(doc.type) || <QuestionMarkIcon />) : <QuestionMarkIcon />}
  </IconButton>
}