/* eslint-disable @typescript-eslint/no-explicit-any */
import { AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
//
import {
  // Queries
  GetUserTimeOffersDocument,
  SearchTimeOffersInput, SearchTimeOffersDocument,
  // Mutations
  CreateTimeOfferInput, CreateTimeOffersDocument,
  UpdateTimeOfferInput, UpdateTimeOffersDocument,
  DeleteTimeOfferInput, DeleteTimeOffersDocument
  // Subscriptions
  // ...
} from '@eksoh/flo/model';
import { ApolloBase } from './apollobase';
import { AppsyncConfigParams } from '@eksoh/shared/common';

export class ScheduleApiClient extends ApolloBase {
  
  constructor(params: AppsyncConfigParams, jwtToken: string, tz?: string) {    
    const auth: AuthOptions = {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => jwtToken
    };
    super(params, auth, tz);
  }

  // Queries

  async getUserTimeOffers(username: string) {
    const result = await this.client.query({ query: GetUserTimeOffersDocument, variables: { username } });
    return result.data?.getUserTimeOffers;
  }

  async searchTimeOffers(input: SearchTimeOffersInput) {
    // input.tz = TZ_AMERICA_MONTREAL;
    const result = await this.client.query({ query: SearchTimeOffersDocument, variables: { input } });
    return result.data?.searchTimeOffers;
  }

  // async searchTimeOffersTod(input: SearchTimeOffersTodInput) {
  //   const result = await this.client.query({ query: SearchTimeOffersTodDocument, variables: { input } });
  //   return result.data?.searchTimeOffersTod;
  // }

  // Mutations

  async createTimeOffers(input: CreateTimeOfferInput) {
    const result = await this.client.mutate({ mutation: CreateTimeOffersDocument, variables: { input } });
    return result.data?.createTimeOffers;
  }

  async updateTimeOffers(input: UpdateTimeOfferInput) {
    const result = await this.client.mutate({ mutation: UpdateTimeOffersDocument, variables: { input } });
    return result.data?.updateTimeOffers;
  }

  async deleteTimeOffers(input: DeleteTimeOfferInput) {
    const result = await this.client.mutate({ mutation: DeleteTimeOffersDocument, variables: { input } });
    return result.data?.deleteTimeOffers;
  }

  // Subscriptions

  // public subscribeToNewMessage(radius: number, extra?: string) {
  //   return this.sub<SubscribeToNewMessageSubscriptionVariables>(SubscribeToNewNearBysDocument, { radius, extra });
  // }
}
