import React, { ReactNode } from 'react';
import { Avatar, Card, Paragraph, List } from 'react-native-paper';
//
import { Entry, HospitalEntry, OccupationalHealthcareEntry, HealthCheckEntry } from '@eksoh/shared/common';
import { assertNever } from '../utils';

function formatDate(d: unknown) {
  // TODO improve me
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return typeof d === 'string' ? d : new Date(d as any).toDateString();
  }
  catch (error) {
    console.log('>>> ERROR FORMATING DATE:', d, error);
  }
  return 'error in date';
}

interface BaseEntryDetails {
  entry: Entry;
  icon: string;
  extras?: ReactNode;
  headers?: string;
  action?: ReactNode;
}

const BaseEntryDetals: React.FC<BaseEntryDetails> = ({ entry, icon, children, extras, headers, action }) => (
  <Card mode='outlined' style={{ marginBottom: 12 }}>
    <Card.Title
      title={formatDate(entry.date)}
      subtitle={headers}
      left={(props) => <Avatar.Icon {...props} icon={icon} />}
      // right={(props) => <IconButton {...props} icon="more-vert" onPress={() => { /* */ }} />}
    />
    <Card.Content>
      <Paragraph>{entry.description}</Paragraph>
    </Card.Content>
    <Card.Content>
      <Paragraph>{children}</Paragraph>
    </Card.Content>
    <Card.Content>
      <Paragraph>{extras}</Paragraph>
    </Card.Content>
  </Card>
);

function HealthCheckDetails({ entry, children }: { entry: HealthCheckEntry, children: ReactNode }) {
  return <BaseEntryDetals entry={entry} icon={'doctor'} extras={children} action={
    <Avatar.Icon icon='heart' color={
      entry.healthCheckRating === 0 ? '#00ff00'
      : entry.healthCheckRating === 1 ? 'yellow'
        : entry.healthCheckRating === 2 ? 'orange'
          : 'red'
      }
    />
  }>
  </BaseEntryDetals>
}

function OccupationalHealthcareDetails({ entry, children }: { entry: OccupationalHealthcareEntry, children: ReactNode }) {
  return <BaseEntryDetals entry={entry} icon={'office-building'} extras={children} headers={entry.employerName}>
    {
      entry.sickLeave && <List.Item
        title='Sick leave'
        description={`from: ${formatDate(entry.sickLeave.startDate)} to ${formatDate(entry.sickLeave.endDate)}`}
        // left={props => <List.Icon {...props} icon="folder" />}
      />
    }
  </BaseEntryDetals>
}

function HospitalDetails({ entry, children }: { entry: HospitalEntry, children: ReactNode }) {
  return <BaseEntryDetals entry={entry} icon={'hospital-building'} extras={children}>
    <List.Item
      title={`Discharged on: ${formatDate(entry.discharge.date)}`}
      description={`Criteria: ${entry.discharge.criteria}`}
      // left={props => <List.Icon {...props} icon="folder" />}
    />
  </BaseEntryDetals>
}

export function EntryDetails({ entry, children }: { entry: Entry, children: ReactNode }) {
  switch (entry.type) {
    case 'HealthCheck':
      return <HealthCheckDetails entry={entry}>{children}</HealthCheckDetails>;
    case 'OccupationalHealthcare':
      return <OccupationalHealthcareDetails entry={entry}>{children}</OccupationalHealthcareDetails>;
    case 'Hospital':
      return <HospitalDetails entry={entry}>{children}</HospitalDetails>;
    default:
      return assertNever(entry);
  }
};
