import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
//
import { EntryFormValues } from '@eksoh/shared/common';
import { AddEntryForm } from './AddEntryForm';

interface Props {
  modalOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EntryFormValues) => void;
  error?: string;
}

export function AddEntryModal({ modalOpen, onClose, onSubmit, error }: Props) {
  return <Dialog open={modalOpen}>
    <DialogTitle>Add a new entry</DialogTitle>
    <DialogContent>
      {error && <Alert severity='error'>{`Error: ${error}`}</Alert>}
      <AddEntryForm onSubmit={onSubmit} onCancel={onClose} />
    </DialogContent>
  </Dialog>
}
