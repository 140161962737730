import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PersonIcon from '@mui/icons-material/Person';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BusinessIcon from '@mui/icons-material/Business';
//
import { Entry, HospitalEntry, OccupationalHealthcareEntry, HealthCheckEntry } from '@eksoh/shared/common';
import { assertNever } from '../utils';

function formatDate(d: unknown) {
  // TODO improve me
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return typeof d === 'string' ? d : new Date(d as any).toDateString();
  }
  catch (error) {
    console.log('>>> ERROR FORMATING DATE:', d, error);
  }
  return 'error in date';
}

export function NoItems() {
  return <Card sx={{ minWidth: 275 }}>
    <CardContent>
      <NotInterestedIcon fontSize='large' /> <Typography variant='subtitle1'>no entries</Typography>
    </CardContent>
  </Card>
}

interface BaseEntryDetails {
  entry: Entry;
  icon: ReactNode;
  extras?: ReactNode;
  headers?: ReactNode;
  action?: ReactNode;
}

const BaseEntryDetals: React.FC<BaseEntryDetails> = ({ entry, icon, children, extras, headers, action }) => (
  <Card elevation={3} sx={{ mb: 2 }}>
    <CardHeader
      avatar={<Icon color='inherit' fontSize='large'>{icon}</Icon>}
      title={formatDate(entry.date)}
      subheader={headers}
      action={action}
    />
    <CardContent>
      <Typography variant='subtitle1'>{entry.description}</Typography>
    </CardContent>
    <CardContent>
      {children}
    </CardContent>
    <CardContent>
      {extras}
    </CardContent>
  </Card>
);

const HealthCheckDetails: React.FC<{ entry: HealthCheckEntry }> = ({ entry, children }) => (
  <BaseEntryDetals entry={entry} icon={<PersonIcon />} extras={children} action={
    <Avatar style={{ backgroundColor: '#ddd' }} sx={{ mr: 2, border: '1px #ccc solid' }}>
      <FavoriteIcon sx={{
        color:
          entry.healthCheckRating === 0 ? '#00ff00'
            : entry.healthCheckRating === 1 ? 'yellow'
              : entry.healthCheckRating === 2 ? 'orange'
                : 'red'
      }} />
    </Avatar>
  }>
  </BaseEntryDetals>
);

const OccupationalHealthcareDetails: React.FC<{ entry: OccupationalHealthcareEntry }> = ({ entry, children }) => (
  <BaseEntryDetals entry={entry} icon={<BusinessIcon />} extras={children} headers={entry.employerName}>
    {
      entry.sickLeave && <>
        <Typography sx={{ mt: 4, mb: 2 }} variant='h6' component='div'>
          Sick leave:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary='Dates'
              secondary={`from: ${formatDate(entry.sickLeave.startDate)} to ${formatDate(entry.sickLeave.endDate)}`}
            />
          </ListItem>
        </List>
      </>
    }
  </BaseEntryDetals>
);

const HospitalDetails: React.FC<{ entry: HospitalEntry }> = ({ entry, children }) => (
  <BaseEntryDetals entry={entry} icon={<LocalHospitalIcon />} extras={children}>
    <List>
      <ListItem>
        <ListItemText
          primary='discharged on'
          secondary={formatDate(entry.discharge.date)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary='criteria'
          secondary={entry.discharge.criteria}
        />
      </ListItem>
    </List>
  </BaseEntryDetals>
);


const EntryDetails: React.FC<{ entry: Entry }> = ({ entry, children }) => {
  switch (entry.type) {
    case 'HealthCheck':
      return <HealthCheckDetails entry={entry}>{children}</HealthCheckDetails>;
    case 'OccupationalHealthcare':
      return <OccupationalHealthcareDetails entry={entry}>{children}</OccupationalHealthcareDetails>;
    case 'Hospital':
      return <HospitalDetails entry={entry}>{children}</HospitalDetails>;
    default:
      return assertNever(entry);
  }
};

export default EntryDetails;
