// import React from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { Diagnosis, Gender, Entry } from '@eksoh/shared/common';

// --- Generic ---
export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectFieldProps<T extends SelectOption> {
  name: string;
  label: string;
  options: T[];
}

export const SelectField = <T extends SelectOption,>({
  name,
  label,
  options,
}: SelectFieldProps<T>) => (
  <FormControl>
    <label>{label}</label>
    <Field as="select" name={name} className="ui dropdown"  >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label || option.value}
        </option>
      ))}
    </Field>
  </FormControl>
);

// -------------

// Gender
export interface GenderOption extends SelectOption {
  value: Gender;
}

export const GenderSelectField: React.FC<SelectFieldProps<GenderOption>> = (props) => (
  SelectField<GenderOption>(props)
);

// -------------

// Entry['type']
export interface EntryTypeOption extends SelectOption {
  value: Entry['type'];
}

export const EntryTypeSelectField: React.FC<SelectFieldProps<EntryTypeOption>> = (props) => (
  SelectField<EntryTypeOption>(props)
);

// -------------

interface TextProps extends FieldProps {
  label: string;
  placeholder: string;
}

export const TextField: React.FC<TextProps> = ({
  field,
  label,
  placeholder
}) => (
  <FormControl>
    <label>{label}</label>
    <Field placeholder={placeholder} {...field} />
    <div style={{ color: 'red' }}>
      <ErrorMessage name={field.name} />
    </div>
  </FormControl>
);

interface TextFieldManagedProps extends FieldProps {
  label: string;
  placeholder: string;
  value: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void);
}

export const TextFieldManaged: React.FC<TextFieldManagedProps> = ({
  field,
  label,
  placeholder,
  value,
  onChange
}) => (
  <FormControl>
    <label>{label}</label>
    <input type='text' placeholder={placeholder} value={value} onChange={onChange} />
    <div style={{ color: 'red' }}>
      <ErrorMessage name={field.name} />
    </div>
  </FormControl>
);

/*
  for exercises 9.24.-
*/
interface NumberProps extends FieldProps {
  label: string;
  errorMessage?: string;
  min: number;
  max: number;
}

export const NumberField: React.FC<NumberProps> = ({ field, label, min, max }) => (
  <FormControl>
    <label>{label}</label>
    <Field {...field} type='number' min={min} max={max} />

    <div style={{ color: 'red' }}>
      <ErrorMessage name={field.name} />
    </div>
  </FormControl>
);

export const DiagnosisSelection = ({
  diagnoses,
  value,
  onChange,
}: {
  diagnoses: { [key: string]: Diagnosis };
  value: Diagnosis['code'][];
  onChange: (event: SelectChangeEvent<Diagnosis['code'][]>) => void;
}) => {
  const field = 'diagnosisCodes';
  const stateOptions = Object.values(diagnoses).map(diagnosis => ({
    key: diagnosis.code,
    text: `${diagnosis.name} (${diagnosis.code})`,
    value: diagnosis.code
  }));

  return (
    <FormControl fullWidth>
      <InputLabel id='diagnoses-label'>Diagnoses</InputLabel>
      <Select
        labelId='diagnoses-label'
        label='Diagnoses'
        name={field}
        multiple
        value={value}
        onChange={onChange}
        fullWidth
        input={<OutlinedInput id='diagnoses-chip' label='diagnoses-label' />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={`${diagnoses[value].name} (${diagnoses[value].code})`} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: /* ITEM_HEIGHT */ 48 * 4.5 + /* ITEM_PADDING_TOP */ 8,
              width: 250,
            },
          },
        }}        
      >
        {stateOptions.map(({ text, value }) => (
          <MenuItem
            key={text}
            value={value}
            // style={getStyles(name, personName, theme)}
          >
            {text}
          </MenuItem>
        ))}
      </Select>
      {/* <ErrorMessage name={field} /> */}
    </FormControl>
  );
};
