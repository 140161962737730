import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';
import { timezone } from 'expo-localization';
import { ActivityIndicator, Banner, Avatar } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import Ionicons from '@expo/vector-icons/Ionicons';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
//
import { Gender, Locale, NurseTitles } from '@eksoh/flo/model';
import { authStore, BeServices } from '@eksoh/shared/ui';
import { nativeStore } from '@eksoh/shared/ui-expo';
import { awsPhoneNumber } from '@eksoh/shared/common';
import { scale, verticalScale, moderateScale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import Logo from '../cmps/Logo';

export enum Mission { TELEMED, PRESENT, WHATEVER }

export default function ValidateAccount({ navigation }: RootStackScreenProps<'ValidateAccount'>) {
  const { authState, setCurGroups, signOut } = useContext(authStore);
  const { setBottomSheetNode } = useContext(nativeStore);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<unknown>();
  const [mounted, setMounted] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    commit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!isFocused) return;
  //   if (authState.onboarding?.misType != null) navigation.push('MissionRadius');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [authState.onboarding]);
  useEffect(() => {
    if (done) setBottomSheetNode(bsNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  async function commit() {
    try {
      const result = await BeServices.getInstance().user.submitNurseRegistration({
        user: {
          givenName: authState.onboarding?.personelInfo?.givenName || 'NO_GIVEN_NAME',
          middleName: authState.onboarding?.personelInfo?.middleName,
          familyName: authState.onboarding?.personelInfo?.familyName || 'NO_FAMILY_NAME',
          phoneNumber: awsPhoneNumber(authState.user?.phoneNumber || authState.onboarding?.phone || ''),
          locale: authState.user?.locale || `${i18n.language}_CA` as Locale, // TODO: Add i18n-iso-countries one day...
          gender: authState.user?.gender || Gender.O,
          address: {
            postalCode: authState.onboarding?.missionDistance?.postal || 'NO_POSTAL_CODE',
          },
          zoneinfo: timezone,
        },
        nurseType: authState.onboarding?.personelInfo?.nurseType || NurseTitles.REG,
      });
      // await BeServices.getInstance().user.deleteOnboardingData();
      setCurGroups(result?.groups);
      setDone(true);
    }
    catch (error) {
      setError(error);
    }
  }

  const bsNode = <View style={{ flex: 1, alignItems: 'center', paddingLeft: 24, paddingRight: 24 }}>
    <View style={{ width: '100%', alignItems: 'flex-end', marginBottom: 32 }}>
      {/* <TouchableHighlight onPress={() => setBottomSheetNode(undefined)} style={{ marginTop: 6 }}>
        <Ionicons name='close' size={32} color='#323232' />
      </TouchableHighlight> */}
    </View>
    <Text style={{ textAlign: 'center', fontWeight: '800', marginTop: verticalScale(60), marginBottom: verticalScale(12), fontSize: 24 }}>
      {t('nurse.weDoneTitle')}
    </Text>
    <Text style={{ textAlign: 'center' }}>{t('nurse.weDoneDetail')}</Text>
    <TouchableHighlight style={styles.but} onPress={() => {
      setBottomSheetNode(undefined);
      navigation.push('Root');
    }}>
      <Text style={styles.butText}>{t('nurse.enterNow')}</Text>
    </TouchableHighlight>
    <TouchableHighlight onPress={() => {
      setBottomSheetNode(undefined);
      signOut();
      // navigation.push('Welcome');
    }}>
      <Text style={{ textAlign: 'center', textDecorationLine: 'underline' }}>{t('nurse.enterLater')}</Text>
    </TouchableHighlight>
  </View>

  return <View style={styles.container}>
    <Banner
      visible={error != null}
      actions={[
        {
          label: t('generic.error.tryAgain'),
          onPress: () => commit(),
        },
        {
          label: t('generic.menu.contactUs'),
          onPress: () => setError(undefined),
        },
      ]}
      icon={cprops => <Avatar.Icon {...cprops} icon='alert-circle' />}
    >
      {t('generic.error.unexpected')}
    </Banner>
    <Logo style={styles.logo} width={100} height={100} />
    <Text style={styles.tagline}>{t('shorts.please-wait')}</Text>
    <View style={styles.surface}>
      <Ionicons name='image-outline' size={32} color='#A5A5A5' style={{ marginTop: verticalScale(25), marginBottom: verticalScale(25) }} />
      <ActivityIndicator size={62} style={{ marginBottom: 16 }} />
      <Text>{t('nurse.creating')}</Text>
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#e5e5e5',
    alignItems: 'center',
    paddingTop: verticalScale(75),
  },
  logo: {
    marginTop: scale(1),
  },
  tagline: {
    marginTop: scale(20),
    marginBottom: scale(20),
    fontSize: moderateScale(25),
    textAlign: 'center',
  },
  surface: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  but: {
    marginRight: 40,
    marginLeft: 40,
    marginTop: verticalScale(60),
    marginBottom: verticalScale(60),
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#323232',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#323232',
    width: 270,
    height: 63,
  },
  butText: {
    color: 'white',
    textAlign: 'center',
  },
});
