import { useState } from 'react';
import * as DocumentPicker from 'expo-document-picker';
import { View } from 'react-native';
import { IconButton, Title } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import path from 'path';

export default function usePickDocument() {
  const [disabled, setDisabled] = useState(false);
  const [doc, setDoc] = useState<File>();
  const [docBase64, setDocBase64] = useState<string>();
  const [fullname, setFullame] = useState<string>();
  const [name, setName] = useState<string>();
  const [ext, setExt] = useState<string>();
  const [size, setSize] = useState<number>();
  const [mimeType, setMimeType] = useState<string>();
  const [t] = useTranslation();

  async function pickDocument() {
    try {
      // Disabling disabled (he he) because DocumentPicker.getDocumentAsync({}) does not
      // resolve if user cancel pick document on web thus button stays disabled. Find other
      // component one day.
      // setDisabled(true);
      const result = await DocumentPicker.getDocumentAsync({});
      // console.log(result);
      // TODO: This is lame, expo has 2 crapy types for DocumentResult that I can't resolve. Using any...
      if (result.type === 'success') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDoc((result as any).file);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDocBase64((result as any).uri);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFullame((result as any).name);
        let strExt = '';
        let strName = '';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try { strName = path.basename((result as any).name); } catch (_) { /* */ }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try { strExt = path.extname((result as any).name); } catch (_) { /* */ }
        try { setName(strName.substring(0, strName.length - strExt.length)); } catch (_) { /* */ }
        try { setExt(strExt.substring(1) || undefined); } catch (_) { /* */ }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setSize((result as any).size);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setMimeType((result as any).mimeType);
      }
    }
    catch (error) {
      // TODO: handle me!
      console.log('>>> PICK DOC ERR:', error);
    }
    finally {
      setDisabled(false);
    }
  }

  function resetDoc() {
    setDoc(undefined);
    setDocBase64(undefined);
    setFullame(undefined);
    setName(undefined);
    setExt(undefined);
    setSize(undefined);
    setMimeType(undefined);
  }

  // const _pickImage = async () => {
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     allowsEditing: true,
  //     aspect: [4, 3],
  //   });
  // };

  function renderPickDoc(text?: string, iconColor?: string) {
    return <View style={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton icon={'file-upload-outline'} size={40} color={iconColor} disabled={disabled} onPress={pickDocument} />
      {text != null && <Title style={{ marginLeft: 20, marginTop: 22 }}>{t(text)}</Title>}
    </View>
  }

  return { doc, docBase64, fullname, name, ext, size, mimeType, pickDocument, resetDoc, renderPickDoc };
}
