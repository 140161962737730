/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer, Dispatch, useEffect } from 'react';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';
//
import { AppVersion } from '@eksoh/shared/common';
import { initConfig } from '../../configs';

export const conf = initConfig();

export enum eAppGlobalsActions {
  INIT, SET_MODE, INIT_SERVICE, SET_CHAT_API,
}

interface InitAction {
  type: typeof eAppGlobalsActions.INIT;
  baseUrl?: string;
  appName: string;
  appVersion: AppVersion;
  isDebug: boolean;
  isDev: boolean;
  baseUrlMobile?: string;
  baseUrlWebapp?: string;
}

interface SetUserInfoAction {
  type: typeof eAppGlobalsActions.SET_MODE;
}

export type AppGlobalsActionTypes = InitAction | SetUserInfoAction; // | SetChatApiAction;

export interface IAppGlobalsState {
  baseUrl?: string;
  appName: string;
  appVersion: AppVersion;
  isReady: boolean;
  isDebug: boolean;
  isDev: boolean;
  baseUrlMobile?: string;
  baseUrlWebapp?: string;
}

const initialState: IAppGlobalsState = {
  appName: 'eksöh inc.',
  appVersion: { code: '#####', gitShortSha: '#####' },
  isReady: false,
  isDebug: false,
  isDev: false,
}

export interface IAppGlobalsContext {
  globalState: IAppGlobalsState;
  dispatch: Dispatch<any>;
}

export const globalStore = createContext<IAppGlobalsContext>({
  globalState: initialState,
  dispatch: () => null,
});

function reducer(state: IAppGlobalsState, action: AppGlobalsActionTypes) {
  switch(action.type) {
    case eAppGlobalsActions.INIT: {
      return {
        ...state, isReady: true, 
        appVersion: action.appVersion, baseUrl: action.baseUrl, isDebug: action.isDebug,
        isDev: action.isDev, 
        baseUrlMobile: action.baseUrlMobile,
        baseUrlWebapp: action.baseUrlWebapp
      };
    }
    default:
      return state;
  }
}

export interface AppGlobalsProps {
  appName: string;
  children: React.ReactNode | React.ReactNode[];
}

export function AppGlobals({ appName, children }: AppGlobalsProps) {
  const [globalState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    Amplify.configure(conf.aws);
    Auth.configure(conf.aws);
    Storage.configure(conf.aws);
    Amplify.Logger.LOG_LEVEL = conf.aws.amplifyLogLevel;
    
    dispatch({
      type: eAppGlobalsActions.INIT,
      appName,
      appVersion: conf.global.appVersion,
      baseUrl: conf.global.baseUrl,
      isDebug: conf.global.isDebugMode,
      isDev: conf.global.isDevMode,
      baseUrlMobile: conf.global.baseUrlMobile,
      baseUrlWebapp: conf.global.baseUrlWebapp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <globalStore.Provider value={{ globalState, dispatch }}>
    {globalState.isReady && children}
  </globalStore.Provider>
}
