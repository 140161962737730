import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { rootReducer } from './reducers';
import errorMiddleware from './error-middleware';
import notificationMiddleware from './notification-middleware';
import loggerMiddleware from './logger-middleware';
// import { loadingBarMiddleware } from 'react-redux-loading-bar';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   // Ignore these field paths in all actions
      //   ignoredActionPaths: [
      //     'payload.config', 'payload.request', 'error', 'meta.arg',
      //     'payload.beServices', 'sessionMngt.beServices', 'patientMngt.beServices',
      //   ],
      // },
    }).concat(errorMiddleware, notificationMiddleware, /* loadingBarMiddleware(), */ loggerMiddleware),
});

export const getStore = () => {
  return store;
};

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;
