/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore: Unreachable code error 

import { ReactNode, useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
//
import { useLocation, Tracker } from '../../..';

// GOOGLE MAPS API KEY: AIzaSyB8BCdD_7Mtr5XBUUrH4N5gPg2kPVvRoA4

const AnyReactComponent = ({ node }: any) => <div>{node}</div>;

export interface MapOnLoaded {
  map: any;
  maps: any;
  ref: Element | null;
}

export interface MapMarker {
  cid: string,
  latitude: number,
  longitude: number,
  node: ReactNode;
}

export interface GoogleMapProps {
  latitude: number;
  longitude: number;
  zoom: number;
  markers?: MapMarker[];
  onGoogleApiLoaded?: (maps: MapOnLoaded) => void;
  onClick?: (value: GoogleMapReact.ClickEventValue) => void;
  onChildClick?: (hoverKey: any, childProps: any) => void;
}

export function GoogleMap(props: GoogleMapProps) {
  const defPos = useRef<{ latitude: number, longitude: number, zoom: number }>();

  useEffect(() => {
    if (defPos.current == null) {
      defPos.current = { latitude: props.latitude, longitude: props.longitude, zoom: props.zoom };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.latitude, props.longitude]);

  if (defPos.current == null) return null;

  // @ts-ignore: Unreachable code error
  return <GoogleMapReact
    // bootstrapURLKeys={{ key: 'AIzaSyB8BCdD_7Mtr5XBUUrH4N5gPg2kPVvRoA4' }}
    bootstrapURLKeys={{ key: 'AIzaSyDAO3vEgUnCUFOKOA6Jhy4XZVaM2Zl4iOU', libraries: ['places'], id: '__googleMapsScriptId' }}
    defaultCenter={{
      lat: defPos.current?.latitude || 0,
      lng: defPos.current?.longitude || 0,
    }}
    defaultZoom={defPos.current?.zoom || 11}
    center={{
      lat: props.latitude,
      lng: props.longitude,
    }}
    zoom={props.zoom}
    yesIWantToUseGoogleMapApiInternals={props.onGoogleApiLoaded != null}
    onGoogleApiLoaded={props.onGoogleApiLoaded}
    onClick={props.onClick}
    onChildClick={props.onChildClick}
  >
    {props.markers?.map((m, i) => <AnyReactComponent
      key={'marker' + i}
      cid={m.cid}
      lat={m.latitude}
      lng={m.longitude}
      node={m.node}
    />)}
  </GoogleMapReact>
}

//
//
//

export interface MapContainerProps {
  markerMe?: ReactNode
  markers?: MapMarker[];
  onClick?: (value: GoogleMapReact.ClickEventValue) => void;
  onChildClick?: (hoverKey: any, childProps: any) => void;
}

export function MapContainer({ markerMe, markers, onClick, onChildClick }: MapContainerProps) {
  const { status, position } = useLocation({});

  const latitude = position?.coords.latitude;
  const longitude = position?.coords.longitude;
  const gotLoc = latitude != null && longitude != null;

  if (!gotLoc) return <div style={{ marginTop: '35%' }}>{status}</div>

  const me = gotLoc && markerMe != null ? { cid: '', latitude, longitude, node: markerMe } : undefined;
  return <GoogleMap
    latitude={latitude}
    longitude={longitude}
    zoom={11}
    markers={me != null
      ? markers != null ? [me, ...markers] : [me]
      : markers
    }
    onClick={onClick}
    onChildClick={onChildClick}
  />
}

//
//
//

export interface MapContainerTrackerProps {
  markerMe?: ReactNode
  markers?: MapMarker[];
  onClick?: (value: GoogleMapReact.ClickEventValue) => void;
  onChildClick?: (hoverKey: any, childProps: any) => void;
}

export function MapContainerTracker({ markerMe, markers, onClick, onChildClick }: MapContainerTrackerProps) {
  // const mapObj = useRef<any>(null);
  // const mapsObj = useRef<any>(null);
  // const mapRef = useRef<Element | null>(null);
  const { status, position, setDebug } = Tracker({});

  const latitude = position?.coords.latitude;
  const longitude = position?.coords.longitude;
  const gotLoc = latitude != null && longitude != null;

  if (!gotLoc) return <div style={{ marginTop: '35%' }}>{status}</div>

  const me = gotLoc && markerMe != null ? { cid: '', latitude, longitude, node: markerMe } : undefined;
  return <GoogleMap
    latitude={latitude}
    longitude={longitude}
    zoom={11}
    markers={me != null
      ? markers != null ? [me, ...markers] : [me]
      : markers
    }
    // onGoogleApiLoaded={(maps: MapOnLoaded) => {
    //   mapRef.current = maps.ref;
    //   mapObj.current = maps.map;
    //   mapsObj.current = maps.maps;
    // }}
    onClick={value => {
      if (position == null) return;
      setDebug({
        coords: { accuracy: 1, latitude: value.lat, longitude: value.lng },
        timestamp: Date.now(),
      } as GeolocationPosition);
      if (onClick) onClick(value);
    }}
    onChildClick={(key, childProps) => {
      setDebug(undefined);
      if (onChildClick) onChildClick(key, childProps);
    }}
  />
}
