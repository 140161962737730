/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, DefaultOptions } from '@apollo/client';
//
import { HttpHeaders } from '@eksoh/flo/model';
import { AppsyncConfigParams } from '@eksoh/shared/common';

export class ApolloBase {
  protected client: ApolloClient<any>;

  constructor(
    private params: AppsyncConfigParams,
    private auth: AuthOptions,
    private tz?: string
  ) {

    const httpLink = new HttpLink({ uri: this.params.graphqlUrl });
    const tzone = tz || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headerMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => ({
        headers: { ...headers, [HttpHeaders.TIMEZONE]: tzone || undefined },
      }));

      return forward(operation);
    })

    const link = ApolloLink.from([
      headerMiddleware,
      createAuthLink({ url: this.params.graphqlUrl, region: this.params.region, auth }),
      createSubscriptionHandshakeLink({ url: this.params.graphqlUrl, region: this.params.region, auth }, httpLink),
    ]);

    // TODO: [TEL-387] Enable Apollo cache eventually (deactivated for tests, maybe disable only for tests)
    const defaultOptions: DefaultOptions = {
      watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'ignore' },
      query: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
    }

    this.client = new ApolloClient({ link, cache: new InMemoryCache(), defaultOptions });
  }
}
