import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { TextInput, Paragraph } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { authStore } from '@eksoh/shared/ui';
import { nativeStore } from '@eksoh/shared/ui-expo';
import { appMaxWidth } from '@eksoh/flo/ui';
import Logo from '../cmps/Logo';
import { scale } from '../Scaling';
import { RootStackScreenProps } from '../types';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import { NurseTitles, kLocaleNurseTypes, LangLevels } from '@eksoh/flo/model';

export default function NurseType({ navigation }: RootStackScreenProps<'NurseType'>) {
  const { authState, onboarding } = useContext(authStore);
  const { setBottomSheetNode } = useContext(nativeStore);
  const [nurseType, setNurseType] = useState<NurseTitles>(NurseTitles.REG);
  const [showDropDown, setShowDropDown] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    if (authState.user?.givenName != null) {
      setGivenName(authState.user?.givenName || '');
      setMiddleName(authState.user?.middleName || '');
      setFamilyName(authState.user?.familyName || '');
    }
    setBottomSheetNode(undefined);
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.personelInfo?.nurseType != null) navigation.push('Languages');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  function validateNext() {
    return givenName == null || givenName.length < 2
      || middleName == null || familyName.length < 2;
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={3} onClose={() => navigation.push('Root')} />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Logo style={{ marginRight: scale(12) }} width={50} height={50} />
        <Paragraph style={{ marginTop: scale(6), textAlign: 'center' }}>{t('nurse.nurseInfo')}</Paragraph>
      </View>
      <View style={{ width: '100%', marginTop: 12 }}>
        <View style={{ width: '100%' }}>
          <DropDown
            label={t('nurse.title')}
            mode='outlined'
            value={`${nurseType}`}
            setValue={setNurseType}
            list={Object.keys(kLocaleNurseTypes).map((l) => ({ label: t(kLocaleNurseTypes[l as keyof typeof NurseTitles]), value: l }))}
            visible={showDropDown}
            showDropDown={() => setShowDropDown(true)}
            onDismiss={() => setShowDropDown(false)}
          />
        </View>
        <View style={{ width: '100%', marginTop: 16 }}>
          <TextInput
            mode={'outlined'} label={t('userManagement.familyName')}
            value={familyName} onChangeText={text => setFamilyName(text)}
          />
        </View>
        <View style={{ width: '100%', marginTop: 12 }}>
          <TextInput
            mode={'outlined'} label={t('userManagement.middleName')}
            value={middleName} onChangeText={text => setMiddleName(text)}
          />
        </View>
        <View style={{ width: '100%', marginTop: 12 }}>
          <TextInput
            mode={'outlined'} label={t('userManagement.givenName')}
            value={givenName} onChangeText={text => setGivenName(text)}
          />
        </View>
      </View>
    </ScrollView>
    <OnboardingFooter
      onNext={() => onboarding({
        personelInfo: {
          nurseType, givenName, middleName, familyName,
          french: LangLevels.UNSET, english: LangLevels.UNSET,
        }
      })}
      disabledBut={validateNext()} hideLater
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
});
