import { useContext, useRef, useEffect } from 'react';
//
import { geolocStore, useLocation, getDistance } from '../../..';

const DEFAULT_TOLERANCE_IN_METER = 20;

export interface TrackerProps {
  tolerance?: number;
}

export function Tracker({ tolerance }: TrackerProps) {
  const { geolocState, addNewGeoloc } = useContext(geolocStore);
  const { status, position, setDebug } = useLocation({});
  const _tolerance = useRef(tolerance || DEFAULT_TOLERANCE_IN_METER);

  // useEffect(() => { _tolerance.current === tolerance || DEFAULT_TOLERANCE_IN_METER }, [tolerance]);

  useEffect(() => {
    // The busy below is a bit risky if the gap is big betweem the first to last
    // position update. Might miss the last one but it is not likely.
    if (position == null || geolocState.busy) return;

    if (geolocState.geolocHisto.length < 1) {
      addNewGeoloc(position as GeolocationPosition);
    }
    else {
      const dist = getDistance(geolocState.geolocHisto[0], position as GeolocationPosition);
      if (dist > _tolerance.current) {
        addNewGeoloc(position as GeolocationPosition);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return { status, position, setDebug };
}
