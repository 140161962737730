/* eslint-disable @typescript-eslint/no-explicit-any */

import { CSSProperties, Fragment, useState } from 'react';
import Box from '@mui/material/Box';
//
import { PopperMenu, PopperMenuData, PopperContent, PopperContentData } from './popper';

const styles = {
  wrapper: {
    // minHeight: '82vh',
    width: '100%',
    height: '100%',
    // padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
}

export interface PopperContainerProps {
  mt?: number | string;
  level?: number;
  data: (PopperMenuData | PopperContentData)[];
  // beService: EmrClient;
  // extra?: EmrForm[];
  // onExtraUpd?: (index: number, data: any) => void;
}

export function PopperContainer({ mt, level, data /* , beService, extra, onExtraUpd */ }: PopperContainerProps) {
  const [opened1, setOpened1] = useState(-1);
  const [opened2, setOpened2] = useState(-1);

  return <Box mt={mt} style={styles.wrapper}>
    {data.map((d, i) => <Fragment key={'popper_cont_data' + i}>
      {d.type === 'menu' && <PopperMenu
        disabled={(opened1 !== -1 && opened1 !== i) || opened2 !== -1}
        level={level || 0}
        title={d.title}
        data={d.data || []}
        onOpen={() => setOpened1(i)}
        onDone={() => setOpened1(-1)}
      />}
      {d.type === 'content' && <PopperContent
        disabled={(opened2 !== -1 && opened2 !== i) || opened1 !== -1}
        level={level || 0}
        title={d.title}
        content={d.content}
        onOpen={() => setOpened2(i)}
        onDone={() => setOpened2(-1)}
      />}
    </Fragment>)}
  </Box>
}
