/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactNode, CSSProperties, useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useSpring, config, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // breaks: position: 'fixed' -> willChange: 'width, height',
  } as CSSProperties,

  level0: {
    marginBottom: 12,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    boxShadow: '0px 10px 10px -5px rgba(0, 0, 0, 0.05)',
  } as CSSProperties,
}

//
// ===
//

interface FullSpaceProps {
  title: string,
  subTitle?: string;
  onClose?: () => void;
  pt?: number | string;
  children: ReactNode;
  show: boolean;
}

function FullSpace({ title, subTitle, onClose, pt, children, show }: FullSpaceProps) {
  const [t] = useTranslation();
  const AnimatedCard = animated(Card);

  const { opacity } = useSpring({
    config: config.default, // for slow testing { mass: 1, tension: 20, friction: 120 },
    from: { opacity: 0 },
    to: {
      opacity: show ? 1 : 0.25,
    },
  });

  return <AnimatedCard
    style={{
      width: '100%', height: '100%', padding: 12, paddingTop: pt,
      textAlign: 'center', overflow: 'scroll',
      backgroundColor: 'white', opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
    }}
  >
    <CardHeader
      avatar={<Avatar><NoteAltIcon /></Avatar>}
      action={<IconButton aria-label='settings' onClick={onClose}><HighlightOffIcon /></IconButton>}
      title={t(title)}
      subheader={subTitle ? t(subTitle) : undefined}
    />
    <CardContent>{children}</CardContent>
  </AnimatedCard>
}

//
// ===
//

function AnimatedLabel({ title, show }: { title: string, show: boolean }) {
  const [t] = useTranslation();
  const AnimatedTypography = animated(Typography);

  const { opacity } = useSpring({
    config: config.default, // for slow testing { mass: 1, tension: 20, friction: 120 },
    from: { opacity: 0 },
    to: {
      opacity: show ? 1 : 0,
    },
  });

  return <AnimatedTypography
    variant='button' mt={1} style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }) }}
  >
    {t(title)}
  </AnimatedTypography>
}

//
// ===
//

export interface PopperContentData {
  type: 'content';
  title: string;
  content: () => ReactNode;
}

export interface PopperContentProps {
  disabled?: boolean;
  level: number;
  title: string;
  content?: () => ReactNode;
  // beService: EmrClient;
  // extra?: EmrForm[];
  // onExtraUpd?: (index: number, data: any) => void;
  onOpen: () => void;
  onDone: () => void;
}

export function PopperContent({ title, content, onOpen, onDone, level, disabled /* , beService, extra, onExtraUpd */ }: PopperContentProps) {
  const [preOpen, setPreOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const cmp = useRef<HTMLDivElement | null>(null);
  const [lleft, setLLeft] = useState(0);
  const [ltop, setLTop] = useState(0);
  const [lright, setLRight] = useState(0);
  const [lbottom, setLBottom] = useState(0);
  // const [lwidth, setLWidth] = useState(0);
  const [lheight, setLHeight] = useState(0);
  const AnimatedBox = animated(Box);

  const [isClosing, setIsClosing] = useState(false);

  const { left, top, right, bottom } = useSpring({
    config: config.default, // for slow testing { mass: 1, tension: 20, friction: 120 },
    from: { left: lleft, top: ltop, right: lright, bottom: lbottom },
    to: {
      left: open ? 10 : lleft,
      top: open ? 70 : ltop,
      right: open ? 10 : lright,
      bottom: open ? 0 : lbottom,
    },
    onStart: () => {
      if (!open) setIsClosing(true);
    },
    onRest: data => {
      setIsClosing(false);
    },
    // onChange: (vals, ctls) => {
    //   console.log('>>> VALS:', vals.value.height);
    //   // console.log('>>> CTLS:', ctls);
    // },
  });

  useEffect(() => {
    if (!preOpen) return;

    getButSize();

    setPreOpen(false);
    setOpen(true);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preOpen]);

  useEffect(() => {
    getButSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmp.current]);

  useEffect(() => {
    if (open) onOpen();
    else onDone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function getButSize() {
    if (cmp.current == null) return;
    const gutter = level === 0 ? 12 : 4;
    const rect = cmp.current.getBoundingClientRect();
    setLLeft(rect.left);
    setLTop(rect.top);
    setLRight(window.innerWidth - rect.left - cmp.current.offsetWidth);
    setLBottom(window.innerHeight - rect.top - cmp.current.offsetHeight - gutter);
    // setLWidth(rect.width);
    setLHeight(Math.max(rect.height, 25));
  }

  return <>
    {(open || isClosing) && <Box width='20%' height={level === 0 ? 40 : 24} />}
    <AnimatedBox
      ref={cmp}
      style={{
        position: open || isClosing ? 'absolute' : undefined,
        ...styles.container, left, top, right, bottom,
        ...(level === 0 ? styles.level0 : {}),
        zIndex: open || isClosing ? 1300 : undefined,
        cursor: disabled || open ? undefined : 'pointer',
        background: disabled ? 'grey' : 'white',
        width: open || isClosing ? undefined : level === 0 ? '20%' : '100%',
        height: open || isClosing ? undefined : level === 0 ? `${lheight}px` : '100%',
        minWidth: open || isClosing ? undefined : 'fit-content',
      }}
      onClick={disabled || open ? undefined : () => setPreOpen(open => !open)}
    >
      {!open && !isClosing && <Box minWidth={193} textAlign='center' pt={0} style={{ width: '100%' }}>
        <AnimatedLabel show={!open} title={title} />
      </Box>}
      {(open || isClosing) && <FullSpace show={open} title={title}
        onClose={() => { setIsClosing(true); setOpen(false); }}
      >
        {content != null ? content() : <Box />}
      </FullSpace>}
    </AnimatedBox>
  </>
}
