/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
// import generator from 'generate-password-ts';
// 
import {
  OnboardingRawData, ListNurseOnboardingQuery, OnboardingState as OnbState,
} from '@eksoh/flo/model';

import { BeServices, IRootState } from '../../..';


// export const defaultInputUser: UserInput = {
//   givenName: '',
//   familyName: '',
//   email: '',
//   groups: [Group.INSURER],
//   locale: Locale.FR_CA,
//   gender: Gender.O,
//   birthdate: new Date((new Date()).setFullYear(new Date().getFullYear() - 20)), // 20 years ago
//   phoneNumber: '+15141231234',
//   middleName: '',∏
//   zoneinfo: 'America/Toronto',
//   products: [Product.INSURANCE],
//   address: {
//     postalCode: ''
//   }
// }
export const defaultDataItem: OnboardingRawData = {
  username: '',
  data: '',
  version: '1.0.0',
  state: OnbState.REG_STARTED
};

// export const defaulCreatetUser: CreateUserInput = {
//   user: defaultInputUser,
//   pwdOptions: {
//     action: PwdAction.SET,
//     password: '',
//   },
//   userType: UserType.BROKER,
//   userBroker: {
//     licenseNo: 'idLicense',
//   },
// }

const initialState = {
  loading: false,
  errorMessage: null as any,
  items: [] as ListNurseOnboardingQuery['listNurseOnboarding'],
  dataItem: { ...defaultDataItem } as any,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

// Async Actions

// export const getUsers = createAsyncThunk('userManagement/fetch_users',
//   async ({ page, size, sort }: IQueryParams) => {
//     // const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
//     const result = await BeServices.getInstance().user.listUsers();
//     return result;
//   }
// );

export const listData = createAsyncThunk('onboarding/fetch_data_list',
  async () => {
    const result = await BeServices.getInstance().user.listNurseOnboarding({completedOnly: false});
    return result;
  }
);

export const getDataItem = createAsyncThunk(
  'onboarding/fetch_data_item',
  async (username: string, { getState }) => {
    // find first item with id and return it
    const state = getState() as IRootState;
    const result = state.onboarding.items?.find(i => i.username === username);
    // const result = await BeServices.getInstance().user.listNurseOnboarding({completedOnly: false});
    if (result == null) throw new Error('item not found');
    return result;
  }
);

// export const createUser = createAsyncThunk(
//   'userManagement/create_user',
//   async (input: CreateUserInput, { dispatch }) => {
//     // TODO @sb - move this into createUserOrPatient ??
//     //          - +1 (514) 290-4800 not accepted by AWS but +15142904800 is
//     if (input.user.phoneNumber) input.user.phoneNumber = input.user.phoneNumber.replace(/[- )(]/g, '');
//     const result = await BeServices.getInstance().user.createUser(input);
//     return result;
//   },
//   { serializeError: serializeAxiosError } // TODO @fp - FE WORK - handler les errors appsync eventuellement
// );

// export const updateUser = createAsyncThunk(
//   'userManagement/update_user',
//   async (user: User, { dispatch }) => {
//     // TODO @sb - move this into createUserOrPatient ??
//     //          - +1 (514) 290-4800 not accepted by AWS but +15142904800 is
//     // if (input.user.phoneNumber) input.user.phoneNumber = input.user.phoneNumber.replace(/[- )(]/g, '');
//     // TODO @sb: const cleanUser = sanitizeObjTmp(user, 'UpdateUserInput');
//     const result = await Promise.resolve(defaultInputUser); // TODO @sb: BeServices.getInstance().user.updateUser(cleanUser);
//     dispatch(getUsersAsAdmin({}));
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

// export const deleteUser = createAsyncThunk(
//   'userManagement/delete_user',
//   async (id: string, { dispatch }) => {
//     const result = await BeServices.getInstance().user.deleteUser(id);
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

export type OnboardingState = Readonly<typeof initialState>;

export const OnboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialState as OnboardingState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(deleteUser.fulfilled, state => {
      //   state.updating = false;
      //   state.updateSuccess = true;
      //   state.user = { ...defaultInputUser } as User;
      // })
      .addMatcher(isFulfilled(getDataItem), (state, action) => {
        state.loading = false;
        state.dataItem = action.payload;
      })
      .addMatcher(isFulfilled(listData, ), (state, action) => {
        state.loading = false;
        state.items = action.payload;
        // state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.totalItems = action.payload?.length || 0;
      })
      .addMatcher(isPending(listData, getDataItem), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(listData, getDataItem), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = OnboardingSlice.actions;

// Reducer
export default OnboardingSlice.reducer;
