import { useState } from 'react';
import { StyleSheet } from 'react-native';
//
import { User /* // INTEG , Conversation */ } from '@eksoh/flo/model';
import { Text, View } from '../cmps/Themed';
import { Users } from '../cmps/Users';
import { Conversations } from '../cmps/Conversations';
import { Chat } from '../cmps/Chat';
import IconButton from '../cmps/IconButton';

export default function TabChat() {
  const [chatUser, setChatUser] = useState<User>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [conv, setConv] = useState<any>(); // INTEG useState<Conversation>();

  return <View style={styles.container}>
    <View style={{ flexDirection: 'row', alignContent: 'space-between', marginTop: 6, marginBottom: 6, width: '100%' }}>
      <View style={{ minWidth: 50, alignItems: 'center' }}>
        {chatUser != null && <IconButton name='arrow-back-circle' size={32} onPress={() => setChatUser(undefined)} />}
      </View>
      <View style={{ flexGrow: 1 }}>
        <Text style={styles.title}>{chatUser == null ? 'chat' : `chatting with ${chatUser.givenName[0]}. ${chatUser.familyName}`}</Text>
      </View>
      <View style={{ minWidth: 50 }} />
    </View>
    <View style={{ flex: 1, minWidth: 350 }}>
      <Users onUserSelected={cu => setChatUser(cu)} />
      {chatUser != null && <Conversations selected={conv} onChatSelected={setConv} />}
      {chatUser != null && conv != null && <Chat />}
    </View>
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 12,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
