// import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale /* , { LocaleState } */ from './locale';
import authentication /* , { AuthenticationState } */ from './authentication';
import applicationProfile /* , { ApplicationProfileState } */ from './application-profile';

import administration /* , { AdministrationState } */ from '../../modules/admin/admin.reducer';
import userManagement /* , { UserManagementState } */ from '../../modules/admin/user-mgnt/reducer';
import stripePayment /* , { UserManagementState } */ from '../../modules/payment/stripe/reducer';
import onboarding /* , { UserManagementState } */ from '../../modules/onboarding/reducer';
import appointments /* , { UserManagementState } */ from '../../modules/appointments/reducer';
import forms /* , { UserManagementState } */ from '../../modules/forms/reducer';
// import register, { RegisterState } from '@src/app/modules/account/register/register.reducer';
// import activate, { ActivateState } from '@src/app/modules/account/activate/activate.reducer';
// import password, { PasswordState } from '@src/app/modules/account/password/password.reducer';
// import settings, { SettingsState } from '@src/app/modules/account/settings/settings.reducer';
// import passwordReset, { PasswordResetState } from '@src/app/modules/account/password-reset/password-reset.reducer';
// import patientMngt from '@src/app/modules/clinician/patient-mngt/reducer';
// import sessionMngt from '@src/app/modules/clinician/session-mngt/reducer';
// import cliGrpMngt from '@src/app/modules/clinicgroup/cligrp-management/reducer';
// import clinicMngt from '@src/app/modules/clinic/clinic-management/reducer';
// import apptmntMngt from '@src/app/modules/clinician/appmnt-mngt/reducer';
// import reportMngt from '@src/app/modules/refcm/report-mngt/reducer';
// // // prettier-ignore
// // import bankAccount from '@src/app/entities/bank-account/bank-account.reducer';
// // // prettier-ignore
// // import label from '@src/app/entities/label/label.reducer';
// // // prettier-ignore
// // import operation from '@src/app/entities/operation/operation.reducer';
// // /* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export const rootReducer = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  stripePayment,
  onboarding,
  appointments,
  forms,
  // register,
  // activate,
  // passwordReset,
  // password,
  // settings,
  // // bankAccount,
  // // label,
  // // operation,
  // /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  // loadingBar,
  // patientMngt,
  // sessionMngt,
  // cliGrpMngt,
  // clinicMngt,
  // apptmntMngt,
  // reportMngt,
};
