import {
  AppSyncIdentity,
  AppSyncIdentityCognito
} from 'aws-lambda';
//

export function retrieveIp(identity: AppSyncIdentity | undefined | null) {
  if(identity && (identity as AppSyncIdentityCognito).sourceIp) {
    const ips = (identity as AppSyncIdentityCognito).sourceIp;
    return Array.isArray(ips) ? ips : [ips];        // fix appsync local bug
  } else {
    return undefined;
  }
}

export interface TzAware {
  input: {
    zoneinfo?: string
  }
}
// function resolTZ(parent: UserTypes) {
//   return parent.zoneinfo || TZ_AMERICA_MONTREAL;
// }