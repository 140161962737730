import { Ref, forwardRef, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Alert from '@mui/material/Alert';

// import { StylesManager, SvgRegistry } from 'survey-core';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';

import 'survey-core/survey.i18n.js';
import 'survey-creator-core/survey-creator-core.i18n.js';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import './index.css';

import { miniForm } from '../cmps/forms';
import { AreYouSureModal, eYesNo } from '../../../..';

// StylesManager.applyTheme('darkblue');
// SvgRegistry.registerIconFromSvg('settings', '<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M841.638649 555.271526c1.775436-14.201443 3.106758-28.625968 3.106758-43.271526 0-14.645558-1.331321-29.069059-3.106758-43.271526l93.865831-73.449849c8.432043-6.656607 10.873652-18.639522 5.325285-28.40391l-88.76158-153.779386c-5.547343-9.54233-17.087167-13.536294-27.072589-9.54233l-110.508883 44.602847c-22.856567-17.530258-47.931662-32.397874-75.003228-43.715641L622.841457 86.830601c-1.997494-10.429537-11.095709-18.639522-22.190395-18.639522l-177.523159 0c-11.095709 0-20.192901 8.209986-21.968337 18.639522l-16.643052 117.609605c-27.072589 11.316743-52.147684 25.962302-75.003228 43.715641l-110.50786-44.602847c-9.985422-3.771907-21.524223 0-27.072589 9.54233l-88.76158 153.779386c-5.547343 9.54233-3.106758 21.524223 5.325285 28.40391l93.643774 73.449849c-1.775436 14.201443-3.106758 28.625968-3.106758 43.271526 0 14.645558 1.331321 29.069059 3.106758 43.271526l-93.643774 73.449849c-8.432043 6.656607-10.873652 18.639522-5.325285 28.40391l88.76158 153.779386c5.547343 9.54233 17.086144 13.536294 27.072589 9.54233l110.508883-44.602847c22.856567 17.530258 47.931662 32.397874 75.003228 43.715641l16.643052 117.609605c1.775436 10.429537 10.873652 18.639522 21.968337 18.639522l177.523159 0c11.095709 0 20.192901-8.209986 21.968337-18.639522l16.643052-117.609605c27.072589-11.316743 52.147684-25.962302 75.003228-43.715641l110.50786 44.602847c9.985422 3.771907 21.525246 0 27.072589-9.54233l88.76158-153.779386c5.547343-9.54233 3.106758-21.524223-5.325285-28.40391L841.638649 555.271526zM511.88846 667.332764c-85.876879 0-155.332764-69.455885-155.332764-155.332764s69.455885-155.332764 155.332764-155.332764c85.876879 0 155.332764 69.455885 155.332764 155.332764S597.765339 667.332764 511.88846 667.332764z" /></svg>');

const drawerWidth = '75vw';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export interface FormEditProps {
  open: boolean;
  onClose: () => void;
  onSave: (model: unknown) => void;
  name: string;
  model: unknown;
  data?: unknown;
}

export default function FormEdit({ open, onClose, onSave, name, model, data }: FormEditProps) {
  const [objModel, setObjModel] = useState<unknown>();
  const [creator, setCreator] = useState<SurveyCreator | null>(null);
  const [showARS, setShowARS] = useState(false);
  const [error, setError] = useState('');
  const [t] = useTranslation();

  useEffect(() => {
    try {
      const m = typeof model === 'string' ? model ? JSON.parse(model) : miniForm : model;
      setObjModel(m);
      const options = { showLogicTab: true, showJSONEditorTab: true, isAutoSave: true };
      const c = new SurveyCreator(options);
      c.haveCommercialLicense = true;
      c.JSON = m;
      setCreator(c);
    }
    catch (error) {
      setObjModel(null);
      setError(`parsing model error: ${JSON.stringify(error)}`);
    }
  }, [model]);

  function handleClose(save?: boolean) {
    if (save) onSave(objModel);
    onClose();
  };

  return <Dialog
    fullScreen
    open={open}
    onClose={() => handleClose()}
    TransitionComponent={Transition}
  >
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => setShowARS(true)}
          aria-label='close'
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
          {name}
        </Typography>
        <Button autoFocus color='inherit' onClick={() => handleClose(true)}>
          save
        </Button>
      </Toolbar>
    </AppBar>
    <Main open={open}>
      {error && <Alert severity='error'>{JSON.stringify(error)}</Alert>}
      {creator == null && <Typography variant='h5' sx={{ width: '100%', textAlign: 'center' }}>
        no forms loaded...
      </Typography>}
      {creator != null && <SurveyCreatorComponent creator={creator} />}
    </Main>
    <AreYouSureModal
      show={showARS}
      onClose={() => setShowARS(false)}
      title={t('shorts.warning')}
      // warning='Warning: This can not be undone.'
      msg={t('userManagement.cancel.question')}
      onChanged={(answer: eYesNo) => {
        if (answer === eYesNo.YES) handleClose(false);
        setShowARS(false);
      }}
      yesText={t('shorts.yes')}
      noText={t('shorts.cancel')}
    />
  </Dialog>
}
