import { useContext, useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs, { Dayjs } from 'dayjs';
//
import { clientEmrStore } from '../../../..';

export default function SmallCalendar() {
  const { clientEmrState, setSmallCalendarMonth, setDaySelected, setMonthIndex } = useContext(clientEmrStore);
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const currentMonthIdx = useRef(dayjs().month());

  useEffect(() => {
    if (value != null && currentMonthIdx.current !== clientEmrState.monthIndex) {
      const diff = clientEmrState.monthIndex - currentMonthIdx.current;
      setValue(value.month(value.month() + diff).date(1).hour(0).minute(0).second(0));
    }
    currentMonthIdx.current = clientEmrState.monthIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmrState.monthIndex]);

  useEffect(() => {
    if (clientEmrState.daySelected != null) {
      setValue(clientEmrState.daySelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmrState.daySelected]);

  return <StaticDatePicker
    displayStaticWrapperAs='desktop'
    openTo='day'
    value={value}
    onChange={(newValue) => {
      setValue(dayjs(newValue));

      if (newValue != null) {
console.log('>>> SM CAL NEW MONTH INDEX:',clientEmrState.monthIndex)
        setSmallCalendarMonth(dayjs(newValue).month());
        setMonthIndex(dayjs(newValue).month());
        setDaySelected(dayjs(newValue));
      }

    }}
    renderInput={(params) => <TextField {...params} />}
  />
}
