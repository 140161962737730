import { Image, StyleSheet, ImageStyle } from 'react-native';

export interface LogoProps {
  style?: ImageStyle;
  width?: number;
  height?: number;
}

export default function Logo({ style, width, height }: LogoProps) {
  return <Image
    // source={require('/assets/flowrence_logo.png')}
    source={require('../../../assets/flowrence_logo.png')}
    style={[style || styles.image, { resizeMode: 'contain', width: width || 110, height: height || 110 }]}
  />
}

const styles = StyleSheet.create({
  image: {
    marginBottom: 8,
  },
})
