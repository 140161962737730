/* eslint-disable @typescript-eslint/no-explicit-any */

// refs:
// https://json-schema.org/understanding-json-schema/
// https://json-schema.org/draft/2019-09/json-schema-validation.html#rfc.section.6.3.2

// import styles from './FormEngine.module.css';
import { CSSProperties, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createAjv } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
//
import { /* authStore, */ globalStore } from '../../..';
import GridLayout, { gridLayoutTester } from '../form-mui/gridlayout';
import TabLayout, { tabLayoutTester } from '../form-mui/tablayout';
import RatingControl, { ratingControlTester } from '../form-mui/ratingcontrol';
import ParagraphControl, { paragraphControlTester } from '../form-mui/paragraphcontrol';
import SpeechToTextControl, { speechToTextControlTester } from '../form-mui/sttcontrol';
import ButRemoteStringControl, { butRemoteStringControlTester } from '../form-mui/remotesttcontrol';
import RangeSliderControl, { rangeSliderControlTester } from '../form-mui/rangeslidercontrol';
import ListAccordionRenderer, { listAccordionRendererTester } from '../form-mui/accordioncontrol';
import RespListWithDetailRenderer, { respListWithDetailRendererTester } from '../form-mui/responsive-list-detail';
import ButValidControl, { butvalidControlTester } from '../form-mui/butvalidcontrol';
import AutocompleteControl, { autocompleteControlTester } from '../form-mui/autocomplete';
import ReadonlyArray, { readonlyArrayRendererTester } from '../form-mui/array-readonly';

const renderers = [
  ...materialRenderers,
  // register custom renderers
  { tester: gridLayoutTester, renderer: GridLayout },
  { tester: tabLayoutTester, renderer: TabLayout },
  { tester: ratingControlTester, renderer: RatingControl },
  { tester: paragraphControlTester, renderer: ParagraphControl },
  { tester: speechToTextControlTester, renderer: SpeechToTextControl },
  { tester: butRemoteStringControlTester, renderer: ButRemoteStringControl },
  { tester: rangeSliderControlTester, renderer: RangeSliderControl },
  { tester: listAccordionRendererTester, renderer: ListAccordionRenderer },
  { tester: respListWithDetailRendererTester, renderer: RespListWithDetailRenderer },
  { tester: butvalidControlTester, renderer: ButValidControl },
  { tester: autocompleteControlTester, renderer: AutocompleteControl },
  { tester: readonlyArrayRendererTester, renderer: ReadonlyArray },
];

const handleDefaultsAjv = createAjv({ useDefaults: true });

export interface FormWithDebugProps {
  schema: any;
  uiSchema: any;
  data: any;
  setData: (data: any) => void;
}

export function FormWithDebug({ schema, uiSchema, data, setData }: FormWithDebugProps) {
  const { globalState } = useContext(globalStore);
  const stringifiedData = useMemo(() => JSON.stringify(data, null, 2), [data]);
  const [t] = useTranslation();

  const clearData = () => {
    setData({});
  };

  return <Box style={{ width: '100%' }}>
    <JsonForms
      schema={schema}
      uischema={uiSchema}
      data={data}
      renderers={renderers}
      cells={materialCells}
      onChange={({ errors, data }) => setData(data)}
      i18n={{ locale: i18next.language, translate: (key, defaultMessage) => t(key) }}
      ajv={handleDefaultsAjv}
    />
    {globalState.isDev && <Accordion sx={{ mt: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#eee' }}>
        <Typography>bound data</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={styles.dataContent}>
          <pre id='boundData' style={{ maxWidth: '-webkit-fill-available' }}>{stringifiedData}</pre>
        </div>
        <Button
          style={styles.resetButton}
          onClick={clearData}
          color='primary'
          variant='contained'
        >
          clear data
        </Button>
      </AccordionDetails>
    </Accordion>}
  </Box>
}

//
// ===
///

export interface FormEngineProps {
  form: { schema: any, ui: any, data: any };
  onChange: (data: any) => void;
}

export function FormEngine({ form, onChange }: FormEngineProps) {
  return <Box width='100%'>
    <FormWithDebug
      schema={form.schema} uiSchema={form.ui} data={form.data}
      setData={(d: any) => onChange(d)}
    />
  </Box>
}

const styles = {
  container: {
    padding: '1em',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    padding: '0.25em',
  } as CSSProperties,
  dataContent: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25em',
    backgroundColor: '#cecece',
    marginBottom: '1rem',
  },
  resetButton: {
    margin: 'auto !important',
    display: 'block !important',
    width: 250,
    alignSelf: 'center',
  },
};
