import { Fragment, ReactElement, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
//
import { OnboardingInfo, OnboardingData, OnboardingState } from '@eksoh/flo/model';
import { BeServices } from '../../../..';
import { nextStepIcons } from './onboardingicons';
import { Registration } from './registration';
import { Interview } from './interview';
import { History } from './history';
import { EmployeeInfo } from './employeeinfo';
import { OnboardingStateIcon } from './onboardingicons';
import { DocumentInfo } from './documentinfo';

const stepsMapping = {
  [OnboardingState.ATCD]: 2,
  [OnboardingState.ATCD_FAILED]: 2,
  [OnboardingState.ATCD_WAITING]: 2,
  [OnboardingState.COMPLETED]: 3,
  [OnboardingState.EMPLOYEE_INFO]: 3,
  [OnboardingState.EMPLOYEE_INFO_COMPLETED]: 3,
  [OnboardingState.INTERVIEW]: 1,
  [OnboardingState.INTERVIEW_FAILED]: 1,
  [OnboardingState.POST_INTERVIEW]: 1,
  [OnboardingState.REG_COMPLETED]: 0,
  [OnboardingState.REG_REJECTED]: 0,
  [OnboardingState.REG_STARTED]: 0,
}

export interface ShowOnboardingStepProps {
  show: boolean;
  onAction: (username: string, notes: string, nextStep: OnboardingState) => void;
  onDismiss: () => void;
  data?: OnboardingInfo;
  state: OnboardingState;
  onNoteUpd: (username: string, notes: string) => void;
  onWaiting: (waiting: boolean) => ReactElement | null;
}

export function ShowOnboardingStep({ show, onAction, onDismiss, data, state, onNoteUpd, onWaiting }: ShowOnboardingStepProps) {
  const [freshData, setFreshData] = useState<OnboardingData>({} as OnboardingData);
  const [onbInfo, setOnbInfo] = useState<OnboardingInfo | undefined>({} as OnboardingInfo);
  const safeCurState = data?.state || OnboardingState.REG_STARTED;

  const [loading, setLoading] = useState(true);
  const {
    getValues, setValue, control, formState: { isDirty, isValid }, reset,
  } = useForm({ defaultValues: { notes: '' } });

  useEffect(() => {
    async function getFreshData(username: string) {
      let rawData: unknown;
      try {
        const newInfo = await BeServices.getInstance().user.getOnboardingInfo(username);
        setOnbInfo(newInfo || data);
        rawData = newInfo?.rawData || data?.rawData || freshData;
      }
      catch (error) {
        console.log('>>> error getting be fresh onboarding data:', error);
      }
      finally {
        let newData: OnboardingData;
        try {
          newData = JSON.parse(rawData || data?.rawData);
        }
        catch (error) {
          console.log('>>> error parsing raw data from boarding info:', error);
          newData = freshData;
        }
        setFreshData(newData);
        setLoading(false);
      }
    }
    if (data?.username != null) getFreshData(data.username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data == null) return;
    if (show) {
      setValue('notes', data.notes || '');
    }
  }, [data, setValue, show]);

  function renderContent() {
    if (data == null) return null;
    if (state === OnboardingState.REG_STARTED) return <Registration data={data} onbData={freshData} />
    if (state === OnboardingState.INTERVIEW) return <Interview data={data} onbData={freshData} />
    if (state === OnboardingState.ATCD) return <History data={data} onbData={freshData} />
    if (state === OnboardingState.EMPLOYEE_INFO) return <EmployeeInfo data={data} onbData={freshData} />
    return null;
  }

  const noActions = stepsMapping[state] < stepsMapping[safeCurState] || data?.state === OnboardingState.COMPLETED;
  const waitingForUser = stepsMapping[state] === stepsMapping[safeCurState] && (onbInfo?.nextStates == null || onbInfo.nextStates.length === 0);
  const noteOnly = state === OnboardingState.ATCD || state === OnboardingState.EMPLOYEE_INFO || state === OnboardingState.COMPLETED;
  return <Dialog open={show} maxWidth='lg' fullWidth={true}>
    <DialogContent style={{ backgroundColor: 'transparent', padding: 0 }}>
      <Card>
        <CardHeader
          avatar={<OnboardingStateIcon state={state} againts={data?.state || OnboardingState.REG_STARTED} onClick={() => {/* */}} />}
          action={<IconButton onClick={onDismiss}><CloseIcon /></IconButton>}
          title={`${data?.user?.givenName} ${data?.user?.familyName}`}
          subheader={`${onbInfo?.user?.email}, ${onbInfo?.user?.phoneNumber}`}
        />
        <CardContent>
          {onWaiting(loading)}
          {!loading && <>
            {renderContent()}
            <Box display='flex' justifyContent='center' mt={2} p={0.4} style={{ border: '1px solid #ccc', borderRadius: 5 }}>
              {data?.documents?.map((doc, i) => <Fragment key={`docCmp_${i}`}>
                <DocumentInfo doc={doc} />
              </Fragment>)}
            </Box>
            <Box display='flex' alignItems='flex-end' mt={2}>
              <Controller
                name='notes'
                control={control} rules={{ required: false }}
                render={({ field: { onChange, onBlur, value } }) => <TextField
                  label='notes' multiline fullWidth
                  onChange={onChange} onBlur={onBlur} value={value}
                  rows={noteOnly ? 15 : 5}
                />}
              />
              <IconButton
                style={{ padding: 0 }} disabled={!isDirty || !isValid}
                onClick={() => { if (data != null) { onNoteUpd(data.username, getValues().notes); reset(getValues()); } }}
              >
                <SaveIcon />
              </IconButton>
            </Box>
          </>}
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'center', margin: 12 }}> {/* disableSpacing> */}
          {!noActions && waitingForUser && <Typography variant='subtitle2'>
            waiting for action from the user...
          </Typography>}
          {!noActions && onbInfo?.nextStates?.map((nextState, i) => (
            <IconButton
              key={'actionBut' + i} style={{ padding: 0 }}
              onClick={() => onAction(onbInfo.username, getValues().notes, nextState)}
            >
              {nextStepIcons.get(nextState)?.node}
            </IconButton>
          ))}
        </CardActions>
      </Card>
    </DialogContent>
  </Dialog>
}
