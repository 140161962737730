// import styles from './waiting-web.module.css';
import React, { CSSProperties } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  container: {
    position: 'absolute', top: '50%', left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
}

export interface WaitingProps {
  colorSpinner?: string;
  colorText?: string;
  text?: string;
  height?: string | number;
  width?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

export function Waiting(props: WaitingProps) {
  const { height, width, colorSpinner, colorText } = props;
  return <div style={{ width: width || '50vw', height: height || '10rem', maxWidth: 350 }} role='main'>
    <div style={styles.container}>
      {props.text && <h3 style={{ marginBottom: 24, color: colorText || 'grey' }}>{props.text}</h3>}
      <CircularProgress style={{ color: colorSpinner || 'grey' }} />
      {props.children}
    </div>
  </div>
}

export interface WaitingModalProps extends WaitingProps {
  open: boolean;
}

export function WaitingModal(props: WaitingModalProps) {
  return <Dialog open={props.open} onClose={() => { /* nothing */ }}>
    <DialogContent>
      <Waiting {...props} />
    </DialogContent>
  </Dialog>
}
