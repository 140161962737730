import {
  AppSyncResolverEventHeaders,
  AppSyncResolverEvent,
  AppSyncIdentity,
  AppSyncIdentityCognito,
  AppSyncIdentityIAM
} from 'aws-lambda';
//
import { TzAware, TZ_AMERICA_MONTREAL } from '@eksoh/shared/common';
import { HttpHeaders } from './HttpHeaders';
// import {
//   AuthUser
// } from '@eksoh/flo/model';

export enum ReminderType {
  TWO_DAYS = 2880,  // 24 * 60 * 2
  FOR_TEST = 100
}

export class GraphqlFloUtils {
  private static retrieveTimezone(headers: AppSyncResolverEventHeaders) {
    return headers[HttpHeaders.TIMEZONE];
  };

  // resolve timezone in this order
  // input -> browser (request header) -> jwt token (cognito zoneinfo attribute) -> default TZ_AMERICA_MONTREAL
  static resolveTimezone(event: AppSyncResolverEvent<TzAware>) {
    console.debug(`TZ: param: ${event.arguments.input?.zoneinfo}, header: ${GraphqlFloUtils.retrieveTimezone(event.request.headers)}, token: ${GraphqlFloUtils.getTokenZoneinfo(event.identity)}`);    
    return event.arguments.input?.zoneinfo
        || GraphqlFloUtils.retrieveTimezone(event.request.headers) 
        || GraphqlFloUtils.getTokenZoneinfo(event.identity)
        || TZ_AMERICA_MONTREAL;
  };

  private static getTokenZoneinfo(identity?: AppSyncIdentity) {
      if(identity && (identity as AppSyncIdentityCognito).sub ) {
      const ident = (identity as AppSyncIdentityCognito);
      return ident.claims.zoneinfo as string;
    }
    return undefined;
  }

  static retrieveUsername(identity: AppSyncIdentity | undefined | null) {
    if(identity && (identity as AppSyncIdentityCognito).sub ) {
      return (identity as AppSyncIdentityCognito).sub;
    } else if(identity && (identity as AppSyncIdentityIAM).username) {
      return (identity as AppSyncIdentityIAM).username
    } else {
      return undefined;
    }
  }
  
  // retrieveAuthUser(identity: AppSyncIdentity | undefined | null) {
  //   if(identity && (identity as AppSyncIdentityCognito).sub ) {
  //     const ident = (identity as AppSyncIdentityCognito);
  //     return new AuthUser()
  //   }
  //   return undefined;
  // }
}
