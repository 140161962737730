// import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
// import { Avatar, Button, CardActions, CardContent, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';

// project imports
import { BajajAreaChartCard } from './bajaj-area-chart-card';
import MainCard from './main-card';
import SkeletonPopularCard from './skeleton/earning-card3';
// import { gridSpacing } from '../store/constant';

// assets
// import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
// import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
// import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    // background: theme.palette.primary.main, // [800],
    borderRadius: '50%',
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 210,
    height: 210,
    // background: theme.palette.primary.main, // [800],
    borderRadius: '50%',
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: -155,
      right: -70
    }
  }
}));

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

export function EarningCard3({ isLoading }: { isLoading: boolean }) {
  const theme = useTheme();

  // const [anchorEl, setAnchorEl] = useState(null);

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // only showing graph for now...

  return isLoading
    ? <SkeletonPopularCard />
    : <CardWrapper border={false} content={false}>
      <Grid container direction='column'>
        <Grid item xs={12} sx={{ color: theme.palette.primary.light }}>
          <BajajAreaChartCard />
        </Grid>
      </Grid>
    </CardWrapper>


  // return isLoading
  //   ? <SkeletonPopularCard />
  //   : <MainCard content={false}>
  //     <CardContent>
  //       <Grid container spacing={gridSpacing}>
  //         <Grid item xs={12}>
  //           <Grid container alignContent='center' justifyContent='space-between'>
  //             <Grid item>
  //               <Typography variant='h4'>Heart Beat</Typography>
  //             </Grid>
  //             <Grid item>
  //               <MoreHorizOutlinedIcon
  //                 fontSize='small'
  //                 sx={{
  //                   color: theme.palette.primary.main, // [200],
  //                   cursor: 'pointer'
  //                 }}
  //                 aria-controls='menu-popular-card'
  //                 aria-haspopup='true'
  //                 onClick={handleClick}
  //               />
  //               <Menu
  //                 id='menu-popular-card'
  //                 anchorEl={anchorEl}
  //                 keepMounted
  //                 open={Boolean(anchorEl)}
  //                 onClose={handleClose}
  //                 variant='selectedMenu'
  //                 anchorOrigin={{
  //                   vertical: 'bottom',
  //                   horizontal: 'right'
  //                 }}
  //                 transformOrigin={{
  //                   vertical: 'top',
  //                   horizontal: 'right'
  //                 }}
  //               >
  //                 <MenuItem onClick={handleClose}> Today</MenuItem>
  //                 <MenuItem onClick={handleClose}> This Month</MenuItem>
  //                 <MenuItem onClick={handleClose}> This Year </MenuItem>
  //               </Menu>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //         <Grid item xs={12} sx={{ pt: '16px !important' }}>
  //           <BajajAreaChartCard />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Grid container direction='column'>
  //             <Grid item>
  //               <Grid container alignItems='center' justifyContent='space-between'>
  //                 <Grid item>
  //                   <Typography variant='subtitle1' color='inherit'>
  //                     Bajaj Finery
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item>
  //                   <Grid container alignItems='center' justifyContent='space-between'>
  //                     <Grid item>
  //                       <Typography variant='subtitle1' color='inherit'>
  //                         $1839.00
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item>
  //                       <Avatar
  //                         variant='rounded'
  //                         sx={{
  //                           width: 16,
  //                           height: 16,
  //                           borderRadius: '5px',
  //                           backgroundColor: theme.palette.success.light,
  //                           color: theme.palette.success.dark,
  //                           ml: 2
  //                         }}
  //                       >
  //                         <KeyboardArrowUpOutlinedIcon fontSize='small' color='inherit' />
  //                       </Avatar>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item>
  //               <Typography variant='subtitle2' sx={{ color: 'success.dark' }}>
  //                 10% Profit
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //           <Divider sx={{ my: 1.5 }} />
  //           <Grid container direction='column'>
  //             <Grid item>
  //               <Grid container alignItems='center' justifyContent='space-between'>
  //                 <Grid item>
  //                   <Typography variant='subtitle1' color='inherit'>
  //                     TTML
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item>
  //                   <Grid container alignItems='center' justifyContent='space-between'>
  //                     <Grid item>
  //                       <Typography variant='subtitle1' color='inherit'>
  //                         $100.00
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item>
  //                       <Avatar
  //                         variant='rounded'
  //                         sx={{
  //                           width: 16,
  //                           height: 16,
  //                           borderRadius: '5px',
  //                           backgroundColor: theme.palette.primary.light, // theme.palette.orange.light,
  //                           color: theme.palette.primary.dark, // theme.palette.orange.dark,
  //                           marginLeft: 1.875
  //                         }}
  //                       >
  //                         <KeyboardArrowDownOutlinedIcon fontSize='small' color='inherit' />
  //                       </Avatar>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item>
  //               <Typography variant='subtitle2' sx={{ color: theme.palette.primary.dark, /* theme.palette.orange.dark */ }}>
  //                 10% loss
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //           <Divider sx={{ my: 1.5 }} />
  //           <Grid container direction='column'>
  //             <Grid item>
  //               <Grid container alignItems='center' justifyContent='space-between'>
  //                 <Grid item>
  //                   <Typography variant='subtitle1' color='inherit'>
  //                     Reliance
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item>
  //                   <Grid container alignItems='center' justifyContent='space-between'>
  //                     <Grid item>
  //                       <Typography variant='subtitle1' color='inherit'>
  //                         $200.00
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item>
  //                       <Avatar
  //                         variant='rounded'
  //                         sx={{
  //                           width: 16,
  //                           height: 16,
  //                           borderRadius: '5px',
  //                           backgroundColor: theme.palette.success.light,
  //                           color: theme.palette.success.dark,
  //                           ml: 2
  //                         }}
  //                       >
  //                         <KeyboardArrowUpOutlinedIcon fontSize='small' color='inherit' />
  //                       </Avatar>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item>
  //               <Typography variant='subtitle2' sx={{ color: theme.palette.success.dark }}>
  //                 10% Profit
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //           <Divider sx={{ my: 1.5 }} />
  //           <Grid container direction='column'>
  //             <Grid item>
  //               <Grid container alignItems='center' justifyContent='space-between'>
  //                 <Grid item>
  //                   <Typography variant='subtitle1' color='inherit'>
  //                     TTML
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item>
  //                   <Grid container alignItems='center' justifyContent='space-between'>
  //                     <Grid item>
  //                       <Typography variant='subtitle1' color='inherit'>
  //                         $189.00
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item>
  //                       <Avatar
  //                         variant='rounded'
  //                         sx={{
  //                           width: 16,
  //                           height: 16,
  //                           borderRadius: '5px',
  //                           backgroundColor: theme.palette.primary.light, // theme.palette.orange.light,
  //                           color: theme.palette.primary.dark, // theme.palette.orange.dark,
  //                           ml: 2
  //                         }}
  //                       >
  //                         <KeyboardArrowDownOutlinedIcon fontSize='small' color='inherit' />
  //                       </Avatar>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item>
  //               <Typography variant='subtitle2' sx={{ color: theme.palette.primary.dark, /* theme.palette.orange.dark */ }}>
  //                 10% loss
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //           <Divider sx={{ my: 1.5 }} />
  //           <Grid container direction='column'>
  //             <Grid item>
  //               <Grid container alignItems='center' justifyContent='space-between'>
  //                 <Grid item>
  //                   <Typography variant='subtitle1' color='inherit'>
  //                     Stolon
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item>
  //                   <Grid container alignItems='center' justifyContent='space-between'>
  //                     <Grid item>
  //                       <Typography variant='subtitle1' color='inherit'>
  //                         $189.00
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item>
  //                       <Avatar
  //                         variant='rounded'
  //                         sx={{
  //                           width: 16,
  //                           height: 16,
  //                           borderRadius: '5px',
  //                           backgroundColor: theme.palette.primary.light, // theme.palette.orange.light,
  //                           color: theme.palette.primary.dark, // theme.palette.orange.dark,
  //                           ml: 2
  //                         }}
  //                       >
  //                         <KeyboardArrowDownOutlinedIcon fontSize='small' color='inherit' />
  //                       </Avatar>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item>
  //               <Typography variant='subtitle2' sx={{ color: theme.palette.primary.dark, /* theme.palette.orange.dark */ }}>
  //                 10% loss
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </CardContent>
  //     <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
  //       <Button size='small' disableElevation>
  //         View All
  //         <ChevronRightOutlinedIcon />
  //       </Button>
  //     </CardActions>
  //   </MainCard>
}
