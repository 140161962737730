/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { ChipTypeMap } from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
//
import { useGoogleApi } from '../../hooks/use-google-api/useGoogleApi'
import { throttle, parse } from './google-place-utils';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  place_id?: string;
  description: string;
  structured_formatting: StructuredFormatting;
}
export type PlaceResult = google.maps.places.PlaceResult;
export type PlaceComponent = google.maps.GeocoderAddressComponent;
export type GooglePlaceOptions = google.maps.places.AutocompleteOptions;
export type GooglePlaceReqOptions = google.maps.places.PlaceSearchRequest;

export interface GooglePlaceProps extends Omit<
  AutocompleteProps<
    PlaceType, false, boolean, false, ChipTypeMap['defaultComponent']
  >, 'options' | 'renderInput' | 'onChange'
> {
  apiKey?: string;
  fields?: string[];
  label: string;
  sortByDistance?: boolean;
  getLocation?: boolean;
  requestOptions?: GooglePlaceOptions;
  onChange: (place: PlaceResult | null) => void;
  listening?: boolean;
  fromVoice?: string;
}

export function GooglePlace(props: GooglePlaceProps) {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [reqOptions, setReqOptions] = useState<GooglePlaceReqOptions>({});
  const curText = useRef('');
  const inputRef = useRef<HTMLInputElement>();
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService>();
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();
  const { googleApiLoaded /* , googleApiError */ } = useGoogleApi();

  type QueryGoogleReq = { input: string };
  type QueryGoogleCb = (results?: readonly PlaceType[]) => void;
  const throttleQueryGoogle = () => throttle((request: QueryGoogleReq, callback: QueryGoogleCb) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (autocompleteService as any)?.getPlacePredictions({ ...reqOptions, ...request }, callback);
  }, 200);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryGoogle = useMemo(throttleQueryGoogle, [autocompleteService]);

  useEffect(() => {
    if (!googleApiLoaded) return;
    if (autocompleteService == null) setAutocompleteService(new window.google.maps.places.AutocompleteService());
    if (placesService == null) setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleApiLoaded]);

  useEffect(() => {
    setReqOptions({
      ...reqOptions,
      rankBy: props.sortByDistance ? google.maps.places.RankBy.DISTANCE : undefined,
      // location: props.getLocation ? google.maps.LatLng : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sortByDistance, props.getLocation]);

  useEffect(() => {
    if (props.listening) setInputValue(curText.current + props.fromVoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fromVoice]);

  useEffect(() => {
    // if (props.listening) console.log('>>> MUI AC STARTS TO LISTEN!');
    // else console.log('>>> MUI AC STOPS LISTENING...');
    if (props.listening) inputRef.current?.focus();
    if (props.listening) curText.current = inputValue;
    else curText.current = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listening]);

  useEffect(() => {
    let active = true;

    if (autocompleteService == null) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    queryGoogle({ ...props.requestOptions, input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => { active = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, queryGoogle]);

  if (autocompleteService == null || autocompleteService == null) {
    return <Box display='flex' flexGrow={1}>
      <CircularProgress size='1rem' sx={{ m: 'auto' }} />
    </Box>
  }

  return (
    <Autocomplete
      id='google-map-demo'
      getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      style={props.style}
      value={value}
      inputValue={inputValue}
      openOnFocus={props.listening}
      onChange={(event: any, newValue: PlaceType | null, reason: AutocompleteChangeReason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        const placeId = newValue?.place_id;
        if (placeId == null) {
          setValue(null);
          return;
        }
        placesService?.getDetails(
          { placeId, fields: props.fields },
          (place: google.maps.places.PlaceResult | null) => {
            setValue(newValue);
            props.onChange({ ...newValue, ...place });
          });
      }}
      onInputChange={(event, newInputValue) => {
        if (!props.listening) setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} inputRef={inputRef} label={props.label} fullWidth />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
