import { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';
import { Auth } from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Paragraph, TextInput } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
//
import { validateEmailFormat, validateNorthAmericaPhoneNumber } from '@eksoh/shared/common';
import { authStore } from '@eksoh/shared/ui';
import { appMaxWidth } from '@eksoh/flo/ui';
import { scale } from '../Scaling';
import { UnauthStackScreenProps } from '../types';
import Logo from '../cmps/Logo';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter from '../cmps/OnboardingFooter';

export type LoginMean = 'USE_PHONE' | 'USE_EMAIL';

export default function GetLoginInfo({ navigation }: UnauthStackScreenProps<'GetLoginInfo'>) {
  const { authState, onboarding } = useContext(authStore);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [go, setGo] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isFocused = useIsFocused();
  const [t] = useTranslation();

  useEffect(() => {
    if (authState.onboarding?.email) setEmail(authState.onboarding.email);
    if (authState.onboarding?.phone) setPhone(authState.onboarding.phone);

    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (authState.onboarding?.email != null && authState.onboarding?.phone != null) {
      if (go) {
        setGo(false);
        navigation.push('ExtraLoginInfo');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [go, authState.onboarding]);

  useFocusEffect(
    useCallback(() => {
      if (!mounted) return;
      // if (authState.user?.email == null) navigation.replace('Root');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted])
  );

  function validateNext() {
    return !email || !validateEmailFormat(email) || !phone || !validateNorthAmericaPhoneNumber(phone);
  }

  function next() {
    setGo(true);
    onboarding({ email, phone } );
  }

  return <View style={styles.container}>
    <OnboardingHeader progress={1} onClose={() => navigation.push('Welcome')} />
    <Logo style={{ marginTop: scale(1) }} width={60} height={60} />
    <Paragraph style={{ marginTop: scale(24), marginBottom: scale(12) }}>{t('nurse.loginMethod')}</Paragraph>
    <TouchableHighlight style={[styles.but, styles.butProv]} onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })}>
      <Text style={styles.butText}>
        {t('login.form.continueWith')}{' '}<Ionicons name='logo-facebook' size={24} />{' '}{t('login.form.facebook')}
      </Text>
    </TouchableHighlight>
    <TouchableHighlight style={[styles.but, styles.butProv]} onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>
      <Text style={styles.butText}>
        {t('login.form.continueWith')}{' '}<Ionicons name='logo-google' size={24} />{' '}{t('login.form.google')}
      </Text>
    </TouchableHighlight>
    <View style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: appMaxWidth, marginTop: 12 }}>
      <View style={[styles.line, { marginRight: 12 }]} />
      <Text style={styles.butText}>{t('shorts.or')}</Text>
      <View style={[styles.line, { marginLeft: 12 }]} />
    </View>
    <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 12 }}>
      <TextInput mode='outlined'
        label={t('login.form.email')} placeholder={t('login.form.email-placeholder')}
        value={email} onChangeText={text => setEmail(text)}
      />
    </View>
    <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 6 }}>
      <TextInput
        mode='outlined' label={t('login.form.mobile')} placeholder={t('login.form.mobile-placeholder')}
        value={phone} onChangeText={text => setPhone(text)}
        render={props => <MaskedTextInput
          value={props.value} mask='+1 (999) 999-9999' style={props.style}
          onChangeText={(t, r) => { if (props.onChangeText) props.onChangeText(r); }}
        />}
      />
    </View>
    <OnboardingFooter
      overrideBackText='login.form.already' disabledBut={validateNext()} hideLater
      onBack={() => navigation.push('Login')} onNext={next}
    />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  surface: {
    elevation: 1,
    // marginTop: 12,
    marginBottom: 12,
    padding: 8,
    width: '-webkit-fill-available',
    backgroundColor: '#F3F3F3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  butText: {
    color: '#323232',
    textAlign: 'center',
  },
  line: {
    display: 'flex',
    flexGrow: 1,
    border: '0.5px solid #000',
    height: 1,
    marginTop: 9,
  },
  but: {
    marginRight: 24,
    marginLeft: 24,
    marginTop: 10,
    paddingBottom: 20,
    backgroundColor: 'transparent',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#323232',
    width: '100%',
    maxWidth: appMaxWidth,
    height: 63,
  },
  butProv: {
    paddingTop: 14,
  },
});
