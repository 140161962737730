import { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button, Card, Checkbox } from 'react-native-paper';
//
import { FFLayout } from './layout';

export interface FFCheckboxProps {
  title: string;
  subTitle: string;
  icon: string;
  question: string;
  extra?: string;
  choices: string[];
  default?: boolean[];
  onSelect: (checked: string[]) => void;
  onBack?: () => void;
}

export function FFCheckbox(props: FFCheckboxProps) {
  const [checked, setChecked] = useState<boolean[]>([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.default == null) setChecked(props.choices.map(() => false));
    else {
      const notNullAry = props.default;
      setChecked(props.choices.map((_, i) => i < notNullAry.length ? notNullAry[i] : false));
    }
  }, [props.choices, props.default]);

  useEffect(() => {
    setReady(checked.filter(c => c).length > 0);
  }, [checked])

  return <View style={{ width: '100%' }}>
    <FFLayout
      icon={props.icon}
      title={props.title}
      subTitle={props.subTitle}
      question={props.question}
      extra={props.extra}
      content={props.choices.map((c, i) => <Checkbox.Item
        key={'dtcb' + i} label={c} status={checked[i] ? 'checked' : 'unchecked'}
        onPress={() => setChecked(checked.map((c, idx) => i === idx ? !c : c))}
      />)}
      onBack={props.onBack}
      action={<Card.Actions style={{ justifyContent: 'flex-end' }}>
        <Button disabled={!ready} onPress={() => props.onSelect(props.choices.filter((_, i) => checked[i]))}>
          {{ en: 'next', fr: 'suivant' }.fr}
        </Button>
      </Card.Actions>}
    />
  </View>
}
