import { useContext, useState, useRef, useEffect } from 'react';
import { Platform, StyleSheet, Text, View, ScrollView, Image, TouchableOpacity } from 'react-native';
import { useTheme, Subheading, Paragraph, Button, IconButton /* , List */, Snackbar } from 'react-native-paper';
import { Camera, CameraType } from 'expo-camera';
import * as FileSystem from 'expo-file-system';
// import * as Linking from 'expo-linking';
// import { ImageType } from 'expo-camera/src/Camera.types';
import { manipulateAsync, /* FlipType, */ SaveFormat, ImageResult } from 'expo-image-manipulator';
import i18n from 'i18next';
import { useTranslation, Trans } from 'react-i18next';
//
import { RootStackScreenProps } from '../types';
import { authStore } from '@eksoh/shared/ui';
import { WaitingModal, GenericModal } from '@eksoh/shared/ui-expo';
import { appMaxWidth } from '@eksoh/flo/ui';
import { scale } from '../Scaling';
import Logo from '../cmps/Logo';
import OnboardingHeader from '../cmps/OnboardingHeader';
import OnboardingFooter, { footMinHeight } from '../cmps/OnboardingFooter';
import useUploadFile from '../hooks/useUploadFile';
import usePickDocument from '../hooks/usePickDocument';
import useOcr, { getMimeType, supportedImgTypes } from '../hooks/useOcr';
import { BeServices } from '@eksoh/shared/ui';
import { stepEnd as prevStepEnd } from './Onboarding1PostInterview';

// NOTE: In American English, the noun is spelled the same as the verb—license. But in British English,
//       the noun is spelled licence. All the while, the meaning stays the same—permission, a permit, a
//       document that states you are qualified or allowed to do something.

// enum CardTypes { ALL, OIIAQ, DRIVER_LICENCE, RCR, ATCD, EXTRA1, EXTRA2, CONSENT }
enum CardTypes { ALL, OIIAQ, DRIVER_LICENCE, RCR, RAMQ, NO2CARDS, CONSENT }

export const stepStart = prevStepEnd;
export const stepEnd = prevStepEnd + Object.keys(CardTypes).length;

export const supportedDocTypes = [
  'data:application/pdf',
  'data:application/msword',
  'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

function ConsentDesc() {
  const [t] = useTranslation();

  return <Trans t={t} i18nKey='nurse.consentDesc'>
    Please download, read, fill and sign <span style={{ color: 'red' }}>handwrititing</span> this consent for you criminal background check up.
  </Trans>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cardInfos: { [key: string]: any } = {
  [CardTypes.ALL]: {
    list: 'nurse.onboarding',
    desc: () => 'nurse.getCardsDesc',
    btn: 'shorts.start',
  },
  [CardTypes.OIIAQ]: {
    list: 'nurse.nurseCard',
    desc: () => 'nurse.genCard',
  },
  [CardTypes.DRIVER_LICENCE]: {
    list: 'nurse.driversLic',
    desc: () => 'nurse.genCard',
  },
  [CardTypes.RCR]: {
    list: 'nurse.rcrCard',
    desc: () => 'nurse.genCard',
  },
  // [CardTypes.ATCD]: {
  //   list: 'nurse.onboarding',
  //   desc: (idx: number) => 'nurse.bgCheckDesc' + idx,
  //   btn: 'shorts.start',
  // },
  // [CardTypes.EXTRA1]: {
  //   list: 'nurse.extraCard',
  //   desc: () => 'nurse.genCard',
  // },
  // [CardTypes.EXTRA2]: {
  //   list: 'nurse.extraCard',
  //   desc: () => 'nurse.genCard',
  // },
  [CardTypes.RAMQ]: {
    list: 'nurse.ramqCard',
    desc: () => 'nurse.genCard',
  },
  [CardTypes.NO2CARDS]: {
    list: '',
    desc: () => 'nurse.no2cardsDesc',
  },
  [CardTypes.CONSENT]: {
    list: 'nurse.consentDl',
    cmp: <ConsentDesc />
  },
}

export default function Onboarding2CardsScan({ navigation }: RootStackScreenProps<'Onboarding2CardsScan'>) {
  const { authState, refreshAuth } = useContext(authStore);
  const [camType, setCamType] = useState(CameraType.back);
  const [camTypes, setCamTypes] = useState<CameraType[]>([]);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [camReady, setCamReady] = useState(false);
  const camRef = useRef<Camera | null>(null);
  const [cardType, setCardType] = useState(CardTypes.ALL);
  const [capture, setCapture] = useState<ImageResult>();
  const [noOiiaqCard, setNoOiiaqCard] = useState<boolean>();
  const [cardOIIAQ, setCardOIIAQ] = useState<ImageResult | File>();
  const [noDlCard, setNoDlCard] = useState<boolean>();
  const [cardDL, setCardDL] = useState<ImageResult | File>();
  const [cardRCR, setCardRCR] = useState<ImageResult | File>();
  // const [cardXtra1, setCardXtra1] = useState<ImageResult>();
  // const [cardXtra2, setCardXtra2] = useState<ImageResult>();
  const [cardRAMQ, setCardRAMQ] = useState<ImageResult | File>();
  const [noRamqCard, setNoRamqCard] = useState<boolean>();
  const [retake, setRetake] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [refreshDone, setRefreshDone] = useState(false);
  const {
    ocrBusy, /* processBase64, */ renderOcr, ocrDocBase64, resetDoc, setPickDocText, dataURLtoFile,
  } = useOcr({
    showPickDoc: true, pickDocText: 'nurse.nurseCard', autoOcr: false,
  });
  const { upload } = useUploadFile({ alwaysRender: true });
  const { doc, renderPickDoc } = usePickDocument();
  const [badUplFormat, setBadUplFormat] = useState(false);
  //
  const { colors } = useTheme();
  const [t] = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const noCardInfos: { [key: string]: any } = {
    [CardTypes.OIIAQ]: {
      label: 'nurse.noOiiaqCard',
      get: noOiiaqCard,
      set: setNoOiiaqCard,
    },
    [CardTypes.DRIVER_LICENCE]: {
      label: 'nurse.noDlCard',
      get: noDlCard,
      set: setNoDlCard,
    },
    [CardTypes.RAMQ]: {
      label: 'nurse.noDlCard',
      get: noRamqCard,
      set: setNoRamqCard,
    },
  };  

  useEffect(() => {
    if (refreshDone) navigation.push('Root'); // Go wait for EMPLOYEE_INFO or ATCD_FAILED...
  }, [refreshDone, navigation]);

  useEffect(() => {
    if (authState.onboarding?.postInterview?.done) {
      navigation.push('Root'); // Go wait for EMPLOYEE_INFO or ATCD_FAILED...
    }
  }, [authState.onboarding, navigation]);

  useEffect(() => {
    if (!camReady) return;
    async function getCamTypes() {
      const types = await Camera.getAvailableCameraTypesAsync();
      setCamTypes(types);
      if (!types.includes(CameraType.back)) {
        setCamType(CameraType.front);
      }
    }
    getCamTypes();
  }, [camReady])

  useEffect(() => {
    if (retake && capture != null) {
      setCapture(undefined);
      setRetake(false);
    }
  }, [capture, retake]);

  useEffect(() => {
    if (capture != null) {
      if (cardType === CardTypes.OIIAQ) setCardOIIAQ(capture);
      else if (cardType === CardTypes.RCR) setCardRCR(capture);
      else if (cardType === CardTypes.DRIVER_LICENCE) setCardDL(capture);
      // else if (cardType === CardTypes.EXTRA1) setCardXtra1(capture);
      // else if (cardType === CardTypes.EXTRA2) setCardXtra2(capture);

      if (cardType === CardTypes.OIIAQ) setNoOiiaqCard(undefined);
      else if (cardType === CardTypes.DRIVER_LICENCE) setNoDlCard(undefined);
      else if (cardType === CardTypes.RAMQ) setCardRAMQ(capture);
    }
  }, [capture, cardType]);

  useEffect(() => {
    async function watchDoc() {
      if (ocrDocBase64 != null && ocrDocBase64.length > 14) {
        const mimeType = getMimeType(ocrDocBase64);
        if (mimeType == null) { setBadUplFormat(true); return; }
        const imgType = supportedImgTypes[mimeType];
console.log('>>> DOC TYPE:', mimeType, imgType, supportedDocTypes.includes(mimeType))
        let docu: ImageResult | File;
        if (imgType != null) {
          docu = await manipulateAsync(
            ocrDocBase64,
            camType === CameraType.front ? [] : [], // [{ flip: FlipType.Horizontal }] : [],
            { compress: 1, format: SaveFormat.PNG }
          );
        }
        else if (supportedDocTypes.includes(mimeType)) {
          docu = dataURLtoFile(ocrDocBase64, 'unknown');
        }
        else  { setBadUplFormat(true); return; }

        if (cardType === CardTypes.OIIAQ) setCardOIIAQ(docu);
        else if (cardType === CardTypes.RCR) setCardRCR(docu);
        else if (cardType === CardTypes.DRIVER_LICENCE) setCardDL(docu);
        // else if (cardType === CardTypes.EXTRA1) setCardXtra1(capture);
        // else if (cardType === CardTypes.EXTRA2) setCardXtra2(capture);
        else if (cardType === CardTypes.RAMQ) setCardRAMQ(docu);

        if (cardType === CardTypes.OIIAQ) setNoOiiaqCard(undefined);
        else if (cardType === CardTypes.DRIVER_LICENCE) setNoDlCard(undefined);
        else if (cardType === CardTypes.RAMQ) setNoRamqCard(undefined);

        // if (imgType == null) { setBadUplFormat(true); return; }
        // const manipResult = await manipulateAsync(
        //   ocrDocBase64,
        //   camType === CameraType.front ? [] : [], // [{ flip: FlipType.Horizontal }] : [],
        //   { compress: 1, format: SaveFormat.PNG }
        // );
        // if (cardType === CardTypes.OIIAQ) setCardOIIAQ(manipResult);
        // else if (cardType === CardTypes.RCR) setCardRCR(manipResult);
        // else if (cardType === CardTypes.DRIVER_LICENCE) setCardDL(manipResult);
        // // else if (cardType === CardTypes.EXTRA1) setCardXtra1(capture);
        // // else if (cardType === CardTypes.EXTRA2) setCardXtra2(capture);
        // else if (cardType === CardTypes.RAMQ) setCardRAMQ(manipResult);

        // if (cardType === CardTypes.OIIAQ) setNoOiiaqCard(undefined);
        // else if (cardType === CardTypes.DRIVER_LICENCE) setNoDlCard(undefined);
        // else if (cardType === CardTypes.RAMQ) setNoRamqCard(undefined);
      }
    }
    if (cardType === CardTypes.CONSENT) return;
    watchDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrDocBase64]);

  if (!permission) {
    // Camera permissions are still loading
    return <View />;
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: 'center' }}>{t('nurse.camNeedPerm')}</Text>
        <Button mode='outlined' onPress={requestPermission}>{t('nurse.camGrantPerm')}</Button>
      </View>
    );
  }

  function toggleCameraType() {
    setCamType((current) => current === CameraType.back ? CameraType.front : CameraType.back);
  }

  async function takePicture() {
    if (camRef.current == null) return;
    if (capture != null) {
      setRetake(true);
      return;
    }

    // const imgOpts: CameraPictureOptions = {
    //   base64?: boolean, // Whether to also include the image data in Base64 format.
    //   exif?: boolean, // Whether to also include the EXIF data for the image.
    //   imageType?: ImageType, //
    //   isImageMirror?: boolean, //
    //   quality?: number, // Specify the quality of compression, from 0 to 1. 0 means compress for small size, 1 means compress for maximum quality.
    //   scale?: number,
    //   skipProcessing?: boolean, // If set to true, camera skips orientation adjustment and returns an image straight from the device's camera. If enabled, quality option is discarded (processing pipeline is skipped as a whole). Although enabling this option reduces image delivery time significantly, it may cause the image to appear in a wrong orientation in the Image component (at the time of writing, it does not respect EXIF orientation of the images).
    //                             // Note: Enabling skipProcessing would cause orientation uncertainty. Image component does not respect EXIF stored orientation information, that means obtained image would be displayed wrongly (rotated by 90°, 180° or 270°). Different devices provide different orientations. For example some Sony Xperia or Samsung devices don't provide correctly oriented images by default. To always obtain correctly oriented image disable skipProcessing option.
    //   onPictureSaved?: (picture: CameraCapturedPicture) => void, // A callback invoked when picture is saved. If set, the promise of this method will resolve immediately with no data after picture is captured. The data that it should contain will be passed to this callback. If displaying or processing a captured photo right after taking it is not your case, this callback lets you skip waiting for it to be saved.
    // };
    // const { uri, width, height, exif, base64 } = await camRef.current.takePictureAsync();
    // console.log('>>> IMG:', uri, width, height, exif, base64);

    // const { base64 } = await camRef.current.takePictureAsync({ isImageMirror: CameraType.front ? true : false });
    const { base64 } = await camRef.current.takePictureAsync();
    if (base64 == null || !base64) return;
    // downloadBase64File(base64, 'b64Snap.png');
    const manipResult = await manipulateAsync(
      base64,
      camType === CameraType.front ? [] : [], // [{ flip: FlipType.Horizontal }] : [],
      { compress: 1, format: SaveFormat.PNG }
    );

    setCapture(manipResult);
    // scanImg('fra', manipResult);
    // processBase64('fra', base64); // TODO FE - re-activate one day...
  }

  const docs = {
    'fr': require('../../../assets/docs/reg-nurse-conscent-fr.pdf'),
    'en': require('../../../assets/docs/reg-nurse-conscent-en.pdf'),
  };

  async function download() {
    const doc = docs[i18n.language.substring(0, 2) as keyof typeof docs];
    if (doc == null) return; // TODO: return UI feedback
    if (Platform.OS === 'web') {
      window.open(doc, '_blank');

      // // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // Linking.openURL(form).catch((err: any) => {
      //   console.log(err)
      // });

      // const downloadLink = document.createElement('a');
      // document.body.appendChild(downloadLink);
      // downloadLink.href = form;
      // downloadLink.target = '_self';
      // downloadLink.download = 'flowrence_consent_form.pdf';
      // downloadLink.click();
      // // document.body.removeChild(downloadLink);
    }
    else {
      FileSystem.downloadAsync(doc, FileSystem.documentDirectory + 'flowrence_consent_form.pdf')
        .then(({ uri }) => {
          console.log('Finished downloading to ', uri);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  async function uploadCard(card: string, name: string) {
    const fileName = `${name}_card.png`;
    await upload(dataURLtoFile('data:image/png;base64,' + card, fileName), fileName);
  }

  async function uploadFile(file: File, name: string) {
    await upload(file, name);
  }

  function uploadDocument(name: string, fileOrImg?: ImageResult | File) {
    if (fileOrImg == null) return;
    const isImg = fileOrImg as ImageResult;
    if (isImg.base64 != null) uploadCard(isImg.base64, name);
    else uploadFile(fileOrImg as File, name)
  }

  async function nextStep() {
    if (cardType === CardTypes.ALL) {
      setCapture(undefined);
      setRetake(false);
      setCardType(CardTypes.OIIAQ);
      setPickDocText(cardInfos[CardTypes.OIIAQ].list)
    }
    else if (cardType === CardTypes.OIIAQ && (cardOIIAQ != null || noOiiaqCard)) {
      setCapture(undefined);
      setRetake(false);
      setCardType(CardTypes.DRIVER_LICENCE);
      setPickDocText(cardInfos[CardTypes.DRIVER_LICENCE].list)
    }
    else if (cardType === CardTypes.DRIVER_LICENCE && (cardDL != null || noDlCard)) {
      setCapture(undefined);
      setRetake(false);
      setCardType(CardTypes.RCR);
      setPickDocText(cardInfos[CardTypes.RCR].list)
    }
    else if (cardType === CardTypes.RCR && cardRCR != null) {
      setCapture(undefined);
      setRetake(false);
      // setCardType(CardTypes.ATCD);
      // // improtant to restart cam
      // setCamReady(false);
      // setCapture(undefined);
      setCardType(CardTypes.RAMQ);
      setPickDocText(cardInfos[CardTypes.RAMQ].list)
    }
    // else if (cardType === CardTypes.ATCD) {
    //   setCapture(undefined);
    //   setRetake(false);
    //   setCardType(CardTypes.EXTRA1);
    //   // setPickDocText(cardInfos[CardTypes.EXTRA1].list)
    //   setPickDocText(noDlCard ? 'nurse.extraCard1' : 'nurse.extraCard')
    // }
    // else if (cardType === CardTypes.EXTRA1 && doc == null) {
    //   setCapture(undefined);
    //   setRetake(false);
    //   setCardType(noDlCard ? CardTypes.EXTRA2 : CardTypes.CONSENT)
    //   // setPickDocText(cardInfos[CardTypes.EXTRA2].list)
    //   setPickDocText(noDlCard ? 'nurse.extraCard2' : 'nurse.extraCard')
    // }
    // else if (cardType === CardTypes.EXTRA2 && doc == null) {
    //   setCapture(undefined);
    //   setRetake(false);
    //   setCardType(CardTypes.CONSENT);
    //   setPickDocText(cardInfos[CardTypes.CONSENT].list)
    // }
    else if (cardType === CardTypes.RAMQ && (cardRAMQ != null || noRamqCard)) {
      setCapture(undefined);
      setRetake(false);
      setCardType(noRamqCard ? CardTypes.NO2CARDS : CardTypes.CONSENT);
      setPickDocText(cardInfos[CardTypes.CONSENT].list)
    }
    else if (cardType === CardTypes.NO2CARDS) {
      setCapture(undefined);
      setRetake(false);
      setCardType(CardTypes.CONSENT);
      setPickDocText(cardInfos[CardTypes.CONSENT].list)
    }
    else if (cardType === CardTypes.CONSENT && doc != null) {
      // if (cardRCR?.base64 != null && doc != null) {
      if (cardRCR != null && doc != null) {
        setUploading(true);
        uploadDocument('nurse', cardOIIAQ); // if (cardOIIAQ?.base64 != null) uploadCard(cardOIIAQ.base64, 'nurse');
        uploadDocument('rcr', cardRCR); // uploadCard(cardRCR.base64, 'rcr');
        uploadDocument('driver', cardDL); // if (cardDL?.base64 != null) uploadCard(cardDL.base64, 'driver');
        // if (cardXtra1?.base64 != null) uploadCard(cardXtra1.base64, 'extra1');
        // if (cardXtra2?.base64 != null) uploadCard(cardXtra2.base64, 'extra2');
        uploadDocument('ramq', cardRAMQ); // if (cardRAMQ?.base64 != null) uploadCard(cardRAMQ.base64, 'ramq');
        uploadFile(doc, 'criminal_consent.pdf');
        await BeServices.getInstance().user.onboardingPostInterview({
          ...authState.onboarding,
          postInterview: { noOiiaqCard, noDlCard, done: true },
        });
        await refreshAuth();
        setUploading(false);  
        setRefreshDone(true);
      }
    }
  }

  function notReadyForNext() {
    return ocrBusy
      || (cardType === CardTypes.OIIAQ && (cardOIIAQ == null && !noOiiaqCard))
      || (cardType === CardTypes.DRIVER_LICENCE && (cardDL == null && !noDlCard))
      || (cardType === CardTypes.RCR && cardRCR == null)
      // || (cardType === CardTypes.EXTRA1 && cardXtra1 == null)
      // || (cardType === CardTypes.EXTRA2 && cardXtra2 == null)
      || (cardType === CardTypes.RAMQ && (cardRAMQ == null && !noRamqCard))
      || cardType === CardTypes.NO2CARDS
      || (cardType === CardTypes.CONSENT && doc == null)
  }

  function cancelPickedDoc() {
    resetDoc();
    setBadUplFormat(false);
  }

  // const bigLogo = cardType === CardTypes.ALL || cardType === CardTypes.ATCD || cardType === CardTypes.CONSENT;
  // const canSkip = cardType === CardTypes.OIIAQ || cardType === CardTypes.DRIVER_LICENCE;
  // const scan = (cardType > CardTypes.ALL && cardType < CardTypes.ATCD) || (cardType > CardTypes.ATCD && cardType < CardTypes.NO2CARDS);
  const bigLogo = cardType === CardTypes.ALL || cardType === CardTypes.NO2CARDS || cardType === CardTypes.CONSENT;
  const canSkip = cardType === CardTypes.OIIAQ || cardType === CardTypes.DRIVER_LICENCE || cardType === CardTypes.RAMQ;
  const scan = cardType > CardTypes.ALL && cardType < CardTypes.NO2CARDS;
  const lastStep = cardType === CardTypes.CONSENT;
  return <View style={styles.container}>
    <OnboardingHeader progress={stepStart + cardType} onClose={() => navigation.push('Root')} step='nurse.onboarding' />
    <ScrollView style={{ width: '100%', marginBottom: footMinHeight }} contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}>
      {bigLogo && <>
        <Logo style={{ marginTop: scale(6) }} width={60} height={60} />
        {/* <Subheading style={{ marginTop: scale(cardType === CardTypes.ATCD ? 20 : 50), textAlign: 'center' }}>{t(cardInfos[cardType].desc(noDlCard ? 2 : 1))}</Subheading> */}
        <Subheading style={{ marginTop: scale(cardType === CardTypes.NO2CARDS ? 20 : 50), textAlign: 'center' }}>
          {cardInfos[cardType].cmp || t(cardInfos[cardType].desc(noDlCard ? 2 : 1))}
        </Subheading>
      </>}
      {/* {cardType === CardTypes.ATCD && <ScrollView
        style={{ width: '100%', marginTop: 12, maxHeight: '42vh', borderWidth: 1, borderStyle: 'solid', borderColor: '#ccc', borderRadius: 10 }}
        contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }} persistentScrollbar={true}
      >
        <List.Section>
          {[...Array(14).keys()].map(idx => <List.Item
            key={'priCard_' + idx} title={t('nurse.priCard' + idx)}
            titleNumberOfLines={2}
          />)}
        </List.Section>
      </ScrollView>} */}
      {!bigLogo && <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Logo style={{ marginTop: scale(18) }} width={50} height={50} />
        <Paragraph style={{ marginTop: scale(6), textAlign: 'center', marginLeft: 12 }}>
          {cardInfos[cardType].cmp || t(cardInfos[cardType].desc())}
        </Paragraph>
      </View>}
      {scan && <>
        <View style={{ width: '100%', height: 220, marginTop: 12 }}>
          <Camera
            ref={camRef} style={[styles.camera, { transform: [{ scaleX: CameraType.front ? -1 : 1 }] }]}
            type={camType} onCameraReady={() => setCamReady(true)}
          >
            <View style={styles.buttonContainer}>
              {/* <TouchableOpacity
                style={styles.button}
                onPress={toggleCameraType}>
                <Text style={styles.text}>Flip Camera</Text>
              </TouchableOpacity> */}
            </View>
          </Camera>
          {capture != null && capture.base64 && <Image
            style={{ width: '100%', height: 220, resizeMode: 'contain' }}
            source={{ uri: 'data:image/png;base64,' + capture.base64 }}
          />}
        </View>
        {renderOcr(
          <View style={{ display: 'flex', width: '100%', flexDirection: 'row', marginTop: 12, justifyContent: camTypes.length > 1 ? 'space-between' : 'center' }}>
            {camTypes.length > 1 && <IconButton
              icon='camera-flip' size={24} style={{ marginRight: 24 }}
              onPress={toggleCameraType}
              disabled={!camReady || camRef.current == null}
            />}
            <Button
              icon='camera' mode='outlined' onPress={takePicture} disabled={!camReady || camRef.current == null}
              style={{ width: 120, justifyContent: 'center' }}
            >
              {t(capture != null ? 'nurse.retake' : 'nurse.snap')}
            </Button>
            {canSkip && !ocrBusy && cardType === CardTypes.OIIAQ && <TouchableOpacity
              style={{
                maxWidth: 150, marginLeft: 12, flexWrap: 'wrap', borderColor: 'rgba(0, 0, 0, 0.29)',
                borderRadius: 4, borderStyle: 'solid', borderWidth: 1,
                backgroundColor: noCardInfos[cardType]['get'] ? colors.primary : undefined,
              }} // , boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px' }}
              onPress={() => noCardInfos[cardType]['set'](!noCardInfos[cardType]['get'])}
            >
              <Text style={{ margin: 6, fontSize: 10, textAlign: 'center', color: noCardInfos[cardType]['get'] ? colors.surface : colors.primary }}>
                {t(noCardInfos[cardType]['label'])}
              </Text>
            </TouchableOpacity>}
            {canSkip && !ocrBusy && cardType !== CardTypes.OIIAQ && <Button
              icon='smart-card-off' mode={noCardInfos[cardType]['get'] ? 'contained' : 'outlined'}
              onPress={() => noCardInfos[cardType]['set'](!noCardInfos[cardType]['get'])}
              style={{ marginLeft: 12, justifyContent: 'center' }}
            >
              {t(noCardInfos[cardType]['label'])}
            </Button>}
          </View>
        )}
      </>}
      {lastStep && <View style={{ width: '100%', maxWidth: appMaxWidth, marginTop: 40 }}>
        <Button icon='download' mode='outlined' onPress={download} style={{ width: '100%', marginBottom: 40 }}>
          {t('nurse.consentDl')}
        </Button>
        {renderPickDoc('nurse.consentUl')}
      </View>}
    </ScrollView>
    <OnboardingFooter butText={t(cardInfos[cardType].btn)} onNext={nextStep} hideLater disabledBut={notReadyForNext()} />
    <GenericModal open={cardType === CardTypes.NO2CARDS} text={t('nurse.no2cardsDesc')} width='70vw'>
      <Button style={{ marginTop: 24 }} onPress={() => setCardType(CardTypes.CONSENT)}>close</Button>
    </GenericModal>
    <Snackbar
      style={{ backgroundColor: 'red' }}
      visible={badUplFormat}
      onDismiss={cancelPickedDoc}
      action={{
        label: t('shorts.close'),
        onPress: cancelPickedDoc,
      }}
    >
      {t('nurse.badUplFormat')}
    </Snackbar>
    <WaitingModal open={uploading} text={t('shorts.uploading')} width='70vw' />
  </View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    maxWidth: appMaxWidth,
    margin: 'auto',
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 64,
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
});
