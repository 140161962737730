import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import { withJsonFormsLayoutProps, JsonFormsDispatch } from '@jsonforms/react';
import { rankWith, uiTypeIs, LayoutProps, Layout } from '@jsonforms/core';

export const gridLayoutTester = rankWith(1000, uiTypeIs('GridLayout'));

export interface GridLayoutConfig {
  xs?: number,
  sm?: number,
  md?: number,
  mt?: number | string;
  mb?: number | string;
}

export interface GridLayoutProps extends LayoutProps {
  uischema: Layout & { config?: GridLayoutConfig };
}

function GridLayout({ uischema, schema, path, visible, enabled, renderers, cells }: GridLayoutProps) {
  return <Hidden xsUp={!visible}>
    <Grid container spacing={2} mt={uischema.config?.mt} mb={uischema.config?.mb}>
      {uischema.elements.map((child, index) => <Grid
        key={index} item
        xs={uischema.config?.xs || 12} sm={uischema.config?.sm || 6} md={uischema.config?.md || 4}
      >
        <JsonFormsDispatch
          schema={schema}
          uischema={child}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      </Grid>)}
    </Grid>
  </Hidden>
};

// this helps getting props values...
//
// function GridLayout(props: LayoutProps) {
// console.log('>>> GRID LAYOUT:', props)
//   return <div />
// }

export default withJsonFormsLayoutProps(GridLayout);
