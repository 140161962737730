import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import TransgenderIcon from '@mui/icons-material/Transgender';
import WcIcon from '@mui/icons-material/Wc';

import { Gender } from '@eksoh/shared/common';

export function GenderIcon({ gender }: { gender: Gender }) {
  switch (gender) {
  case Gender.Male: return <ManIcon fontSize='large' />;
  case Gender.Female: return <WomanIcon fontSize='large' />;
  case Gender.TransMale:
  case Gender.TransFemale: return <TransgenderIcon fontSize='large' />;
  case Gender.GenderQueer: return <TransgenderIcon fontSize='large' />;
  default: return <WcIcon fontSize='large' />;
  }
};
