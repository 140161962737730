import {
  AdmCreateAppmtMutation, CreateAppmtMutation, AppointmentPaymentProfileError,
} from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { eNectoActions, NectoActionTypes, NectoInfo } from './necto';

export type AdmiCreateAppmt = AdmCreateAppmtMutation['admCreateAppmt'];
export type CreateAppmt = CreateAppmtMutation['createAppmt'];
export type AllCreateAppmt = AdmiCreateAppmt | CreateAppmt;

function dispatchUnknownError(dispatch: (value: NectoActionTypes) => void) {
  dispatch({
    type: eNectoActions.SET_ERROR,
    error: {
      msg: 'Unable to create appointment. We are experiencing problems at the moment. Try another date or time or call Flowrence for assistance.',
      error: { type: 'TODO' },
    },
  });
}

function dispatchSuccess(dispatch: (value: NectoActionTypes) => void, info?: NectoInfo) {
  dispatch({
    type: eNectoActions.SET_INFO,
    info: info || {
      msg: 'Operation succeeded',
      info: { type: 'SUCCESS' },
    },
  });
}

export function checkForErrors(dispatch: (value: NectoActionTypes) => void, res: AllCreateAppmt) {
  if (res == null) {
    dispatchUnknownError(dispatch);
  }
  else {
    if (res.__typename === 'AppointmentPaymentProfileError') {
      const error = res as AppointmentPaymentProfileError;
      if (error.validation?.approved === false) {
        if (error.validation.message === 'DECLINE') {
          dispatch({
            type: eNectoActions.SET_ERROR,
            error: {
              msg: 'Unable to create appointment. Your card has been declined. Try another card or call Flowrence for assistance.',
              error: { type: 'TODO' },
            },
          });
        }
        else {
          dispatchUnknownError(dispatch);
        }
      }
      else {
        dispatchUnknownError(dispatch);
      }
    }
    else {
      dispatchSuccess(dispatch);
    }
  }
}
